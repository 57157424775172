import { gql, TypedDocumentNode } from '@apollo/client';
import {
  Contest,
  ContestOptions,
  Leaderboard,
} from '../../types/contest/types';

const CONTEST_FIELDS = `
    id
    title
    description
    companyIds
    contestType
    pointCategories
    imageUrl
    duration {
      startDate
      endDate
    }
    optedOutUsers
`;

const CONTEST_POINT_FIELDS = `
    userPoints {
      year
      month
      week
      totalPoints
      pointsByCategory {
        category
        points
      }
      pointBreakouts {
        eventType
        eventTypeDescription
        category
        pointTotals
      }
    }
`;

const LEADERBOARD_FIELDS = `
    id
    contestId
    rollup
    timestamp
`;

const LEADERBOARD_USER_FIELDS = `
    user {
      id
      name
      firstName
      lastName
      avatar {
        metadataId
        components {
          componentId
          optionId
          color
        }
      }
    }
`;

export const getContestsQuery = (includeUserPoints?: boolean) =>
  gql`
    query GET_CONTESTS(
      $contestIds: [String]
      $contestType: String,
      $isDraft: Boolean,
      $interval: ContestDurationInterval
      $activeBetweenYYYMM: String,
      $startsBeforeYYYYMM: String,
      $endsBeforeYYYYMM: String,
      $sortBy: String,
      $sortDirection: String,
    ) {
      findCompanyContests(
        filter: {
          contestIds: $contestIds
          contestType: $contestType
          isDraft: $isDraft
          interval: $interval
          activeBetweenYYYMM: $activeBetweenYYYMM
          startsBeforeYYYYMM: $startsBeforeYYYYMM
          endsBeforeYYYYMM: $endsBeforeYYYYMM
          sortBy: $sortBy
          sortDirection: $sortDirection
        }
      ) {
        ${CONTEST_FIELDS}
        ${includeUserPoints ? CONTEST_POINT_FIELDS : ''}
      }
    }
  ` as TypedDocumentNode<{ findCompanyContests: Contest[] }>;

export const getLeaderboardsQuery = (
  includeRankings?: boolean,
  includeUsers?: boolean,
) =>
  gql`
  query GET_LEADERBOARDS(
    $contestId: String!
    $companyId: String
    $rollup: String
  ) {
    leaderboards(
      contestId: $contestId
      companyId: $companyId
      rollup: $rollup
    ) {
      ${LEADERBOARD_FIELDS}
      ${
        includeRankings
          ? `
            contestRanking {
              userId
              contestTotal
              rank
              ${includeUsers ? LEADERBOARD_USER_FIELDS : ''}
            }`
          : ''
      }
    }
  }
` as TypedDocumentNode<{ leaderboards: Leaderboard[] }>;

export const GET_CONTEST_OPTIONS = gql`
  query GET_CONTEST_OPTIONS {
    contestOptions {
      pointCategories {
        id
        label
      }
      images {
        label
        imageFilename
        imageUrl
      }
    }
  }
` as TypedDocumentNode<{ contestOptions: ContestOptions }>;

export const OPT_OUT_OF_CONTEST = gql`
  mutation OPT_OUT_OF_CONTEST($contestId: String!, $optOut: Boolean!) {
    optOutOfContest(contestId: $contestId, optOut: $optOut)
  }
` as TypedDocumentNode<{ optOutOfContest: boolean }>;

export const UPSERT_CONTEST = gql`
  mutation UPSERT_CONTEST($contest: ContestInput!, $companyId: String) {
    upsertContest(contest: $contest, companyId: $companyId) {
      error
    }
  }
` as TypedDocumentNode<{ upsertContest: { error: string; contest: Contest } }>;
