import React from 'react';
import MemberLayout from '../../../components/layouts/MemberLayout';
import ChallengeList from '../../../components/member/contest/ChallengeList';

const MemberChallenges: React.FC = () => (
  <MemberLayout>
    <ChallengeList />
  </MemberLayout>
);

export default MemberChallenges;
