import { useQuery } from '@apollo/client';
import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import { Company } from '../../../types/company/types';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconDiamond from '../../common/Project/CategoryIconDiamond';

export type Props = {
  categoryIds: Company['categoryIds'];
  noCategoriesText?: string;
};

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px',
    height: '100%',
    background: '(0,0,0,1)',
    backgroundSize: 'cover',
    backgroundBlendMode: 'luminosity',
  },
  label: {
    color: '#fff',
  },
  emptyStateLabel: {
    textAlign: 'center',
  },
}));

/**
 * Renders a list of project category cards.
 */
const CategoryCards: React.FC<Props> = ({
  categoryIds,
  noCategoriesText: noCatgoriesText,
}) => {
  const classes = useStyles();
  const { loading: categoriesLoading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const filteredCategories = categoryIds?.map((cId) =>
    projectCategories.find((pc) => pc.id === cId),
  );

  return (
    <DataStateHandler loading={categoriesLoading}>
      {(filteredCategories?.length && (
        <Grid container spacing={2} alignItems="stretch">
          {filteredCategories.map(
            (category) =>
              category && (
                <Grid key={category.id} item xs={3}>
                  <Paper
                    className={classes.card}
                    style={{
                      backgroundImage: `url(${category.backgroundImageUri})`,
                      backgroundColor: category.color,
                    }}>
                    <CategoryIconDiamond
                      categoryId={category.id}
                      size="small"
                    />
                    <Typography variant="h3" className={classes.label}>
                      {category.label}
                    </Typography>
                  </Paper>
                </Grid>
              ),
          )}
        </Grid>
      )) || (
        <Typography variant="h2" className={classes.emptyStateLabel}>
          {noCatgoriesText ||
            `This client currently does not have any Categories of Interest selected.`}
        </Typography>
      )}
    </DataStateHandler>
  );
};

export default CategoryCards;
