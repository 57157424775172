import { gql, TypedDocumentNode } from '@apollo/client';
import { CompanyGoal, MutationCompanyGoal } from '../../types/company/types';
import {
  COMPANY_BUDGET_SETTINGS_FIELDS,
  COMPANY_GOALS_FIELDS,
  COMPANY_SUBSCRIPTION_FIELDS,
  generateCompanyFields,
} from './company';

export const UPSERT_COMPANY_MUTATION = gql`
  mutation upsertCompany($company: CompanyInput!, $companyId: String) {
    company: upsertCompany(company: $company, companyId: $companyId) {
      ${generateCompanyFields({
        includePlatformConfig: true,
        includeScore: true,
        includeEmployeeMetrics: true,
        includeSubscriptionConfig: true,
      })}
    }
  }
`;

export const UPDATE_COMPANY_PLATFORM_DATA_MUTATION = gql`
  mutation updateCompanyPlatformData($company: CompanyPlatformInput!, $companyId: String) {
    company: updateCompanyPlatformData(company: $company, companyId: $companyId) {
      ${generateCompanyFields({
        includePlatformConfig: true,
        includeScore: true,
        includeEmployeeMetrics: true,
        includeSubscriptionConfig: true,
      })}
    }
  }
`;

export const UPSERT_COMPANY_BUDGET_SETTINGS_MUTATION = gql`
  mutation upsertBudgetSettings($settings: BudgetSettingsInput!, $companyId: String!) {
    upsertBudgetSettings(settings: $settings, companyId: $companyId) {
      ${COMPANY_BUDGET_SETTINGS_FIELDS}
    }
  }
`;

export const UPSERT_COMPANY_GOAL_MUTATION = gql`
  mutation upsertGoal($goal: CompanyGoalInput!, $goalId: String) {
    upsertGoal(goal: $goal, goalId: $goalId) {
      ${COMPANY_GOALS_FIELDS}
    }
  }
` as TypedDocumentNode<
  { upsertGoal: CompanyGoal },
  { goal: MutationCompanyGoal; goalId?: CompanyGoal['id'] }
>;

export const DELETE_COMPANY_GOAL_MUTATION = gql`
  mutation deleteGoal($goalId: String!, $companyId: String!) {
    deleteGoal(goalId: $goalId, companyId: $companyId)
  }
` as TypedDocumentNode<
  never,
  {
    goalId: string;
    companyId: string;
  }
>;

export const UPDATE_COMPANY_GOAL_ORDER_MUTATION = gql`
  mutation updateGoalOrder($companyId: String!, $goalIds: [String]!) {
    updateGoalOrder(companyId: $companyId, goalIds: $goalIds)
  }
` as TypedDocumentNode<{
  companyId: string;
  goalIds: string[];
}>;

export const UPDATE_COMPANY_CONTACTS_MUTATION = gql`
  mutation updateCompanyContacts(
    $type: CompanyContactType!
    $companyId: String!
    $userIds: [String]!
  ) {
    updateCompanyContacts(type: $type, companyId: $companyId, userIds: $userIds)
  }
`;

export const UPSERT_SUBSCRIPTION = gql`
  mutation upsertSubscription(
    $companyId: String!
    $input: CompanySubscriptionInput!
  ) {
    upsertSubscription(companyId: $companyId, input: $input) {
      ${COMPANY_SUBSCRIPTION_FIELDS}
    }
  }
`;

export const UPSERT_CREDIT_HISTORY = gql`
  mutation upsertCreditHistory(
    $companyId: String!
    $input: CompanyCreditHistoryInput!
  ) {
    upsertCreditHistory(companyId: $companyId, input: $input) {
      id
    }
  }
`;

export const UPSERT_ADDITIONAL_ALLOTMENT = gql`
  mutation upsertAdditionalAllotment(
    $companyId: String!
    $subscriptionId: String!
    $input: CompanySubscriptionAdditionalAllotmentInput!
  ) {
    upsertAdditionalAllotment(
      companyId: $companyId
      subscriptionId: $subscriptionId
      input: $input
    ) {
      id
    }
  }
`;
