import {
  Box,
  ButtonBase,
  Checkbox,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Waypoint } from 'react-waypoint';
import SVG from '../../../assets/svg';
import arrayUtils, { EMPTY_ARRAY } from '../../../utils/arrayUtils';
import StickyCell from './StickyCell';
import { SelectTableRow, SelectTableRowId } from './types';

export const ROW_HEIGHT = 55;
export const DEFAULT_STICKY_LEFT = 38;
export const HIDDEN_STICKY_LEFT = -1000;

const useStyles = makeStyles((theme) => ({
  tableRoot: {
    borderCollapse: 'separate',
  },
  header: {
    background: theme.palette.background.paper,
    border: 0,
  },
  checkbox: {
    zIndex: 1,
    background: theme.palette.background.paper,
    width: ROW_HEIGHT,
  },
  row: {
    height: ROW_HEIGHT,
  },
  checkboxWithChildren: { marginLeft: 8 },
  collapseArrow: { position: 'absolute', top: 35, paddingLeft: 8 },
  indentChild: { paddingLeft: '2rem' },
  loading: {
    maxHeight: 100,
    height: 100,
  },
}));

function SelectTableInner<TData extends SelectTableRowId>(
  {
    headerCells = [],
    rows = [],
    tableTitle = '',
    onRowClick,
    groupHeaderCells = [],
    childRowSelector = true,
    loadMoreRows,
    hasMoreRows = false,
    onSelected,
    isLoadingMoreRows = false,
    onRowHover,
    onRowHoverOut,
    hideStickyCol = false,
    rowStyle = {},
    initialSelected = EMPTY_ARRAY as Required<
      SelectTableProps<TData>
    >['initialSelected'],
    allSelectedBannerText,
    errorText,
  }: SelectTableProps<TData>,
  ref: Ref<unknown>,
) {
  const classes = useStyles();
  const [selected, setSelected] = useState(initialSelected);
  useEffect(() => {
    setSelected(initialSelected || []);
  }, [initialSelected]);
  const [showingChildren, setShowingChildren] = useState<
    Array<string | number>
  >([]);

  const isRowSelected = (id: string | number) =>
    selected.findIndex((x) => x.id === id) !== -1;
  const numSelected = selected.length;

  // sum all parent rows and all of their children rows
  const rowCount =
    rows.reduce(
      (accum, row) => accum + (row.children ? row.children.length : 0),
      0,
    ) + rows.length;
  const [showSelectAllBanner, setShowSelectAllBanner] = useState(
    numSelected === rowCount,
  );
  useEffect(() => {
    setShowSelectAllBanner(!!allSelectedBannerText && numSelected === rowCount);
  }, [allSelectedBannerText, numSelected, rowCount]);

  const updateSelected = (selectedRows: SelectTableRowId[]) => {
    setSelected(selectedRows);
    if (onSelected) {
      onSelected(selectedRows, {
        allSelected: selectedRows.length === rowCount,
      });
    }
  };

  const handleSelectAllClick = (e: unknown, checked: boolean) => {
    if (checked) {
      // selected contains all rows' data, including children rows,
      // so we use a reducer to get a map of all child rows and combine that
      // with the parent rows
      const newSelected = [
        ...rows.map((row) => ({ id: row.id })),
        ...rows.flatMap((row) =>
          row.children ? row.children.map((c) => ({ id: c.id })) : [],
        ),
      ];
      updateSelected(newSelected);
      return;
    }
    updateSelected([]);
  };

  const handleCheckRow = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    data: SelectTableRow<TData>,
  ) => {
    event.stopPropagation();
    const newSelected = arrayUtils.addOrRemoveById(selected, data);
    updateSelected(newSelected);
  };

  const createGroupHeader = () => (
    <TableRow className={classes.header}>{groupHeaderCells}</TableRow>
  );

  const toggleChildren = (id: string | number) => {
    const copy = [...showingChildren];
    arrayUtils.addOrRemove(copy, id);
    setShowingChildren(copy);
  };

  const isShowingChildren = (row: SelectTableRow<TData>) =>
    row.children && row.children.length > 0 && showingChildren.includes(row.id);

  const hasGroupHeaders = !!(groupHeaderCells || []).length;
  const hasExpandibleChildren = !!rows.find(
    (r) => r.children && r.children.length > 0,
  );

  const handleWaypointEnter = () => {
    if (loadMoreRows && hasMoreRows) {
      loadMoreRows();
    }
  };

  const allRowsAreSelected = () => numSelected === rowCount;

  useImperativeHandle(ref, () => ({
    clearSelectedItems() {
      setSelected([]);
    },
  }));

  return (
    <Table
      aria-labelledby={tableTitle}
      padding="none"
      className={classes.tableRoot}>
      <TableHead>
        {hasGroupHeaders && createGroupHeader()}
        <TableRow className={classes.header}>
          {!hideStickyCol && (
            <StickyCell
              containerClassName={`${classes.header} ${
                !childRowSelector ? classes.indentChild : ''
              }`}
              containerStyle={{
                top: hasGroupHeaders ? ROW_HEIGHT : 0,
              }}
              position="both">
              <Checkbox
                className={
                  hasExpandibleChildren ? classes.checkboxWithChildren : ''
                }
                size="small"
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={allRowsAreSelected()}
                onChange={handleSelectAllClick}
                data-testid="SelectTable.SelectAllCheckbox"
              />
            </StickyCell>
          )}

          {headerCells}
        </TableRow>
        {errorText && (
          <TableRow>
            <StickyCell
              colSpan={headerCells.length + 1}
              containerStyle={{
                top: ROW_HEIGHT + (hasGroupHeaders ? ROW_HEIGHT : 0),
              }}>
              <Box
                bgcolor="error.main"
                color="primary.light"
                width="100%"
                p={2}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    container
                    justifyContent="center"
                    style={{ flex: 1 }}>
                    <Typography variant="body1" color="inherit">
                      {errorText}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </StickyCell>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {showSelectAllBanner && allSelectedBannerText && (
          <TableRow>
            <TableCell colSpan={headerCells.length + 1}>
              <Box bgcolor="backgrounds.light" width="100%" p={2}>
                <Grid container alignItems="center">
                  <Grid
                    item
                    container
                    justifyContent="center"
                    style={{ flex: 1 }}>
                    <Typography variant="body1">
                      {typeof allSelectedBannerText === 'function'
                        ? allSelectedBannerText(rowCount)
                        : allSelectedBannerText}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SVG.Close onClick={() => setShowSelectAllBanner(false)} />
                  </Grid>
                </Grid>
              </Box>
            </TableCell>
          </TableRow>
        )}
        {rows.map((row) => {
          const isSelected = isRowSelected(row.id);

          return (
            <React.Fragment key={row.id}>
              <TableRow
                data-testid="select-table-row"
                style={{ cursor: onRowClick ? 'pointer' : '', ...rowStyle }}
                className={classes.row}
                onClick={
                  onRowClick
                    ? () => {
                        onRowClick(row.data);
                      }
                    : undefined
                }
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                selected={isSelected}
                onMouseEnter={
                  onRowHover ? (e) => onRowHover(e, row) : undefined
                }
                onMouseLeave={
                  onRowHoverOut ? (e) => onRowHoverOut(e, row) : undefined
                }>
                {!hideStickyCol && (
                  <StickyCell
                    position="left"
                    containerClassName={classes.checkbox}>
                    <Checkbox
                      className={
                        hasExpandibleChildren
                          ? classes.checkboxWithChildren
                          : ''
                      }
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        handleCheckRow(e, row);
                      }}
                      checked={isSelected}
                      data-testid="SelectTable.Checkbox"
                    />
                    {row.children && row.children.length > 0 && (
                      <ButtonBase
                        className={classes.collapseArrow}
                        onClick={(e) => {
                          toggleChildren(row.id);
                          e.stopPropagation();
                        }}>
                        (isShowingChildren(row) ? <SVG.ArrowDown /> :{' '}
                        <SVG.ArrowUp />)
                      </ButtonBase>
                    )}
                  </StickyCell>
                )}

                {row.node}
              </TableRow>
              {isShowingChildren(row) && row.children && (
                <>
                  {row.children.map((rowChild) => {
                    const isChildSelected = isRowSelected(rowChild.id);
                    return (
                      <TableRow
                        key={rowChild.id}
                        hover
                        onClick={
                          onRowClick
                            ? () => {
                                if (!rowChild.isReadOnly) {
                                  onRowClick(rowChild.data);
                                }
                              }
                            : undefined
                        }
                        role="checkbox"
                        aria-checked={isChildSelected}
                        tabIndex={-1}
                        selected={isChildSelected}>
                        {!hideStickyCol && (
                          <StickyCell
                            position="left"
                            containerClassName={
                              !rowChild.isReadOnly ? classes.checkbox : ''
                            }>
                            {childRowSelector && (
                              <Checkbox
                                className={
                                  hasExpandibleChildren
                                    ? classes.checkboxWithChildren
                                    : ''
                                }
                                size="small"
                                color="primary"
                                onClick={(e) => {
                                  handleCheckRow(e, rowChild);
                                }}
                                checked={isChildSelected}
                                data-testid="SelectTable.Checkbox"
                              />
                            )}
                          </StickyCell>
                        )}
                        {rowChild.node}
                      </TableRow>
                    );
                  })}
                </>
              )}
            </React.Fragment>
          );
        })}
        <tr>
          <td>
            <Waypoint onEnter={handleWaypointEnter} />
          </td>
        </tr>
        {(isLoadingMoreRows || hasMoreRows) && (
          <TableRow>
            <TableCell colSpan={100} align="center">
              <CircularProgress className={classes.loading} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

const SelectTable = forwardRef(SelectTableInner);

export type SelectTableProps<TData extends SelectTableRowId> = {
  tableTitle?: string;
  groupHeaderCells?: React.ReactNode[];
  onSelected?: (
    selectedRows: Pick<TData, 'id'>[],
    params: { allSelected: boolean },
  ) => void;
  onRowClick?: (data: TData) => void;
  childRowSelector?: boolean;
  loadMoreRows?: () => void;
  hasMoreRows?: boolean;
  headerCells?: React.ReactNode[];
  rows?: SelectTableRow<TData>[];
  isLoadingMoreRows?: boolean;
  onRowHover?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: SelectTableRow<TData>,
  ) => void;
  onRowHoverOut?: (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: SelectTableRow<TData>,
  ) => void;
  hideStickyCol?: boolean;
  rowStyle?: React.CSSProperties;
  initialSelected?: readonly {
    id: string | number;
  }[];
  allSelectedBannerText?: string | ((count: number) => string);
  errorText?: string;
};

export default SelectTable as <T extends SelectTableRowId>(
  props: SelectTableProps<T> & { ref?: React.ForwardedRef<HTMLTableElement> },
) => ReturnType<typeof SelectTable>;
