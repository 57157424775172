import React from 'react';
import HabitsContainer from '../../components/habits/HabitsContainer';
import MainLayout from '../../components/layouts/MainLayout';

const Habits = () => (
  <MainLayout childBoxStyle={{ paddingTop: 36 }}>
    <HabitsContainer />
  </MainLayout>
);

export default Habits;
