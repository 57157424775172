import { Button, Grid, Typography, Drawer, Box, Alert } from '@mui/material';
import React, { useContext, useState } from 'react';

import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import useSettingsStyles from './useSettingsStyles';
import CompanySettingsModalInput from '../../components/settings/CompanySettingsModalInput';
import ActiveSubscriptionCard from '../../components/settings/subscriptions/ActiveSubscriptionCard';
import SubscriptionsList from '../../components/settings/subscriptions/SubscriptionsList';
import SubscriptionCreditHistory from '../../components/settings/subscriptions/SubscriptionCreditHistory';
import { CompanySubscription } from '../../types/company/types';
import { formatDateStringUtc } from '../../utils/dateUtils';

export const subscriptionDateRangeDisplay = (
  subscription: CompanySubscription,
): string =>
  `${formatDateStringUtc(
    subscription.startDate,
    'M/D/yyyy',
  )} - ${formatDateStringUtc(subscription.endDate, 'M/D/yyyy')}`;

const SubscriptionSettings: React.FC = () => {
  const { company, loading, isInheritingSubscriptionData } = useContext(
    CompanySettingsContext,
  );
  const settingsClasses = useSettingsStyles();
  const [panelOpen, setPanelOpen] = useState(false);

  return (
    <DataStateHandler loading={loading}>
      <>
        <Grid container direction="column">
          {isInheritingSubscriptionData ? (
            <Grid item sx={{ pb: 4 }}>
              <Alert severity="warning">
                This organization&apos;s subscription is managed by it&apos;s
                parent organization and cannot be edited. Create a new
                subscription to have this organization manage their own
                subscription settings.
              </Alert>
            </Grid>
          ) : null}
          <Grid
            item
            container
            direction="row"
            className={settingsClasses.group}>
            <Grid item xs={3}>
              <div className={settingsClasses.leftCol}>
                <Typography variant="h3">Subscription Details</Typography>
              </div>
            </Grid>
            <Grid item container direction="column" xs={9}>
              <ActiveSubscriptionCard
                activeSubscription={
                  company.subscriptionData?.currentSubscription
                }
                creditBalance={company.subscriptionData?.creditBalance}
              />
              <Grid item container justify-content="flex-start">
                <Button
                  onClick={() => setPanelOpen(true)}
                  size="small"
                  disabled={
                    !company.subscriptionData?.subscriptionHistory.length
                  }>
                  View Subscriptions
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            className={settingsClasses.group}>
            <Grid item xs={3}>
              <div className={settingsClasses.leftCol}>
                <Typography variant="h3">
                  Subscription Credit History
                </Typography>
              </div>
            </Grid>
            <Grid item container direction="column" xs={9}>
              <SubscriptionCreditHistory />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            className={settingsClasses.group}>
            <Grid item xs={3}>
              <div className={settingsClasses.leftCol}>
                <Typography variant="h3">Account Setup</Typography>
              </div>
            </Grid>
            <Grid item container direction="column" xs={9}>
              <CompanySettingsModalInput fieldName="clientSecretExpirationDate" />
            </Grid>
          </Grid>
        </Grid>
        <Drawer
          open={panelOpen}
          onClose={() => setPanelOpen(false)}
          anchor="right">
          <Box>
            <SubscriptionsList onClose={() => setPanelOpen(false)} />
          </Box>
        </Drawer>
      </>
    </DataStateHandler>
  );
};

export default SubscriptionSettings;
