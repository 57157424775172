import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import EmissionsBenchmarkChart from './EmissionsBenchmarkChart';
import AppInfo from '../../common/AppInfo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import MultiCarousel from '../../common/MultiCarousel';
import useEmissionsBenchmarkData from '../../../hooks/useEmissionsBenchmarkData';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    margin: '64px 0',
  },
  header: {
    color: theme.palette.text.primary,
  },
  subHeader: {
    zIndex: 1,
    marginBottom: 24,
    justifyContent: 'space-between',
  },
  divider: {
    marginTop: 48,
    marginBottom: 32,
  },
}));

const EmissionsBenchmarks: React.FC = () => {
  const classes = useStyles();

  const {
    chartData: { totalGeographical, totalOrganizational, categoryBreakdown },
    loading,
    errors,
  } = useEmissionsBenchmarkData();

  return (
    <DataStateHandler loading={loading} error={errors?.[0]}>
      <Grid container className={classes.container} spacing={2}>
        <Typography className={classes.header} variant="h3">
          {t('benchmarking.emissionsBenchmarks')}
        </Typography>
        <Grid container item>
          <Grid container item className={classes.subHeader}>
            <Typography variant="subtitle1" color={Colors.TertiaryText}>
              {t('benchmarking.annualFootprintProjection')}
            </Typography>
            <AppInfo id="annual_footprint_projection" />
          </Grid>
          <Grid container item spacing={6}>
            <Grid container item xs={6}>
              <EmissionsBenchmarkChart
                data={totalGeographical.data}
                label={totalGeographical.label}
              />
            </Grid>
            <Grid container item xs={6}>
              <EmissionsBenchmarkChart
                data={totalOrganizational.data}
                label={totalOrganizational.label}
                horizontal
              />
            </Grid>
          </Grid>
        </Grid>
        <Divider className={classes.divider} flexItem />
        <Grid container item>
          <Grid container item className={classes.subHeader}>
            <Typography variant="subtitle1" color={Colors.TertiaryText}>
              {t('benchmarking.emissionsCategoryBreakdown')}
            </Typography>
            <AppInfo id="emissions_category_breakdown" />
          </Grid>
          <MultiCarousel
            itemsPerSlide={2}
            spacing={48}
            items={Object.values(categoryBreakdown).map((cb) => ({
              id: cb.label,
              renderItem: (
                <EmissionsBenchmarkChart
                  key={cb.label}
                  data={cb.data}
                  label={cb.label}
                />
              ),
            }))}
          />
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default EmissionsBenchmarks;
