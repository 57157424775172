import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext, getIn, FieldArray, Field } from 'formik';
import { orderBy } from 'lodash';
import {
  Grid,
  Typography,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { HabitImpactValue, HabitListItem } from '../../types/habit/types';
import SVG from '../../assets/svg';
import { QuestionProfile } from '../../types/question/types';
import HabitCondition from './HabitCondtition';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import AppTextField from '../common/AppTextField';
import HabitNestedContainer from './HabitNestedContainer';
import {
  EMISSION_CATEGORY_OPTIONS,
  HABIT_UOM,
} from '../../graphql/habit/habits';

const useStyles = makeStyles(() => ({
  fieldArraySection: {
    paddingTop: 16,
  },
  questionRow: {
    marginBottom: 16,
  },
  field: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
}));

type Props = {
  onRemove?: () => void;
  canEdit: boolean;
  path: string;
  questions: QuestionProfile[];
  habits: HabitListItem[];
  depth?: number;
  showMultipleValuesOption: boolean; // Show the 'Multiple Values' as a dropdown list option?
};

const HabitImpactValueComponent: React.FC<Props> = ({
  onRemove,
  canEdit,
  path,
  questions,
  habits,
  depth = 0,
  showMultipleValuesOption,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const impactValue = getIn(values, path) as HabitImpactValue;

  // Units of measure to populate the dropdown list
  const { data: { habitUoms = [] } = {} } = useQuery(HABIT_UOM);

  // Options for static emission types
  const { data: { emissionCategoryOptions = {} } = {} } = useQuery(
    EMISSION_CATEGORY_OPTIONS,
  );

  // Set the UOM for display
  const [chosenUom, setChosenUom] = useState('');

  const classes = useStyles();

  // Hard coding for now, only one profile has calc nums...
  const calcNumOptions = (profileId?: string) =>
    profileId === 'f9bc9459-9179-42eb-8a96-b23b9fa7ae8c'
      ? [
          {
            label: `1 - Heat`,
            value: 1,
          },
          {
            label: `2 - Cooling`,
            value: 2,
          },
          {
            label: `3 - Water Heating`,
            value: 3,
          },
          {
            label: `4 - Cooking`,
            value: 4,
          },
          {
            label: `5 - Appliances`,
            value: 5,
          },
        ]
      : [
          {
            label: `1 - Default`,
            value: 1,
          },
        ];

  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-between"
      alignItems="center">
      <Grid item xs={onRemove ? 6 : 12}>
        <Typography variant="body2">Value Type</Typography>
        <TextField
          label=""
          fullWidth
          className={classes.field}
          onChange={(e: React.ChangeEvent<{ value: string }>) => {
            const type = e.target.value;

            if (type) {
              setFieldValue(path, { type });
            } else {
              setFieldValue(path, onRemove ? {} : undefined);
            }
          }}
          value={impactValue?.type || ''}
          select>
          <MenuItem key="" value="">
            Select a Type
          </MenuItem>
          <MenuItem key="static" value="static">
            Static
          </MenuItem>
          <MenuItem key="conditional" value="conditional">
            Conditional
          </MenuItem>
          <MenuItem key="combineValues" value="combineValues">
            Combine Values
          </MenuItem>
          {showMultipleValuesOption && (
            <MenuItem key="multipleValues" value="multipleValues">
              Multiple Values
            </MenuItem>
          )}
          <MenuItem key="emissionSourceProfile" value="emissionSourceProfile">
            Emission Source Profile
          </MenuItem>
          <MenuItem
            key="emissionSourceProfileReplace"
            value="emissionSourceProfileReplace">
            Emission Source Profile Replacement
          </MenuItem>
        </TextField>
      </Grid>
      {!!onRemove && (
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <IconButton onClick={onRemove} size="large">
              <SVG.Delete />
            </IconButton>
          </Grid>
        </Grid>
      )}
      {impactValue?.type === 'static' ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">Unit of Measure</Typography>
            <TextField
              label=""
              name={`${path}.uom`}
              fullWidth
              disabled={!canEdit}
              className={classes.field}
              onChange={(e: React.ChangeEvent<{ value: string }>) => {
                const uom = e.target.value;
                // the server schema does not allow empty string as a unit of measure
                // if we don't have a UOM we need to set the field value to undefined so the schema passes
                setFieldValue(`${path}.uom`, uom || undefined);

                // Store the UOM label for display
                const habitUom = habitUoms.find((u) => u.id === uom);
                setChosenUom(` in ${habitUom?.label}` || '');
              }}
              value={impactValue?.uom || ''}
              select>
              <MenuItem key="" value="">
                Select a UOM
              </MenuItem>
              {habitUoms.map((uom) => (
                <MenuItem key={uom.id} value={uom.id}>
                  {uom.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Impact Value</Typography>
            <Field
              name={`${path}.value`}
              label=""
              component={AppTextField}
              disabled={!canEdit}
              className={classes.field}
              placeholder={`Impact Value${chosenUom}`}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Emissions Type</Typography>
            <TextField
              label=""
              name={`${path}.resultType`}
              fullWidth
              disabled={!canEdit}
              className={classes.field}
              onChange={(e: React.ChangeEvent<{ value: string }>) => {
                const type = e.target.value;
                // the server schema does not allow empty string
                setFieldValue(`${path}.resultType`, type || undefined);
                setFieldValue(`${path}.resultSubType`, undefined);
                setFieldValue(`${path}.resultPurposeType`, undefined);
              }}
              value={impactValue?.resultType || ''}
              select>
              <MenuItem key="" value="">
                &nbsp;
              </MenuItem>
              {Object.keys(emissionCategoryOptions).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Emissions Sub Type</Typography>
            <TextField
              label=""
              name={`${path}.resultSubType`}
              fullWidth
              disabled={!canEdit}
              className={classes.field}
              onChange={(e: React.ChangeEvent<{ value: string }>) => {
                const type = e.target.value;
                // the server schema does not allow empty string
                setFieldValue(`${path}.resultSubType`, type || undefined);
                setFieldValue(`${path}.resultPurposeType`, undefined);
              }}
              value={impactValue?.resultSubType || ''}
              select>
              <MenuItem key="" value="">
                &nbsp;
              </MenuItem>
              {Object.keys(
                emissionCategoryOptions[impactValue.resultType || ''] || [],
              ).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Emissions Purpose Type</Typography>
            <TextField
              label=""
              name={`${path}.resultPurposeType`}
              fullWidth
              disabled={!canEdit}
              className={classes.field}
              onChange={(e: React.ChangeEvent<{ value: string }>) => {
                const type = e.target.value;
                // the server schema does not allow empty string
                setFieldValue(`${path}.resultPurposeType`, type || undefined);
              }}
              value={impactValue?.resultPurposeType || ''}
              select>
              <MenuItem key="" value="">
                &nbsp;
              </MenuItem>
              {(
                emissionCategoryOptions[impactValue.resultType || '']?.[
                  impactValue.resultSubType || ''
                ] || []
              ).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </>
      ) : null}
      {impactValue?.type === 'emissionSourceProfile' ||
      impactValue?.type === 'emissionSourceProfileReplace' ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">Emissions Source Profile</Typography>
            <Field
              label=""
              component={AppTextField}
              fullWidth
              className={classes.field}
              select
              name={`${path}.profileId`}
              options={orderBy(
                questions.map((q) => ({
                  label: `${q.questionTreeCategory} - ${q.title}`,
                  value: q.id,
                })),
                (x) => x.label,
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Calc Num</Typography>
            <Field
              label=""
              component={AppTextField}
              fullWidth
              className={classes.field}
              select
              name={`${path}.calcNum`}
              options={calcNumOptions(impactValue?.profileId)}
            />
          </Grid>
        </>
      ) : null}
      {impactValue?.type === 'emissionSourceProfileReplace' ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">
              Replace With Emissions Source Profile
            </Typography>
            <Field
              label=""
              component={AppTextField}
              fullWidth
              className={classes.field}
              select
              name={`${path}.replacementProfileId`}
              options={orderBy(
                questions.map((q) => ({
                  label: `${q.questionTreeCategory} - ${q.title}`,
                  value: q.id,
                })),
                (x) => x.label,
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Replace With Calc Num</Typography>
            <Field
              label=""
              component={AppTextField}
              fullWidth
              className={classes.field}
              select
              name={`${path}.replacementCalcNum`}
              options={calcNumOptions(impactValue?.replacementProfileId)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Replacement Input Key</Typography>
            <Field
              name={`${path}.replacementInputKey`}
              label=""
              component={AppTextField}
              disabled={!canEdit}
              className={classes.field}
              fullWidth
            />
          </Grid>
        </>
      ) : null}
      {impactValue?.type === 'combineValues' ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">Combination Operation</Typography>
            <TextField
              label=""
              fullWidth
              className={classes.field}
              onChange={(e: React.ChangeEvent<{ value: string }>) => {
                setFieldValue(`${path}.operation`, e.target.value);
              }}
              value={impactValue?.operation || ''}
              select>
              <MenuItem key="" value="">
                Select an Operation
              </MenuItem>
              <MenuItem key="add" value="add">
                Add
              </MenuItem>
              <MenuItem key="multiply" value="multiply">
                Multiply
              </MenuItem>
            </TextField>
          </Grid>
          <FieldArray
            name={`${path}.values`}
            render={(arrayHelpers) => (
              <Grid item container xs={12}>
                {impactValue?.type === 'combineValues' &&
                  impactValue?.values?.map((c, index) => (
                    <HabitNestedContainer
                      title={`Value ${index + 1}`}
                      depth={depth}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}>
                      <Grid item xs={12}>
                        <HabitImpactValueComponent
                          path={`${path}.values[${index}]`}
                          onRemove={() => {
                            arrayHelpers.remove(index);
                          }}
                          canEdit={canEdit}
                          questions={questions}
                          habits={habits}
                          depth={depth + 1}
                          showMultipleValuesOption={false}
                        />
                      </Grid>
                    </HabitNestedContainer>
                  ))}

                <Grid item xs={12}>
                  <SmallPaddedButton
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      arrayHelpers.push({ type: '' });
                    }}>
                    + Add Value
                  </SmallPaddedButton>
                </Grid>
              </Grid>
            )}
          />
        </>
      ) : null}
      {impactValue?.type === 'multipleValues' ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body2">Multiple Impacts Operation</Typography>
          </Grid>
          <FieldArray
            name={`${path}.values`}
            render={(arrayHelpers) => (
              <Grid item container xs={12}>
                {impactValue?.type === 'multipleValues' &&
                  impactValue?.values?.map((c, index) => (
                    <HabitNestedContainer
                      title={`Value ${index + 1}`}
                      depth={depth}
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}>
                      <Grid item xs={12}>
                        <HabitImpactValueComponent
                          path={`${path}.values[${index}]`}
                          onRemove={() => {
                            arrayHelpers.remove(index);
                          }}
                          canEdit={canEdit}
                          questions={questions}
                          habits={habits}
                          depth={depth + 1}
                          showMultipleValuesOption={false}
                        />
                      </Grid>
                    </HabitNestedContainer>
                  ))}

                <Grid item xs={12}>
                  <SmallPaddedButton
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      arrayHelpers.push({ type: '' });
                    }}>
                    + Add Value
                  </SmallPaddedButton>
                </Grid>
              </Grid>
            )}
          />
        </>
      ) : null}
      {impactValue?.type === 'conditional' ? (
        <Grid
          item
          container
          justifyContent="space-between"
          spacing={2}
          className={classes.questionRow}
          alignItems="flex-end">
          <FieldArray
            name={`${path}.conditions`}
            render={(arrayHelpers) => (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2">Conditions</Typography>
                </Grid>
                {impactValue?.type === 'conditional' &&
                  impactValue?.conditions?.map((cond, index) => (
                    <Grid
                      item
                      container
                      xs={12} // eslint-disable-next-line react/no-array-index-key
                      key={index}>
                      <HabitNestedContainer
                        title={`Impact Condition ${index + 1}`}
                        depth={depth}>
                        <Grid item xs={12}>
                          <HabitCondition
                            path={`${path}.conditions[${index}].condition`}
                            onRemove={() => arrayHelpers.remove(index)}
                            questions={questions}
                            habits={habits}
                            depth={depth + 1}
                            canEdit={canEdit}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <HabitImpactValueComponent
                            canEdit={canEdit}
                            questions={questions}
                            habits={habits}
                            path={`${path}.conditions[${index}].value`}
                            showMultipleValuesOption={false}
                          />
                        </Grid>
                      </HabitNestedContainer>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <SmallPaddedButton
                    fullWidth
                    onClick={() => arrayHelpers.push({})}>
                    + Add Condition
                  </SmallPaddedButton>
                </Grid>
              </>
            )}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default HabitImpactValueComponent;
