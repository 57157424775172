import React from 'react';
import { FieldProps, getIn } from 'formik';
import {
  Input,
  ListItemText,
  MenuItem,
  Select,
  SelectProps,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../assets/svg';

type Props = {
  data: {
    value: string;
    label: React.ReactNode;
  }[];
  label: React.ReactNode;
};

const useStyles = makeStyles((theme) => ({
  rootFc: {
    width: '100%',
  },
  rootSelect: {
    width: '100%',
    background: 'white',
    padding: '12px 9px',
    marginRight: '16px',
    border: '2px solid #BDD9C5',
    borderRadius: 6,
  },
  bullseye: {
    lineHeight: '17px',
    verticalAlign: 'bottom',
  },
  checkbox: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const AppMultiSelectCheckbox: React.FC<FieldProps & SelectProps & Props> = ({
  field,
  form,
  error,
  data,
  label,
  ...rest
}) => {
  const classes = useStyles();
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  return (
    <FormControl
      variant="standard"
      className={classes.rootFc}
      error={error ?? Boolean(isTouched && errorMessage)}>
      <Select
        label={label}
        className={classes.rootSelect}
        multiple
        input={<Input disableUnderline />}
        variant="outlined"
        renderValue={(selected) => (
          <span>
            <SVG.Marketplace.Bullseye className={classes.bullseye} />
            &nbsp;&nbsp;
            {(selected as string[]).length === 0
              ? label
              : (selected as string[]).join(', ')}
          </span>
        )}
        {...rest} // includes any Material-UI specific props
        {...field} // includes all props contributed by the Formik Field/FastField
      >
        {data.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            <Checkbox
              size="small"
              color="primary"
              checked={(field.value || []).indexOf(item.value) > -1}
            />
            <ListItemText primary={item.label} />
          </MenuItem>
        ))}
      </Select>
      {isTouched && errorMessage && (
        <FormHelperText>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default AppMultiSelectCheckbox;
