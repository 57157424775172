import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useMenuAnchor from '../../../hooks/useMenuAnchor';
import SVG from '../../../assets/svg';
import { Disableable } from '../../../types/common';

const useStyles = makeStyles(() => ({
  root: {
    marginLeft: 'auto',
  },
  button: {
    padding: 10,
    textOverflow: 'ellipsis',
  },
}));

type Props<T extends { id: string; name: string; level: number }> =
  Disableable & {
    items: T[];
    initialSelectedItems?: T[];
    onChange: (items: T[]) => void;
    allSelectedMessage?: string;
  };

// The core of this was copied from CompanyListItem.tsx, but made generic to accept any type of items
// eslint-disable-next-line import/prefer-default-export
export const MultiSelect = <
  T extends { id: string; name: string; level: number },
>({
  items,
  onChange,
  allSelectedMessage,
  disabled,
  initialSelectedItems,
}: Props<T>) => {
  const { onMenuClick, isMenuOpen, anchorEl, setAnchorEl } = useMenuAnchor();

  const [selectedItems, setSelectedItems] = React.useState<T[]>(
    initialSelectedItems ?? [],
  );

  const classes = useStyles({
    fullWidth: true,
  });

  const divisionLabel =
    selectedItems.length === items.length && allSelectedMessage
      ? allSelectedMessage
      : selectedItems.map((d) => d.name).join(', ');

  return items.length ? (
    <Grid item className={classes.root}>
      <Button
        className={classes.button}
        color="secondary"
        variant="contained"
        size="small"
        disabled={disabled}
        onClick={onMenuClick}
        endIcon={disabled ? null : <SVG.ArrowDown />}>
        {divisionLabel}
      </Button>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        keepMounted>
        {items.map((item) => (
          <MenuItem
            key={item.id}
            style={{ marginLeft: 8 * item.level }}
            onClick={() => {
              const newSelectedItems =
                (selectedItems || []).map((x) => x.id).indexOf(item.id) > -1
                  ? selectedItems.filter((c) => c.id !== item.id)
                  : [...selectedItems, item];
              setSelectedItems(newSelectedItems);
              onChange(newSelectedItems);
            }}>
            <Checkbox
              size="small"
              color="primary"
              checked={
                (selectedItems || []).map((x) => x.id).indexOf(item.id) > -1
              }
            />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  ) : null;
};
