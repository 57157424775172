import { Box } from '@mui/material';
import React from 'react';
import { CommunicationType } from './types';
import SVG from '../../assets/svg';

type Props = {
  communicationType: CommunicationType;
};

const CommunicationTypeChip: React.FC<Props> = ({ communicationType }) => {
  const typeData = {
    [CommunicationType.BLOG_POST]: {
      label: 'Article',
      icon: <SVG.Communications.Blog />,
    },
    [CommunicationType.AUDIO]: {
      label: 'Audio',
      icon: <SVG.Communications.Audio />,
    },
    [CommunicationType.VIDEO]: {
      label: 'Video',
      icon: <SVG.Communications.Video />,
    },
    [CommunicationType.HINT]: {
      label: 'Hints & Tips',
      icon: <SVG.Communications.Hint />,
    },
  }[communicationType];
  return (
    <Box
      component="span"
      ml="8px"
      fontSize="12px"
      p="8px"
      borderRadius="8px"
      bgcolor="#F1F8FD">
      {typeData.icon} {typeData.label}
    </Box>
  );
};

export default CommunicationTypeChip;
