import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { orderBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import SVG from '../../assets/svg';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { ALL_ROLES } from '../../graphql/auth';
import { RolesAndAbilities } from '../../types/user/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import HeaderTab from '../common/HeaderTabs/HeaderTab';
import HeaderTabs from '../common/HeaderTabs/HeaderTabs';

const RoleTableCell = withStyles(() => ({
  root: {
    border: 0,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: 16,
    paddingTop: 24,
    position: 'relative',
  },
  closeIcon: {
    position: 'relative',
    top: -5,
    color: theme.palette.text.primary,
  },
  leftCol: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  roleButton: {
    width: 160,
    height: 70,
    fontSize: 18,
  },
  oddRow: {
    backgroundColor: '#FAFAFA',
  },
  column: {
    textAlign: 'center',
  },
  selectedColumn: {
    backgroundColor: 'rgba(241, 248, 253, 0.5)',
  },
  footer: {
    padding: '16px 32px',
    borderTop: '1px solid #DADADA',
    marginTop: 16,
  },
  legend: {
    backgroundColor: '#F9F9F9',
    borderRadius: 4,
    padding: '16px 4px',
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: 600,
    '& span': {
      padding: '4px 16px',
      display: 'inline-block',
      '&:nth-Child(2)': {
        borderLeft: '1px solid #DADADA',
        borderRight: '1px solid #DADADA',
      },
    },
    '& svg': {
      position: 'relative',
      top: 3,
      marginRight: 8,
    },
  },
}));

type Props = {
  initialRole: string;
  modalOpen: boolean;
  onClose: (role?: string) => void;
  preventMemberAssignment?: boolean;
  preventMemberAssignmentReason?: string;
};

const RoleSelectionModal: React.FC<Props> = ({
  initialRole,
  modalOpen,
  onClose,
  preventMemberAssignment,
  preventMemberAssignmentReason,
}) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const [selectedRole, setSelectedRole] = useState<string>();
  const [showPrivateRoles, setShowPrivateRoles] = useState(false);
  const canEditPrivateRoles = userHasAccess('Api.AllowSetPrivateRoles', 'VIEW');
  const classes = useStyles();

  const { loading, data: { allRolesAndAbilities = {} } = {} } =
    useQuery(ALL_ROLES);

  if (!selectedRole && initialRole && selectedRole !== initialRole) {
    setSelectedRole(initialRole);
  }

  const { roles = [], abilities = [] } =
    allRolesAndAbilities as RolesAndAbilities;

  const sortedAbilities = orderBy(
    [...abilities].filter(
      (x) =>
        x.name !== 'ZeroMe.Unknown' &&
        (!x.private || (showPrivateRoles && x.private)),
    ),
    (x) => x.description,
  );

  const getIcon = (operation: string | undefined, active: boolean) => {
    if (operation === 'VIEW') {
      return <SVG.BoxCheck style={{ color: active ? '#032133' : '#819099' }} />;
    }

    if (operation === 'EDIT') {
      return <SVG.BoxEdit style={{ color: active ? '#3E7DA2' : '#9FBED1' }} />;
    }

    return <SVG.BoxX style={{ color: active ? '#F7BAC6' : '#F6DBE1' }} />;
  };

  const closeModal = (role?: string) => {
    setSelectedRole(undefined);
    onClose(role);
  };

  const validRoles = roles.filter((x) => showPrivateRoles === x.private);

  useEffect(() => {
    const currentRole = roles.find((x) => x.id === initialRole);

    if (currentRole?.private) {
      setShowPrivateRoles(true);
    }
  }, [loading, initialRole]);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={modalOpen}
      onClose={() => closeModal()}>
      <DataStateHandler loading={loading}>
        <Grid container direction="column" className={classes.dialog}>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            style={{ marginBottom: 8 }}>
            <Grid item>
              <Typography variant="h3">Update Permissions Role</Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.closeIcon}
                onClick={() => closeModal()}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
          </Grid>
          {canEditPrivateRoles && (
            <Grid item>
              <HeaderTabs value={showPrivateRoles.toString()}>
                <HeaderTab
                  value={false.toString()}
                  style={{ minWidth: 130 }}
                  onClick={() => setShowPrivateRoles(false)}
                  label="Client Roles"
                />
                <HeaderTab
                  value={true.toString()}
                  onClick={() => setShowPrivateRoles(true)}
                  label="ZM Roles"
                />
              </HeaderTabs>
            </Grid>
          )}
          <Grid item style={{ maxHeight: 600, overflowY: 'auto' }}>
            <Table>
              <TableHead
                style={{ position: 'sticky', top: 0, backgroundColor: '#fff' }}>
                <TableRow>
                  <RoleTableCell />
                  {validRoles.map((r) => (
                    <RoleTableCell
                      key={`header_${r.id}`}
                      style={{ borderRadius: 8 }}
                      className={`${
                        r.id === selectedRole ? classes.selectedColumn : ''
                      }`}>
                      <Grid container justifyContent="center">
                        <Button
                          variant={
                            selectedRole === r.id ? 'contained' : 'outlined'
                          }
                          size="small"
                          color="primary"
                          className={classes.roleButton}
                          onClick={() => setSelectedRole(r.id)}>
                          {r.name}
                        </Button>
                      </Grid>
                    </RoleTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAbilities.map((a, i) => (
                  <TableRow
                    key={a.name}
                    className={`${i % 2 === 1 ? classes.oddRow : ''}`}>
                    <RoleTableCell className={classes.leftCol}>
                      {a.description}
                    </RoleTableCell>
                    {validRoles.map((r) => (
                      <RoleTableCell
                        key={`${a.name}_${r.id}`}
                        className={`${classes.column} ${
                          r.id === selectedRole ? classes.selectedColumn : ''
                        }`}>
                        {getIcon(
                          r.abilities.find((x) => x.name === a.name)?.operation,
                          r.id === selectedRole,
                        )}
                      </RoleTableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item container direction="column" className={classes.footer}>
            <Grid item container direction="row" justifyContent="space-between">
              <Grid item>
                <div className={classes.legend}>
                  <span>{getIcon('VIEW', true)} View Access</span>
                  <span>{getIcon('EDIT', true)} Edit Permissions</span>
                  <span>{getIcon(undefined, true)} Not Available</span>
                </div>
              </Grid>
              <Grid item>
                <SmallPaddedButton
                  style={{ marginTop: 8 }}
                  disabled={
                    preventMemberAssignment && selectedRole === 'member'
                  }
                  onClick={() => closeModal(selectedRole)}>
                  Apply New Role
                </SmallPaddedButton>
              </Grid>
              {preventMemberAssignment && selectedRole === 'member' && (
                <Grid item container justifyContent="flex-end">
                  <Grid item>
                    <Box color="error.main">
                      <Typography variant="subtitle1" color="inherit">
                        {preventMemberAssignmentReason}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DataStateHandler>
    </Dialog>
  );
};

export default RoleSelectionModal;
