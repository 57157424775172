import { useQuery } from '@apollo/client';
import React from 'react';
import { Typography } from '@mui/material';

import { ALL_HABITS } from '../../graphql/habit/habits';
import { HabitListItem } from '../../types/habit/types';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import HabitCategoryChip from '../common/Habit/HabitCategoryChip';
import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../common/Table/types';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import SVG from '../../assets/svg';
import { formatDateStringUtc } from '../../utils/dateUtils';

type Props = {
  onSelected: (habit: HabitListItem) => void;
};

const tableStructure: GroupableTableStructure<HabitListItem>[] = [
  {
    key: 'main',
    columns: [
      {
        key: 'name',
        display: 'Name',
        searchable: true,
        type: AlphaColumnFilter,
        searchPlaceholder: 'Search Title',
      },
      {
        key: 'isDraft',
        display: 'Draft',
        render: (data) =>
          data.isDraft ? (
            <span title="This habit is unpublished">
              <SVG.BoxEdit /> Draft
            </span>
          ) : null,
      },
      {
        key: 'category',
        display: 'Category',
        render: ({ category }) =>
          category ? <HabitCategoryChip category={category} /> : null,
      },
      {
        key: 'publishDate',
        display: 'Publish Date',
        type: AlphaColumnFilter,
        resolveFilterLabel: (value) =>
          value ? formatDateStringUtc(value as string, 'M/D/yy') : '',
        render: (c) => (
          <Typography variant="body2">
            {c.publishDate ? formatDateStringUtc(c.publishDate, 'M/D/yy') : ''}
          </Typography>
        ),
      },
    ],
  },
];

const HabitsList: React.FC<Props> = ({ onSelected }) => {
  const {
    data: { habits = [] } = {},
    loading,
    error,
  } = useQuery(ALL_HABITS, {
    variables: { includeDraft: true },
  });
  return (
    <DataStateHandler loading={loading} error={error}>
      <RoundedFlexBox>
        <GroupableSelectTable<HabitListItem>
          tableData={habits.map((h) => ({
            ...h,
            categoryName: h.category?.label || '',
          }))}
          tableStructure={tableStructure}
          hideStickyCol
          onRowClick={onSelected}
        />
      </RoundedFlexBox>
    </DataStateHandler>
  );
};

export default HabitsList;
