import React from 'react';
import theme from '../../../theme/memberTheme';
import SVG from '../../../assets/svg-v2';

interface IProps {
  complete?: boolean;
}

function CompleteIcon({ complete }: IProps): JSX.Element | null {
  let color = theme.palette.divider;

  if (complete) {
    color = theme.palette.primary.main;
  }

  return (
    <SVG.Complete
      data-testid="complete-icon"
      width={20}
      height={20}
      fill={color}
    />
  );
}

export default CompleteIcon;
