/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useRef } from 'react';
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  LexicalEditor,
} from 'lexical';
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection';
import { mergeRegister } from '@lexical/utils';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isImageNode } from '../ImageNode';

export interface ImageProps {
  lexicalNodeKey: string;
  src: string;
  caption: LexicalEditor;
}

export const Image = (props: ImageProps) => {
  const { src, lexicalNodeKey } = props;
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [isSelected] = useLexicalNodeSelection(lexicalNodeKey);
  const [editor] = useLexicalComposerContext();

  const onDelete = useCallback(
    (payload: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        const event: KeyboardEvent = payload;
        event.preventDefault();
        const node = $getNodeByKey(lexicalNodeKey);
        if ($isImageNode(node)) {
          node.remove();
        }
      }
      return false;
    },
    [isSelected, lexicalNodeKey],
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        KEY_DELETE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        onDelete,
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, onDelete]);

  return (
    <figure>
      <div style={{ position: 'relative' }}>
        <img
          style={{
            maxWidth: 480,
          }}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={0} // tab index is required for focus effect trigger
          ref={(ref) => {
            imageRef.current = ref;
          }}
          src={src}
          alt="rich text editor"
        />
      </div>
    </figure>
  );
};
