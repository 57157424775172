import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ComponentProps } from 'react';
import DefaultLogo from '../../../assets/img/default-logo.png';
import { COMPANY_DISPLAY_INFO_QUERY } from '../../../graphql/company/company';
import { Company } from '../../../types/company/types';
import DataStateHandler from '../DataStateHandler/DataStateHandler';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 36,
    top: 4,
    marginLeft: 40,
    position: 'relative',
    display: 'inline-block',
  },
  logo: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    display: 'block',
    borderRadius: 16,
    position: 'absolute',
    left: -40,
    top: -4,
  },
}));

type Props = {
  companyId?: string;
  company?: {
    name?: string;
    logoUris?: Record<'thumb', string>;
  };
  textVariant?: 'body1' | 'body2';
  color?: ComponentProps<typeof Typography>['color'];
  maxTextLength?: number;
  indent?: number;
  textInsteadOfCompanyName?: string;
};

const CompanyNameWithLogo: React.FC<Props> = ({
  companyId,
  company: inputCompany,
  textVariant = 'body2',
  color,
  maxTextLength,
  indent,
  textInsteadOfCompanyName, // Override to display this text instead of the company name
}) => {
  const classes = useStyles();

  const { loading, data: { company: queryCompany } = {} } = useQuery<{
    company: Company;
  }>(COMPANY_DISPLAY_INFO_QUERY, {
    skip: !companyId,
    variables: {
      id: companyId,
    },
  });

  const company = inputCompany || queryCompany;

  return (
    <DataStateHandler loading={loading} loadingPaddingY={0}>
      <div
        className={classes.wrapper}
        style={indent ? { marginLeft: 30 + 35 * indent } : {}}>
        {company?.name && (
          <img
            alt="Client"
            src={company?.logoUris?.thumb || DefaultLogo}
            className={classes.logo}
            style={indent ? { height: 28, width: 28 } : {}}
          />
        )}
        <Typography
          variant={textVariant}
          color={color}
          component="span"
          style={{
            width: maxTextLength ? `${maxTextLength}ch` : undefined,
            display: maxTextLength ? 'inline-block' : undefined,
          }}
          noWrap={!!maxTextLength}>
          {textInsteadOfCompanyName ?? company?.name}
        </Typography>
      </div>
    </DataStateHandler>
  );
};

export default CompanyNameWithLogo;
