import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import {
  EMISSIONS,
  WATER_FOOTPRINT,
} from '../../../graphql/dashboard/emissions/emissions';
import DashboardLayout from '../../../components/layouts/DashboardLayout';
import { OFFSET_OPPORTUNITIES } from '../../../graphql/dashboard/emissions/offsetOpportunities';
import { CategoryData } from '../../../types/dashboard/types';
import { dateRangeFromEndDate } from '../../../utils/dateUtils';
import useCurrentCompany from '../../../hooks/useCurrentCompany';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { DashboardContext } from '../../../contexts/DashboardContext';
import CarbonFooprint from '../../../components/dashboards/Impact/CarbonFootprint';
import Offsets from '../../../components/dashboards/Impact/Offsets';
import HeaderTab from '../../../components/common/HeaderTabs/HeaderTab';
import HeaderTabs from '../../../components/common/HeaderTabs/HeaderTabs';
import WaterFootprint from '../../../components/dashboards/Impact/WaterFootprint';

const useStyles = makeStyles(() => ({
  tab: {
    maxWidth: '150px !important',
  },
}));

enum ImpactTabs {
  CarbonFootprint = 0,
  OffsetsTab = 1,
  Water = 2,
}

const Impact: React.FC = () => {
  const { companyId, includeChildren } = useCurrentCompany();
  const { company } = useContext(AuthorizationContext);
  const { endDate } = useContext(DashboardContext);
  const [tab, setTab] = useState(ImpactTabs.CarbonFootprint);
  const classes = useStyles();

  const {
    data: { emissions = {} } = {},
    loading: emissionsLoading,
    error: emissionsError,
  } = useQuery(EMISSIONS, {
    skip: !companyId,
    fetchPolicy: 'network-only',
    variables: {
      dateGrain: 'MONTHLY',
      companyId,
      includeChildren,
      ...dateRangeFromEndDate(endDate, 12),
    },
  });

  const {
    data: { waterFootprint = [] } = {},
    loading: waterLoading,
    error: waterError,
  } = useQuery(WATER_FOOTPRINT, {
    skip: !companyId,
    fetchPolicy: 'network-only',
    variables: {
      companyId,
      includeChildren,
      date: endDate,
    },
  });

  const {
    data: {
      company: {
        currentBudget = {
          total: null,
          remaining: null,
          spent: null,
        },
        lifetimeBudget = {},
      } = {},
      investments = [],
    } = {},
    loading: offsetDataLoading,
    error: offsetDataError,
  } = useQuery(OFFSET_OPPORTUNITIES, {
    skip: !companyId,
    variables: {
      dateGrain: 'MONTHLY',
      companyId,
      includeChildren,
      includeDemo: company.isDemoCompany,
      ...dateRangeFromEndDate(endDate),
      budgetDate: endDate,
    },
    // apollo will try to cache graph data results from
    // this query which results in data being overwritten
    fetchPolicy: 'no-cache',
  });

  const totalInvestments = investments.find((i: CategoryData) => i.id === '1');
  const categoryInvestments = investments.filter(
    (i: CategoryData) => i.id !== '1',
  );

  return (
    <DashboardLayout>
      <DataStateHandler
        loading={
          !companyId || emissionsLoading || offsetDataLoading || waterLoading
        }
        error={emissionsError || offsetDataError || waterError}>
        <>
          <Grid item container style={{ marginBottom: 16 }}>
            <HeaderTabs value={tab} style={{ marginBottom: 24, width: '100%' }}>
              <HeaderTab
                classes={{ root: classes.tab }}
                label="Carbon Footprint"
                onClick={() => setTab(ImpactTabs.CarbonFootprint)}
              />
              <HeaderTab
                classes={{ root: classes.tab }}
                label="Offsets"
                onClick={() => setTab(ImpactTabs.OffsetsTab)}
              />
              <HeaderTab
                classes={{ root: classes.tab }}
                label="Water Footprint"
                onClick={() => setTab(ImpactTabs.Water)}
              />
            </HeaderTabs>
          </Grid>
          {tab === ImpactTabs.CarbonFootprint && (
            <CarbonFooprint emissions={emissions} targetMt={company.targetMt} />
          )}
          {tab === ImpactTabs.OffsetsTab && (
            <Offsets
              lifetimeBudget={lifetimeBudget}
              totalInvestments={totalInvestments}
              currentBudget={currentBudget}
              categoryInvestments={categoryInvestments}
            />
          )}
          {tab === ImpactTabs.Water && (
            <WaterFootprint companyFootprint={waterFootprint} />
          )}
        </>
      </DataStateHandler>
    </DashboardLayout>
  );
};

export default Impact;
