import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';

import { LocalizedQuiz, QuizType } from '../components/communications/types';
import {
  GET_CATEGORY_TIPS,
  GET_QUESTION_TREE_CATEGORIES,
  GET_QUESTIONS,
  GET_QUIZ_ANSWERS,
  GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL,
  UPDATE_QUIZ_ANSWER,
  UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS,
} from '../graphql/questionnaire/questions';
import {
  GET_USER_EMISSION_SOURCES,
  UPDATE_USER_EMISSION_SOURCES,
} from '../graphql/questionnaire/userEmissionSources';
import useRemoveQuestionRelatedData from '../hooks/cacheModifiers/useRemoveQuestionRelatedData';
import useCheckForPointAwards from '../hooks/useCheckForPointAwards';
import {
  FilterOnboardingQuestionOptions,
  ProfileCard,
  ProfileCardType,
  QuestionAnswer,
  QuestionProfile,
  QuestionTreeCategory,
  Tip,
  UserEmissionSources,
} from '../types/question/types';
import { User } from '../types/user/types';
import {
  getFirstUnansweredQuestionIndex,
  isMultiSelectQuestion,
} from '../utils/questionnaire';

export type QuestionnaireContextProps = {
  children?: React.ReactNode;
  filterOnboardingQuestions?: FilterOnboardingQuestionOptions;
  jumpToInitialQuestion?: boolean;
  initialQuestionId?: string;
  initialIndex?: number;
  cleanup: UseCleanupQuestionnaireProps;
  quiz?: LocalizedQuiz;
  onboarding?: boolean;
  questionTreeCategory?: QuestionTreeCategory;
  testing?: boolean;
};

export enum QuestionnaireActionTypes {
  READY = 'READY',
  INITIALIZED = 'INITIALIZED',
  LOADING = 'LOADING',
  ENABLE = 'ENABLE',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  QUESTION_ANSWERED_FAILED = 'QUESTION_ANSWERED_FAILED',
  CLEAR_TOAST = 'CLEAR_TOAST',
  NEXT = 'NEXT',
  CLEAN_UP_STARTED = 'CLEAN_UP_STARTED',
  CLEAN_UP_COMPLETE = 'CLEAN_UP_COMPLETE',
  GO_BACK = 'GO_BACK',
  RESTART = 'RESTART',
}

type QuestionnaireState = {
  internalState: QuestionnaireActionTypes;
  onboarding: boolean;
  carouselIndex: number;
  profileCards: ProfileCard[];
  questionTreeCategories: QuestionTreeCategory[];
  questionTreeCategory?: QuestionTreeCategory;
  filterOnboardingQuestions?: FilterOnboardingQuestionOptions;
  userEmissionSources: UserEmissionSources[];
  initializing: boolean;
  loading: boolean;
  disabled: boolean;
  cleaningUp: boolean;
  title: string;
  toastSeverity?: 'error' | 'success';
  toast?: string;
  quiz?: LocalizedQuiz;
  cleanup: UseCleanupQuestionnaireProps;
  testing?: boolean;
  testingAnswers?: QuestionAnswer[];
  refetchUserEmissionSources: () => Promise<{
    data: {
      me: Pick<User, 'userEmissionSources' | 'id'>;
    };
  }>;
  refetchQuestions: () => Promise<{
    data: {
      questions: QuestionProfile[];
    };
  }>;
  refetchTips: () => Promise<{
    data: {
      tips: Tip[];
    };
  }>;
};

type QuestionnaireActionType =
  | {
      type: QuestionnaireActionTypes.READY;
    }
  | {
      type: QuestionnaireActionTypes.INITIALIZED;
      data: {
        carouselIndex: number;
        profileCards: ProfileCard[];
        userEmissionSources: UserEmissionSources[];
        questionTreeCategories: QuestionTreeCategory[];
        filterOnboardingQuestions?: FilterOnboardingQuestionOptions;
      };
    }
  | {
      type: QuestionnaireActionTypes.LOADING;
    }
  | { type: QuestionnaireActionTypes.ENABLE }
  | {
      type: QuestionnaireActionTypes.QUESTION_ANSWERED;
      data: {
        profileCards: ProfileCard[];
        userEmissionSources: UserEmissionSources[];
        multiSelect?: boolean;
        initiateCleanup?: boolean;
        isLastQuestion?: boolean;
        toast?: string;
        answer?: QuestionAnswer;
      };
    }
  | {
      type: QuestionnaireActionTypes.QUESTION_ANSWERED_FAILED;
      data: {
        errorLabel: string;
      };
    }
  | {
      type: QuestionnaireActionTypes.CLEAR_TOAST;
    }
  | {
      type: QuestionnaireActionTypes.NEXT;
    }
  | {
      type: QuestionnaireActionTypes.CLEAN_UP_STARTED;
    }
  | {
      type: QuestionnaireActionTypes.CLEAN_UP_COMPLETE;
    }
  | {
      type: QuestionnaireActionTypes.GO_BACK;
    }
  | {
      type: QuestionnaireActionTypes.RESTART;
    };

const QuestionnaireContext = createContext<QuestionnaireState | null>(null);
const QuestionnaireDispatchContext =
  createContext<React.Dispatch<QuestionnaireActionType> | null>(null);

const StateReducer = (
  state: QuestionnaireState,
  action: QuestionnaireActionType,
): QuestionnaireState => {
  // This seems a bit hacky but should work fine
  // This is meant to stop multiple button presses breaking the carousel or just the app in general
  // If the dispatched event is the same as the current internal event then we just return the state and do nothing
  // If it's not the same we allow it

  if (action.type === state.internalState) {
    return { ...state };
  }

  const newState = {
    ...state,
    internalState: action.type,
    errorLabel: undefined,
  };

  switch (action.type) {
    case QuestionnaireActionTypes.INITIALIZED:
      return {
        ...newState,
        initializing: false,
        disabled: false,
        carouselIndex: action.data.carouselIndex,
        profileCards: action.data.profileCards,
        userEmissionSources: action.data.userEmissionSources,
        questionTreeCategories: action.data.questionTreeCategories,
        questionTreeCategory: newState.onboarding
          ? undefined
          : action.data.profileCards[action.data.carouselIndex]
              .questionTreeCategory,
        filterOnboardingQuestions: action.data.filterOnboardingQuestions,
        title:
          action.data.profileCards[action.data.carouselIndex]
            .questionTreeCategory.title,
      };
    case QuestionnaireActionTypes.LOADING:
      return {
        ...newState,
        loading: true,
        disabled: true,
      };
    case QuestionnaireActionTypes.ENABLE:
      return {
        ...newState,
        disabled: false,
      };
    case QuestionnaireActionTypes.QUESTION_ANSWERED_FAILED:
      return {
        ...newState,
        toast: action.data.errorLabel,
        toastSeverity: 'error',
        disabled: false,
        loading: false,
      };
    case QuestionnaireActionTypes.QUESTION_ANSWERED: {
      const initiateCleanup = !!action.data.initiateCleanup;

      // Decide whether to move on to the next question
      const questionAnsweredIndex =
        action.data.multiSelect || action.data.isLastQuestion || initiateCleanup
          ? newState.carouselIndex
          : newState.carouselIndex + 1;

      return {
        ...newState,
        loading: false,
        // this is a little special logic, we know if we are the last question we are going to be cleaning up
        // so we go ahed and start the process
        disabled: !action.data.multiSelect || initiateCleanup,
        cleaningUp: initiateCleanup,
        carouselIndex: questionAnsweredIndex,
        profileCards: action.data.profileCards,
        userEmissionSources: action.data.userEmissionSources,
        questionTreeCategory: newState.onboarding
          ? undefined
          : action.data.profileCards[questionAnsweredIndex]
              .questionTreeCategory,
        title:
          action.data.profileCards[questionAnsweredIndex].questionTreeCategory
            .title,
        toast: action.data.toast,
        toastSeverity: 'success',
        // Store answers for testing in state, so we can track accuracy without persisting to cosmos
        ...(state.testing &&
          action.data.answer && {
            testingAnswers: [
              ...(state.testingAnswers || []).filter(
                (x) => x.questionId !== action.data.answer?.questionId,
              ),
              action.data.answer,
            ],
          }),
      };
    }
    case QuestionnaireActionTypes.NEXT: {
      const nextIndex = newState.carouselIndex + 1;

      return {
        ...newState,
        disabled: true,
        carouselIndex: nextIndex,
        questionTreeCategory: newState.onboarding
          ? undefined
          : newState.profileCards[nextIndex].questionTreeCategory,
        title: newState.profileCards[nextIndex].questionTreeCategory.title,
      };
    }
    case QuestionnaireActionTypes.CLEAR_TOAST: {
      return {
        ...newState,
        toast: undefined,
      };
    }
    case QuestionnaireActionTypes.GO_BACK: {
      const goBackIndex = newState.carouselIndex - 1;

      return {
        ...newState,
        disabled: true,
        carouselIndex: goBackIndex,
        questionTreeCategory: newState.onboarding
          ? undefined
          : newState.profileCards[goBackIndex].questionTreeCategory,
        title: newState.profileCards[goBackIndex].questionTreeCategory.title,
      };
    }
    case QuestionnaireActionTypes.RESTART: {
      return {
        ...newState,
        disabled: true,
        carouselIndex: 0,
        questionTreeCategory: newState.onboarding
          ? undefined
          : newState.profileCards[0].questionTreeCategory,
        title: newState.profileCards[0].questionTreeCategory.title,
      };
    }
    case QuestionnaireActionTypes.CLEAN_UP_STARTED:
      return {
        ...newState,
        disabled: true,
        cleaningUp: true,
      };
    case QuestionnaireActionTypes.CLEAN_UP_COMPLETE:
      return {
        ...newState,
        disabled: false,
        cleaningUp: false,
      };
    default:
      return newState;
  }
};

const createProfileCards = (
  questionData: QuestionProfile[],
  questionTreeCategories: QuestionTreeCategory[],
  tipData?: Tip[],
  quizType?: QuizType,
) => {
  const internalQuestionData = [...questionData];

  let internalQuestionTreeCategory: QuestionTreeCategory | undefined;
  const profileCards: ProfileCard[] = [];

  internalQuestionData
    .sort((first, second) => first.categoryOrder - second.categoryOrder)
    .flat()
    .forEach((profile) => {
      internalQuestionTreeCategory = questionTreeCategories.find(
        (x) => x.id === profile.questionTreeCategory,
      );

      if (internalQuestionTreeCategory) {
        profile.questions?.forEach((question) => {
          // this extra check is to satisfy typescript
          if (internalQuestionTreeCategory) {
            profileCards.push({
              profileId: profile.id,
              questionTreeCategory: { ...internalQuestionTreeCategory },
              question: { ...question },
              cardType: ProfileCardType.Question,
            });

            // For quizzes, add a card to show the result of the previous question
            if (quizType === QuizType.QUIZ) {
              profileCards.push({
                cardType: ProfileCardType.QuizAnswer,
                profileId: profile.id,
                question: { ...question },
                questionTreeCategory: { ...internalQuestionTreeCategory },
              });
            }
          }
        });
      }
    });

  if (internalQuestionTreeCategory) {
    if (tipData) {
      const internalTipData = [...tipData];

      profileCards.push({
        categoryTipTitle: internalQuestionData[0].categoryTipTitle,
        profileTipTitle: internalQuestionData[0].profileTipTitle,
        iconLabel: internalQuestionData[0].categoryLabel,
        cardType: ProfileCardType.CategoryTip,
        questionTreeCategory: { ...internalQuestionTreeCategory },
        tips: [...internalTipData],
      });
    }

    if (quizType === QuizType.QUIZ) {
      // For quizzes, add a card to show the final results
      profileCards.push({
        cardType: ProfileCardType.QuizResult,
        questionTreeCategory: { ...internalQuestionTreeCategory },
      });
    }
  }

  return profileCards;
};

const createQuizQuestionData = (quiz: LocalizedQuiz): QuestionProfile[] => [
  {
    id: quiz.id,
    title: quiz.title,
    questionTreeCategory: quiz.id,
    categoryIconBoldUrl: '',
    categoryIconUrl: '',
    categoryLabel: '',
    categoryOrder: 1,
    categoryTipTitle: '',
    hasOnboardingQuestions: false,
    profileTipTitle: '',
    questions: quiz.questions,
  },
];

const createQuizQuestionTreeCategories = (quiz: LocalizedQuiz) => [
  {
    id: quiz.id,
    category: 'quiz',
    iconUrl: quiz.iconUrl,
    incompleteLabel: '',
    title: quiz.title,
  },
];

const createQuizProfileCards = (quiz: LocalizedQuiz) =>
  createProfileCards(
    createQuizQuestionData(quiz),
    createQuizQuestionTreeCategories(quiz),
    undefined,
    quiz.quizType,
  );

export function QuestionnaireProvider(props: QuestionnaireContextProps) {
  const {
    children,
    filterOnboardingQuestions,
    initialQuestionId,
    initialIndex,
    jumpToInitialQuestion = true,
    quiz,
    testing,
    ...rest
  } = props;

  const {
    data: { me: { userEmissionSources } } = { me: {} },
    refetch: refetchUserEmissionSources,
  } = useQuery(GET_USER_EMISSION_SOURCES, {
    skip: !!quiz,
  });

  const { data: { quizAnswers } = {}, refetch: refetchQuizAnswers } = useQuery(
    GET_QUIZ_ANSWERS,
    {
      variables: {
        quizId: quiz?.id,
      },
      skip: !quiz,
    },
  );

  const { data: { questionTreeCategories } = {} } = useQuery(
    GET_QUESTION_TREE_CATEGORIES,
    {
      skip: !!quiz,
    },
  );

  const {
    data: { me: { questionTreeCategoryStatusDetail = [] } } = { me: {} },
  } = useQuery(GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL, {
    skip: !!quiz,
  });

  const { data: { questions: questionData } = {}, refetch: refetchQuestions } =
    useQuery(GET_QUESTIONS, {
      variables: {
        questionTreeCategory: rest.questionTreeCategory?.id,
        filterValidQuestions: true,
        filterOnboardingQuestions,
      },
      skip: !!quiz,
    });

  const { data: { tips: tipData } = {}, refetch: refetchTips } = useQuery(
    GET_CATEGORY_TIPS,
    {
      variables: {
        options: {
          entityType: 'question',
          questionTreeCategoryId: rest.questionTreeCategory?.id,
        },
      },
      skip: rest.onboarding || !!quiz,
    },
  );

  const [state, dispatch] = useReducer(StateReducer, {
    internalState: QuestionnaireActionTypes.READY,
    onboarding: !!rest.onboarding,
    carouselIndex: 0,
    profileCards: [],
    userEmissionSources: [],
    initializing: true,
    loading: false,
    questionTreeCategories: [],
    filterOnboardingQuestions,
    disabled: true,
    cleaningUp: false,
    title: rest.onboarding ? '' : rest.questionTreeCategory?.title || '',
    cleanup: rest.cleanup,
    quiz,
    testing,
    refetchUserEmissionSources: quiz
      ? async () => {
          const { data: { quizAnswers: qa } = {} } = await refetchQuizAnswers();
          return {
            data: {
              me: {
                id: 'N/A',
                userEmissionSources: [
                  {
                    profileId: quiz.id,
                    answeredQuestions: qa?.answers || [],
                  },
                ],
              },
            },
          };
        }
      : refetchUserEmissionSources,
    refetchQuestions: quiz
      ? () =>
          Promise.resolve({ data: { questions: createQuizQuestionData(quiz) } })
      : refetchQuestions,
    refetchTips: quiz
      ? () => Promise.resolve({ data: { tips: [] } })
      : refetchTips,
  });

  useEffect(() => {
    if (state.internalState === QuestionnaireActionTypes.GO_BACK) {
      // In mobile, the carousel handles removing the GO_BACK state, if we don't
      // do this you can only go back once
      dispatch({
        type: QuestionnaireActionTypes.ENABLE,
      });
    }
  }, [state.internalState, dispatch]);

  useEffect(() => {
    if (!state.initializing) {
      return;
    }

    const initUserEmissionSources = quiz
      ? [
          {
            profileId: quiz.id,
            answeredQuestions: quizAnswers?.answers || [],
          },
        ]
      : userEmissionSources;

    const initQuestionData = quiz ? createQuizQuestionData(quiz) : questionData;

    const initQuestionTreeCategories = quiz
      ? createQuizQuestionTreeCategories(quiz)
      : questionTreeCategories;

    const initQuestionTreeCategoryStatusDetail = quiz
      ? []
      : questionTreeCategoryStatusDetail;

    if (
      initUserEmissionSources &&
      initQuestionData &&
      (quiz || rest.onboarding || (!rest.onboarding && tipData)) &&
      initQuestionTreeCategories &&
      initQuestionTreeCategoryStatusDetail
    ) {
      const profileCards = quiz
        ? createQuizProfileCards(quiz)
        : createProfileCards(
            initQuestionData,
            initQuestionTreeCategories,
            tipData,
          );
      let internalInitialCarouselIndex = -1;

      // we don't allow initial question id in onboarding
      // because there isn't a good way to validate what questions have been answered
      // we also need to check that we can default to the initial question, some instances we don't want to
      if (
        initQuestionData &&
        !rest.onboarding &&
        rest.questionTreeCategory &&
        jumpToInitialQuestion
      ) {
        // initial quesiton id should win over next available question
        // however, we should validate the intial index is "safe"
        // as in it's not outside the expected index range

        const status = initQuestionTreeCategoryStatusDetail.find(
          (find) => find.questionTreeCategory === rest.questionTreeCategory?.id,
        );

        if (status) {
          if (initialIndex) {
            internalInitialCarouselIndex = initialIndex;
          } else if (initialQuestionId) {
            // if we have an initial question id we would like to navigate to it if possible
            const index = profileCards.findIndex(
              (x) =>
                x.cardType === ProfileCardType.Question &&
                x.question.questionId === initialQuestionId,
            );

            // if our intial index is -1 we didn't find the card so move on
            // if our initial index is not -1 but we are past the questions we have answered
            // we need to not go to that question in case a previous quesiton could remove this one
            // this case shouldn't happen very open
            if (index !== -1 && index <= status.answered) {
              internalInitialCarouselIndex = index;
            }
          }

          // if we don't have an initial question id
          // and the category is complete we just go to the first question to let them "restart"
          // if the category is not complete we need to calculate the first question without an answer
          // and navigate to that question
          if (internalInitialCarouselIndex === -1 && !status.isComplete) {
            internalInitialCarouselIndex = getFirstUnansweredQuestionIndex(
              profileCards,
              initUserEmissionSources,
            );
          }
        }
      }

      // if our index is -1 then we didn't find a quesiton and need to default to the "first" question
      if (internalInitialCarouselIndex === -1) {
        internalInitialCarouselIndex = 0;
      }

      dispatch({
        type: QuestionnaireActionTypes.INITIALIZED,
        data: {
          carouselIndex: internalInitialCarouselIndex,
          profileCards,
          userEmissionSources: initUserEmissionSources,
          questionTreeCategories: initQuestionTreeCategories,
          filterOnboardingQuestions,
        },
      });
    }
  }, [
    rest,
    jumpToInitialQuestion,
    state.initializing,
    filterOnboardingQuestions,
    initialQuestionId,
    userEmissionSources,
    questionData,
    tipData,
    questionTreeCategories,
    questionTreeCategoryStatusDetail,
    quiz,
    testing,
  ]);

  return (
    <QuestionnaireContext.Provider value={state}>
      <QuestionnaireDispatchContext.Provider value={dispatch}>
        {children}
      </QuestionnaireDispatchContext.Provider>
    </QuestionnaireContext.Provider>
  );
}

export function useQuestionnaire() {
  const state = useContext(QuestionnaireContext);

  if (!state) {
    throw new Error(
      'useQuestionnaire hook must be used inside a QuestionnaireProvider',
    );
  }

  return state;
}

export function useQuestionnaireDispatch() {
  const dispatch = useContext(QuestionnaireDispatchContext);

  if (!dispatch) {
    throw new Error(
      'useQuestionnaireDispatch hook must be used inside a QuestionnaireProvider',
    );
  }

  return dispatch;
}

export type UseCleanupQuestionnaireProps =
  | {
      navigationType: 'NAVIGATE';
      url: string;
    }
  | {
      navigationType: 'GO_BACK';
    }
  | {
      navigationType: 'IMPACT';
    }
  | {
      navigationType: 'FUNCTION';
      function: () => void;
    };

export function useCleanupQuestionnaire() {
  const history = useHistory();
  const state = useContext(QuestionnaireContext);
  const dispatch = useContext(QuestionnaireDispatchContext);

  if (!state) {
    throw new Error(
      'useQuestionnaire hook must be used inside a QuestionnaireProvider',
    );
  }

  if (!dispatch) {
    throw new Error(
      'useQuestionnaireDispatch hook must be used inside a QuestionnaireProvider',
    );
  }

  const { remove: removeQuestionRelatedData } = useRemoveQuestionRelatedData();
  const { checkForPointAwards } = useCheckForPointAwards();

  const [updateUserOnboardingQuestionnaireStatus] = useMutation(
    UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS,
  );

  const cleanupQuestionnaire = async (props: UseCleanupQuestionnaireProps) => {
    dispatch({ type: QuestionnaireActionTypes.CLEAN_UP_STARTED });

    setTimeout(async () => {
      if (!state.quiz) {
        await removeQuestionRelatedData();

        if (state.onboarding) {
          // TODO: onboarding complete stuff

          // Does this still make sense clearing postalCodeChanged here?
          await updateUserOnboardingQuestionnaireStatus({
            variables: {
              hasCompletedOnboardingQuestionnaire: true,
              postalCodeChanged: false,
            },
          });
        }
      }

      await checkForPointAwards();

      if (!state.onboarding && props.navigationType === 'NAVIGATE') {
        history.push(props.url);
      }

      if (props.navigationType === 'GO_BACK') {
        history.goBack();
      }

      if (props.navigationType === 'FUNCTION') {
        props.function();
      }

      dispatch({ type: QuestionnaireActionTypes.CLEAN_UP_COMPLETE });
    }, 200);
  };

  return cleanupQuestionnaire;
}

export function useAnswerQuestion() {
  const state = useContext(QuestionnaireContext);
  const dispatch = useContext(QuestionnaireDispatchContext);

  if (!state) {
    throw new Error(
      'useQuestionnaire hook must be used inside a QuestionnaireProvider',
    );
  }

  if (!dispatch) {
    throw new Error(
      'useQuestionnaireDispatch hook must be used inside a QuestionnaireProvider',
    );
  }

  const cleanupQuestionnaire = useCleanupQuestionnaire();

  const [updateUserEmissionSources] = useMutation(UPDATE_USER_EMISSION_SOURCES);

  const [updateQuizAnswer] = useMutation(UPDATE_QUIZ_ANSWER);

  const update = async ({
    categoryId,
    answers,
  }: {
    categoryId: string;
    answers: {
      answer: QuestionAnswer | null;
      profileId: string;
    }[];
  }) => {
    if (!state.testing) {
      if (state.quiz) {
        if (answers[0].answer) {
          await updateQuizAnswer({
            variables: {
              quizId: state.quiz.id,
              answer: answers[0].answer,
            },
          });
        } else {
          throw new Error('no quiz answer?');
        }
      } else {
        await updateUserEmissionSources({
          variables: {
            categoryId,
            answers,
          },
        });
      }
    }
  };

  const answerQuestion = async (
    profileCard: ProfileCard,
    newQuestionAnswer: QuestionAnswer,
    optionProfileDocumentData?: {
      answerId: string;
      profileDocumentId: string;
    },
  ) => {
    if (state.loading) {
      // Ignore multiple clicks while loading
      return;
    }

    const answer = newQuestionAnswer
      ? (omitDeep(newQuestionAnswer, '__typename') as QuestionAnswer)
      : null;

    dispatch({ type: QuestionnaireActionTypes.LOADING });

    setTimeout(async () => {
      if (profileCard.cardType === ProfileCardType.Question) {
        const multiSelect =
          isMultiSelectQuestion(profileCard) && !!optionProfileDocumentData;

        try {
          if (multiSelect) {
            // check if the profile answer is part of the answers
            // if it is then we add the user emission source
            // if it is not then we remove it
            if (
              answer?.answerIds?.includes(optionProfileDocumentData.answerId)
            ) {
              await update({
                categoryId: profileCard.questionTreeCategory.category,
                answers: [
                  {
                    profileId: profileCard.profileId,
                    answer,
                  },
                  {
                    profileId: optionProfileDocumentData.profileDocumentId,
                    answer: null,
                  },
                ],
              });
            } else {
              // for removing an answer, the server will automatically remove the incorrect profile
              // when it is removed from the answer here
              await update({
                categoryId: profileCard.questionTreeCategory.category,
                answers: [
                  {
                    profileId: profileCard.profileId,
                    answer,
                  },
                ],
              });
            }
          } else {
            await update({
              categoryId: profileCard.questionTreeCategory.category,
              answers: [
                {
                  profileId: profileCard.profileId,
                  answer,
                },
              ],
            });
          }

          const [newUserEmissionSources, newTipData, newQuestionData] =
            await Promise.all([
              state.refetchUserEmissionSources(),
              state.onboarding ? undefined : state.refetchTips(),
              state.refetchQuestions(),
            ]);

          let toast: string | undefined;

          if (newQuestionAnswer.unsure) {
            // Find what the answer was set to in the new sources and pop a toast message informing the user
            const [updatedAnswerId] =
              newUserEmissionSources.data.me.userEmissionSources
                .find((x) => x.profileId === profileCard.profileId)
                ?.answeredQuestions.find(
                  (x) => x.questionId === newQuestionAnswer.questionId,
                )?.answerIds || [];

            const updatedOption = newQuestionData.data.questions
              .find((x) => x.id === profileCard.profileId)
              ?.questions.find(
                (x) => x.questionId === newQuestionAnswer.questionId,
              )
              ?.options?.find((x) => x.answerId === updatedAnswerId);

            if (updatedOption) {
              toast = `Choosing ‘Unsure’ will record ${updatedOption.answerText} as your default response.`;
            }
          }

          const profileCards = state.quiz
            ? createQuizProfileCards(state.quiz)
            : createProfileCards(
                newQuestionData.data.questions,
                state.questionTreeCategories,
                newTipData?.data.tips,
              );

          const isLastQuestion =
            profileCards.length - 1 === state.carouselIndex;
          const initiateCleanup =
            isLastQuestion && state.quiz?.quizType !== QuizType.POLL;

          dispatch({
            type: QuestionnaireActionTypes.QUESTION_ANSWERED,
            data: {
              profileCards,
              userEmissionSources:
                newUserEmissionSources.data.me.userEmissionSources,
              multiSelect,
              initiateCleanup,
              isLastQuestion,
              toast,
              ...(state.testing && answer && { answer }),
            },
          });

          if (initiateCleanup) {
            await cleanupQuestionnaire(state.cleanup);
          }
        } catch (err) {
          let errorLabel =
            'There was an error with your answer.  Please try again.';

          if (err instanceof ApolloError) {
            switch (err.graphQLErrors[0]?.extensions?.code) {
              case 'INVALID_POSTAL_CODE':
                errorLabel = 'The provided postal code is invalid.';
                break;
              default:
                break;
            }
          }

          dispatch({
            type: QuestionnaireActionTypes.QUESTION_ANSWERED_FAILED,
            data: { errorLabel },
          });
        }
      }
    }, 200);
  };

  return answerQuestion;
}
