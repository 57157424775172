import React from 'react';
import { Box, CircularProgress } from '@mui/material';

type Props = {
  size?: number;
  paddingY?: number;
};

const Loading: React.FC<Props> = ({ size, paddingY = 4 }) => (
  <Box
    aria-label="Loading"
    display="grid"
    paddingY={paddingY}
    justifyContent="center"
    data-testid="loading-component"
    width="100%">
    <CircularProgress size={size} color="secondary" />
  </Box>
);

export default Loading;
