import { useQuery } from '@apollo/client';
import { Divider, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../../../assets/svg';
import { AuthorizationContext } from '../../../../contexts/AuthorizationContext';
import { DashboardContext } from '../../../../contexts/DashboardContext';
import { BEHAVIOR_CREDITS_BY_COMPANY } from '../../../../graphql/dashboard/emissions/emissions';
import {
  dateRangeFromEndDate,
  formatDateStringUtc,
} from '../../../../utils/dateUtils';
import CardFooter from '../../../common/CardFooter/CardFooter';
import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import NumberUtils from '../../../../utils/numberUtils';
import ChartLegend from '../../../common/ChartLegend/ChartLegend';
import AppCard from '../../../common/AppCard/AppCard';
import TextUtils from '../../../../utils/textUtils';
import TooltipContainer from '../../../common/ChartTooltips/TooltipContainer';
import ChartLabel from '../../../common/ChartLabel/ChartLabel';
import { formatTooltip } from '../../../common/ChartTooltips/FormattedTooltip';

const useStyles = makeStyles(() => ({
  centerParentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  centerContent: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

// [{
//  "month": "Dec, 2022",
//  "water": 0.02646479999999997,
//  "home": 0.6987388923127483,
//  "diet": 0.11406000000000002,
//  "home-improvements": 38.54166000000021,
//  "lifestyle": 1.0103380000000008,
//  "transportation": 0
// }]
// Dynamically created project category 'type' properties that represent the chart data
type chartDataType = {
  month: string;
};

// Represents the project category name and associated colors
type habitColorType = {
  name: string;
  label: string;
  backgroundColor: string;
  lineColor: string;
};

type BehaviorCreditsCardType = 'carbon' | 'water';

type Props = {
  type: BehaviorCreditsCardType;
};

const BehaviorCredits: React.FC<Props> = ({ type }) => {
  const { company } = useContext(AuthorizationContext);
  const { division, includeChildren, endDate } = useContext(DashboardContext);
  const userCompId: string = division || company.id;

  const { tooltipId, title, uomLabel } = {
    water: {
      tooltipId: 'impactWaterBehaviorCredits',
      title: 'BEHAVIOR CREDITS - WATER SAVINGS',
      uomLabel: 'Liters',
    },
    carbon: {
      tooltipId: 'impactBehaviorCredits',
      title: 'BEHAVIOR CREDITS - CARBON SAVINGS',
      uomLabel: 'MT C0₂E',
    },
  }[type];

  const classes = useStyles();

  // Dynamic property names and colors
  const creditColors: habitColorType[] = [];

  // TODO: type will have to affect the query and possibly
  // how this all gets mapped

  const {
    loading: loadingBehaviorCreditsByCompany,
    error: errorBehaviorCreditsByCompany,
    data: { behaviorCreditsByCompany = [] } = {},
  } = useQuery(BEHAVIOR_CREDITS_BY_COMPANY, {
    variables: {
      companyId: userCompId,
      parameters: {
        dateGrain: 'MONTHLY',
        includeChildren,
        ...dateRangeFromEndDate(endDate),
      },
    },
    fetchPolicy: 'network-only',
  });

  // Remove 'All' and map as parent/child
  const companyData = behaviorCreditsByCompany
    .filter((item) => item.label !== 'All')
    .map((b) => ({
      id: b.groupId,
      label: b.label,
      graphForeColor: b.graphForeColor,
      graphBackgroundColor: b.graphBackgroundColor,
      data: b.data
        .filter((o) => o.purposeType === 'all')
        .map((d) => ({
          value: d.value,
          x: formatDateStringUtc(d.date, 'MMM, YYYY'),
          y: d.value / 1000,
        })),
    }));

  // Get the children (data) of all items and combined with parent items
  const supportData = companyData.flatMap((row) =>
    row.data
      ? row.data.map((c) => ({
          id: row.id,
          label: row.label,
          graphForeColor: row.graphForeColor,
          graphBackgroundColor: row.graphBackgroundColor,
          month: c.x,
          value: c.value / 1000,
        }))
      : [],
  );

  // Sum all values
  const total = _.sumBy(supportData, (child) => child.value);

  // Dynamically assign a property to an object
  const setDynamicProperty = (
    obj: chartDataType,
    prop: string,
    value: number,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = obj;
    data[prop] = value;
  };

  // [{
  //  "month": "Dec, 2022",
  //  "water": 0.02646479999999997,
  //  "home": 0.6987388923127483,
  //  "diet": 0.11406000000000002,
  //  "lifestyle": 1.0103380000000008,
  //  "transportation": 0
  // }]
  // Dynamically created project category 'type' properties that represent the chart data
  const chartData: chartDataType[] = [];
  supportData.forEach((d) => {
    let item = chartData.find((x) => x.month === d.month);
    if (!item) {
      item = {
        month: d.month,
      };

      chartData.push(item);
    }

    // Store the dynamic property names for the the Area's dataKey
    if (creditColors.findIndex((e) => e.name === d.id) === -1) {
      const colors = supportData.find((h) => h.id === d.id);
      creditColors.push({
        name: d.id,
        label: d.label,
        backgroundColor: colors?.graphBackgroundColor || '#123456',
        lineColor: colors?.graphForeColor || '#000000',
      });
    }

    // Add the dynamic property and its numeric value based on the project category name, i.e. solar, diet, etc.
    setDynamicProperty(item, d.id, d.value);
  });

  // Get the behavior credit value (from the y-axis property) for the tooltips
  const renderTooltipContents = ({
    active,
    payload,
  }: TooltipProps<number, string>) => {
    if (active) {
      let monthName = '';
      const renderTooltips = creditColors.map((creditColor) => {
        const item = payload?.find((p) => p.name === creditColor.name);
        if (!item) {
          return <svg />;
        }

        // Get the the numeric property dynamically by name
        const value = item.payload[creditColor.name] as number;

        // Only store month name once for the iteration
        if (monthName.length === 0) {
          monthName = item.payload.month;
        }

        return (
          <ChartLabel
            key={creditColor.name}
            labelColor="#6B6B6B"
            color={creditColor.lineColor}
            label={creditColor.label}
            value={formatTooltip(value, 'precisionNumber')}
          />
        );
      });

      return (
        <TooltipContainer>
          <>
            <ChartLabel
              labelColor="#6B6B6B"
              color="#FFFFFF"
              value=""
              label={monthName}
            />
            <Divider />
            {renderTooltips}
          </>
        </TooltipContainer>
      );
    }
    return <svg />;
  };

  // Get an array of ChartLegend elements to render
  const getChartLegendKeys = creditColors.map((projectCategoryColor) => ({
    color: projectCategoryColor.lineColor,
    label: TextUtils.clampText(projectCategoryColor.label, 15, '.'),
  }));

  return (
    <AppCard
      height={460}
      headerProps={{
        title,
        tooltipId,
      }}>
      <DataStateHandler
        loading={loadingBehaviorCreditsByCompany}
        error={errorBehaviorCreditsByCompany}>
        {total > 0 ? (
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <div style={{ height: 280 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={chartData}
                    margin={{
                      top: 10,
                      right: 10,
                      left: 0,
                      bottom: 10,
                    }}>
                    <CartesianGrid
                      vertical={false}
                      color="#D1FFBB"
                      opacity={0.2}
                    />
                    <XAxis
                      dataKey="month"
                      orientation="bottom"
                      stroke="#2A2A2A"
                      fontSize={10}
                      tickMargin={24}
                      tickSize={0}
                      interval={0}
                      padding={{ right: 25 }}
                    />
                    <YAxis
                      orientation="left"
                      tickMargin={16}
                      tickSize={0}
                      fontSize={10}
                      stroke="#2A2A2A"
                    />
                    <Tooltip
                      content={renderTooltipContents}
                      cursor={{ stroke: '#D1FFBB' }}
                    />
                    {creditColors.map((creditColor) => (
                      <Area
                        type="monotone"
                        key={creditColor.name}
                        dataKey={creditColor.name}
                        strokeWidth={2}
                        stroke={creditColor.lineColor}
                        fillOpacity={1}
                        stackId="1"
                        activeDot={{
                          stroke: creditColor.lineColor,
                          fill: creditColor.backgroundColor,
                        }}
                        fill={creditColor.backgroundColor}
                      />
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between">
                <Grid item xs={8}>
                  <ChartLegend keys={getChartLegendKeys} labelColor="#2A2A2A" />
                </Grid>
                <Grid item xs={4}>
                  <CardFooter
                    data={NumberUtils.format(
                      total,
                      type === 'carbon' ? 'precisionNumber' : 'integer',
                    )}
                    label={uomLabel}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <div className={classes.centerParentContainer}>
            <div className={classes.centerContent}>
              <SVG.EmptyState />

              <Typography variant="h2">
                You don&apos;t have any behavior credits
              </Typography>
            </div>
          </div>
        )}
      </DataStateHandler>
    </AppCard>
  );
};

export default BehaviorCredits;
