import React, { createContext, FC, useState, useMemo } from 'react';
import { Snackbar } from '@mui/material';
import MemberAlert, {
  MemberAlertContent,
} from '../components/member/myhabits/MemberAlert';

type MemberToastContext = {
  showToast: (toast: MemberAlertContent) => void;
  lastUserAwardTimestamp: string;
  setLastUserAwardTimestamp: (timestamp: string) => void;
};
const initialContext: MemberToastContext = {
  showToast: () => null,
  lastUserAwardTimestamp: new Date().toISOString(),
  setLastUserAwardTimestamp: () => null,
};
export const MemberToastContext = createContext(initialContext);

const MemberToastProvider: FC = ({ children }) => {
  const [toastState, setToastState] = useState<{
    show: boolean;
    content?: MemberAlertContent;
  }>({ show: false });
  const [lastUserAwardTimestamp, setLastUserAwardTimestamp] = useState(
    initialContext.lastUserAwardTimestamp,
  );

  const toastContextValue = useMemo(
    () => ({
      showToast: (content: MemberAlertContent) =>
        setToastState({
          show: true,
          content,
        }),
      lastUserAwardTimestamp,
      setLastUserAwardTimestamp,
    }),
    [setToastState, lastUserAwardTimestamp, setLastUserAwardTimestamp],
  );

  const close = () => {
    setToastState({
      ...toastState,
      show: false,
    });
  };

  return (
    <MemberToastContext.Provider value={toastContextValue}>
      <>
        {children}
        <Snackbar
          open={toastState.show}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={4000}
          onClose={close}>
          <MemberAlert {...toastState.content} onClose={close} />
        </Snackbar>
      </>
    </MemberToastContext.Provider>
  );
};

export default MemberToastProvider;
