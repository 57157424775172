import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

type Props = {
  isActive: boolean;
};

const Status: React.FC<Props> = ({ isActive }) => (
  <Grid container alignItems="center">
    <Box
      borderRadius="50%"
      bgcolor={isActive ? '#398360' : '#6B6B6B'}
      height="8px"
      width="8px"
      mr="4px"
    />
    <Typography variant="body2">{isActive ? 'Active' : 'Inactive'}</Typography>
  </Grid>
);

export default Status;
