import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  right: {
    flex: 1,
    textAlign: 'right',
  },
  root: {
    marginBottom: 16,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  tabs: {
    alignSelf: 'flex-end',
  },
  closeLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const OffsetHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" className={classes.root}>
      <Grid item>
        <Box mr={2} pb={1}>
          <Typography variant="h1">Offset Opportunity</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OffsetHeader;
