import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  Redirect,
  Switch,
  matchPath,
  useLocation,
  useRouteMatch,
} from 'react-router';
import MainLayout from '../../components/layouts/MainLayout';
import CompanySettings from './CompanySettings';
import PlatformSettings from './PlatformSettings';
import EmployeeSettings from './EmployeeSettings';
import CompanySettingsProvider from '../../contexts/CompanySettingsContext';
import CompanySettingsHeader from '../../components/settings/CompanySettingsHeader';
import AuthorizedRoute from '../../components/common/AuthorizedRoute';
import SubscriptionSettings from './SubscriptionSettings';

const useStyles = makeStyles(() => ({
  content: {
    height: '100%',
  },
}));

const Settings: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { path } = useRouteMatch();

  const isEmployees = !!matchPath(location.pathname, {
    path: `${path}/employees/:id?`,
  });

  return (
    <MainLayout childBoxStyle={{ paddingTop: 0 }}>
      <CompanySettingsProvider>
        <CompanySettingsHeader>
          <div
            className={classes.content}
            style={{ overflow: isEmployees ? 'visible' : 'auto' }}>
            <Switch>
              <AuthorizedRoute
                ability="Configuration.Company"
                path={`${path}/company/:id?`}>
                <CompanySettings />
              </AuthorizedRoute>
              <AuthorizedRoute
                ability="Configuration.Platform"
                path={`${path}/platform/:id?`}>
                <PlatformSettings />
              </AuthorizedRoute>
              <AuthorizedRoute
                ability="Client.Employees"
                path={`${path}/employees/:id?`}>
                <EmployeeSettings />
              </AuthorizedRoute>
              <AuthorizedRoute
                ability="ZeroMe.SubscriptionManagement"
                path={`${path}/subscription/:id?`}>
                <SubscriptionSettings />
              </AuthorizedRoute>
              <Redirect to={`${path}/company`} />
            </Switch>
          </div>
        </CompanySettingsHeader>
      </CompanySettingsProvider>
    </MainLayout>
  );
};

export default Settings;
