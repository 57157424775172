import React from 'react';

import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberLegalContent from './MemberLegalContent';

const MemberPrivacy: React.FC = () => (
  <MemberLayout>
    <MemberLegalContent type="privacy" />
  </MemberLayout>
);

export default MemberPrivacy;
