import React from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppCard from '../../common/AppCard/AppCard';
import NumberUtils from '../../../utils/numberUtils';
import AppInfo from '../../common/AppInfo';

const useStyles = makeStyles((theme) => ({
  value: {
    color: theme.palette.buttons.primary.main,
  },
  legend: {
    color: '#6B6B6B',
    marginBottom: 16,
    fontSize: 12,
  },
}));

type Props = {
  traderBudgetStats?: {
    projectCount: number;
    spent: number;
    spentMt: number;
    spentMWh: number;
  };
};

const YtdInvestmentCard: React.FC<Props> = ({ traderBudgetStats }) => {
  const classes = useStyles();

  return (
    <AppCard height={350}>
      <>
        <Grid container justifyContent="space-between">
          <Typography
            variant="subtitle1"
            style={{ fontSize: 12, marginBottom: 24, color: '#2A2A2A' }}>
            YEAR TO DATE INVESTMENT
          </Typography>
          <AppInfo id="offsetYtdInvestment" />
        </Grid>

        <Typography variant="h2" className={classes.value}>
          {NumberUtils.format(traderBudgetStats?.projectCount, 'integer')}
        </Typography>
        <Typography variant="subtitle1" className={classes.legend}>
          PROJECTS
        </Typography>
        <Typography variant="h2" className={classes.value}>
          {NumberUtils.format(traderBudgetStats?.spentMt, 'integer')}
        </Typography>
        <Typography variant="subtitle1" className={classes.legend}>
          MT
        </Typography>
        <Typography variant="h2" className={classes.value}>
          {NumberUtils.format(traderBudgetStats?.spentMWh, 'integer')}
        </Typography>
        <Typography variant="subtitle1" className={classes.legend}>
          MWh
        </Typography>
        <Typography variant="h2" className={classes.value}>
          {NumberUtils.format(traderBudgetStats?.spent, 'currency')}
        </Typography>
        <Typography variant="subtitle1" className={classes.legend}>
          USD
        </Typography>
      </>
    </AppCard>
  );
};

export default YtdInvestmentCard;
