import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { UserActivityType } from '../../../types/user/activityTypes';
import { UserActivityListItemProps } from './UserActivityListItem';
import CompanyLogo from '../../common/Company/CompanyLogo';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import UserActivityRenderer, {
  LEFT_COLUMN_WIDTH,
  renderActivityDate,
} from './UserActivityRenderer';
import theme from '../../../theme/memberTheme';
import { Colors } from '../../../theme/shared';

const UserActivityCompanyAllocationListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    const { data: { projectCategories } = {} } = useQuery(GET_ALL_CATEGORIES);

    if (userActivity.activityType !== UserActivityType.COMPANY_OFFSET) {
      return null;
    }

    if (!projectCategories) {
      return null;
    }

    const project = projectCategories.find(
      (find) => find.id === userActivity.data.projectCategoryId,
    );

    if (!project) {
      return null;
    }

    const renderUOM = () => {
      if (userActivity.data.uom && userActivity.data.emissions) {
        switch (userActivity.data.uom) {
          case 'kgCO2e':
            return `${new Intl.NumberFormat('en-us', {
              maximumFractionDigits: 1,
            }).format(userActivity.data.emissions)} kg`;

          case 'MT':
            return `${new Intl.NumberFormat('en-us', {
              maximumFractionDigits: 1,
            }).format(userActivity.data.emissions / 1000)} MT`;

          default:
            throw new Error(`Unrecognized uom: ${userActivity.data.uom}`);
        }
      }

      return '';
    };

    return (
      <UserActivityRenderer
        leftComponent={
          !!project.iconUri && (
            <Grid
              item
              style={{
                position: 'relative',
                backgroundColor: Colors.CobaltBlue,
                width: LEFT_COLUMN_WIDTH,
                height: LEFT_COLUMN_WIDTH,
                borderRadius: LEFT_COLUMN_WIDTH / 2,
              }}>
              <ReactSVG
                src={project.iconUri}
                style={{
                  marginLeft: LEFT_COLUMN_WIDTH / 4,
                  paddingTop: LEFT_COLUMN_WIDTH / 4,
                }}
                beforeInjection={(svg) => {
                  svg.setAttribute('fill', '#fff');
                }}
              />
              <Box
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: LEFT_COLUMN_WIDTH / -8,
                }}>
                <CompanyLogo
                  logoUris={userActivity.data.companyLogoUris}
                  style={{
                    width: LEFT_COLUMN_WIDTH / 2,
                    height: LEFT_COLUMN_WIDTH / 2,
                    borderRadius: LEFT_COLUMN_WIDTH / 4,
                  }}
                />
              </Box>
            </Grid>
          )
        }
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">
                {userActivity.data.projectName}
              </Typography>
            </Grid>
            <Grid item container flexDirection="row" alignItems="center">
              <Chip
                size="small"
                style={{ marginRight: 8 }}
                label={
                  <>
                    <ReactSVG
                      src={userActivity.data.emissionTypeIconUri || ''}
                      beforeInjection={(svg) => {
                        svg.setAttribute('fill', theme.palette.text.secondary);
                        svg.setAttribute('width', '15px');
                        svg.setAttribute('height', '15px');
                      }}
                      style={{
                        display: 'inline-block',
                        height: 12,
                        position: 'relative',
                        top: 2,
                        marginRight: 4,
                      }}
                    />
                    <Typography variant="button" style={{ fontSize: 12 }}>
                      {userActivity.data.emissionTypeLabel}
                    </Typography>
                  </>
                }
              />
              {renderActivityDate(userActivity)}
            </Grid>
          </Grid>
        }
        rightComponent={<Typography variant="h4">{renderUOM()}</Typography>}
      />
    );
  },
);

export default UserActivityCompanyAllocationListItem;
