import React from 'react';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ProjectPurchaseHistory,
  ProjectPurchaseStatus,
} from '../../../types/offsetProject/types';
import { projectStatusLabel } from '../../../utils/projectUtils';

const useStyles = makeStyles({
  pending: {
    backgroundColor: '#F1F8FD',
    color: '#3E7DA2',
  },
  complete: {
    backgroundColor: '#E7F1EA',
    color: '#398360',
  },
  cancelled: {
    backgroundColor: '#FFDDE4',
    color: '#D33050',
  },
});

type Props = {
  status: ProjectPurchaseHistory['status'];
};

const PurchaseHistoryStatusChip: React.FC<Props> = ({ status }) => {
  const classes = useStyles();
  const label = projectStatusLabel(status);
  let className;
  switch (status) {
    case ProjectPurchaseStatus.COMPLETE:
      className = classes.complete;
      break;
    case ProjectPurchaseStatus.CANCELLED:
      className = classes.cancelled;
      break;
    case ProjectPurchaseStatus.REQUESTED:
    case ProjectPurchaseStatus.APPROVED:
      className = classes.pending;
      break;
    default:
      break;
  }

  return <Chip label={label} size="small" className={className} />;
};

export default PurchaseHistoryStatusChip;
