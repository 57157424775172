import { Grid, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import SmallPaddedButton from '../../components/common/Buttons/SmallPaddedButton';

type Props = {
  /** The message to be shown to the user. */
  message: string;

  /** Show the 'browse marketplace' button when the tableData is empty? */
  showBrowseMarketplaceButton?: boolean;
};

/**
 * Show a message and optional 'Browse Marketplace button.
 * @param message - Message to show.
 * @param showBrowseMarketplaceButton - Show Browse Marketplace button?
 * @returns
 */
const EmptyProjects = ({
  message,
  showBrowseMarketplaceButton = true,
}: Props) => {
  const history = useHistory();

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center">
      <Grid item>
        <Typography variant="body1">{message}</Typography>
      </Grid>
      {showBrowseMarketplaceButton && (
        <Grid item>
          <SmallPaddedButton onClick={() => history.push('/marketplace')}>
            Browse Marketplace &gt;
          </SmallPaddedButton>
        </Grid>
      )}
    </Grid>
  );
};

export default EmptyProjects;
