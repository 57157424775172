import * as yup from 'yup';
import { CommunicationType } from '../types';

const commonSchemaProps = {
  title: yup.string().required(),
  content: yup.string(),
  communicationType: yup.string(),
  publishDate: yup.string().nullable(),
};

const blogSchema = yup.object().shape({
  ...commonSchemaProps,
  isFeatured: yup.boolean().required(),
  content: yup.string().required(),
  author: yup.string(),
  categoryIds: yup.array().of(yup.string()).min(1),
});

const videoSchema = yup.object().shape({
  ...commonSchemaProps,
  isFeatured: yup.boolean().required(),
  author: yup.string(),
  categoryIds: yup.array().of(yup.string()).min(1),
});

const audioSchema = yup.object().shape({
  ...commonSchemaProps,
  isFeatured: yup.boolean().required(),
  author: yup.string(),
  categoryIds: yup.array().of(yup.string()).min(1),
});

const hintSchema = yup.object().shape({
  ...commonSchemaProps,
  isFeatured: yup.boolean().required(),
  content: yup.string().required(),
  author: yup.string(),
  categoryIds: yup.array().of(yup.string()).min(1),
});

export const getSchemaByContentType = (
  communicationType: CommunicationType,
) => {
  switch (communicationType) {
    case CommunicationType.BLOG_POST:
      return blogSchema;
    case CommunicationType.VIDEO:
      return videoSchema;
    case CommunicationType.AUDIO:
      return audioSchema;
    case CommunicationType.HINT:
      return hintSchema;

    default:
      return null;
  }
};

export default {
  getSchemaByContentType,
};
