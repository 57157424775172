import React from 'react';
import { useParams } from 'react-router';

import MemberLayout from '../../../components/layouts/MemberLayout';
import Poll from '../../../components/member/poll/Poll';

const MemberPoll: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <MemberLayout>
      <Poll pollId={id} />
    </MemberLayout>
  );
};

export default MemberPoll;
