import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/client';
import MemberLayout from '../../../components/layouts/MemberLayout';
import { GET_QUESTION_TREE_CATEGORIES } from '../../../graphql/questionnaire/questions';
import MemberProfileAvatar from '../../../components/member/aboutme/MemberProfileAvatar';
import { QuestionnaireProvider } from '../../../contexts/QuestionnaireContext';
import ResponseQuestionList from '../../../components/common/Questionnaire/ResponseQuestionList';

const MyResponses: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: { questionTreeCategories } = {} } = useQuery(
    GET_QUESTION_TREE_CATEGORIES,
  );

  const category = questionTreeCategories?.find((x) => x.id === id);

  return category ? (
    <MemberLayout>
      <QuestionnaireProvider
        onboarding={false}
        questionTreeCategory={category}
        cleanup={{ navigationType: 'NAVIGATE', url: '/member/about-me' }}>
        <Box pb={3}>
          <MemberProfileAvatar />
        </Box>
        <Box
          style={{
            maxWidth: 600,
            margin: 'auto',
          }}>
          <ResponseQuestionList />
        </Box>
      </QuestionnaireProvider>
    </MemberLayout>
  ) : (
    <div />
  );
};

export default MyResponses;
