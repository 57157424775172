import React from 'react';

import { Grid } from '@mui/material';

import MonthlyUsersGraph from '../MonthlyUsersGraph/MonthlyUsersGraph';
import AppSection from '../../../common/AppSection/AppSection';
import {
  CategoryInterest,
  LikedProject,
  MonthlyUsersPoint,
} from '../../../../types/dashboard/types';
import ProjectCategoryInterest from '../ProjectCategoryInterest/ProjectCategoryInterest';
import ProfileCompletionCard from '../ProfileCompletion/ProfileCompletionCard';
import LikedProjectsChart from '../LikedProjectsChart';

type Props = {
  total: number;
  profileCompletion: {
    total: number;
    completedAllCategories: number;
    completedAllCategoriesPercentOfTotal: number;
    categories: CategoryInterest[];
  };
  projectCategoryInterest: CategoryInterest[];
  monthlyUsers: MonthlyUsersPoint[];
  likedProjects: LikedProject[];
};

const EngagementMetrics: React.FC<Props> = ({
  total,
  profileCompletion,
  projectCategoryInterest,
  monthlyUsers,
  likedProjects,
}) => {
  const categoryData = projectCategoryInterest?.map((cat) => ({
    ...cat,
    id: cat.label,
  }));

  const height = (profileCompletion?.categories?.length || 0) * 58 + 240;

  return (
    <AppSection header="Engagement Metrics">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MonthlyUsersGraph total={total} data={monthlyUsers} />
        </Grid>
        <Grid item xs={3}>
          <ProfileCompletionCard
            profileCompletion={profileCompletion}
            height={height}
          />
        </Grid>
        <Grid item xs={9}>
          <ProjectCategoryInterest data={categoryData} height={height} />
        </Grid>
        <Grid item xs={12}>
          <LikedProjectsChart likedProjects={likedProjects} />
        </Grid>
      </Grid>
    </AppSection>
  );
};

export default EngagementMetrics;
