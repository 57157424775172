import React, { useContext, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { ProjectPurchaseHistory as ProjectPurchaseHistoryType } from '../../../types/offsetProject/types';
import { GET_MARKETPLACE_PURCHASEHIST } from '../../../graphql/projects/projects';
import { GroupableTableStructure } from '../Table/types';
import AlphaColumnFilter from '../Table/AlphaColumnFilter';
import CompanyName from '../Company/CompanyName';
import CompanyNameWithLogo from '../Company/CompanyNameWithLogo';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import RoundedFlexBox from '../../trader/RoundedFlexBox';
import GroupableSelectTable from '../Table/GroupableSelectTable';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import useWindowSize from '../../../hooks/useWindowSize';
import UserImage from '../User/UserImage';
import { User } from '../../../types/user/types';
import NumberUtils from '../../../utils/numberUtils';

const ProjectPurchaseHistory: React.FC = () => {
  const { project } = useContext(ProjectContext);
  const windowSize = useWindowSize();
  const [tableHeight, setTableHeight] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);

  const {
    loading: purchHistoryLoading,
    error: purchHistoryError,
    data: { offsetProjects = [] } = {},
  } = useQuery<{
    offsetProjects: ProjectPurchaseHistoryType[];
  }>(GET_MARKETPLACE_PURCHASEHIST(), {
    variables: {
      filter: {
        status: ['COMPLETE'],
        projectId: [project.id],
      },
    },
    fetchPolicy: 'network-only',
  });

  const tableStructure: GroupableTableStructure<ProjectPurchaseHistoryType>[] =
    [
      {
        key: 'main',
        columns: [
          {
            key: 'requestedDateOnly',
            type: AlphaColumnFilter,
            display: 'Purchased On',
            searchable: true,
            searchPlaceholder: 'Search Dates',
          },
          {
            key: 'companyId',
            display: 'Company',
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Company',
            resolveFilterLabel: (value) => (
              <CompanyName
                companyId={value as ProjectPurchaseHistoryType['companyId']}
              />
            ),
            render: (c) => <CompanyNameWithLogo companyId={c.companyId} />,
          },
          {
            key: 'user',
            display: 'User',
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Users',
            resolveFilterLabel: (value) => (value as User).name,
            render: (c) =>
              c.user ? (
                <>
                  <UserImage user={c.user as User} size="sm" />{' '}
                  <span>{c.user.name}</span>
                </>
              ) : null,
          },
          {
            key: 'displayPrice',
            display: 'Price Purchased',
            type: AlphaColumnFilter,
          },
          {
            key: 'salesAmount',
            display: 'Total Purchased',
            searchable: false,
            type: AlphaColumnFilter,
          },
          {
            key: 'displayQuantity',
            display: 'Amount',
          },
          {
            key: 'displayAllocated',
            display: 'Allocated',
            style: {
              backgroundColor: '#F1F8FD',
            },
          },
          {
            key: 'displayRemaining',
            display: 'Remaining',
            style: {
              backgroundColor: '#F1F8FD',
            },
          },
        ],
      },
    ];

  const flattenedProjects = offsetProjects.map((x) => ({
    ...x,
    requestedDateOnly: formatDateStringUtc(x.requestedDate, 'MM/DD/YYYY'),
    salesAmount: NumberUtils.format(x.price * x.quantity, 'currency2'),
    displayPrice: NumberUtils.formatCurrencyAndUom(
      x.price,
      x.uom,
      x.project.uom,
    ),
    displayQuantity: NumberUtils.formatQuantityAndUom(
      x.quantity,
      x.uom,
      x.project.uom,
    ),
    displayAllocated: NumberUtils.formatQuantityAndUom(
      x.allocated,
      x.uom,
      x.project.uom,
    ),
    displayRemaining: NumberUtils.formatQuantityAndUom(
      x.remaining,
      x.uom,
      x.project.uom,
    ),
  }));

  if (windowSize && ref?.current) {
    const gridBounds = ref.current.getBoundingClientRect();
    const newHeight = Math.max(windowSize.height - gridBounds.y - 50, 300);

    if (newHeight !== tableHeight) {
      setTableHeight(newHeight);
    }
  }

  return (
    <Grid item container ref={ref}>
      <DataStateHandler loading={purchHistoryLoading} error={purchHistoryError}>
        <Grid
          container
          direction="column"
          style={{ height: tableHeight, position: 'relative' }}>
          <RoundedFlexBox>
            <GroupableSelectTable<ProjectPurchaseHistoryType>
              tableData={flattenedProjects}
              tableStructure={tableStructure}
              hideStickyCol
            />
          </RoundedFlexBox>
        </Grid>
      </DataStateHandler>
    </Grid>
  );
};

export default ProjectPurchaseHistory;
