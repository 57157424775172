import { useMutation } from '@apollo/client';
import { Grid, Paper, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext } from 'react';

import { UPSERT_NOTIFICATION } from '../../../graphql/communications/communications';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationDetailsHeader from '../CommunicationDetailsHeader';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import { Notification } from '../types';
import AppTextField from '../../common/AppTextField';
import HeadingInput from './HeadingInput';
import IncludeChildrenSwitch from './IncludeChildrenSwitch';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import PublishFields from './PublishFields';

type Props = {
  notification: Notification | undefined;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 64px',
  },
  form: {
    padding: '16px 0px !important',
    maxWidth: '100% !important',
  },
  paper: {
    padding: 24,
  },
}));

const linkTypes = [
  {
    value: 'NONE',
    label: 'No Link',
  },
  {
    value: 'COMMUNICATION',
    label: 'Communication',
  },
];

const schema = yup.object().shape({
  title: yup.string().required().max(160),
  content: yup.string().max(160),
  linkType: yup.string().oneOf(['NONE', 'COMMUNICATION']),
  linkId: yup.string(),
  publishDate: yup.string().nullable(),
});

const NotificationDetails: React.FC<Props> = ({
  notification = {},
  onClose,
}) => {
  const { refetchCommunicationsQuery, companyId, communications } = useContext(
    CommunicationsContext,
  );

  const { userHasAccess } = useContext(AuthorizationContext);

  const [upsert, { loading: upsertLoading, error: upsertError }] =
    useMutation(UPSERT_NOTIFICATION);

  const classes = useStyles();
  const initialValues = {
    title: '',
    content: '',
    linkType: 'NONE',
    linkId: ' ',
    sentDate: undefined,
    publishDate: '',
    __typename: 'Notification',
    includeChildren: false,
    ...(companyId ? { companyId } : {}),
    ...notification,
  };

  const canEdit =
    (companyId
      ? userHasAccess('Client.Communication.PushNotifications', 'EDIT')
      : userHasAccess('ZeroMe.Communication.PushNotifications', 'EDIT')) &&
    !initialValues.sentDate;

  const showIncludeChildren =
    companyId && userHasAccess('Api.AllowParentQueries', 'VIEW');

  return (
    <DataStateHandler loading={upsertLoading} error={upsertError}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values) => {
          const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            __typename,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            createdDate,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updatedDate,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            sentDate,
            ...input
          } = values;

          await upsert({
            // companyId must be undefined for global pushes
            variables: {
              input: { ...input, companyId: values.companyId || undefined },
            },
            onCompleted: () => onClose(),
          });
          refetchCommunicationsQuery();
        }}
        validateOnMount>
        {(props) => (
          <Grid
            container
            direction="column"
            className={classes.root}
            spacing={2}>
            <Grid item>
              <CommunicationDetailsHeader
                title={
                  notification
                    ? `${canEdit ? 'Edit' : 'View'} Details`
                    : 'Create New'
                }
                canEdit={canEdit}
                onClose={onClose}
                saveDisabled={!!props.values.sentDate || !props.isValid}
                onSave={props.submitForm}
              />
            </Grid>

            <Grid item className={classes.form}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {showIncludeChildren ? (
                      <IncludeChildrenSwitch disabled={!canEdit} />
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <HeadingInput label="Title" disabled={!canEdit} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">Link Type</Typography>
                    <Field
                      name="linkType"
                      label=""
                      component={AppTextField}
                      disabled={!canEdit}
                      fullWidth
                      select
                      options={linkTypes}
                    />
                  </Grid>
                  {props.values.linkType === 'COMMUNICATION' ? (
                    <Grid item xs={12}>
                      <Typography variant="body2">Communication</Typography>
                      <Field
                        name="linkId"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        fullWidth
                        select
                        options={[
                          { value: ' ', label: 'None' },
                          ...communications.map((c) => ({
                            value: c.id,
                            label: c.title,
                          })),
                        ]}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Typography variant="body2">Content</Typography>
                    <Field
                      name="content"
                      label=""
                      component={AppTextField}
                      disabled={!canEdit}
                      placeholder=""
                      fullWidth
                    />
                  </Grid>
                  {props.values.sentDate ? null : (
                    <Grid item xs={12}>
                      <PublishFields
                        minDate={new Date()}
                        alwaysAllowEditing
                        disabled={!canEdit}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box bgcolor="#F1F8FD" padding={2} borderRadius={1}>
                      <Typography variant="body2">
                        {props.values.sentDate
                          ? `This notification was sent on ${formatDateStringUtc(
                              props.values.sentDate || '',
                              'M/D/yy',
                            )}`
                          : 'If no publish date is set, this notification will be processed immediately, and should be received by users within a few minutes.'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Formik>
    </DataStateHandler>
  );
};

export default NotificationDetails;
