import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { orderBy, take } from 'lodash';
import AppCard from '../../common/AppCard/AppCard';
import { PROJECT_VIEWS } from '../../../graphql/projects/projects';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import ProjectDetailsDrawer from '../../common/Project/ProjectDetailsDrawer';
import ProjectBubbleChart from '../../common/ProjectBubbleChart';

const TopViewedProjectsCard: React.FC = () => {
  const [projectId, setProjectId] = useState<string | undefined>();

  const {
    loading,
    error,
    data: { searchProjects } = {},
  } = useQuery(PROJECT_VIEWS, {
    fetchPolicy: 'network-only',
  });

  const minViewDate = moment().subtract(90, 'days').format('yyyy-MM');

  const data = {
    id: 'root',
    name: 'root',
    color: '#fff',
    children: take(
      orderBy(
        (searchProjects?.items || [])
          .map((x) => ({
            id: x.id,
            name: x.title,
            count: Object.keys(x.views || {})
              .filter((v) => v > minViewDate)
              .reduce((acc, cur) => acc + x.views[cur], 0),
            color: x.categoryData.color,
            categoryId: x.categoryData.id,
          }))
          .filter((x) => x.count > 0),
        ['count'],
        ['desc'],
      ),
      10,
    ),
  };

  return (
    <>
      <Typography variant="h3" style={{ marginBottom: 16 }}>
        Top Viewed Projects
      </Typography>
      <AppCard height={680} headerProps={{ tooltipId: 'topViewedProjects' }}>
        <DataStateHandler loading={loading} error={error}>
          <ProjectBubbleChart
            data={data}
            onClick={(x) => (x.depth ? setProjectId(x.data.id) : undefined)}
          />
        </DataStateHandler>
      </AppCard>
      <ProjectDetailsDrawer
        editable
        open={!!projectId}
        projectId={projectId}
        handleClose={() => setProjectId(undefined)}
        disableCart
      />
    </>
  );
};

export default TopViewedProjectsCard;
