import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import _ from 'lodash';

import LeaderboardCategoryPointsItem from './LeaderboardCategoryPointsItem';
import AppInfo from '../../common/AppInfo';
import { Colors } from '../../../theme/shared';
import { UserPointBreakout } from '../../../types/user/types';

const useStyles = makeStyles(() => ({
  titleContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  title: {
    color: Colors.TertiaryText,
    textTransform: 'uppercase',
  },
}));

type Props = {
  userPoints: UserPointBreakout[] | undefined;
};

const LeaderboardCategoryPoints: React.FC<Props> = ({ userPoints }) => {
  const classes = useStyles();
  const categories = _.uniq(
    userPoints?.flatMap((up) => up.pointsByCategory.map((pbc) => pbc.category)),
  );
  const categoryPoints: React.ComponentProps<
    typeof LeaderboardCategoryPointsItem
  >['item'][] = categories?.map((category) => ({
    title: category,
    totalPoints:
      userPoints?.reduce(
        (acc, cur) =>
          acc +
          (cur.pointsByCategory.find((pbc) => pbc.category === category)
            ?.points || 0),
        0,
      ) || 0,
    subItems:
      userPoints?.map<
        React.ComponentProps<typeof LeaderboardCategoryPointsItem>['item']
      >((up) => ({
        title: up.month?.toString() || '',
        totalPoints:
          up.pointsByCategory.find((pbc) => pbc.category === category)
            ?.points || 0,
        subItems: up.pointBreakouts
          .filter((pb) => pb.category === category)
          .map((pb) => ({
            title: pb.eventTypeDescription ?? pb.eventType,
            totalPoints: pb.pointTotals,
          })),
      })) ?? [],
  }));

  return (
    <Grid container item direction="column">
      <Grid container item className={classes.titleContainer}>
        <Typography className={classes.title} variant="subtitle1">
          {t('contest.pointsEarnedByCategory')}
        </Typography>
        <AppInfo id="leaderboard_points_by_category" />
      </Grid>
      <Grid container item>
        {categoryPoints.map((cp) => (
          <LeaderboardCategoryPointsItem key={cp.title} item={cp} />
        ))}
      </Grid>
    </Grid>
  );
};

export default LeaderboardCategoryPoints;
