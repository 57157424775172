import React, { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';
import { t } from 'i18next';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { MemberToastContext } from '../contexts/MemberToastContext';
import { GET_USER_AWARDS } from '../graphql/user/user';
import { UserEventBadgeAward, UserEventPointsAward } from '../types/user/types';
import { MemberAlertContent } from '../components/member/myhabits/MemberAlert';

const useCheckForPointAwards = () => {
  const { lastUserAwardTimestamp, setLastUserAwardTimestamp, showToast } =
    useContext(MemberToastContext);

  const history = useHistory();
  const [getUserAwards] = useLazyQuery(GET_USER_AWARDS);

  return {
    checkForPointAwards: async (dontShowToast?: boolean) => {
      setLastUserAwardTimestamp(new Date().toISOString());

      const awards =
        (
          await getUserAwards({
            variables: {
              sinceTimestamp: lastUserAwardTimestamp,
            },
          })
        ).data?.userAwards ?? [];

      const result = {
        points: 0,
        toastShown: false,
      };

      if (awards.length) {
        // Set timestamp according to the awards received
        setLastUserAwardTimestamp(
          awards.reduce(
            (acc, cur) => (cur.timestamp > acc ? cur.timestamp : acc),
            '',
          ),
        );

        const messages: MemberAlertContent['messages'] = [];

        const pointsAwards = awards.filter(
          // eslint-disable-next-line no-underscore-dangle
          (x) => x.__typename === 'UserEventPointsAward',
        ) as UserEventPointsAward[];

        const badgeAwards = awards.filter(
          // eslint-disable-next-line no-underscore-dangle
          (x) => x.__typename === 'UserEventBadgeAward',
        ) as UserEventBadgeAward[];

        if (pointsAwards.length) {
          result.points = pointsAwards.reduce(
            (acc, cur) => cur.points + acc,
            0,
          );

          const title =
            result.points > 1
              ? t('award.earnedManyPoints', {
                  points: result.points,
                })
              : t('award.earnedOnePoint');

          const distinctMessages = [
            ...new Set(pointsAwards.map((x) => x.awardMessage).filter(Boolean)),
          ];

          const formatMessage = (message: string) => {
            if (distinctMessages.length > 1) {
              return ` - ${message}\n\n`;
            }
            return message;
          };

          const description = distinctMessages.reduce(
            (acc, cur) => acc + formatMessage(cur),
            '',
          );

          messages.push({
            title,
            description,
          });
        }

        if (badgeAwards.length) {
          const title =
            badgeAwards.length > 1
              ? t('award.earnedManyBadges', {
                  badges: badgeAwards.length,
                })
              : t('award.earnedOneBadge');
          messages.push({
            title,
            description: (
              <Button
                size="small"
                variant="text"
                style={{
                  padding: 0,
                  margin: 0,
                }}
                onClick={() => history.push(`/member/badge`)}>
                {t('award.viewBadges')}
              </Button>
            ),
          });
        }

        if (!dontShowToast && messages.length) {
          result.toastShown = true;
          showToast({
            messages,
          });
        }
      }

      return result;
    },
  };
};

export default useCheckForPointAwards;
