import { useQuery } from '@apollo/client';

import React, { useContext } from 'react';
import { Alert, AlertTitle } from '@mui/material';

import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import { DashboardContext } from '../../../../contexts/DashboardContext';
import { COMPANY_PROJECTED_EMISSIONS } from '../../../../graphql/dashboard/companyProjectedEmissions';
import { AuthorizationContext } from '../../../../contexts/AuthorizationContext';

const ProjectedEmissions: React.FC = () => {
  const { companyId, includeChildren } = useCurrentCompany();
  const { company } = useContext(AuthorizationContext);

  const { endDate } = useContext(DashboardContext);

  const {
    data: {
      companyProjectedEmissions: { total = 0 } = {},
      company: {
        lifetimeBudget = {
          spentMt: 0,
          allocatedOffPlatformMt: 0,
          allocatedMt: 0,
        },
      } = {},
    } = {},
    loading,
    error,
  } = useQuery(COMPANY_PROJECTED_EMISSIONS, {
    variables: {
      companyId,
      date: endDate,
      includeChildren,
      includeDemo: company.isDemoCompany,
    },
  });

  const allocatedMt =
    (lifetimeBudget.allocatedMt || 0) -
    (lifetimeBudget.allocatedOffPlatformMt || 0);

  const unallocatedMt = (lifetimeBudget.spentMt || 0) - allocatedMt;

  const projectedTotal = total / 1000;

  if (projectedTotal < unallocatedMt || lifetimeBudget.spentMt === 0) {
    return null;
  }

  return (
    <DataStateHandler loading={loading} error={error}>
      <Alert severity="warning" sx={{ mb: 2 }}>
        <AlertTitle>URGENT ATTENTION NEEDED</AlertTitle>
        Your carbon reduction project inventory balance is at risk of falling
        below the required threshold before this month ends. Please replenish
        your inventory promptly to ensure you have a sufficient balance. For
        further assistance, please reach out to us at support@zerome.com.
      </Alert>
    </DataStateHandler>
  );
};

export default ProjectedEmissions;
