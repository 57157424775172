import React, { useEffect } from 'react';
import Quill from 'quill';
import { useQuill } from 'react-quilljs';
import ImageCompress from 'quill-image-compress';
import 'quill/dist/quill.snow.css';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Colors } from '../../../theme/shared';
import { AppRichTextFieldProps } from './types';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 8,
  },
  label: {
    position: 'relative',
    bottom: 8,
  },
  editTextBox: {
    height: 350,
    borderRadius: 8,
    border: `2px solid ${Colors.CobaltBlue}`,
    maxWidth: 500,
  },
  displayTextBox: {
    maxWidth: 500,
    overflowWrap: 'break-word',
    overflowX: 'auto',
  },
  '@global': {
    '.ql-container.ql-snow, .ql-toolbar.ql-snow': {
      border: '0 !important',
    },
  },
}));

const AppRichTextField = ({
  labelText,
  disabled,
  field,
  form,
}: AppRichTextFieldProps) => {
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],

        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image'],
        [{ color: [] }, { background: [] }],

        ['clean'],
      ],
      clipboard: {
        matchVisual: false,
      },
      imageCompress: {
        quality: 0.7,
        maxWidth: 1000,
        maxHeight: 1000,
        imageType: 'image/jpeg',
      },
    },
    formats: [
      'bold',
      'italic',
      'underline',
      'strike',
      'align',
      'list',
      'indent',
      'header',
      'link',
      'color',
      'background',
      'clean',
      'image',
    ],
  });

  if (Quill && !quill) {
    Quill.register('modules/imageCompress', ImageCompress, true);
  }

  const classes = useStyles();
  useEffect(() => {
    const value = field.value ?? form.initialValues[field.name];
    if (quill?.clipboard.dangerouslyPasteHTML && value) {
      quill.clipboard.dangerouslyPasteHTML(value);
    }
  }, [quill?.clipboard.dangerouslyPasteHTML]);

  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        form.setFieldValue(field.name, quill.root.innerHTML);
      });
    }
  }, [quill]);

  if (disabled) {
    return (
      <Box
        className={classes.displayTextBox}
        dangerouslySetInnerHTML={{
          __html: field.value ?? '',
        }}
      />
    );
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="body2">
        {labelText}
      </Typography>
      <Box className={classes.editTextBox}>
        <div style={{ height: 265 }} ref={quillRef} />
      </Box>
    </Box>
  );
};

export default AppRichTextField;
