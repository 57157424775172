import { gql, TypedDocumentNode } from '@apollo/client';
import {
  COMMUNICATION_RATINGS_CORE,
  QUIZ_ANSWER_FIELDS,
  QUIZ_METRIC_FIELDS,
} from './communications';
import {
  CommunicationRatingAggregates,
  LocalizedQuiz,
  MemberCommunicationListItem,
} from '../../components/communications/types';

export const GET_DISCOVER_COMMUNICATIONS = gql`
  query GET_DISCOVER_COMMUNICATIONS($companyId: String, $sortBy: CommunicationSortByOption, $sortDirection: SortDirection, $categoryIds: [String]) {
    communications(options: { companyId: $companyId, isPublished: true, sortBy: $sortBy, sortDirection: $sortDirection, categoryIds: $categoryIds }) {
      continuationToken
      items {
        id
        title
        author
        communicationType
        isFeatured
        contentUploadUrl
        headerImageUrls {
          large
          medium
          thumb
        }
        headerImageBlurHash
        publishDate
        createdDate
        updatedDate
        ratings {
          ${COMMUNICATION_RATINGS_CORE}
        }
      }
    }
  }
` as TypedDocumentNode<{
  communications: {
    items: MemberCommunicationListItem[];
    continuationToken: string;
  };
}>;

export const GET_DISCOVER_COMMUNICATION_DETAILS = gql`
  query communication($id: String!) {
    communication(id: $id) {
      id
      title
      author
      content
      readingTime
      communicationType
      isFeatured
      contentUploadUrl
      headerImageUrls {
        large
        medium
        thumb
      }
      headerImageBlurHash
      publishDate
      relatedArticles {
        id
        title
        author
        communicationType
        headerImageUrls {
          large
          medium
          thumb
        }
        ratings {
          ${COMMUNICATION_RATINGS_CORE}
        }
      }
      relatedHabits {
        id
        name
        category {
          id
          label
          primaryColor
          secondaryColor
          iconUrl
          fillIconUrl
        }
        imageUrls {
          large
          medium
        }
      }
      createdDate
      updatedDate
      ratings {
        ${COMMUNICATION_RATINGS_CORE}
      }
      quiz {
        ${QUIZ_ANSWER_FIELDS}
      }
    }
  }
` as TypedDocumentNode<{
  communication: MemberCommunicationListItem & {
    content: string;
    readingTime: number;
  };
}>;

export const GET_WELCOME_VIDEO = gql`
  query getWelcomeVideo {
    getWelcomeVideo {
      id
      contentUploadUrl
    }
  }
` as TypedDocumentNode<{
  getWelcomeVideo: {
    id: string;
    contentUploadUrl: string;
  } | null;
}>;

export const UPDATE_COMMUNICATION_RATING = gql`
  mutation UPDATE_COMMUNICATION_RATING(
    $communicationId: String!
    $score: Int!
  ) {
    rateCommunication(communicationId: $communicationId, score: $score) {
      ${COMMUNICATION_RATINGS_CORE}
    }
  }
` as TypedDocumentNode<
  { rateCommunication: CommunicationRatingAggregates },
  { communicationId: string; score?: number }
>;

export const UPDATE_COMMUNICATION_VIEWS = gql`
  mutation UPDATE_COMMUNICATION_VIEWS($communicationId: String!) {
    viewCommunication(communicationId: $communicationId) {
      id
    }
  }
` as TypedDocumentNode<
  { viewCommunication: { id: string } },
  { communicationId: string }
>;

export const GET_MEMBER_QUIZ = gql`
  query GET_MEMBER_QUIZ(
    $quizId: String!,
  ) {
    memberQuiz(
      quizId: $quizId
    ) {
      ${QUIZ_ANSWER_FIELDS}
    }
  }
` as TypedDocumentNode<{ memberQuiz: LocalizedQuiz }>;

export const GET_MEMBER_QUIZ_METRICS = gql`
  query GET_MEMBER_QUIZ_METRICS(
    $quizId: String!,
  ) {
    memberQuiz(
      quizId: $quizId
    ) {
      ${QUIZ_METRIC_FIELDS}
    }
  }
` as TypedDocumentNode<{ memberQuiz: LocalizedQuiz }>;

export const GET_MEMBER_QUIZZES = gql`
  query GET_MEMBER_QUIZZES(
    $companyId: String,
    $types: [QuizType],
    $isActive: Boolean,
    $sortBy: QuizSortByOption,
    $sortDirection: SortDirection
  ) {
    memberQuizzes(
      options: {
        companyId: $companyId,
        types: $types,
        isActive: $isActive,
        sortBy: $sortBy,
        sortDirection: $sortDirection
      }
    ) {
      continuationToken
      items {
        ${QUIZ_ANSWER_FIELDS}
      }
    }
  }
` as TypedDocumentNode<{
  memberQuizzes: {
    items: LocalizedQuiz[];
    continuationToken: string;
  };
}>;

export default {
  GET_DISCOVER_COMMUNICATIONS,
};
