import { Box, Typography, Grid, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';
import { isAfter } from 'date-fns';

import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import SVG from '../../assets/svg';

import { GroupableTableStructure } from '../common/Table/types';
import CommunicationDetails from './CommunicationDetails';
import { CommunicationListItem, CommunicationType } from './types';
import CreateCommunicationButton from './CreateCommunicationButton';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { formatDateStringUtc } from '../../utils/dateUtils';
import PageTitle from '../common/PageTitle';
import CommunicationTypeIcon from '../common/CommunicationTypeIcon';
import CommunicationStarRating from '../member/discover/CommunicationStarRating';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 16,
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const dateFormat = 'M/D/yy';

const getTableStructure =
  (): GroupableTableStructure<CommunicationListItem>[] => [
    {
      key: 'main',
      columns: [
        {
          key: 'communicationType',
          display: 'Type',
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) => {
            switch (value) {
              case CommunicationType.AUDIO:
                return 'Audio';
              case CommunicationType.BLOG_POST:
                return 'Article';
              case CommunicationType.HINT:
                return 'Hints & Tips';
              case CommunicationType.VIDEO:
                return 'Video';
              default:
                return 'Misc';
            }
          },
          render: (c) => (
            <CommunicationTypeIcon communicationType={c.communicationType} />
          ),
        },
        {
          key: 'title',
          display: 'Title',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Title',
          render: (c) => <Typography variant="body2">{c.title}</Typography>,
        },
        {
          key: 'status',
          display: 'Status',
          type: AlphaColumnFilter,
          render: ({ publishDate }) => {
            if (!publishDate || isAfter(new Date(publishDate), new Date())) {
              return <Typography variant="body2">Draft</Typography>;
            }
            return (
              <Typography variant="body2" style={{ color: '#398360' }}>
                Published
              </Typography>
            );
          },
        },
        {
          key: 'updatedDate',
          type: AlphaColumnFilter,
          display: 'Created/Modified',
          resolveFilterLabel: (value) =>
            value
              ? formatDateStringUtc(
                  value as CommunicationListItem['updatedDate'],
                  dateFormat,
                )
              : '',
          render: (c) => (
            <Typography variant="body2">
              {c.updatedDate
                ? formatDateStringUtc(c.updatedDate, dateFormat)
                : ''}
            </Typography>
          ),
        },
        {
          key: 'publishDate',
          type: AlphaColumnFilter,
          display: 'Published',
          resolveFilterLabel: (value) =>
            value
              ? formatDateStringUtc(
                  value as CommunicationListItem['publishDate'],
                  dateFormat,
                )
              : '',
          render: (c) => (
            <Typography variant="body2">
              {c.publishDate
                ? formatDateStringUtc(c.publishDate, dateFormat)
                : ''}
            </Typography>
          ),
        },
        {
          key: 'viewsCount',
          display: 'Viewed',
          render: (c: CommunicationListItem) => (
            <Typography variant="body2">
              {Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 0,
              }).format(c.viewsCount)}
            </Typography>
          ),
        },
        {
          key: 'ratings.global.count',
          display: 'Total Ratings',
          render: (c: CommunicationListItem) => (
            <Typography variant="body2">
              {Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 0,
              }).format(c.ratings.global?.count || 0)}
            </Typography>
          ),
        },
        {
          key: 'ratings.global.score',
          display: 'Avg Rating',
          width: 135,
          render: (c: CommunicationListItem) =>
            c.ratings.global ? (
              <CommunicationStarRating rating={c.ratings.global} size="md" />
            ) : undefined,
        },
        {
          key: 'filterableIsFeatured',
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) => (value === 'true' ? 'Yes' : 'No'),
          display: 'Featured',
          render: (c: CommunicationListItem) => (
            <Box paddingLeft="20px">
              {c.isFeatured ? <SVG.StarFilled /> : <SVG.StarOutline />}
            </Box>
          ),
        },
      ],
    },
  ];

const CommunicationsList: React.FC = () => {
  const [communicationDetailsProps, setCommunicationDetailsProps] = useState<{
    id: string;
    communicationType: CommunicationType;
  } | null>(null);
  const classes = useStyles();
  const { communications, companyId } = useContext(CommunicationsContext);
  const { userHasAccess } = useContext(AuthorizationContext);

  const canEdit = companyId
    ? userHasAccess('Client.Communication.Discover', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Discover', 'EDIT');

  return (
    <>
      <Box px={8} py={4}>
        <Grid container direction="column">
          <Grid item>
            <PageTitle title="Discover">
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {canEdit && (
                  <CreateCommunicationButton
                    onSelected={(communicationType) =>
                      setCommunicationDetailsProps({
                        id: 'new',
                        communicationType,
                      })
                    }
                  />
                )}
              </>
            </PageTitle>
          </Grid>
          <Grid item className={classes.table}>
            <Grid
              container
              direction="column"
              style={{ height: 'calc(100vh - 150px)' }}>
              <RoundedFlexBox>
                <GroupableSelectTable<CommunicationListItem>
                  tableData={communications.map((i) => {
                    let status: 'Draft' | 'Published' = 'Published';
                    if (
                      !i.publishDate ||
                      isAfter(new Date(i.publishDate), new Date())
                    ) {
                      status = 'Draft';
                    }
                    return {
                      ...i,
                      filterableIsFeatured: i.isFeatured ? 'true' : 'false',
                      status,
                    };
                  })}
                  tableStructure={getTableStructure()}
                  hideStickyCol
                  onRowClick={({ id, communicationType }) => {
                    setCommunicationDetailsProps({ id, communicationType });
                  }}
                />
              </RoundedFlexBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        open={!!communicationDetailsProps}
        onClose={() => setCommunicationDetailsProps(null)}
        anchor="right">
        <Box className={classes.drawer}>
          {communicationDetailsProps && (
            <CommunicationDetails
              communicationType={communicationDetailsProps.communicationType}
              communicationId={communicationDetailsProps.id}
              onClose={() => setCommunicationDetailsProps(null)}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default CommunicationsList;
