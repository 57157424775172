import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Drawer, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import {
  ProjectPurchaseHistory,
  ProjectPurchaseStatus,
} from '../../../types/offsetProject/types';
import { ProjectPurchaseType } from '../../../types/project/types';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import NumberUtils from '../../../utils/numberUtils';
import { projectStatusLabel } from '../../../utils/projectUtils';
import CompanyName from '../../common/Company/CompanyName';
import CompanyNameWithLogo from '../../common/Company/CompanyNameWithLogo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';
import PurchaseHistoryStatusChip from '../../common/Project/PurchaseHistoryStatusChip';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import {
  ExportableGroupableTableStructure,
  ExportableSelectTableColumn,
} from '../../common/Table/types';
import RoundedFlexBox from '../RoundedFlexBox';
import FulfillOffsetRequestDetails from './FulfillOffsetRequestDetails';
import PendingOffersRowOptions from './PendingOffersRowOptions';
import UpdateOffsetRequestDetails from './UpdateOffsetRequestDetails';
import UploadProjectPurchaseHistoryCsvModal from './UploadProjectPurchaseHistoryCsvModal';
import {
  APPROVE_PURCHASE,
  UNAPPROVE_PURCHASE,
} from '../../../graphql/projects/projectMutations';

const useStyles = makeStyles((theme) => ({
  filterButton: {
    padding: 16,
  },
  linkButton: {
    color: '#3E7DA2',
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

type Props = {
  projects: ProjectPurchaseHistory[];
  refetch: () => void;
};

const PendingOrders: React.FC<Props> = ({ projects, refetch }) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const [purchaseType, setPurchaseType] = useState(ProjectPurchaseType.COMPANY);
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const [approve, { loading: approveLoading, error: approveError }] =
    useMutation(APPROVE_PURCHASE);
  const [unapprove, { loading: unapproveLoading, error: unapproveError }] =
    useMutation(UNAPPROVE_PURCHASE);

  const loading = categoriesLoading || approveLoading || unapproveLoading;

  const error = categoriesError || approveError || unapproveError;

  const flattenedProjects = projects.map((x) => ({
    ...x,
    projectTitle: x.project.title,
    projectFriendlyId: x.project.projectId,
    projectSource: x.project.source,
    requestedByUserEmail: x.requestedByUser?.email,
    requestedByUserName: x.requestedByUser?.name,
    requestedDateOnly: formatDateStringUtc(x.requestedDate, 'MM/DD/YYYY'),
    approvalDateOnly: x.approvalDate
      ? formatDateStringUtc(x.approvalDate, 'MM/DD/YYYY')
      : '',
    exportedToCompassDateOnly: x.exportedToCompassDate
      ? formatDateStringUtc(x.exportedToCompassDate, 'MM/DD/YYYY')
      : '',
    approvedByUserEmail: x.approvedByUser?.email,
    approvedByUserName: x.approvedByUser?.name,
    totalSpent: x.price * x.quantity,
    displayQuantity: NumberUtils.formatQuantityAndUom(
      x.quantity,
      x.uom,
      x.project.uom,
    ),
    displayPrice: NumberUtils.formatCurrencyAndUom(
      x.price,
      x.uom,
      x.project.uom,
    ),
  }));

  // For the CSV import modal
  const [importOpen, setImportOpen] = useState(false);
  const openImportDialog = () => setImportOpen(true);
  const closeImportDialog = () => {
    setImportOpen(false);
  };

  // For the Retirement Date/DN drawer
  const [selectedProject, setSelectedProject] =
    useState<ProjectPurchaseHistory>();

  const [panelState, setPanelState] = useState<'closed' | 'fulfill' | 'update'>(
    'closed',
  );

  const onPanelClosed = () => {
    setSelectedProject(undefined);
    setPanelState('closed');
  };

  const onApprove = (projectPurchaseId: string) => {
    approve({
      variables: {
        projectPurchaseId,
      },
      onCompleted: () => refetch(),
    });
  };

  const onUnapprove = (projectPurchaseId: string) => {
    unapprove({
      variables: {
        projectPurchaseId,
      },
      onCompleted: () => refetch(),
    });
  };

  const tableStructure: ExportableGroupableTableStructure<
    typeof flattenedProjects[0]
  >[] = [
    {
      key: 'sticky',
      sticky: true,
      columns: [
        {
          key: 'serialNumber',
          display: 'Serial Number',
          searchable: false,
          isVisible: false,
          export: { format: ({ serialNumber }) => serialNumber ?? '' },
        },
        {
          key: 'completedDate',
          display: 'Retirement Date',
          searchable: false,
          isVisible: false,
          export: { format: ({ completedDate }) => completedDate ?? '' },
        },
        {
          key: 'requestedDateOnly',
          type: AlphaColumnFilter,
          display: 'Purchase Requested',
          searchable: true,
          searchPlaceholder: 'Search Dates',
          width: 140,
          stickyLeft: 0,
        },
        {
          key: 'projectFriendlyId',
          display: 'ID',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Project IDs',
          width: 100,
          stickyLeft: 140,
        },
        {
          key: 'projectCategoryId',
          display: 'Category',
          type: AlphaColumnFilter,
          width: 130,
          stickyLeft: 240,
          resolveFilterLabel: (value) => (
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CategoryIconSmall categoryId={value as string} />
              </Grid>
              <Grid item>
                {projectCategories.find((cat) => cat.id === value)?.label || ''}
              </Grid>
            </Grid>
          ),
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
          export: {
            format: ({ projectCategoryId }) =>
              projectCategories.find((cat) => cat.id === projectCategoryId)
                ?.label || '',
            preview: true,
          },
        },
        {
          key: 'projectTitle',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Projects',
          export: { preview: true },
          width: 300,
          stickyLeft: 370,
        },
      ],
    },
    {
      key: 'main',
      columns: [
        {
          key: 'projectSource',
          display: 'Source',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Source',
        },
        {
          key: 'displayPrice',
          display: 'Purchase Price',
          type: AlphaColumnFilter,
          export: { format: ({ price }) => price ?? '' },
        },
        {
          key: 'totalSpent',
          display: 'Total Spent',
          type: AlphaColumnFilter,
          resolveFilterLabel: (data) =>
            NumberUtils.format(data as number, 'currency2'),
          format: (data) => NumberUtils.format(data as number, 'currency2'),
          export: { format: ({ totalSpent }) => totalSpent ?? '' },
        },
        {
          key: 'creditUsed',
          display: 'Credit Used',
          render: ({ totalSpent, invoicedAmount }) => (
            <Typography noWrap variant="body2">
              {NumberUtils.format(
                totalSpent - (invoicedAmount || 0),
                'currency2',
              )}
            </Typography>
          ),
        },
        {
          key: 'invoicedAmount',
          display: 'Invoiced Amount',
          resolveFilterLabel: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
          format: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
        },
        {
          key: 'displayQuantity',
          display: 'Amount Requested',
          export: { format: ({ quantity }) => quantity ?? '' },
        },
        {
          key: 'companyId',
          display: 'Requested By',
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) => (
            <CompanyName
              companyId={value as ProjectPurchaseHistory['companyId']}
            />
          ),
          render: (c) => <CompanyNameWithLogo companyId={c.companyId} />,
          export: { format: ({ company: c }) => c?.name },
        },
        {
          key: 'requestedByUserName',
          display: 'Requester Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search by Requester',
          export: {
            format: ({ requestedByUserName }) => requestedByUserName || '',
          },
        },
        {
          key: 'requestedByUserEmail',
          display: 'Requester Email',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search by Requester Email',
          export: {
            format: ({ requestedByUserEmail }) => requestedByUserEmail || '',
          },
        },
        {
          key: 'status',
          display: 'Status',
          type: AlphaColumnFilter,
          resolveFilterLabel: (c) =>
            projectStatusLabel(c as ProjectPurchaseStatus),
          render: (c) => <PurchaseHistoryStatusChip status={c.status} />,
          export: { format: ({ status }) => status },
        },
        {
          key: 'approvalDateOnly',
          type: AlphaColumnFilter,
          display: 'Approval Date',
          searchable: true,
          searchPlaceholder: 'Search Dates',
        },
        {
          key: 'approvedByUserName',
          display: 'Approved By Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search by Approver',
          export: {
            format: ({ approvedByUserName }) => approvedByUserName || '',
          },
        },
        {
          key: 'approvedByUserEmail',
          display: 'Approved By Email',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search by Approver Email',
          export: {
            format: ({ approvedByUserEmail }) => approvedByUserEmail || '',
          },
        },
        {
          key: 'idDoNotModify',
          display: '*UID-DO-NOT-MODIFY*',
          searchable: false,
          isVisible: false,
          export: { format: ({ id }) => id },
        },
        {
          key: 'companyIdDoNotModify',
          display: '*C-DO-NOT-MODIFY*',
          searchable: false,
          isVisible: false,
          export: { format: ({ companyId }) => companyId },
        },
        ...(userHasAccess('ZeroMe.Marketplace', 'EDIT')
          ? [
              {
                key: 'options',
                display: '',
                render: (c) => (
                  <PendingOffersRowOptions
                    onFulfill={
                      c.status === ProjectPurchaseStatus.APPROVED
                        ? () => {
                            setSelectedProject(c);
                            setPanelState('fulfill');
                          }
                        : undefined
                    }
                    onApprove={
                      c.status === ProjectPurchaseStatus.APPROVED
                        ? undefined
                        : () => onApprove(c.id)
                    }
                    onUnapprove={
                      c.status === ProjectPurchaseStatus.APPROVED
                        ? () => onUnapprove(c.id)
                        : undefined
                    }
                    onUpdate={
                      c.status !== ProjectPurchaseStatus.APPROVED
                        ? () => {
                            setSelectedProject(c);
                            setPanelState('update');
                          }
                        : undefined
                    }
                  />
                ),
                export: false,
              } as ExportableSelectTableColumn<typeof flattenedProjects[0]>,
            ]
          : []),
      ],
    },
  ];

  const grouped = _.groupBy(
    flattenedProjects,
    ({ purchaseType: pt }) => pt || ProjectPurchaseType.COMPANY,
  );

  const pendingOrders = grouped?.[purchaseType] || [];

  return (
    <>
      {importOpen && (
        <UploadProjectPurchaseHistoryCsvModal
          refetch={() => refetch()}
          onClose={() => closeImportDialog()}
        />
      )}
      <DataStateHandler loading={loading} error={error}>
        <>
          <Grid
            container
            direction="column"
            style={{ height: 'calc(100vh - 190px)', position: 'relative' }}>
            <RoundedFlexBox>
              <GroupableSelectTable<typeof flattenedProjects[0]>
                tableData={pendingOrders}
                tableStructure={tableStructure}
                queryVariables={{ 'sort-list': projectCategories }}
                exportable={userHasAccess('ZeroMe.Marketplace', 'EDIT')}
                exportOptions={{
                  contentPlural: 'Credit Requests',
                  filename: 'credit_requests',
                }}
                actionItems={
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        variant={
                          purchaseType === ProjectPurchaseType.COMPANY
                            ? 'contained'
                            : 'outlined'
                        }
                        color="primary"
                        onClick={() =>
                          setPurchaseType(ProjectPurchaseType.COMPANY)
                        }
                        startIcon={<SVG.Company />}
                        className={classes.filterButton}>
                        Companies (
                        {grouped?.[ProjectPurchaseType.COMPANY]?.length || 0})
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          purchaseType === ProjectPurchaseType.USER
                            ? 'contained'
                            : 'outlined'
                        }
                        color="primary"
                        onClick={() =>
                          setPurchaseType(ProjectPurchaseType.USER)
                        }
                        startIcon={<SVG.User />}
                        className={classes.filterButton}>
                        Individuals (
                        {grouped?.[ProjectPurchaseType.USER]?.length || 0})
                      </Button>
                    </Grid>
                  </Grid>
                }
                importExportActionItems={
                  <Button variant="text" onClick={openImportDialog}>
                    <Typography variant="body2">
                      <SVG.FileImport style={{ marginBottom: -3 }} /> Import
                      Request Fulfillment
                    </Typography>
                  </Button>
                }
                exportButtonUI={
                  <>
                    <SVG.FileExport style={{ marginBottom: -3 }} /> Export
                    Requests
                  </>
                }
              />
            </RoundedFlexBox>
          </Grid>
          <Drawer
            open={!!selectedProject}
            onClose={() => setSelectedProject(undefined)}
            anchor="right">
            <Box className={classes.drawer}>
              {selectedProject && panelState === 'fulfill' && (
                <FulfillOffsetRequestDetails
                  projectHistory={selectedProject}
                  onSave={() => refetch()}
                  onClose={onPanelClosed}
                />
              )}
              {selectedProject && panelState === 'update' && (
                <UpdateOffsetRequestDetails
                  projectHistory={selectedProject}
                  onSave={() => refetch()}
                  onClose={onPanelClosed}
                />
              )}
            </Box>
          </Drawer>
        </>
      </DataStateHandler>
    </>
  );
};

export default PendingOrders;
