import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext, getIn, FieldArray, Field } from 'formik';

import {
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import { HabitCondition, HabitListItem } from '../../types/habit/types';
import SVG from '../../assets/svg';
import HabitConditionQuestionSelectorModal from './HabitConditionQuestionSelectorModal';
import { QuestionProfile } from '../../types/question/types';
import HabitConditionHabitSelectorModal from './HabitConditionHabitSelectorModal';
import HabitNestedContainer from './HabitNestedContainer';
import HabitDisplayQuestionAnswer from './HabitDisplayQuestionAnswer';
import AppTextField from '../common/AppTextField';

const useStyles = makeStyles(() => ({
  dialog: {
    width: 600,
    padding: 16,
  },
  questionRow: {
    marginBottom: 16,
  },
  field: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
}));

type Props = {
  onRemove?: () => void;
  path: string;
  questions: QuestionProfile[];
  habits: HabitListItem[];
  canEdit: boolean;
  depth?: number;
};

const HabitConditionComponent: React.FC<Props> = ({
  onRemove,
  path,
  questions,
  habits,
  canEdit,
  depth = 0,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const condition = getIn(values, path) as HabitCondition;

  const [dialogState, setDialogState] = useState<
    'CLOSED' | 'ADDING_QUESTION' | 'ADDING_HABIT'
  >('CLOSED');

  const classes = useStyles();
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body2">Condition Type</Typography>
          <TextField
            label=""
            fullWidth
            className={classes.field}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              const type = e.target.value;

              if (type) {
                setFieldValue(path, { type });
              } else {
                setFieldValue(path, onRemove ? {} : undefined);
              }
            }}
            value={condition?.type || ''}
            select>
            <MenuItem key="" value="">
              Select a Type
            </MenuItem>
            <MenuItem key="and" value="and">
              And
            </MenuItem>
            <MenuItem key="hasLifestyleHabit" value="hasLifestyleHabit">
              Has Lifestyle Habit
            </MenuItem>
            <MenuItem key="not" value="not">
              Not
            </MenuItem>
            <MenuItem key="or" value="or">
              Or
            </MenuItem>
            <MenuItem key="questionAnswer" value="questionAnswer">
              Question Answer
            </MenuItem>
            <MenuItem
              key="hasUserPropertyEqualTo"
              value="hasUserPropertyEqualTo">
              Has User Property Equal To...
            </MenuItem>
          </TextField>
        </Grid>
        <FieldArray
          name={`${path}.conditions`}
          render={(arrayHelpers) => (
            <>
              <Grid item>
                <Grid container spacing={2}>
                  {['or', 'and'].includes(condition?.type || '') ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        arrayHelpers.push({ type: '' });
                      }}>
                      + Add
                    </Button>
                  ) : null}
                  {onRemove ? (
                    <IconButton onClick={onRemove} size="large">
                      <SVG.Delete />
                    </IconButton>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                {condition?.type === 'not' ? (
                  <HabitNestedContainer title="Not Condition" depth={depth}>
                    <Grid item xs={12}>
                      <HabitConditionComponent
                        questions={questions}
                        habits={habits}
                        path={`${path}.condition`}
                        depth={depth + 1}
                        canEdit={canEdit}
                      />
                    </Grid>
                  </HabitNestedContainer>
                ) : null}
                {['or', 'and'].includes(condition?.type || '') ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {(condition?.type === 'or' || condition?.type === 'and') &&
                      condition?.conditions?.map((c, index) => (
                        <HabitNestedContainer
                          title={`${
                            condition?.type === 'and' ? 'And' : 'Or'
                          } Condition ${index + 1}`}
                          depth={depth}>
                          <Grid item xs={12}>
                            <HabitConditionComponent
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              path={`${path}.conditions[${index}]`}
                              onRemove={() => {
                                arrayHelpers.remove(index);
                              }}
                              questions={questions}
                              habits={habits}
                              depth={depth + 1}
                              canEdit={canEdit}
                            />
                          </Grid>
                        </HabitNestedContainer>
                      ))}
                  </>
                ) : null}
                {condition?.type === 'questionAnswer' ? (
                  <HabitDisplayQuestionAnswer
                    questionAnswer={condition}
                    questions={questions}
                    onEdit={() => setDialogState('ADDING_QUESTION')}
                  />
                ) : null}
                {condition?.type === 'hasLifestyleHabit' ? (
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    className={classes.questionRow}
                    alignItems="flex-end">
                    <Grid item>
                      <Typography variant="body2">Habit</Typography>
                      <Typography variant="body1">
                        {habits?.find((c) => c.id === condition.habitId)
                          ?.name || condition.habitId}
                      </Typography>
                    </Grid>
                    <IconButton
                      onClick={() => setDialogState('ADDING_HABIT')}
                      size="large">
                      <SVG.Edit />
                    </IconButton>
                  </Grid>
                ) : null}
                {condition?.type === 'hasUserPropertyEqualTo' ? (
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    className={classes.questionRow}
                    alignItems="flex-end">
                    <Grid item xs={12}>
                      <Typography variant="body2">Property</Typography>
                      <TextField
                        label=""
                        fullWidth
                        className={classes.field}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                          setFieldValue(`${path}.propertyName`, e.target.value);
                        }}
                        value={condition?.propertyName || ''}
                        select>
                        <MenuItem key="" value="">
                          Select a Property
                        </MenuItem>
                        <MenuItem key="climateZone" value="climateZone">
                          climateZone
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Value</Typography>
                      <Field
                        name={`${path}.propertyValue`}
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        className={classes.field}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </>
          )}
        />
      </Grid>
      <Dialog
        onClose={() => {
          setDialogState('CLOSED');
        }}
        open={dialogState === 'ADDING_QUESTION'}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <HabitConditionQuestionSelectorModal
            questions={questions}
            path={path}
            onSave={({ questionId, answerId, comment }) => {
              const existing = getIn(values, path);
              setFieldValue(path, {
                ...existing,
                questionId,
                answerId,
                comment,
              });
              setDialogState('CLOSED');
            }}
          />
        </Box>
      </Dialog>
      <Dialog
        onClose={() => {
          setDialogState('CLOSED');
        }}
        open={dialogState === 'ADDING_HABIT'}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <HabitConditionHabitSelectorModal
            habits={habits}
            path={path}
            onSave={({ habitId }) => {
              const existing = getIn(values, path);
              setFieldValue(path, {
                ...existing,
                habitId,
              });
              setDialogState('CLOSED');
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default HabitConditionComponent;
