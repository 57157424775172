import { Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import SVG from '../../../assets/svg-v2';
import {
  UserActivity,
  UserActivityType,
} from '../../../types/user/activityTypes';
import { Colors } from '../../../theme/shared';

type UserActivityRendererProps = {
  leftComponent?: JSX.Element | boolean;
  centerComponent?: JSX.Element | boolean;
  rightComponent?: JSX.Element | boolean;
  onClick?: () => void;
};

export const LEFT_COLUMN_WIDTH = 48;

export const renderActivityDate = (userActivity: UserActivity) => {
  let { date } = userActivity;

  switch (userActivity.activityType) {
    case UserActivityType.PUSH_NOTIFICATION:
      date =
        userActivity.data.sentDate || userActivity.data.createdDate || date;
      break;

    default:
      break;
  }

  return (
    <Typography variant="body2" style={{ color: Colors.TertiaryText }}>
      {moment(date).isSame(new Date(), 'day')
        ? 'Today'
        : moment(date).format('MM/DD/YYYY')}
    </Typography>
  );
};

export default function UserActivityRenderer({
  leftComponent,
  centerComponent,
  rightComponent,
  onClick,
}: UserActivityRendererProps) {
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap">
      <Grid item pr={3} style={{ width: LEFT_COLUMN_WIDTH, marginRight: 24 }}>
        {leftComponent}
      </Grid>
      <Grid item flexGrow={1}>
        {centerComponent}
      </Grid>
      {!!rightComponent && (
        <Grid item style={{ minWidth: 100, textAlign: 'right' }}>
          {rightComponent}
        </Grid>
      )}
      <Grid item pl={3}>
        {!!onClick && (
          <IconButton onClick={onClick} size="large">
            <SVG.CaretRightSmall />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
