import React from 'react';
import { Box } from '@mui/material';

export type TooltipFormat = 'currency' | 'percent' | 'number';

type Props = {
  children: JSX.Element;
};

const TooltipContainer: React.FC<Props> = ({ children }) => (
  <Box
    bgcolor="white"
    p="8px"
    boxShadow="0px 4px 12px rgba(0, 0, 0, 0.12);"
    width="100%"
    borderRadius="4px">
    {children}
  </Box>
);

export default TooltipContainer;
