import { MenuItem, Radio, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect } from 'react';
import {
  ALL_COMPANIES,
  DashboardContext,
  DivisionSelection,
} from '../../../contexts/DashboardContext';
import ArrayUtils from '../../../utils/arrayUtils';
import SelectMenu from '../SelectMenu/SelectMenu';

type Props = {
  className?: string;
  includeAllCompanies?: boolean;
  defaultToAll?: boolean;
};

const useStyles = makeStyles((theme) => ({
  menuItem: {
    height: 36,
    minWidth: 400,
  },

  selectedItem: {
    backgroundColor: 'transparent !important',
  },
  selectedChip: {
    fontWeight: 600,
    borderRadius: 4,
    marginLeft: 'auto',
    padding: '0px 8px',
    backgroundColor: theme.palette.buttons.primary.disabled,
    color: theme.palette.buttons.primary.active,
  },
}));

type ChildConnectorProps = {
  lastChild?: boolean;
};

const ChildConnector: React.FC<ChildConnectorProps> = ({ lastChild }) => (
  <svg
    width="10"
    height="36"
    viewBox="0 0 10 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M1 0V11C1 13.2091 2.79086 15 5 15H10" stroke="#DADADA" />
    {lastChild ? null : <path d="M1 10L1 36" stroke="#DADADA" />}
  </svg>
);

const ZeroMeCompanySelect: React.FC<Props> = ({
  className,
  includeAllCompanies,
  defaultToAll,
}) => {
  const {
    company,
    divisionKey,
    divisions,
    setDivisionFromKey,
    includeChildren,
    division,
    getKeyParts,
  } = useContext(DashboardContext);

  const classes = useStyles();

  const selected = includeChildren
    ? [
        division,
        ...ArrayUtils.getChildren(divisions, 'parentCompanyId', division).map(
          (d) => d.id,
        ),
      ]
    : [division];

  // Initialize division
  useEffect(() => {
    if (!division) {
      if (defaultToAll) {
        setDivisionFromKey(ALL_COMPANIES);
      } else {
        setDivisionFromKey(`${company.id}_${company.name}`);
      }
    }
  }, [company.id]);

  const isChecked = (item: DivisionSelection): boolean => {
    const [id] = getKeyParts(divisionKey);
    if (includeChildren) {
      if (item.id === id && !item.key.includes('ALL**')) {
        return false;
      }

      const noIncludeAll =
        divisions.filter((d) => d.id === item.id).length === 1;

      return (
        (selected || []).indexOf(item.id) > -1 &&
        (item.key.includes('ALL**') || noIncludeAll)
      );
    }
    return (
      (selected || []).indexOf(item.id) > -1 && !item.key.includes('ALL**')
    );
  };

  const isLastChild = (item: DivisionSelection): boolean => {
    const keys = divisions.map((d) => d.key);
    const placeInList = keys.indexOf(item.key);
    // this is the last child for this item if it is last in the list
    // or the next item in the list has a different level than our item
    const nextKey = keys[placeInList + 1];
    const nextItem = divisions.find((d) => d.key === nextKey);
    return !nextKey || nextItem?.level !== item.level;
  };

  return (
    <SelectMenu
      testId="zerome-company-select"
      key="division"
      id="division"
      className={className}
      onChange={(evt) => {
        setDivisionFromKey(evt.target.value);
      }}
      inputStyle={{
        height: 42,
      }}
      SelectProps={{
        renderValue: (k) =>
          division === ALL_COMPANIES
            ? 'All Companies'
            : divisions.find((d) => d.key === k)?.name || '',
      }}
      value={divisionKey}>
      {includeAllCompanies && (
        <MenuItem
          classes={{ selected: classes.selectedItem }}
          value={ALL_COMPANIES}
          key={ALL_COMPANIES}
          className={classes.menuItem}>
          <Grid container alignItems="center">
            <Radio
              size="small"
              color="primary"
              checked={division === ALL_COMPANIES}
            />
            All Companies
          </Grid>
        </MenuItem>
      )}
      {divisions.map((item: DivisionSelection) => (
        <MenuItem
          classes={{ selected: classes.selectedItem }}
          value={item.key}
          key={item.key}
          className={classes.menuItem}>
          <Grid
            container
            alignItems="center"
            style={item.level ? { marginLeft: item.level * 16 - 12 } : {}}>
            {item.level ? (
              <div
                style={{
                  position: 'relative',
                  height: 36,
                  width: 16,
                  left: 12,
                  top: 2,
                }}>
                <ChildConnector lastChild={isLastChild(item)} />
              </div>
            ) : null}
            <Radio size="small" color="primary" checked={isChecked(item)} />
            {item.name}
            {item.key === divisionKey ? (
              <Grid item className={classes.selectedChip}>
                {selected.length} Selected
              </Grid>
            ) : null}
          </Grid>
        </MenuItem>
      ))}
    </SelectMenu>
  );
};

export default ZeroMeCompanySelect;
