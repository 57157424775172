import React from 'react';
import _ from 'lodash';
import Papa from 'papaparse';
import { MenuItem } from '@mui/material';
import { useApolloClient } from '@apollo/client';
import SelectMenu from '../common/SelectMenu/SelectMenu';
import { downloadBlob } from '../../utils/domUtils';
import { GET_TOOLTIPS } from '../../graphql/settings';
import { GET_ALL_FAQS } from '../../graphql/faq/faq';
import { HABIT_EXPORT } from '../../graphql/habit/habits';

const ConfigExport = () => {
  const client = useApolloClient();

  // Add UTF-8 BOM so Excel properly handles encoding
  const uft8bom = new Uint8Array([0xef, 0xbb, 0xbf]);

  return (
    <SelectMenu
      key="export"
      id="export"
      onChange={async (e) => {
        switch (e.target.value) {
          case 'tooltips':
            {
              const { data } = await client.query({
                query: GET_TOOLTIPS,
                fetchPolicy: 'no-cache',
              });

              await downloadBlob(
                new Blob(
                  [
                    uft8bom,
                    Papa.unparse(
                      _.orderBy(data.tooltips, ['platform', 'id']).map((x) =>
                        _.omit(x, '__typename'),
                      ),
                    ),
                  ],
                  {
                    type: 'text/csv',
                  },
                ),
                'tooltips.csv',
              );
            }
            break;

          case 'faqs':
            {
              const { data } = await client.query({
                query: GET_ALL_FAQS,
                fetchPolicy: 'no-cache',
              });

              await downloadBlob(
                new Blob(
                  [
                    uft8bom,
                    Papa.unparse(
                      data.faqQuestions.map((x) => _.omit(x, '__typename')),
                    ),
                  ],
                  {
                    type: 'text/csv',
                  },
                ),
                'faqs.csv',
              );
            }
            break;

          case 'habits':
            {
              const { data } = await client.query({
                query: HABIT_EXPORT,
                fetchPolicy: 'no-cache',
              });

              const csvData = data.habits.map((x) => {
                const result: Record<string, string> = {
                  ..._.omit(x, '__typename', 'tips'),
                };

                for (let i = 0; i < x.tips.length; i += 1) {
                  result[`tip_id_${i + 1}`] = x.tips[i].id;
                  result[`tip_text_${i + 1}`] = x.tips[i].text;
                }

                return result;
              });

              await downloadBlob(
                new Blob(
                  [
                    uft8bom,
                    Papa.unparse(csvData, {
                      columns: csvData
                        .map((x) => Object.keys(x))
                        // Use the row with the most headers
                        .reduce(
                          (acc, cur) => (cur.length > acc.length ? cur : acc),
                          [],
                        ),
                    }),
                  ],
                  {
                    type: 'text/csv',
                  },
                ),
                'habits.csv',
              );
            }
            break;

          default:
            break;
        }
      }}
      inputStyle={{
        height: 42,
      }}
      SelectProps={{ renderValue: () => 'Export Config Data' }}
      value="none">
      <MenuItem value="faqs">FAQs</MenuItem>
      <MenuItem value="habits">Habits</MenuItem>
      <MenuItem value="tooltips">Tooltips</MenuItem>
    </SelectMenu>
  );
};

export default ConfigExport;
