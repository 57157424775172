import React from 'react';
import { Colors } from '../../../theme/shared';

type Props = {
  active?: boolean;
};

const DiscoverIcon: React.FC<Props> = ({ active }) => (
  <svg
    width="19"
    height="16"
    viewBox="0 0 19 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.5938 4.0625L7.09375 6.125C6.875 6.25 6.71875 6.40625 6.59375 6.625L4.53125 11.125C4.3125 11.6562 4.84375 12.1875 5.375 11.9688L9.875 9.90625C10.0938 9.78125 10.25 9.625 10.375 9.40625L12.4375 4.90625C12.6562 4.375 12.125 3.84375 11.5938 4.0625ZM9.1875 8.71875C8.8125 9.125 8.15625 9.125 7.78125 8.71875C7.375 8.34375 7.375 7.6875 7.78125 7.3125C8.15625 6.90625 8.8125 6.90625 9.1875 7.3125C9.59375 7.6875 9.59375 8.34375 9.1875 8.71875ZM8.5 0.25C4.21875 0.25 0.75 3.75 0.75 8C0.75 12.2812 4.21875 15.75 8.5 15.75C12.75 15.75 16.25 12.2812 16.25 8C16.25 3.75 12.75 0.25 8.5 0.25ZM8.5 14.25C5.03125 14.25 2.25 11.4688 2.25 8C2.25 4.5625 5.03125 1.75 8.5 1.75C11.9375 1.75 14.75 4.5625 14.75 8C14.75 11.4688 11.9375 14.25 8.5 14.25Z"
      fill={active ? `${Colors.ElectricGreen}` : '#FFFFFF'}
    />
  </svg>
);

export default DiscoverIcon;
