import { gql } from '@apollo/client';

export const OFFSET_OPPORTUNITIES = gql`
  query offsetOpportunities(
    $companyId: String!
    $startDate: String!
    $endDate: String!
    $budgetDate: String
    $dateGrain: DateGrain!
    $includeChildren: Boolean
    $includeDemo: Boolean
  ) {
    company(id: $companyId) {
      currentBudget(
        includeChildren: $includeChildren
        includeDemo: $includeDemo
        date: $budgetDate
      ) {
        total
        spent
        spentValueByUom {
          mt
          mwh
        }
        spentMt
        remaining
        allocated
        allocatedMt
      }
      lifetimeBudget: currentBudget(
        includeChildren: $includeChildren
        includeDemo: $includeDemo
        lifetime: true
      ) {
        total
        spent
        spentValueByUom {
          mt
          mwh
        }
        spentMt
        remaining
        allocated
        allocatedMt
        allocatedOffPlatform
        allocatedOffPlatformMt
      }
    }
    investments(
      companyId: $companyId
      includeDemo: $includeDemo
      parameters: {
        dateGrain: $dateGrain
        startDate: $startDate
        endDate: $endDate
        includeChildren: $includeChildren
      }
    ) {
      id
      label
      color
      data {
        id
        label
        color
        data {
          date
          value
          mt
          mwh
        }
      }
    }
  }
`;

export default {
  OFFSET_OPPORTUNITIES,
};
