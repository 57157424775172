import questionsCompletedWorkDisabled from './QuestionsCompletedWorkDisabled';
import questionsCompletedWorkEnabled from './QuestionsCompletedWorkEnabled';

const questionsCompletedWork = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? questionsCompletedWorkDisabled(level, size)
    : questionsCompletedWorkEnabled(level, size);

export default questionsCompletedWork;
