import React, { useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import moment from 'moment';

import LeaderboardCategoryPoints from './LeaderboardCategoryPoints';
import LeaderboardSummaryBar from './LeaderboardSummaryBar';
import LeaderboardTags from './LeaderboardTags';
import PointsBreakdownChart from './PointsBreakdownChart';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { MemberToastContext } from '../../../contexts/MemberToastContext';
import {
  getContestsQuery,
  getLeaderboardsQuery,
  OPT_OUT_OF_CONTEST,
} from '../../../graphql/contest/contests';
import { Colors } from '../../../theme/shared';
import { ContestType, LeaderboardRollup } from '../../../types/contest/types';
import getUserContestPoints from '../../../utils/contestUtils';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    justifyContent: 'space-evenly',
    marginTop: 8,
    padding: 16,
    borderRadius: 16,
    backgroundColor: theme.palette.primary.light,
  },
  cardContainer: {
    padding: '0px 8px',
  },
  card: {
    alignItems: 'center',
    flexDirection: 'column',
    padding: 8,
    borderRadius: 16,
    backgroundColor: theme.palette.backgrounds.white,
  },
  title: {
    color: theme.palette.text.primary,
    margin: '24px 0px',
  },
}));

type Props = {
  contestId: string;
  rollup: LeaderboardRollup;
};

const LeaderboardDetails: React.FC<Props> = ({ contestId, rollup }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useContext(AuthorizationContext);
  const { showToast } = useContext(MemberToastContext);
  const [optOutOfContest] = useMutation(OPT_OUT_OF_CONTEST, {
    variables: { contestId, optOut: false },
    refetchQueries: [getContestsQuery(true)],
  });

  const {
    loading: loadingContests,
    error: errorContests,
    data: { findCompanyContests: [contest] = [undefined] } = {},
  } = useQuery(getContestsQuery(true), {
    variables: {
      contestIds: [contestId],
    },
  });

  const {
    companyIds,
    contestType,
    userPoints,
    pointCategories,
    optedOutUsers,
    duration: { endDate = '' } = {},
  } = contest || {};
  const optedOut = optedOutUsers?.includes(user?.id || '');

  const {
    loading: loadingLeaderboards,
    error: errorLeaderboards,
    data: { leaderboards: [leaderboard] = [undefined] } = {},
  } = useQuery(getLeaderboardsQuery(true, false), {
    variables: {
      contestId,
      rollup,
    },
    skip: optedOut,
  });

  const isQuarterly = contestType === ContestType.AUTOMATIC_QUARTERLY;
  const userRank = leaderboard?.contestRanking?.find(
    (cr) => cr.userId === user?.id,
  );
  const totalPoints =
    userRank?.contestTotal || getUserContestPoints(userPoints, pointCategories);
  const isContestClosed = moment().isAfter(moment(endDate).endOf('day'));

  const pointBreakdownChartData =
    userPoints?.map((up, i) => ({
      x: up.month
        ? moment(up.month?.toString()).format('MMMM')
        : moment(`${up.year}${up.week}`, 'YYYYWW').format('M/D'),
      y: getUserContestPoints(
        // For week data, include previous weeks to calculate a rolling total
        up.month ? [up] : userPoints.slice(0, i + 1),
        pointCategories,
      ),
    })) || [];

  const handleOptIn = async () => {
    showToast({
      messages: [
        {
          title: isQuarterly
            ? t('contest.optIn.leaderboard.toastTitle')
            : t('contest.optIn.challenge.toastTitle'),
          description: t('contest.optIn.toastDescription'),
        },
      ],
    });
    await optOutOfContest();
  };

  return (
    <DataStateHandler
      loading={loadingContests || loadingLeaderboards}
      error={errorContests || errorLeaderboards}>
      <Grid container>
        <Typography variant="h3">
          {isQuarterly
            ? t('contest.leaderboardDetails')
            : t('contest.challengeDetails')}
        </Typography>
      </Grid>
      <Grid container justifyContent="center">
        <LeaderboardSummaryBar
          rank={userRank?.rank}
          points={totalPoints}
          optedOut={optedOut}
          backgroundColor={theme.palette.primary.light}
        />
        <Grid container item spacing={2}>
          {!isQuarterly && (
            <Grid container item className={classes.title} xs={6}>
              <LeaderboardTags
                companyIds={companyIds}
                pointCategoryIds={pointCategories}
              />
            </Grid>
          )}
          <Grid container item xs={isQuarterly ? 12 : 6}>
            <Typography className={classes.title} variant="h3">
              {t('contest.pointsBreakdown')}
            </Typography>
            {isQuarterly ? (
              <Grid container item spacing={2}>
                <Grid container item xs={6}>
                  <PointsBreakdownChart
                    data={pointBreakdownChartData}
                    datePeriod="month"
                    variant="bar"
                  />
                </Grid>
                <Grid container item xs={6}>
                  <LeaderboardCategoryPoints userPoints={userPoints} />
                </Grid>
              </Grid>
            ) : (
              <PointsBreakdownChart
                data={pointBreakdownChartData}
                datePeriod="week"
                variant="line"
              />
            )}
          </Grid>
        </Grid>
        {optedOut && !isContestClosed && (
          <Button onClick={handleOptIn}>
            <Typography variant="subtitle2" color={Colors.TertiaryText}>
              {isQuarterly
                ? t('contest.optIn.leaderboard.button')
                : t('contest.optIn.challenge.button')}
            </Typography>
          </Button>
        )}
      </Grid>
    </DataStateHandler>
  );
};

export default LeaderboardDetails;
