import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';
import { Field, Formik, FormikValues } from 'formik';
import { t } from 'i18next';
import omitDeep from 'omit-deep-lodash';
import moment, { Moment } from 'moment';
import * as yup from 'yup';

import CompanyInput from './CompanyInput';
import CommunicationDetailsHeader from '../CommunicationDetailsHeader';
import AppDatePicker from '../../common/AppDatePicker';
import AppTextField from '../../common/AppTextField';
import ConfirmCancelModal from '../../common/ConfirmCancelModal/ConfirmCancelModal';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { SplitButton } from '../../common/Buttons/SplitButton';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import { UPSERT_CONTEST } from '../../../graphql/contest/contests';
import {
  Contest,
  ContestDurationInterval,
  ContestType,
} from '../../../types/contest/types';

enum EditMode {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISH',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column',
    padding: '24px 64px',
  },
  form: {
    flexDirection: 'column',
    padding: '16px 0px !important',
    '&.MuiGrid-item': {
      maxWidth: '100%',
    },
  },
  select: {
    width: '100%',
  },
  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  menuItem: {
    fontSize: 18,
  },
  divider: {
    marginTop: 16,
    marginLeft: 16,
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 3,
    marginLeft: 14,
  },
  image: {
    borderRadius: 16,
  },
}));

type Props = {
  contest?: Contest;
  onClose: () => void;
};

const validationSchema = yup.object().shape({
  editMode: yup.string().oneOf(Object.values(EditMode)),
  companyIds: yup.array().of(yup.string()).min(1),
  pointCategories: yup
    .array()
    .of(yup.string())
    .when('editMode', {
      is: EditMode.PUBLISH,
      then: (schema) => schema.min(1, t('contest.pointCategoriesMin')),
      otherwise: (schema) => schema,
    }),
  title: yup.string().required(t('titleIsRequired')),
  imageFilename: yup.string().when('editMode', {
    is: EditMode.PUBLISH,
    then: (schema) => schema.required(t('imageIsRequired')),
    otherwise: (schema) => schema,
  }),
  description: yup.string().when('editMode', {
    is: EditMode.PUBLISH,
    then: (schema) => schema.required(t('descriptionIsRequired')),
    otherwise: (schema) => schema,
  }),
  startDate: yup.date().when('editMode', {
    is: EditMode.PUBLISH,
    then: (schema) =>
      schema
        .test((startDate) =>
          moment(startDate).isSameOrAfter(
            moment().startOf('isoWeek').add(1, 'week'),
          ),
        )
        .required(t('startDateIsRequired')),
    otherwise: (schema) => schema,
  }),
  endDate: yup
    .date()
    .when('startDate', (startDate, schema) =>
      startDate && moment(startDate).isValid()
        ? schema.min(startDate, t('minEndDate'))
        : schema,
    )
    .when(['editMode', 'isDraft'], {
      is: (editMode: EditMode, isDraft: boolean) =>
        editMode === EditMode.PUBLISH && isDraft,
      then: (schema) =>
        schema
          .test({
            name: 'endDate',
            message: t('minEndDate'),
            test: (endDate, context) => {
              const { startDate } = context.parent;
              const validStartDate = moment(startDate).isValid()
                ? moment(startDate)
                : moment();
              return validStartDate
                ? moment(endDate).isSameOrAfter(
                    moment(validStartDate).endOf('isoWeek'),
                  )
                : moment(endDate).isSameOrAfter(
                    moment().endOf('isoWeek').add(1, 'week'),
                  );
            },
          })
          .required(t('endDateIsRequired')),
      otherwise: (schema) => schema,
    }),
});

const ChallengeDetails: React.FC<Props> = ({ contest, onClose }) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { companyId, contestOptions, refetchCommunicationsQuery } = useContext(
    CommunicationsContext,
  );
  const [showPublishWarning, setShowPublishWarning] = useState<boolean>(false);
  const [acceptedWarning, setAcceptedWarning] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<string | null>(null);
  const [upsert, { loading, error }] = useMutation(UPSERT_CONTEST);

  const isDraft = !contest?.id || !!contest?.isDraft;
  const canEdit =
    isDraft && userHasAccess('Client.Communication.Challenges', 'EDIT');

  const initialValues = {
    isDraft,
    ...contest,
    companyIds: contest?.companyIds || (companyId ? [companyId] : []),
    pointCategories: contest?.pointCategories || [],
    title: contest?.title || '',
    imageFilename: contest?.imageFilename || '',
    description: contest?.description || '',
    startDate: contest?.duration.startDate || '',
    endDate: contest?.duration.endDate || '',
    editMode: isDraft ? EditMode.DRAFT : EditMode.PUBLISH,
  };

  const handleSubmit = async (values: FormikValues) => {
    const { editMode, startDate, endDate, ...variables } = values;

    await upsert({
      variables: {
        contest: {
          ...omitDeep(variables, ['updatedDate', '__typename']),
          contestType: ContestType.COMPANY,
          isDraft: editMode === EditMode.DRAFT,
          duration: {
            interval: ContestDurationInterval.WEEKLY,
            startPeriod: startDate
              ? moment(startDate).startOf('isoWeek').format('YYYYWW')
              : '',
            endPeriod: endDate
              ? moment(endDate).endOf('isoWeek').format('YYYYWW')
              : '',
          },
        },
      },
      onCompleted: (data) => {
        if (data.upsertContest.error) {
          setValidationError(data.upsertContest.error);
        } else {
          onClose();
          refetchCommunicationsQuery();
        }
      },
    });
  };

  return (
    <DataStateHandler loading={loading} error={error}>
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validateOnMount>
        {({
          errors,
          isValid,
          isSubmitting,
          submitForm,
          setFieldValue,
          values,
        }) => {
          const publishMode = values.editMode === EditMode.PUBLISH;
          const image = contestOptions?.images.find(
            ({ imageFilename }) => imageFilename === values.imageFilename,
          );

          return (
            <Grid container className={classes.root} spacing={2}>
              <Grid item>
                <CommunicationDetailsHeader
                  title={
                    contest
                      ? t(canEdit ? 'editDetails' : 'viewDetails')
                      : t('createNew')
                  }
                  canEdit={canEdit}
                  onClose={onClose}
                  saveButton={
                    <SplitButton
                      options={[
                        {
                          text: {
                            value: t('saveAsDraft'),
                            variant: 'caption',
                          },
                          id: 'draft',
                          onClick: submitForm,
                          disabled: !isValid || isSubmitting || !canEdit,
                        },
                        {
                          text: {
                            value: t('publish'),
                            variant: 'caption',
                          },
                          id: 'published',
                          onClick: async () => setShowPublishWarning(true),
                          disabled: !isValid || isSubmitting || !canEdit,
                        },
                      ]}
                      onOptionSelected={(index) => {
                        // index is dependent on the order of the options array above!
                        setFieldValue(
                          'editMode',
                          index ? EditMode.PUBLISH : EditMode.DRAFT,
                        );
                      }}
                    />
                  }
                />
              </Grid>
              <Grid container item className={classes.form} spacing={2}>
                {!!validationError && (
                  <Grid item>
                    <Alert severity="error" variant="filled">
                      {t('contest.failedToSave')}: {validationError}
                    </Alert>
                  </Grid>
                )}
                {canEdit && publishMode && (
                  <Grid item>
                    <Alert severity="warning">
                      {t('contest.publishWarning.alert')}
                    </Alert>
                  </Grid>
                )}
                <Grid item>
                  <CompanyInput
                    rootCompanyId={companyId}
                    disabled={!canEdit}
                    initialCompanyIds={values.companyIds}
                    label={`${t('participants')}${publishMode ? ' *' : ''}`}
                    onChange={(v) => setFieldValue('companyIds', v)}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {t('contest.pointCategories')}
                    {publishMode ? ' *' : ''}
                  </Typography>
                  <Select
                    className={classes.select}
                    disabled={!canEdit}
                    multiple
                    value={values.pointCategories}
                    onChange={(event) =>
                      setFieldValue('pointCategories', event.target.value)
                    }
                    renderValue={(categories) => (
                      <Box className={classes.chip}>
                        {categories.map((category) => (
                          <Chip
                            key={category}
                            label={
                              contestOptions?.pointCategories.find(
                                ({ id }) => id === category,
                              )?.label
                            }
                          />
                        ))}
                      </Box>
                    )}
                    input={<OutlinedInput />}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: '600px',
                          maxWidth: '1000px',
                          overflow: 'auto',
                        },
                      },
                    }}>
                    {contestOptions?.pointCategories.map(({ id, label }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        className={classes.menuItem}>
                        <Checkbox
                          checked={values.pointCategories.includes(id)}
                        />
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                  {typeof errors.pointCategories === 'string' &&
                    errors.pointCategories !== '' && (
                      <Typography className={classes.error} variant="subtitle1">
                        {errors.pointCategories}
                      </Typography>
                    )}
                </Grid>
                <Divider className={classes.divider} flexItem />
                <Grid item>
                  <Typography variant="body2">{t('title')} *</Typography>
                  <Field
                    name="title"
                    inputProps={{ maxLength: 60 }}
                    component={AppTextField}
                    disabled={!canEdit}
                    fullWidth
                    placeholder={t('title')}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {t('image')}
                    {publishMode ? ' *' : ''}
                  </Typography>
                  <Field
                    name="imageFilename"
                    component={AppTextField}
                    disabled={!canEdit}
                    fullWidth
                    select
                    options={contestOptions?.images.map(
                      ({ imageFilename, label }) => ({
                        value: imageFilename,
                        label,
                      }),
                    )}
                  />
                </Grid>
                {image && (
                  <Grid item>
                    <img
                      className={classes.image}
                      src={image?.imageUrl}
                      alt={image?.label || ''}
                      width={535}
                    />
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="body2">
                    {t('description')}
                    {publishMode ? ' *' : ''}
                  </Typography>
                  <Field
                    name="description"
                    component={AppTextField}
                    inputProps={{ maxLength: 250 }}
                    disabled={!canEdit}
                    fullWidth
                    placeholder={t('description')}
                  />
                </Grid>
                <Divider className={classes.divider} flexItem />
                <Grid container item direction="column">
                  <Typography variant="h3">
                    {t('contest.datePickerTitle')}
                  </Typography>
                  <Typography variant="body2">
                    {t('contest.datePickerDescription')}
                  </Typography>
                  <Grid container item spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {t('startDate')}
                        {publishMode ? ' *' : ''}
                      </Typography>
                      <Field
                        name="startDate"
                        component={AppDatePicker}
                        disabled={!canEdit}
                        serializeFn={(m: Moment) =>
                          m.startOf('isoWeek').toISOString()
                        }
                        minDate={moment().startOf('isoWeek').add(1, 'week')}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {t('endDate')}
                        {publishMode ? ' *' : ''}
                      </Typography>
                      <Field
                        name="endDate"
                        component={AppDatePicker}
                        disabled={!canEdit}
                        serializeFn={(m: Moment) =>
                          m.endOf('isoWeek').toISOString()
                        }
                        minDate={
                          values.startDate
                            ? moment(values.startDate).endOf('isoWeek')
                            : moment().endOf('isoWeek').add(1, 'week')
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <ConfirmCancelModal
                isOpen={showPublishWarning}
                onConfirm={async () => {
                  setShowPublishWarning(false);
                  await submitForm();
                }}
                disableConfirm={!acceptedWarning}
                title={t('publishing')}
                confirmLabel={t('contest.publishWarning.confirm')}
                cancelLabel={t('contest.publishWarning.cancel')}
                onCancel={() => setShowPublishWarning(false)}
                messageFragments={[
                  <Grid container direction="column" key="publishing">
                    <Typography variant="body1">
                      {t('contest.publishWarning.message')}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptedWarning}
                          onChange={() => setAcceptedWarning(!acceptedWarning)}
                        />
                      }
                      componentsProps={{ typography: { variant: 'body1' } }}
                      label={t('iUnderstand')}
                    />
                  </Grid>,
                ]}
              />
            </Grid>
          );
        }}
      </Formik>
    </DataStateHandler>
  );
};

export default ChallengeDetails;
