import { Box, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ProjectContext } from '../../../contexts/ProjectContext';
import CreateProjectNavigationItem from './CreateProjectNavigationItem';

const CreateProjectNavigation: React.FC = () => {
  const { project, projectDetailsSteps } = useContext(ProjectContext);
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Box marginBottom={2}>
          <Typography variant="h2">Project Details</Typography>
        </Box>
      </Grid>
      {projectDetailsSteps.map((step) => (
        <CreateProjectNavigationItem
          title={step.title}
          step={step.step}
          isCompleted={(project.completedSteps || []).indexOf(step.step) >= 0}
          key={step.step}
          isValid={step.valid}
        />
      ))}
    </Grid>
  );
};

export default CreateProjectNavigation;
