import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import { LocalizedQuiz } from '../../communications/types';
import QuizResults from './QuizResults';

const useStyles = makeStyles(() => ({
  container: {
    margin: '64px 0px',
  },
  card: {
    padding: '12px 48px',
    marginTop: 8,
    minHeight: 160,
    borderRadius: 16,
    backgroundColor: '#f1f8fd',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  startButton: {
    height: 64,
    minWidth: 320,
  },
}));

type Props = {
  quiz: LocalizedQuiz;
};

const QuizCard: React.FC<Props> = ({ quiz }) => {
  const classes = useStyles();
  const history = useHistory();
  const answers = quiz.answers?.answers;

  // Check if every question has a corresponding answer
  const isQuizComplete = quiz.questions?.every(
    (q) =>
      answers?.find((a) => a.questionId === q.questionId)?.answerIds?.length,
  );

  const correctAnswers = answers?.filter((answer) => {
    const question = quiz?.questions?.find(
      (q) => q.questionId === answer.questionId,
    );
    return answer?.answerIds?.[0] === question?.correctAnswerId;
  });

  return (
    <Grid container className={classes.container}>
      <Typography variant="h3">{t('testYourKnowledge')}</Typography>
      <Grid container item className={classes.card}>
        <Button
          className={classes.startButton}
          variant="contained"
          onClick={() => history.push(`/member/quiz/${quiz.id}`)}>
          {isQuizComplete ? t('quiz.restartQuiz') : t('quiz.startQuiz')}
        </Button>
        <Grid item>
          {isQuizComplete ? (
            <QuizResults
              total={answers?.length || 1}
              correct={correctAnswers?.length || 0}
            />
          ) : (
            <img alt={quiz.title} src={quiz.iconUrl} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuizCard;
