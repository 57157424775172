import {
  ButtonGroup,
  ButtonProps,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, { MouseEventHandler, useRef, useState } from 'react';
import SmallPaddedButton from './SmallPaddedButton';
import SVG from '../../../assets/svg';

type Props = {
  options: (Omit<MenuItemProps, 'onClick'> & {
    text: Omit<TypographyProps, 'children'> & { value: string };
    onClick: ButtonProps['onClick'];
  })[];
  onOptionSelected?: (index: number) => void;
};

// Mostly copied from https://mui.com/material-ui/react-button-group/#split-button,
// but made it more generic and added some customizations.
// eslint-disable-next-line import/prefer-default-export
export const SplitButton = (props: Props) => {
  const { options, onOptionSelected } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const currentOption = options[selectedIndex];
    if (currentOption.onClick) {
      currentOption.onClick(event);
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    if (onOptionSelected) {
      onOptionSelected(index);
    }
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <SmallPaddedButton
          onClick={handleClick}
          disabled={options[selectedIndex].disabled}>
          {options[selectedIndex].text.value}
        </SmallPaddedButton>
        <SmallPaddedButton size="small" onClick={handleToggle}>
          <SVG.ArrowDownWhite fill="white" />
        </SmallPaddedButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map(
                    (
                      {
                        text: { value, ...typographyProps },
                        // We don't was to pass disabled because it gets used on the
                        // actual button component above.
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        disabled,
                        ...option
                      },
                      index,
                    ) => (
                      <MenuItem
                        key={value}
                        {...option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        <Typography {...typographyProps}>{value}</Typography>
                      </MenuItem>
                    ),
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
