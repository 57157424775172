import { useMutation } from '@apollo/client';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Link,
  Typography,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useState } from 'react';
import SVG from '../../assets/svg';
import { UPLOAD_USER_CSV } from '../../graphql/user/userMutations';
import { Company } from '../../types/company/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import FileUpload, { FileData } from '../common/FileUpload/FileUpload';
import LoadingBackdrop from '../common/LoadingBackdrop';
import { downloadFileUrl } from '../../utils/domUtils';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
    padding: 16,
  },
  closeIcon: {
    position: 'relative',
    top: -8,
    color: theme.palette.text.primary,
  },
  csvTemplate: {
    textAlign: 'center',
    color: '#398360',
    fontSize: 12,
    fontWeight: 600,
    marginTop: 24,
  },
  downloadErrors: {
    textAlign: 'center',
    color: '#398360',
    fontSize: 16,
    fontWeight: 600,
    margin: 32,
  },
}));

type UploadResult = {
  result:
    | 'PROCESSING'
    | 'COMPANY_NOT_FOUND'
    | 'SUCCESS'
    | 'PARSE_FAILURE'
    | 'NO_DATA'
    | 'INVALID_DATA'
    | 'COSMOS_FAILURE'
    | 'UPLOAD_IN_PROGRESS'
    | 'INVALID_COMPANY_POLICY';
  errorCsvUrl: string;
};

type Props = {
  refetch?: () => void;
  companyId: Company['id'];
  disabled?: boolean;
};

const UploadUserCsvModal: React.FC<Props> = ({
  refetch,
  companyId,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState<FileData>();
  const [disableUsersNotInFile, setDisableUsersNotInFile] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState<UploadResult>();

  const classes = useStyles();

  const close = () => {
    setOpen(false);
    setResultDialogOpen(false);
    setFile(undefined);
  };

  const [uploadUserCsv, { loading }] = useMutation<
    { uploadUserCsv: UploadResult },
    {
      upload: {
        companyId: string;
        csvData: File;
      };
      options: {
        disableUsersNotInFile: boolean;
      };
    }
  >(UPLOAD_USER_CSV);

  const createCsvUrl = (csv: string) =>
    URL.createObjectURL(new Blob([csv], { type: 'text/csv' }));

  const csvTemplateUrl =
    createCsvUrl(`firstName,lastName,email,postalCode,country,status,company
John,Member,john@test.com,12345,US,ENROLLING,
Joe,Admin,admin@test.com,11111,US,ACTIVE,
Jill,Leadership,leadership@test.com,22222,US,ACTIVE,
Jeff,Marketplace,marketplace@test.com,33333,US,ACTIVE,`);

  return (
    <>
      <SmallPaddedButton
        variant="outlined"
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}
        disabled={disabled}>
        Upload CSV
      </SmallPaddedButton>
      <LoadingBackdrop open={loading} />
      <Dialog open={open} onClose={close}>
        <Box className={classes.dialog}>
          <Grid container direction="column" alignItems="stretch">
            <Grid item container direction="row" justifyContent="space-between">
              <Typography align="center" variant="h3">
                Upload CSV
              </Typography>
              <IconButton
                onClick={close}
                className={classes.closeIcon}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <Switch
                    checked={disableUsersNotInFile}
                    onChange={() =>
                      setDisableUsersNotInFile(!disableUsersNotInFile)
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    Disable all users not in this file?
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <div style={{ margin: '40px 0' }}>
              <FileUpload
                accept=".csv"
                showFiles
                uploadIcon={<SVG.CsvIcon />}
                onFileChange={(f) => setFile(f.length ? f[0] : undefined)}
              />
            </div>
            <Grid item>
              <SmallPaddedButton
                fullWidth
                disabled={!file}
                onClick={async () => {
                  setOpen(false);
                  setUploadResult(undefined);

                  const result = await uploadUserCsv({
                    variables: {
                      upload: {
                        companyId,
                        csvData: file?.file as File,
                      },
                      options: {
                        disableUsersNotInFile,
                      },
                    },
                  });

                  setResultDialogOpen(true);
                  setUploadResult(result.data?.uploadUserCsv);

                  if (refetch) {
                    refetch();
                  }
                }}>
                Save
              </SmallPaddedButton>
            </Grid>
            <Grid item className={classes.csvTemplate}>
              <Link
                download="zerome_user_template.csv"
                href={csvTemplateUrl}
                underline="hover">
                Download Template
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        onClose={() => close()}
        open={!!resultDialogOpen}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <Grid container direction="column" alignContent="center" spacing={2}>
            <Grid item container direction="row" justifyContent="space-between">
              <Typography variant="h1">
                Upload {uploadResult?.result === 'SUCCESS' ? 'Succeeded!' : ''}
                {uploadResult?.result === 'PROCESSING' ? 'In Progress!' : ''}
                {['SUCCESS', 'PROCESSING'].includes(uploadResult?.result || '')
                  ? ''
                  : 'Failed'}
              </Typography>
              <IconButton
                onClick={close}
                className={classes.closeIcon}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
            {uploadResult?.result === 'PROCESSING' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  Your file is being processed. Processing can take several
                  minutes based on file size. Please check back later.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'PARSE_FAILURE' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  There was an error reading the file you uploaded. Please try
                  again.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'UPLOAD_IN_PROGRESS' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  Your company already has an upload in progress, please try
                  again later.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'NO_DATA' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  There were no rows of data in the file you uploaded. Please
                  try again.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'INVALID_DATA' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  The data you uploaded was invalid. The link below contains
                  your CSV file with an error column that will help you diagnose
                  the issues with your file.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'COSMOS_FAILURE' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  There was a general error processing your file. Please try
                  again or contact support.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'INVALID_COMPANY_POLICY' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  Company Policy is not defined.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'COMPANY_NOT_FOUND' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  One or more clients could not be found. Please ensure spelling
                  is correct in your csv file.
                </Typography>
              </Grid>
            )}
            <Grid item>
              {uploadResult?.result === 'INVALID_DATA' && (
                <SmallPaddedButton
                  fullWidth
                  style={{ marginBottom: '5px' }}
                  onClick={() => {
                    downloadFileUrl(
                      uploadResult.errorCsvUrl,
                      `zerome_user_errors_${moment().format(
                        'YYYYMMDD_HHmm',
                      )}.csv`,
                    );

                    close();
                  }}>
                  Download Errors
                </SmallPaddedButton>
              )}

              {uploadResult?.result !== 'INVALID_DATA' && (
                <SmallPaddedButton fullWidth onClick={close}>
                  OK
                </SmallPaddedButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default UploadUserCsvModal;
