import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import { Margin, Theme } from '@nivo/core';

import { CategoryData } from '../../../types/dashboard/types';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import TotalsTooltip from '../ChartTooltips/TotalsTooltip';
import { formatTooltip } from '../ChartTooltips/FormattedTooltip';
import ChartUtils from '../../../utils/chartUtils';
import { NumberFormat } from '../../../utils/numberUtils';
import { FULL_Y_BAR_TOOLTIP_LAYERS } from '../ChartTooltips/FullYBarTooltipLayer';

type Props = {
  categoryData: CategoryData;
  format?: NumberFormat;
  suffix?: string;
  theme?: Theme;
  height?: number;
  margin?: Partial<Margin>;
  dateFormat?: string;
};

type Data = {
  month: string;
  [key: string]: string | number;
};

const CategoryDataBarChart: React.FC<Props> = ({
  categoryData,
  format = 'number',
  suffix,
  theme,
  height = 265,
  margin = { top: 20, right: 0, bottom: 55, left: 55 },
  dateFormat = 'MMM yyyy',
}) => {
  const allChartColors = [
    ...(categoryData.data.map((c) => c.color || '') || []),
  ];

  const keys = categoryData.data.map((c) => c.label);

  // all dates are the same across categories so we
  // can grab the first one
  const [{ data: dateRanges = [] } = {}] = categoryData.data || [];
  const data: Data[] = dateRanges.map((d) => {
    const { date } = d;
    const pointData = keys.reduce((acc, curr) => {
      const o = categoryData.data.find((cat) => cat.label === curr);
      const value = o?.data.find((p) => p.date === date)?.value || 0;
      return {
        ...acc,
        [curr]: value,
      };
    }, {});
    return {
      ...pointData,
      month: formatDateStringUtc(date, dateFormat),
    };
  });
  let hasData = false;
  data.forEach((d) => {
    const dataKeys = _.keys(d).filter((k) => k !== 'month');
    dataKeys.forEach((k) => {
      if ((d[k] as number) > 0) {
        hasData = true;
      }
    });
  });

  const getTooltipData = (nivoData: { [k: string]: string | number }) => {
    const keysWithValues = Object.keys(_.omit(nivoData, 'month', 'nocat'));
    return keysWithValues.map((k) => {
      const i = keys.indexOf(k);
      return {
        color: allChartColors[i],
        id: k,
        value: nivoData[k] as number,
      };
    });
  };

  return (
    <div style={{ height }}>
      <ResponsiveBar
        theme={theme || {}}
        data={data}
        keys={keys}
        indexBy="month"
        margin={margin}
        padding={0.75}
        innerPadding={1}
        groupMode="stacked"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={allChartColors}
        axisTop={null}
        axisRight={null}
        gridYValues={6}
        axisBottom={{
          tickSize: 0,
          tickPadding: 24,
          tickRotation: 0,
        }}
        axisLeft={{
          format: (value) => `${formatTooltip(value, format)}${suffix || ''}`,
          tickValues: 6,
          tickSize: 0,
          tickPadding: 1,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: -20,
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        layers={FULL_Y_BAR_TOOLTIP_LAYERS}
        tooltip={(props) => (
          <TotalsTooltip
            format={format}
            suffix={suffix}
            items={getTooltipData(props.data)}
          />
        )}
        role="application"
        ariaLabel="Total Investments Chart"
        {...ChartUtils.configureChartMinMaxValues(hasData)}
      />
    </div>
  );
};

export default CategoryDataBarChart;
