import React from 'react';
import { Button, ButtonProps } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  button: {
    padding: '12px',
    fontSize: 12,
    lineHeight: '16px',
  },
  wide: {
    padding: '12px 42px',
  },
  fullWidth: {
    width: '100%',
  },
}));

type Props = {
  wide?: boolean;
  fullWidth?: boolean;
};

const SmallPaddedButton: React.FC<Props & ButtonProps> = ({
  wide,
  children,
  fullWidth,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      size="small"
      color="primary"
      {...props}
      className={`${classes.button} ${wide ? classes.wide : ''} ${
        fullWidth ? classes.fullWidth : ''
      }`}>
      {children}
    </Button>
  );
};

export default SmallPaddedButton;
