import { Typography, Grid, Box } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';

import _ from 'lodash';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';

type Props = {
  projectHistory: Pick<ProjectPurchaseHistory, 'updateNotes'>;
};

const OffsetRequestNotes: React.FC<Props> = ({ projectHistory }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {projectHistory.updateNotes?.length ? (
      <Grid container>
        <Box mb={2}>
          <Typography variant="h3">Update Notes</Typography>
        </Box>
        {_.orderBy(projectHistory.updateNotes, 'date', 'desc').map((note) => (
          <Grid item style={{ width: '100%' }}>
            <Grid container direction="column">
              <Box mb={1}>
                <Typography variant="h4">
                  {format(new Date(note.date), 'MM/dd/yyyy')}
                </Typography>
              </Box>
              <Box mb={2}>
                <Typography variant="body2">{note.notes}</Typography>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    ) : null}
  </>
);

export default OffsetRequestNotes;
