import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { sortBy } from 'lodash';
import MemberLayout from '../../../components/layouts/MemberLayout';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import {
  GET_COMPANY_PROJECTS,
  ALLOTMENT_METRICS,
} from '../../../graphql/projects/projects';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';

import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import MemberProjectCard from '../../../components/member/offsets/MemberProjectCard';

const MemberProjects: React.FC = () => {
  const { company } = useContext(AuthorizationContext);
  const history = useHistory();

  const {
    data: { companyProjects: { items = [] } = {} } = {},
    loading: projectsLoading,
    error: projectsError,
  } = useQuery(GET_COMPANY_PROJECTS, {
    variables: {
      companyId: company.id,
    },
    skip: !company?.id,
  });

  const {
    data: { me: { allotmentMetrics }, projectAllotmentMetrics } = { me: {} },
    loading: metricsLoading,
    error: metricsError,
  } = useQuery(ALLOTMENT_METRICS, {
    variables: {
      companyId: company.id,
    },
    skip: !company?.id,
  });

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const data = sortBy(
    items
      .filter((i) => !i.isIndicative && i.uom === 'MT')
      .map((project) => ({
        project,
        category: projectCategories?.find((pc) => pc.id === project.category),
        projectMetrics: projectAllotmentMetrics?.find(
          (x) => x.projectId === project.id,
        ),
      })),
    (x) => `${x.projectMetrics?.allotted ? 'A' : 'U'}_${x.project.title}`,
  );

  return (
    <MemberLayout>
      <DataStateHandler
        loading={projectsLoading || metricsLoading || categoriesLoading}
        error={projectsError || metricsError || categoriesError}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" align="center">
              Carbon Reduction Projects
            </Typography>
          </Grid>
          {data.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                There are currently no offset projects available to browse.
              </Typography>
            </Grid>
          )}
          {data.map((x) => (
            <Grid
              item
              md={6}
              sm={12}
              key={x.project.id}
              data-testid="member-projects-card"
              display="grid">
              <MemberProjectCard
                onClick={() => history.push(`/member/projects/${x.project.id}`)}
                userMetrics={allotmentMetrics}
                {...x}
              />
            </Grid>
          ))}
        </Grid>
      </DataStateHandler>
    </MemberLayout>
  );
};

export default MemberProjects;
