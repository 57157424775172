import React, { MouseEventHandler, useEffect } from 'react';
import { useTooltip } from '@nivo/tooltip';
import { BarCustomLayer, BarDatum, BarLayer } from '@nivo/bar';
import { nextTick } from 'process';

const FullYBarTooltipLayer: BarCustomLayer<BarDatum> = ({
  bars,
  tooltip,
  width,
  height,
  margin,
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const toggleTooltip: MouseEventHandler<SVGRectElement> = (point) => {
    if (point.nativeEvent) {
      const chartX = point.nativeEvent.offsetX - margin.left;

      let hoveredBars = bars.filter(
        (b) => chartX >= b.x - b.width / 2 && chartX <= b.x + b.width * 1.5,
      );

      if (hoveredBars.length) {
        // In grouped mode, we need to find related bars...
        hoveredBars = [
          ...new Set([
            ...hoveredBars,
            ...bars.filter((x) =>
              hoveredBars.some((hb) => hb.data.index === x.data.index),
            ),
          ]),
        ];

        const barData = {
          ...hoveredBars[0],
          ...hoveredBars[0].data,
          value: hoveredBars[0].data.value || 0,
          data: hoveredBars.reduce((acc, cur) => {
            acc[cur.data.id] = cur.data.value || 0;
            return acc;
          }, {} as Record<string, number>),
        };

        // Without this nextTick the tooltip was really "jumpy" ¯\_(ツ)_/¯
        nextTick(() =>
          showTooltipFromEvent(tooltip(barData) as JSX.Element, point),
        );
        return;
      }
    }

    hideTooltip();
  };

  // We don't always catch the mouseleave events, this will clean up if we mouse out
  // of the chart bounds quickly
  useEffect(() => {
    const windowListener = () =>
      toggleTooltip({} as React.MouseEvent<SVGRectElement, MouseEvent>);
    window.addEventListener('mouseout', windowListener);
    return () => window.removeEventListener('mouseout', windowListener);
  });

  return (
    <rect
      x={-margin.left}
      width={width}
      height={height}
      fillOpacity={0}
      onMouseEnter={toggleTooltip}
      onMouseMove={toggleTooltip}
      onMouseLeave={toggleTooltip}
    />
  );
};

export default FullYBarTooltipLayer;

export const FULL_Y_BAR_TOOLTIP_LAYERS: BarLayer<BarDatum>[] = [
  'grid',
  'axes',
  'bars',
  'markers',
  'legends',
  'annotations',
  FullYBarTooltipLayer,
];
