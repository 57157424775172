import React from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import SVG from '../../../assets/svg-v2';

export type ConfirmHabitsCardProps = {
  snooze: () => void;
};

export function ConfirmHabitsCard({ snooze }: ConfirmHabitsCardProps) {
  const history = useHistory();

  return (
    <Grid
      container
      flexDirection="column"
      style={{
        padding: 16,
        border: '1px dashed grey',
        borderRadius: 8,
        marginBottom: 24,
      }}>
      <Grid
        item
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}>
        <Grid item>
          <Typography variant="h2">
            Let&apos;s make sure your Habit List is still accurate!
          </Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={snooze}>
            <SVG.Close color="black" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item pb={2}>
        <Typography variant="body2">
          Revisiting your Habits every 30 days helps make sure your lifestyle is
          accurately represented in your footprint. Confirm your Habits to earn
          points...
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" spacing={2}>
        <Grid item xs>
          <Button
            variant="outlined"
            color="secondary"
            onClick={snooze}
            fullWidth
            size="small">
            Dismiss
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              history.push(`/member/myhabits/confirm`);
            }}
            fullWidth>
            Confirm My Habits
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
