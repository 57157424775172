import React from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import SVG from '../../../assets/svg-v2';

export type WFHReminderCardProps = {
  snooze: () => void;
  daysPerWeek: number;
  hoursPerDay: number;
};

export function WFHReminderCard({
  snooze,
  daysPerWeek,
  hoursPerDay,
}: WFHReminderCardProps) {
  const history = useHistory();

  return (
    <Grid
      container
      flexDirection="column"
      style={{
        padding: 16,
        border: '1px dashed grey',
        borderRadius: 8,
        marginBottom: 24,
      }}>
      <Grid
        item
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}>
        <Grid item>
          <Typography variant="h2">How does your work week look?</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={snooze}>
            <SVG.Close color="black" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item pb={2}>
        <Typography variant="body2">
          You told us that you work from home {daysPerWeek} days a week
          {hoursPerDay > 0 ? `, ${hoursPerDay} hours each day. ` : '. '}
          Is that still accurate?
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" spacing={2}>
        <Grid item xs>
          <Button
            variant="outlined"
            color="secondary"
            onClick={snooze}
            fullWidth
            size="small">
            Nothing Has Changed
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              snooze();
              history.push(
                `/member/questionnaire/residence-wfh?returnTo=dashboard`,
              );
            }}
            fullWidth>
            Update WFH Details
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
