import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ButtonBase, Dialog, Grid, Typography } from '@mui/material';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import UserImage from '../../common/User/UserImage';
import { CartoonSvgAvatar } from '../../common/CartoonSVGAvatar';
import EditAvatar from './EditAvatar';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.light,
    height: 263,
    width: '100vw',
    position: 'relative',
    left: '50%',
    right: '50%',
    marginLeft: '-50vw',
    marginRight: '-50vw',
  },
  item: {
    marginTop: 8,
  },
  content: {
    textAlign: 'center',
  },
}));

const MemberProfileAvatar: React.FC = () => {
  const classes = useStyles();
  const { user } = useContext(AuthorizationContext);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <Grid
      alignItems="center"
      justifyContent="center"
      className={classes.root}
      container>
      <Grid item>
        <Grid container direction="column" className={classes.content}>
          <Grid item>
            <ButtonBase onClick={() => setIsEditingAvatar(true)}>
              {user.avatar ? (
                <CartoonSvgAvatar size="lg" avatar={user.avatar} />
              ) : (
                <UserImage user={user} />
              )}
            </ButtonBase>

            <Typography className={classes.item} variant="h2">
              {user.name}
            </Typography>
            <Grid item className={classes.item}>
              {user.place}, {user.region}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={isEditingAvatar}
        maxWidth="sm"
        onClose={() => setIsEditingAvatar(false)}>
        <EditAvatar
          avatar={user.avatar}
          onClose={() => setIsEditingAvatar(false)}
        />
      </Dialog>
    </Grid>
  );
};

export default MemberProfileAvatar;
