import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { useQuery } from '@apollo/client';
import { chartColors } from '../../../theme/theme';
import NumberUtils from '../../../utils/numberUtils';
import AppSection from '../../common/AppSection/AppSection';
import PieChartCard from '../../common/PieChartCard/PieChartCard';
import {
  HABIT_METRICS,
  HABIT_USAGE,
} from '../../../graphql/dashboard/engagement';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import AppCard from '../../common/AppCard/AppCard';
import { DashboardContext } from '../../../contexts/DashboardContext';
import CompanyHabitsList from './CompanyHabitsList/CompanyHabitsList';
import useCurrentCompany from '../../../hooks/useCurrentCompany';
import NoDataAvailable from '../../common/NoDataAvailable';

const useStyles = makeStyles(() => ({
  legend: {
    fontSize: 12,
    textTransform: 'uppercase',
  },
}));

const HabitMetrcs: React.FC = () => {
  const classes = useStyles();
  const { companyId, includeChildren } = useCurrentCompany();

  const { endDate } = useContext(DashboardContext);

  const {
    data: {
      habitMetrics = { participation: undefined, activity: undefined },
    } = {},
    loading,
    error,
  } = useQuery(HABIT_METRICS, {
    variables: {
      companyId,
      includeChildren,
      endDate,
    },
  });

  const {
    data: { habitsWithUsageData: habits = [] } = {},
    loading: habitsLoading,
    error: habitsError,
  } = useQuery(HABIT_USAGE, {
    variables: {
      companyId,
      includeChildren,
      date: endDate,
    },
  });

  const totalHabits = habits.reduce(
    (sum, cur) => sum + cur.totalParticipants,
    0,
  );

  const { participation = { enrolled: 0, notEnrolled: 0, total: 0 } } =
    habitMetrics;

  const hasParticipation = participation.total > 0;

  return (
    <Grid container direction="column">
      <DataStateHandler
        loading={loading || habitsLoading}
        error={error || habitsError}>
        <>
          <Grid item>
            <AppSection header="My Habits Metrics">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  {hasParticipation ? (
                    <PieChartCard
                      headerProps={{
                        title: `MY HABITS PARTICIPATION`,
                        tooltipId: 'betterMeParticipation',
                      }}
                      data={[
                        {
                          id: 'Enrolled',
                          label: 'Enrolled',
                          value: participation.enrolled || 0,
                          color: chartColors.darkBlue,
                        },
                        {
                          id: 'Not Enrolled',
                          label: 'Not Enrolled',
                          value: participation.notEnrolled || 0,
                          color: chartColors.blue,
                        },
                      ]}
                      dataLabels={[
                        NumberUtils.format(
                          participation.total
                            ? participation.enrolled / participation.total
                            : 0,
                          'wholePercent',
                        ),
                        NumberUtils.format(
                          participation.total
                            ? participation.notEnrolled / participation.total
                            : 0,
                          'wholePercent',
                        ),
                      ]}
                      footer={{
                        data: NumberUtils.format(
                          participation.total || 0,
                          'integer',
                        ),
                        label: 'Total Members',
                      }}
                      showTooltip
                    />
                  ) : (
                    <AppCard
                      height={366}
                      headerProps={{
                        title: `MY HABITS PARTICIPATION`,
                        tooltipId: 'betterMeParticipation',
                      }}>
                      <NoDataAvailable />
                    </AppCard>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <AppCard
                    height={366}
                    headerProps={{ title: 'MY HABITS USER OVERVIEW' }}>
                    <Grid
                      container
                      direction="column"
                      sx={{ height: '100%' }}
                      justifyContent="space-between">
                      <Grid item>
                        <Typography variant="h1" color="primary">
                          {NumberUtils.format(totalHabits, 'integer')}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.legend}>
                          Total number of habits added
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h1" color="primary">
                          {NumberUtils.format(participation.total, 'integer')}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.legend}>
                          Total number of users
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h1" color="primary">
                          {participation.total
                            ? NumberUtils.format(
                                totalHabits / participation.total,
                                'number',
                              )
                            : 0}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          className={classes.legend}>
                          Average number of habits per user
                        </Typography>
                      </Grid>
                    </Grid>
                  </AppCard>
                </Grid>
              </Grid>
            </AppSection>
          </Grid>
          <Grid item style={{ marginTop: 12 }}>
            <AppSection header="Habit List">
              <CompanyHabitsList habits={habits} />
            </AppSection>
          </Grid>
        </>
      </DataStateHandler>
    </Grid>
  );
};

export default HabitMetrcs;
