import React, { useEffect, useState } from 'react';
import { Grid, IconButton } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import SVG from '../../assets/svg-v2';

type Props = {
  items: JSX.Element[];
};

const AppCarousel: React.FC<Props> = ({ items }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    // Reset the index if the items list changes and is now shorter than the current index
    if (index > items.length - 1) {
      setIndex(0);
    }
  }, [index, items]);

  if (!items.length) return null;

  return (
    <>
      <Carousel
        selectedItem={index}
        onChange={setIndex}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        interval={4000}
        infiniteLoop>
        {items}
      </Carousel>
      <Grid
        style={{ position: 'relative', top: -24 }}
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between">
        <IconButton
          aria-label="Previous Item"
          onClick={() => setIndex(index === 0 ? items.length - 1 : index - 1)}
          size="large">
          <SVG.CaretLeftSmall />
        </IconButton>
        <Grid item>
          {items.map((x, i) => (
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: 5,
                display: 'inline-block',
                margin: '0 6px',
                backgroundColor: index === i ? '#2D63F9' : '#A9A9A9',
              }}
            />
          ))}
        </Grid>
        <IconButton
          aria-label="Next Item"
          onClick={() => setIndex(index === items.length - 1 ? 0 : index + 1)}
          size="large">
          <SVG.CaretRightSmall />
        </IconButton>
      </Grid>
    </>
  );
};

export default AppCarousel;
