import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

export type ChartLabelProps = {
  color?: string;
  imageUri?: string;
  label: string;
  labelColor?: string;
  valueColor?: string;
  value?: string;
  labelStyle?: React.CSSProperties;
  size?: 'small' | 'medium';
  endAdornment?: JSX.Element;
  rootClass?: string;
};

const useStyles = makeStyles((theme) => ({
  labelSmall: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    marginRight: 8,
  },
  labelMedium: {
    fontSize: '1.125rem',
    color: theme.palette.text.secondary,
    marginRight: 8,
  },
  rootSmall: {
    padding: 4,
    width: '100%',
  },
  rootMedium: {
    padding: 8,
    width: '100%',
  },
  grid: {
    width: '100%',
  },
}));

type SizeOptions = {
  labelClass: string;
  dotSize: number;
  labelTypography: 'subtitle2' | 'body2';
  rootClass: string;
};

const ChartLabel: React.FC<ChartLabelProps> = ({
  color,
  imageUri,
  label,
  value,
  labelColor,
  valueColor,
  labelStyle,
  rootClass,
  size = 'small',
  endAdornment,
}) => {
  const classes = useStyles();

  let sizeOptions: SizeOptions;
  switch (size) {
    case 'medium':
      sizeOptions = {
        labelClass: classes.labelMedium,
        dotSize: 10,
        labelTypography: 'body2',
        rootClass: classes.rootMedium,
      };
      break;
    case 'small':
    default:
      sizeOptions = {
        labelClass: classes.labelSmall,
        dotSize: 8,
        labelTypography: 'subtitle2',
        rootClass: classes.rootMedium,
      };
      break;
  }

  return (
    <Grid
      container
      className={`${sizeOptions.rootClass} ${rootClass || ''}`}
      direction="row"
      alignItems="center"
      justifyContent="space-between">
      <Grid item>
        <Typography
          style={labelStyle}
          className={sizeOptions.labelClass}
          variant={sizeOptions.labelTypography}>
          <Grid container alignItems="center">
            {color ? (
              <Grid item>
                <Box
                  data-testid="chart-label-dot"
                  borderRadius="50%"
                  width={sizeOptions.dotSize}
                  height={sizeOptions.dotSize}
                  bgcolor={color}
                  mr={1}
                />
              </Grid>
            ) : null}
            {imageUri ? (
              <Grid item>
                <img
                  data-testid="chart-label-img"
                  src={imageUri}
                  alt={label}
                  style={{ marginRight: 8 }}
                />
              </Grid>
            ) : null}
            <Grid item>
              <span style={labelColor ? { color: labelColor } : {}}>
                {label}
              </span>
            </Grid>
            {endAdornment && (
              <Grid item data-testid="chart-label-adornment">
                {endAdornment}
              </Grid>
            )}
          </Grid>
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className={sizeOptions.labelClass}
          variant={sizeOptions.labelTypography}
          style={valueColor ? { color: valueColor } : {}}>
          {value ? ` ${value}` : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ChartLabel;
