import React from 'react';

const allQuestionsCompletedDisabled = (
  level: number | undefined,
  size: number,
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3236 62.9206C3.97867 53.5756 3.97867 38.4244 13.3236 29.0794L29.0798 13.3233C38.4247 3.97834 53.5759 3.97834 62.9209 13.3233L78.677 29.0794C88.022 38.4244 88.022 53.5756 78.677 62.9206L62.9209 78.6767C53.5759 88.0217 38.4247 88.0217 29.0798 78.6767L13.3236 62.9206Z"
      fill="white"
    />
    <path
      d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40885 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5935 39.0936 84.5935 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
      fill="url(#paint0_angular_5400_144387)"
    />
    <mask
      id="mask0_5400_144387"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40885 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5935 39.0936 84.5935 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
        fill="url(#paint1_angular_5400_144387)"
      />
    </mask>
    <g mask="url(#mask0_5400_144387)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M103.737 15.1727C104.152 15.5875 104.152 16.2599 103.737 16.6744L33.7319 86.6799C29.241 91.1709 21.9567 91.1679 17.462 86.6732C12.9674 82.1786 12.9644 74.8943 17.4553 70.4033L76.6691 11.1896C80.9131 6.94555 80.9103 0.0617308 76.6627 -4.18582C72.4152 -8.43337 65.5314 -8.43619 61.2873 -4.19213L18.8982 38.197C18.4837 38.6115 17.8113 38.6113 17.3964 38.1964C16.9816 37.7815 16.9813 37.1092 17.3958 36.6947L59.785 -5.69448C64.8581 -10.7676 73.0866 -10.7642 78.1638 -5.68693C83.2411 -0.609657 83.2445 7.61885 78.1714 12.6919L18.9577 71.9057C15.2957 75.5676 15.2982 81.5072 18.9631 85.1721C22.6281 88.8371 28.5676 88.8395 32.2296 85.1776L102.235 15.172C102.65 14.7575 103.322 14.7578 103.737 15.1727Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88961 53.8896 2.88961 38.1104 12.6219 28.3781L28.3781 12.6219C38.1104 2.88961 53.8896 2.88961 63.6219 12.6219L79.3781 28.3781C89.1104 38.1104 89.1104 53.8896 79.3781 63.6219L63.6219 79.3781C53.8896 89.1104 38.1104 89.1104 28.3781 79.3781L12.6219 63.6219ZM14.0247 29.7808C5.06706 38.7384 5.06706 53.2616 14.0247 62.2192L29.7808 77.9753C38.7384 86.9329 53.2616 86.9329 62.2192 77.9753L77.9753 62.2192C86.9329 53.2616 86.9329 38.7384 77.9753 29.7808L62.2192 14.0247C53.2616 5.06707 38.7384 5.06706 29.7808 14.0247L14.0247 29.7808Z"
      fill="#6B6B6B"
    />
    <path
      d="M45.877 42.4634C51.1077 42.4634 55.4417 38.1706 55.4417 32.8235C55.4417 27.5517 51.1077 23.1836 45.877 23.1836C40.5716 23.1836 36.3124 27.5517 36.3124 32.8235C36.3124 38.1706 40.5716 42.4634 45.877 42.4634ZM52.5274 44.8734H51.2571C49.6132 45.7018 47.8198 46.0784 45.877 46.0784C43.9342 46.0784 42.0661 45.7018 40.4222 44.8734H39.1519C33.6223 44.8734 29.1389 49.4674 29.1389 55.0405V58.1283C29.1389 60.1617 30.7081 61.7432 32.7257 61.7432H59.0284C60.9712 61.7432 62.6151 60.1617 62.6151 58.1283V55.0405C62.6151 49.4674 58.057 44.8734 52.5274 44.8734Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3207 32.8232C35.3207 27.016 40.0117 22.1914 45.8772 22.1914C51.663 22.1914 56.4337 27.0109 56.4337 32.8232C56.4337 38.7159 51.658 43.455 45.8772 43.455C40.0167 43.455 35.3207 38.7108 35.3207 32.8232ZM45.8772 24.1752C41.132 24.1752 37.3045 28.0868 37.3045 32.8232C37.3045 37.6299 41.127 41.4712 45.8772 41.4712C50.5578 41.4712 54.45 37.6248 54.45 32.8232C54.45 28.0918 50.5527 24.1752 45.8772 24.1752ZM28.1472 55.0402C28.1472 48.9363 33.0579 43.8812 39.1521 43.8812C40.1541 43.8812 41.0949 44.1419 41.9245 44.44C43.1416 44.8772 44.4782 45.0862 45.8772 45.0862C47.2707 45.0862 48.5589 44.8789 49.7527 44.4445C50.584 44.142 51.5251 43.8812 52.5276 43.8812C58.6123 43.8812 63.6072 48.9267 63.6072 55.0402V58.128C63.6072 60.7153 61.5131 62.7348 59.0286 62.7348H32.7258C30.1532 62.7348 28.1472 60.7018 28.1472 58.128V55.0402ZM39.1521 45.865C34.1872 45.865 30.131 49.9979 30.131 55.0402V58.128C30.131 59.6209 31.2634 60.7511 32.7258 60.7511H59.0286C60.4297 60.7511 61.6234 59.6075 61.6234 58.128V55.0402C61.6234 50.0076 57.5021 45.865 52.5276 45.865C51.8596 45.865 51.1695 46.0401 50.431 46.3087C48.9916 46.8324 47.47 47.07 45.8772 47.07C44.2833 47.07 42.7157 46.8321 41.2538 46.3069C40.5113 46.0402 39.8205 45.865 39.1521 45.865Z"
      fill="#6B6B6B"
    />
    <path
      d="M37.0709 64.4061C37.0709 70.0895 32.4636 74.6969 26.7801 74.6969C21.0966 74.6969 16.4893 70.0895 16.4893 64.4061C16.4893 58.7226 21.0966 54.1152 26.7801 54.1152C32.4636 54.1152 37.0709 58.7226 37.0709 64.4061Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.4963 53.1608C28.0667 52.9153 30.646 53.5289 32.8198 54.9029L31.5766 56.8146C29.8376 55.7153 27.7742 55.2244 25.7178 55.4208C23.6615 55.6172 21.7316 56.4895 20.2383 57.8974C18.745 59.3054 17.775 61.1673 17.4841 63.1841C17.1932 65.2008 17.5982 67.2553 18.6342 69.0175C19.6702 70.7796 21.277 72.1471 23.1963 72.9002C25.1157 73.6533 27.2363 73.7483 29.2173 73.1699C31.1983 72.5916 32.9248 71.3734 34.1194 69.7112C35.3139 68.049 35.9072 66.0393 35.8039 64.0049L38.1039 63.8908C38.2331 66.4338 37.4915 68.946 35.9983 71.0238C34.505 73.1015 32.3469 74.6242 29.8706 75.3471C27.3944 76.0701 24.7437 75.9513 22.3444 75.0099C19.9452 74.0686 17.9368 72.3592 16.6418 70.1566C15.3468 67.9539 14.8405 65.3858 15.2041 62.8648C15.5678 60.3439 16.7803 58.0165 18.647 56.2565C20.5136 54.4965 22.9259 53.4062 25.4963 53.1608ZM27.4298 68.1016L40.2822 55.2272L38.6752 53.623L26.6137 65.7051L21.6151 60.8538L20.0337 62.4832L25.8356 68.1142L26.6389 68.8938L27.4298 68.1016Z"
      fill="#A9A9A9"
    />
    {level ? (
      <g clipPath="url(#clip0_5400_144387)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#E6E6E6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#6B6B6B"
        />
        <text
          x="74"
          y="77.5"
          fill="#6B6B6B"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_5400_144387"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.0019 43.5179) rotate(134.772) scale(44.0114 22.1644)">
        <stop offset="0.00538652" stopColor="#A9A9A9" />
        <stop offset="0.992958" stopColor="#E6E6E6" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_5400_144387"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1908 45.9977) rotate(-8.79121) scale(53.9478 54.824)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_5400_144387">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default allQuestionsCompletedDisabled;
