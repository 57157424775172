import { Grid, Typography } from '@mui/material';
import React from 'react';

type Props = {
  title: string;
  depth?: number;
};

const HabitNestedContainer: React.FC<Props> = ({
  title,
  depth = 0,
  children,
}) => (
  <Grid
    container
    spacing={2}
    justifyContent="space-between"
    alignItems="center"
    style={{
      border: '1px solid #888',
      borderRadius: 8,
      backgroundColor: '#'.padEnd(7, (14 - depth).toString(16)),
      position: 'relative',
      margin: '8px 0 16px 0',
      paddingBottom: 8,
      paddingRight: 16,
    }}>
    <Typography
      variant="body2"
      style={{
        position: 'absolute',
        top: -10,
        left: 5,
        color: '#fff',
        backgroundColor: '#888',
        borderRadius: 8,
        padding: '0 8px',
      }}>
      {title}
    </Typography>
    {children}
  </Grid>
);

export default HabitNestedContainer;
