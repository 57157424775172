import React from 'react';
import { useQuery } from '@apollo/client';
import { Company } from '../../../types/company/types';
import { COMPANY_DISPLAY_INFO_QUERY } from '../../../graphql/company/company';
import DataStateHandler from '../DataStateHandler/DataStateHandler';

type Props = {
  companyId: string;
};

const CompanyName: React.FC<Props> = ({ companyId }) => {
  const { loading, data: { company } = {} } = useQuery<{
    company: Company;
  }>(COMPANY_DISPLAY_INFO_QUERY, {
    variables: {
      id: companyId,
    },
  });

  return (
    <DataStateHandler loading={loading} loadingPaddingY={0}>
      <span>{company?.name}</span>
    </DataStateHandler>
  );
};

export default CompanyName;
