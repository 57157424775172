import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useParams } from 'react-router';
import { ApolloError, useQuery } from '@apollo/client';

import { AuthorizationContext } from './AuthorizationContext';
import { CommunicationsParams } from '../components/communications/CommunicationsContainer';
import DiscoverIcon from '../components/communications/icons/DiscoverIcon';
import EmailIcon from '../components/communications/icons/EmailIcon';
import GearIcon from '../components/communications/icons/GearIcon';
import NotificationIcon from '../components/communications/icons/NotificationsIcon';
import QuizIcon from '../components/communications/icons/QuizIcon';
import {
  CommunicationCategory,
  CommunicationListItem,
  EmailCommunicationListItem,
  LocalizedQuiz,
  Notification,
  Quotation,
} from '../components/communications/types';
import {
  COMMUNICATION_CATEGORIES,
  generateCommunicationsQuery,
} from '../graphql/communications/communications';
import { GET_CONTEST_OPTIONS } from '../graphql/contest/contests';
import { Contest, ContestOptions } from '../types/contest/types';
import { abilityType } from '../types/user/types';

export const navItems = [
  {
    id: 'discover',
    label: 'Discover',
    ability: abilityType('Client.Communication.Discover'),
    icon: (active: boolean) => <DiscoverIcon active={active} />,
  },
  {
    id: 'discover',
    label: 'Discover',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Discover'),
    icon: (active: boolean) => <DiscoverIcon active={active} />,
  },
  {
    id: 'quizzes',
    label: 'Quizzes',
    ability: abilityType('Client.Communication.Quizzes'),
    icon: (active: boolean) => <QuizIcon active={active} />,
  },
  {
    id: 'quizzes',
    label: 'Quizzes',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Quizzes'),
    icon: (active: boolean) => <QuizIcon active={active} />,
  },
  {
    id: 'polls',
    label: 'Polls',
    ability: abilityType('Client.Communication.Quizzes'),
    icon: (active: boolean) => <QuizIcon active={active} />,
  },
  {
    id: 'polls',
    label: 'Polls',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Quizzes'),
    icon: (active: boolean) => <QuizIcon active={active} />,
  },
  {
    id: 'challenges',
    label: 'Challenges',
    ability: abilityType('Client.Communication.Challenges'),
    icon: (active: boolean) => <DiscoverIcon active={active} />,
  },
  {
    id: 'emails',
    label: 'Emails',
    ability: abilityType('Client.Communication.Emails'),
    icon: (active: boolean) => <EmailIcon active={active} />,
  },
  {
    id: 'emails',
    label: 'Emails',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Emails'),
    icon: (active: boolean) => <EmailIcon active={active} />,
  },
  {
    id: 'notifications',
    label: 'Push Notifications',
    ability: abilityType('Client.Communication.PushNotifications'),
    icon: (active: boolean) => <NotificationIcon active={active} />,
  },
  {
    id: 'notifications',
    label: 'Push Notifications',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.PushNotifications'),
    icon: (active: boolean) => <NotificationIcon active={active} />,
  },
  {
    id: 'settings',
    label: 'Settings',
    isGlobal: true,
    ability: abilityType('ZeroMe.Communication.Settings'),
    icon: (active: boolean) => <GearIcon active={active} />,
  },
];

type CommunicationsContext = {
  communications: CommunicationListItem[];
  emails: EmailCommunicationListItem[];
  quotations: Quotation[];
  communicationCategories: CommunicationCategory[];
  notifications: Notification[];
  quizzes: LocalizedQuiz[];
  polls: LocalizedQuiz[];
  challenges: Contest[];
  contestOptions?: ContestOptions;
  communicationsLoading: boolean;
  communicationsError: ApolloError | undefined;
  companyId?: string;
  refetchCommunicationsQuery: () => void;
};

const initialContext: CommunicationsContext = {
  communications: [],
  emails: [],
  quotations: [],
  notifications: [],
  quizzes: [],
  polls: [],
  challenges: [],
  communicationCategories: [],
  communicationsError: undefined,
  communicationsLoading: false,
  companyId: '',
  refetchCommunicationsQuery: () => null,
};

export const CommunicationsContext = createContext(initialContext);

const CommunicationsProvider: FC = ({ children }) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const { companyId } = useParams<CommunicationsParams>();

  const variables = useMemo(
    () => ({
      includeGlobalCommunications: !companyId,
      companyId,
      ignoreCache: false,
    }),
    [companyId],
  );

  const {
    data: {
      communications: { items: communications = [] } = {},
      quotations = [],
      emailCommunications: { items: emails = [] } = {},
      notifications: { items: notifications = [] } = {},
      quizzes: { items: quizzes = [] } = {},
      findContests: { items: challenges = [] } = {},
    } = {},
    loading: communicationsLoading,
    error: communicationsError,
    refetch,
  } = useQuery(generateCommunicationsQuery(userHasAccess, !!companyId), {
    variables,
  });
  const { data: { communicationCategories = [] } = {} } = useQuery(
    COMMUNICATION_CATEGORIES,
  );
  const { data: { contestOptions } = {} } = useQuery(GET_CONTEST_OPTIONS);

  const refetchCommunicationsQuery = useCallback(
    () =>
      refetch({
        ...variables,
        ignoreCache: true,
      }),
    [refetch, variables],
  );

  const value = useMemo(
    () => ({
      communications,
      emails,
      quotations,
      notifications,
      quizzes: quizzes.filter((q: LocalizedQuiz) => q.quizType === 'QUIZ'),
      polls: quizzes.filter((q: LocalizedQuiz) => q.quizType === 'POLL'),
      challenges,
      contestOptions,
      communicationCategories,
      communicationsError,
      communicationsLoading,
      companyId,
      refetchCommunicationsQuery,
    }),
    [
      communications,
      emails,
      quotations,
      notifications,
      quizzes,
      challenges,
      contestOptions,
      communicationCategories,
      communicationsError,
      communicationsLoading,
      companyId,
      refetchCommunicationsQuery,
    ],
  );

  return (
    <CommunicationsContext.Provider value={value}>
      {children}
    </CommunicationsContext.Provider>
  );
};

export default CommunicationsProvider;
