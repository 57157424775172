import React from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppInfo from '../AppInfo';

const useStyles = makeStyles(() => ({
  section: {
    marginBottom: 16,
  },
  title: {
    fontSize: 12,
  },
  root: {
    height: 35,
  },
}));

export type AppCardHeaderProps = {
  title?: string;
  rightContent?: JSX.Element;
  tooltipId?: string;
  titleStyle?: React.CSSProperties;
  tooltipIconColor?: 'white';
  sectionStyle?: React.CSSProperties;
};

const AppCardHeader: React.FC<AppCardHeaderProps> = ({
  title,
  rightContent,
  tooltipId,
  titleStyle,
  tooltipIconColor,
  sectionStyle,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.section} style={sectionStyle}>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        className={classes.root}
        justifyContent="space-between">
        <Grid item>
          <Typography
            data-testid="app-card-header-title"
            className={classes.title}
            variant="subtitle1"
            style={titleStyle}
            color="textSecondary">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            {rightContent}
            {tooltipId ? (
              <AppInfo id={tooltipId} iconColor={tooltipIconColor} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default AppCardHeader;
