import clsx from 'clsx';
import { Box, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import SidebarNavigation from './SidebarNavigation';
import { Colors } from '../../../theme/shared';

export const OPEN_DRAWER_WIDTH = 280;
export const CLOSED_DRAWER_WIDTH = 64;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: OPEN_DRAWER_WIDTH,
  },
  paper: {
    borderRight: 0,
  },
  drawerOpen: {
    width: OPEN_DRAWER_WIDTH,
    background: Colors.DarkGrey,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: Colors.DarkGrey,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: CLOSED_DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: Number(theme.spacing) * 9 + 1,
    },
  },
}));

type Props = {
  open: boolean;
  openCallback(): void;
  closeCallback(): void;
};

const Sidebar: React.FC<Props> = ({ open, openCallback, closeCallback }) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      open={open}>
      <Box>
        <Box py={4}>
          <SidebarNavigation
            drawer={open}
            openCallback={openCallback}
            closeCallback={closeCallback}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
