import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import { Trans } from 'react-i18next';

import {
  Chip,
  Grid,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import MemberLayout from '../../../components/layouts/MemberLayout';
import { GET_DISCOVER_COMMUNICATIONS } from '../../../graphql/communications/memberCommunications';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import CommunicationCard from '../../../components/member/discover/CommunicationCard';
import AppCarousel from '../../../components/common/AppCarousel';
import SelectMenu from '../../../components/common/SelectMenu/SelectMenu';
import { COMMUNICATION_CATEGORIES } from '../../../graphql/communications/communications';

const useStyles = makeStyles((theme) => {
  const chip = {
    borderRadius: theme.spacing(3.5),
    padding: theme.spacing(0.5),
    margin: theme.spacing(0.5),
  };

  return {
    side: {
      [theme.breakpoints.up('lg')]: {
        maxHeight: 1200,
        overflowY: 'auto',
      },
    },
    drawer: {
      maxWidth: 680,
      overflowX: 'hidden',
      [theme.breakpoints.up('lg')]: {
        width: 680,
      },
    },
    selectedChip: chip,
    unselectedChip: {
      ...chip,
      backgroundColor: theme.palette.primary.light,
    },
    sort: {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
      '& .MuiSelect-select, & svg': {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
    },
  };
});

const MemberDiscover: React.FC = () => {
  const [sort, setSort] = useState('updatedDate_DESC');
  const [communicationCategory, setCommunicationCategory] = useState<
    string | undefined
  >(undefined);

  const { data: { communicationCategories = [] } = {} } = useQuery(
    COMMUNICATION_CATEGORIES,
  );
  const { company } = useContext(AuthorizationContext);

  const splitSort = sort.split('_');

  const {
    data: { communications: { items = [] } = {} } = {},
    loading,
    error,
  } = useQuery(GET_DISCOVER_COMMUNICATIONS, {
    variables: {
      companyId: company?.id,
      sortBy: splitSort[0],
      sortDirection: splitSort[1],
      categoryIds: communicationCategory ? [communicationCategory] : undefined,
    },
    skip: !company,
  });

  const theme = useTheme();
  const lessThanMd = useMediaQuery(theme.breakpoints.down('lg'));

  const classes = useStyles();

  const history = useHistory();

  const featured = items.filter((i) => i.isFeatured);

  const notFeatured = items.filter((i) => !i.isFeatured);

  const navigate = (id: string) => {
    history.push(`/member/discover/${id}`);
  };

  return (
    <MemberLayout>
      <DataStateHandler loading={loading} error={error}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Chip
              className={
                communicationCategory
                  ? classes.unselectedChip
                  : classes.selectedChip
              }
              color={communicationCategory ? 'default' : 'primary'}
              key="all"
              label="All"
              onClick={() => setCommunicationCategory(undefined)}
            />
            {communicationCategories.map((hc) => (
              <Chip
                className={
                  communicationCategory === hc.id
                    ? classes.selectedChip
                    : classes.unselectedChip
                }
                key={hc.id}
                color={communicationCategory === hc.id ? 'primary' : 'default'}
                label={hc.label}
                onClick={() => setCommunicationCategory(hc.id)}
              />
            ))}
          </Grid>
          {!featured.length && !notFeatured.length && (
            <Grid item xs={12}>
              <Typography align="center" variant="h3">
                {t('communication.nothingRelated')}
              </Typography>
            </Grid>
          )}
          {featured.length ? (
            <Grid item xs={12}>
              <Typography variant="h3">
                <Trans i18nKey="featured" />
              </Typography>
            </Grid>
          ) : null}
          <Grid item lg={9} xs={12} style={{ paddingTop: 16 }}>
            <Grid item xs={12}>
              <AppCarousel
                items={featured.map((item) => (
                  <CommunicationCard
                    key={item.id}
                    imageHeight={400}
                    communication={item}
                    onClick={(id) => navigate(id)}
                  />
                ))}
              />
            </Grid>
            {notFeatured.length ? (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Typography variant="h3">
                    <Trans i18nKey="sustainabilityNews" />
                  </Typography>
                  <SelectMenu
                    id="discover-sort"
                    value={sort}
                    onChange={(e) => setSort(e.target.value)}
                    className={classes.sort}>
                    <MenuItem value="updatedDate_DESC">
                      <Trans i18nKey="sortByMostRecentFirst" />
                    </MenuItem>
                    <MenuItem value="updatedDate_ASC">
                      <Trans i18nKey="sortByOldestFirst" />
                    </MenuItem>
                    <MenuItem value="averageScore_DESC">
                      <Trans i18nKey="sortByHighestRating" />
                    </MenuItem>
                  </SelectMenu>
                </Grid>
                <Grid container spacing={4}>
                  {notFeatured.slice(0, 4).map((fc) => (
                    <Grid item lg={6} xs={12}>
                      <CommunicationCard
                        imageHeight={234}
                        key={fc.id}
                        communication={fc}
                        onClick={(id) => navigate(id)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            style={{ top: 16, position: 'relative', paddingTop: 0 }}
            className={classes.side}>
            {notFeatured.slice(4).map((fc) => (
              <CommunicationCard
                key={fc.id}
                communication={fc}
                imageHeight={lessThanMd ? 234 : 112}
                onClick={(id) => navigate(id)}
              />
            ))}
          </Grid>
        </Grid>
      </DataStateHandler>
    </MemberLayout>
  );
};

export default MemberDiscover;
