import React from 'react';
import { useParams } from 'react-router';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberPollResultsDetail from '../../../components/member/poll/MemberPollResultsDetail';

const MemberPollResultDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <MemberLayout>
      <MemberPollResultsDetail pollId={id} />
    </MemberLayout>
  );
};

export default MemberPollResultDetails;
