import { FormikState } from 'formik';
import React, { useEffect } from 'react';
import {
  ProjectContextEvents,
} from '../../../contexts/ProjectContext';
import { Project } from '../../../types/project/types';

const ResetProjectFormListener: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetForm: (nextState: Partial<FormikState<any>>) => void;
  makeInitialValues: (project: Project) => Record<string, string | boolean | number>;
}> = ({ resetForm, makeInitialValues }) => {
  const doReset = ({ detail }: CustomEventInit<Project>) => {
    if (detail) {
      const values = makeInitialValues(detail);
      resetForm({ values });
    }
  };

  useEffect(() => {
    document.addEventListener(ProjectContextEvents.RELOAD, doReset);
    return () => {
      document.removeEventListener(ProjectContextEvents.RELOAD, doReset);
    };
  }, []);

  return null;
};

export default ResetProjectFormListener;
