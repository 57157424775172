import { gql, TypedDocumentNode } from '@apollo/client';
import { ProjectPurchaseHistory } from '../../types/offsetProject/types';
import { generateProjectFields, OFFSET_PROJECTS_FIELDS } from './projects';

export const CREATE_PROJECT_MUTATION = gql`
  mutation createProject($project: ProjectInput!) {
    createProject(project: $project) {
      ${generateProjectFields(true)}
    }
  }
`;

export const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject($project: ProjectInput!, $projectId: String!) {
    updateProject(project: $project, projectId: $projectId) {
      ${generateProjectFields(true)}
    }
  }
`;

export const DELETE_PROJECT_MUTATION = gql`
  mutation deleteProject($projectId: String!) {
    deleteProject(projectId: $projectId)
  }
`;

/**
 * Mutation for 'Credit Requests' CSV file upload.
 */
export const UPLOAD_PROJECT_PURCHASE_HISTORY_CSV = gql`
  mutation uploadProjectPurchaseHistoryCsv(
    $upload: UploadProjectPurchaseHistoryCsvInput!
  ) {
    uploadProjectPurchaseHistoryCsv(upload: $upload) {
      result
      container {
        responseCsv
        errorCount
        successCount
        skippedCount
      }
    }
  }
`;

export const CONFIRM_PROJECT_PURCHASES = gql`
  mutation confirmProjectPurchases($companyId: String!, $purchaseIds: [String]!, $completedDate: ISO8601DateOnly!, $serialNumber: String!) {
    confirmProjectPurchases(companyId: $companyId, purchaseIds: $purchaseIds, completedDate: $completedDate, serialNumber: $serialNumber) {
      ${OFFSET_PROJECTS_FIELDS()}
    }
  }
` as TypedDocumentNode<
  { confirmProjectPurchases: ProjectPurchaseHistory[] },
  {
    companyId: string;
    purchaseIds: string[];
    completedDate: string;
    serialNumber: string;
  }
>;

export const TRACK_PROJECT_VIEW = gql`
  mutation trackProjectView($projectId: String!) {
    trackProjectView(projectId: $projectId)
  }
` as TypedDocumentNode<
  number,
  {
    projectId: string;
  }
>;

export const UPSERT_PROJECT_INVENTORY = gql`
  mutation upsertProjectInventory(
    $projectId: String!
    $input: ProjectInventoryHistoryInput!
  ) {
    upsertProjectInventory(
      projectId: $projectId
      projectInventoryHistoryInput: $input
    ) {
      id
      availableQuantity
      remainingQuantity
      inventoryHistory {
        id
        purchasedDate
        totalPurchaseAmount
        remainingQuantity
        pricePerUnit
        quantity
        notes
      }
    }
  }
`;

export const REMOVE_PROJECT_INVENTORY = gql`
  mutation removeProjectInventory(
    $projectId: String!
    $projectInventoryHistoryId: String!
  ) {
    removeProjectInventory(
      projectId: $projectId
      projectInventoryHistoryId: $projectInventoryHistoryId
    ) {
      id
      availableQuantity
      remainingQuantity
      inventoryHistory {
        id
        purchasedDate
        totalPurchaseAmount
        remainingQuantity
        pricePerUnit
        quantity
      }
    }
  }
`;

export const CONTACT_TRADER = gql`
  mutation contactTrader(
    $type: ContactTraderType!
    $content: String
    $projectId: String
  ) {
    contactTrader(type: $type, content: $content, projectId: $projectId)
  }
`;

export const UPDATE_PURCHASE = gql`
  mutation updatePurchase(
    $projectPurchaseId: String!
    $projectPurchase: ProjectPurchaseInput!
    $notes: String!
  ) {
    updatePurchase(
      projectPurchaseId: $projectPurchaseId
      projectPurchase: $projectPurchase
      notes: $notes
    ) {
      id
      quantity
      remaining
      allocated
      status
      updateNotes {
        date
        notes
      }
    }
  }
`;

export const CANCEL_PURCHASE = gql`
  mutation cancelPurchase($projectPurchaseId: String!, $notes: String!) {
    cancelPurchase(projectPurchaseId: $projectPurchaseId, notes: $notes) {
      id
      quantity
      remaining
      allocated
      status
    }
  }
`;

export const APPROVE_PURCHASE = gql`
  mutation approvePurchase($projectPurchaseId: String!) {
    approvePurchase(projectPurchaseId: $projectPurchaseId) {
      id
      status
      approvedByUser {
        id
        name
        email
      }
      approvalDate
      exportedToCompassDate
    }
  }
`;

export const UNAPPROVE_PURCHASE = gql`
  mutation unapprovePurchase($projectPurchaseId: String!) {
    unapprovePurchase(projectPurchaseId: $projectPurchaseId) {
      id
      status
      approvedByUser {
        id
        name
        email
      }
      approvalDate
      exportedToCompassDate
    }
  }
`;
