import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { VictoryAxis, VictoryBar, VictoryChart } from 'victory';

import { Colors } from '../../../theme/shared';
import { EmissionsBenchmarkChartData } from '../../../types/user/types';
import { MTCO2E } from '../../../utils/stringConstants';
import { typographyToVictory } from '../../../utils/victoryUtils';

const useStyles = makeStyles(() => ({
  legendContainer: {
    padding: 12,
  },
  legendBox: {
    width: 8,
    height: 8,
    borderRadius: 2,
    marginRight: 4,
  },
}));

type LegendProps = {
  label: string;
  fill: string;
};

const EmissionsBenchmarkLegend: React.FC<LegendProps> = ({ label, fill }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.legendContainer}>
      <Grid container alignItems="center">
        <Box className={classes.legendBox} bgcolor={fill} />
        <Typography variant="subtitle2">{label}</Typography>
      </Grid>
    </Grid>
  );
};

type ChartProps = {
  data: EmissionsBenchmarkChartData[];
  label: string;
  horizontal?: boolean;
};

const EmissionsBenchmarkChart: React.FC<ChartProps> = ({
  data,
  label,
  horizontal = false,
}) => {
  const theme = useTheme();

  return (
    <Grid container item direction="column">
      <Typography variant="h4">{label}</Typography>
      <Typography variant="subtitle2" color={Colors.TertiaryText}>
        {MTCO2E}
      </Typography>
      <Grid container item>
        <VictoryChart
          {...(horizontal
            ? {
                padding: { top: 20, bottom: 20 },
                domainPadding: { x: 60 / data.length, y: 48 },
              }
            : {
                padding: 0,
                domainPadding: { x: 48, y: 30 },
              })}
          height={200}>
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            // Hide tick labels
            tickFormat={[]}
            style={{
              axis: { stroke: 'transparent' },
            }}
          />
          <VictoryBar
            {...(horizontal
              ? { horizontal, barWidth: 50 }
              : {
                  barWidth: 250 / data.length,
                })}
            cornerRadius={{
              topLeft: 4,
              topRight: 4,
            }}
            style={{
              data: {
                fill: ({ datum }) => datum.fill,
              },
              labels: {
                ...typographyToVictory(theme.typography.h4),
                fill: Colors.TertiaryText,
              },
            }}
            data={data}
            labels={({ datum }) => datum.y}
          />
        </VictoryChart>
      </Grid>
      <Grid container item>
        {data.map((d) => (
          <EmissionsBenchmarkLegend
            key={d.x}
            label={d.legendLabel}
            fill={d.fill}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default EmissionsBenchmarkChart;
