import { useQuery } from '@apollo/client';
import {
  Badge,
  Box,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { GET_COMPANY_ACTIONS_COUNT } from '../../../graphql/company/company';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import useAllCompanies from '../../../hooks/useAllCompanies';
import { Company } from '../../../types/company/types';
import ArrayUtils from '../../../utils/arrayUtils';
import NumberUtils from '../../../utils/numberUtils';
import {
  projectCategoryFilterLabel,
  projectCategorySearchFunction,
} from '../../../utils/projectUtils';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import CompanyNameWithLogo from '../../common/Company/CompanyNameWithLogo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconCircleGroup from '../../common/Project/CategoryIconCircleGroup';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import RoundedFlexBox from '../RoundedFlexBox';
import ClientActions from './ClientActions/ClientActions';
import ClientDetails from './ClientDetails';
import { ZeroMeTM } from '../../../utils/stringConstants';

const useStyles = makeStyles((theme) => ({
  headerRow: {
    marginTop: 16,
    marginBottom: 28,
  },
  drawer: {
    maxWidth: 1338,
    [theme.breakpoints.up('lg')]: {
      width: 1338,
    },
  },
  smallDrawer: {
    maxWidth: 620,
    [theme.breakpoints.up('lg')]: {
      width: 620,
    },
  },
  badge: {
    left: 4,
  },
}));
const Clients: React.FC = () => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const classes = useStyles();
  const [company, setCompany] = useState<Company>();
  const [drawerState, setDrawerState] = useState<
    'CLOSED' | 'COMPANY_DETAILS' | 'CLIENT_ACTIONS'
  >('CLOSED');

  const [showDemoClients, setShowDemoClients] = useState(false);

  const showBell = userHasAccess('ZeroMe.ClientActions', 'VIEW');

  const {
    loading,
    error,
    allCompanies = [],
  } = useAllCompanies({
    includeEmployeeMetrics: true,
    includeCurrentBudget: true,
  });

  // Required for categories column filtering
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const {
    loading: actionsLoading,
    error: actionsError,
    data: { companyActionsCount = 0 } = {},
  } = useQuery(GET_COMPANY_ACTIONS_COUNT, {
    skip: !showBell,
  });

  const sorted = ArrayUtils.groupAndSortHierarchy(
    allCompanies,
    'parentCompanyId',
  ).filter((o) => (showDemoClients ? !!o.isDemoCompany : !o.isDemoCompany));

  const tableStructure: GroupableTableStructure<Company & { level: number }>[] =
    [
      {
        key: 'main',
        columns: [
          {
            key: 'name',
            display: 'Client',
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Names',
            render: (c) => <CompanyNameWithLogo indent={c.level} company={c} />,
          },
          {
            key: 'categoryIds',
            display: 'Interests',
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Interests',
            searchFunction: projectCategorySearchFunction(projectCategories),
            resolveFilterLabel: projectCategoryFilterLabel(projectCategories),
            render: ({ categoryIds }) =>
              categoryIds?.length ? (
                <CategoryIconCircleGroup categoryIds={categoryIds} />
              ) : null,
          },
          {
            key: 'currentBudget',
            display: 'Budget',
            render: ({ currentBudget }) => (
              <Typography variant="body2">
                {NumberUtils.format(currentBudget?.total, 'currency')}
              </Typography>
            ),
          },
          {
            key: 'employees',
            display: 'Members',
            render: ({ currentEmployeeMetrics }) => (
              <Typography variant="body2">
                {NumberUtils.format(
                  currentEmployeeMetrics?.enabledEmployeeCount,
                  'integer',
                )}
              </Typography>
            ),
          },
          {
            key: 'createdDate',
            display: 'Member Since',
            format: (date) =>
              (typeof date === 'string' &&
                format(new Date(date), 'MM/dd/yyyy')) ||
              '',
          },
          {
            key: 'active',
            display: 'Status',
            format: () => 'Active',
          },
          {
            key: 'edit',
            render: ({ id }) =>
              userHasAccess('ZeroMe.Clients', 'EDIT') ? (
                <Link to={`/settings/company/${id}`}>
                  <IconButton onClick={() => null} size="large">
                    <SVG.Gear />
                  </IconButton>
                </Link>
              ) : null,
          },
        ],
      },
    ];

  return (
    <Grid container>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        className={classes.headerRow}
        justifyContent="space-between">
        <Typography variant="h1">{ZeroMeTM} Clients</Typography>
        <Grid item>
          <Grid container alignItems="center">
            <Box mr={3}>
              <FormControlLabel
                componentsProps={{
                  typography: {
                    style: { fontSize: 14 },
                  },
                }}
                control={
                  <Switch
                    checked={showDemoClients}
                    onChange={() => setShowDemoClients(!showDemoClients)}
                  />
                }
                label="Demo Clients"
              />
            </Box>
            {showBell ? (
              <Box mr={3}>
                <IconButton onClick={() => setDrawerState('CLIENT_ACTIONS')}>
                  <Badge
                    classes={{ badge: classes.badge }}
                    badgeContent={companyActionsCount}
                    color="primary"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}>
                    <SVG.Bell />
                  </Badge>
                </IconButton>
              </Box>
            ) : null}

            {userHasAccess('ZeroMe.Clients', 'EDIT') && (
              <Link
                to="/settings/company/new"
                style={{ textDecoration: 'none' }}>
                <SmallPaddedButton>+ Add New Client</SmallPaddedButton>
              </Link>
            )}
          </Grid>
        </Grid>
      </Grid>

      <DataStateHandler
        loading={loading || categoriesLoading || actionsLoading}
        error={categoriesError || error || actionsError}>
        <Grid
          container
          direction="column"
          style={{ height: 'calc(100vh - 110px)' }}>
          <RoundedFlexBox>
            <GroupableSelectTable<Company & { level: number }>
              tableData={sorted}
              tableStructure={tableStructure}
              queryVariables={{ 'sort-list': projectCategories }}
              hideStickyCol
              onRowClick={(c) => {
                setDrawerState('COMPANY_DETAILS');
                setCompany(c);
              }}
            />
          </RoundedFlexBox>
        </Grid>
      </DataStateHandler>

      <Drawer
        open={drawerState === 'COMPANY_DETAILS'}
        onClose={() => {
          setDrawerState('CLOSED');
          setCompany(undefined);
        }}
        anchor="right">
        {company && (
          <Box className={classes.drawer}>
            <ClientDetails
              companyId={company.id}
              handleClose={() => {
                setDrawerState('CLOSED');
                setCompany(undefined);
              }}
            />
          </Box>
        )}
      </Drawer>

      <Drawer
        open={drawerState === 'CLIENT_ACTIONS'}
        onClose={() => {
          setDrawerState('CLOSED');
        }}
        anchor="right">
        <Box className={classes.smallDrawer}>
          <ClientActions onClose={() => setDrawerState('CLOSED')} />
        </Box>
      </Drawer>
    </Grid>
  );
};

export default Clients;
