import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import moment from 'moment';

import LeaderboardSummaryBar from './LeaderboardSummaryBar';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { getLeaderboardsQuery } from '../../../graphql/contest/contests';
import { Colors } from '../../../theme/shared';
import { Contest, LeaderboardRollup } from '../../../types/contest/types';
import getUserContestPoints from '../../../utils/contestUtils';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: 'none !important',
    width: '100%',
    borderRadius: 16,
  },
  subtitle: {
    color: Colors.TertiaryText,
    marginTop: 8,
    marginBottom: 8,
  },
  image: {
    borderRadius: 16,
  },
  sashContainer: {
    position: 'absolute',
    top: 16,
    left: 15.5,
  },
  sashIcon: {
    position: 'absolute',
    top: 8,
    left: 8,
  },
  summaryBar: {
    position: 'absolute',
    left: 0,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

type Props = {
  challenge?: Contest;
  titlePosition: 'top' | 'bottom';
  imageHeight: number;
  showSummary?: boolean;
  showSash?: boolean;
  disabled?: boolean;
};

const ChallengeCard: React.FC<Props> = ({
  challenge,
  titlePosition,
  imageHeight,
  showSummary,
  showSash,
  disabled,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthorizationContext);
  const {
    id: challengeId,
    title,
    imageUrl,
    pointCategories,
    userPoints,
    optedOutUsers,
    duration: { startDate = '', endDate = '' } = {},
  } = challenge || {};

  const {
    loading,
    error,
    data: { leaderboards: [leaderboard] = [undefined] } = {},
  } = useQuery(getLeaderboardsQuery(true, false), {
    variables: {
      contestId: challengeId,
      rollup: LeaderboardRollup.PARENT,
    },
    skip: !challengeId || !showSummary,
  });

  const optedOut = optedOutUsers?.includes(user?.id || '');
  const userRank = leaderboard?.contestRanking?.find(
    (cr) => cr.userId === user?.id,
  );
  const totalPoints =
    userRank?.contestTotal || getUserContestPoints(userPoints, pointCategories);

  const sashIcon = {
    1: <SVG.ContestGold className={classes.sashIcon} />,
    2: <SVG.ContestSilver className={classes.sashIcon} />,
    3: <SVG.ContestBronze className={classes.sashIcon} />,
  }[userRank?.rank ?? 0];

  return (
    <DataStateHandler loading={loading} error={error}>
      <Card className={classes.card}>
        <CardActionArea
          onClick={() => history.push(`/member/challenge/${challengeId}`)}
          disabled={disabled}>
          <CardContent>
            {titlePosition === 'top' && (
              <Grid container item direction="column" alignItems="center">
                <Typography variant="h2">{title}</Typography>
                <Typography className={classes.subtitle} variant="body1">
                  {moment(startDate).format('MMM D, YYYY')} -{' '}
                  {moment(endDate).format('MMM D, YYYY')}
                </Typography>
              </Grid>
            )}
            {showSash && sashIcon && (
              <Grid item className={classes.sashContainer}>
                <SVG.Sash />
                {sashIcon}
              </Grid>
            )}
            <CardMedia
              component="img"
              className={classes.image}
              image={imageUrl}
              alt={title}
              height={imageHeight}
            />
            {showSummary && (
              <Grid
                container
                item
                className={classes.summaryBar}
                style={{ bottom: imageHeight / 10 }}>
                <LeaderboardSummaryBar
                  rank={userRank?.rank}
                  points={totalPoints}
                  optedOut={optedOut}
                  boxShadow={3}
                />
              </Grid>
            )}
            {titlePosition === 'bottom' && (
              <Grid container item direction="column">
                <Typography variant="h3">{title}</Typography>
                <Typography className={classes.subtitle} variant="body1">
                  {moment(endDate).format('MMMM YYYY')}
                </Typography>
              </Grid>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </DataStateHandler>
  );
};

export default ChallengeCard;
