import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useQuery } from '@apollo/client';
import AppTextField from '../common/AppTextField';
import {
  CompanySettingsContext,
  EditableCompany,
} from '../../contexts/CompanySettingsContext';
import { GET_ALL_REGIONS } from '../../graphql/settings';
import { ISOCountry, ISORegion } from '../../types/common';
import useDebounce from '../../hooks/useDebounce';
import { mapboxGeocode } from '../../utils/mapboxUtils';
import LocationMap from '../common/LocationMap';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';

const CompanyLocationForm: React.FC = () => {
  const { allCountries } = useContext(CompanySettingsContext);
  const [lastCountry, setLastCountry] = useState<string>();
  const { values, isValid, setTypedFieldValue } =
    useTypedFormikContext<EditableCompany>();

  const { loading: regionsLoading, data: { allRegions = [] } = {} } = useQuery<{
    allRegions: ISORegion[];
  }>(GET_ALL_REGIONS, {
    skip: !values.country,
    variables: {
      countryCode: values.country,
    },
  });

  const address = useDebounce(
    isValid
      ? `${values.address} ${values.place}, ${values.region} ${values.postalCode} ${values.country}`
      : '',
    500,
  );

  useEffect(() => {
    (async () => {
      if (isValid) {
        const coords = await mapboxGeocode(address);
        if (coords) {
          setTypedFieldValue('latitude', coords.lat);
          setTypedFieldValue('longitude', coords.lng);
          return;
        }
      }

      setTypedFieldValue('latitude', null);
      setTypedFieldValue('longitude', null);

      if (lastCountry && lastCountry !== values.country) {
        setTypedFieldValue('region', '');
      }

      setLastCountry(values.country);
    })();
  }, [isValid, address]);

  const formatCountry = (x?: ISOCountry) => (x ? `${x.code} - ${x.name}` : '');

  return (
    <Grid item container direction="row" spacing={2}>
      <Grid item xs={6}>
        <Field
          name="country"
          component={AppTextField}
          select
          fullWidth
          options={allCountries.map((x) => ({
            value: x.code,
            label: formatCountry(x),
          }))}
          SelectProps={{
            displayEmpty: true,
            renderValue: (e: string) =>
              formatCountry(allCountries.find((x) => x.code === e)) ||
              'Country',
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="postalCode"
          component={AppTextField}
          placeholder="Postal code (optional)"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="address"
          component={AppTextField}
          placeholder="Street"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="place"
          component={AppTextField}
          placeholder="City"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name="region"
          component={AppTextField}
          select
          disabled={!values.country || regionsLoading}
          options={allRegions.map((x) => ({
            value: x.code,
            label: x.name,
          }))}
          fullWidth
          SelectProps={{
            displayEmpty: true,
            renderValue: (e: string) =>
              allRegions.find((x) => x.code === e)?.name || 'Region',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <LocationMap latitude={values.latitude} longitude={values.longitude} />
      </Grid>
    </Grid>
  );
};

export default CompanyLocationForm;
