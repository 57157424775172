import { Button } from '@mui/material';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { DatePickerProps, DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { Moment } from 'moment';

/**
 * DatePickerButton Props
 * @property {Date} value - The current date value.
 * @property onChange - This is a function that will be called when the user selects a date.
 */
type Props = {
  value?: Date;
  format?: string;
  onChange: (date: Date) => void;
  datePickerProps: Pick<
    DatePickerProps<Date, Moment>,
    'views' | 'disablePast' | 'disableFuture' | 'maxDate' | 'minDate'
  >;
};

/* Displays a button with selected date value that opens a calendar when clicked. */
const DatePickerButton = ({
  value,
  onChange,
  format: formatString = 'PP',
  datePickerProps,
  ...props
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...datePickerProps}
      open={open}
      value={value}
      onChange={(date) => {
        if (date) {
          onChange(date.toDate());
          setOpen(false);
        }
      }}
      renderInput={(dpProps) => (
        <Button
          {...props}
          ref={dpProps.inputRef}
          variant="outlined"
          endIcon={<CalendarToday />}
          onClick={() => setOpen(!open)}>
          {(value && formatString && format(value, formatString)) || null}
        </Button>
      )}
    />
  );
};

export default DatePickerButton;
