import { useQuery } from '@apollo/client';
import React, {
  createContext,
  FC,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { companySubsidiariesQuery } from '../graphql/company/companySubsidiaries';
import { AuthorizationContext } from './AuthorizationContext';
import { BaseCompany } from '../types/company/types';
import { generateAllCompaniesQuery } from '../graphql/company/company';
import ArrayUtils from '../utils/arrayUtils';

export type DivisionSelection = BaseCompany & {
  shouldIncludeChildren: boolean;
  key: string;
  level?: number;
};

export type DashboardContextType = {
  company: BaseCompany;
  division: string;
  divisionKey: string;
  divisions: DivisionSelection[];
  includeChildren: boolean;
  setDivisionFromKey(key: string): void;
  endDate: Date;
  setEndDate(dt: Date): void;
  isShowingAllCompanies: boolean;
  getKeyParts(key: string): string[];
};
const initialContext: DashboardContextType = {
  company: {
    id: ' ',
    policyId: '',
    name: ' ',
    parentCompanyId: ' ',
    parentCompanyName: ' ',
    createdDate: '',
    currentFiscalYearDateRange: {
      startDate: '',
      endDate: '',
    },
    fiscalYearDateRanges: [],
    targetMt: 0,
  },
  division: '',
  divisionKey: '',
  divisions: [],
  endDate: new Date(),
  setEndDate: () => null,
  includeChildren: false,
  setDivisionFromKey: () => null,
  isShowingAllCompanies: false,
  getKeyParts: () => [],
};
export const DashboardContext = createContext(initialContext);

export const ALL_COMPANIES = 'ALLCOMPANIES';

const CreateDashboardProvider = () => {
  const DashboardProvider: FC<{ companyOverride?: BaseCompany }> = ({
    companyOverride,
    children,
  }) => {
    const { company: authCompany, userHasAccess } =
      useContext(AuthorizationContext);

    const company = companyOverride || authCompany;

    const userCompId: string = company.id;
    const [division, setDivision] = useState('');
    const [includeChildren, setIncludeChildren] = useState(false);
    const [divisions, setDivisions] = useState<DivisionSelection[]>([]);
    const [divisionKey, setDivisionKey] = useState('');
    const [endDate, setEndDate] = useState(new Date());

    useEffect(() => {
      setDivision('');
    }, [authCompany]);

    const isShowingAllCompanies = userHasAccess(
      'ZeroMe.Dashboard.SelectAllCompanies',
      'VIEW',
    );

    useQuery(companySubsidiariesQuery, {
      variables: { parentCompanyId: userCompId },
      onCompleted(data) {
        const { parentCompanyId, parentCompanyName } = company;

        setDivisions([
          ...(userHasAccess('Api.AllowParentQueries', 'VIEW')
            ? [
                {
                  id: company.parentCompanyId || company.id,
                  name: 'All',
                  shouldIncludeChildren: true,
                  parentCompanyId,
                  parentCompanyName,
                  key: `${company.parentCompanyId || company.id}_ALL**`,
                },
              ]
            : []),
          {
            id: company.id,
            name: company.name,
            shouldIncludeChildren: false,
            parentCompanyId,
            parentCompanyName,
            key: `${company.id}_${company.name}`,
          },
          ...data.companySubsidiaries.map((item: BaseCompany) => ({
            ...item,
            key: `${item.id}_${item.name}`,
            shouldIncludeChildren: false,
          })),
        ]);
      },
      skip: !userCompId || isShowingAllCompanies,
    });

    useQuery(generateAllCompaniesQuery(), {
      onCompleted(data) {
        const { allCompanies = [] } = data;

        const sorted = ArrayUtils.groupAndSortHierarchy(
          allCompanies,
          'parentCompanyId',
        );
        const mapped = sorted
          .filter((c) => !c.isDemoCompany)
          .flatMap((c) => {
            if (allCompanies.some((o) => o.parentCompanyId === c.id)) {
              return [
                {
                  ...c,
                  shouldIncludeChildren: true,
                  key: `${c.id}_ALL**`,
                },
                {
                  ...c,
                  shouldIncludeChildren: false,
                  key: `${c.id}_${c.name}`,
                  name: `${c.name} (only)`,
                  level: (c.level || 0) + 1,
                },
              ];
            }
            return [
              {
                ...c,
                shouldIncludeChildren: false,
                key: `${c.id}_${c.name}`,
              },
            ];
          });
        setDivisions(mapped);
      },
      skip: !userCompId || !isShowingAllCompanies,
    });

    const getKeyParts = (key: string): string[] => key.split('_');

    const setDivisionFromKey = (key: string): void => {
      const [id, name = ''] = key.split('_');
      if (name === 'ALL**') {
        setIncludeChildren(true);
      } else {
        setIncludeChildren(false);
      }

      setDivision(id);
      setDivisionKey(key);
    };

    const value = useMemo(
      () => ({
        company,
        division,
        divisionKey,
        divisions,
        includeChildren,
        setDivisionFromKey,
        endDate,
        setEndDate,
        isShowingAllCompanies,
        getKeyParts,
      }),
      [
        company,
        division,
        divisions,
        includeChildren,
        setDivisionFromKey,
        divisionKey,
        endDate,
        setEndDate,
        isShowingAllCompanies,
      ],
    );

    return (
      <DashboardContext.Provider value={value}>
        {children}
      </DashboardContext.Provider>
    );
  };

  return DashboardProvider;
};

export default CreateDashboardProvider;
