import { FormControl, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ChangeEventHandler, ComponentProps } from 'react';

const useStyles = makeStyles({
  outline: {
    fontSize: 12,
  },
  root: {
    fontSize: 12,
    borderRadius: 8,
  },
  outer: {
    padding: 8,
  },
});

interface Props {
  id: string;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  value: string | number | string[] | number[] | undefined;
  className?: string;
  inputClass?: string;
  inputStyle?: React.CSSProperties;
  SelectProps?: ComponentProps<typeof TextField>['SelectProps'];
  testId?: string;
  disabled?: boolean;
}

const SelectMenu: React.FC<Props> = (props) => {
  const {
    id,
    value,
    onChange,
    children,
    SelectProps,
    className,
    inputClass,
    inputStyle,
    testId,
    disabled,
  } = props;
  const classes = useStyles(props);
  return (
    <FormControl variant="standard">
      <TextField
        data-testid={testId}
        key={id}
        id={id}
        disabled={disabled}
        value={value}
        className={className || classes.outer}
        size="small"
        variant="outlined"
        InputProps={{
          classes: {
            notchedOutline: classes.outline,
            root: inputClass || classes.root,
          },
          style: inputStyle,
        }}
        select
        onChange={onChange}
        SelectProps={SelectProps}>
        {children}
      </TextField>
    </FormControl>
  );
};
export default SelectMenu;
