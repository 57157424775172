import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../../contexts/ProjectContext';
import useMapboxHighlightCountry from '../../../../hooks/useMapboxHighlightCountry';
import { Project } from '../../../../types/project/types';
import CreateProjectInput from '../CreateProjectInput';
import createProjectSchema from '../createProjectSchema';
import ResetProjectFormListener from '../ResetProjectFormListener';
import ProjectContextFieldValidator from './ProjectContextFieldValidator';

const makeInitialValues = (project: Project) => ({
  country: project.country || '',
  postalCode: project.postalCode || '',
  address: project.address || '',
  place: project.place || '',
  region: project.region || '',
});

const CreateProjectLocation: React.FC = () => {
  const { allCountries, allRegions, regionsLoading, project } =
    useContext(ProjectContext);

  const mapNode = useMapboxHighlightCountry(project);

  return (
    <Formik
      initialValues={makeInitialValues(project)}
      // formik requires an onSubmit handler but we don't actually
      // do any submitting here - just using formik to handle validation
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      validationSchema={createProjectSchema.locationSchema}>
      {({ resetForm, setFieldValue }) => (
        <>
          <ProjectContextFieldValidator step={ProjectDetailsStep.location} />
          <ResetProjectFormListener
            resetForm={resetForm}
            makeInitialValues={makeInitialValues}
          />
          <Typography variant="h3">Location</Typography>
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CreateProjectInput
                  fieldType="Select"
                  name="country"
                  options={allCountries.map((x) => ({
                    value: x.code,
                    label: `${x.code} - ${x.name}`,
                  }))}
                  onChange={() =>
                    setTimeout(() => setFieldValue('region', ''), 10)
                  }
                  placeholder="Country"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  name="postalCode"
                  fieldType="Text"
                  placeholder="Postal Code"
                />
              </Grid>
              <Grid item xs={12}>
                <CreateProjectInput
                  fieldType="Text"
                  name="address"
                  placeholder="Street"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  fieldType="Text"
                  name="place"
                  placeholder="City"
                />
              </Grid>
              <Grid item xs={6}>
                <CreateProjectInput
                  fieldType="Select"
                  disabled={!project.country || regionsLoading}
                  name="region"
                  options={allRegions.map((x) => ({
                    value: x.code,
                    label: x.name,
                  }))}
                  placeholder="Region"
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  ref={mapNode}
                  style={{ width: '100%', height: 207, borderRadius: 8 }}
                />
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CreateProjectLocation;
