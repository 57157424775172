import React from 'react';
import { matchPath, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { Grid, useTheme } from '@mui/material';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberProfileAvatar from '../../../components/member/aboutme/MemberProfileAvatar';
import LeaderboardDetails from '../../../components/member/contest/LeaderboardDetails';
import { LeaderboardRollup } from '../../../types/contest/types';

type Params = {
  contestId: string;
  rollup?: LeaderboardRollup;
};

const MemberLeaderboard: React.FC = () => {
  const { contestId, rollup = LeaderboardRollup.PARENT } = useParams<Params>();
  const { pathname } = useLocation();
  const theme = useTheme();

  return (
    <MemberLayout
      styleOverrides={
        matchPath(pathname.toLowerCase(), '/member/challenge/detail')
          ? { backgroundColor: theme.palette.backgrounds.light }
          : undefined
      }>
      <Grid container spacing={6} justifyContent="center">
        <Grid item xs={12}>
          <MemberProfileAvatar />
        </Grid>
        <Grid item xs={12}>
          <LeaderboardDetails contestId={contestId} rollup={rollup} />
        </Grid>
      </Grid>
    </MemberLayout>
  );
};

export default MemberLeaderboard;
