import React from 'react';
import { Grid } from '@mui/material';
import MemberLayout from '../../../components/layouts/MemberLayout';
import AboutMe from '../../../components/member/aboutme/AboutMe';
import MemberProfileAvatar from '../../../components/member/aboutme/MemberProfileAvatar';

const MemberAboutMe: React.FC = () => (
  <MemberLayout>
    <Grid container spacing={6} justifyContent="center">
      <Grid item xs={12}>
        <MemberProfileAvatar />
      </Grid>
      <Grid item xs={12}>
        <AboutMe />
      </Grid>
    </Grid>
  </MemberLayout>
);

export default MemberAboutMe;
