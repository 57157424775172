import { Grid, Typography, Button, Divider } from '@mui/material';
import { Field, useFormikContext, getIn, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';

import AppTextField from '../common/AppTextField';
import { HabitListItem } from '../../types/habit/types';

const schema = yup.object().shape({
  habitId: yup.string().required('Habit is required'),
});

type Props = {
  habits: HabitListItem[];
  onSave: ({ habitId }: { habitId: StringConstructor }) => void;
  path: string;
};

const HabitConditionHabitSelectorModal: React.FC<Props> = ({
  onSave,
  path,
  habits,
}) => {
  const { values } = useFormikContext();

  const habitId = getIn(values, `${path}.habitId`);

  const initialValues = {
    habitId,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (v) => {
        onSave(v);
      }}
      validateOnMount>
      {(props) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">Habit</Typography>
            <Field
              label=""
              component={AppTextField}
              fullWidth
              select
              name="habitId"
              options={_.sortBy(habits, 'name').map((h) => ({
                label: h.name,
                value: h.id,
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-end">
              <Button
                disabled={props.isSubmitting || !props.isValid}
                variant="contained"
                onClick={props.submitForm}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

export default HabitConditionHabitSelectorModal;
