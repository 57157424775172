import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import QuestionnaireButton from './QuestionnaireButton';
import QuizResults from '../../member/quiz/QuizResults';
import {
  QuestionnaireActionTypes,
  useCleanupQuestionnaire,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';
import { QuestionAnswer } from '../../../types/question/types';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '40px 0px',
    alignItems: 'center',
    width: '60%',
  },
  closeButton: {
    marginTop: 16,
    width: '100%',
  },
  restartButton: {
    marginTop: 16,
    width: '100%',
    color: theme.palette.text.primary,
  },
}));

const QuizResult: React.FC = () => {
  const classes = useStyles();
  const state = useQuestionnaire();
  const dispatch = useQuestionnaireDispatch();
  const cleanupQuestionnaire = useCleanupQuestionnaire();

  const answers: QuestionAnswer[] = state.testing
    ? state.testingAnswers || []
    : state.userEmissionSources.flatMap((x) => x.answeredQuestions);

  const correctAnswers: QuestionAnswer[] = answers.filter((answer) => {
    const question = state.quiz?.questions?.find(
      (x) => x.questionId === answer.questionId,
    );
    return answer?.answerIds?.[0] === question?.correctAnswerId;
  });

  return (
    <Grid container justifyContent="center">
      <Grid container item direction="column" className={classes.container}>
        <QuizResults total={answers.length} correct={correctAnswers.length} />
        <Grid container item justifyContent="space-between" spacing={1}>
          <Grid item xs={6}>
            <QuestionnaireButton
              className={classes.closeButton}
              variant="contained"
              onClick={async () => {
                if (
                  state.carouselIndex === state.profileCards.length - 1 &&
                  state.cleanup
                ) {
                  await cleanupQuestionnaire(state.cleanup);
                } else {
                  dispatch({ type: QuestionnaireActionTypes.NEXT });
                }
              }}>
              {t('close')}
            </QuestionnaireButton>
          </Grid>
          <Grid item xs={6}>
            <QuestionnaireButton
              className={classes.restartButton}
              variant="outlined"
              color="inherit"
              onClick={async () =>
                dispatch({ type: QuestionnaireActionTypes.RESTART })
              }>
              {t('quiz.restartQuiz')}
            </QuestionnaireButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuizResult;
