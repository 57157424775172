import { Button, Menu } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { ComponentProps } from 'react';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
  },
}));

type Props = {
  textPrefix?: string;
  onChange?: (
    event: React.ChangeEvent<{ name?: string; value?: unknown }>,
  ) => void;
  onClick?: (
    event: React.ChangeEvent<{ name?: string; value?: unknown }>,
  ) => void;
  value?: string | number;
  error?: boolean;
  children: React.ReactNode;
};

const SelectButton = React.forwardRef<
  HTMLButtonElement,
  Omit<ComponentProps<typeof Button>, keyof Props> & Props
>(
  (
    { textPrefix, value: initialValue, children, error, ...props },
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const classes = useStyles();
    const localRef = React.useRef<HTMLButtonElement>(null);
    const anchorRef = (ref as React.RefObject<HTMLButtonElement>) || localRef;
    const [isOpen, setOpen] = React.useState(false);
    const valueRef = React.useRef(initialValue);
    valueRef.current = initialValue;

    const handleItemClick =
      (value: Props['value']) =>
      (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        setOpen(false);
        Object.defineProperty(e, 'target', {
          writable: true,
          value: { value },
        });
        valueRef.current = value;
        props.onChange?.(e);
      };

    const items = React.Children.map(
      children as React.ReactElement,
      (child) => {
        if (!child) {
          return null;
        }
        const selected = valueRef.current === child.props.value;
        const valueReadable = child.props.children;
        const item = React.cloneElement(child, {
          'aria-selected': selected ? 'true' : undefined,
          onClick: handleItemClick(child.props.value),
          role: 'option',
          selected,
          value: undefined, // The value is most likely not a valid HTML attribute.
          'data-value': child.props.value, // Instead, we provide it as a data attribute.
          'data-value-readable': valueReadable,
        });
        return item;
      },
    )?.filter((item) => item !== null);

    const displayName = (value?: Props['value']) =>
      items?.find((item) => item.props['data-value'] === value)?.props[
        'data-value-readable'
      ] || '';

    return (
      <>
        <Button
          {...props}
          onClick={() => setOpen(true)}
          ref={anchorRef}
          className={[props.className, error ? classes.error : ''].join(' ')}
          endIcon={<ArrowDropDownIcon />}>
          {textPrefix}
          {displayName(valueRef.current)}
        </Button>
        <Menu
          open={isOpen}
          onClose={() => setOpen(false)}
          anchorEl={anchorRef.current}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          {items}
        </Menu>
      </>
    );
  },
);

SelectButton.displayName = 'SelectButton';

export default SelectButton;
