import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import moment from 'moment';

import { UserActivityListItemProps } from './UserActivityListItem';
import UserActivityRenderer from './UserActivityRenderer';
import SVG from '../../../assets/svg-v2';
import { GET_CONTEST } from '../../../graphql/contest/contest';
import { Colors } from '../../../theme/shared';
import { ContestType } from '../../../types/contest/types';
import { UserActivityType } from '../../../types/user/activityTypes';

const UserActivityContestListItem = React.memo(
  ({
    userActivity,
  }: UserActivityListItemProps<
    UserActivityType.CONTEST_CLOSED | UserActivityType.CONTEST_OPENED
  >): JSX.Element | null => {
    const history = useHistory();
    const {
      data: { contestId },
      message,
    } = userActivity;

    const { data: { findContest: contest } = {} } = useQuery(GET_CONTEST, {
      variables: {
        contestId,
      },
      skip: !contestId,
    });

    const onClick = () =>
      history.push(
        contest?.contestType === ContestType.AUTOMATIC_QUARTERLY
          ? `/member/leaderboard`
          : `/member/challenge/${contestId}`,
      );

    return (
      <UserActivityRenderer
        onClick={onClick}
        leftComponent={<SVG.ChallengeActivity />}
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">{message}</Typography>
            </Grid>
            <Grid item container flexDirection="row" alignItems="center">
              <Typography variant="body2" color={Colors.TertiaryText}>
                {`${t('contest.challengeRuns')} ${moment(
                  contest?.duration?.startDate,
                ).format('MM/DD/YYYY')} - ${moment(
                  contest?.duration?.endDate,
                ).format('MM/DD/YYYY')} `}
              </Typography>
            </Grid>
          </Grid>
        }
      />
    );
  },
);

export default UserActivityContestListItem;
