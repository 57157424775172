import { createTheme } from '@mui/material/styles';
import shared from './shared';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1030,
      xl: 1030,
    },
  },
  ...shared,
});

export const FullPageWidth = theme.breakpoints.values.lg - 32 * 2;
export const HalfPageWidth = FullPageWidth / 2 - 20;

export default theme;
