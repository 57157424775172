import { gql, TypedDocumentNode } from '@apollo/client';
import { UserHasAccessFn } from '../../contexts/AuthorizationContext';
import {
  Project,
  ProjectAllotmentMetrics,
  ProjectCategory,
  UserAllotmentMetrics,
} from '../../types/project/types';
import { ProjectPurchaseHistory } from '../../types/offsetProject/types';
import {
  COMPANY_LOGO_URIS,
  PROJECT_ADDITIONAL_SCALED_IMAGE_URIS,
  PROJECT_HEADER_IMAGE_URIS,
  USER_PROFILE_IMAGE_URIS,
} from '../shared';
import { Company } from '../../types/company/types';

export const SEARCH_PROJECTS = gql`
  query ($filter: ProjectSearchFilterProps, $continuationToken: String) {
    searchProjects(
      filter: $filter
      pageSize: 100
      language: "en"
      continuationToken: $continuationToken
    ) {
      continuationToken
      items {
        id
        projectId
        title
        uom
        category
        companies
        individualScope
        createdDate
        source
        vintage
        indicator
        costPerUnit
        country
        auditor
        projectHQ
        uom

        startDate
        endDate

        verAuditReportDate
        ccbSdgAuditor
        ccbSdgReportDate
        sdVista

        isFeatured
        completedSteps
        isActive
        isDemoProject
        isIndicative
        availableQuantity
        remainingQuantity
      }
    }
  }
`;

export const SEARCH_PROJECTS_PROPERTIES = gql`
  query (
    $filter: ProjectSearchFilterProps
    $property: String
    $searchText: String
  ) {
    properties: marketplaceProjectsProperties(
      filter: $filter
      property: $property
      searchText: $searchText
      language: "en"
    )
  }
`;

export const OFFSET_PROJECTS_FIELDS = (includeInventoryHistory?: boolean) => `
  id
  projectId
  projectCategoryId
  purchaseType
  requestedDate
  completedDate
  companyId
  userId
  price
  quantity
  allocated
  remaining
  status
  serialNumber
  sysProjectId
  inventory {
    projectInventoryHistoryId
    quantity
  }
  approvedByUser {
    id
    name
    email
  }
  approvalDate
  invoicedAmount
  certificateUrl
  exportedToCompassDate
  project {
    projectId
    title
    source
    indicator
    uom
    ${
      includeInventoryHistory
        ? `
    # Loading this when not needed can cause the graphql cache to get in infinite loops
    inventoryHistory {
      pricePerUnit
      id
    }`
        : ''
    }
  }
  updateNotes {
    date
    notes
  }
  user {
    id
    name
    ${USER_PROFILE_IMAGE_URIS}
  }
  requestedByUser {
    id
    name
    email
  }
  company {
    name
  }
`;

export const OFFSET_PROJECTS_FIELDS_COMPANY = (includeUserData?: boolean) => `
  id
  projectId
  projectCategoryId
  requestedDate
  completedDate
  serialNumber
  companyId
  userId
  price
  quantity
  allocated
  remaining
  status
  uom
  sysProjectId
  invoicedAmount
  certificateUrl
  ${
    includeUserData
      ? `approvedByUser {
    id
    name
    email
  }`
      : ''
  }
  approvalDate
  exportedToCompassDate
  updateNotes {
    date
    notes
  }
  project {
    projectId
    title
    purchasePrice
    uom
  }
`;

export const GET_MARKETPLACE_PURCHASEHIST = (
  includeInventoryHistory?: boolean,
) => gql`
  query ($filter: OffsetProjectSearchFilterProps) {
    offsetProjects(filter: $filter, language: "en") {
      ${OFFSET_PROJECTS_FIELDS(includeInventoryHistory)}
    }
  }
`;

export const GET_COMPANY_PURCHASE_HISTORY = (includeUserData?: boolean) =>
  gql`
  query offsetProjectsByCompany ($filter: OffsetProjectSearchFilterProps, $companyId: String!) {
    offsetProjectsByCompany(filter: $filter, language: "en", companyId: $companyId) {
      items {
        ${OFFSET_PROJECTS_FIELDS_COMPANY(includeUserData)}
      }
    }
  }
` as TypedDocumentNode<{
    offsetProjectsByCompany: {
      items: ProjectPurchaseHistory[];
    };
  }>;

/**
 * Get the certificate (PDF) URL.
 */
export const GET_PROJECT_PURCHASE_CERTIFICATE_DATA = gql`
  query ($id: String!) {
    projectPurchase(id: $id) {
      id
      quantity
      serialNumber
      completedDate
      project {
        id
        projectId
        source
        title
        description
        ${PROJECT_HEADER_IMAGE_URIS}
        categoryData {
          id
          color
          iconUri
        }
      }
      company {
        id
        name
        ${COMPANY_LOGO_URIS}
      }
    }
  }
` as TypedDocumentNode<{
  projectPurchase: {
    id: string;
    quantity: number;
    serialNumber: string;
    completedDate: string;
    project: Project & {
      categoryData: {
        id: string;
        color: string;
        iconUri: string;
      };
    };
    company: Company;
  };
}>;

export const GET_COMPANY_PROJECTS = gql`
  query ($categoryId: String, $companyId: String!) {
    projectIndicators(language: "en") {
      id
      name
    }
    companyProjects(
      companyId: $companyId
      pageSize: 100
      language: "en"
      categoryId: $categoryId
    ) {
      items {
        id
        projectId
        category
        country
        region
        title
        startDate
        endDate
        smallSummary
        description
        projectHQ
        sellingPrice
        isFeatured
        sponsor
        uom
        indicator
        availableQuantity
        remainingQuantity
        isFeatured
        isDraft
        ${PROJECT_HEADER_IMAGE_URIS}
        createdDate
        isActive
        isIndicative
      }
    }
  }
` as TypedDocumentNode<{
  projectIndicators: { id: string; name: string }[];
  companyProjects: {
    items: Project[];
  };
}>;

export const ALLOTMENT_METRICS = gql`
  query ($companyId: String!) {
    me {
      id
      allotmentMetrics {
        allotted
        redeemed
        available
        allotmentIds
        totalRedemptions
      }
    }
    projectAllotmentMetrics(companyId: $companyId) {
      projectId
      allotted
      redeemed
      available
    }
  }
` as TypedDocumentNode<{
  me: {
    allotmentMetrics: UserAllotmentMetrics;
  };
  projectAllotmentMetrics: ProjectAllotmentMetrics[];
}>;

export const COMPANY_USER_ALLOTMENT_METRICS = gql`
  query ($companyId: String!, $includeChildren: Boolean) {
    companyUserAllotmentMetrics(
      companyId: $companyId
      includeChildren: $includeChildren
    ) {
      allotted
      redeemed
      available
      allotmentIds
      totalRedemptions
    }
  }
` as TypedDocumentNode<{
  companyUserAllotmentMetrics: UserAllotmentMetrics;
}>;

export const PROJECT_VIEWS = gql`
  query {
    searchProjects(
      filter: {
        draft: ["false"]
        individualScope: ["false"]
        isDemoProject: ["false"]
      }
      pageSize: 1000
      language: "en"
    ) {
      items {
        id
        title
        views
        categoryData {
          id
          color
        }
      }
    }
  }
` as TypedDocumentNode<{
  searchProjects: {
    items: {
      id: string;
      title: string;
      views: Record<string, number>;
      categoryData: ProjectCategory;
    }[];
  };
}>;

export const generateProjectFields = (addTraderFields: boolean) => `
  id
  projectId
  category
  country
  region
  title
  smallSummary
  description
  projectHQ
  postalCode
  address
  place
  startDate
  endDate
  purchasePrice
  costPerUnit
  source
  sponsor
  projectHQ
  vintage
  indicator
  sellingPrice
  uom
  isFeatured
  isDraft
  isIndicative
  ${PROJECT_HEADER_IMAGE_URIS}
  additionalImageUris
  ${PROJECT_ADDITIONAL_SCALED_IMAGE_URIS}
  documentUris
  costPerUnit
  auditor
  sdVista
  verAuditReportDate
  ccbSdgAuditor
  ccbSdgReportDate
  visitedSteps
  completedSteps
  individualScope
  companies
  remainingQuantity
  ${
    addTraderFields
      ? `
  availableQuantity
  sellingPriceHistory {
    price
    date
  }
  inventoryHistory {
    id
    purchasedDate
    totalPurchaseAmount
    remainingQuantity
    pricePerUnit
    quantity
    notes
  }
  totalRevenue {
    mt
    revenue
  }
  isActive
  isDemoProject
  allocationTotals {
    pendingMT
    pending
  }
  `
      : ''
  }
`;

export const generateProjectByIdQuery = (userHasAccessFn: UserHasAccessFn) =>
  gql`
  query ($projectId: String!) {
    project(projectId: $projectId) {
      ${generateProjectFields(userHasAccessFn('ZeroMe.Marketplace', 'VIEW'))}
    }
  }
` as TypedDocumentNode<
    {
      project: Project;
    },
    {
      projectId: string;
    }
  >;

export const MEMBER_PROJECT_DETAILS = gql`
query ($projectId: String!) {
  project(projectId: $projectId) {
    id
  ${PROJECT_HEADER_IMAGE_URIS}
    title
    description
    smallSummary
    category
    startDate
    projectId
    country
  }
}
` as TypedDocumentNode<{
  project: Pick<
    Project,
    | 'id'
    | 'headerImageUris'
    | 'headerImagePosition'
    | 'title'
    | 'category'
    | 'description'
    | 'smallSummary'
    | 'startDate'
    | 'projectId'
    | 'uom'
    | 'sellingPrice'
    | 'remainingQuantity'
    | 'country'
  >;
}>;
