import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import { VictoryPie } from 'victory';

import theme from '../../../theme/memberTheme';

const useStyles = makeStyles(() => ({
  chart: {
    marginTop: 16,
    marginBottom: 16,
    position: 'relative',
  },
  chartText: {
    position: 'absolute',
    top: 0,
    left: 0,
    textAlign: 'center',
    width: 184,
    lineHeight: '184px',
    color: theme.palette.primary.main,
  },
}));

type Props = {
  total: number;
  correct: number;
};

const QuizResults: React.FC<Props> = ({ total, correct }) => {
  const classes = useStyles();

  // If the total is greater than 100, we want to round down to avoid showing 100% correct
  // incase the user actually got 99.5% - 99.9% correct.
  const accuracy =
    total > 100
      ? Math.floor((correct / total) * 100)
      : Math.round((correct / total) * 100);

  return (
    <Grid container direction="column" alignItems="center">
      <Typography variant="h3">Quiz Results</Typography>
      <Grid item className={classes.chart}>
        <VictoryPie
          height={176}
          width={176}
          padding={0}
          innerRadius={75}
          labels={[]}
          colorScale={[
            theme.palette.primary.main,
            theme.palette.secondary.main,
          ]}
          data={[{ y: correct }, { y: total - correct }]}
        />
        <Typography className={classes.chartText} variant="h1">
          {correct}/{total}
        </Typography>
      </Grid>
      <Typography variant="body1">
        {t('quiz.accuracy', { accuracy })}
      </Typography>
    </Grid>
  );
};

export default QuizResults;
