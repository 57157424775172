import React, { useState } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';

import { InvestmentsGraphCoordinate } from '../../../../types/dashboard/types';
import AppCard from '../../../common/AppCard/AppCard';
import CardFooter from '../../../common/CardFooter/CardFooter';
import CategoryDataBarChart from '../../../common/CategoryDataBarChart/CategoryDataBarChart';
import MissingInvestmentData from './MissingInvestmentData';
import NumberUtils from '../../../../utils/numberUtils';

type Props = {
  investments: {
    id: string;
    label: string;
    color?: string | undefined;
    data: Array<{
      id: string;
      label: string;
      color?: string;
      data: Array<InvestmentsGraphCoordinate>;
    }>;
  };
  spent: number;
  context?: string; // What is the context (e.g. caller) of this component? This decides if the 'showViewProjectsButton' flag is set to true or false.
};

const TotalInvestments: React.FC<Props> = ({ investments, spent, context }) => {
  const [value, setValue] = useState<'value' | 'mt' | 'mwh'>('value');

  const getInvestmentData = () => ({
    ...investments,
    data: investments.data.map((c) => ({
      ...c,
      data: c.data.map((d) => ({
        date: d.date,
        value: d[value] as number,
      })),
    })),
  });

  let invested;
  switch (value) {
    case 'mt':
      invested = `${NumberUtils.format(
        investments.data
          .map((d) => d.data)
          .flat()
          .reduce((sum, d) => sum + (d.mt || 0), 0),
        'integer',
      )} MT`;
      break;
    case 'mwh':
      invested = `${NumberUtils.format(
        investments.data
          .map((d) => d.data)
          .flat()
          .reduce((sum, d) => sum + (d.mwh || 0), 0),
        'integer',
      )} MWh`;
      break;
    default:
      invested = NumberUtils.format(spent, 'currency');
      break;
  }

  const data = investments.data.map((i) => i.data).flat();
  const isEmpty = data.reduce((sum, e) => sum + e.value, 0) === 0;

  // What is the context (e.g. caller) of this component? This decides if the 'showViewProjectsButton' flag is set to true or false.
  const showViewProjectsButton = isEmpty && context === 'offset-opportunities';

  // What is the context (e.g. caller) of this component? This decides which 'empty' message is shown.
  const emptyMessage =
    isEmpty && context === 'client-details'
      ? `This client doesn't have any investments.`
      : undefined;

  return (
    <AppCard
      headerProps={{
        title: 'TOTAL INVESTMENTS',
        rightContent: (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {!isEmpty ? (
              <FormControl variant="outlined">
                <SelectMenu
                  id="demo-simple-select"
                  value={value}
                  onChange={(e) =>
                    setValue(e.target.value as 'value' | 'mt' | 'mwh')
                  }>
                  <MenuItem key="value" value="value">
                    $ USD
                  </MenuItem>
                  <MenuItem key="mt" value="mt">
                    VCC
                  </MenuItem>
                  <MenuItem key="mwh" value="mwh">
                    REC
                  </MenuItem>
                </SelectMenu>
              </FormControl>
            ) : null}
          </>
        ),
        tooltipId: 'totalInvestments',
      }}>
      {isEmpty ? (
        <MissingInvestmentData
          showViewProjectsButton={showViewProjectsButton}
          emptyMessage={emptyMessage}
        />
      ) : (
        <>
          <CategoryDataBarChart
            categoryData={getInvestmentData()}
            format={value === 'value' ? 'currency' : 'number'}
          />

          <CardFooter data={invested} label="Invested" />
        </>
      )}
    </AppCard>
  );
};

export default TotalInvestments;
