/* eslint-disable import/prefer-default-export */
import React from 'react';
import { AppRichTextFieldProps } from './types';
import QuillRichTextField from './AppRichTextField';
import { EmailRichTextField } from './EmailRichTextField';

export const AppRichTextField = (props: AppRichTextFieldProps) => {
  const { isEmailEditor } = props;
  return isEmailEditor ? (
    <EmailRichTextField {...props} />
  ) : (
    <QuillRichTextField {...props} />
  );
};
