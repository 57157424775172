import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { useMutation, useQuery } from '@apollo/client';
import SettingsPanel from './SettingsPanel';
import LoadingBackdrop from '../common/LoadingBackdrop';
import {
  MutationUser,
  User,
  UserMigrationCompanyAndPolicy,
} from '../../types/user/types';
import SettingsModalInput from './SettingsModalInput';
import AppTextField from '../common/AppTextField';
import SettingsFieldRow from './SettingsFieldRow';
import CompanyNameWithLogo from '../common/Company/CompanyNameWithLogo';
import RoleSelectionModal from './RoleSelectionModal';
import RoleChip from '../common/User/RoleChip';
import {
  RESEND_WELCOME_EMAIL,
  UPSERT_USER_MUTATION,
} from '../../graphql/user/userMutations';
import UserImage from '../common/User/UserImage';
import {
  GET_ALL_COUNTRIES,
  GET_GLOBAL_AUTHENTICATION_POLICIES,
} from '../../graphql/settings';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import { ISOCountry } from '../../types/common';
import { validPostalCodeTest } from '../../utils/yupTests';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { getUserStatus, UserStatus } from '../../utils/userUtils';
import { ACTIVE_USER_COUNT } from '../../graphql/user/user';
import {
  GET_COMPANY_CONTACTS,
  GET_COMPANY_TYPE,
  generateCompanyQuery,
} from '../../graphql/company/company';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import ConfirmCancelModal from '../common/ConfirmCancelModal/ConfirmCancelModal';
import UserDataRequestRow from './UserDataRequestRow';
import { Company } from '../../types/company/types';
import { companySubsidiariesQuery } from '../../graphql/company/companySubsidiaries';
import { DashboardContext } from '../../contexts/DashboardContext';
import { ZeroMeTM } from '../../utils/stringConstants';

const useStyles = makeStyles((theme) => ({
  section: {
    marginTop: 32,
    marginBottom: 48,
  },
  error: {
    color: theme.palette.error.main,
  },
  subheading: {
    marginBottom: 16,
  },
}));

const NAME_VALIDATION_SCHEMA = {
  firstName: yup.string().required('Required'),
  lastName: yup.string().required('Required'),
};

const EMAIL_VALIDATION_SCHEMA = {
  email: yup
    .string()
    .required('Required')
    .email('Please enter a valid email address')
    .test('emaillower', 'Email address must be lower case', (value) => {
      if (!value) {
        return true;
      }
      return value === value.toLocaleLowerCase();
    }),
};

const POSTAL_CODE_VALIDATION_SCHEMA = {
  postalCode: yup.string().required('Required').test(validPostalCodeTest()),
  country: yup.string().required('Required'),
};

const COMPANY_VALIDATION_SCHEMA = {
  companyId: yup.string().required('Company is required.'),
};

const COMPANY_POLICY_VALIDATION_SCHEMA = {
  companyPolicyId: yup.string().required('Policy is required.'),
};

type Props = {
  user?: User;
  companyId: string;
  open: boolean;
  onClose: (refetch: boolean) => void;
};

// List item for binding
type BindableListItem = {
  value: string;
  label: JSX.Element;
  name: string;
};

const EditEmployeePanel: React.FC<Props> = ({
  user,
  companyId,
  open,
  onClose,
}) => {
  const {
    userHasAccess,
    user: me,
    refetchMe,
  } = useContext(AuthorizationContext);

  // Temporary check used so the platform_admin can set the user's company and policy id
  const isPlatformAdmin = me?.roles.includes('platform_admin') || false;

  // Get the divisions so that we can get the indentation level
  const { divisions } = useContext(DashboardContext);

  const [refreshOnClose, setRefreshOnClose] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [confirmResendWelcomeEmail, setConfirmResendWelcomeEmail] =
    useState(false);
  const [welcomeEmailResent, setWelcomeEmailResent] = useState(false);
  const [title, setTitle] = useState('');
  const classes = useStyles();

  const { loading: companyLoading, data: { company } = {} } = useQuery<{
    company: Company;
  }>(generateCompanyQuery(userHasAccess), {
    variables: { id: companyId },
  });

  // Modal content to display to the user if they open the Company modal for an existing employee
  const modalCompanyContent = user
    ? 'Heads up, updating the company/division for an individual will transfer all personal emissions to the updated company/division.'
    : undefined;

  // For form element binding
  let currentCompanyId: string | undefined = user?.companyId || company?.id;
  let currentPolicyId: string | undefined =
    user?.companyPolicyId || company?.policyId;
  let currentPolicyName: string | undefined;

  // Company id lookups to avoid adding duplicates
  const lookupCompanies: string[] = [];

  // Policy id lookups to avoid adding duplicates
  const lookupPolicies: string[] = [];

  // Bindable companies list
  const listCompanies: BindableListItem[] = [];

  // Bindable policies list
  const listPolicies: BindableListItem[] = [];

  // Get global authentication policies
  const { data: { globalAuthenticationPolicies = [] } = {} } = useQuery(
    GET_GLOBAL_AUTHENTICATION_POLICIES,
    {
      fetchPolicy: 'network-only',
    },
  );

  // Add a listitem to the specified company list (for data binding)
  const addCompanyToList = (comp: Company, list: BindableListItem[]) => {
    // Add any parent
    if (comp.parentCompanyId) {
      const parentCompany = divisions.find(
        (f) => f.id === comp.parentCompanyId,
      );
      if (
        parentCompany &&
        !lookupCompanies.find((f) => f === parentCompany.id)
      ) {
        lookupCompanies.push(parentCompany.id);

        // Get the division for the indentation
        const indent = parentCompany.level || 0;

        list.push({
          value: parentCompany.id,
          label: (
            <CompanyNameWithLogo company={parentCompany} indent={indent} />
          ),
          name: parentCompany.name,
        });
      }
    }

    // Keep a master list of companies to avoid adding duplicates
    if (!lookupCompanies.find((f) => f === comp.id)) {
      lookupCompanies.push(comp.id);

      // Get the division for the indentation
      const division = divisions.find((f) => f.id === comp.id);
      let indent = 0;
      if (division) {
        indent = division.level || 1;
      }

      list.push({
        value: comp.id,
        label: <CompanyNameWithLogo company={comp} indent={indent} />,
        name: comp.name,
      });
    }
  };

  // Add a listitem to the specified policy list (for data binding)
  const addCompanyPolicyToList = (comp: Company, list: BindableListItem[]) => {
    // Add the company's parent
    if (comp.parentCompanyId) {
      const parentCompany = divisions.find(
        (f) => f.id === comp.parentCompanyId,
      );

      if (parentCompany) {
        let parentPolicyId = parentCompany.policyId;

        if (parentPolicyId) {
          parentPolicyId = parentPolicyId.trim().toLowerCase();

          if (!lookupPolicies.find((f) => f === parentPolicyId)) {
            lookupPolicies.push(parentPolicyId);

            list.push({
              value: parentPolicyId,
              label: (
                <CompanyNameWithLogo
                  textInsteadOfCompanyName={parentPolicyId}
                />
              ),
              name: parentPolicyId,
            });
          }
        }
      }
    }

    // Add the company
    let { policyId } = comp;
    if (policyId?.length > 0) {
      policyId = policyId.trim().toLowerCase();

      // Keep a master list of policies to avoid adding duplicates
      if (!lookupPolicies.find((f) => f === policyId)) {
        lookupPolicies.push(policyId);

        list.push({
          value: policyId,
          label: <CompanyNameWithLogo textInsteadOfCompanyName={policyId} />,
          name: policyId,
        });
      }
    }
  };

  // Set the company's state vars
  const setCompany = (compId: string) => {
    currentCompanyId = compId;
  };

  // Set the policy's state vars
  const setPolicy = (polId: string | undefined = undefined) => {
    currentPolicyId = polId?.toLowerCase();

    // If a policy id was specified and we have policy id's in the lookups
    if (currentPolicyId) {
      const selectedPolicy = listPolicies.find(
        (f) => f.value?.toLowerCase() === currentPolicyId,
      );

      if (selectedPolicy) {
        // setCurrentPolicyName(selectedPolicy.name);
        currentPolicyName = selectedPolicy.name;
      } else {
        // Policy id not found. Perhaps the user has a different one. Add it to the list.
        lookupPolicies.push(currentPolicyId);

        listPolicies.push({
          value: currentPolicyId,
          label: (
            <CompanyNameWithLogo textInsteadOfCompanyName={currentPolicyId} />
          ),
          name: currentPolicyId,
        });
        currentPolicyName = currentPolicyId;
      }
    } else {
      currentPolicyName = undefined;
    }
  };

  // Get all child companies
  const { data: { companySubsidiaries = [] } = {} } = useQuery<{
    companySubsidiaries: Company[];
  }>(companySubsidiariesQuery, {
    variables: {
      parentCompanyId: companyId,
    },
  });

  // Populate the bindable companies list
  if (company) {
    addCompanyToList(company, listCompanies);
    addCompanyPolicyToList(company, listPolicies);
    if (companySubsidiaries) {
      companySubsidiaries.forEach((comp) => {
        addCompanyToList(comp, listCompanies);
        addCompanyPolicyToList(comp, listPolicies);
      });
    }
  }

  // Add any defined global policies
  globalAuthenticationPolicies.forEach((c) => {
    listPolicies.push({
      value: c.value,
      label: company ? (
        <CompanyNameWithLogo textInsteadOfCompanyName={c.name} />
      ) : (
        <span>{c.name}</span>
      ),
      name: c.name,
    });
  });

  // Set the policy id and name
  setPolicy(currentPolicyId);

  const [
    upsertUserMutation,
    { loading: upsertingUser, error: upsertError, reset },
  ] = useMutation(UPSERT_USER_MUTATION);

  const [
    resendWelcomeEmailMutation,
    { loading: resendingEmail, error: resendError },
  ] = useMutation(RESEND_WELCOME_EMAIL);

  const { loading: countriesLoading, data: { allCountries = [] } = {} } =
    useQuery<{ allCountries: ISOCountry[] }>(GET_ALL_COUNTRIES);

  const close = (forceRefresh?: boolean) => {
    currentCompanyId = undefined;
    setPolicy(); // Clear the policy id and name

    reset();
    setWelcomeEmailResent(false);
    onClose(forceRefresh || refreshOnClose);
  };

  const { loading: loadingCount, data: { activeUserCount = 0 } = {} } =
    useQuery(ACTIVE_USER_COUNT, {
      skip: !companyId,
      variables: {
        companyId,
      },
      fetchPolicy: 'network-only',
    });

  const {
    data: { company: { contacts: companyContacts = [] } = {} } = {},
    loading: loadingContacts,
  } = useQuery(GET_COMPANY_CONTACTS, {
    skip: !companyId,
    variables: { id: companyId },
  });

  const { loading: companyTypeLoading, data: { companyType } = {} } = useQuery(
    GET_COMPANY_TYPE,
    {
      skip: !companyId,
      variables: {
        companyId,
      },
    },
  );

  const assignedAsContact =
    user &&
    companyContacts.filter(
      (cc) => cc.users.findIndex(({ id }) => user.id === id) > -1,
    );

  const contactTypeDisplay = assignedAsContact?.map(
    ({ type }) =>
      (type === 'ADMIN' && `${ZeroMeTM} Admin`) ||
      (type === 'HR' && 'HR') ||
      (type === 'SUSTAINABILITY' && 'Sustainability'),
  );

  useEffect(() => {
    setTitle(user?.name || 'Add New Member');
  }, [user]);

  const error = upsertError || resendError;

  return (
    <DataStateHandler
      loading={
        companyLoading ||
        countriesLoading ||
        loadingCount ||
        loadingContacts ||
        companyTypeLoading
      }>
      <>
        <LoadingBackdrop open={upsertingUser || resendingEmail} />
        <SettingsPanel<MutationUser>
          forceOpen={open}
          onClose={close}
          title={title}
          hideSaveButton={false}
          dontCloseOnSave={false}
          formikProps={{
            initialValues: {
              firstName: user?.firstName || '',
              lastName: user?.lastName || '',
              email: user?.email || '',
              roles: user?.roles || ['member'],
              postalCode: user?.postalCode || '',
              country: user?.country || '',
              isActive: user?.isActive ?? true,
              isEnrolling: user?.isEnrolling ?? activeUserCount === 0,
              companyId: user?.companyId || company?.id || '',
              companyPolicyId: (
                user?.companyPolicyId ||
                company?.policyId ||
                ''
              )?.toLowerCase(),
              userTypeId: user?.userTypeId || '',
            },
            validationSchema: yup.object().shape({
              ...NAME_VALIDATION_SCHEMA,
              ...EMAIL_VALIDATION_SCHEMA,
              ...POSTAL_CODE_VALIDATION_SCHEMA,
              ...COMPANY_VALIDATION_SCHEMA,
              ...COMPANY_POLICY_VALIDATION_SCHEMA,
            }),
            onSubmit: async (values) => {
              // Will this user be migrated to another company?
              let migrationDetails: UserMigrationCompanyAndPolicy | undefined;

              if (user && user.companyId !== values.companyId) {
                // Company was changed for existing user. Populate the migration details.
                migrationDetails = {
                  existingCompanyId: user.companyId,
                  existingCompanyPolicyId: user.companyPolicyId.toLowerCase(),
                  newCompanyId: values.companyId,
                  newCompanyPolicyId: values.companyPolicyId.toLowerCase(),
                };
              }

              await upsertUserMutation({
                variables: {
                  user: values,
                  companyId: user?.companyId || companyId,
                  userId: user?.id,
                  existingUserMigration: migrationDetails,
                },
              });

              close(true);
            },
          }}>
          {({ values, setValues }) => {
            const onSave = async (data: Partial<MutationUser>) => {
              setValues({ ...values, ...data });

              if (user) {
                if (user.id === me?.id) {
                  refetchMe();
                }
              }
            };

            return (
              <>
                {error && (
                  <Typography variant="h3" className={classes.error}>
                    {error.graphQLErrors[0]?.extensions?.code ||
                      'Validation Error'}
                  </Typography>
                )}
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  className={classes.section}>
                  <UserImage
                    user={
                      {
                        ...(user || {}),
                        firstName: values.firstName,
                      } as User
                    }
                  />
                </Grid>
                <Grid item>
                  <SettingsModalInput<MutationUser>
                    label="Full Name"
                    modalLabel="Edit Name"
                    disabled={!userHasAccess('Client.Employees', 'EDIT')}
                    validationSchema={yup.object().shape({
                      ...NAME_VALIDATION_SCHEMA,
                    })}
                    formNode={
                      <Grid item container direction="row" spacing={2}>
                        <Grid item xs>
                          <Field
                            name="firstName"
                            placeholder="First Name"
                            component={AppTextField}
                            fullWidth
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="lastName"
                            placeholder="Last Name"
                            component={AppTextField}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    }
                    displayValue={`${values.firstName} ${values.lastName}`}
                    initialValues={{
                      firstName: values.firstName,
                      lastName: values.lastName,
                    }}
                    onSave={onSave}
                  />
                  <SettingsModalInput<MutationUser>
                    label="Email"
                    modalLabel="Edit Email"
                    disabled={!userHasAccess('Client.Employees', 'EDIT')}
                    validationSchema={yup.object().shape({
                      ...EMAIL_VALIDATION_SCHEMA,
                    })}
                    displayValue={values.email}
                    initialValues={{ email: values.email }}
                    onSave={onSave}
                  />
                  {isPlatformAdmin && (
                    <SettingsModalInput<MutationUser>
                      label="Organization"
                      modalLabel="Choose an organization"
                      modalContent={modalCompanyContent}
                      disabled={!userHasAccess('Client.Employees', 'EDIT')}
                      validationSchema={yup.object().shape({
                        ...COMPANY_VALIDATION_SCHEMA,
                      })}
                      displayValue={
                        <CompanyNameWithLogo companyId={currentCompanyId} />
                      }
                      initialValues={{ companyId: values.companyId }}
                      onSave={async (usr) => {
                        if (usr.companyId !== currentCompanyId) {
                          setCompany(usr.companyId);

                          setPolicy(); // Clear the policy so the user can select one
                          // eslint-disable-next-line no-param-reassign
                          usr.companyPolicyId = '';
                        }
                        onSave(usr);
                      }}
                      formNode={
                        <Grid item>
                          <Field
                            name="companyId"
                            component={AppTextField}
                            fullWidth
                            autoFocus
                            select
                            options={listCompanies}
                          />
                        </Grid>
                      }
                    />
                  )}
                  {isPlatformAdmin && (
                    <SettingsModalInput<MutationUser>
                      label={
                        currentPolicyId
                          ? 'Policy ID'
                          : 'Policy ID is required - choose one now'
                      }
                      modalLabel="Choose a policy"
                      disabled={!userHasAccess('Client.Employees', 'EDIT')}
                      validationSchema={yup.object().shape({
                        ...COMPANY_POLICY_VALIDATION_SCHEMA,
                      })}
                      displayValue={
                        currentPolicyName && (
                          <CompanyNameWithLogo
                            textInsteadOfCompanyName={currentPolicyName}
                          />
                        )
                      }
                      initialValues={{
                        companyPolicyId: values.companyPolicyId,
                      }}
                      onSave={(usr) => {
                        if (usr.companyPolicyId !== currentPolicyId) {
                          setPolicy(usr.companyPolicyId);
                        }
                        onSave(usr);
                      }}
                      formNode={
                        <Grid item>
                          <Field
                            name="companyPolicyId"
                            component={AppTextField}
                            fullWidth
                            autoFocus
                            select
                            options={listPolicies}
                          />
                        </Grid>
                      }
                    />
                  )}
                  <SettingsFieldRow
                    label="Role"
                    value={<RoleChip roleId={values?.roles[0]} />}
                    onClick={
                      userHasAccess('Client.Employees', 'EDIT') &&
                      me?.id !== user?.id
                        ? () => setRoleModalOpen(true)
                        : undefined
                    }
                  />
                  <RoleSelectionModal
                    initialRole={values.roles[0]}
                    modalOpen={roleModalOpen}
                    preventMemberAssignment={!!assignedAsContact?.length}
                    preventMemberAssignmentReason={
                      (!!assignedAsContact?.length &&
                        `User currently assigned as contact (${contactTypeDisplay?.join(
                          ', ',
                        )}). User must be removed as contact to change role.`) ||
                      undefined
                    }
                    onClose={(role) => {
                      setRoleModalOpen(false);

                      if (role) {
                        onSave({
                          ...values,
                          roles: [role],
                        });
                      }
                    }}
                  />
                  {(companyType?.userTypes?.length || 0) > 1 && (
                    <SettingsModalInput<MutationUser>
                      label={`User Type ${
                        user?.userTypeSetByUserEmissionSources
                          ? '(Set by user, cannot be changed)'
                          : ''
                      }`}
                      modalLabel="Choose User Type"
                      disabled={
                        !userHasAccess('Client.Employees', 'EDIT') ||
                        user?.userTypeSetByUserEmissionSources
                      }
                      displayValue={
                        companyType?.userTypes?.find(
                          (x) => x.id === values.userTypeId,
                        )?.label
                      }
                      initialValues={{ userTypeId: values.userTypeId }}
                      onSave={onSave}
                      formNode={
                        <Grid item>
                          <Field
                            name="userTypeId"
                            component={AppTextField}
                            fullWidth
                            autoFocus
                            select
                            options={companyType?.userTypes?.map((x) => ({
                              label: x.label,
                              value: x.id,
                            }))}
                          />
                        </Grid>
                      }
                    />
                  )}
                  <SettingsModalInput<MutationUser>
                    label="Postal Code/Country"
                    modalLabel="Edit Postal Code and Country"
                    disabled={!userHasAccess('Client.Employees', 'EDIT')}
                    validationSchema={yup.object().shape({
                      ...POSTAL_CODE_VALIDATION_SCHEMA,
                    })}
                    displayValue={
                      values.postalCode
                        ? `${values.postalCode}/${values.country}`
                        : ''
                    }
                    initialValues={{
                      postalCode: values.postalCode,
                      country: values.country,
                    }}
                    onSave={onSave}
                    formNode={
                      <Grid item container direction="row" spacing={2}>
                        <Grid item xs>
                          <Field
                            name="postalCode"
                            placeholder="Postal Code"
                            component={AppTextField}
                            fullWidth
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs>
                          <Field
                            name="country"
                            component={AppTextField}
                            select
                            placeholder="Country"
                            fullWidth
                            options={allCountries.map((x) => ({
                              value: x.code,
                              label: `${x.code} - ${x.name}`,
                            }))}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                  <SettingsModalInput<{ status: UserStatus }>
                    label="Status"
                    modalLabel="Edit User Status"
                    disabled={!userHasAccess('Client.Employees', 'EDIT')}
                    displayValue={getUserStatus(values)}
                    initialValues={{ status: getUserStatus(values) }}
                    onSave={(v) =>
                      onSave({
                        isEnrolling: v.status === UserStatus.enrolling,
                        isActive: v.status !== UserStatus.inactive,
                      })
                    }
                    validationSchema={
                      assignedAsContact?.length
                        ? yup.object().shape({
                            status: yup
                              .string()
                              .is(
                                [UserStatus.active],
                                `User currently assigned as contact (${contactTypeDisplay?.join(
                                  ', ',
                                )}). User must be removed as contact to change status.`,
                              ),
                          })
                        : undefined
                    }
                    formNode={
                      <Grid item>
                        <Field
                          name="status"
                          component={AppTextField}
                          fullWidth
                          autoFocus
                          select
                          options={[
                            {
                              value: UserStatus.enrolling,
                              label: UserStatus.enrolling,
                            },
                            {
                              value: UserStatus.active,
                              label: UserStatus.active,
                            },
                            {
                              value: UserStatus.inactive,
                              label: UserStatus.inactive,
                            },
                          ]}
                        />
                      </Grid>
                    }
                  />
                  {user?.welcomeEmailSent && (
                    <SettingsFieldRow
                      label={
                        welcomeEmailResent
                          ? 'Welcome email has been resent!'
                          : 'Welcome Email Sent At'
                      }
                      value={
                        welcomeEmailResent ? null : (
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            style={{
                              marginBottom: -40,
                              position: 'relative',
                              top: -9,
                            }}>
                            <Typography
                              variant="body2"
                              style={{ paddingRight: 8 }}>
                              {moment(user.welcomeEmailSent).format(
                                'M/D/YYYY h:mm A',
                              )}
                            </Typography>
                            <SmallPaddedButton
                              onClick={() =>
                                setConfirmResendWelcomeEmail(true)
                              }>
                              Resend
                            </SmallPaddedButton>
                          </Grid>
                        )
                      }
                    />
                  )}
                </Grid>
                <Grid item className={classes.section}>
                  <Typography variant="h3" className={classes.subheading}>
                    User Data Requests
                  </Typography>
                  <Paper>
                    {user?.dataRequests?.map((req) => (
                      <Grid item key={req.type}>
                        <UserDataRequestRow
                          userId={user.id}
                          companyId={companyId}
                          dataRequest={req}
                          onDelete={() => onClose(true)}
                        />
                      </Grid>
                    ))}
                  </Paper>
                </Grid>
              </>
            );
          }}
        </SettingsPanel>
        <ConfirmCancelModal
          isOpen={confirmResendWelcomeEmail}
          onCancel={() => setConfirmResendWelcomeEmail(false)}
          onConfirm={async () => {
            setRefreshOnClose(true);
            if (user) {
              await resendWelcomeEmailMutation({
                variables: {
                  userId: user.id,
                  companyId: user.companyId,
                },
              });

              setWelcomeEmailResent(true);
            }
            setConfirmResendWelcomeEmail(false);
          }}
          title="Resend Welcome Email?"
          message={`Resend the welcome email to ${user?.name}?`}
        />
      </>
    </DataStateHandler>
  );
};

export default EditEmployeePanel;
