import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import AuthorizedRoute from '../../components/common/AuthorizedRoute';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import HeaderTab from '../../components/common/HeaderTabs/HeaderTab';
import HeaderTabs from '../../components/common/HeaderTabs/HeaderTabs';
import Header from '../../components/common/marketplace/Header';
import MainLayout from '../../components/layouts/MainLayout';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import MyInventory from './MyInventory';
import Projects from './Projects';
import PurchaseHistory from './PurchaseHistory';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 8,
    position: 'sticky',
    zIndex: 1,
    background: theme.palette.background.default,
  },
}));

const Marketplace: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const { loading: userLoading, isImpersonating } =
    useContext(AuthorizationContext);

  const tabs = [
    {
      label: 'Projects',
      route: path,
    },
    {
      label: 'My Inventory',
      route: `${path}/inventory`,
    },
    {
      label: 'Purchase History',
      route: `${path}/purchases`,
    },
  ];

  return (
    <MainLayout>
      <DataStateHandler loading={userLoading}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            className={classes.header}
            style={{ top: isImpersonating ? 40 : 0 }}>
            <Header />
            <HeaderTabs
              value={tabs.findIndex((x) => x.route === location.pathname)}
              onChange={(e, s) => history.push(tabs[s].route)}>
              {tabs.map((s) => (
                <HeaderTab
                  key={s.route}
                  label={s.label}
                  style={{ minWidth: `${s.label.length}ch` }}
                />
              ))}
            </HeaderTabs>
          </Grid>
          <Switch>
            <AuthorizedRoute
              ability="Client.Marketplace"
              path={`${path}/inventory`}>
              <MyInventory />
            </AuthorizedRoute>
            <AuthorizedRoute
              ability="Client.Marketplace"
              path={`${path}/purchases/`}>
              <PurchaseHistory />
            </AuthorizedRoute>
            <AuthorizedRoute ability="Client.Marketplace">
              <Projects />
            </AuthorizedRoute>
          </Switch>
        </Grid>
      </DataStateHandler>
    </MainLayout>
  );
};

export default Marketplace;
