import React from 'react';
import { useTheme } from '@nivo/core';
import { AxisTickProps } from '@nivo/axes';
import { ReactSVG } from 'react-svg';

type Props = {
  tick: AxisTickProps<string>;
  iconUri: string;
  color?: string;
};

const LogoTick: React.FC<Props> = ({ tick, iconUri, color }) => {
  const theme = useTheme();

  const { x, y, value } = tick;

  const text = value.split(' ');

  return (
    <g transform={`translate(${x},${y + 22})`}>
      <g transform="translate(-12, -12)" width={24} height={24}>
        <ReactSVG
          wrapper="svg"
          src={iconUri}
          beforeInjection={(svg) => {
            svg.setAttribute('style', `color: ${color}; fill: ${color}`);
            svg.setAttribute('height', '24');
            svg.setAttribute('width', '24');
          }}
        />
      </g>

      {text.map((t, index) => (
        <text
          key={t}
          y={12 + (index + 1) * 12}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            ...theme.axis.ticks.text,
            fill: '#333',
            fontSize: 10,
          }}>
          {t}
        </text>
      ))}
    </g>
  );
};

export default LogoTick;
