import leaderboardCompanyThirdDisabled from './LeaderboardCompanyThirdDisabled';
import leaderboardCompanyThirdEnabled from './LeaderboardCompanyThirdEnabled';

const leaderboardCompanyPlacementThird = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? leaderboardCompanyThirdDisabled(level, size)
    : leaderboardCompanyThirdEnabled(level, size);

export default leaderboardCompanyPlacementThird;
