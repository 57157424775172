import React from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { AppRichTextField } from '../common/AppRichTextField';

type Props = {
  disabled?: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const WelcomeEmailForm = (props?: Props) => (
  <Grid item display="flex" justifyContent="center">
    <Field
      // eslint-disable-next-line react/destructuring-assignment
      disabled={props?.disabled}
      name="welcomeEmailAdditionalText"
      editorStateFieldName="welcomeEmailAdditionalTextEditor"
      isEmailEditor
      component={AppRichTextField}
      autoFocus
    />
  </Grid>
);
