import { Dialog, Grid, Typography, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
  dialog: {
    padding: 24,
  },
  dialogButtonRow: {
    marginTop: 16,
    width: '100%',
  },
}));

type Props = {
  isOpen: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  hideCancel?: boolean;
  icon?: JSX.Element;
  title?: string;
  message?: string;
  messageFragments?: JSX.Element[]; // An array of elements to display. If defined, message will be shown first and then messageFragments.
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm: () => void;
  disableConfirm?: boolean;
  disableCancel?: boolean;
};

const ConfirmCancelModal: React.FC<Props> = ({
  isOpen,
  onCancel,
  onClose,
  onConfirm,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  hideCancel,
  title,
  icon,
  message,
  messageFragments,
  disableConfirm,
  disableCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose || onCancel || onConfirm}
      maxWidth="md">
      <Grid
        container
        className={classes.dialog}
        direction="column"
        alignItems="center">
        {icon || null}
        <Grid item>
          <Typography variant="h1" align="center">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {message}
            {messageFragments}
          </Typography>
        </Grid>
        <Grid item className={classes.dialogButtonRow}>
          <Grid
            container
            alignItems="center"
            spacing={2}
            justifyContent="space-between">
            {!hideCancel && (
              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disabled={disableCancel}
                  onClick={onCancel}>
                  {cancelLabel}
                </Button>
              </Grid>
            )}
            <Grid item xs={hideCancel ? 12 : 6}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={disableConfirm}
                onClick={onConfirm}>
                {confirmLabel}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmCancelModal;
