/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_NORMAL } from 'lexical';
import { $insertNodeToNearestRoot } from '@lexical/utils';
import { $createImageNode, ImageNode } from './nodes/ImageNode';
import { INSERT_IMAGE_COMMAND } from './commands';

export const ImagePlugin = () => {
  const [editor] = useLexicalComposerContext();

  if (!editor.hasNode(ImageNode)) {
    throw new Error('ImageNode: "ImageNode" not registered on editor');
  }
  editor.registerCommand(
    INSERT_IMAGE_COMMAND,
    ({ src }: { src: string }) => {
      $insertNodeToNearestRoot($createImageNode({ src }));
      return true;
    },
    COMMAND_PRIORITY_NORMAL,
  );

  return null;
};
