import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { chartColors } from '../../../../theme/theme';
import { CurrentBudget } from '../../../../types/dashboard/types';
import ConfigureBudgetCard from './ConfigureBudgetCard';
import NumberUtils from '../../../../utils/numberUtils';
import AppCard from '../../../common/AppCard/AppCard';
import PieChart from '../../../common/PieChart/PieChart';
import CollapsibleChartLabel from '../../../common/ChartLabel/CollapsibleChartLabel';

type Props = {
  currentBudget: CurrentBudget;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  label: {
    paddingTop: '20px !important',
  },
  arrow: {
    paddingTop: 12,
  },
}));

const OffsetBudgetCard: React.FC<Props> = ({ currentBudget, style }) => {
  const classes = useStyles();
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {currentBudget.total ? (
        <AppCard
          headerProps={{ title: 'OFFSET BUDGET', tooltipId: 'offsetBudget' }}
          height={366}>
          <Grid container spacing={4}>
            <Grid item xs={6} style={{ marginTop: 40 }}>
              <PieChart
                style={style}
                tooltipFormat="currency"
                data={[
                  {
                    id: 'Purchased',
                    label: 'Purchased',
                    value: currentBudget.spent || 0,
                    color: chartColors.darkBlue,
                  },
                  {
                    id: 'Remaining',
                    label: 'Remaining',
                    value: currentBudget.remaining || 0,
                    color: chartColors.blue,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 64 }}>
              <CollapsibleChartLabel
                rootClass={classes.label}
                size="medium"
                label="Total Budget"
                value={NumberUtils.format(currentBudget.total || 0, 'currency')}
              />
              <CollapsibleChartLabel
                rootClass={classes.label}
                arrowClass={classes.arrow}
                size="medium"
                label="Purchased"
                color={chartColors.darkBlue}
                value={NumberUtils.format(currentBudget.spent || 0, 'currency')}
                childLabels={[
                  {
                    label: 'VCC',
                    value: NumberUtils.format(
                      currentBudget.spentValueByUom.mt,
                      'currency',
                    ),
                  },
                  {
                    label: 'REC',
                    value: NumberUtils.format(
                      currentBudget.spentValueByUom.mwh,
                      'currency',
                    ),
                  },
                ]}
              />
              <CollapsibleChartLabel
                rootClass={classes.label}
                size="medium"
                label="Remaining"
                color={chartColors.blue}
                value={NumberUtils.format(
                  currentBudget.remaining || 0,
                  'currency',
                )}
              />
            </Grid>
          </Grid>
        </AppCard>
      ) : (
        <ConfigureBudgetCard />
      )}
    </>
  );
};

export default OffsetBudgetCard;
