import i18n from 'i18next';
import Pseudo from 'i18next-pseudo';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './en.json';

const pseudoEnabled = !!process.env.REACT_APP_PSEUDO_LOCALIZATION;

i18n
  .use(LanguageDetector)
  .use(
    new Pseudo({
      enabled: pseudoEnabled,
      languageToPseudo: 'en-US',
    }),
  )
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    parseMissingKeyHandler: (key) =>
      pseudoEnabled ? `!!!KEY NOT FOUND: ${key}!!!` : key,
    interpolation: {
      escapeValue: false,
    },
    postProcess: ['pseudo'],
    resources: {
      // TODO: Dynamic load of other languages?
      en: {
        translation: translationEN,
      },
    },
  });

export default i18n;
