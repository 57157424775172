import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { LngLatLike, Map, Marker } from 'mapbox-gl';
import useMapbox from '../../hooks/useMapbox';

const useStyles = makeStyles(() => ({
  mapDiv: { width: '100%', height: 207, borderRadius: 8, position: 'relative' },
}));

type Props = {
  latitude?: number | null;
  longitude?: number | null;
};

const LocationMap: React.FC<Props> = ({ latitude, longitude }) => {
  const classes = useStyles();
  const [marker, setMarker] = useState<Marker>();
  const { map, allLayersLoaded, mapNode } = useMapbox();

  useEffect(() => {
    if (marker) {
      marker.remove();
    }

    if (map && latitude && longitude && allLayersLoaded) {
      const lngLat: LngLatLike = [longitude, latitude];

      setMarker(new Marker().setLngLat(lngLat).addTo(map as Map));
      map?.setCenter(lngLat);
      map?.setZoom(10);
    }
  }, [latitude, longitude, map, allLayersLoaded]);

  return <div ref={mapNode} className={classes.mapDiv} />;
};

export default LocationMap;
