import React from 'react';
import { useParams } from 'react-router';

import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberCommunicationDetails from '../../../components/member/discover/MemberCommunicationDetails';

const MemberDiscoverDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <MemberLayout>
      <MemberCommunicationDetails id={id} />
    </MemberLayout>
  );
};

export default MemberDiscoverDetails;
