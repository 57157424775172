import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import SVG from '../../../../assets/svg';
import CategoryIconDiamond from '../../../common/Project/CategoryIconDiamond';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  button: {
    width: '100%',
  },
  text: {
    flex: 2,
  },
  icon: {
    marginTop: 64,
  },
  linkButton: {
    textDecoration: 'none',
  },
}));

type Props = {
  category?: { label: string; id: string };
  showViewProjectsButton: boolean; // Show the 'View [category] Projects' button?
  emptyMessage?: string; // If specified, show this message instead.
};

const MissingInvestmentData: React.FC<Props> = ({
  category,
  showViewProjectsButton,
  emptyMessage,
}) => {
  const classes = useStyles();

  // Determines which message to display
  const message =
    emptyMessage ||
    `You don't currently have any ${
      category ? `${category.label} projects.` : 'investments.'
    }`;

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}>
      <Grid item className={classes.icon}>
        {category ? (
          <CategoryIconDiamond categoryId={category.id} size="large" />
        ) : (
          <SVG.ConfigureBudget />
        )}
      </Grid>
      <Grid item className={classes.text}>
        <Typography variant="h2" align="center">
          {message}
        </Typography>
      </Grid>
      {showViewProjectsButton && (
        <Grid item className={classes.button}>
          <Link
            to={`/marketplace${category ? `?category=${category.id}` : ''}`}
            className={classes.linkButton}>
            <Button variant="contained" color="primary" fullWidth>
              View {category ? category.label : 'Marketplace'} Projects
            </Button>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default MissingInvestmentData;
