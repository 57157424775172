import React, { MouseEvent, useRef } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ListItem, FormControlLabel, Checkbox } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  labelDisplayedRows: {
    padding: 10,
    color: theme.palette.background.paper,
  },
  formControlLabelRoot: {
    width: '100%',
  },
  formControlLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 600,
  },
  listItem: {
    backgroundColor: theme.palette.background.paper,
  },
  checkbox: {
    padding: 0,
    paddingRight: 8,
  },
}));

const CheckList: React.FC<Props> = ({
  options = [],
  onChange = () => {
    // Do nothing
  },
}) => {
  const classes = useStyles();
  const checkboxRefs = useRef<Record<string, HTMLInputElement | undefined>>({});

  const handleListItemClick = (option: CheckListOption) => () => {
    if (!checkboxRefs.current[option.value]) {
      onChange(option);
    }
  };

  const handleLabelClick =
    (option: CheckListOption) => (e: MouseEvent<HTMLLabelElement>) => {
      if (!checkboxRefs.current[option.value]) {
        e.stopPropagation();
      }
    };

  const setInputRef = (option: CheckListOption) => (ref: HTMLInputElement) => {
    checkboxRefs.current[option.value] = ref;
  };

  return (
    <>
      {options.map((option) => (
        <ListItem
          button
          onClick={handleListItemClick(option)}
          key={JSON.stringify(option.value)}
          data-testid="CheckListItem"
          className={classes.listItem}>
          <FormControlLabel
            onClick={handleLabelClick(option)}
            control={
              <Checkbox
                data-testid="checklist-checkbox"
                inputRef={setInputRef(option)}
                checked={option.checked}
                onChange={() =>
                  onChange({
                    ...option,
                    checked: !option.checked,
                  })
                }
                value={option.value}
                color="secondary"
                className={classes.checkbox}
              />
            }
            label={option.description || option.value}
            classes={{
              root: classes.formControlLabelRoot,
              label: classes.formControlLabel,
            }}
          />
        </ListItem>
      ))}
    </>
  );
};

export type CheckListOption = {
  checked: boolean;
  value: string;
  description?: string | React.ReactNode;
};

type Props = {
  options: Array<CheckListOption>;
  onChange: (option: CheckListOption) => void;
};

export default CheckList;
