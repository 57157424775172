import { MenuItem } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import {
  DashboardContext,
  DivisionSelection,
} from '../../../contexts/DashboardContext';
import SelectMenu from '../SelectMenu/SelectMenu';

type Props = {
  className?: string;
};

const ClientCompanySelect: React.FC<Props> = ({ className }) => {
  const { company, divisionKey, divisions, setDivisionFromKey } =
    useContext(DashboardContext);

  useEffect(() => {
    setDivisionFromKey(`${company.id}_${company.name}`);
  }, [company.id]);
  return (
    <SelectMenu
      testId="client-company-select"
      key="division"
      id="division"
      className={className}
      onChange={(evt) => {
        setDivisionFromKey(evt.target.value);
      }}
      inputStyle={{
        height: 42,
      }}
      value={divisionKey}>
      {divisions.map((item: DivisionSelection) => (
        <MenuItem value={item.key} key={item.key}>
          {item.name}
        </MenuItem>
      ))}
    </SelectMenu>
  );
};

export default ClientCompanySelect;
