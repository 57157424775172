import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../../contexts/ProjectContext';
import { Project } from '../../../../types/project/types';
import CreateProjectInput from '../CreateProjectInput';
import createProjectSchema from '../createProjectSchema';
import ResetProjectFormListener from '../ResetProjectFormListener';
import ProjectContextFieldValidator from './ProjectContextFieldValidator';

const makeInitialValues = (project: Project) => ({
  description: project.description || '',
  smallSummary: project.smallSummary || '',
});

const CreateProjectDescription: React.FC = () => {
  const { project } = useContext(ProjectContext);
  return (
    <Formik
      initialValues={makeInitialValues(project)}
      // formik requires an onSubmit handler but we don't actually
      // do any submitting here - just using formik to handle validation
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      validationSchema={createProjectSchema.descriptionSchema}>
      {({ resetForm }) => (
        <Form noValidate>
          <ProjectContextFieldValidator step={ProjectDetailsStep.description} />
          <ResetProjectFormListener
            resetForm={resetForm}
            makeInitialValues={makeInitialValues}
          />
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <CreateProjectInput
                name="smallSummary"
                fieldType="Text"
                multiline
                variant="outlined"
                rows={3}
                fullWidth
                label="Short Description"
                sublabel="This description will be shown on the preview of each project"
              />
            </Grid>
            <Grid item>
              <CreateProjectInput
                name="description"
                fieldType="Text"
                multiline
                variant="outlined"
                rows={20}
                fullWidth
                label="Main Description"
                sublabel="This is the full description brought over from VERRA.org"
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default CreateProjectDescription;
