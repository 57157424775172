import { postcodeValidator } from 'postcode-validator';
import * as yup from 'yup';

export const optionalNumber = yup
  .number()
  .nullable(true)
  .transform((_, val) => (val === Number(val) ? val : undefined));

export const validPostalCodeTest = (): yup.TestConfig<
  string | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> => ({
  name: 'postalcode',
  message: 'Postal Code is invalid',
  // Need to use function syntax to avoid resetting this to get parent state
  // eslint-disable-next-line object-shorthand
  test: function test(value: string | undefined) {
    if (!value) {
      return true;
    }

    const user = this.parent;

    if (!user.country) {
      return false;
    }

    // Restrict postal code in US to 5 digits
    if (user.country?.toUpperCase() === 'US') {
      const usZip9Chrs = /^(\d{5})-(\d{4})$/;
      if (usZip9Chrs.test(value)) {
        // Uses existing 'postalcode' path
        return this.createError({
          message: 'Only 5-digit ZIP codes are permitted.',
        });
      }
    }

    return postcodeValidator(value || '', user.country);
  },
});

type SdbedProps =
  | {
      startDateField?: string;
      endDateField?: string;
      startDateLabel?: string;
      endDateLabel?: string;
    }
  | undefined;

export const startDateBeforeEndDateTest = ({
  startDateField = 'startDate',
  endDateField = 'endDate',
  startDateLabel = 'Start Date',
  endDateLabel = 'End Date',
}: SdbedProps = {}): yup.TestConfig<
  Date | null | undefined,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> => ({
  name: 'startDateBeforeEndDate',
  message: `${startDateLabel} must be before ${endDateLabel}`,
  // Need to use function syntax to avoid resetting this to get parent state
  // eslint-disable-next-line object-shorthand
  test: function test() {
    const startDate: Date | null = this.parent[startDateField];
    const endDate: Date | null = this.parent[endDateField];

    if (!startDate || !endDate) {
      return true;
    }

    return startDate < endDate;
  },
});
