import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { MemberCommunicationListItem } from '../../communications/types';
import CommunicationTypeIcon from '../../common/CommunicationTypeIcon';
import DefaultImage from '../../common/DefaultImage';

type Props = {
  communication: MemberCommunicationListItem;
  imageHeight: number;
  imageStyle?: React.CSSProperties;
};

const useStyles = makeStyles(() => ({
  root: {
    margin: '16px 0px',
    borderRadiuus: 8,
  },
  image: {
    borderRadius: 8,
    width: '100%',
    objectFit: 'cover',
    marginBottom: 8,
  },
  author: {
    color: '#6B6B6B',
  },
}));

const CommunicationHeaderImage: React.FC<Props> = ({
  communication,
  imageHeight,
  imageStyle,
}) => {
  const { headerImageUrls, communicationType, title } = communication;
  const classes = useStyles();

  return (
    <Box position="relative">
      {headerImageUrls.large ? (
        <img
          src={headerImageUrls.large}
          alt={title}
          className={classes.image}
          style={{ height: imageHeight, ...imageStyle }}
        />
      ) : (
        <DefaultImage
          height={imageHeight}
          style={imageStyle}
          className={classes.image}
          innerWidth="40%"
        />
      )}

      <Box position="absolute" left="8px" bottom="32px">
        <CommunicationTypeIcon communicationType={communicationType} />
      </Box>
    </Box>
  );
};

export default CommunicationHeaderImage;
