import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useSpringCarousel } from 'react-spring-carousel';

import SVG from '../../assets/svg-v2';
import { Colors } from '../../theme/shared';

const useStyles = makeStyles(() => ({
  container: {
    overflow: 'hidden',
  },
  navigation: {
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    top: -38,
  },
  tick: {
    height: 10,
    width: 10,
    borderRadius: 5,
    display: 'inline-block',
    margin: '0 6px',
  },
}));

type Props = {
  items: { id: string; renderItem: JSX.Element }[];
  itemsPerSlide: number;
  spacing?: number;
};

const MultiCarousel: React.FC<Props> = ({
  items,
  itemsPerSlide,
  spacing = 0,
}) => {
  const classes = useStyles();
  const [index, setIndex] = useState(0);

  const { carouselFragment, slideToPrevItem, slideToNextItem } =
    useSpringCarousel({
      withThumbs: false,
      withLoop: true,
      disableGestures: true,
      gutter: spacing,
      itemsPerSlide,
      items,
    });

  const previousSlide = () => {
    setIndex(index === 0 ? items.length - 1 : index - 1);
    slideToPrevItem();
  };

  const nextSlide = () => {
    setIndex(index === items.length - 1 ? 0 : index + 1);
    slideToNextItem();
  };

  return (
    <Grid container className={classes.container}>
      {carouselFragment}
      <Grid container className={classes.navigation}>
        <IconButton
          aria-label="Previous Item"
          size="large"
          onClick={previousSlide}>
          <SVG.CaretLeftSmall />
        </IconButton>
        <Grid item>
          {items.map((item, i) => (
            <Box
              key={item.id}
              className={classes.tick}
              bgcolor={index === i ? Colors.CobaltBlue : '#A9A9A9'}
            />
          ))}
        </Grid>
        <IconButton aria-label="Next Item" size="large" onClick={nextSlide}>
          <SVG.CaretRightSmall width={10} height={14} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MultiCarousel;
