/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
import {
  createEditor,
  DecoratorNode,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  SerializedEditor,
} from 'lexical';
import { SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import React from 'react';
import { Image } from './components';

const NODE_TYPE = 'ImageNode';

export interface SerializedImageNode extends SerializedDecoratorBlockNode {
  caption: SerializedEditor;
  src: string;
}

export class ImageNode extends DecoratorNode<JSX.Element> {
  __caption: LexicalEditor;

  __src: string;

  constructor(props?: {
    key?: NodeKey;
    caption?: LexicalEditor;
    src?: string;
  }) {
    super(props?.key);
    this.__caption = props?.caption || createEditor();
    this.__src = props?.src || 'https://placehold.co/600x400';
  }

  static getType(): string {
    return NODE_TYPE;
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode({
      key: node.__key,
      caption: node.__caption,
      src: node.__src,
    });
  }

  // This will be used for JSON export
  exportJSON(): SerializedImageNode {
    return {
      format: 'center', // this.__format,
      src: this.__src,
      caption: this.__caption.toJSON(),
      type: NODE_TYPE,
      version: 1,
    };
  }

  // This will be used for JSON import ('parse initial state')
  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    const { caption, src } = serializedNode;
    const node = $createImageNode({ src });

    const nestedEditor = node.__caption;
    const editorState = nestedEditor.parseEditorState(caption.editorState);
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState);
    }

    return node;
  }

  setImageSrc(src: string) {
    const writable = this.getWritable();
    writable.__src = src;
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  createDOM(config: EditorConfig): HTMLElement {
    const htmlElement = document.createElement('div');
    return htmlElement;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): false {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  decorate(_editor: LexicalEditor, config: EditorConfig): JSX.Element {
    return (
      <Image
        lexicalNodeKey={this.__key}
        src={this.__src}
        caption={this.__caption}
      />
    );
  }
}

export function $createImageNode(props?: {
  src: string;
  caption?: LexicalEditor;
}): ImageNode {
  if (!props?.caption) {
    return new ImageNode({
      src: props?.src,
    });
  }

  // NOTE: When you will try to clone existing editor we will need to create a new one
  // Without this we will just pass a reference to the previous editor
  const newEditor = createEditor();
  const editorState = newEditor.parseEditorState(
    props.caption.getEditorState().toJSON(),
  );
  if (!editorState.isEmpty()) {
    newEditor.setEditorState(editorState);
  }

  return new ImageNode({
    src: props?.src,
    caption: newEditor,
  });
}

export function $isImageNode(node?: LexicalNode | null): node is ImageNode {
  return node instanceof ImageNode;
}
