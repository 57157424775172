import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import moment, { Moment } from 'moment';

import ChallengeCard from './ChallengeCard';
import AppInfo from '../../common/AppInfo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import MultiCarousel from '../../common/MultiCarousel';
import { getContestsQuery } from '../../../graphql/contest/contests';
import {
  ContestDurationInterval,
  ContestType,
} from '../../../types/contest/types';
import { Colors } from '../../../theme/shared';

const ChallengeList: React.FC = () => {
  const now: Moment = moment();

  const {
    loading: loadingActive,
    error: errorActive,
    data: { findCompanyContests: activeChallenges = [] } = {},
  } = useQuery(getContestsQuery(true), {
    variables: {
      contestType: ContestType.COMPANY,
      isDraft: false,
      interval: ContestDurationInterval.WEEKLY,
      activeBetweenYYYMM: now.format('YYYYWW'),
      sortBy: 'duration.startPeriod',
      sortDirection: 'DESC',
    },
  });

  const {
    loading: loadingPast,
    error: errorPast,
    data: { findCompanyContests: pastChallenges = [] } = {},
  } = useQuery(getContestsQuery(false), {
    variables: {
      contestType: ContestType.COMPANY,
      isDraft: false,
      interval: ContestDurationInterval.WEEKLY,
      endsBeforeYYYYMM: now.clone().subtract(1, 'week').format('YYYYWW'),
      sortBy: 'duration.startPeriod',
      sortDirection: 'DESC',
    },
  });

  const noActiveChallenges = !activeChallenges.length && !pastChallenges.length;

  return (
    <DataStateHandler
      loading={loadingActive || loadingPast}
      error={errorActive || errorPast}>
      <Grid container>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{t('contest.teamChallenge')}</Typography>
          <AppInfo id="challenges_header" />
        </Grid>
        {noActiveChallenges && (
          <Grid xs textAlign="center">
            <Typography variant="body1" color={Colors.TertiaryText}>
              {t('contest.noActiveChallenges')}
            </Typography>
          </Grid>
        )}
        {!!activeChallenges.length && (
          <MultiCarousel
            itemsPerSlide={1}
            items={activeChallenges.map((challenge) => ({
              id: challenge.id,
              renderItem: (
                <ChallengeCard
                  key={challenge.id}
                  challenge={challenge}
                  titlePosition="top"
                  imageHeight={500}
                  showSummary
                />
              ),
            }))}
          />
        )}
        {!!pastChallenges.length && (
          <Grid container item>
            <Typography variant="h3">{t('contest.pastChallenges')}</Typography>
            <Grid container item>
              {pastChallenges.map((challenge) => (
                <Grid item key={challenge.id} xs={4}>
                  <ChallengeCard
                    challenge={challenge}
                    titlePosition="bottom"
                    imageHeight={192}
                    showSash
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </DataStateHandler>
  );
};

export default ChallengeList;
