import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { User } from '../../../types/user/types';

const useStyles = makeStyles(() => ({
  profileImage: {
    width: 110,
    height: 110,
    backgroundSize: 'cover',
    display: 'inline-block',
    borderRadius: 55,
    fontSize: 50,
    backgroundColor: '#DADADA',
    lineHeight: '110px',
    textAlign: 'center',
  },
  medium: {
    width: 40,
    height: 40,
    borderRadius: 20,
    fontSize: 20,
    lineHeight: '40px',
  },
  small: {
    width: 24,
    height: 24,
    borderRadius: 12,
    fontSize: 12,
    lineHeight: '24px',
  },
}));

type Props = {
  user?: Pick<User, 'profileImageUris' | 'firstName' | 'lastName' | 'name'>;
  size?: 'sm' | 'md' | 'lg';
};

const UserImage: React.FC<Props> = ({ user, size = 'lg' }) => {
  const classes = useStyles();

  const sizeClass = {
    sm: classes.small,
    md: classes.medium,
    lg: '',
  }[size];

  return (
    <div
      className={`${classes.profileImage} ${sizeClass}`}
      style={{ backgroundImage: `url(${user?.profileImageUris?.medium})` }}>
      {user?.profileImageUris?.medium
        ? '\u00A0'
        : `${(user?.firstName ||
            user?.name ||
            ' ')[0].toUpperCase()}${(user?.lastName || ' ')[0].toUpperCase()}`}
    </div>
  );
};

export default UserImage;
