import { useQuery } from '@apollo/client';
import { ImageSize } from '../types/common';
import { Project } from '../types/project/types';
import { GET_ALL_CATEGORIES } from '../graphql/projects/projectCategories';

type Props = {
  project?: Pick<Project, 'headerImageUris' | 'category'>;
  size: ImageSize;
};

export default function useProjectImage({ project, size }: Props) {
  const { data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const category = projectCategories?.find((x) => x.id === project?.category);

  return project?.headerImageUris?.[size] || category?.backgroundImageUri;
}
