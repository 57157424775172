import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import PollCard from './PollCard';
import AppCarousel from '../../common/AppCarousel';
import { LocalizedQuiz } from '../../communications/types';
import { GET_MEMBER_QUIZZES } from '../../../graphql/communications/memberCommunications';
import { UPDATE_QUIZ_ANSWER } from '../../../graphql/questionnaire/questions';

type Props = {
  polls: LocalizedQuiz[];
};

const PollCards: React.FC<Props> = ({ polls }) => {
  const history = useHistory();

  const [updateQuizAnswer] = useMutation(UPDATE_QUIZ_ANSWER, {
    refetchQueries: [GET_MEMBER_QUIZZES],
  });

  const onDismiss = (pollId: string) =>
    updateQuizAnswer({
      variables: {
        quizId: pollId,
        isHidden: true,
      },
    });

  if (!polls.length) {
    return null;
  }

  return (
    <AppCarousel
      items={polls.map((poll) => (
        <PollCard
          key={poll.id}
          title={poll.title}
          onClick={() => history.push(`/member/poll/${poll.id}`)}
          onDismiss={() => onDismiss(poll.id)}
        />
      ))}
    />
  );
};

export default PollCards;
