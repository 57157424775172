/* eslint-disable react/no-array-index-key */
import { Chip, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import SVG from '../../../../assets/svg';
import {
  ProjectContext,
  ProjectContextEvents,
} from '../../../../contexts/ProjectContext';
import ImageUtils from '../../../../utils/imageUtils';
import { getProjectAdditionalImageUris } from '../../../../utils/projectUtils';
import FileUpload from '../../../common/FileUpload/FileUpload';
import AdditionalProjectImagesModal from '../AdditionalProjectImagesModal';

const useStyles = makeStyles(() => ({
  additionalImage: {
    height: 100,
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
  },
}));

const CreateProjectAdditionalImages: React.FC = () => {
  const { project, setTypedProjectField } = useContext(ProjectContext);
  const [localImageUrls, setLocalImageUrls] = useState<string[]>([]);
  const classes = useStyles();

  const allImages = [
    ...getProjectAdditionalImageUris(project),
    ...localImageUrls,
  ];

  const clearLocalImageUrls = () => setLocalImageUrls([]);

  useEffect(() => {
    document.addEventListener(ProjectContextEvents.RELOAD, clearLocalImageUrls);
    return () => {
      document.removeEventListener(
        ProjectContextEvents.RELOAD,
        clearLocalImageUrls,
      );
    };
  }, []);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Grid item>
          <Typography variant="h3">Additional Images</Typography>
        </Grid>
        <Grid item>
          {!!project.category && (
            <AdditionalProjectImagesModal
              categoryId={project.category}
              onSave={(urls) => {
                setTypedProjectField('additionalImages', [
                  ...(project.additionalImages || []),
                  ...urls.map((publicUrl) => ({
                    publicUrl,
                  })),
                ]);
                setLocalImageUrls([...localImageUrls, ...urls]);
              }}
            />
          )}
        </Grid>
      </Grid>
      <div style={{ margin: '9px 0 18px' }}>
        <FileUpload
          accept={ImageUtils.SupportedFileExtensions}
          allowMultiple
          uploadLabel={
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h4">
                  Drop additional images here
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" style={{ fontSize: 10 }}>
                  Supports JPG, JPEG2000, PNG, BMP
                </Typography>
              </Grid>
            </Grid>
          }
          onFileChange={(fd) => {
            setTypedProjectField('additionalImages', [
              ...(project.additionalImages || []),
              ...fd.map((x) => ({
                file: x.file,
              })),
            ]);
            setLocalImageUrls([
              ...localImageUrls,
              ...fd.map((x) => x.imageDataUrl),
            ]);
          }}
        />
      </div>
      <Grid container direction="row" spacing={2}>
        {allImages.map((url, imgIndex) => (
          <Grid item key={imgIndex} xs={4} style={{ position: 'relative' }}>
            <img
              alt="Additional"
              className={classes.additionalImage}
              src={url}
            />
            <Chip
              label={<SVG.Delete style={{ position: 'relative', top: 3 }} />}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                opacity: 0.75,
              }}
              onClick={() => {
                const uriIndex = (project.additionalImageUris || [])
                  .filter(
                    (x, i) =>
                      (project.additionalImageIndexesToRemove || []).indexOf(
                        i,
                      ) < 0,
                  )
                  .indexOf(url);

                if (uriIndex >= 0) {
                  setTypedProjectField('additionalImageIndexesToRemove', [
                    ...(project.additionalImageIndexesToRemove || []),
                    uriIndex,
                  ]);
                } else {
                  const localImageIndex = localImageUrls.indexOf(url);

                  setTypedProjectField(
                    'additionalImages',
                    (project.additionalImages || []).filter(
                      (x, i) => i !== localImageIndex,
                    ),
                  );
                  setLocalImageUrls(
                    localImageUrls.filter((x, i) => i !== localImageIndex),
                  );
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CreateProjectAdditionalImages;
