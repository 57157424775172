import { Typography, Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import FileUpload from '../common/FileUpload/FileUpload';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';
import {
  EditableCompany,
  COMPANY_LOGO_BACKGROUND_COLORS,
} from '../../contexts/CompanySettingsContext';
import ImageUtils from '../../utils/imageUtils';

const useStyles = makeStyles(() => ({
  rect: {
    stroke: '#DADADA',
    strokeWidth: 1,
  },
  colorLabel: {
    marginLeft: 4,
    position: 'relative',
    top: -5,
  },
  button: {
    height: 24,
    width: '100%',
    textAlign: 'left',
  },
  selectedButton: {
    border: '1px solid #BDD9C5',
  },
}));

const UploadCompanyLogo: React.FC = () => {
  const { values, setTypedFieldValue } =
    useTypedFormikContext<EditableCompany>();
  const classes = useStyles();

  return (
    <>
      <Typography align="center" variant="h1" style={{ marginBottom: 40 }}>
        Upload Organization Logo
      </Typography>
      <FileUpload
        showFiles
        accept={ImageUtils.SupportedFileExtensions}
        onFileChange={(files) => {
          if (files.length) {
            setTypedFieldValue('logo', {
              file: files[0].file,
            });
          }
        }}
      />
      <Typography
        variant="h3"
        style={{ marginTop: 40, marginBottom: 16, textAlign: 'center' }}>
        Select Background Color
      </Typography>
      <Grid container direction="row" style={{ marginBottom: 24 }}>
        {COMPANY_LOGO_BACKGROUND_COLORS.map((c) => (
          <Grid item key={c.color} xs={4}>
            <Button
              fullWidth
              onClick={() => setTypedFieldValue('logoBackgroundColor', c.color)}
              className={
                c.color === values.logoBackgroundColor
                  ? classes.selectedButton
                  : undefined
              }>
              <div className={classes.button}>
                <svg width="24" height="24">
                  <rect
                    width="24"
                    height="24"
                    rx="2"
                    className={classes.rect}
                    style={{
                      fill: c.color,
                    }}
                  />
                </svg>
                <Typography
                  variant="body1"
                  className={classes.colorLabel}
                  component="span">
                  {c.name}
                </Typography>
              </div>
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default UploadCompanyLogo;
