import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import moment from 'moment';

import Leaderboards from './Leaderboards';
import AppInfo from '../../common/AppInfo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { getContestsQuery } from '../../../graphql/contest/contests';
import { Colors } from '../../../theme/shared';
import { ContestType } from '../../../types/contest/types';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 24,
    marginBottom: 24,
  },
}));

const QuarterlyLeaderboards: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedContestId, setSelectedContestId] = useState<string>();

  const {
    loading,
    error,
    data: { findCompanyContests: contests = [] } = {},
  } = useQuery(getContestsQuery(true), {
    variables: {
      contestType: ContestType.AUTOMATIC_QUARTERLY,
      // New contests will not have leaderboard data until the 2nd day
      startsBeforeYYYYMM: moment().subtract(1, 'day').format('YYYYMM'),
      sortBy: 'duration.startPeriod',
      sortDirection: 'DESC',
    },
    onCompleted: (data) =>
      setSelectedContestId(data.findCompanyContests[0]?.id),
  });

  const handleSelectContest = (contestId: string) => {
    setSelectedContestId(contestId);
    setAnchorEl(null);
  };

  const selectedContest = contests?.find((c) => c.id === selectedContestId);

  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container justifyContent="space-between">
        <Typography variant="h3">{t('contest.leaderboard')}</Typography>
        <AppInfo id="leaderboard_header" />
      </Grid>
      {selectedContest ? (
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="center">
            <Grid item>
              <Typography variant="body1" color={Colors.TertiaryText}>
                {moment(selectedContest.duration?.startDate).format('M/D/YYYY')}{' '}
                - {moment(selectedContest.duration?.endDate).format('M/D/YYYY')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                endIcon={
                  <KeyboardArrowDownIcon style={{ fill: Colors.DarkGrey }} />
                }
                onClick={(event) => setAnchorEl(event.currentTarget)}>
                <Typography variant="body1">
                  {t('contest.viewPreviousQuarters')}
                </Typography>
              </Button>
              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}>
                {contests.map(({ id, duration }) => (
                  <MenuItem key={id} onClick={() => handleSelectContest(id)}>
                    <Typography variant="body1">
                      {moment(duration.startDate).format('M/D/YYYY')} -{' '}
                      {moment(duration.endDate).format('M/D/YYYY')}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>
          <Divider className={classes.divider} flexItem />
          <Leaderboards contest={selectedContest} />
        </Grid>
      ) : (
        <Grid>
          <Typography variant="h2" align="center">
            {t('contest.calculatingResults')}
          </Typography>
          <Typography variant="body1" align="center">
            {t('contest.calculatingResultsDescription')}
          </Typography>
        </Grid>
      )}
    </DataStateHandler>
  );
};

export default QuarterlyLeaderboards;
