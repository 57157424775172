import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { isBefore, parseISO } from 'date-fns';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';

import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { CommunicationInput } from './types';
import SVG from '../../assets/svg';
import ConfirmCancelModal from '../common/ConfirmCancelModal/ConfirmCancelModal';

type Props = {
  onSave: () => void;
  onDelete?: () => void;
  saveDisabled?: boolean;
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: 24,
  },
  dialogButtonRow: {
    marginTop: 16,
    width: '100%',
  },
  delete: {
    borderColor: theme.palette.error.dark,
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      color: 'white',
    },
  },
  deleteIcon: {
    color: theme.palette.error.main,
  },
}));

const CommunicationDetailsFooter: React.FC<Props> = ({
  onSave,
  saveDisabled,
  onDelete,
}) => {
  const { setTypedFieldValue, initialValues } =
    useTypedFormikContext<CommunicationInput>();
  const classes = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { publishDate: initialPublishDate } = initialValues;

  const hasBeenPublished = isBefore(
    parseISO(initialPublishDate),
    parseISO(new Date().toISOString()),
  );
  return (
    <>
      <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
        {onDelete ? (
          <Grid item>
            <SmallPaddedButton
              onClick={() => setIsDeleteModalOpen(true)}
              variant="outlined"
              classes={{
                root: classes.delete,
                outlinedPrimary: classes.delete,
              }}
              color="primary">
              Delete
            </SmallPaddedButton>
          </Grid>
        ) : null}
        {hasBeenPublished ? (
          <Grid item>
            <SmallPaddedButton
              variant="outlined"
              color="primary"
              onClick={() => setIsDialogOpen(true)}>
              Unpublish
            </SmallPaddedButton>
          </Grid>
        ) : (
          <Grid item>
            <SmallPaddedButton
              disabled={saveDisabled}
              variant="contained"
              color="primary"
              onClick={onSave}>
              Save
            </SmallPaddedButton>
          </Grid>
        )}
      </Grid>
      <ConfirmCancelModal
        isOpen={isDialogOpen}
        onCancel={() => setIsDialogOpen(false)}
        confirmLabel="Unpublish"
        onConfirm={() => {
          setTypedFieldValue('publishDate', '');
          setTypedFieldValue('hasBeenUnpublished', true);
          setIsDialogOpen(false);
        }}
        title="Heads Up!"
        message="If you unpublish this it will be removed from the mobile app and will not be able to be accessed again."
      />
      {onDelete ? (
        <ConfirmCancelModal
          title="Heads Up!"
          icon={
            <Grid item>
              <SVG.WarningTriangle className={classes.deleteIcon} />
            </Grid>
          }
          message="You are about to delete this communication. This cannot be reversed."
          isOpen={isDeleteModalOpen}
          onCancel={() => setIsDeleteModalOpen(false)}
          onConfirm={() => {
            setIsDeleteModalOpen(false);
            onDelete();
          }}
        />
      ) : null}
    </>
  );
};

export default CommunicationDetailsFooter;
