import React, { useState } from 'react';
import FormattedTooltip from './ChartTooltips/FormattedTooltip';
import { WaterFootprintItem } from '../../types/dashboard/types';

type Props = {
  items: WaterFootprintItem[];
  staticTooltipStyle?: React.CSSProperties;
};

const WaterFootprintDropChart: React.FC<Props> = ({
  items,
  staticTooltipStyle,
}) => {
  const [tooltipContents, setTooltipContents] = useState<
    (WaterFootprintItem & { y: number }) | undefined
  >(undefined);

  const [coords, setCoords] = useState<{ x: number; y: number } | undefined>(
    undefined,
  );
  const svgHeight = 275;
  let currentY = 0;

  const total = items.reduce((acc, cur) => acc + cur.value, 0);

  const rects = items.map((item) => {
    const height = (item.value / total) * svgHeight;
    currentY += height;
    return {
      ...item,
      height,
      y: svgHeight - currentY,
    };
  });

  return (
    <>
      {tooltipContents && coords && (
        <div
          style={
            staticTooltipStyle || {
              position: 'absolute',
              left: (coords.x || 0) + 20,
              top: (coords.y || 0) - 15,
            }
          }>
          <FormattedTooltip
            format="integer"
            value={tooltipContents.value}
            id={tooltipContents.id}
            color={tooltipContents.color}
          />
        </div>
      )}
      <div
        style={{ height: svgHeight }}
        onMouseMove={(ev) =>
          setCoords({
            x: ev.pageX,
            y: ev.pageY,
          })
        }
        onMouseLeave={() => setCoords(undefined)}>
        <svg
          width="185"
          height={svgHeight}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="waterdrop">
              <path
                transform="translate (40, 0) rotate(180, 92.5, 137.5)"
                d="M132.281,264.564c51.24,0,92.931-41.681,92.931-92.918c0-50.18-87.094-164.069-90.803-168.891L132.281,0l-2.128,2.773
			c-3.704,4.813-90.802,118.71-90.802,168.882C39.352,222.883,81.042,264.564,132.281,264.564z"
              />
            </clipPath>
          </defs>
          <g
            height={svgHeight}
            width={185}
            clipPath="url(#waterdrop)"
            transform="rotate(180, 92.5, 137.5)">
            {rects.map((item) => (
              <rect
                key={item.id}
                y={item.y}
                height={item.height}
                width={185}
                fill={item.color}
                onMouseEnter={() => setTooltipContents(item)}
                onMouseLeave={() => setTooltipContents(undefined)}
              />
            ))}
          </g>
        </svg>
      </div>
    </>
  );
};

export default WaterFootprintDropChart;
