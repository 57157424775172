import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ProjectCard from '../Project/ProjectCard';
import { Project, ProjectCategory } from '../../../types/project/types';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

type Props = {
  categories: ProjectCategory[];
  projects: Project[];
  clickHandler: (arg: string) => void;
};

const ProjectBox: React.FC<Props> = ({
  categories,
  projects,
  clickHandler,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {projects.length > 0 ? (
        <Grid container spacing={3} direction="row">
          {projects.map((project) => (
            <Grid
              item
              xs={4}
              key={project.id}
              onClick={() => clickHandler(project.id || ' ')}>
              <ProjectCard
                category={
                  categories.find(
                    (cat) => project.category === cat.id,
                  ) as ProjectCategory
                }
                unavailable={
                  (!project.remainingQuantity ||
                    project.remainingQuantity === 0) &&
                  !project.isIndicative
                }
                project={project}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        'No projects match your search requirements.'
      )}
    </div>
  );
};

export default ProjectBox;
