import React, { useContext } from 'react';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { BaseCompany } from '../../../types/company/types';

export type PlatformFeature = 'budget' | 'marketplace' | 'offsets';

type Props = {
  featureIds: PlatformFeature[];
  children: JSX.Element;
};

export const isFeatureEnabled = (
  company: BaseCompany,
  featureId: PlatformFeature,
) => company.features?.find((f) => f.id === featureId)?.isEnabled ?? false;

const CheckFeature: React.FC<Props> = ({ featureIds, children }) => {
  const { company } = useContext(AuthorizationContext);

  const hasAccess = featureIds.every((featureId) =>
    isFeatureEnabled(company, featureId),
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{hasAccess ? children : null}</>;
};

export default CheckFeature;
