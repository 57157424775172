import React, { useEffect } from 'react';
import { Grid, Typography, TextField, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useDebounce from '../../../hooks/useDebounce';
import useFirstRender from '../../../hooks/useFirstRender';
import { sanitizeQuantity } from '../marketplace/marketplaceUitls';
import TooltipError from '../Tooltip/TooltipError';
import { CartItem } from '../../../types/user/types';
import useProjectImage from '../../../hooks/useProjectImage';

const useStyles = makeStyles((theme) => ({
  quantity: {
    marginTop: 8,
    maxHeight: 48,
  },
  qtyInput: {
    width: '100%',
    borderRadius: 4,
  },
  qtyLabel: {
    float: 'left',
    marginTop: 8,
    fontWeight: 600,
  },
  right: {
    marginLeft: 'auto',
    textAlign: 'right',
  },
  container: {
    paddingLeft: 8,
    flexWrap: 'nowrap',
  },
  customColor: {
    color: theme.palette.divider,
  },
  footer: {
    marginTop: 12,
  },
  removeButton: {
    color: 'red',
  },
  thumbnail: {
    width: 101,
    height: 96,
    borderRadius: 8,
  },
}));

type Props = {
  item: CartItem;
  updateValid(valid: boolean): void;
  updateTotal(value: number, id: string, remove?: boolean): void;
};

const ShoppingCartItemView: React.FC<Props> = ({
  item,
  updateValid,
  updateTotal,
}) => {
  const classes = useStyles();
  const firstRender = useFirstRender();
  const [qty, setQty] = React.useState(item.quantity);
  const [removing, setRemoving] = React.useState(false);
  const projectImage = useProjectImage({
    project: item.project,
    size: 'thumb',
  });

  const getErrorTooltip = (newQty: number) => {
    if (newQty <= 0) {
      return 'Quantity must be greater than 0';
    }

    if (!removing && newQty > (item.project.remainingQuantity || 0)) {
      return `The quantity you've entered is higher than the available amount`;
    }

    return undefined;
  };

  const handleDelete = () => {
    setRemoving(true);
    setQty(0);
    updateTotal(0, item.project.id, true);
  };
  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = sanitizeQuantity(e);
    setQty(newQty);
    updateValid(!getErrorTooltip(newQty));
  };

  useEffect(() => setQty(item.quantity), [item]);

  const debouncedQty = useDebounce(qty, 500);

  useEffect(() => {
    if (!firstRender && qty && qty <= (item.project.remainingQuantity || 0)) {
      updateTotal(qty, item.project.id);
    }
  }, [debouncedQty]);

  const uomDisplay = item.project.uom ? ` / ${item.project.uom}` : '';

  return (
    <Grid
      container
      alignItems="center"
      direction="row"
      className={classes.container}
      spacing={2}>
      <Grid item>
        <img
          alt="cart item thumbnail"
          src={projectImage}
          className={classes.thumbnail}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="space-evenly">
          <Grid item>
            <Typography variant="body2" className={classes.customColor}>
              {(item.project.sellingPrice || 0).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}{' '}
              {uomDisplay}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h3" color="textPrimary">
              {item.project.title}
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            className={classes.quantity}
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            spacing={1}>
            <Grid item>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.qtyLabel}>
                QTY:
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <TooltipError
                title={getErrorTooltip(qty)}
                popperProps={{
                  disablePortal: false,
                }}
                open={!!getErrorTooltip(qty)}>
                <TextField
                  id={item.project.id}
                  value={qty.toString()}
                  size="small"
                  variant="outlined"
                  type="number"
                  className={classes.qtyInput}
                  onChange={handleQtyChange}
                />
              </TooltipError>
            </Grid>
            <Grid item>
              <Button onClick={handleDelete} variant="text" fullWidth>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.removeButton}>
                  Remove
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.right} xs={4}>
        <Typography variant="h3" color="textSecondary">
          {((item.project.sellingPrice || 0) * qty).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ShoppingCartItemView;
