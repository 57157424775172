import { Grid } from '@mui/material';
import React from 'react';
import OffsetOpportunities from './OffsetOpportunities/OffsetOpportunities';
import {
  CategoryData,
  CurrentBudget,
  InvestmentsData,
} from '../../../types/dashboard/types';
import CheckFeature from '../../common/CheckFeature/CheckFeature';

type Props = {
  totalInvestments: InvestmentsData;
  categoryInvestments: CategoryData[];
  currentBudget: CurrentBudget;
  lifetimeBudget: CurrentBudget;
};

const Offsets: React.FC<Props> = ({
  totalInvestments,
  categoryInvestments,
  currentBudget,
  lifetimeBudget,
}) => (
  <Grid
    container
    spacing={2}
    direction="column"
    style={{ height: '100%', marginBottom: 32 }}>
    <Grid item>
      <CheckFeature featureIds={['marketplace']}>
        <OffsetOpportunities
          lifetimeBudget={lifetimeBudget}
          totalInvestments={totalInvestments}
          categoryInvestments={categoryInvestments}
          currentBudget={currentBudget}
        />
      </CheckFeature>
    </Grid>
  </Grid>
);

export default Offsets;
