import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableBody,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import { GET_ALL_CATEGORIES } from '../../graphql/projects/projectCategories';
import CategoryIconDiamond from '../common/Project/CategoryIconDiamond';
import { GET_COMPANY_PROJECTS } from '../../graphql/projects/projects';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import ArrayUtils from '../../utils/arrayUtils';
import useDebounce from '../../hooks/useDebounce';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';

const useStyles = makeStyles(() => ({
  categoryText: {
    lineHeight: '50px',
    paddingLeft: 8,
  },
  headerText: {
    fontSize: 12,
    fontWeight: 500,
  },
  center: {
    textAlign: 'center',
  },
}));

const CompanyOffsetCategories: React.FC = () => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { company, saveCompany } = useContext(CompanySettingsContext);
  const companyCategoryIds = [...(company.categoryIds || [])];
  const [selectedCategories, setSelectedCategories] =
    useState(companyCategoryIds);

  const canEdit = userHasAccess('Configuration.Platform', 'EDIT');

  const debouncedCategories = useDebounce(selectedCategories, 1000);

  useEffect(() => {
    if (!ArrayUtils.equal(debouncedCategories, companyCategoryIds)) {
      saveCompany(
        {
          categoryIds: debouncedCategories,
        },
        {
          savePlatform: true,
        },
      );
    }
  }, [debouncedCategories]);

  const { loading: categoriesLoading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const {
    loading: projectsLoading,
    data: { companyProjects: { items: projects = [] } = {} } = {},
  } = useQuery(GET_COMPANY_PROJECTS, {
    variables: {
      companyId: company.id,
      pageSize: 1000,
    },
  });

  const categoryProjectCount = projects.reduce<{ [index: string]: number }>(
    (acc, cur) => ({
      ...acc,
      [cur.category || '']: (acc[cur.category || ''] || 0) + 1,
    }),
    {},
  );

  return (
    <DataStateHandler loading={categoriesLoading || projectsLoading}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {canEdit && (
                <Checkbox
                  checked={projectCategories.every(
                    (x) => selectedCategories.indexOf(x.id) >= 0,
                  )}
                  onClick={() =>
                    setSelectedCategories(
                      selectedCategories.length < projectCategories.length
                        ? projectCategories.map((x) => x.id)
                        : [],
                    )
                  }
                />
              )}
            </TableCell>
            <TableCell className={classes.headerText} style={{ width: 220 }}>
              CATEGORY
            </TableCell>
            <TableCell className={classes.headerText}>DESCRIPTION</TableCell>
            <TableCell className={classes.headerText} style={{ width: 160 }}>
              ACTIVE PROJECTS IN THE MARKETPLACE
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...projectCategories]
            .sort(
              (a, b) => categoryProjectCount[b.id] - categoryProjectCount[a.id],
            )
            .map((c) => (
              <TableRow key={c.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedCategories.indexOf(c.id) >= 0}
                    disabled={!canEdit}
                    onClick={() =>
                      setSelectedCategories(
                        ArrayUtils.addOrRemove(selectedCategories, c.id),
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <Grid container direction="row">
                    <Grid item>
                      <CategoryIconDiamond categoryId={c.id} size="small" />
                    </Grid>
                    <Grid item>
                      <Typography variant="h4" className={classes.categoryText}>
                        {c.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>{c.description}</TableCell>
                <TableCell className={classes.center}>
                  {categoryProjectCount[c.id] || 0}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </DataStateHandler>
  );
};

export default CompanyOffsetCategories;
