import { Grid, Switch, Typography } from '@mui/material';
import React from 'react';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { CommunicationInput } from '../types';
import { Disableable } from '../../../types/common';

type Props = Disableable & {
  style?: React.CSSProperties;
};

const IncludeChildrenSwitch: React.FC<Props> = ({ disabled, style }) => {
  const { setTypedFieldValue, values } =
    useTypedFormikContext<CommunicationInput>();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      style={style}>
      <Grid item>
        <Typography variant="body2">Send to Organization</Typography>
      </Grid>
      <Grid item>
        <Switch
          checked={values.includeChildren || false}
          disabled={disabled}
          onChange={(e, checked) =>
            setTypedFieldValue('includeChildren', checked)
          }
        />
      </Grid>
    </Grid>
  );
};

export default IncludeChildrenSwitch;
