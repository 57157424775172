import { useFormikContext } from 'formik';

export default function useTypedFormikContext<T>() {
  // Remove setFieldValue to prevent accidental usage
  const { setFieldValue, ...formikContext } = useFormikContext<T>();

  return {
    ...formikContext,
    setTypedFieldValue: <Key extends keyof T>(field: Key, value: T[Key]) =>
      setFieldValue(field as string, value),
    setNestedTypedFieldValue: <NestedType>(
      prefix: string,
      field: keyof NestedType,
      value: NestedType[keyof NestedType],
    ) => setFieldValue(`${prefix}.${String(field)}`, value),
  };
}
