import React from 'react';
import { useQuery } from '@apollo/client';

import Emissions from '../../dashboards/Impact/Emissions/Emissions';
import useCurrentCompany from '../../../hooks/useCurrentCompany';
import { EMISSIONS } from '../../../graphql/dashboard/emissions/emissions';
import { dateRangeFromEndDate } from '../../../utils/dateUtils';
import { ALL_COMPANIES } from '../../../contexts/DashboardContext';

type Props = {
  endDate: Date;
};

const EmployeeEmissionsCard: React.FC<Props> = ({ endDate }) => {
  const { companyId, includeChildren } = useCurrentCompany();

  const {
    data: { emissions = {}, company: { targetMt = 0 } = {} } = {},
    loading,
    error,
  } = useQuery(EMISSIONS, {
    fetchPolicy: 'network-only',
    variables: {
      dateGrain: 'MONTHLY',
      companyId: companyId === ALL_COMPANIES ? undefined : companyId,
      includeChildren,
      ...dateRangeFromEndDate(endDate, 12),
    },
  });
  return (
    <Emissions
      emissions={emissions}
      currentGoal={targetMt}
      includeCompanySelect
      loading={loading}
      error={error}
    />
  );
};

export default EmployeeEmissionsCard;
