import { useQuery, useMutation } from '@apollo/client';
import { Grid, Typography, Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useLocation } from 'react-router-dom';

import React, { useState } from 'react';
import {
  MEMBER_HABIT_DETAILS,
  MEMBER_LIFESTYLE_HABITS,
  ADD_HABIT,
  REMOVE_HABIT,
  MEMBER_HABITS,
} from '../../../graphql/habit/memberHabits';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import HabitCategoryChip from '../../common/Habit/HabitCategoryChip';
import MemberHabitBanner from './MemberHabitBanner';
import MemberSnackbar from './MemberSnackbar';
import useRemoveQuestionRelatedData from '../../../hooks/cacheModifiers/useRemoveQuestionRelatedData';
import useCheckForPointAwards from '../../../hooks/useCheckForPointAwards';

const useStyles = makeStyles(() => ({
  description: {
    whiteSpace: 'pre-wrap',
  },
  root: {
    marginTop: 110,
  },
  item: {
    marginBottom: 40,
  },
  button: {
    marginTop: 24,
    height: 64,
    width: '100%',
  },
}));

type Props = {
  id: string;
};
const MemberHabitDetails: React.FC<Props> = ({ id }) => {
  const classes = useStyles();
  const [performedAction, setPerformedAction] = useState(false);
  const [messages, setMessages] = useState<
    { title: string; description: string; iconUrl: string }[]
  >([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const { checkForPointAwards } = useCheckForPointAwards();
  const [actionText, setActionText] = useState('');
  useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    data: { habit } = {},
    loading,
    error,
  } = useQuery(MEMBER_HABIT_DETAILS, {
    variables: {
      id,
    },
  });

  const {
    data: { me: { lifestyleHabits = [] } = {} } = {},
    loading: lifestyleLoading,
    error: lifestyleError,
  } = useQuery(MEMBER_LIFESTYLE_HABITS);

  const { remove: removeQuestionRelatedData } = useRemoveQuestionRelatedData({
    ignoreHabits: true,
  });

  const [addHabit, { loading: addLoading, error: addError }] = useMutation(
    ADD_HABIT,
    {
      onCompleted: removeQuestionRelatedData,
    },
  );

  const [removeHabit, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_HABIT, {
      onCompleted: removeQuestionRelatedData,
    });

  const isLifestyle = lifestyleHabits.some((o) => o.id === id);

  const mutation = isLifestyle ? removeHabit : addHabit;

  const handleAction = async () => {
    await mutation({
      variables: { habitId: id },
      awaitRefetchQueries: true,
      refetchQueries: [
        MEMBER_LIFESTYLE_HABITS,
        {
          query: MEMBER_HABITS,
          variables: {
            filter: {
              orderBy: 'RECENTLY_ADDED',
            },
          },
        },
      ],
      onCompleted: async (data) => {
        const awardResult = await checkForPointAwards();

        if (data.convertHabit?.messages?.length) {
          setTimeout(
            () => {
              setMessages(data.convertHabit.messages);
              setShowSnackbar(true);
            },
            // If we showed an award toast, wait 5 seconds to show this toast
            awardResult.toastShown ? 5000 : 0,
          );
        }

        setActionText(
          `You've ${data.convertHabit ? 'added' : 'removed'} "${
            habit?.name
          }" as a sustainable lifestyle habit.`,
        );
        setPerformedAction(true);
      },
    });
  };

  const returnTo = {
    label: 'Browse Habits',
    url: '/member/myhabits',
  };

  switch (queryParams.get('returnTo')) {
    case 'myhabits':
      returnTo.label = 'View My Habits';
      returnTo.url = '/member/myhabits?tab=myhabits';
      break;

    case 'dashboard':
      returnTo.label = 'Return to Dashboard';
      returnTo.url = '/member/dashboard';
      break;

    default:
      break;
  }

  return (
    <DataStateHandler
      loading={loading || lifestyleLoading || addLoading || removeLoading}
      error={error || lifestyleError || addError || removeError}>
      {habit ? (
        <Grid
          container
          spacing={4}
          className={classes.root}
          direction="row"
          justifyContent="space-between">
          <Grid item lg={6} xs={12}>
            <Grid container direction="column">
              {performedAction ? (
                <Typography variant="h1">
                  {actionText}
                  <Grid item>
                    <Grid container spacing={2} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Link to="/member/myhabits">
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.button}>
                            Browse More Habits
                          </Button>
                        </Link>
                      </Grid>

                      <Grid item xs={6}>
                        <Link to="/member/myhabits?tab=myhabits">
                          <Button
                            className={classes.button}
                            variant="contained"
                            color="primary">
                            View My Habits
                          </Button>
                        </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
              ) : (
                <>
                  <Typography variant="h1">{habit.name}</Typography>
                  <Grid item className={classes.item}>
                    <Box component="span" mr={1}>
                      <HabitCategoryChip category={habit.category} />
                    </Box>
                  </Grid>
                  <Grid item className={classes.item}>
                    <Typography variant="body1" className={classes.description}>
                      {habit.description}
                    </Typography>
                  </Grid>
                  {habit.tips.length > 0 ? (
                    <Grid item className={classes.item}>
                      <Typography variant="subtitle1">TIPS</Typography>
                      <ul>
                        {habit.tips.map((t) => (
                          <li key={t.id}>
                            <Typography variant="body1">{t.text}</Typography>
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  ) : null}

                  <Grid
                    item
                    container
                    direction="row"
                    spacing={2}
                    className={classes.item}>
                    <Grid item xs>
                      <Link to={returnTo.url}>
                        <Button fullWidth variant="outlined" color="primary">
                          {returnTo.label}
                        </Button>
                      </Link>
                    </Grid>
                    <Grid item xs>
                      <Button
                        onClick={handleAction}
                        fullWidth
                        variant="contained"
                        color="primary">
                        {isLifestyle ? 'Remove Habit' : '+ Add Habit'}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item lg={6} xs={12}>
            <MemberHabitBanner habit={habit} />
          </Grid>
          {messages.map((message) => (
            <MemberSnackbar
              key={message.title}
              open={showSnackbar}
              snackbarContent={{
                messages: [message],
              }}
            />
          ))}
        </Grid>
      ) : null}
    </DataStateHandler>
  );
};

export default MemberHabitDetails;
