import React from 'react';
import { ResponsivePieCanvas } from '@nivo/pie';
import { Margin } from '@nivo/core';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormattedTooltip from '../ChartTooltips/FormattedTooltip';
import { NumberFormat } from '../../../utils/numberUtils';

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: '0px',
  },
  tooltip: {
    color: theme.palette.buttons.primary.active,
  },
}));
export type DonutLabel = {
  title: string | number;
  subtitle?: string;
  titleStyle?: React.CSSProperties;
  subtitleStyle?: React.CSSProperties;
  rootStyle?: React.CSSProperties;
  radius?: number;
};
export interface PieChartData {
  id: string | number;
  label: string | number;
  value: string | number;
  color: string;
}
interface Props {
  data: Array<PieChartData>;
  header?: string;
  donutLabel?: DonutLabel;
  showTooltip?: boolean;
  chartSize?: number;
  tooltipFormat?: NumberFormat;
  borderWidth?: number;
  margin?: Partial<Margin>;
  padAngle?: number;
  style?: React.CSSProperties;
}

const PieChart: React.FC<Props> = ({
  data,
  header,
  donutLabel,
  showTooltip,
  chartSize = 200,
  tooltipFormat = 'number',
  borderWidth = 1,
  padAngle = 0,
  margin = { top: 0, right: 10, bottom: 10, left: 10 },
  style,
}) => {
  const classes = useStyles();

  return (
    <div
      style={{
        position: 'relative',
        height: chartSize,
        width: chartSize,
        ...style,
      }}>
      {header && (
        <div className={classes.section}>
          <Typography variant="subtitle1" color="primary">
            {header}
          </Typography>
        </div>
      )}
      {donutLabel && (
        <div
          style={{
            position: 'absolute',
            top: chartSize / 2 - 25,
            width: chartSize,
            ...(donutLabel.rootStyle || {}),
          }}>
          <div
            style={{
              fontSize: 55,
              color: '#FFFFFF',
              textAlign: 'center',
              fontWeight: 700,
              ...(donutLabel.titleStyle || {}),
            }}>
            {donutLabel.title}
          </div>
          {donutLabel.subtitle && (
            <div
              style={{
                fontSize: 18,
                color: '#D1FFBB',
                textAlign: 'center',
                fontWeight: 700,
                marginTop: 20,
                ...(donutLabel.subtitleStyle || {}),
              }}>
              {donutLabel.subtitle}
            </div>
          )}
        </div>
      )}

      <ResponsivePieCanvas
        data={data}
        colors={data.map((d) => d.color)}
        margin={margin}
        innerRadius={donutLabel ? donutLabel.radius || 0.9 : 0}
        padAngle={padAngle}
        cornerRadius={0}
        borderWidth={borderWidth}
        borderColor={
          donutLabel
            ? '#000'
            : {
                from: 'color',
              }
        }
        tooltip={(props) => (
          <FormattedTooltip format={tooltipFormat} {...props.datum} />
        )}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        isInteractive={showTooltip}
      />
    </div>
  );
};
export default PieChart;
