import { Chip, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  white: { color: '#FFF' },
  amount: { padding: 8, backgroundColor: 'transparent', color: '#FFF' },
  pointer: { cursor: 'pointer' },
  chip: {
    background: theme.palette.primary.light,
    color: theme.palette.text.primary,
  },
}));

type Props = {
  icon?: JSX.Element;
  title: string;
  quantity: number;
  uom?: string;
  chips?: string[];
  onDelete: () => void;
  onClick?: () => void;
};

const CartItem = ({
  icon,
  title,
  quantity,
  uom,
  chips,
  onDelete,
  onClick,
}: Props) => {
  const classes = useStyles();
  return (
    <Grid container onClick={onClick} className={classes.white} spacing={1}>
      <Grid
        item
        container
        justifyContent="space-between"
        direction="row"
        wrap="nowrap"
        spacing={1}>
        <Grid item container spacing={1} direction="row" wrap="nowrap">
          {(icon && <Grid item>{icon}</Grid>) || null}
          <Grid item>
            <Typography variant="body2" color="inherit">
              {title}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          wrap="nowrap"
          justifyContent="flex-end"
          spacing={1}>
          <Grid item>
            <Typography variant="body1" color="inherit">
              {quantity} {uom}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
              variant="body1"
              color="inherit"
              className={classes.pointer}>
              &minus;
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {(chips?.length && (
        <Grid item container spacing={1}>
          {chips.map((chip) => (
            <Grid item key={chip}>
              <Chip className={classes.chip} label={chip} />
            </Grid>
          ))}
        </Grid>
      )) ||
        null}
    </Grid>
  );
};

export default CartItem;
