import React from 'react';

import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberLegalContent from './MemberLegalContent';

const MemberTermsAndConditions: React.FC = () => (
  <MemberLayout>
    <MemberLegalContent type="terms" />
  </MemberLayout>
);

export default MemberTermsAndConditions;
