import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AppInfo from '../../common/AppInfo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';

const useStyles = makeStyles(() => ({
  card: {
    padding: '16px',
    height: '100%',
  },
}));

export type Props = {
  title: string;
  data: string | number;
  subtitle: string;
  loading?: boolean;
  tooltipId?: string;
};

const OverviewCard: React.FC<Props> = ({
  title,
  data,
  subtitle,
  loading,
  tooltipId,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Typography variant="subtitle1">{title}</Typography>
            {tooltipId ? <AppInfo id={tooltipId} /> : null}
          </Grid>
        </Grid>
        <DataStateHandler loading={loading}>
          <>
            <Grid item>
              <Typography variant="h2">{data}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{subtitle}</Typography>
            </Grid>
          </>
        </DataStateHandler>
      </Grid>
    </Paper>
  );
};

export default OverviewCard;
