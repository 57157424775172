import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext, getIn, FieldArray } from 'formik';

import {
  Grid,
  Typography,
  Box,
  Dialog,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import { HabitAction, HabitListItem } from '../../types/habit/types';
import SVG from '../../assets/svg';
import HabitConditionQuestionSelectorModal from './HabitConditionQuestionSelectorModal';
import { QuestionProfile } from '../../types/question/types';
import HabitConditionHabitSelectorModal from './HabitConditionHabitSelectorModal';
import HabitConditionComponent from './HabitCondtition';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import HabitNestedContainer from './HabitNestedContainer';
import HabitDisplayQuestionAnswer from './HabitDisplayQuestionAnswer';

const useStyles = makeStyles(() => ({
  dialog: {
    width: 600,
    padding: 16,
  },
  questionRow: {
    marginBottom: 16,
  },
  field: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
}));

type Props = {
  onRemove?: () => void;
  path: string;
  questions: QuestionProfile[];
  habits: HabitListItem[];
  canEdit: boolean;
  depth?: number;
};

const HabitActionComponent: React.FC<Props> = ({
  onRemove,
  path,
  questions,
  habits,
  canEdit,
  depth = 0,
}) => {
  const { values, setFieldValue } = useFormikContext();

  const action = getIn(values, path) as HabitAction;

  const [dialogState, setDialogState] = useState<
    'CLOSED' | 'ADDING_QUESTION' | 'ADDING_HABIT'
  >('CLOSED');

  const classes = useStyles();
  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs={6}>
          <Typography variant="body2">Action Type</Typography>
          <TextField
            label=""
            fullWidth
            className={classes.field}
            onChange={(e: React.ChangeEvent<{ value: string }>) => {
              const type = e.target.value;

              if (type) {
                setFieldValue(path, { type });
              } else {
                setFieldValue(path, onRemove ? {} : undefined);
              }
            }}
            value={action?.type || ''}
            select>
            <MenuItem key="" value="">
              Select a Type
            </MenuItem>
            <MenuItem key="conditional" value="conditional">
              Conditional
            </MenuItem>
            <MenuItem key="removeHabit" value="removeHabit">
              Remove a Habit
            </MenuItem>
            <MenuItem key="removeLifestyleHabit" value="removeLifestyleHabit">
              Remove a Lifestyle Habit
            </MenuItem>
            <MenuItem key="setQuestionAnswer" value="setQuestionAnswer">
              Set a Question Answer
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="flex-end">
            <IconButton onClick={onRemove} size="large">
              <SVG.Delete />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {action?.type === 'setQuestionAnswer' ? (
            <HabitDisplayQuestionAnswer
              questionAnswer={action}
              questions={questions}
              onEdit={() => setDialogState('ADDING_QUESTION')}
            />
          ) : null}
          {action?.type === 'removeHabit' ||
          action?.type === 'removeLifestyleHabit' ? (
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              className={classes.questionRow}
              alignItems="flex-end">
              <Grid item>
                <Typography variant="body2">Habit</Typography>
                <Typography variant="body1">
                  {habits?.find((c) => c.id === action.habitId)?.name ||
                    action.habitId}
                </Typography>
              </Grid>
              <IconButton
                onClick={() => setDialogState('ADDING_HABIT')}
                size="large">
                <SVG.Edit />
              </IconButton>
            </Grid>
          ) : null}
          {action?.type === 'conditional' ? (
            <Grid
              container
              justifyContent="space-between"
              spacing={2}
              className={classes.questionRow}
              alignItems="flex-end">
              <FieldArray
                name={`${path}.conditions`}
                render={(arrayHelpers) => (
                  <>
                    <Grid item container xs={12}>
                      {action?.type === 'conditional' &&
                        action?.conditions?.map((c, index) => (
                          <HabitNestedContainer
                            title={`Action Condition ${index + 1}`}
                            depth={depth}
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}>
                            <Grid item xs={12}>
                              <HabitConditionComponent
                                path={`${path}.conditions[${index}].condition`}
                                onRemove={() => {
                                  arrayHelpers.remove(index);
                                }}
                                questions={questions}
                                habits={habits}
                                depth={depth + 1}
                                canEdit={canEdit}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body2">Actions</Typography>
                            </Grid>
                            <Grid item container xs={12}>
                              <FieldArray
                                name={`${path}.conditions[${index}].actions`}
                                render={(innerArrayHelpers) => (
                                  <>
                                    {c.actions?.map((a, innerIndex) => (
                                      <HabitNestedContainer
                                        title={`Action ${innerIndex + 1}`}
                                        depth={depth + 1}
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={index}>
                                        <Grid item xs={12}>
                                          <HabitActionComponent
                                            path={`${path}.conditions[${index}].actions[${innerIndex}]`}
                                            onRemove={() => {
                                              innerArrayHelpers.remove(
                                                innerIndex,
                                              );
                                            }}
                                            questions={questions}
                                            habits={habits}
                                            depth={depth + 2}
                                            canEdit={canEdit}
                                          />
                                        </Grid>
                                      </HabitNestedContainer>
                                    ))}
                                    <Grid item xs={12}>
                                      <SmallPaddedButton
                                        fullWidth
                                        variant="contained"
                                        onClick={() => {
                                          innerArrayHelpers.push({
                                            type: '',
                                          });
                                        }}>
                                        + Add Action
                                      </SmallPaddedButton>
                                    </Grid>
                                  </>
                                )}
                              />
                            </Grid>
                          </HabitNestedContainer>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                      <SmallPaddedButton
                        variant="contained"
                        fullWidth
                        onClick={() => {
                          arrayHelpers.push({});
                        }}>
                        + Add Condition
                      </SmallPaddedButton>
                    </Grid>
                  </>
                )}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Dialog
        onClose={() => {
          setDialogState('CLOSED');
        }}
        open={dialogState === 'ADDING_QUESTION'}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <HabitConditionQuestionSelectorModal
            questions={questions}
            path={path}
            onSave={({ questionId, answerId, comment }) => {
              const existing = getIn(values, path);
              setFieldValue(path, {
                ...existing,
                questionId,
                answerId,
                comment,
              });
              setDialogState('CLOSED');
            }}
          />
        </Box>
      </Dialog>
      <Dialog
        onClose={() => {
          setDialogState('CLOSED');
        }}
        open={dialogState === 'ADDING_HABIT'}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <HabitConditionHabitSelectorModal
            habits={habits}
            path={path}
            onSave={({ habitId }) => {
              const existing = getIn(values, path);
              setFieldValue(path, {
                ...existing,
                habitId,
              });
              setDialogState('CLOSED');
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default HabitActionComponent;
