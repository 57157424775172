import React from 'react';
import { matchPath, useParams } from 'react-router';
import { useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MemberLayout from '../../../components/layouts/MemberLayout';
import ChallengeLeaderboard from '../../../components/member/contest/ChallengeLeaderboard';
import QuarterlyLeaderboards from '../../../components/member/contest/QuarterlyLeaderboards';

type Params = {
  contestId?: string;
};

const MemberLeaderboards: React.FC = () => {
  const { contestId } = useParams<Params>();
  const { pathname } = useLocation();
  const theme = useTheme();

  return (
    <MemberLayout
      styleOverrides={
        matchPath(pathname.toLowerCase(), '/member/challenge')
          ? { backgroundColor: theme.palette.backgrounds.light }
          : undefined
      }>
      {contestId ? (
        <ChallengeLeaderboard contestId={contestId} />
      ) : (
        <QuarterlyLeaderboards />
      )}
    </MemberLayout>
  );
};

export default MemberLeaderboards;
