import { TypedDocumentNode, gql } from '@apollo/client';

export const AVERAGE_EMPLOYEE_FOOTPRINT = gql`
  query averageEmployeeFootprint(
    $dateGrain: DateGrain!
    $companyId: String!
    $startDate: String!
    $endDate: String!
    $includeChildren: Boolean
  ) {
    averageEmployeeFootprintByDateRange(
      companyId: $companyId
      parameters: {
        startDate: $startDate
        endDate: $endDate
        dateGrain: $dateGrain
        includeChildren: $includeChildren
      }
    ) {
      footprintsByRange {
        enabledEmployees
        date
        value
        waterValue
        category6
        category7
        wfh
      }
    }
  }
` as TypedDocumentNode<{
  averageEmployeeFootprintByDateRange: {
    footprintsByRange: {
      enabledEmployees: number;
      date: string;
      value: number;
      waterValue: number;
      category6: number;
      category7: number;
      wfh: number;
    }[];
  };
}>;

export default {
  AVERAGE_EMPLOYEE_FOOTPRINT,
};
