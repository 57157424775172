import React, { useState } from 'react';
import { Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import MemberLayout from '../../../components/layouts/MemberLayout';
import EmissionsBenchmarks from '../../../components/member/benchmarking/EmissionsBenchmarks';
import CarbonFootprintChart from '../../../components/member/dashboard/CarbonFootprintChart';
import MyWaterUse from '../../../components/member/dashboard/MyWaterUse';
import NetEmissionsChart from '../../../components/member/dashboard/NetEmissionsChart';
import RecentlyAddedHabits from '../../../components/member/dashboard/RecentlyAddedHabits';
import ReductionData from '../../../components/member/dashboard/ReductionData';
import ReminderCards from '../../../components/member/dashboard/ReminderCards';
import theme, {
  FullPageWidth,
  HalfPageWidth,
} from '../../../theme/memberTheme';

const MemberDashboard: React.FC = () => {
  const [chartTab, setChartTab] = useState('net');
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MemberLayout>
      <Grid container flexDirection="column" alignItems="center">
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
          }}>
          <ReminderCards />
        </Grid>
        <Grid item pb={2} container alignItems="flex-start" direction="column">
          <Typography align="left" variant="h1">
            <Trans i18nKey="journeyToZerome" />
          </Typography>
        </Grid>
        <Grid
          item
          container
          flexDirection="row"
          justifyContent={isSm ? 'center' : 'space-between'}
          flexWrap={isSm ? 'wrap' : 'nowrap'}>
          <Grid item style={{ width: HalfPageWidth, position: 'relative' }}>
            <Tabs
              value={chartTab}
              onChange={(e, v) => setChartTab(v)}
              variant={isSm ? 'scrollable' : 'standard'}>
              <Tab label={t('netEmissions')} value="net" />
              <Tab label={t('carbonFootprint')} value="carbon" />
            </Tabs>
            {chartTab === 'net' && <NetEmissionsChart />}
            {chartTab === 'carbon' && <CarbonFootprintChart />}
          </Grid>
          <Grid item style={{ width: HalfPageWidth }}>
            <ReductionData />
          </Grid>
        </Grid>
        <Grid container item>
          <EmissionsBenchmarks />
        </Grid>
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
            marginTop: isSm ? undefined : 32,
          }}>
          <MyWaterUse />
        </Grid>
        <Grid
          item
          style={{
            width: isSm ? HalfPageWidth : FullPageWidth,
            marginTop: 32,
          }}>
          <RecentlyAddedHabits />
        </Grid>
      </Grid>
    </MemberLayout>
  );
};

export default MemberDashboard;
