import React, { useContext, useState } from 'react';
import { Grid, Typography, ButtonBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ProjectContext } from '../../../contexts/ProjectContext';
import Status from '../Status';
import ConfirmCancelModal from '../ConfirmCancelModal/ConfirmCancelModal';

const useStyles = makeStyles((theme) => ({
  box: {
    padding: 16,
    marginTop: 16,
    backgroundColor: '#f9f9f9',
    text: theme.palette.secondary.dark,
    fontWeight: 600,
    borderRadius: 8,
  },
  chip: {
    background: '#FFFFFF',
    borderRadius: 8,
    padding: '4px 8px',
  },
}));

const ProjectStatus: React.FC = () => {
  const classes = useStyles();
  const { project, hideTraderFields, updateProject } =
    useContext(ProjectContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (hideTraderFields) {
    return null;
  }

  const onConfirm = () => {
    updateProject({ ...project, isActive: !project.isActive });
    setIsModalOpen(false);
  };

  const { isActive } = project;

  const message = isActive
    ? 'This Project is currently visible on the Marketplace'
    : 'This Project is not currently visible on the Marketplace';

  const modalProps = {
    message: isActive
      ? 'Deactivating this project will remove it from the marketplace. Don’t worry, you will be able to activate later if you need to.'
      : 'Reactivating this project will will make the entered quantity available for purchase in the marketplace.',
    title: isActive
      ? 'Are you sure you want to deactivate this project?'
      : 'Are you sure you want to reactivate this project?',
  };

  return (
    <>
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Project Status
        </Typography>
      </Grid>
      <ButtonBase onClick={() => setIsModalOpen(true)}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          className={classes.box}>
          <Typography align="center">{message}</Typography>
          <Grid item className={classes.chip}>
            <Status isActive={isActive} />
          </Grid>
        </Grid>
      </ButtonBase>

      <ConfirmCancelModal
        isOpen={isModalOpen}
        onConfirm={onConfirm}
        onCancel={() => setIsModalOpen(false)}
        {...modalProps}
      />
    </>
  );
};

export default ProjectStatus;
