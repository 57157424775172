import { useEffect, useState, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

type Props = {
  onLoad?: (map: mapboxgl.Map) => void;
};

export default function useMapbox({ onLoad = () => {
    // Do nothing
} }: Props = {}) {
  const [map, setMap] = useState<mapboxgl.Map>();
  const [allLayersLoaded, setAllLayersLoaded] = useState(false);

  const mapNode = useRef(null);

  useEffect(() => {
    const node = mapNode.current;

    if (typeof window === 'undefined' || node === null) {
      return () => {
        // Do nothing
      };
    }

    const mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-74.5, 40],
      zoom: 0,
      interactive: false,
    });

    mapboxMap.on('load', () => {
      onLoad(mapboxMap);

      mapboxMap.on('idle', () => {
        setAllLayersLoaded(true);
      });
    });

    setMap(mapboxMap);

    return () => {
      mapboxMap.remove();
      setMap(undefined);
    };
  }, []);

  return {
    map,
    mapNode,
    allLayersLoaded,
  };
}
