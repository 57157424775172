import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import EditProjectStepModal from './EditProjectStepModal';

const ProjectAuditInformation: React.FC = () => {
  const { project, projectCcbSdgAuditors, hideTraderFields } =
    useContext(ProjectContext);

  if (hideTraderFields) {
    return null;
  }

  const ccbSdg = projectCcbSdgAuditors.find(
    (x) => x.id === project?.ccbSdgAuditor,
  );

  return (
    <Grid item container spacing={2} direction="row">
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Audit Information
        </Typography>
        <EditProjectStepModal step={ProjectDetailsStep.audit} />
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            CCB/SDG AUDITOR
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {ccbSdg?.name || ' '}
          </Typography>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            CCB/SDG REPORT DATE
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.ccbSdgReportDate
              ? moment(project.ccbSdgReportDate).format('MMM Do, YYYY')
              : ' '}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="subtitle2" color="textSecondary">
            VCC AUDIT REPORT DATE
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.verAuditReportDate
              ? moment(project.verAuditReportDate).format('MMM Do, YYYY')
              : ' '}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProjectAuditInformation;
