import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';
import React, { useContext } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowupIcon from '@mui/icons-material/KeyboardArrowUp';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import Logout from './Logout';
import UserImage from '../User/UserImage';
import { User } from '../../../types/user/types';
import { CLOSED_DRAWER_WIDTH, OPEN_DRAWER_WIDTH } from './Sidebar';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.common.white,
  },
  textActive: {
    background: 'linear-gradient(90deg, #CDF0AB 60%, #5BB98D 100%)',
    backgroundColor: theme.palette.buttons.primary.main,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  iconActive: {
    color: theme.palette.secondary.main,
  },
  itemActive: {
    borderRight: `5px solid ${theme.palette.secondary.main}`,
  },
  name: { color: '#F1F8FD', fontSize: 14, fontWeight: 400 },
  container: {
    padding: 24,
    paddingRight: 32,
    backgroundColor: Colors.DarkGrey,
  },
  details: {
    paddingLeft: 4,
  },
  dropdown: {
    marginTop: 6,
  },
}));

type Props = {
  drawer?: boolean;
  clickHandler?(arg: number | string | undefined): void;
};

const SidebarNavigationProfileItem: React.FC<Props> = ({
  drawer,
  clickHandler = () => 0,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { user, company, userHasAccess } = useContext(AuthorizationContext);
  return (
    <Box
      position="fixed"
      bottom={0}
      className={classes.container}
      style={drawer ? {} : { paddingLeft: 12 }}
      width={drawer ? OPEN_DRAWER_WIDTH : CLOSED_DRAWER_WIDTH}
      onClick={() => {
        clickHandler('clicked');
      }}>
      <ListItem button disableGutters>
        <Grid
          container
          direction="row"
          alignItems="center"
          sx={{
            backgroundColor: Colors.DarkGrey,
          }}
          justifyContent="space-between">
          <Grid
            item
            xs={3}
            onClick={() => {
              clickHandler('clicked');
            }}>
            <UserImage user={user as User} size="md" />
          </Grid>
          {drawer && (
            <>
              <Grid item xs className={classes.details}>
                {user && (
                  <Typography
                    className={
                      classes.name
                    }>{`${user.firstName} ${user.lastName}`}</Typography>
                )}
                <Typography variant="h5" sx={{ color: Colors.ElectricGreen }}>
                  {company.name}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.dropdown}>
                {menuOpen ? (
                  <IconButton
                    onClick={() => setMenuOpen(false)}
                    style={{ color: 'white' }}
                    size="large">
                    <KeyboardArrowupIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => setMenuOpen(true)}
                    style={{ color: 'white' }}
                    size="large">
                    <KeyboardArrowDownIcon />
                  </IconButton>
                )}
              </Grid>
            </>
          )}
          {drawer && !!menuOpen && (
            <Grid container direction="column">
              <Grid item>
                <Logout />
              </Grid>
              {userHasAccess('Mobile', 'VIEW') && (
                <Grid item>
                  <Button
                    className={classes.text}
                    onClick={() => history.push('/member')}
                    variant="text">
                    Member View
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </ListItem>
    </Box>
  );
};

export default SidebarNavigationProfileItem;
