import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ProfileCard, ProfileCardType } from '../../../types/question/types';
import ProfileQuestionInput from './ProfileQuestionInput';
import theme from '../../../theme/memberTheme';

type ResponseQuestionProps = {
  profileCard: ProfileCard;
  count: number;
};

export default function ResponseQuestion({
  profileCard,
  count,
}: ResponseQuestionProps) {
  if (profileCard.cardType !== ProfileCardType.Question) {
    return null;
  }

  return (
    <Grid item container flexDirection="column">
      <Grid
        item
        style={{
          height: 40,
          width: 40,
          borderRadius: 20,
          backgroundColor: theme.palette.backgrounds.dark,
        }}>
        <Box
          style={{
            margin: 2,
            height: 36,
            width: 36,
            backgroundColor: theme.palette.background.default,
            textAlign: 'center',
            paddingTop: 4,
            borderRadius: 20,
          }}>
          <Typography variant="body1">{count}</Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="h1">
          {profileCard.question.questionText}
        </Typography>
      </Grid>
      <Grid item>
        <ProfileQuestionInput
          profileCard={profileCard}
          forceDisabled
          inputOnly
        />
      </Grid>
    </Grid>
  );
}
