import React from 'react';
import { Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  customColor: {
    color: '#6B6B6B',
  },
  value: {
    color: theme.palette.buttons.primary.active,
  },
  footer: {
    marginTop: 8,
  },
  container: {
    marginTop: 'auto',
  },
}));
export type CardFooterProps = {
  data: string | number | JSX.Element;
  label: string;
  dataStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
};

const CardFooter: React.FC<CardFooterProps> = ({
  data,
  label,
  dataStyle,
  labelStyle,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      className={classes.container}>
      <Grid item>
        <div className={classes.footer}>
          <Grid container direction="column" alignItems="flex-end">
            <Grid item xs={12}>
              <Typography
                variant="h1"
                className={classes.value}
                style={dataStyle || {}}
                color="primary">
                {data}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                className={classes.customColor}
                style={labelStyle || {}}>
                {label}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

export default CardFooter;
