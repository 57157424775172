import { Box, Grid, Button, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import client from '../../apollo/settingsClient';
import LoginForm from '../../components/login/LoginForm';
import SVG from '../../assets/svg-v2';
import ConfirmCancelModal from '../../components/common/ConfirmCancelModal/ConfirmCancelModal';
import { GET_TERMS_PUBLIC } from '../../graphql/settings';
import MemberFooter from '../../components/member/MemberFooter';
import theme from '../../theme/memberTheme';
import LoginImage from '../../assets/img-v2/login.jpg';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
}));

const Login: React.FC = () => {
  const isSm = useMediaQuery(theme.breakpoints.down('xl'));
  const classes = useStyles();

  // Use settings endpoint
  const { data: { termsPublic = [] } = {} } = useQuery(GET_TERMS_PUBLIC, {
    client,
  });

  // State for open/close of terms and conditions modal
  const [termsModalOpen, setTermsModalOpen] = useState(false);

  // div's for terms and conditions
  const allTermsFragment = termsPublic.map((t) => (
    // eslint-disable-next-line react/no-danger
    <div key={t.type} dangerouslySetInnerHTML={{ __html: t.content }} />
  ));

  const SMALL_WIDTH = 475;
  const LARGE_WIDTH = 1000;
  const PADDING = 16;

  return (
    <>
      <Grid
        container
        flexDirection="column"
        className={classes.root}
        justifyContent="space-between"
        alignItems="center"
        flexWrap="nowrap"
        style={{ width: '100%' }}>
        <Grid item style={{ width: '100%' }}>
          <Box
            style={{
              maxWidth: isSm ? SMALL_WIDTH + PADDING * 2 : LARGE_WIDTH,
              margin: 'auto',
              padding: PADDING,
            }}>
            <Grid
              item
              flexGrow={1}
              container
              direction={isSm ? 'column' : 'row'}
              justifyContent={isSm ? 'center' : 'space-between'}
              alignItems="center">
              <Grid
                item
                style={{
                  width: isSm ? undefined : LARGE_WIDTH,
                  marginRight: 'auto',
                }}>
                <Box mt={1} mb={6}>
                  <SVG.ZeroMeLogo />
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  maxWidth: isSm ? undefined : SMALL_WIDTH,
                }}>
                <LoginForm />
                <Button
                  variant="text"
                  size="small"
                  onClick={() => setTermsModalOpen(true)}>
                  Terms &amp; Conditions
                </Button>
              </Grid>
              <Grid item>
                <img src={LoginImage} alt="login" />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <MemberFooter />
        </Grid>
      </Grid>
      <ConfirmCancelModal
        isOpen={termsModalOpen}
        confirmLabel="Close"
        hideCancel
        onCancel={() => setTermsModalOpen(false)}
        onConfirm={() => setTermsModalOpen(false)}
        title="Terms &amp; Conditions"
        messageFragments={allTermsFragment}
      />
    </>
  );
};

export default Login;
