import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import React, { useMemo } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { MEMBER_HABITS } from '../../../graphql/habit/memberHabits';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import MemberHabitCard from '../myhabits/MemberHabitCard';

const RecentlyAddedHabits = () => {
  const history = useHistory();

  const publishDate = useMemo(() => new Date().toISOString(), []);

  const {
    data: { habits = [] } = {},
    loading,
    error,
  } = useQuery(MEMBER_HABITS, {
    variables: {
      filter: {
        orderBy: 'RECENTLY_ADDED',
        limit: 3,
        publishDate,
      },
    },
  });

  return (
    <Grid container flexDirection="column">
      <Grid
        item
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="h3">Recently Added Habits</Typography>
        <Button
          variant="text"
          size="small"
          onClick={() => history.push(`/member/myhabits`)}>
          View All Habits
        </Button>
      </Grid>
      <DataStateHandler loading={loading} error={error}>
        <Grid item container spacing={3} rowSpacing={1} flexDirection="row">
          {habits.map((h) => (
            <Grid item key={h.id} md={4} xs={12}>
              <MemberHabitCard
                cardType="RECENTLY_ADDED"
                habit={h}
                imageHeight={205}
                onClick={(id) =>
                  history.push(`/member/myhabits/${id}?returnTo=dashboard`)
                }
              />
            </Grid>
          ))}
        </Grid>
      </DataStateHandler>
    </Grid>
  );
};

export default RecentlyAddedHabits;
