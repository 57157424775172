/* eslint-disable react/no-array-index-key */
import { Grid, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import SVG from '../../../../assets/svg';
import {
  ProjectContext,
  ProjectContextEvents,
} from '../../../../contexts/ProjectContext';
import { getProjectDocumentList } from '../../../../utils/projectUtils';
import FileUpload from '../../../common/FileUpload/FileUpload';

type DocData = {
  name: string;
  url: string;
};

const CreateProjectDocuments: React.FC = () => {
  const { project, setTypedProjectField } = useContext(ProjectContext);
  const [localDocs, setLocalDocs] = useState<DocData[]>([]);

  const allDocuments = [...getProjectDocumentList(project), ...localDocs];

  const clearLocalDocs = () => setLocalDocs([]);

  useEffect(() => {
    document.addEventListener(ProjectContextEvents.RELOAD, clearLocalDocs);
    return () => {
      document.removeEventListener(ProjectContextEvents.RELOAD, clearLocalDocs);
    };
  }, []);

  return <>
    <Typography variant="h3">Project Documents</Typography>
    <div style={{ margin: '9px 0 18px' }}>
      <FileUpload
        accept=".pdf,.doc,.docx,.xls,.xlsx"
        allowMultiple
        uploadLabel={
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h4">Drop documents here</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" style={{ fontSize: 10 }}>
                Supports PDF, Word, Excel
              </Typography>
            </Grid>
          </Grid>
        }
        onFileChange={(fd) => {
          setTypedProjectField('documents', [
            ...(project.documents || []),
            ...fd.map((x) => ({
              file: x.file,
            })),
          ]);
          setLocalDocs([
            ...localDocs,
            ...fd.map((x) => ({
              name: x.file.name,
              url: x.imageDataUrl,
            })),
          ]);
        }}
      />
    </div>
    {allDocuments.map((f, docIndex) => (
      <Grid
        key={docIndex}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Grid item>
          <a href={f.url} target="_blank" rel="noreferrer">
            <Typography>{f.name}</Typography>
          </a>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              const uriIndex = (project.documentUris || [])
                .filter(
                  (x, i) =>
                    (project.documentIndexesToRemove || []).indexOf(i) < 0,
                )
                .indexOf(f.url);

              if (uriIndex >= 0) {
                setTypedProjectField('documentIndexesToRemove', [
                  ...(project.documentIndexesToRemove || []),
                  uriIndex,
                ]);
              } else {
                const localIndex = localDocs.findIndex(
                  (x) => x.url === f.url,
                );

                setTypedProjectField(
                  'documents',
                  (project.documents || []).filter(
                    (x, i) => i !== localIndex,
                  ),
                );
                setLocalDocs(localDocs.filter((x, i) => i !== localIndex));
              }
            }}
            size="large">
            <SVG.Delete />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </>;
};

export default CreateProjectDocuments;
