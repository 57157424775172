import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import ProjectProvider, {
  ProjectContext,
  ProjectDetailsStep,
  ProjectStep,
} from '../../../contexts/ProjectContext';
import { Project } from '../../../types/project/types';
import SmallPaddedButton from '../Buttons/SmallPaddedButton';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: 16,
  },
  editButton: {
    fontSize: 12,
    color: theme.palette.primary.main,
    lineHeight: '16px',
  },
}));

type Props = {
  step: ProjectDetailsStep | 'ASSIGN';
  buttonStyle?: 'TEXT' | 'ICON';
  width?: number;
  height?: number;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

type InnerProps = Omit<Props, 'project'> & {
  open: boolean;
  setOpen: (open: boolean) => void;
  onUpdate: (project: Project) => void;
};

const EditProjectStepModalInner: React.FC<InnerProps> = ({
  open,
  setOpen,
  onUpdate,
  buttonStyle = 'TEXT',
  step,
  width = 600,
  height,
  maxWidth = 'sm',
}) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { project, projectDetailsSteps, forceStepValidation, goToStep, steps } =
    useContext(ProjectContext);
  const detailsStep = projectDetailsSteps.find((x) => x.step === step);
  const assignStep =
    (step === 'ASSIGN' && steps[ProjectStep.assign]) || undefined;
  const StepComponent = detailsStep?.stepComponent || assignStep?.stepComponent;

  useEffect(() => {
    // ForceStepValidation requires the appropriate step to be selected in the context.
    if (detailsStep) {
      goToStep(ProjectStep.details);
    } else {
      goToStep(ProjectStep.assign);
    }
    forceStepValidation();
  }, [detailsStep]);

  const valid =
    (detailsStep && detailsStep.valid) ||
    (assignStep && assignStep.valid(project));

  return <>
    {userHasAccess('ZeroMe.Marketplace', 'EDIT') && (
      <>
        {buttonStyle === 'TEXT' && (
          <Button
            onClick={() => setOpen(true)}
            className={classes.editButton}>
            Edit
          </Button>
        )}
        {buttonStyle === 'ICON' && (
          <IconButton onClick={() => setOpen(true)} size="large">
            <SVG.Edit />
          </IconButton>
        )}
      </>
    )}
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={maxWidth}>
      <Box className={classes.dialog} style={{ width, height }}>
        {StepComponent && <StepComponent />}
      </Box>
      <DialogActions>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <SmallPaddedButton
              onClick={() => setOpen(false)}
              variant="outlined"
              fullWidth
              color="primary">
              Cancel
            </SmallPaddedButton>
          </Grid>
          <Grid item xs>
            <SmallPaddedButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={!valid}
              onClick={() => {
                setOpen(false);
                onUpdate(project);
              }}>
              Save
            </SmallPaddedButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  </>;
};
const EditProjectStepModalProviderWrapper: React.FC<Props> = (props) => {
  const { project, editable, updateProject } = React.useContext(ProjectContext);
  const [open, setOpen] = useState(false);

  return editable ? (
    <ProjectProvider initialProject={open ? project : undefined} editable>
      <EditProjectStepModalInner
        open={open}
        setOpen={setOpen}
        onUpdate={(newProject) => updateProject(newProject)}
        {...props}
      />
    </ProjectProvider>
  ) : null;
};

const EditProjectStepModal = EditProjectStepModalProviderWrapper;

export default EditProjectStepModal;
