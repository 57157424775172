import { gql, TypedDocumentNode } from '@apollo/client';
import { Badge, BadgeCategory } from '../../types/badge/types';

const BADGE_FIELDS = `
  badgeId
  level
  earnedOn
  title
  subtitle
  description
  levelUpTitle
  levelUpSubtitle
  category
`;

const BADGE_CATEGORY_FIELDS = `
  id
  category
  title
  subtitle
`;

export const GET_USER_BADGES = gql`
  query GET_USER_BADGES {
    userBadges {
      ${BADGE_FIELDS}
    }
  }
` as TypedDocumentNode<{ userBadges: Badge[] }>;

export const GET_BADGE_CATEGORIES = gql`
  query GET_BADGE_CATEGORIES {
    getBadgeCategories {
      ${BADGE_CATEGORY_FIELDS}
    }
  }
` as TypedDocumentNode<{ getBadgeCategories: BadgeCategory[] }>;
