import { LngLatBoundsLike } from 'mapbox-gl';
import { useEffect } from 'react';
import { countryBoundingBoxes } from '../utils/countryData';
import useMapbox from './useMapbox';

type Props = {
  country?: string;
};

export default function useMapboxHighlightCountry({ country }: Props) {
  const { map, mapNode, allLayersLoaded } = useMapbox({
    onLoad: (m) => {
      m.addLayer(
        {
          id: 'country-boundaries',
          source: {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
          },
          'source-layer': 'country_boundaries',
          type: 'fill',
          paint: {
            'fill-color': '#d2361e',
            'fill-opacity': 0.4,
          },
        },
        'country-label',
      );

      m.setFilter('country-boundaries', ['in', 'iso_3166_1', 'none']);
    },
  });

  useEffect(() => {
    if (map && allLayersLoaded && country) {
      map.setFilter('country-boundaries', ['in', 'iso_3166_1', country]);

      // This code sorta works, but it has to start on a fully zoomed out map, and it pulls in too much (hawaii/alaska for US for example)
      // Since we dont' have clear business requirements here, I'm just using some hard coded bounding boxes I found on github for now.
      /* const features = map.querySourceFeatures('country-boundaries', {
        sourceLayer: 'country_boundaries',
        filter: ['in', 'iso_3166_1', project.country],
      });

      const bbox = features.reduce(
        (acc, cur) => {
          if (cur.geometry.type === 'MultiPolygon') {
            for (let i = 0; i < cur.geometry.coordinates.length; i += 1) {
              for (let j = 0; j < cur.geometry.coordinates[i].length; j += 1) {
                for (
                  let k = 0;
                  k < cur.geometry.coordinates[i][j].length;
                  k += 1
                ) {
                  const coords = cur.geometry.coordinates[i][j][k];
                  if (coords[0] < acc[0][0]) {
                    [acc[0][0]] = coords;
                  }
                  if (coords[0] > acc[1][0]) {
                    [acc[1][0]] = coords;
                  }
                  if (coords[1] < acc[0][1]) {
                    [, acc[0][1]] = coords;
                  }
                  if (coords[1] > acc[1][1]) {
                    [, acc[1][1]] = coords;
                  }
                }
              }
            }
          }

          return acc;
        },
        [
          [200, 200],
          [-200, -200],
        ],
      ); */

      const bbox = countryBoundingBoxes[country];

      if (bbox) {
        map.fitBounds(bbox[1] as LngLatBoundsLike);
      }
    }
  }, [country, map, allLayersLoaded]);

  return mapNode;
}
