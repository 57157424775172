import React from 'react';
import { FieldProps, getIn } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import moment, { Moment } from 'moment';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

type Props = {
  parseFn?: (fieldValue: string) => Date | null;
  serializeFn?: (m: Moment) => string;
};

const AppDatePicker: React.FC<
  Props &
    FieldProps &
    TextFieldProps &
    DatePickerProps<Date, Date> & { onChange: (dt: string) => void }
> = ({
  field,
  form,
  error,
  parseFn = (fieldValue) => (fieldValue ? moment(fieldValue).toDate() : null),
  serializeFn = (m) => m.toISOString().substring(0, 10),
  minDate,
  maxDate,
  disableFuture,
  helperText,
  disabled,
  onChange: onChangeOverride,
  ...rest
}) => {
  const classes = useStyles();
  const dateValue = parseFn(field.value);
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const onChange = (date: Date | null) => {
    if (field.onChange) {
      field.onChange(serializeFn(moment(date)));
    }
    if (onChangeOverride) {
      onChangeOverride(serializeFn(moment(date)));
    }
    form.setFieldTouched(field.name);

    form.setFieldValue(field.name, date ? serializeFn(moment(date)) : null);
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <DatePicker
        className={classes.root}
        minDate={minDate}
        maxDate={maxDate}
        disableFuture={disableFuture}
        disabled={disabled}
        renderInput={(props) => (
          <TextField
            onKeyDown={(e) => e.preventDefault()}
            helperText={
              helperText ??
              ((isTouched || dateValue) && errorMessage
                ? errorMessage
                : undefined)
            }
            {...field}
            {...rest} // includes any Material-UI specific props
            {...props}
            error={error ?? Boolean((isTouched || dateValue) && errorMessage)}
          />
        )}
        value={dateValue}
        onChange={onChange}
      />
    </div>
  );
};

export default AppDatePicker;
