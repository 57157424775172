import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Chip, IconButton, Typography, Drawer, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import EmptyProjects from '../../../pages/marketplace/EmptyProjects';
import {
  ProjectPurchaseHistory,
  ProjectPurchaseStatus,
} from '../../../types/offsetProject/types';
import { ProjectCategory } from '../../../types/project/types';
import NumberUtils from '../../../utils/numberUtils';
import {
  projectCategoryFilterLabel,
  projectCategorySearchFunction,
} from '../../../utils/projectUtils';
import RoundedFlexBox from '../../trader/RoundedFlexBox';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../Project/CategoryIconSmall';
import AlphaColumnFilter from '../Table/AlphaColumnFilter';
import GroupableSelectTable from '../Table/GroupableSelectTable';
import { GroupableTableStructure } from '../Table/types';
import SVG from '../../../assets/svg';
import RequestOffsetInventoryChange from '../marketplace/RequestOffsetInventoryChange';
import {
  AuthorizationContext,
  UserHasAccessFn,
} from '../../../contexts/AuthorizationContext';

type TableData = ProjectPurchaseHistory & {
  totalPurchased: number;
  totalSpent: number;
};

type Props = {
  tableData?: TableData[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  tableGrid: {
    height: '100%',
  },
  name: {
    width: 250,
  },
  chipPending: {
    backgroundColor: '#F1F8FD',
    color: '#3E7DA2',
  },
  chipComplete: {
    backgroundColor: '#E7F1EA',
    color: '#398360',
  },
  chipCancelled: {
    backgroundColor: '#FFDDE4',
    color: '#D33050',
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const useStatusMap = (): Record<
  ProjectPurchaseHistory['status'],
  JSX.Element
> => {
  const classes = useStyles();
  return {
    COMPLETE: <Chip label="Completed" className={classes.chipComplete} />,
    REQUESTED: <Chip label="Requested" className={classes.chipPending} />,
    APPROVED: <Chip label="Pending" className={classes.chipPending} />,
    CANCELLED: <Chip label="Cancelled" className={classes.chipCancelled} />,
  };
};

const useTableStructure = (
  projectCategories: ProjectCategory[],
  setSelectedProject: (project: TableData) => void,
  userHasAccess: UserHasAccessFn,
): GroupableTableStructure<TableData>[] => {
  const classes = useStyles();
  const statusMap = useStatusMap();
  return [
    {
      key: 'main',
      columns: [
        {
          key: 'projectCategoryId',
          display: 'Cat',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          searchFunction: projectCategorySearchFunction(projectCategories),
          resolveFilterLabel: projectCategoryFilterLabel(projectCategories),
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
        },
        {
          key: 'project.projectId',
          display: 'ID',
          render: ({ project: { projectId } }) => (
            <Typography variant="body2">{projectId}</Typography>
          ),
        },
        {
          key: 'projectTitle',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Project Name',
          render: ({ project: { title } }) => (
            <Typography noWrap variant="body2" className={classes.name}>
              {title}
            </Typography>
          ),
        },
        {
          key: 'price',
          display: 'Purchase Price',
          format: (data) =>
            data ? NumberUtils.format(data as number, 'currency2') : '',
        },
        {
          key: 'totalSpent',
          display: 'Total Spent',
          resolveFilterLabel: (data) =>
            data ? NumberUtils.format(data as number, 'currency2') : '',
          format: (data) =>
            data ? NumberUtils.format(data as number, 'currency2') : '',
        },
        {
          key: 'creditUsed',
          display: 'Credit Used',
          render: ({ totalSpent, invoicedAmount }) => (
            <Typography noWrap variant="body2">
              {NumberUtils.format(
                totalSpent - (invoicedAmount || 0),
                'currency2',
              )}
            </Typography>
          ),
        },
        {
          key: 'invoicedAmount',
          display: 'Invoiced Amount',
          resolveFilterLabel: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
          format: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
        },
        {
          key: 'displayTotalPurchased',
          display: 'Total Purchased',
        },
        {
          key: 'displayRemaining',
          display: 'Remaining',
        },
        {
          key: 'displayAllocated',
          display: 'Allocated',
        },
        {
          key: 'status',
          display: 'Status',
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Status',
          resolveFilterLabel: (value) =>
            statusMap[value as TableData['status']],
          render: ({ status }) => statusMap[status],
        },
        {
          key: 'actions',
          render: (data) =>
            data.status === ProjectPurchaseStatus.REQUESTED &&
            userHasAccess('Client.Marketplace.ContactTrader', 'EDIT') ? (
              <IconButton onClick={() => setSelectedProject(data)}>
                <SVG.Edit />
              </IconButton>
            ) : null,
        },
      ],
    },
  ];
};

const ProjectPurchaseTable = ({ tableData }: Props) => {
  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const { userHasAccess } = useContext(AuthorizationContext);

  const [selectedProject, setSelectedProject] = useState<TableData | undefined>(
    undefined,
  );

  const tableStructure = useTableStructure(
    projectCategories,
    setSelectedProject,
    userHasAccess,
  );

  const tableDataItems = (tableData || []).map((x) => ({
    ...x,
    projectTitle: x.project.title,
    displayTotalPurchased: NumberUtils.formatQuantityAndUom(
      x.totalPurchased,
      x.uom,
      x.project.uom,
    ),
    displayRemaining: NumberUtils.formatQuantityAndUom(
      x.remaining,
      x.uom,
      x.project.uom,
    ),
    displayAllocated: NumberUtils.formatQuantityAndUom(
      x.allocated,
      x.uom,
      x.project.uom,
    ),
  }));

  const classes = useStyles();

  return (
    <DataStateHandler
      loading={!tableData || loadingCategories}
      error={errorCategories}>
      <>
        <RoundedFlexBox>
          {(!tableData?.length && (
            <EmptyProjects
              message="Make your first purchase!"
              showBrowseMarketplaceButton
            />
          )) || (
            <GroupableSelectTable<TableData>
              tableData={tableDataItems}
              tableStructure={tableStructure}
              queryVariables={{ 'sort-list': projectCategories }}
              hideStickyCol
            />
          )}
        </RoundedFlexBox>
        <Drawer
          open={!!selectedProject}
          onClose={() => setSelectedProject(undefined)}
          anchor="right">
          <Box className={classes.drawer}>
            {selectedProject && (
              <RequestOffsetInventoryChange
                projectHistory={selectedProject}
                onClose={() => setSelectedProject(undefined)}
              />
            )}
          </Box>
        </Drawer>
      </>
    </DataStateHandler>
  );
};

export default ProjectPurchaseTable;
