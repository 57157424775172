import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { CategoryInterest } from '../../../../types/dashboard/types';
import NumberUtils from '../../../../utils/numberUtils';

type Props = {
  category: CategoryInterest;
};

const useStyles = makeStyles(() => ({
  root: {
    margin: '6px 0px',
  },
  title: {
    alignItems: 'center',
  },
  progress: {
    borderRadius: 8,
    height: 8,
  },
  icon: {
    '& svg': {
      height: 40,
      width: 40,
      fill: '#0A3751',
    },
  },
}));

const ProfileCompletionItem: React.FC<Props> = ({ category }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid className={classes.title} container justifyContent="space-between">
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <ReactSVG
              src={category.iconUri}
              className={classes.icon}
              alt={category.label}
            />
            <Typography variant="body2">{category.label}</Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {NumberUtils.format(category.percent || 0, 'wholePercent')}
          </Typography>
        </Grid>
      </Grid>
      <LinearProgress
        className={classes.progress}
        variant="determinate"
        value={category.percent * 100}
      />
    </Box>
  );
};

export default ProfileCompletionItem;
