import React from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import SVG from '../../../../assets/svg';
import { CompanyActionItem } from '../../../../types/company/types';
import UserImage from '../../../common/User/UserImage';
import CompanyLogo from '../../../common/Company/CompanyLogo';
import { fancyMoment } from '../../../../utils/dateUtils';
import { ContactType } from '../../../../types/user/types';
import UserDataStatusChip from '../../../common/UserDataStatusChip';

const useStyles = makeStyles(() => ({
  companyLogo: {
    height: 24,
    width: 24,
  },
  logo: {
    position: 'relative',
    marginRight: 16,
  },
  date: {
    color: '#6b6b6b',
    marginRight: 8,
  },
  chipText: {
    fontSize: 14,
  },
}));

type Props = {
  action: CompanyActionItem;
};

const labels = {
  [ContactType.EXPORT_DATA_REQUEST]: 'Export all data',
  [ContactType.DELETE_ACCOUNT_REQUEST]: 'Delete their account',
};

const ClientActionDataRequestRow: React.FC<Props> = ({ action }) => {
  const {
    date,
    data: { company, user, dataRequest },
  } = action;
  const classes = useStyles();
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container>
          <Grid item className={classes.logo}>
            <UserImage size="md" user={user} />
            <CompanyLogo
              logoUris={company?.logoUris}
              className={classes.companyLogo}
              style={{ position: 'absolute', bottom: 0, right: 0 }}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Typography variant="body2">
                {user.name} from {company.name} is requesing to{' '}
                <strong>{labels[dataRequest.type]}</strong>
              </Typography>
              <Grid item>
                <Grid container alignItems="center">
                  <Typography variant="body2" className={classes.date}>
                    {fancyMoment(date)}
                  </Typography>
                  <Grid item>
                    <Grid container>
                      <UserDataStatusChip
                        fulfilledDate={dataRequest.fulfilledDate}
                        textClassname={classes.chipText}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SVG.CaretRightSmall />
    </Grid>
  );
};

export default ClientActionDataRequestRow;
