import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React from 'react';
import { Disableable } from '../../../types/common';
import AppTextField from '../../common/AppTextField';

type Props = Disableable & {
  label?: string;
  placeholder?: string;
};

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.typography.h1,
  },
}));

const HeadingInput: React.FC<Props> = ({
  label = 'Heading',
  placeholder = 'Type your heading here',
  disabled,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Field
        name="title"
        component={AppTextField}
        disabled={disabled}
        label=""
        InputProps={{ className: classes.input }}
        placeholder={placeholder}
        fullWidth
      />
    </>
  );
};

export default HeadingInput;
