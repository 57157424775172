import { Box, Button, Dialog, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import React from 'react';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  errorList: {
    margin: 0,
    paddingInlineStart: 20,
  },
}));

type Props = {
  amount?: number;
  error?: boolean;
  onClose?: () => void;
  open?: boolean;
  loading?: boolean;
};

const AllocationAppliedDialog: React.FC<Props> = ({
  amount,
  error: passedInError,
  onClose,
  open,
  loading,
}) => {
  const classes = useStyles();

  const success = (
    <Grid container direction="column" alignItems="stretch" spacing={5}>
      <Grid item>
        <Typography variant="h1">Your allocation has been applied!</Typography>
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography style={{ fontSize: '5em', lineHeight: '1em' }}>
            {amount}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h3">MT C0₂E</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={onClose} fullWidth>
          Done
        </Button>
      </Grid>
    </Grid>
  );

  const error = (
    <Grid container direction="column" alignItems="stretch" spacing={2}>
      <Grid
        item
        container
        className={classes.error}
        direction="column"
        alignItems="center"
        spacing={2}>
        <Grid item>
          <WarningRoundedIcon fontSize="large" />
        </Grid>
        <Grid item>
          <Typography variant="h1" color="inherit">
            Something went wrong!
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center">
          We were unable to complete your allocations request. Please try again
          or contact our customer support.
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2">Common issues to look for:</Typography>
        <ul className={classes.errorList}>
          <li>Make sure the dates you have selected do not overlap</li>
        </ul>
      </Grid>
      <Grid item>
        <Button color="primary" variant="contained" onClick={onClose} fullWidth>
          Go Back
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Dialog open={!!open}>
      <Box p={5}>
        <DataStateHandler loading={loading}>
          {passedInError ? error : success}
        </DataStateHandler>
      </Box>
    </Dialog>
  );
};

export default AllocationAppliedDialog;
