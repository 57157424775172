import { gql, TypedDocumentNode } from '@apollo/client';
import {
  BehaviorCreditsData,
  CarbonFootprint,
  UserWaterFootprintItem,
  WaterFootprintItem,
} from '../../../types/dashboard/types';
import { EmissionsCalcResult } from '../../../types/offsetProject/types';

export const EMISSIONS = gql`
  query emissions(
    $dateGrain: DateGrain!
    $companyId: String
    $startDate: String
    $endDate: String
    $includeChildren: Boolean
  ) {
    emissions(
      companyId: $companyId
      parameters: {
        startDate: $startDate
        endDate: $endDate
        dateGrain: $dateGrain
        includeChildren: $includeChildren
      }
    ) {
      totalFootprint {
        date
        value
        offsetValue
        category6
        category7
        wfh
        category6OffsetValue
        category7OffsetValue
        wfhOffsetValue
      }
      categories {
        id
        label
        purposeTypes {
          id
          label
        }
        data {
          id
          label
          color
          data {
            date
            value
            purposeType
          }
        }
      }
    }
  }
`;

export const WATER_FOOTPRINT = gql`
  query waterFootprint(
    $companyId: String!
    $date: String
    $includeChildren: Boolean
  ) {
    waterFootprint(
      companyId: $companyId
      includeChildren: $includeChildren
      date: $date
      dateGrain: MONTHLY
    ) {
      id
      value
      color
    }
  }
`;

export const BEHAVIOR_CREDITS_BY_COMPANY = gql`
  query behaviorCreditsByCompany(
    $companyId: String
    $parameters: DateRangeQueryParameters!
  ) {
    behaviorCreditsByCompany(companyId: $companyId, parameters: $parameters) {
      groupId
      label
      graphForeColor
      graphBackgroundColor
      purposeTypes {
        id
        label
      }
      data {
        value
        purposeType
        date
      }
    }
  }
` as TypedDocumentNode<{
  behaviorCreditsByCompany: BehaviorCreditsData[];
}>;

export const EMISSIONS_BENCHMARKS = gql`
  query emissionsBenchmarks($country: String!) {
    averageCarbonFootprintForGlobe {
      total
      categoryBreakdown {
        type
        emissions
      }
    }
    averageCarbonFootprintForCountry(country: $country) {
      total
      categoryBreakdown {
        type
        emissions
      }
    }
  }
` as TypedDocumentNode<{
  averageCarbonFootprintForGlobe: CarbonFootprint;
  averageCarbonFootprintForCountry: CarbonFootprint;
}>;

export const WATER_COMPARISON = gql`
  query waterComparison(
    $userId: String!
    $companyId: String!
    $startYYYYWW: Int!
    $endYYYYWW: Int!
    $date: String!
    $country: String!
  ) {
    userEmissionsWeekly(
      userId: $userId
      startYYYYWW: $startYYYYWW
      endYYYYWW: $endYYYYWW
    ) {
      totals {
        totalWaterEmissions
      }
      details {
        year
        week
        waterTotals {
          purposeType
          type
          subType
          liters
          purposeTypeLabel
          typeLabel
          subTypeLabel
          graphColor
          typeGraphColor
        }
      }
    }
    waterFootprint(
      companyId: $companyId
      date: $date
      includeChildren: false
      employeeAverage: true
      dateGrain: WEEKLY
    ) {
      id
      color
      value
    }
    averageWaterFootprintForCountry(country: $country) {
      id
      color
      value
    }
  }
` as TypedDocumentNode<{
  userEmissionsWeekly: {
    totals: {
      totalWaterEmissions: number;
    }[];
    details: {
      year: number;
      week: number;
      waterTotals: UserWaterFootprintItem[];
    }[];
  };
  waterFootprint: WaterFootprintItem[];
  averageWaterFootprintForCountry: WaterFootprintItem[];
}>;

export const GET_COMPANY_EMISSIONS = gql`
  query GET_COMPANY_EMISSIONS(
    $companyId: String!
    $startYYYYMM: Int!
    $endYYYYMM: Int!
    $countryCode: String
    $regionCode: String
    $postalCode: String
    $includeFamilyTree: Boolean
  ) {
    emissionsCalculations(
      companyId: $companyId
      startYYYYMM: $startYYYYMM
      endYYYYMM: $endYYYYMM
      countryCode: $countryCode
      regionCode: $regionCode
      postalCode: $postalCode
      includeFamilyTree: $includeFamilyTree
    ) {
      emissionsTotals {
        typeLabel
        subTypeLabel
        graphColor
        typeGraphColor
        emissions
      }
      avgAnnualEmissions {
        avgTotalEmissions
        avgTotalHabitCredits
        avgFinalEmissions
        avgEmissionBreakdown {
          calculationType
          type
          avgEmissions
        }
      }
    }
  }
` as TypedDocumentNode<
  {
    emissionsCalculations: EmissionsCalcResult;
  },
  {
    companyId: string;
    startYYYYMM: number;
    endYYYYMM: number;
    countryCode?: string;
    regionCode?: string;
    postalCode?: string;
    includeFamilyTree?: boolean;
  }
>;
