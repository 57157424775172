import React from 'react';
import { Box } from '@mui/material';

import SVG from '../../assets/svg';
import { CommunicationType } from '../communications/types';

type Props = {
  communicationType: string;
};
const CommunicationTypeIcon: React.FC<Props> = ({ communicationType }) => {
  let icon = null;
  switch (communicationType) {
    case CommunicationType.AUDIO:
      icon = <SVG.Communications.Audio />;
      break;
    case CommunicationType.BLOG_POST:
      icon = <SVG.Communications.Blog />;
      break;
    case CommunicationType.HINT:
      icon = <SVG.Communications.Hint />;
      break;
    case CommunicationType.VIDEO:
      icon = <SVG.Communications.Video />;
      break;
    default:
      break;
  }
  return (
    <Box
      width="24px"
      height="24px"
      bgcolor="#F1F8FD"
      display="flex"
      borderRadius="4px"
      justifyContent="center"
      alignItems="center">
      {icon}
    </Box>
  );
};

export default CommunicationTypeIcon;
