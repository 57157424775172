import questionsCompletedResidentialDisabled from './QuestionsCompletedResidentialDisabled';
import questionsCompletedResidentialEnabled from './QuestionsCompletedResidentialEnabled';

const questionsCompletedResidential = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? questionsCompletedResidentialDisabled(level, size)
    : questionsCompletedResidentialEnabled(level, size);

export default questionsCompletedResidential;
