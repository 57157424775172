import { createTheme } from '@mui/material/styles';

import shared from './shared';

// export chart palette separate from theme
// since this will usually be used outside
// of a useStyles hook and we don't need
// to import the whole theme to get colors
// most chart colors come from the db but not
// all of them
export const chartColors = {
  blue: '#3E7DA2',
  darkBlue: '#0A3751',
  darkGreenBlue: '#224E56',
  lightBlue: '#7FC1D2',
  lightGreenBlue: '#7DCBBC',
  green: '#BDD9C5',
  darkGreen: '#398360',
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1408,
      xl: 1536,
    },
  },
  ...shared,
});

export default theme;
