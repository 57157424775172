import { gql, TypedDocumentNode } from '@apollo/client';
import {
  ProjectAuditor,
  ProjectCcbSdgAuditor,
  ProjectIndicator,
  ProjectSource,
  ProjectSponsor,
  ProjectUom,
} from '../../types/project/types';

const RAW_GET_ALL_PROJECT_AUDITORS = `
    projectAuditors (language: "en") {
      id
      name
    }
`;
export const GET_ALL_PROJECT_AUDITORS =
  gql`query { ${RAW_GET_ALL_PROJECT_AUDITORS} }` as TypedDocumentNode<{
    projectAuditors: ProjectAuditor[];
  }>;

const RAW_GET_ALL_PROJECT_CCB_SDG_AUDITORS = `
    projectCcbSdgAuditors (language: "en") {
      id
      name
      hqCountryCode
    }
`;
export const GET_ALL_PROJECT_CCB_SDG_AUDITORS =
  gql`query { ${RAW_GET_ALL_PROJECT_CCB_SDG_AUDITORS} }` as TypedDocumentNode<{
    projectCcbSdgAuditors: ProjectCcbSdgAuditor[];
  }>;

const RAW_GET_ALL_PROJECT_INDICATORS = `
    projectIndicators (language: "en") {
      id
      name
    }
`;
export const GET_ALL_PROJECT_INDICATORS =
  gql`query { ${RAW_GET_ALL_PROJECT_INDICATORS} }` as TypedDocumentNode<{
    projectIndicators: ProjectIndicator[];
  }>;

const RAW_GET_ALL_PROJECT_SOURCES = `
    projectSources (language: "en") {
      id
      name
      uoms
    }
`;
export const GET_ALL_PROJECT_SOURCES =
  gql`query { ${RAW_GET_ALL_PROJECT_SOURCES} }` as TypedDocumentNode<{
    projectSources: ProjectSource[];
  }>;

const RAW_GET_ALL_PROJECT_SPONSORS = `
    projectSponsors (language: "en") {
      id
      name
    }
`;
export const GET_ALL_PROJECT_SPONSORS =
  gql`query { ${RAW_GET_ALL_PROJECT_SPONSORS} }` as TypedDocumentNode<{
    projectSponsors: ProjectSponsor[];
  }>;

const RAW_GET_ALL_PROJECT_UOMS = `
    projectUoms (language: "en") {
      id
      name
    }
`;
export const GET_ALL_PROJECT_UOMS =
  gql`query { ${RAW_GET_ALL_PROJECT_UOMS} }` as TypedDocumentNode<{
    projectSponsors: ProjectUom[];
  }>;

export const GET_ALL_PROJECT_ENUMS = gql`
  query {
    ${RAW_GET_ALL_PROJECT_AUDITORS}
    ${RAW_GET_ALL_PROJECT_CCB_SDG_AUDITORS}
    ${RAW_GET_ALL_PROJECT_INDICATORS}
    ${RAW_GET_ALL_PROJECT_SOURCES}
    ${RAW_GET_ALL_PROJECT_SPONSORS}
    ${RAW_GET_ALL_PROJECT_UOMS}
  }
` as TypedDocumentNode<{
  projectAuditors: ProjectAuditor[];
  projectCcbSdgAuditors: ProjectCcbSdgAuditor[];
  projectIndicators: ProjectIndicator[];
  projectSources: ProjectSource[];
  projectSponsors: ProjectSponsor[];
  projectUoms: ProjectUom[];
}>;
