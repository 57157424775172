import { useQuery } from '@apollo/client';
import { Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { GET_PROJECTED_EMISSIONS } from '../../../graphql/user/user';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import PieChart from '../../common/PieChart/PieChart';
import AppInfo from '../../common/AppInfo';

const CarbonFootprintChart: React.FC = () => {
  const { user, company } = useContext(AuthorizationContext);
  const [selectedType, setSelectedType] = useState('total');
  const [purposeType, setPurposeType] = useState('all');
  const [allTypes, setAllTypes] = useState<
    {
      id: string;
      label: string;
    }[]
  >();
  const skip = !user?.id || !company.id;

  const {
    loading,
    error,
    data: { projectedEmissions } = {},
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: company.id,
      type: selectedType === 'total' ? undefined : selectedType,
      purposeType: purposeType === 'all' ? undefined : purposeType,
    },
    skip,
  });

  if (projectedEmissions?.categories && !allTypes) {
    setAllTypes(
      projectedEmissions.categories.map((x) => ({
        id: x.type,
        label: x.label,
      })),
    );
  }

  let footprintData = (projectedEmissions?.categories || []).map((x) => ({
    id: `${x.type}_${x.subType}`,
    label: x.label,
    value: x.percentOfTotal,
    color: x.color,
  }));

  // Hack to handle issue in food & diet where there's habits removing emissions
  // but we don't know what food type to assign the emissions to
  if (selectedType !== 'total' && footprintData.length > 1) {
    for (const nullData of footprintData.filter((x) =>
      x.id.endsWith('_null'),
    )) {
      if (footprintData.length > 1) {
        footprintData = footprintData.filter((x) => x !== nullData);
        footprintData[0].value += nullData.value;
      }
    }
  }

  return (
    <DataStateHandler loading={loading || skip} error={error}>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ width: 340, margin: 'auto' }}>
        <div style={{ position: 'absolute', right: 0, top: 60 }}>
          <AppInfo id="carbon_footprint_breakdown_chart" />
        </div>
        <Grid item container direction="row" spacing={2} pt={3} pb={3}>
          <Grid xs={6} item>
            <Select
              value={selectedType}
              sx={{
                fontSize: 12,
              }}
              style={{ width: '100%' }}
              onChange={(e) => {
                setSelectedType(e.target.value);
                setPurposeType('all');
              }}>
              <MenuItem value="total">Total</MenuItem>
              {allTypes?.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  {cat.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid xs={6} item>
            <Select
              value={purposeType}
              disabled={selectedType === 'total'}
              style={{ width: '100%' }}
              sx={{
                fontSize: 12,
              }}
              onChange={(e) => setPurposeType(e.target.value)}>
              <MenuItem value="all">All</MenuItem>
              {(projectedEmissions?.purposeTypes?.length || 0) > 1 &&
                projectedEmissions?.purposeTypes?.map((p) => (
                  <MenuItem key={p.id} value={p.id}>
                    {p.label}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
        <Grid item style={{ position: 'relative' }}>
          <PieChart
            chartSize={215}
            padAngle={1}
            donutLabel={{
              radius: 0.85,
              title: '',
              subtitle: '',
            }}
            borderWidth={0}
            data={footprintData}
          />
          <Grid
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            style={{ position: 'absolute', top: 0, width: 215, height: 215 }}>
            <Typography variant="h1" style={{ fontSize: 56 }}>
              {new Intl.NumberFormat('en-us', {
                maximumFractionDigits: 1,
              }).format((projectedEmissions?.currentFootprint || 0) / 1000)}
            </Typography>
            <Typography variant="h3">
              MT CO<sub>2</sub>E
            </Typography>
            <Typography variant="h3" style={{ color: '#A9A9A9', fontSize: 14 }}>
              Annual Projection
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={1}
          alignContent="flex-start">
          {footprintData.map((x) => (
            <Grid key={x.id} item xs={6}>
              <div>
                <Typography variant="body2">
                  <span style={{ color: x.color }}>
                    {new Intl.NumberFormat('en-us', {
                      maximumFractionDigits: 1,
                      style: 'percent',
                    }).format(x.value / 100)}
                  </span>
                  {` ${x.label}`}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default CarbonFootprintChart;
