import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  group: {
    marginBottom: 16,
  },
  leftCol: {
    width: 180,
  },
}));

export default function useSettingsStyles() {
  return useStyles();
}
