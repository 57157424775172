import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import client from '../../apollo/settingsClient';
import { createUserManager } from '../../auth/userManager';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { GET_COMPANY_SETTINGS_FOR_EMAIL, ME } from '../../graphql/auth';
import AppTextField from '../common/AppTextField';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    position: 'absolute',
    top: 120,
    color: theme.palette.error.main,
  },
}));

const validationSchema = yup.object().shape({
  email: yup.string().required('Required'),
});

const LoginForm: React.FC = () => {
  const classes = useStyles();

  const { isAuthenticated } = useContext(AuthorizationContext);
  const location = useLocation();

  const inactive = !!new URLSearchParams(location.search).get('inactive');

  // when token is set isAuthenticated will be true, at that point we
  // can fetch the user to verify they are set up correctly
  // and ensure user is loaded in memory of downstream calls then
  // redirect
  const { loading: userLoading, error: userError } = useQuery(ME, {
    skip: !isAuthenticated,
  });
  const [isWaitingForMicrosoft, setIsWaitingForMicrosoft] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [getCompanyForEmail, { loading, error }] = useLazyQuery(
    GET_COMPANY_SETTINGS_FOR_EMAIL,
    {
      client,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.companySettingsForEmail) {
          const { companySettingsForEmail: { policyId = '' } = {} } = data;
          setIsWaitingForMicrosoft(true);
          const auth = createUserManager(policyId);
          // this will trigger the auth flow and will redirect to microsoft
          // to complete the login process. After logging in, the
          // AuthorizationContext will grab the state and finalize the login
          auth.signinRedirect({ prompt: 'login' });
        } else {
          setInvalidEmail(true);
        }
      },
    },
  );

  return (
    <Box>
      {inactive && (
        <Typography variant="h2">
          Please contact your administrator for assistance.
        </Typography>
      )}
      {!inactive && (
        <DataStateHandler
          loading={loading || isWaitingForMicrosoft || userLoading}
          error={error || userError}
          loadingPaddingY={18}>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={validationSchema}
            onSubmit={async ({ email }) => {
              setInvalidEmail(false);
              await getCompanyForEmail({ variables: { email } });
            }}>
            {({ isSubmitting }) => (
              <Form noValidate autoComplete="off">
                <Grid container flexDirection="column">
                  <Grid item>
                    <Typography
                      variant="h1"
                      style={{ fontSize: 56, lineHeight: '64px' }}
                      pb={6}>
                      Working together to reduce our carbon footprint.
                    </Typography>
                  </Grid>
                  <Grid item style={{ position: 'relative' }} pb={3}>
                    <Field
                      name="email"
                      label="Email Address"
                      component={AppTextField}
                      disabled={isSubmitting || isWaitingForMicrosoft}
                    />
                    {invalidEmail && (
                      <Typography
                        variant="body2"
                        className={classes.errorMessage}>
                        Invalid login, please try again.
                      </Typography>
                    )}
                  </Grid>
                  <Grid item pb={3}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="small"
                      disabled={isSubmitting || isWaitingForMicrosoft}>
                      Login
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </DataStateHandler>
      )}
    </Box>
  );
};

export default LoginForm;
