import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';

import BadgeSection from './BadgeSection';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import {
  GET_BADGE_CATEGORIES,
  GET_USER_BADGES,
} from '../../../graphql/badge/badges';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 16,
  },
}));

const BadgeList: React.FC = () => {
  const classes = useStyles();

  const {
    loading: loadingBadges,
    error: errorBadges,
    data: { userBadges = [] } = {},
  } = useQuery(GET_USER_BADGES, {
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { getBadgeCategories: badgeCategories = [] } = {},
  } = useQuery(GET_BADGE_CATEGORIES);

  return (
    <DataStateHandler
      loading={loadingCategories || loadingBadges}
      error={errorCategories || errorBadges}>
      <Typography className={classes.title} variant="h3">
        {t('badge.badges')}
      </Typography>
      <Grid container>
        {badgeCategories.map((badgeCategory, index) => (
          <BadgeSection
            key={badgeCategory.id}
            badgeCategory={badgeCategory}
            badges={userBadges}
            showDivider={index > 0}
          />
        ))}
      </Grid>
    </DataStateHandler>
  );
};

export default BadgeList;
