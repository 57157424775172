import { useMutation, useQuery } from '@apollo/client';
import { Alert, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import FeaturedProjects from '../../components/common/marketplace/FeaturedProjects';
import FilterToolbar, {
  BatchSelectedState,
  Sort,
} from '../../components/common/marketplace/FilterToolbar';
import ProjectBox from '../../components/common/marketplace/ProjectBox';
import ProjectDetailsDrawer from '../../components/common/Project/ProjectDetailsDrawer';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { GET_ALL_CATEGORIES } from '../../graphql/projects/projectCategories';
import { TRACK_PROJECT_VIEW } from '../../graphql/projects/projectMutations';
import { GET_COMPANY_PROJECTS } from '../../graphql/projects/projects';
import useQueryStringParams from '../../hooks/useQueryStringParams';
import { Project, ProjectCategory } from '../../types/project/types';
import { EMPTY_ARRAY } from '../../utils/arrayUtils';
import { MarketplaceContext } from '../../contexts/MarketplaceContext';

interface ProjectId {
  id: string;
}

const Projects: React.FC = () => {
  const { company } = useContext(AuthorizationContext);
  const { creditBalance } = useContext(MarketplaceContext);
  const userCompId: string = company.id;
  const [projectId, updateProjectId] = useState(
    useParams<ProjectId>().id || undefined,
  );
  const [open, setOpen] = useState(!!projectId);
  const [projects, setProjects] = useState<Project[]>([]);

  const [categoryFilters, setCategoryFilters] = useState<ProjectCategory[]>([]);
  const [sort, setSort] = useState(Sort.whatsNew);
  const [selectedIndicator, setSelectedIndicator] = useState<string>('all');
  const [isShowingIndicative, setIsShowingIndicative] = useState(false);

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);
  const {
    loading: loadingProjects,
    error: errorProjects,
    data: {
      companyProjects: { items: allProjects = EMPTY_ARRAY as Project[] } = {},
      projectIndicators = [],
    } = {},
  } = useQuery(GET_COMPANY_PROJECTS, {
    variables: { companyId: userCompId },
    skip: !userCompId,
    fetchPolicy: 'network-only',
  });

  const [trackProjectView] = useMutation(TRACK_PROJECT_VIEW);

  const handleClose = () => {
    setOpen(false);
  };

  const handlePurchase = () => {
    setOpen(false);
  };

  const handleOpen = (pid: string) => {
    trackProjectView({
      variables: {
        projectId: pid,
      },
    });
    updateProjectId(pid);
    setOpen(true);
  };

  const handleFilter = (
    selectedCategories: ProjectCategory[],
    categoryBatchSelectionMode: BatchSelectedState,
    indicator: string,
  ) => {
    // State of the Project Category 'Select All' checkbox
    switch (categoryBatchSelectionMode) {
      case BatchSelectedState.all: {
        // All categories selected
        setCategoryFilters([...projectCategories]);
        break;
      }
      case BatchSelectedState.indeterminate: {
        // Some categories selected
        const cats = projectCategories.filter((cat) =>
          selectedCategories.map((cat2) => cat2.label).includes(cat.label),
        );
        setCategoryFilters(cats);
        break;
      }
      default: {
        // No categories selected
        setCategoryFilters([]);
        break;
      }
    }

    setSelectedIndicator(indicator);
  };

  const queryStringParams = useQueryStringParams();

  useEffect(() => {
    setProjects(cloneDeep(allProjects));

    // Pre-select all items
    setCategoryFilters([...projectCategories]);
  }, [allProjects]);

  useEffect(() => {
    let filtered = allProjects;
    if (categoryFilters.length) {
      const categoryIds = categoryFilters.map((cat) => cat.id);
      filtered = allProjects.filter(
        (proj: Project) =>
          proj.category != null &&
          categoryIds.includes(proj.category) &&
          !!proj.isIndicative === isShowingIndicative,
      );
    } else {
      filtered = allProjects.filter(
        (proj: Project) =>
          proj.category !== '' && !!proj.isIndicative === isShowingIndicative,
      );
    }

    if (selectedIndicator !== 'all') {
      filtered = filtered.filter(
        (p) =>
          p.indicator === selectedIndicator &&
          !!p.isIndicative === isShowingIndicative,
      );
    }

    switch (sort) {
      case Sort.priceHighLow:
        filtered = filtered.sort(
          ({ sellingPrice: a }, { sellingPrice: b }) => (b ?? 0) - (a ?? 0),
        );
        break;
      case Sort.priceLowHigh:
        filtered = filtered.sort(
          ({ sellingPrice: a }, { sellingPrice: b }) => (a ?? 0) - (b ?? 0),
        );
        break;
      case Sort.whatsNew:
        filtered = filtered.sort(
          ({ createdDate: a }, { createdDate: b }) =>
            +new Date(b || '') - +new Date(a || ''),
        );
        break;
      default:
        break;
    }

    setProjects(filtered);
  }, [
    allProjects,
    categoryFilters,
    sort,
    selectedIndicator,
    isShowingIndicative,
  ]);

  useEffect(() => {
    const categoryName = queryStringParams.get('category');
    if (categoryName) {
      const category = projectCategories.find(
        (c: { id: string }) => c.id === categoryName,
      );
      if (category) {
        handleFilter(
          [category],
          BatchSelectedState.indeterminate,
          selectedIndicator,
        );
      }
    }
  }, [queryStringParams, allProjects.length]);

  const featuredProjects = allProjects.filter(
    (proj: Project) => proj.isFeatured === true,
  );

  return (
    <>
      <DataStateHandler
        loading={loadingProjects || loadingCategories}
        error={errorProjects || errorCategories}>
        <>
          {creditBalance > 0 && (
            <Grid item style={{ width: '100%' }}>
              <Alert
                icon={false}
                sx={{
                  '& .MuiAlert-message': {
                    textAlign: 'center',
                    width: '100%',
                  },
                }}>
                You&apos;ve got{' '}
                <strong>
                  {new Intl.NumberFormat('en-us', {
                    maximumFractionDigits: 0,
                    currency: 'USD',
                    style: 'currency',
                  }).format(creditBalance)}{' '}
                  in ZeroMe Credits
                </strong>{' '}
                to Redeem!
              </Alert>
            </Grid>
          )}
          {!!featuredProjects?.length && (
            <Grid item>
              <FeaturedProjects
                clickHandler={handleOpen}
                categories={projectCategories}
                projects={featuredProjects}
              />
            </Grid>
          )}
          <Grid item style={{ minHeight: 53 }} container spacing={0}>
            {projectCategories && (
              <Grid item xs={12}>
                <FilterToolbar
                  data={projectCategories}
                  selectedItems={categoryFilters}
                  filterCallback={handleFilter}
                  sort={sort}
                  onSort={setSort}
                  indicator={selectedIndicator}
                  projectIndicators={projectIndicators}
                  isIndicativeChecked={isShowingIndicative}
                  onToggleIndicative={() =>
                    setIsShowingIndicative(!isShowingIndicative)
                  }
                />
              </Grid>
            )}
          </Grid>
          <Grid item container>
            {projectCategories && (
              <ProjectBox
                categories={projectCategories}
                projects={projects}
                clickHandler={handleOpen}
              />
            )}
          </Grid>
        </>
      </DataStateHandler>
      <ProjectDetailsDrawer
        hideTraderFields
        projectId={projectId}
        open={open}
        handleClose={handleClose}
        handlePurchase={handlePurchase}
      />
    </>
  );
};

export default Projects;
