import React from 'react';

const pollCompletedDisabled = (level: number | undefined, size: number) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3233 62.9205C3.97839 53.5756 3.97839 38.4244 13.3233 29.0795L29.0794 13.3235C38.4243 3.97854 53.5754 3.97854 62.9203 13.3235L78.6764 29.0795C88.0213 38.4244 88.0213 53.5756 78.6764 62.9205L62.9203 78.6765C53.5754 88.0215 38.4243 88.0215 29.0794 78.6765L13.3233 62.9205Z"
      fill="white"
    />
    <path
      d="M15.9281 61.432C7.40633 52.9102 7.40633 39.0937 15.9281 30.5719L30.5692 15.9308C39.091 7.40903 52.9075 7.40903 61.4293 15.9308L76.0704 30.5719C84.5922 39.0937 84.5922 52.9102 76.0704 61.432L61.4293 76.0731C52.9075 84.5949 39.091 84.5949 30.5692 76.0731L15.9281 61.432Z"
      fill="url(#paint0_angular_6519_130351)"
    />
    <mask
      id="mask0_6519_130351"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9281 61.4281C7.40633 52.9063 7.40633 39.0898 15.9281 30.568L30.5692 15.9269C39.091 7.40513 52.9075 7.40513 61.4293 15.9269L76.0704 30.568C84.5922 39.0898 84.5922 52.9063 76.0704 61.4281L61.4293 76.0692C52.9075 84.591 39.091 84.591 30.5692 76.0692L15.9281 61.4281Z"
        fill="url(#paint1_angular_6519_130351)"
      />
    </mask>
    <g mask="url(#mask0_6519_130351)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.1272 15.0448C44.7146 14.6321 44.0441 14.6337 43.6296 15.0482L17.5565 41.1212C13.0656 45.6121 13.049 52.8767 17.5194 57.3472C21.9898 61.8176 29.2544 61.8009 33.7453 57.31L89.9875 1.06776C94.2316 -3.17628 101.097 -3.19201 105.321 1.03263C109.546 5.25726 109.53 12.1225 105.286 16.3665L46.9785 74.6743C46.564 75.0888 46.5625 75.7593 46.9751 76.172C47.3877 76.5846 48.0583 76.583 48.4728 76.1685L106.781 17.8608C111.854 12.7877 111.872 4.5814 106.823 -0.468473C101.773 -5.51835 93.5664 -5.49955 88.4933 -0.426475L32.2511 55.8157C28.5892 59.4776 22.6656 59.4912 19.0205 55.846C15.3753 52.2009 15.3889 46.2773 19.0508 42.6154L45.1238 16.5424C45.5383 16.1279 45.5398 15.4574 45.1272 15.0448Z"
        fill="white"
      />
    </g>
    <path
      d="M25.6675 26.5578C25.6675 24.9683 26.956 23.6797 28.5456 23.6797H63.0826C64.6721 23.6797 65.9607 24.9683 65.9607 26.5578V64.6924C65.9607 66.282 64.6721 67.5705 63.0826 67.5705H28.5456C26.956 67.5705 25.6675 66.282 25.6675 64.6924V26.5578Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.0826 25.1187H28.5456C27.7508 25.1187 27.1065 25.763 27.1065 26.5578V64.6924C27.1065 65.4872 27.7508 66.1315 28.5456 66.1315H63.0826C63.8774 66.1315 64.5217 65.4872 64.5217 64.6924V26.5578C64.5217 25.763 63.8774 25.1187 63.0826 25.1187ZM28.5456 23.6797C26.956 23.6797 25.6675 24.9683 25.6675 26.5578V64.6924C25.6675 66.282 26.956 67.5705 28.5456 67.5705H63.0826C64.6721 67.5705 65.9607 66.282 65.9607 64.6924V26.5578C65.9607 24.9683 64.6721 23.6797 63.0826 23.6797H28.5456Z"
      fill="#6B6B6B"
    />
    <path
      d="M31.4241 43.1074H47.9731C48.3705 43.1074 48.6926 43.4296 48.6926 43.8269V45.266C48.6926 45.6634 48.3705 45.9855 47.9731 45.9855H31.4241V43.1074Z"
      fill="#A9A9A9"
    />
    <path
      d="M31.4241 51.0215H59.4854C59.8828 51.0215 60.2049 51.3436 60.2049 51.741V53.18C60.2049 53.5774 59.8828 53.8996 59.4854 53.8996H31.4241V51.0215Z"
      fill="#A9A9A9"
    />
    <path
      d="M31.4241 58.9355H53.7292C54.1266 58.9355 54.4488 59.2577 54.4488 59.6551V61.0941C54.4488 61.4915 54.1266 61.8136 53.7292 61.8136H31.4241V58.9355Z"
      fill="#A9A9A9"
    />
    <path
      d="M37.1797 30.1551C37.1797 29.7577 37.5018 29.4355 37.8992 29.4355H53.7287C54.1261 29.4355 54.4482 29.7577 54.4482 30.1551C54.4482 30.5524 54.1261 30.8746 53.7287 30.8746H37.8992C37.5018 30.8746 37.1797 30.5524 37.1797 30.1551Z"
      fill="#6B6B6B"
    />
    <path
      d="M31.4241 41.6687C31.4241 41.2714 31.7462 40.9492 32.1436 40.9492H39.3388C39.7362 40.9492 40.0583 41.2714 40.0583 41.6687C40.0583 42.0661 39.7362 42.3883 39.3388 42.3883H32.1436C31.7462 42.3883 31.4241 42.0661 31.4241 41.6687Z"
      fill="#6B6B6B"
    />
    <path
      d="M31.4241 49.5808C31.4241 49.1835 31.7462 48.8613 32.1436 48.8613H45.095C45.4924 48.8613 45.8145 49.1835 45.8145 49.5808C45.8145 49.9782 45.4924 50.3004 45.095 50.3004H32.1436C31.7462 50.3004 31.4241 49.9782 31.4241 49.5808Z"
      fill="#6B6B6B"
    />
    <path
      d="M31.4241 57.4969C31.4241 57.0995 31.7462 56.7773 32.1436 56.7773H40.7779C41.1752 56.7773 41.4974 57.0995 41.4974 57.4969C41.4974 57.8942 41.1752 58.2164 40.7779 58.2164H32.1436C31.7462 58.2164 31.4241 57.8942 31.4241 57.4969Z"
      fill="#6B6B6B"
    />
    <path
      d="M31.4241 33.032C31.4241 32.6346 31.7462 32.3125 32.1436 32.3125H59.4854C59.8828 32.3125 60.2049 32.6346 60.2049 33.032C60.2049 33.4294 59.8828 33.7515 59.4854 33.7515H32.1436C31.7462 33.7515 31.4241 33.4294 31.4241 33.032Z"
      fill="#6B6B6B"
    />
    <path
      d="M34.3018 35.9109C34.3018 35.5135 34.6239 35.1914 35.0213 35.1914H56.6069C57.0043 35.1914 57.3265 35.5135 57.3265 35.9109C57.3265 36.3083 57.0043 36.6304 56.6069 36.6304H35.0213C34.6239 36.6304 34.3018 36.3083 34.3018 35.9109Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88957 53.8896 2.88957 38.1104 12.6219 28.3781L28.3779 12.6221C38.1102 2.88981 53.8893 2.88981 63.6216 12.6221L79.3777 28.3781C89.1099 38.1104 89.1099 53.8896 79.3777 63.6219L63.6216 79.3779C53.8893 89.1102 38.1102 89.1102 28.3779 79.3779L12.6219 63.6219ZM14.0246 29.7809C5.06701 38.7385 5.06701 53.2615 14.0246 62.2191L29.7806 77.9752C38.7382 86.9327 53.2613 86.9327 62.2189 77.9752L77.9749 62.2191C86.9325 53.2615 86.9325 38.7385 77.9749 29.7809L62.2189 14.0248C53.2613 5.06726 38.7382 5.06726 29.7806 14.0248L14.0246 29.7809Z"
      fill="#6B6B6B"
    />
    {level ? (
      <g clipPath="url(#clip0_6519_130351)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#E6E6E6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#6B6B6B"
        />
        <text
          x="74"
          y="77.5"
          fill="#6B6B6B"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_6519_130351"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(53.6878 38.7445) rotate(61.14) scale(44.3107 55.015)">
        <stop offset="0.0543017" stopColor="#E6E6E6" />
        <stop offset="0.411101" stopColor="#A8A8A8" />
        <stop offset="0.606103" stopColor="#A8A8A8" />
        <stop offset="0.758954" stopColor="#E6E6E6" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_6519_130351"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1896 45.9937) rotate(-8.79121) scale(53.9475 54.8238)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_6519_130351">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default pollCompletedDisabled;
