import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { isFeatureEnabled, PlatformFeature } from './CheckFeature/CheckFeature';

// type Props = {
//   children: JSX.Element;
//   path: string;
// };

type Props = {
  ability?: string;
  abilityEdit?: boolean;
  featureIds?: PlatformFeature[];
};

const AuthorizedRoute = ({
  children,
  ability,
  abilityEdit,
  featureIds,
  ...props
}: Props & RouteProps): JSX.Element | null => {
  const { isAuthenticated, user, company } = useContext(AuthorizationContext);

  if (isAuthenticated && ability) {
    const abilityInstances =
      user?.roleConfig?.abilities.filter((x) => x.name.startsWith(ability)) ||
      [];

    if (
      !abilityInstances.length ||
      (abilityEdit && !abilityInstances.every((x) => x.operation === 'EDIT'))
    ) {
      return null;
    }

    if (
      featureIds?.length &&
      !featureIds?.every((featureId) => isFeatureEnabled(company, featureId))
    ) {
      return null;
    }
  }

  return (
    <Route
      {...props}
      render={() =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
            }}
          />
        )
      }
    />
  );
};

export default AuthorizedRoute;
