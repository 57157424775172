import React from 'react';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';

import { Box, Grid, Typography } from '@mui/material';
import client from '../../../apollo/settingsClient';
import { GET_TERMS_PUBLIC } from '../../../graphql/settings';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';

type Props = {
  type: 'privacy' | 'terms';
};

const MemberLegalContent: React.FC<Props> = ({ type }) => {
  const {
    loading,
    error,
    data: { termsPublic: terms = [] } = {},
  } = useQuery(GET_TERMS_PUBLIC, {
    client,
    variables: {
      appType: 'mobile',
    },
  });
  const doc = terms.find((t) => t.type === type);
  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container spacing={2} direction="column">
        <Typography variant="h2">
          {type === 'privacy' ? 'Privacy Policy' : 'Terms & Conditions'}
        </Typography>
        <Typography variant="body1">
          Effective Date:{' '}
          {doc?.lastUpdatedDate
            ? format(new Date(doc.lastUpdatedDate), 'MMMM dd, yyyy')
            : ''}
        </Typography>
        <Box
          mt={2}
          dangerouslySetInnerHTML={{
            __html: doc?.content || '',
          }}
        />
      </Grid>
    </DataStateHandler>
  );
};

export default MemberLegalContent;
