/* import {
  IntercomProvider,
  useIntercom,
  IntercomProps,
} from 'react-use-intercom'; */
import React from 'react';

type InitProps = {
  children: JSX.Element;
};

/**
 * Internal SupportChat component as useIntercom() cannot be called within the same component as <IntercomProvider />.
 * @param children - JSX.Element of all children to render.
 * @returns The children.
 */
// eslint-disable-next-line arrow-body-style
const SupportChat: React.FC<InitProps> = ({ children }) => {
  /* const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID || '';

  const { user, company } = useContext(AuthorizationContext);
  const { boot, update, hardShutdown } = useIntercom();
  const location = useLocation();
  const [isBooted, setIsBooted] = useState(false);

  // members have no portal access - add a check here
  // to prevent a redirect loop
  const isMember =
    user?.roles?.includes('member') ||
    user?.roles?.includes('member_contest_admin');

  if (intercomAppId && !isMember) {
    // If the user is logging out, shutdown Intercom
    const currentPath = location.pathname?.toLowerCase();
    if (
      currentPath.indexOf('/logout') >= 0 ||
      (currentPath.indexOf('/login') >= 0 && isBooted)
    ) {
      // Intercom shutdown: Delete cookies and window Intercom settings
      hardShutdown();
      setIsBooted(false);
    } else if (user?.id && company?.id) {
      // User and company must be populated
      const baseProps: IntercomProps = {
        name: `${user?.firstName} ${user?.lastName}`,
        email: user?.email,
        customAttributes: {
          Roles: `|${user?.roles.join('|')}|`, // 'Roles' needs to be added as a custom attribute in the 'People data' section (https://app.intercom.com/a/apps/[app-id]/settings/people-data)
        },
      };

      if (!isBooted) {
        // Intercom boot: Send user and company details to Intercom
        const bootProps = {
          ...baseProps,
          userId: user?.id,
          userHash: user.userChatSettings?.hashWeb || undefined, // If the web user hash is present, send to Intercom
          companies: [
            {
              companyId: company?.id,
              name: company?.name,
            },
          ],
        } as IntercomProps;

        // Add parent company, too
        if (company?.parentCompanyId) {
          bootProps.companies?.push({
            companyId: company?.parentCompanyId,
            name: company?.parentCompanyName,
          });
        }

        // Call boot method to show chat icon in web app. This is the hook needed
        // to show all other Intercom assets too (banners, product tours, etc.)
        boot(bootProps);
        setIsBooted(true);
      } else {
        // Intercom update: Send ping to Intercom. Also, send user's name, roles, and email as those can be changed in the portal.
        const updateProps = {
          ...baseProps,
        } as IntercomProps;
        update(updateProps);
      }
    }
  } */

  return children;
};

/**
 * The support chat provider component to encapsulate the Intercom provider.
 * @param children - JSX.Element of all children to render.
 * @returns The children.
 */
const SupportChatProvider: React.FC<InitProps> = ({ children }) => {
  const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID || '';

  // Intercom app id must be defined
  if (!intercomAppId) {
    return children;
  }

  return (
    // <IntercomProvider appId={intercomAppId}>
    <SupportChat>{children}</SupportChat>
    // </IntercomProvider>
  );
};

export default SupportChatProvider;
