import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';
import { fancyMoment } from '../../../utils/dateUtils';
import PurchaseHistoryStatusChip from '../../common/Project/PurchaseHistoryStatusChip';
import CompanyNameWithLogo from '../../common/Company/CompanyNameWithLogo';

type Props = {
  purchase: ProjectPurchaseHistory;
};

const useStyles = makeStyles((theme) => ({
  quantity: {
    color: '#6B6B6B',
    border: '1px solid #6B6B6B',
    display: 'inline-block',
    padding: 2,
    borderRadius: 4,
    marginLeft: 8,
  },
  price: {
    '&, & h4, & h6': {
      color: `${theme.palette.text.secondary}`,
      display: 'inline-block',
    },
  },
  projectTitle: {
    marginTop: 12,
    '& p': {
      color: `${theme.palette.text.secondary}`,
    },
  },
}));

const PurchaseRequestListItem: React.FC<Props> = ({ purchase }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item container direction="row">
        <Grid item xs={3}>
          <PurchaseHistoryStatusChip status={purchase.status} />
        </Grid>
        <Grid item xs={3}>
          {fancyMoment(purchase.requestedDate)}
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <div className={classes.price}>
            <Typography variant="h4">
              {new Intl.NumberFormat('en-us', {
                maximumFractionDigits: 2,
                currency: 'USD',
                style: 'currency',
              }).format(purchase.price)}
            </Typography>{' '}
            /{' '}
            <Typography variant="subtitle2">{purchase.project.uom}</Typography>
          </div>
          <Typography variant="h4" className={classes.quantity}>
            {purchase.quantity} {purchase.project.uom}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.projectTitle}>
        <Typography variant="body2">{purchase.project.title}</Typography>
      </Grid>
      <Grid item style={{ marginTop: 16, marginBottom: 6 }}>
        <CompanyNameWithLogo companyId={purchase.companyId} />
      </Grid>
    </Grid>
  );
};

export default PurchaseRequestListItem;
