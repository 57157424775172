import { Divider, Grid } from '@mui/material';
import React from 'react';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';
import { DateGraphCoordinate } from '../../../../../types/dashboard/types';
import { formatDateStringUtc } from '../../../../../utils/dateUtils';
import NumberUtils from '../../../../../utils/numberUtils';
import CardFooter from '../../../../common/CardFooter/CardFooter';
import ChartLabel from '../../../../common/ChartLabel/ChartLabel';
import ChartLegend from '../../../../common/ChartLegend/ChartLegend';
import { formatTooltip } from '../../../../common/ChartTooltips/FormattedTooltip';
import TooltipContainer from '../../../../common/ChartTooltips/TooltipContainer';

type Props = {
  footprints: Array<
    DateGraphCoordinate & { offsetValue: number; category?: string }
  >;
  goal?: number;
};

const COLORS = {
  emissions: '#E96B52',
  offsets: '#6140A5',
};

const Scope3Chart: React.FC<Props> = ({ footprints, goal }) => {
  const data = footprints.map((fp) => ({
    month: formatDateStringUtc(fp.date, 'MMM YYYY'),
    footprint: fp.value / 1000,
    offset: fp.offsetValue / 1000,
    category: fp.category,
  }));

  const total = footprints.reduce((t, f) => t + f.value / 1000, 0);

  const dataMax = Math.max(...data.map((i) => i.footprint));

  const off = 100 - ((goal || 0) / dataMax) * 100;

  const renderTooltipContents = ({
    active,
    payload,
  }: TooltipProps<number, string>) => {
    if (active) {
      if (payload && payload.length > 0) {
        const payloadData = payload[0].payload;
        if (payloadData) {
          const monthName = (payloadData.month || '') as string;

          const emissions = payloadData.footprint || 0;
          const emissionsDisplay = formatTooltip(emissions, 'number');

          let offsetType = (payloadData.category || '') as string;
          switch (offsetType) {
            case 'all':
              offsetType = 'Offsets (All)';
              break;
            case 'category6':
              offsetType = 'Offsets (Cat6)';
              break;
            case 'category7':
              offsetType = 'Offsets (Cat7)';
              break;
            case 'wfh':
              offsetType = 'Offsets (WFH)';
              break;
            default:
              break;
          }

          const offsets = (payloadData.offset || 0) as number;
          const offsetValue = formatTooltip(offsets, 'number');

          const adjusted = emissions - offsets;
          const adjustedValue = formatTooltip(adjusted, 'number');

          return (
            <TooltipContainer>
              <>
                <ChartLabel
                  labelColor="#6B6B6B"
                  color="#FFFFFF"
                  value=""
                  label={monthName}
                />
                <Divider />
                <ChartLabel
                  labelColor="#6B6B6B"
                  color={COLORS.emissions}
                  label="Emissions"
                  value={emissionsDisplay}
                />
                <ChartLabel
                  labelColor="#6B6B6B"
                  color={COLORS.offsets}
                  label={offsetType}
                  value={offsetValue}
                />
                <Divider />
                <ChartLabel
                  key="adjusted"
                  labelColor="#6B6B6B"
                  label="Adjusted Footprint"
                  value={adjustedValue}
                />
              </>
            </TooltipContainer>
          );
        }
      }
    }
    return <svg />;
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <div style={{ height: 215 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 10,
              }}>
              <CartesianGrid vertical={false} color="#D1FFBB" opacity={0.2} />
              <XAxis
                dataKey="month"
                orientation="bottom"
                stroke="#FFFFFF"
                fontSize={10}
                tickMargin={24}
                tickSize={0}
              />
              <YAxis
                orientation="left"
                tickMargin={16}
                tickSize={0}
                fontSize={10}
                stroke="#FFFFFF"
              />
              <Tooltip
                content={renderTooltipContents}
                cursor={{ stroke: '#D1FFBB' }}
              />
              <defs>
                <linearGradient id="emissions" x1={0} y1={0} x2={0} y2={1}>
                  <stop
                    offset="0%"
                    stopColor={COLORS.emissions}
                    stopOpacity={1}
                  />
                  <stop
                    offset={`${off}%`}
                    stopColor={COLORS.emissions}
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="offsets" x1={0} y1={0} x2={0} y2={1}>
                  <stop
                    offset="0%"
                    stopColor={COLORS.offsets}
                    stopOpacity={0.75}
                  />
                  <stop offset="100%" stopColor="#C9BDD9" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="footprint"
                strokeWidth={2}
                stroke={COLORS.emissions}
                fillOpacity={1}
                activeDot={{ stroke: COLORS.emissions, fill: COLORS.emissions }}
                fill="url(#emissions)"
              />
              <Area
                type="monotone"
                dataKey="offset"
                strokeWidth={2}
                stroke={COLORS.offsets}
                fillOpacity={1}
                activeDot={{ stroke: COLORS.offsets, fill: COLORS.offsets }}
                fill="url(#offsets)"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between">
          <Grid item>
            <ChartLegend
              keys={[
                {
                  color: COLORS.emissions,
                  label: 'Emissions',
                },
                {
                  color: COLORS.offsets,
                  label: 'Offset',
                },
              ]}
              labelColor="#FFFFFF"
            />
          </Grid>
          <Grid item>
            <CardFooter
              dataStyle={{ color: '#FFFFFF' }}
              labelStyle={{
                color: '#FFFFFF',
                opacity: 0.5,
                whiteSpace: 'pre-line',
                textAlign: 'right',
                lineHeight: 1.5,
              }}
              data={NumberUtils.format(total, 'integer')}
              label="MT C0₂E"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Scope3Chart;
