import React from 'react';
import { Divider, Grid, List, ListItem, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CompanyNameWithLogo from './CompanyNameWithLogo';

const useStyles = makeStyles(() => ({
  listDivider: {
    margin: '0 8px',
  },
}));

type Props = {
  companyIds?: string[];
  allCompaniesContent?: React.ReactNode;
  selectedCompaniesContent?: React.ReactNode;
};

const CompanyList: React.FC<Props> = ({
  companyIds,
  allCompaniesContent,
  selectedCompaniesContent,
}) => {
  const classes = useStyles();

  return (
    <>
      {!companyIds && allCompaniesContent}
      {!!companyIds && (
        <>
          {selectedCompaniesContent}
          <Grid item xs={12}>
            <List>
              {companyIds.map((c, i) => (
                <React.Fragment key={c}>
                  <ListItem>
                    <ListItemText>
                      <CompanyNameWithLogo companyId={c} />
                    </ListItemText>
                  </ListItem>
                  {i < companyIds.length - 1 && (
                    <Divider className={classes.listDivider} component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </>
      )}
    </>
  );
};

export default CompanyList;
