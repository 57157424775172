/**
 * Image-related utilities.
 */
export default class ImageUtils {
  /**
   * Returns a comma-separated list of supported image file extensions.
   */
  static get SupportedFileExtensions(): string {
    return ImageUtils.SupportedFileExtensionsList.toString();
  }

  /**
   * Array of supported image file extensions. Each one must begin with a period.
   */
  static get SupportedFileExtensionsList(): Array<string> {
    return ['.jpg', '.jpeg', '.png', 'webp'];
  }

  static get DefaultVerticalObjectPosition(): number {
    return 50;
  }

  static ObjectPositionStyle(value?: number): React.CSSProperties {
    return {
      objectPosition: `center ${
        value || ImageUtils.DefaultVerticalObjectPosition
      }%`,
    };
  }
}
