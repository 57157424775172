import { Grid } from '@mui/material';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { format } from 'date-fns';
import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import { OffsetContext, Step } from '../../contexts/OffsetContext';
import { ProjectOffsetAllocation } from '../../types/offsetProject/types';
import Cart from './cart/Cart';
import CartItem from './cart/CartItem';
import EmployeeCarbonEmissionsChart from './EmployeeCarbonEmissionsChart';
import AllocationStepButton from './AllocationStepButton';

const Allocations = () => {
  const { currentStep, offsets, removeOffset, emissionsSettings, company } =
    useContext(OffsetContext);
  const [selectedOffset, setSelectedOffset] =
    useState<ProjectOffsetAllocation>();

  useEffect(() => {
    setSelectedOffset(undefined);
  }, [offsets]);

  const onDeleteOffset = ({ id }: typeof offsets[0]) => {
    removeOffset(id);
  };

  const cartItems: ComponentProps<typeof Cart>['items'] = offsets.map(
    (offset) => {
      const cat =
        emissionsSettings?.find(
          ({ allocType }) => allocType === offset.allocType,
        )?.allocTypeLabel || '';
      const subCat =
        emissionsSettings?.find(
          ({ allocSubType }) => allocSubType === offset.allocSubType,
        )?.allocSubTypeLabel || '';

      return {
        id: offset.id,
        component: (
          <CartItem
            icon={<CalendarToday />}
            quantity={offset.quantity}
            title={`${format(offset.startDate, 'MMM, yyyy')} - ${format(
              offset.endDate,
              'MMM, yyyy',
            )}`}
            chips={[cat, subCat, company?.name || '']}
            onDelete={() => onDeleteOffset(offset)}
            onClick={() => setSelectedOffset(offset)}
          />
        ),
      };
    },
  );

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      wrap="nowrap"
      style={{
        display: currentStep !== Step.allocate ? 'none' : undefined,
        height: '100%',
      }}>
      <Grid
        item
        xs={12}
        container
        direction="row"
        spacing={2}
        justifyContent="space-between">
        <Grid item xs={7}>
          <EmployeeCarbonEmissionsChart
            offset={selectedOffset}
            onReset={() => setSelectedOffset(undefined)}
          />
        </Grid>
        <Grid item xs={5}>
          <Cart items={cartItems} />
        </Grid>
      </Grid>
      <AllocationStepButton />
    </Grid>
  );
};

export default Allocations;
