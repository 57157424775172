import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import MainLayout from '../../components/layouts/MainLayout';
import EmployeesList from '../../components/user/EmployeesList';
import useInitializedRef from '../../hooks/useInitializedRef';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { DashboardContext } from '../../contexts/DashboardContext';
import PageTitle from '../../components/common/PageTitle';

const Employees: React.FC = () => {
  const ref = useInitializedRef();
  const { company } = useContext(AuthorizationContext);
  const { division, includeChildren } = useContext(DashboardContext);

  return (
    <MainLayout>
      <Grid container direction="column">
        <PageTitle title="Members">
          <div ref={ref} />
        </PageTitle>
        <Grid item container style={{ height: 'calc(100vh - 110px)' }}>
          <EmployeesList
            divisionId={division || company.id}
            companyId={company.id}
            includeChildren={includeChildren}
            portalRef={ref}
          />
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Employees;
