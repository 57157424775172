import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import _ from 'lodash';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CREATE_PURCHASE_ALLOCATIONS,
  GET_OFFSET_METRICS,
  OffsetMetricsResult,
} from '../graphql/offsetProject/offsetProject';
import { BaseCompany } from '../types/company/types';
import {
  EmisisonsSetting,
  ProjectOffsetAllocation,
  ProjectOffsetAssignment,
  ProjectPurchaseAllocation,
  ProjectPurchaseHistory,
  ProjectPurchaseHistoryInput,
} from '../types/offsetProject/types';
import EnumUtils from '../utils/enumUtils';
import { AuthorizationContext } from './AuthorizationContext';

export enum Step {
  allocate = 1,
  assign,
}

const OffsetSteps = {
  [Step.allocate]: {
    title: 'Allocate',
    nextButton: 'Assign Projects',

    valid: false,
  },
  [Step.assign]: {
    title: 'Assign',
    nextButton: 'Complete Allocations',
    valid: false,
  },
};

type OffsetContext = {
  // #region Steps
  currentStep: Step;
  steps: typeof OffsetSteps;
  goToStep: (step: Step) => void;
  // #endregion

  // #region Data
  userHasViewAccess: boolean;
  userHasEditAccess: boolean;
  loading: boolean;
  divisions?: BaseCompany[];
  emissionsSettings?: EmisisonsSetting[];
  projectPurchases?: ProjectPurchaseHistory[];
  projectCategoryOfInterest?: OffsetMetricsResult['projectCategoryInterest'][0];
  applyAllocations: () => void;
  allocationInProgress: boolean;
  createAllocationsError?: string;
  createAllocationsSuccess?: boolean;
  reset: () => void;
  // #endregion

  // #region Offsets
  addOffset: (
    offset: Partial<Pick<ProjectOffsetAllocation, 'id'>> &
      Omit<ProjectOffsetAllocation, 'id'>,
  ) => ProjectOffsetAllocation['id'];
  removeOffset: (offsetId: ProjectOffsetAllocation['id']) => void;
  setCompany: (company?: OffsetContext['company']) => void;
  offsets: ProjectOffsetAllocation[];
  company?: BaseCompany;
  offsetTotal: number;
  // #endregion

  // #region Offset Projects
  addProject: (project: ProjectPurchaseHistory, quantity: number) => void;
  updateProject: (project: ProjectPurchaseHistory, quantity: number) => void;
  removeProject: (id: ProjectOffsetAssignment['project']['id']) => void;
  projectOffsetAssignments: ProjectOffsetAssignment[];
  projectTotal: number;
  // #endregion
};

const initialContext: OffsetContext = {
  currentStep: Step.allocate,
  steps: OffsetSteps,
  goToStep: () => null,

  addOffset: () => '',
  removeOffset: () => null,
  setCompany: () => null,
  offsets: [],
  offsetTotal: 0,

  addProject: () => null,
  updateProject: () => null,
  removeProject: () => null,
  projectOffsetAssignments: [],
  projectTotal: 0,

  userHasViewAccess: false,
  userHasEditAccess: false,
  loading: false,
  applyAllocations: () => null,
  allocationInProgress: false,
  reset: () => null,
};

export const OffsetContext = createContext<OffsetContext>(initialContext);

const OffsetProvider: React.FC<{
  children: ReactNode;
  mtOnly?: boolean;
}> = ({ children, mtOnly }) => {
  const { company: userCompany, userHasAccess } =
    useContext(AuthorizationContext);
  const userHasViewAccess = userHasAccess('Client.Offset', 'VIEW');
  const userHasEditAccess = userHasAccess('Client.Offset', 'EDIT');

  // #region Steps
  const [currentStep, setCurrentStep] = useState(initialContext.currentStep);
  const [steps, setSteps] = useState(OffsetSteps);

  const goToStep = (step: Step) => {
    if (EnumUtils.values(Step).includes(step)) {
      setCurrentStep(step);
    }
  };
  // #endregion

  // #region Offsets
  const [offsets, setOffsets] = useState(initialContext.offsets);
  const [offsetTotal, setOffsetTotal] = useState(initialContext.offsetTotal);
  const [company, setCompanyState] = useState(initialContext.company);
  const addOffset = (offset: Parameters<OffsetContext['addOffset']>[0]) => {
    const newId = offset.id || _.uniqueId();
    setOffsets((list) =>
      _.orderBy(
        [
          ...list.filter(({ id }) => id !== newId),
          { ...offset, id: newId } as ProjectOffsetAllocation,
        ],
        ['id'],
      ),
    );

    return newId;
  };

  const removeOffset = (offsetId: ProjectOffsetAllocation['id']) => {
    setOffsets((list) =>
      _.orderBy(
        list.filter((o) => o.id !== offsetId),
        ['id'],
      ),
    );
  };
  useEffect(() => {
    if (currentStep === Step.allocate) {
      setSteps((state) => ({
        ...state,
        [currentStep]: {
          ...state[currentStep],
          valid: !!offsets.filter(({ quantity }) => !!quantity).length,
        },
      }));
    }
  }, [currentStep, offsets]);

  useEffect(() => {
    setOffsetTotal(
      offsets.reduce((total, { quantity }) => total + (quantity || 0), 0),
    );
  });

  const setCompany = (newCompany?: OffsetContext['company']) => {
    if (!offsets.length) {
      setCompanyState(newCompany);
    }
  };
  // #endregion

  // #region Offset Projects
  const [projects, setProjects] = useState(
    initialContext.projectOffsetAssignments,
  );
  const [projectTotal, setProjectTotal] = useState(initialContext.projectTotal);

  const addProject = (project: ProjectPurchaseHistory, quantity: number) => {
    setProjects((list) =>
      _.orderBy([...list, { project, quantity }], ['project.id']),
    );
  };

  const removeProject = (
    projectId: ProjectOffsetAssignment['project']['id'],
  ) => {
    setProjects((list) => list.filter((p) => p.project.id !== projectId));
  };

  const updateProject = (project: ProjectPurchaseHistory, quantity: number) => {
    setProjects((list) =>
      _.orderBy(
        [
          ...list.filter((p) => p.project.id !== project.id),
          { project, quantity },
        ],
        ['project.id'],
      ),
    );
  };

  useEffect(() => {
    if (currentStep === Step.assign) {
      setSteps((state) => ({
        ...state,
        [currentStep]: {
          ...state[currentStep],
          valid: offsetTotal === projectTotal,
        },
      }));
    }
  }, [currentStep, offsetTotal, projectTotal]);

  useEffect(() => {
    setProjectTotal(
      projects.reduce((total, { quantity }) => total + (quantity || 0), 0),
    );
  });

  const [
    createPurchaseAllocations,
    {
      called: createAllocationsAttempted,
      loading: createAllocationsLoading,
      error: createAllocationsError,
      reset: resetCreateAllocations,
    },
  ] = useMutation(CREATE_PURCHASE_ALLOCATIONS);

  const applyAllocations = () => {
    if (!company) {
      return Promise.reject(new Error('Company Required'));
    }

    const remainingAllocations = offsets.reduce(
      (prev, curr) => ({ ...prev, [curr.id]: { ...curr } }),
      {} as Record<typeof offsets[0]['id'], typeof offsets[0]>,
    );
    const purchaseAllocations = projects.flatMap((project) => {
      let remainingToOffset = project.quantity;
      const allocations = Object.keys(remainingAllocations)
        .filter((key) => remainingAllocations[key].quantity)
        .reduce((curr, key) => {
          const remainingAllocation = remainingAllocations[key];
          const quantityAllocated = Math.min(
            remainingAllocation.quantity,
            remainingToOffset,
          );
          remainingAllocation.quantity -= quantityAllocated;
          remainingToOffset -= quantityAllocated;
          if (quantityAllocated) {
            return [
              ...curr,
              {
                quantity: quantityAllocated,
                allocType: remainingAllocation.allocType,
                allocSubType: remainingAllocation.allocSubType,
                periodStart: format(remainingAllocation.startDate, 'yyyy-MM'),
                periodEnd: format(remainingAllocation.endDate, 'yyyy-MM'),
              } as ProjectPurchaseAllocation,
            ];
          }
          return curr;
        }, [] as ProjectPurchaseAllocation[]);
      const projectPurchaseHistoryInput = {
        purchaseHistoryId: project.project.id,
        allocations,
      } as ProjectPurchaseHistoryInput;
      return projectPurchaseHistoryInput;
    }) as ProjectPurchaseHistoryInput[];

    return createPurchaseAllocations({
      variables: {
        companyId: company.id,
        purchaseAllocations,
      },
    });
  };
  // #endregion

  // #region Project Purchases
  const {
    loading: loadingMetrics,
    data: {
      offsetProjectsByCompany: { items: projectPurchases } = {
        items: undefined,
      },
      projectCategoryInterest = [],
      emissionsSettings,
      companySubsidiaries: divisions = [],
    } = {},
  } = useQuery(GET_OFFSET_METRICS, {
    variables: {
      companyId: userCompany.id,
      language: 'en',
      filter: { purchaseType: ['COMPANY'], ...(mtOnly ? { uom: ['MT'] } : {}) },
    },
    skip: !userCompany.id,
  });

  const projectCategoryOfInterest =
    (projectCategoryInterest.length &&
      projectCategoryInterest.reduce(
        (prev, curr) => (curr.percent > prev.percent && curr) || prev,
        projectCategoryInterest[0],
      )) ||
    undefined;

  // #endregion

  const reset = () => {
    resetCreateAllocations();
    if (!createAllocationsError) {
      setCurrentStep(initialContext.currentStep);
      setProjects(initialContext.projectOffsetAssignments);
      setOffsets(initialContext.offsets);
    }
  };

  const value = useMemo<OffsetContext>(
    () => ({
      currentStep,
      steps,
      goToStep,
      divisions:
        (!loadingMetrics && [
          userCompany,
          ...divisions.filter((d) => d.id !== userCompany.id),
        ]) ||
        undefined,
      emissionsSettings: (!loadingMetrics && emissionsSettings) || undefined,
      projectPurchases: (!loadingMetrics && projectPurchases) || undefined,
      projectCategoryOfInterest,
      addOffset,
      removeOffset,
      offsets,
      offsetTotal,
      setCompany,
      company,
      addProject,
      updateProject,
      removeProject,
      projectOffsetAssignments: _.orderBy(projects, ['id']),
      projectTotal,
      userHasViewAccess,
      userHasEditAccess,
      loading: loadingMetrics,
      applyAllocations,
      allocationInProgress: createAllocationsAttempted,
      createAllocationsError:
        (createAllocationsAttempted &&
          !createAllocationsLoading &&
          createAllocationsError?.message) ||
        undefined,
      createAllocationsSuccess:
        createAllocationsAttempted &&
        !createAllocationsLoading &&
        !createAllocationsError,
      reset,
    }),
    [
      currentStep,
      offsets,
      steps,
      divisions,
      emissionsSettings,
      loadingMetrics,
      projectPurchases,
      projectCategoryOfInterest,
      projects,
      offsetTotal,
      projectTotal,
      createAllocationsAttempted,
      createAllocationsLoading,
      createAllocationsError,
      userHasViewAccess,
      userHasEditAccess,
      company,
    ],
  );

  return (
    <OffsetContext.Provider value={value}>{children}</OffsetContext.Provider>
  );
};

export default OffsetProvider;
