import React, { useContext, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ProjectData from './ProjectData';
import ProjectDocuments from './ProjectDocuments';
import CategoryIconDiamond from './CategoryIconDiamond';
import ProjectDataPill from './ProjectDataPill';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import ProjectAdditionalImages from './ProjectAdditionalImages';
import ProjectLocation from './ProjectLocation';
import ProjectDescription from './ProjectDescription';
import ProjectAuditInformation from './ProjectAuditInformation';
import EditProjectStepModal from './EditProjectStepModal';
import ProjectScopeOfOffering from './ProjectScopeOfOffering';
import ProjectTraderInfo from './ProjectTraderInfo';
import HeaderTabs from '../HeaderTabs/HeaderTabs';
import HeaderTab from '../HeaderTabs/HeaderTab';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import ProjectPriceHistory from './ProjectPriceHistory';
import ProjectPurchaseHistory from './ProjectPurchaseHistory';
import ProjectInventoryHistory from './ProjectInventoryHistory/ProjectInventoryHistoryTable';
import ProjectStatus from './ProjectStatus';
import ImageUtils from '../../../utils/imageUtils';
import useProjectImage from '../../../hooks/useProjectImage';
import DefaultImage from '../DefaultImage';

const useStyles = makeStyles(() => ({
  photo: {
    height: 285,
    width: '100%',
    objectFit: 'cover',
  },
  categoryTitle: {
    color: '#6b6b6b',
  },
  shape: {
    marginTop: -33,
    marginLeft: 48,
    position: 'absolute',
  },
  qty: {
    marginTop: -24,
    right: 32,
    position: 'absolute',
    width: '100%',
  },
  parentLayer: {
    position: 'relative',
    marginBottom: 10,
  },
  editTitle: {
    position: 'absolute',
    top: 10,
    right: 10,
    '& svg': {
      color: '#fff',
    },
  },
  tabs: {
    '& span': {
      fontSize: 14,
    },
  },
}));

enum ProjectDetailTabs {
  General = 0,
  Pricing = 1,
  Purchases = 2,
  Inventory = 3,
}

const ProjectDetails: React.FC = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(ProjectDetailTabs.General);
  const { project, editable, projectCategories, hideTraderFields } =
    useContext(ProjectContext);
  const { userHasAccess } = useContext(AuthorizationContext);
  const projectImage = useProjectImage({ project, size: 'large' });
  const finalImage = project.headerImage?.publicUrl || projectImage;

  const category = projectCategories.find((x) => x.id === project.category);

  let dataType;
  if (project.isIndicative) {
    dataType = 'INDICATIVE_OFFER';
  } else {
    dataType = editable ? 'SELLING_PRICE' : 'REMAINING_QUANTITY';
  }

  return (
    <>
      <div className={classes.parentLayer}>
        {finalImage ? (
          <img
            className={classes.photo}
            alt="Project"
            src={finalImage}
            style={ImageUtils.ObjectPositionStyle(project.headerImagePosition)}
          />
        ) : (
          <DefaultImage height={285} innerHeight="40%" />
        )}
        <div className={classes.editTitle}>
          <EditProjectStepModal
            step={ProjectDetailsStep.title}
            buttonStyle="ICON"
          />
        </div>
        {category && (
          <>
            <div className={classes.shape}>
              <CategoryIconDiamond
                categoryId={project.category}
                size="medium"
              />
            </div>
            <div className={classes.qty}>
              <ProjectDataPill
                project={project}
                category={category}
                dataType={
                  dataType as
                    | 'INDICATIVE_OFFER'
                    | 'SELLING_PRICE'
                    | 'REMAINING_QUANTITY'
                }
              />
            </div>
          </>
        )}
      </div>
      <Grid
        container
        direction="column"
        style={{ padding: 48, paddingTop: 24 }}>
        <Grid item>
          <Typography variant="body2" className={classes.categoryTitle}>
            {category?.label}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h2" gutterBottom>
            {project?.title || ' '}
          </Typography>
        </Grid>
        {editable &&
          !project.isDraft &&
          userHasAccess('ZeroMe.Marketplace', 'VIEW') && (
            <Grid item container style={{ marginBottom: 16 }}>
              {project.isIndicative ? null : <ProjectTraderInfo />}
              <HeaderTabs
                value={tab}
                className={classes.tabs}
                style={{ marginTop: 24, width: '100%' }}>
                <HeaderTab
                  label="General"
                  onClick={() => setTab(ProjectDetailTabs.General)}
                />
                <HeaderTab
                  label="Pricing"
                  onClick={() => setTab(ProjectDetailTabs.Pricing)}
                />
                <HeaderTab
                  label="Purchases"
                  onClick={() => setTab(ProjectDetailTabs.Purchases)}
                />
                {project.isIndicative ? null : (
                  <HeaderTab
                    label="Inventory"
                    onClick={() => setTab(ProjectDetailTabs.Inventory)}
                  />
                )}
              </HeaderTabs>
            </Grid>
          )}
        {tab === ProjectDetailTabs.General && (
          <Grid item container direction="row">
            <Grid item xs={8} style={{ paddingRight: 40 }}>
              <Grid container direction="column">
                <ProjectDescription />
                {hideTraderFields ? null : <ProjectLocation />}
                <ProjectAdditionalImages />
                <ProjectScopeOfOffering />
                <ProjectStatus />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <ProjectData />
              {!hideTraderFields ? null : <ProjectLocation />}
              <ProjectAuditInformation />
              <ProjectDocuments />
            </Grid>
          </Grid>
        )}
        {tab === ProjectDetailTabs.Pricing && <ProjectPriceHistory />}
        {tab === ProjectDetailTabs.Purchases && <ProjectPurchaseHistory />}
        {tab === ProjectDetailTabs.Inventory && (
          <ProjectInventoryHistory
            inventoryHistory={project.inventoryHistory}
          />
        )}
      </Grid>
    </>
  );
};

export default ProjectDetails;
