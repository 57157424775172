import { styled, Tabs } from '@mui/material';
import { Colors } from '../../../theme/shared';

export default styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: Colors.CobaltBlue,
  },
  '&.MuiTabs-root': {
    borderBottom: '1px solid #DADADA',
  },
});
