import React, { useContext } from 'react';
import { DashboardContext } from '../../../contexts/DashboardContext';
import ClientCompanySelect from './ClientCompanySelect';
import ZeroMeCompanySelect from './ZeroMeCompanySelect';

type Props = {
  className?: string;
};

const CompanySelect: React.FC<Props> = ({ className }) => {
  const { isShowingAllCompanies } = useContext(DashboardContext);

  return isShowingAllCompanies ? (
    <ZeroMeCompanySelect includeAllCompanies className={className} />
  ) : (
    <ClientCompanySelect className={className} />
  );
};

export default CompanySelect;
