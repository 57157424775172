import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';

import ParticipationActivity from './ParticipationActivity/ParticipationActivity';
import EngagementMetrics from './EngagementMetrics/EngagementMetrics';
import {
  ENGAGEMENT_METRICS,
  LIKED_PROJECTS,
} from '../../../graphql/dashboard/engagement';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { dateRangeFromEndDate } from '../../../utils/dateUtils';
import useCurrentCompany from '../../../hooks/useCurrentCompany';
import { DashboardContext } from '../../../contexts/DashboardContext';
import { COMPANY_USER_ALLOTMENT_METRICS } from '../../../graphql/projects/projects';

const Participation: React.FC = () => {
  const { companyId, includeChildren } = useCurrentCompany();
  const { endDate } = useContext(DashboardContext);

  const {
    data: {
      participationActivity = {},
      profileCompletion = {},
      projectCategoryInterest = [],
      monthlyUsers = [],
    } = {},
    loading,
    error,
  } = useQuery(ENGAGEMENT_METRICS, {
    skip: !companyId,
    fetchPolicy: 'network-only',
    variables: {
      dateGrain: 'MONTHLY',
      companyId,
      includeChildren,
      ...dateRangeFromEndDate(endDate, 12),
    },
  });

  const {
    data: { likedProjects = [], projectAllotmentMetrics = [] } = {},
    loading: likesLoading,
    error: likesError,
  } = useQuery(LIKED_PROJECTS, {
    skip: !companyId,
    variables: {
      companyId,
      includeChildren,
      date: endDate.toISOString(),
    },
  });

  const {
    data: { companyUserAllotmentMetrics } = {},
    loading: allotmentsLoading,
    error: allotmentsError,
  } = useQuery(COMPANY_USER_ALLOTMENT_METRICS, {
    variables: {
      companyId,
      includeChildren,
    },
    skip: !companyId,
  });

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      style={{ height: '100%', marginBottom: 32 }}>
      <DataStateHandler
        loading={loading || likesLoading || allotmentsLoading}
        error={error || likesError || allotmentsError}>
        <>
          <Grid item>
            <ParticipationActivity
              data={{
                ...participationActivity,
                creditsRedeemed: companyUserAllotmentMetrics?.redeemed,
                totalRedemptions: companyUserAllotmentMetrics?.totalRedemptions,
              }}
            />
          </Grid>
          <Grid item>
            <EngagementMetrics
              total={participationActivity.total}
              profileCompletion={profileCompletion}
              projectCategoryInterest={projectCategoryInterest}
              monthlyUsers={monthlyUsers}
              likedProjects={likedProjects.map((lp) => ({
                ...lp,
                isSponsored: projectAllotmentMetrics
                  .map((o) => o.projectId)
                  .includes(lp.likedProjectId),
              }))}
            />
          </Grid>
        </>
      </DataStateHandler>
    </Grid>
  );
};
export default Participation;
