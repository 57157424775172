import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import ProjectDetails from '../../common/Project/ProjectDetails';
import RoundedFlexBox from '../RoundedFlexBox';

const useStyles = makeStyles((theme) => ({
  headerRow: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  header: {
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 32,
  },
  root: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
  bodyWrapper: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginLeft: 32,
    marginRight: 32,
  },
}));

const CreateProjectReview: React.FC = () => {
  const classes = useStyles();

  return (
    <RoundedFlexBox overflow="hidden">
      <>
        <div
          style={{ height: '100%', overflow: 'hidden auto', paddingTop: 60 }}>
          <ProjectDetails />
        </div>
        <Grid container className={classes.headerRow}>
          <div style={{ backgroundColor: 'white', width: '100%' }}>
            <Grid item container direction="column" className={classes.header}>
              <Grid item>
                <Typography variant="h2">Review Project</Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </>
    </RoundedFlexBox>
  );
};

export default CreateProjectReview;
