import { Grid, Drawer, Box } from '@mui/material';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import PageTitle from '../common/PageTitle';
import HabitsList from './HabitsList';
import HabitDetails from './HabitDetails';
import ConfirmCancelModal from '../common/ConfirmCancelModal/ConfirmCancelModal';
import ConfigExport from './ConfigExport';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 16,
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const HabitsContainer = () => {
  const [selectedHabitId, setSelectedHabitId] = useState<string | undefined>(
    undefined,
  );

  const [isCancelling, setIsCancelling] = useState(false);

  const classes = useStyles();

  return (
    <>
      <Grid container direction="column">
        <PageTitle title="Habits">
          <>
            <ConfigExport />
            <SmallPaddedButton
              onClick={() => setSelectedHabitId('new')}
              style={{ marginTop: 8, marginLeft: 8 }}>
              + Create New
            </SmallPaddedButton>
          </>
        </PageTitle>

        <Grid item container style={{ height: 'calc(100vh - 110px)' }}>
          <HabitsList onSelected={({ id }) => setSelectedHabitId(id)} />
        </Grid>
      </Grid>
      <Drawer
        open={!!selectedHabitId}
        onClose={() => {
          setIsCancelling(true);
        }}
        anchor="right">
        <Box className={classes.drawer}>
          {selectedHabitId && (
            <HabitDetails
              id={selectedHabitId}
              onClose={(cancelled) =>
                cancelled
                  ? setIsCancelling(true)
                  : setSelectedHabitId(undefined)
              }
            />
          )}
        </Box>
      </Drawer>
      <ConfirmCancelModal
        isOpen={isCancelling}
        title="Are you sure you want to leave?"
        onCancel={() => setIsCancelling(false)}
        onConfirm={() => {
          setSelectedHabitId(undefined);
          setIsCancelling(false);
        }}
      />
    </>
  );
};

export default HabitsContainer;
