import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React from 'react';
import {
  getHabitBannerImage,
  getHabitBannerText,
} from '../../../utils/habitUtils';
import { MemberHabit } from '../../../types/habit/types';
import SVG from '../../../assets/svg-v2';
import DefaultImage from '../../common/DefaultImage';

const useStyles = makeStyles(() => ({
  image: {
    width: '100%',
    objectFit: 'cover',
    height: 450,
    borderRadius: '8px 8px 0 0',
  },
  impact: {
    background: '#F1F8FD',
    padding: 16,
    paddingBottom: 40,
    width: '100%',
    textAlign: 'center',
  },
  bannerImage: {
    width: '100%',
    height: 125,
    objectFit: 'cover',
    borderRadius: '0 0 8px 8px',
  },
  earthBannerImage: {
    height: 125,
    width: 125,
    margin: 'auto',
    display: 'block',
    position: 'relative',
    bottom: 20,
  },
  bannerText: {
    color: '#FFF',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  banner: {
    background: '#022131',
    padding: 16,
    border: '1px solid #FFF',
    borderRadius: 8,
    margin: 'auto',
    position: 'absolute',
    zIndex: 2,
    left: 0,
    right: 0,
    width: 300,
    textAlign: 'center',
  },
  left: {
    position: 'absolute',
    left: 50,
  },
  right: {
    position: 'absolute',
    right: 50,
  },
}));

type Props = {
  habit: MemberHabit;
};
const MemberHabitBanner: React.FC<Props> = ({ habit }) => {
  const classes = useStyles();

  if (!habit.bannerType) {
    return null;
  }

  const isEarthFriendly = habit.bannerType === 'EARTH_FRIENDLY';

  const bannerTop = isEarthFriendly ? -5 : -30;
  const flapTop = isEarthFriendly ? 15 : -10;

  return (
    <Grid container>
      {habit.imageUrls.large ? (
        <img
          className={classes.image}
          src={habit.imageUrls.large}
          alt={habit.impact}
        />
      ) : (
        <DefaultImage height={450} innerHeight="25%" />
      )}

      <Grid item className={classes.impact}>
        <Typography variant="body2">{habit.bannerDescription}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Box position="relative">
          <Box className={classes.banner} style={{ top: bannerTop }}>
            <Typography variant="subtitle1" className={classes.bannerText}>
              {isEarthFriendly ? '' : habit.bannerValue}{' '}
              {getHabitBannerText(habit.bannerType)}
            </Typography>
          </Box>
          <SVG.BannerLeft className={classes.left} style={{ top: flapTop }} />
          <SVG.BannerRight className={classes.right} style={{ top: flapTop }} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <img
          className={
            isEarthFriendly ? classes.earthBannerImage : classes.bannerImage
          }
          src={getHabitBannerImage(habit.bannerType) || ''}
          alt={habit.bannerDescription}
        />
      </Grid>
    </Grid>
  );
};

export default MemberHabitBanner;
