import {
  ChangeLanguageKeysToString,
  FileAttachment,
  ImageSize,
  LanguageKey,
} from '../../types/common';
import { QuestionAnswer } from '../../types/question/types';
import { MemberHabit } from '../../types/habit/types';

export enum CommunicationType {
  BLOG_POST = 'BLOG_POST',
  VIDEO = 'VIDEO',
  HINT = 'HINT',
  AUDIO = 'AUDIO',
}

export type CommunicationRatingAggregate = {
  score: number;
  count: number;
};

export type CommunicationRatingAggregates = {
  global?: CommunicationRatingAggregate;
  company?: CommunicationRatingAggregate;
  user?: CommunicationRatingAggregate;
};

export type MemberCommunicationListItem = {
  id: string;
  title: string;
  author: string;
  communicationType: CommunicationType;
  isFeatured: boolean;
  contentUploadUrl?: string;
  headerImageUrls: Record<ImageSize, string>;
  headerImageBlurHash?: string;
  publishDate: string;
  relatedArticles: MemberCommunicationListItem[];
  relatedHabits: MemberHabit[];
  createdDate: string;
  updatedDate: string;
  ratings: CommunicationRatingAggregates;
  quiz: LocalizedQuiz;
};

export type Communication = {
  id: string;
  author: string;
  title: string;
  content?: string;
  headerImageUrl?: string;
  contentUploadUrl?: string;
  communicationType: CommunicationType;
  categoryIds: {
    id: string;
  }[];
  isFeatured: boolean;
  publishDate?: string;
  createdDate: string;
  updatedDate: string;
};

export type CommunicationInput = {
  id: string;
  author: string;
  title: string;
  content: string;
  headerImage: FileAttachment;
  contentUpload: FileAttachment;
  contentUploadUrl?: string;
  communicationType: CommunicationType;
  categoryIds: string[];
  isFeatured: boolean;
  publishDate: string;
  // this doesn't get sent up to the server, it is
  // there to manage unpublished state for the UI
  hasBeenUnpublished: boolean;
  includeChildren?: boolean;
  headerImageUrl?: string;
  relatedArticleIds?: string[];
  relatedHabitIds?: string[];
};

export type CommunicationListItem = {
  id: string;
  title: string;
  createdDate: string;
  updatedDate: string;
  publishDate: string;
  viewsCount: number;
  bookmarksCount: number;
  isFeatured: boolean;
  communicationType: CommunicationType;
  quiz: LocalizedQuiz;
  // these are only to assist in filtering, not part of the actual nodel
  status?: 'Draft' | 'Published';
  filterableIsFeatured?: string;
  ratings: CommunicationRatingAggregates;
};

export type Quotation = {
  id: string;
  quote: string;
  author: string;
  status: 'PUBLISHED' | 'DRAFT';
};

export type EmailCommunicationListItem = {
  id: string;
  title: string;
  createdDate: string;
  updatedDate: string;
  publishDate?: string;
  openedPercent?: number;
};

export type CommunicationCategory = {
  id: string;
  label: string;
  description?: string;
};

export type CommunicationSettings = {
  welcomeVideoId?: string;
};

export type Notification = {
  id: string;
  title: string;
  content?: string;
  linkType: 'NONE' | 'COMMUNICATION';
  linkId?: string;
  publishDate?: string;
  sentDate?: string;
  createdDate: string;
  updatedDate: string;
};

export type EditableQuizQuestion = {
  questionId: string;
  correctAnswerId?: string;
  questionText: LanguageKey[];
  options: EditableQuizQuestionOption[];
};

export type LocalizedQuizQuestion = Omit<
  ChangeLanguageKeysToString<EditableQuizQuestion>,
  'options'
> & {
  options: LocalizedQuizQuestionOption[];
};

export type EditableQuizQuestionOption = {
  answerId: string;
  answerText: LanguageKey[];
  answerExplanation: LanguageKey[];
  nextQuestionId?: string;
};

export type LocalizedQuizQuestionOption =
  ChangeLanguageKeysToString<EditableQuizQuestionOption>;

export enum QuizType {
  QUIZ = 'QUIZ',
  POLL = 'POLL',
}

export enum QuizStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum QuizDisplayStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  COMPLETED = 'COMPLETED',
  ACTIVE = 'ACTIVE',
}

export type EditableQuiz = {
  id?: string;
  quizType: QuizType;
  title: LanguageKey[];
  status: QuizStatus;
  communicationIds?: string[];
  questions: EditableQuizQuestion[];
  companyIds?: string[];
  createdDate?: string;
  updatedDate?: string;
  publishDate?: string;
  expireDate?: string;
  iconUrl?: string;
};

export type LocalizedQuiz = Omit<
  ChangeLanguageKeysToString<EditableQuiz>,
  'questions' | 'id'
> & {
  id: string; // id is not required on EditableQuiz, but is here.
  questions: LocalizedQuizQuestion[];
  metrics?: QuizMetrics;
  answers?: QuizAnswers;
};

export type QuizAnswers = {
  id: string;
  quizId: string;
  isHidden: boolean;
  answers: QuestionAnswer[];
  updatedDate: string;
};

export type QuizMetricsAnswer = {
  questionId: string;
  correct: boolean;
  answerId: string;
  totalOccurances: number;
};

export type QuizMetrics = {
  averageAccuracy: number;
  quizCount: number;
  answers: QuizMetricsAnswer[];
};
