import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';

import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import SVG from '../../assets/svg';

import { CommunicationType } from './types';

type Props = {
  onSelected: (communicationType: CommunicationType) => void;
};

const communicationTypesMenuItems = [
  { value: CommunicationType.HINT, label: 'Hints & Tips' },
  { value: CommunicationType.VIDEO, label: 'Video' },
  { value: CommunicationType.AUDIO, label: 'Audio' },
  { value: CommunicationType.BLOG_POST, label: 'Article' },
];

const CreateCommunicationButton: React.FC<Props> = ({ onSelected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);
  return (
    <div>
      <SmallPaddedButton onClick={handleClick} endIcon={<SVG.ArrowDownWhite />}>
        + Create New
      </SmallPaddedButton>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        keepMounted>
        {communicationTypesMenuItems.map(({ value, label }) => (
          <MenuItem
            key={value}
            onClick={() => {
              setAnchorEl(null);
              onSelected(value);
            }}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CreateCommunicationButton;
