import { challengeFirstDisabled } from './ChallengeFirstDisabled';
import { challengeFirstEnabled } from './ChallengeFirstEnabled';

// eslint-disable-next-line import/prefer-default-export
export const challengePlacementFirst = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? challengeFirstDisabled(level, size)
    : challengeFirstEnabled(level, size);
