import { gql, TypedDocumentNode } from '@apollo/client';
import { CurrentBudget } from '../../types/dashboard/types';

export const COMPANY_PROJECTED_EMISSIONS = gql`
  query employeeCommutingStatistics(
    $date: String
    $companyId: String!
    $includeChildren: Boolean
    $includeDemo: Boolean
  ) {
    companyProjectedEmissions(
      date: $date
      companyId: $companyId
      includeChildren: $includeChildren
    ) {
      total
    }

    company(id: $companyId) {
      id
      lifetimeBudget: currentBudget(
        includeChildren: $includeChildren
        includeDemo: $includeDemo
        lifetime: true
      ) {
        total
        spent
        spentValueByUom {
          mt
          mwh
        }
        spentMt
        remaining
        allocated
        allocatedMt
        allocatedOffPlatform
        allocatedOffPlatformMt
      }
    }
  }
` as TypedDocumentNode<{
  companyProjectedEmissions: { total: number };
  company: {
    id: string;
    lifetimeBudget: CurrentBudget;
  };
}>;

export default {
  COMPANY_PROJECTED_EMISSIONS,
};
