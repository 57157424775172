import React, { useContext, useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  IconButton,
  TextField,
  Dialog,
  Box,
  Drawer,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SVG from '../../../assets/svg';
import { MarketplaceContext } from '../../../contexts/MarketplaceContext';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { sanitizeQuantity } from '../marketplace/marketplaceUitls';
import AddedToCartModal from './AddedToCartModal';
import ProjectDetails from './ProjectDetails';
import TooltipError from '../Tooltip/TooltipError';
import ProjectProvider from '../../../contexts/ProjectContext';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import { generateProjectByIdQuery } from '../../../graphql/projects/projects';
import AskATrader from '../marketplace/AskATrader';

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 1338,
    [theme.breakpoints.up('lg')]: {
      width: 1338,
    },
  },
  textFieldStyle: {
    '& .MuiInputBase-root': {
      color: '#9E9E9E',
    },
    '& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        border: 'inherit',
      },
    width: 110,
  },
  buy: {
    float: 'right',
    padding: 8,
    display: 'flex',
  },
  column: {
    marginRight: 16,
  },
  money: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  button: {
    marginLeft: 16,
  },
  content: {
    width: '100%',
    padding: 0,
    overflow: 'hidden',
  },
}));

type Props = {
  open: boolean;
  handleClose: () => void;
  handlePurchase?: () => void;
  projectId?: string;
  disableCart?: boolean;
  hideTraderFields?: boolean;
  editable?: boolean;
};

const ProjectDetailsDrawer: React.FC<Props> = ({
  open,
  handleClose,
  handlePurchase,
  projectId,
  disableCart,
  hideTraderFields,
  editable,
}) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { updateCart, setCartVisible, cart, setProject } =
    useContext(MarketplaceContext);
  const [qty, setQty] = React.useState(0);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    loading,
    error,
    data: { project } = {},
  } = useQuery(generateProjectByIdQuery(userHasAccess), {
    variables: { projectId: projectId || '' },
    skip: !projectId,
  });

  const handleQtyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = sanitizeQuantity(e);
    setShowError(newQty > (project?.remainingQuantity || 0));
    setQty(newQty);
  };
  const updateCartOnSubmit = () => {
    setIsCartModalOpen(true);
  };

  const onCartModalClosed = (openCart: boolean) => {
    if (projectId && handlePurchase) {
      setQty(0);
      updateCart(projectId, qty, 'INCREMENT');
      setIsCartModalOpen(false);
      if (openCart) {
        setCartVisible(true);
      }
      handlePurchase();
    }
  };

  // For Carbon Offset project, add to cart.
  // For Indicative Offer project, show the Ask A Trader component.
  type viewModes = 'CART_MODE' | 'CONTACT_TRADER_MODE' | undefined;
  const [contactTraderOpen, setContactTraderOpen] = useState(false);
  let cartIsDisabled: boolean;
  let viewMode: viewModes;

  // Show which component?
  if (project?.isIndicative) {
    // Ask a trader
    cartIsDisabled = true;
    viewMode = 'CONTACT_TRADER_MODE';
  } else {
    // Add to cart
    cartIsDisabled = disableCart || false;
    viewMode = 'CART_MODE';
  }

  // Close 'Ask a Trader' drawer
  const handleContactTraderClose = () => {
    setContactTraderOpen(false);
  };

  // Open 'Ask a Trader' drawer
  const handleContactTraderOpen = () => {
    setProject(project);
    setContactTraderOpen(true);
  };

  const isAlreadyInCart = cart.items.some((c) => c.project.id === project?.id);

  const buttonText =
    qty === 0 ? 'Enter Quantity to Add to Cart' : 'Add to Cart';
  return (
    <>
      <Drawer open={open} onClose={handleClose} anchor="right">
        <Box className={classes.drawer}>
          <DataStateHandler loading={loading} error={error}>
            {project ? (
              <>
                <div>
                  <DialogTitle>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item>
                        <Typography variant="h1">
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            size="large">
                            <SVG.Back />
                          </IconButton>{' '}
                          Project Details
                        </Typography>
                      </Grid>
                      {!cartIsDisabled &&
                        viewMode === 'CART_MODE' &&
                        userHasAccess('Client.Marketplace', 'EDIT') && (
                          <>
                            {isAlreadyInCart && (
                              <Grid item>
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between">
                                  <Box mr={2}>
                                    <Typography variant="h3">
                                      This item is already in your cart.
                                    </Typography>
                                  </Box>

                                  <Button
                                    onClick={() => {
                                      setCartVisible(true);
                                      handleClose();
                                    }}
                                    variant="outlined"
                                    color="primary">
                                    View Cart
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                            {!isAlreadyInCart && (
                              <Grid item>
                                <div className={classes.buy}>
                                  <div className={classes.column}>
                                    <Typography>
                                      <span className={classes.money}>
                                        ${project.sellingPrice || 0.0}
                                      </span>
                                      <br />
                                      {project.uom ? `/ ${project.uom}` : ''}
                                    </Typography>
                                  </div>
                                  <div>
                                    <TooltipError
                                      title={
                                        showError
                                          ? `The quantity you've entered is higher than the available amount`
                                          : ''
                                      }
                                      popperProps={{
                                        disablePortal: false,
                                      }}
                                      open={showError}>
                                      <TextField
                                        id={project.id}
                                        value={qty === 0 ? '' : qty}
                                        size="medium"
                                        variant="outlined"
                                        color="secondary"
                                        className={classes.textFieldStyle}
                                        onChange={handleQtyChange}
                                        error={showError}
                                      />
                                    </TooltipError>
                                  </div>
                                  <div>
                                    <Button
                                      onClick={updateCartOnSubmit}
                                      className={classes.button}
                                      variant="contained"
                                      disabled={qty === 0 || showError}
                                      color="primary">
                                      <ShoppingCartIcon
                                        style={{ marginRight: 5 }}
                                      />{' '}
                                      {buttonText}
                                    </Button>
                                  </div>
                                </div>
                              </Grid>
                            )}
                          </>
                        )}
                      {cartIsDisabled && viewMode === 'CONTACT_TRADER_MODE' && (
                        <Grid item>
                          <div>
                            <Button
                              onClick={handleContactTraderOpen}
                              className={classes.button}
                              variant="contained"
                              color="primary">
                              I am interested! Please contact me.
                            </Button>
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </DialogTitle>

                  <DialogContent className={classes.content}>
                    <ProjectProvider
                      initialProject={project}
                      editable={editable}
                      hideTraderFields={hideTraderFields}>
                      <ProjectDetails />
                    </ProjectProvider>
                  </DialogContent>
                </div>
                <Dialog
                  open={isCartModalOpen}
                  onClose={() => onCartModalClosed(false)}
                  maxWidth="sm">
                  <AddedToCartModal
                    project={project}
                    quantity={qty}
                    price={(project.sellingPrice || 0) * qty}
                    onClose={onCartModalClosed}
                  />
                </Dialog>
              </>
            ) : null}
          </DataStateHandler>
        </Box>
      </Drawer>
      <Drawer
        open={
          contactTraderOpen &&
          cartIsDisabled &&
          viewMode === 'CONTACT_TRADER_MODE'
        }
        onClose={handleContactTraderClose}
        anchor="right"
        className={classes.drawer}>
        <AskATrader
          onClose={handleContactTraderClose}
          header="I am interested!"
          subHeading="Please contact me to discuss this indicative offer."
        />
      </Drawer>
    </>
  );
};

export default ProjectDetailsDrawer;
