import React, { useContext } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Grid,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { useMutation, useQuery } from '@apollo/client';

import { Formik, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';

import { CompanySubscription } from '../../types/company/types';

import AppTextField from '../common/AppTextField';
import AppDatePicker from '../common/AppDatePicker';
import {
  UPSERT_SUBSCRIPTION,
  UPSERT_CREDIT_HISTORY,
} from '../../graphql/company/companyMutations';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { formatDateStringUtc } from '../../utils/dateUtils';
import SVG from '../../assets/svg';
import { GET_EMISSIONS_SETTINGS } from '../../graphql/settings';
import { AllocTypeMapping } from '../offset/EmployeeCarbonEmissionsChart';

const schema = yup.object().shape({
  startDate: yup.date().required(),
  licenseFee: yup.number(),
  marketplaceCredits: yup.number(),
  notes: yup.string(),
  allotments: yup
    .array()
    .of(
      yup.object().shape({
        startDate: yup.string(),
        endDate: yup.string(),
        allotmentCreditsPerUser: yup.number().required(),
        emissionTargets: yup.array().of(
          yup.object().shape({
            type: yup.string().required('Required'),
            subtype: yup.string().required('Required'),
          }),
        ),
      }),
    )
    .min(1),
});

type Props = {
  subscription?: CompanySubscription;
  creditBalance?: number;
  open?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

const CompanySubscriptionModal: React.FC<Props> = ({
  subscription,
  creditBalance,
  open,
  onCancel,
  onSubmit,
}) => {
  const { company, refetch, isInheritingSubscriptionData } = useContext(
    CompanySettingsContext,
  );
  const [addSubscription, { loading, error }] =
    useMutation(UPSERT_SUBSCRIPTION);
  const [addCredits, { loading: creditsLoading, error: creditsError }] =
    useMutation(UPSERT_CREDIT_HISTORY);
  const {
    data: { emissionsSettings = [] } = {},
    loading: enumsLoading,
    error: enumsError,
  } = useQuery(GET_EMISSIONS_SETTINGS);
  const { userHasAccess } = useContext(AuthorizationContext);
  const canEdit =
    userHasAccess('ZeroMe.SubscriptionManagement', 'EDIT') &&
    (!subscription || !isInheritingSubscriptionData);

  const disabled =
    (subscription && moment().isAfter(subscription.startDate)) || !canEdit;

  const types = emissionsSettings?.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.allocType]: {
        allocTypeLabel: curr.allocTypeLabel,
        subTypes: {
          ...prev[curr.allocType]?.subTypes,
          [curr.allocSubType]: curr.allocSubTypeLabel,
        },
      },
    }),
    {} as AllocTypeMapping,
  );

  const setEndDate = (date: Date | string) =>
    moment(date).add(1, 'year').add(-1, 'day').toDate();

  const lastEndDate = company?.subscriptionData?.subscriptionHistory?.reduce(
    (acc, cur) => (cur.endDate > acc ? cur.endDate : acc),
    '',
  );

  const newStartDate = lastEndDate
    ? moment(lastEndDate).add(1, 'day').toDate()
    : new Date();

  const initialValues = subscription
    ? {
        ...subscription,
        marketplaceCredits: creditBalance || 0,
        allotments: subscription.allotments ?? [],
      }
    : {
        startDate: newStartDate.toISOString(),
        endDate: setEndDate(newStartDate).toISOString(),
        licenseFee: 0,
        marketplaceCredits: 0,
        notes: '',
        allotments: [
          {
            startDate: newStartDate.toISOString(),
            endDate: setEndDate(newStartDate).toISOString(),
            allotmentCreditsPerUser: 1,
            emissionTargets: [
              {
                type: 'ALL',
                subtype: '',
                priority: 1,
              },
            ],
          },
        ],
      };

  const handleSubmit = (
    values: Partial<CompanySubscription> & {
      marketplaceCredits: number;
    },
  ) => {
    addSubscription({
      variables: {
        companyId: company.id,
        input: {
          id: values.id,
          startDate: moment(values.startDate).format('YYYY-MM-DD'),
          endDate: moment(values.endDate).format('YYYY-MM-DD'),
          licenseFee: values.licenseFee,
          notes: values.notes,
          allotments: values.allotments?.map((a) =>
            _.omit(
              {
                ...a,
                startDate: moment(a.startDate).format('YYYY-MM-DD'),
                endDate: moment(a.endDate).format('YYYY-MM-DD'),
                emissionTargets: a.emissionTargets.map((et, i) =>
                  _.omit(
                    {
                      ...et,
                      priority: i + 1,
                    },
                    '__typename',
                  ),
                ),
              },
              '__typename',
            ),
          ),
        },
      },
      onCompleted: async (data) => {
        if (values.marketplaceCredits > 0) {
          addCredits({
            variables: {
              companyId: company.id,
              input: {
                date: new Date().toISOString(),
                amount: values.marketplaceCredits - (creditBalance || 0),
                type: 'CREDIT',
                subscriptionId: data.upsertSubscription.id,
              },
            },
            onCompleted: async () => {
              await refetch();
              onSubmit();
            },
          });
        } else {
          await refetch();
          onSubmit();
        }
      },
    });
  };

  const code =
    error?.graphQLErrors[0]?.extensions?.code ||
    creditsError?.graphQLErrors[0]?.extensions?.code;

  const showFetchError = !code && (error || creditsError || enumsError);

  const areEmissionTargetsValid = (
    targets: { type: string; subtype?: string }[],
  ): boolean => {
    const m = targets.map((t) => `${t.type}-${t.subtype ?? ''}`);

    // no duplicates
    if (new Set(m).size !== m.length) {
      return false;
    }

    // require all
    for (const t of targets) {
      if (!t.type || !t.subtype) {
        return false;
      }
    }

    return true;
  };

  const getSubtypeOptions = (t: string): { label: string; value: string }[] => {
    const typeObject = types[t];
    const subTypes =
      typeObject && typeObject.subTypes
        ? Object.keys(typeObject.subTypes).map((st) => ({
            value: st,
            label: typeObject.subTypes[st],
          }))
        : [];

    return subTypes.length > 1
      ? [
          {
            value: 'all',
            label: 'All',
          },
          ...subTypes,
        ]
      : [
          {
            value: 'all',
            label: 'All',
          },
        ];
  };

  return (
    <Dialog open={!!open} onClose={onCancel} maxWidth="md">
      <DialogContent>
        <DataStateHandler
          loading={loading || creditsLoading || enumsLoading}
          error={showFetchError}>
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values) => handleSubmit(values)}>
            {(props) => (
              <fieldset disabled={disabled} style={{ border: 0 }}>
                <Grid container direction="column" width={600}>
                  <Typography variant="h3" mb={2}>
                    {subscription
                      ? `${!disabled ? 'Edit' : ''} Subscription Details`
                      : 'Create New Subscription'}
                  </Typography>
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">Start Date</Typography>
                        <Field
                          name="startDate"
                          label=""
                          onChange={(dt: string) => {
                            props.setFieldValue('endDate', setEndDate(dt));
                          }}
                          error={!!code}
                          helperText={code}
                          component={AppDatePicker}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle1">End Date</Typography>
                        <Typography variant="body1" mt={2}>
                          {formatDateStringUtc(
                            props.values.endDate,
                            'M/D/yyyy',
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <Typography variant="body1" mb={2}>
                          Allotment Configuration
                        </Typography>
                        <FieldArray
                          name="allotments"
                          render={() => (
                            <Grid container direction="column">
                              {props.values.allotments.map((allotment, idx) => (
                                <Grid
                                  key={allotment.startDate}
                                  item
                                  container
                                  spacing={2}
                                  direction="row">
                                  <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                      Credits Per User
                                    </Typography>
                                    <Field
                                      name={`allotments[${idx}].allotmentCreditsPerUser`}
                                      label=""
                                      type="number"
                                      component={AppTextField}
                                      disabled={props.isSubmitting}
                                      placeholder=""
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                      Start Date
                                    </Typography>
                                    <Field
                                      name={`allotments[${idx}].startDate`}
                                      label=""
                                      component={AppDatePicker}
                                      disabled={props.isSubmitting}
                                      minDate={moment(
                                        props.values.startDate,
                                      ).toDate()}
                                      maxDate={moment(
                                        props.values.endDate,
                                      ).toDate()}
                                      placeholder=""
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="subtitle1">
                                      End Date
                                    </Typography>
                                    <Field
                                      name={`allotments[${idx}].endDate`}
                                      label=""
                                      component={AppDatePicker}
                                      disabled={props.isSubmitting}
                                      placeholder=""
                                      minDate={moment(
                                        props.values.startDate,
                                      ).toDate()}
                                      maxDate={moment(
                                        props.values.endDate,
                                      ).toDate()}
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid container direction="column">
                                      <Grid item>
                                        <FieldArray
                                          name={`allotments[${idx}].emissionTargets`}
                                          render={(helpers) => (
                                            <Grid container direction="column">
                                              <Grid item>
                                                <Grid
                                                  item
                                                  container
                                                  alignItems="center"
                                                  justifyContent="space-between">
                                                  <Typography variant="body2">
                                                    Emission Targets
                                                  </Typography>
                                                  <Button
                                                    size="small"
                                                    variant="text"
                                                    onClick={() =>
                                                      helpers.push({
                                                        type: '',
                                                        subtype: '',
                                                      })
                                                    }>
                                                    Add
                                                  </Button>
                                                </Grid>

                                                <hr />
                                              </Grid>
                                              {allotment.emissionTargets.map(
                                                (emissionTarget, eidx) => (
                                                  <Grid
                                                    key={
                                                      emissionTarget.priority
                                                    }
                                                    item
                                                    container
                                                    spacing={2}
                                                    mb={2}
                                                    alignItems="center"
                                                    direction="row">
                                                    <Grid item xs={5}>
                                                      <Typography variant="subtitle1">
                                                        Type
                                                      </Typography>
                                                      <Field
                                                        name={`allotments[${idx}].emissionTargets[${eidx}].type`}
                                                        label=""
                                                        component={AppTextField}
                                                        disabled={
                                                          props.isSubmitting ||
                                                          disabled
                                                        }
                                                        placeholder=""
                                                        fullWidth
                                                        select
                                                        options={[
                                                          {
                                                            value: 'all',
                                                            label: 'All',
                                                          },
                                                          ...Object.keys(types)
                                                            .filter(
                                                              (o) =>
                                                                types[o]
                                                                  .allocTypeLabel,
                                                            )
                                                            .map((type) => ({
                                                              value: type,
                                                              label:
                                                                types[type]
                                                                  .allocTypeLabel,
                                                            })),
                                                        ]}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                      <Typography variant="subtitle1">
                                                        Subtype
                                                      </Typography>
                                                      <Field
                                                        name={`allotments[${idx}].emissionTargets[${eidx}].subtype`}
                                                        label=""
                                                        component={AppTextField}
                                                        disabled={
                                                          props.isSubmitting ||
                                                          disabled
                                                        }
                                                        placeholder=""
                                                        fullWidth
                                                        select
                                                        options={[
                                                          ...getSubtypeOptions(
                                                            props.values
                                                              .allotments[idx]
                                                              .emissionTargets[
                                                              eidx
                                                            ].type,
                                                          ),
                                                        ]}
                                                      />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                      {eidx > 0 && (
                                                        <IconButton
                                                          onClick={() =>
                                                            helpers.remove(eidx)
                                                          }>
                                                          <SVG.Delete
                                                            style={{
                                                              width: 32,
                                                              height: 32,
                                                            }}
                                                          />
                                                        </IconButton>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                ),
                                              )}
                                            </Grid>
                                          )}
                                        />
                                      </Grid>
                                      <Grid item>
                                        {areEmissionTargetsValid(
                                          props.values.allotments[0]
                                            ?.emissionTargets ?? [],
                                        ) ? null : (
                                          <Typography
                                            variant="subtitle1"
                                            color="error">
                                            Emission Targets are required and
                                            must be unique
                                          </Typography>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} mt={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="body1">ZeroMe Fee</Typography>
                        <Field
                          name="licenseFee"
                          label=""
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          component={AppTextField}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body1">
                          Marketplace Offset Credits
                        </Typography>
                        <Field
                          name="marketplaceCredits"
                          label=""
                          type="number"
                          component={AppTextField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          disabled={props.isSubmitting}
                          placeholder=""
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item mt={2}>
                    <Typography variant="body1">Notes</Typography>
                    <Field
                      name="notes"
                      label=""
                      component={AppTextField}
                      disabled={props.isSubmitting}
                      placeholder=""
                      multiline
                      rows={4}
                      fullWidth
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <Button
                          disabled={
                            disabled ||
                            !areEmissionTargetsValid(
                              props.values.allotments[0]?.emissionTargets ?? [],
                            )
                          }
                          variant="contained"
                          onClick={props.submitForm}>
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </fieldset>
            )}
          </Formik>
        </DataStateHandler>
      </DialogContent>
    </Dialog>
  );
};

export default CompanySubscriptionModal;
