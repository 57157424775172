import articleReadDisabled from './ArticleReadDisabled';
import articleReadEnabled from './ArticleReadEnabled';

const articleRead = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled ? articleReadDisabled(level, size) : articleReadEnabled(level, size);

export default articleRead;
