import { Grid, Typography, Button, Divider } from '@mui/material';
import { Field, useFormikContext, getIn, Formik } from 'formik';
import React from 'react';
import _ from 'lodash';
import * as yup from 'yup';

import AppTextField from '../common/AppTextField';
import { QuestionProfile } from '../../types/question/types';

const schema = yup.object().shape({
  questionId: yup.string().required('Question is required'),
  answerId: yup.string().required('Answer is required'),
  comment: yup.string(),
});

type Props = {
  questions: QuestionProfile[];
  onSave: ({
    questionId,
    answerId,
    comment,
  }: {
    questionId: string;
    answerId: string;
    comment: string;
  }) => void;
  path: string;
};

const HabitConditionQuestionSelectorModal: React.FC<Props> = ({
  onSave,
  path,
  questions,
}) => {
  const { values } = useFormikContext();

  const allQuestions = _.sortBy(
    questions.flatMap((qc) => qc.questions).filter((q) => q.options),
    'questionText',
  );

  const selectedQuestionId = getIn(values, `${path}.questionId`);

  const selectedAnswerId = getIn(values, `${path}.answerId`);
  const comment = getIn(values, `${path}.comment`);

  const initialValues = {
    questionId: selectedQuestionId,
    answerId: selectedAnswerId,
    comment,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (v) => {
        onSave(v);
      }}
      validateOnMount>
      {(props) => {
        const selectedQuestion = allQuestions.find(
          (q) => q.questionId === props.values.questionId,
        );
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">Question</Typography>
              <Field
                label=""
                component={AppTextField}
                fullWidth
                select
                name="questionId"
                options={allQuestions.map((q) => ({
                  label: q.questionText,
                  value: q.questionId,
                }))}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Response</Typography>
              <Field
                label=""
                component={AppTextField}
                fullWidth
                name="answerId"
                select
                options={
                  selectedQuestion
                    ? selectedQuestion.options?.map((o) => ({
                        label: o.answerText,
                        value: o.answerId,
                      }))
                    : []
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Comment</Typography>
              <Field
                label=""
                component={AppTextField}
                fullWidth
                name="comment"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Question ID</Typography>
              <Field
                label=""
                disabled
                component={AppTextField}
                fullWidth
                name="questionId"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Answer ID</Typography>
              <Field
                label=""
                disabled
                component={AppTextField}
                fullWidth
                name="answerId"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  disabled={props.isSubmitting || !props.isValid}
                  variant="contained"
                  onClick={props.submitForm}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
};

export default HabitConditionQuestionSelectorModal;
