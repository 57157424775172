import { Box, Grid, ListItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import SVG from '../../../assets/svg';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';

const useStyles = makeStyles((theme) => ({
  active: {
    background: theme.palette.buttons.primary.disabled,
  },
  root: {
    padding: 8,
    borderRadius: 8,
    cursor: 'pointer',
  },
  text: {
    fontSize: '1.125rem',
  },
  stepCount: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: '1px solid #9e9e9e',
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeStep: {
    background: theme.palette.buttons.primary.active,
    borderColor: theme.palette.buttons.primary.active,
  },
  errorStep: {
    background: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    color: '#FFF',
  },
  validStep: {
    borderColor: theme.palette.buttons.primary.active,
  },
  stepText: {
    fontSize: '1.125rem',
  },
  activeStepText: {
    color: '#FFFFFF',
  },
}));

type Props = {
  isCompleted?: boolean;
  title: string;
  step: ProjectDetailsStep;
  isValid: boolean;
};

const CreateProjectNavigationItem: React.FC<Props> = ({
  isCompleted,
  title,
  step,
  isValid,
}) => {
  const { setCurrentDetailsStep, currentDetailsStep } =
    useContext(ProjectContext);
  const classes = useStyles();
  const isActive = step === currentDetailsStep;
  const showCompletedState = !isActive && isCompleted;
  return (
    <Grid item>
      <ListItem
        onClick={() => setCurrentDetailsStep(step)}
        className={`${classes.root} ${isActive ? classes.active : ''}`}>
        <Box
          className={`${classes.stepCount} ${
            isActive && isValid ? classes.activeStep : ''
          } ${showCompletedState && isValid ? classes.validStep : ''}
          ${!isValid ? classes.errorStep : ''}`}>
          <Typography
            variant="body1"
            className={`${classes.stepText} ${
              isActive && isValid ? classes.activeStepText : ''
            }
            ${!isValid ? classes.errorStep : ''}`}>
            {showCompletedState ? <SVG.Check /> : step}
          </Typography>
        </Box>
        <Typography
          className={classes.text}
          variant="body1"
          color={showCompletedState ? 'textSecondary' : 'textPrimary'}>
          {title}
        </Typography>
      </ListItem>
    </Grid>
  );
};

export default CreateProjectNavigationItem;
