import { Grid } from '@mui/material';
import React from 'react';

import LogoBadge from '../../assets/img-v2/zerome-logo-badge.png';

type Props = {
  height: number;
  innerHeight?: number | string;
  innerWidth?: number | string;
  style?: React.CSSProperties;
  className?: string;
};

const DefaultImage: React.FC<Props> = ({
  height,
  innerHeight,
  innerWidth,
  style,
  className,
}) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    style={style}
    className={className}
    sx={{ bgcolor: '#EFEFEF', height }}>
    <img
      src={LogoBadge}
      alt="logo"
      style={{
        margin: 8,
        height: innerHeight || '40%',
        width: innerWidth || 'initial',
      }}
    />
  </Grid>
);

export default DefaultImage;
