import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type StyleProps = {
  padding?: number;
  overflow?: string;
};

const useStyles = makeStyles(() => ({
  flexWrapper: {
    display: 'flex',
    flex: 1,
    position: 'relative',
    borderRadius: 16,
    filter: 'drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12))',
    backgroundColor: 'white',
    height: '100%',
  },
  absoluteWrapper: {
    left: (props: StyleProps) => props.padding,
    right: (props: StyleProps) => props.padding,
    bottom: (props: StyleProps) => props.padding,
    top: (props: StyleProps) => props.padding,
    position: 'absolute',
    overflow: (props: StyleProps) => props.overflow,
  },
  groupIcon: {
    height: 16,
    marginTop: 4,
  },
}));

type Props = {
  children: JSX.Element;
} & StyleProps;

const RoundedFlexBox: React.FC<Props> = ({
  children,
  padding = 10,
  overflow = 'auto',
}) => {
  const classes = useStyles({ padding, overflow });

  return (
    <Box mb={3} className={classes.flexWrapper}>
      <div className={classes.absoluteWrapper}>{children}</div>
    </Box>
  );
};

export default RoundedFlexBox;
