import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import EmployeesList from '../../components/user/EmployeesList';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { DashboardContext } from '../../contexts/DashboardContext';
import useInitializedRef from '../../hooks/useInitializedRef';
import { ClientDashboardProvider } from '../../components/routing/AdminRouter';

const useStyles = makeStyles(() => ({
  buttonPortal: {
    position: 'absolute',
    top: -140,
    right: 0,
    width: 650,
    zIndex: 3,
  },
}));

const EmployeeSettingsList: React.FC = () => {
  const classes = useStyles();
  const ref = useInitializedRef();
  const { company, division, includeChildren } = useContext(DashboardContext);

  return (
    <>
      <div ref={ref} className={classes.buttonPortal} />
      <EmployeesList
        companyId={company.id}
        includeChildren={includeChildren}
        divisionId={division || company.id}
        portalRef={ref}
      />
    </>
  );
};

const EmployeeSettings: React.FC = () => {
  const { company } = useContext(CompanySettingsContext);

  return (
    <ClientDashboardProvider companyOverride={company}>
      <Grid
        container
        direction="column"
        style={{ height: 'calc(100vh - 190px)', position: 'relative' }}>
        <EmployeeSettingsList />
      </Grid>
    </ClientDashboardProvider>
  );
};

export default EmployeeSettings;
