import React from 'react';

const questionsCompletedResidentialEnabled = (
  level: number | undefined,
  size: number,
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3236 62.9206C3.97867 53.5756 3.97867 38.4244 13.3236 29.0794L29.0798 13.3233C38.4247 3.97833 53.5759 3.97833 62.9209 13.3233L78.677 29.0794C88.022 38.4244 88.022 53.5756 78.677 62.9206L62.9209 78.6767C53.5759 88.0217 38.4247 88.0217 29.0798 78.6767L13.3236 62.9206Z"
      fill="white"
    />
    <path
      d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
      fill="url(#paint0_angular_5400_144400)"
    />
    <mask
      id="mask0_5400_144400"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
        fill="url(#paint1_angular_5400_144400)"
      />
    </mask>
    <g mask="url(#mask0_5400_144400)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.8156 38.4164C72.2305 38.0015 72.9028 38.0012 73.3174 38.4158L83.6407 48.7391C88.1317 53.2301 88.1287 60.5144 83.6341 65.0091C79.1394 69.5037 71.8551 69.5067 67.3641 65.0157L22.4767 20.1283C18.2326 15.8842 11.3488 15.887 7.10126 20.1346C2.85371 24.3821 2.85089 31.266 7.09495 35.51L49.4841 77.8992C49.8986 78.3137 49.8983 78.986 49.4835 79.4009C49.0686 79.8157 48.3963 79.816 47.9817 79.4015L5.5926 37.0124C0.519503 31.9393 0.522884 23.7108 5.60015 18.6335C10.6774 13.5562 18.9059 13.5528 23.979 18.6259L68.8665 63.5134C72.5284 67.1753 78.468 67.1729 82.133 63.5079C85.7979 59.843 85.8003 53.9034 82.1384 50.2415L71.815 39.9181C71.4005 39.5036 71.4008 38.8312 71.8156 38.4164Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88961 53.8896 2.88961 38.1104 12.6219 28.3781L28.3781 12.6219C38.1104 2.88961 53.8896 2.88961 63.6219 12.6219L79.3781 28.3781C89.1104 38.1104 89.1104 53.8896 79.3781 63.6219L63.6219 79.3781C53.8896 89.1104 38.1104 89.1104 28.3781 79.3781L12.6219 63.6219ZM14.0247 29.7808C5.06706 38.7384 5.06706 53.2616 14.0247 62.2192L29.7808 77.9753C38.7384 86.9329 53.2616 86.9329 62.2192 77.9753L77.9753 62.2192C86.9329 53.2616 86.9329 38.7384 77.9753 29.7808L62.2192 14.0247C53.2616 5.06707 38.7384 5.06706 29.7808 14.0247L14.0247 29.7808Z"
      fill="#022131"
    />
    <path
      d="M28.2327 45.8855L30.7184 42.975V26.9677L45.6343 16.7812L60.914 26.9677V42.975L62.785 45.8855V70.7011H28.2327V45.8855Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.5552 70.9498C52.094 70.9498 52.5308 70.513 52.5308 69.9742L52.5308 56.1533C52.5308 55.6145 52.094 55.1777 51.5552 55.1777L40.3257 55.1777C39.7869 55.1777 39.3501 55.6145 39.3501 56.1533L39.3501 69.9742C39.3501 70.513 39.7869 70.9498 40.3257 70.9498C40.8645 70.9498 41.3013 70.513 41.3013 69.9742L41.3013 57.1289L50.5796 57.1289L50.5796 69.9742C50.5796 70.513 51.0164 70.9498 51.5552 70.9498Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.4905 15.6125C46.1592 15.387 45.7237 15.387 45.3924 15.6125L35.6861 22.2211V18.8986H29.8478V26.1961L25.0929 29.4334C24.6476 29.7367 24.5323 30.3435 24.8356 30.7889C25.1388 31.2343 25.7457 31.3495 26.191 31.0463L29.8478 28.5565V42.8908L22.4622 48.4649C22.0321 48.7894 21.9466 49.4012 22.2712 49.8313C22.5957 50.2613 23.2075 50.3468 23.6375 50.0223L27.2569 47.2906V70.8387C27.2569 71.3775 27.6937 71.8143 28.2325 71.8143H62.7848C63.3236 71.8143 63.7604 71.3775 63.7604 70.8387V46.6384L68.2439 50.0223C68.674 50.3468 69.2858 50.2613 69.6103 49.8313C69.9349 49.4012 69.8494 48.7894 69.4193 48.4649L62.0331 42.8904V28.5552L65.6919 31.0463C66.1372 31.3495 66.7441 31.2343 67.0473 30.7889C67.3506 30.3435 67.2353 29.7367 66.79 29.4334L46.4905 15.6125ZM61.8092 45.1659L45.9407 33.1897L29.2081 45.8181V69.8632H61.8092V45.1659ZM60.082 41.4178V27.2268L45.9414 17.5992L31.799 27.2281V41.4182L45.3531 31.1887C45.7009 30.9262 46.1806 30.9262 46.5284 31.1887L60.082 41.4178ZM31.799 24.8676L33.7349 23.5495V20.8498H31.799V24.8676Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.3717 22.3535C45.1966 22.3535 44.0696 22.8203 43.2387 23.6513C42.4077 24.4822 41.9409 25.6092 41.9409 26.7843V27.7599L50.8025 27.7599V26.7843C50.8025 25.6092 50.3357 24.4822 49.5048 23.6513C48.6738 22.8203 47.5468 22.3535 46.3717 22.3535ZM44.6183 25.0309C45.0834 24.5659 45.7141 24.3047 46.3717 24.3047C47.0294 24.3047 47.6601 24.5659 48.1251 25.0309C48.3507 25.2565 48.5283 25.521 48.6514 25.8087L44.092 25.8087C44.2152 25.521 44.3928 25.2565 44.6183 25.0309Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5465 66.3669C22.8873 64.6877 24.3719 63.4238 26.1517 63.4238C27.9315 63.4238 29.4161 64.6877 29.7569 66.3669H31.5477C33.8503 66.3669 35.717 68.2336 35.717 70.5362C35.717 72.8389 33.8503 74.7056 31.5477 74.7056H21.247C18.9443 74.7056 17.0776 72.8389 17.0776 70.5362C17.0776 68.2336 18.9443 66.3669 21.247 66.3669H22.5465Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1507 64.4154C24.8519 64.4154 23.7664 65.3379 23.5175 66.5639L23.3562 67.3585H21.2459C19.4911 67.3585 18.0685 68.7811 18.0685 70.5359C18.0685 72.2908 19.4911 73.7134 21.2459 73.7134H31.5466C33.3015 73.7134 34.7241 72.2908 34.7241 70.5359C34.7241 68.7811 33.3015 67.3585 31.5466 67.3585H28.9451L28.7838 66.5639C28.535 65.3379 27.4494 64.4154 26.1507 64.4154ZM21.8102 65.3747C22.4971 63.6504 24.1807 62.4316 26.1507 62.4316C28.1206 62.4316 29.8042 63.6504 30.4911 65.3747H31.5466C34.3971 65.3747 36.7079 67.6855 36.7079 70.5359C36.7079 73.3864 34.3971 75.6971 31.5466 75.6971H21.2459C18.3955 75.6971 16.0847 73.3864 16.0847 70.5359C16.0847 67.6855 18.3955 65.3747 21.2459 65.3747H21.8102Z"
      fill="#022131"
    />
    {level ? (
      <g clipPath="url(#clip0_5400_144400)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#2EF699" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#022131"
        />
        <text
          x="74"
          y="77.5"
          fill="#022131"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_5400_144400"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.83042 37.6286) rotate(69.4614) scale(95.0646 110.608)">
        <stop offset="0.387843" stopColor="#2EF699" />
        <stop offset="0.401164" stopColor="#2D63F9" />
        <stop offset="0.668561" stopColor="#2D9AD5" />
        <stop offset="0.953715" stopColor="#2EF699" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_5400_144400"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1908 45.9977) rotate(-8.79121) scale(53.9478 54.824)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_5400_144400">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default questionsCompletedResidentialEnabled;
