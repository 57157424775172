import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid, Typography, InputAdornment, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useLazyQuery } from '@apollo/client';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';
import { GENERATE_DEFAULT_FISCAL_PERIODS } from '../../graphql/company/company';
import {
  BudgetSettings,
  CompanyFiscalPeriodSettings,
  CompanyFiscalYearSettings,
  PlanPeriodType,
} from '../../types/company/types';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import SettingsModalInput from './SettingsModalInput';
import BudgetPanelChangePeriodModal from './BudgetPanelChangePeriodModal';
import { Disableable } from '../../types/common';

const useStyles = makeStyles(() => ({
  totalSpend: {
    padding: '24px 16px',
    border: '1px solid #BDD9C5',
    borderRadius: 8,
    marginTop: 40,
  },
  spendPeriodButton: {
    borderColor: '#BDD9C5',
    padding: 20,
    marginBottom: 8,
  },
}));

type Props = Disableable & {
  selectedYear: number;
};

const BudgetPanelYearlySpend: React.FC<Props> = ({
  disabled,
  selectedYear,
}) => {
  const classes = useStyles();
  const [generateDefaultFiscalPeriods, { loading: defaultPeriodsLoading }] =
    useLazyQuery<{
      generateDefaultFiscalPeriods: CompanyFiscalPeriodSettings[];
    }>(GENERATE_DEFAULT_FISCAL_PERIODS);
  const { values, setNestedTypedFieldValue } =
    useTypedFormikContext<BudgetSettings>();

  const { fiscalYears, fiscalYearStartDate } = values;

  const fiscalYearIndex = fiscalYears.findIndex(
    (x) => x.fiscalYear === selectedYear,
  );

  const fiscalYear = fiscalYears[fiscalYearIndex];
  const fiscalYearFormPrefix = `fiscalYears[${fiscalYearIndex}]`;
  const [prevFiscalYearStartDate, setPrevFiscalYearStartDate] =
    useState(fiscalYearStartDate);

  const updatePlanPeriodType = async (planPeriodType: PlanPeriodType) => {
    const result = await generateDefaultFiscalPeriods({
      variables: {
        fiscalYear: selectedYear,
        fiscalYearStartDate,
        planPeriodType,
      },
    });

    if (result.data) {
      setNestedTypedFieldValue<CompanyFiscalYearSettings>(
        fiscalYearFormPrefix,
        'periods',
        fiscalYear.periods && fiscalYear.periods.length
          ? result.data.generateDefaultFiscalPeriods.map((x, i) => ({
              ...x,
              ...(fiscalYear.periods[i]
                ? { amount: fiscalYear.periods[i].amount }
                : {}),
            }))
          : result.data.generateDefaultFiscalPeriods,
      );

      setNestedTypedFieldValue<CompanyFiscalYearSettings>(
        fiscalYearFormPrefix,
        'planPeriodType',
        planPeriodType,
      );
    }
  };

  useEffect(() => {
    if (fiscalYearStartDate !== prevFiscalYearStartDate) {
      setPrevFiscalYearStartDate(fiscalYearStartDate);
      updatePlanPeriodType(fiscalYear.planPeriodType);
    }
  }, [fiscalYearStartDate]);

  return (
    <>
      {!fiscalYear.planPeriodType && (
        <Grid item style={{ marginTop: 32 }}>
          <Typography variant="h3" style={{ marginTop: 40, marginBottom: 24 }}>
            Projected Spend
          </Typography>
          <Typography variant="body1">
            Select the option below that you would like to plan your
            company&apos;s spending by:
          </Typography>
          <Button
            variant="outlined"
            fullWidth
            disabled={disabled}
            style={{ marginTop: 32 }}
            className={classes.spendPeriodButton}
            onClick={() => updatePlanPeriodType('MONTHLY')}>
            Monthly
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={disabled}
            className={classes.spendPeriodButton}
            onClick={() => updatePlanPeriodType('QUARTERLY')}>
            Quarterly
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={disabled}
            className={classes.spendPeriodButton}
            onClick={() => updatePlanPeriodType('YEARLY')}>
            Annually
          </Button>
        </Grid>
      )}
      {!!fiscalYear.planPeriodType && (
        <DataStateHandler loading={defaultPeriodsLoading}>
          <>
            <Grid item container>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                style={{ marginTop: 40, marginBottom: 24 }}>
                <Typography variant="h3">
                  Projected{' '}
                  {
                    {
                      QUARTERLY: 'Spend per Quarter',
                      MONTHLY: 'Spend per Month',
                      YEARLY: 'Annual Spend',
                    }[fiscalYear.planPeriodType]
                  }
                </Typography>
                {!disabled && (
                  <BudgetPanelChangePeriodModal
                    onClose={(reset) => {
                      if (reset) {
                        setNestedTypedFieldValue<CompanyFiscalYearSettings>(
                          fiscalYearFormPrefix,
                          'planPeriodType',
                          '',
                        );
                        setNestedTypedFieldValue<CompanyFiscalYearSettings>(
                          fiscalYearFormPrefix,
                          'periods',
                          [],
                        );
                      }
                    }}
                  />
                )}
              </Grid>
              {fiscalYear.periods?.map((x, i) => (
                <SettingsModalInput<CompanyFiscalPeriodSettings>
                  key={x.label}
                  label={x.label}
                  disabled={disabled}
                  modalLabel={`Edit ${x.label} Budget`}
                  validationSchema={yup.object().shape({
                    amount: yup
                      .number()
                      .required('Required')
                      .typeError('Please enter a number')
                      .min(0, 'Amount must be positive')
                      .integer('Enter whole dollar amounts only'),
                  })}
                  displayValue={parseInt(
                    x.amount.toString(),
                    10,
                  ).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                  textFieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            variant="body1"
                            style={{ marginBottom: -2 }}>
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                    },
                  }}
                  initialValues={{ amount: x.amount }}
                  onSave={(data) =>
                    setNestedTypedFieldValue<CompanyFiscalPeriodSettings>(
                      `${fiscalYearFormPrefix}.periods[${i}]`,
                      'amount',
                      data.amount,
                    )
                  }
                />
              ))}
            </Grid>
            <Grid
              item
              container
              className={classes.totalSpend}
              direction="row"
              justifyContent="space-between">
              <Grid item>
                <Typography variant="h3">Total Fiscal Year Spend</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3">
                  {fiscalYear.periods
                    ?.reduce(
                      (acc, cur) => acc + parseInt(cur.amount.toString(), 10),
                      0,
                    )
                    .toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}
                </Typography>
              </Grid>
            </Grid>
          </>
        </DataStateHandler>
      )}
    </>
  );
};

export default BudgetPanelYearlySpend;
