import React from 'react';
import BlogPostForm from './BlogPostForm';
import HintsAndTipsForm from './HintsAndTipsForm';
import AudioForm from './AudioForm';
import VideoForm from './VideoForm';
import { CommunicationType } from '../types';

type Props = {
  communicationType: CommunicationType;
  disabled?: boolean;
};

const CommunicationForm: React.FC<Props> = ({ communicationType, disabled }) => {
  switch (communicationType) {
    case CommunicationType.BLOG_POST:
      return <BlogPostForm disabled={disabled} />;
    case CommunicationType.HINT:
      return <HintsAndTipsForm disabled={disabled} />;
    case CommunicationType.AUDIO:
      return <AudioForm disabled={disabled} />;
    case CommunicationType.VIDEO:
      return <VideoForm disabled={disabled} />;
    default:
      return null;
  }
};

export default CommunicationForm;
