import React from 'react';
import { CategoryInterest } from '../../../../types/dashboard/types';
import NumberUtils from '../../../../utils/numberUtils';
import AppCard from '../../../common/AppCard/AppCard';
import CardFooter from '../../../common/CardFooter/CardFooter';
import ProfileCompletionItem from './ProfileCompletionItem';
import NoDataAvailable from '../../../common/NoDataAvailable';

type Props = {
  profileCompletion: {
    total: number;
    completedAllCategories: number;
    completedAllCategoriesPercentOfTotal: number;
    categories: Array<CategoryInterest>;
  };
  height?: number;
};

const ProfileCompletionCard: React.FC<Props> = ({
  profileCompletion,
  height,
}) => {
  const hasData = profileCompletion.categories?.some((cat) => cat.value > 0);
  return (
    <AppCard
      headerProps={{
        title: 'PROFILE COMPLETION',
        tooltipId: 'profileCompletion',
      }}
      height={height || 635}>
      {hasData ? (
        <>
          {profileCompletion.categories?.map((cat) => (
            <ProfileCompletionItem key={cat.id} category={cat} />
          ))}
          <CardFooter
            data={NumberUtils.format(
              profileCompletion.completedAllCategoriesPercentOfTotal || 0,
              'wholePercent',
            )}
            label="Total Profile Completion"
          />
        </>
      ) : (
        <NoDataAvailable size="small" />
      )}
    </AppCard>
  );
};

export default ProfileCompletionCard;
