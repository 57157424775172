import React from 'react';
import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { WaterFootprintItem } from '../../../types/dashboard/types';
import SVG from '../../../assets/svg';
import WaterFootprintDropChart from '../../common/WaterFootprintDropChart';
import ChartLabel from '../../common/ChartLabel/ChartLabel';
import theme from '../../../theme/memberTheme';

type Props = {
  title: string;
  onClose: () => void;
  data?: WaterFootprintItem[];
};

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 8,
    padding: 16,
    minWidth: 350,
    background: '#FFF',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const MemberWaterFootprintCard: React.FC<Props> = ({
  title,
  onClose,
  data = [],
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      item
      flexDirection="column"
      xs={12}
      md={5}
      container
      direction="column"
      className={classes.root}
      style={
        isSm
          ? {}
          : {
              position: 'relative',
              top: 24,
              marginBottom: 24,
            }
      }>
      <Grid item>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">{title}</Typography>
          <IconButton onClick={onClose}>
            <SVG.Close />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item container justifyContent="center">
        <WaterFootprintDropChart
          staticTooltipStyle={{
            left: 16,
            top: 44,
            position: 'absolute',
          }}
          items={data}
        />
      </Grid>
      {data.map((item) => (
        <ChartLabel
          key={item.id}
          label={item.id}
          color={item.color}
          size="small"
          value={`${Intl.NumberFormat('en-US', {
            notation: 'compact',
            maximumFractionDigits: 0,
          }).format(item.value)} Liters`}
        />
      ))}
    </Grid>
  );
};

export default MemberWaterFootprintCard;
