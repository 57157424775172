import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    paddingBottom: 32,
  },
  header: {
    marginBottom: 32,
  },
}));

type Props = {
  header: string;
  children: JSX.Element;
};

const AppSection: React.FC<Props> = ({ header, children }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item className={classes.header}>
        <Typography variant="h3" color="textPrimary">
          {header}
        </Typography>
      </Grid>
      <Grid item>{children}</Grid>
    </Grid>
  );
};

export default AppSection;
