import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { StyledEngineProvider, GlobalStyles } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
import Error from './components/common/Error/Error';
import reportWebVitals from './reportWebVitals';
import globalStyles from './theme/globalStyles';
import AuthorizationProvider from './contexts/AuthorizationContext';
import './i18n/i18n';
import client from './apollo/client';
import ThemeSelector from './theme/ThemeSelector';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

// eslint-disable-next-line no-console
const consoleError = console.error.bind(console);

// eslint-disable-next-line no-console
console.error = (errObj, ...args) => {
  // hide findDOMNode warnings coming from material UI, they're just making it harder to see real issues:
  // https://github.com/mui/material-ui/issues/13394
  if (
    process.env.NODE_ENV === 'development' &&
    typeof errObj === 'string' &&
    errObj.includes('is deprecated in StrictMode') &&
    args[0] === 'findDOMNode'
  ) {
    return;
  }
  consoleError(errObj, ...args);
};

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<Error />}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <ApolloProvider client={client}>
          <GlobalStyles styles={globalStyles} />
          <AuthorizationProvider>
            <StyledEngineProvider injectFirst>
              <BrowserRouter>
                <ThemeSelector />
              </BrowserRouter>
            </StyledEngineProvider>
          </AuthorizationProvider>
        </ApolloProvider>
      </AppInsightsContext.Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
