import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';

import SVG from '../../../assets/svg';

type Props = {
  onFulfill?: () => void;
  onUpdate?: () => void;
  onApprove?: () => void;
  onUnapprove?: () => void;
};

const PendingOffersRowOptions: React.FC<Props> = ({
  onFulfill,
  onUpdate,
  onApprove,
  onUnapprove,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} size="large">
        <SVG.VerticalDots />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {onFulfill ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onFulfill();
            }}>
            Fulfill
          </MenuItem>
        ) : null}
        {onApprove ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onApprove();
            }}>
            Approve
          </MenuItem>
        ) : null}
        {onUnapprove ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onUnapprove();
            }}>
            Unapprove
          </MenuItem>
        ) : null}
        {onUpdate ? (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              onUpdate();
            }}>
            Update
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default PendingOffersRowOptions;
