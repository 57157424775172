import { useQuery } from '@apollo/client';
import {
  Typography,
  Grid,
  ListItemButton,
  IconButton,
  MenuItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';

import { GET_COMPANY_ACTIONS } from '../../../../graphql/company/company';
import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import { User } from '../../../../types/user/types';
import EditEmployeePanel from '../../../settings/EditEmployeePanel';
import ClientActionDataRequestRow from './ClientActionDataRequestRow';
import SVG from '../../../../assets/svg';
import { AuthorizationContext } from '../../../../contexts/AuthorizationContext';
import { CompanyActionItem } from '../../../../types/company/types';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';

const useStyles = makeStyles(() => ({
  root: {
    padding: 24,
  },
  listItem: {
    borderBottom: '1px solid #DADADA',
    paddingTop: 24,
    paddingBottom: 16,
  },
  title: {
    borderBottom: '1px solid #DADADA',
    paddingBottom: 8,
    marginBottom: 24,
  },
}));

type Props = {
  onClose: () => void;
};

const ClientActions: React.FC<Props> = ({ onClose }) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const {
    data: { companyActions = [] } = {},
    loading,
    error,
  } = useQuery(GET_COMPANY_ACTIONS);
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState<User>();
  const [filter, setFilter] = useState<'NONE' | 'REQUESTED' | 'COMPLETED'>(
    'NONE',
  );

  let filtered: CompanyActionItem[] = [];
  switch (filter) {
    case 'REQUESTED':
      filtered = companyActions.filter(
        (c) => !c.data.dataRequest.fulfilledDate,
      );
      break;
    case 'COMPLETED':
      filtered = companyActions.filter((c) => c.data.dataRequest.fulfilledDate);
      break;
    default:
      filtered = companyActions;
      break;
  }

  const canEdit = userHasAccess('ZeroMe.Clients', 'EDIT');
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectedUser ? (
        <EditEmployeePanel
          user={selectedUser}
          companyId={selectedUser.companyId}
          open={!!selectedUser}
          onClose={() => {
            setSelectedUser(undefined);
          }}
        />
      ) : (
        <DataStateHandler loading={loading} error={error}>
          <Grid container className={classes.root}>
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.title}>
                <Grid item>
                  <Grid container alignItems="center">
                    <IconButton onClick={onClose}>
                      <SVG.Back />
                    </IconButton>
                    <Typography variant="h3">Notifications</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <SelectMenu
                    key="filter"
                    id="filter"
                    onChange={(e) => {
                      setFilter(
                        e.target.value as 'NONE' | 'REQUESTED' | 'COMPLETED',
                      );
                    }}
                    inputStyle={{
                      height: 42,
                    }}
                    value={filter}>
                    <MenuItem value="NONE" key="NONE">
                      All
                    </MenuItem>
                    <MenuItem value="REQUESTED" key="REQUESTED">
                      Requests
                    </MenuItem>
                    <MenuItem value="COMPLETED" key="COMPLETED">
                      Completed
                    </MenuItem>
                  </SelectMenu>
                </Grid>
              </Grid>
            </Grid>
            {filtered.map((a, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Grid item xs={12} key={index}>
                <ListItemButton
                  className={classes.listItem}
                  disabled={!canEdit}
                  onClick={
                    canEdit ? () => setSelectedUser(a.data.user) : undefined
                  }>
                  <ClientActionDataRequestRow action={a} />
                </ListItemButton>
              </Grid>
            ))}
          </Grid>
        </DataStateHandler>
      )}
    </>
  );
};

export default ClientActions;
