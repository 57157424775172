import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { List, Grid, ListItemButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';

const useStyles = makeStyles(() => ({
  item: {
    height: 80,
    borderBottom: `1px solid #DADADA`,
    paddingRight: 0,
  },
  link: {
    textDecoration: 'none',
    color: '#0A3751',
  },
}));

const items = [
  {
    label: t('settings.faqs.title'),
    link: '/member/faqs',
  },
  {
    label: t('settings.contactUs.title'),
    link: '/member/contact',
  },
  {
    label: t('settings.termsConditions'),
    link: '/terms',
    target: '_blank',
  },
  {
    label: t('settings.privacy'),
    link: '/privacy',
    target: '_blank',
  },
  {
    label: t('settings.manageMyData.title'),
    link: '/member/manage-data',
  },
  {
    isDev: true,
    label: t('settings.development.title'),
    link: '/member/development',
  },
];

const MemberSettingsList: React.FC = () => {
  const classes = useStyles();
  const { user } = useContext(AuthorizationContext);

  const showDev = !!user?.roles.includes('platform_admin');

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h3">{t('sidenav.settings')}</Typography>
      </Grid>
      <Grid item>
        <List>
          {items
            .filter((x) => !x.isDev || showDev)
            .map((item) => (
              <Link
                to={item.link}
                key={item.label}
                className={classes.link}
                target={item.target}>
                <ListItemButton className={classes.item}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Typography variant="h3">{item.label}</Typography>
                    <SVG.CaretRightSmall />
                  </Grid>
                </ListItemButton>
              </Link>
            ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default MemberSettingsList;
