import { SelectProps, TextFieldProps, Typography } from '@mui/material';
import { DatePickerProps } from '@mui/lab';
import { Field, FieldProps } from 'formik';
import React, { useContext } from 'react';
import {
  EditableProject,
  ProjectContext,
} from '../../../contexts/ProjectContext';
import AppDatePicker from '../../common/AppDatePicker';
import AppTextField, { AppTextFieldProps } from '../../common/AppTextField';
import { isFieldRequired } from './createProjectSchema';

type Props = {
  name: keyof EditableProject;
  label?: string;
  sublabel?: string;
  onChange?: (newValue: unknown) => void;
} & (
  | ({
      fieldType: 'Text';
    } & AppTextFieldProps &
      TextFieldProps)
  | ({
      fieldType: 'Date';
    } & Omit<DatePickerProps<Date>, 'onChange' | 'value'>)
  | ({
      fieldType: 'Select';
      options?: Array<{
        value: string;
        label: string;
      }>;
    } & TextFieldProps)
);

const CreateProjectInput: React.FC<Props> = (props) => {
  const { setProjectField } = useContext(ProjectContext);
  const { name, fieldType, placeholder, label, sublabel, onChange, ...rest } =
    props;
  const Component = {
    Text: AppTextField,
    Date: AppDatePicker,
    Select: AppTextField,
  }[fieldType] as React.FC<FieldProps & { select: boolean }>;

  const isRequired = isFieldRequired(name);

  const findOption = (value: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    if (props.fieldType === 'Select') {
      // eslint-disable-next-line react/destructuring-assignment
      return (props.options || []).find((x) => x.value === value)?.label;
    }

    return value;
  };

  return (
    <>
      {label && sublabel && (
        <Typography variant="h3">
          {label} {isRequired ? '*' : ''}
        </Typography>
      )}
      {label && (
        <Typography variant="body2">
          {sublabel || label} {!sublabel && isRequired ? '*' : ''}
        </Typography>
      )}
      <Field name={name} variant="outlined" fullWidth {...rest}>
        {({ field, form, meta }: FieldProps) => (
          <Component
            field={{
              ...field,
              onChange: (e: React.ChangeEvent<Element>) => {
                if (fieldType === 'Date') {
                  setProjectField(name as string, e);
                  onChange?.(e);
                } else {
                  field.onChange(e);
                  const { value } = e.target as HTMLInputElement;

                  if (fieldType === 'Select') {
                    setProjectField(name as string, value);
                  }

                  onChange?.(value);
                }
              },
              onBlur: () => {
                if (fieldType !== 'Date') {
                  setProjectField(name as string, field.value);
                }
              },
            }}
            form={form}
            meta={meta}
            select={fieldType === 'Select'}
            {...((fieldType === 'Select' && {
              SelectProps: {
                displayEmpty: true,
                renderValue:
                  (placeholder &&
                    ((e) =>
                      e
                        ? findOption(e as string)
                        : placeholder +
                          ((!label && isRequired && ' *') || ''))) ||
                  undefined,
              } as SelectProps,
            }) ||
              {})}
            {...{
              placeholder: placeholder
                ? placeholder + ((isRequired && ' *') || '')
                : placeholder,
            }}
            {...rest}
          />
        )}
      </Field>
    </>
  );
};

export default CreateProjectInput;
