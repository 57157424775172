import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { isBefore, subHours } from 'date-fns';
import { List } from '@mui/material';
import SidebarNavigationItem from './SidebarNavigationItem';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import SidebarNavigationProfileItem from './SidebarNavigationProfileItem';
import SidebarBrand from './SidebarBrand';
import { GET_COMPANY_ACTIONS_COUNT } from '../../../graphql/company/company';
import {
  isFeatureEnabled,
  PlatformFeature,
} from '../CheckFeature/CheckFeature';
import { GET_MARKETPLACE_PURCHASEHIST } from '../../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';

type NavigationItem = {
  to: string;
  messageKey: string;
  icon: JSX.Element;
  activeFn?: (location: { pathname: string }) => boolean;
  actionItemsProperty?: string;
};

const useGetNavItemsForUser = (): NavigationItem[] => {
  const { loading, user, company } = useContext(AuthorizationContext);

  if (loading) {
    return [];
  }

  const testAbilityPrefix = (
    prefix: string,
    item: NavigationItem,
    requireEdit?: boolean,
    featureIds?: PlatformFeature[],
  ): NavigationItem[] => {
    if (
      !user?.roleConfig?.abilities.some(
        (x) =>
          x.name.startsWith(prefix) && (!requireEdit || x.operation === 'EDIT'),
      )
    ) {
      return [];
    }

    if (
      featureIds &&
      !featureIds.every((featureId) => isFeatureEnabled(company, featureId))
    ) {
      return [];
    }
    return [item];
  };

  return [
    ...testAbilityPrefix('Client.Impact', {
      to: '/impact',
      messageKey: 'sidenav.impact',
      icon: <SVG.Sidenav.Tracker />,
    }),
    ...testAbilityPrefix('Client.Engagement', {
      to: '/engagement',
      messageKey: 'sidenav.engagement',
      icon: <SVG.Sidenav.Engagement />,
    }),
    ...(company.parentCompanyId
      ? []
      : testAbilityPrefix(
          'Client.Marketplace',
          {
            to: '/marketplace',
            messageKey: 'sidenav.marketplace',
            icon: <SVG.Sidenav.Leaf />,
          },
          false,
          ['marketplace'],
        )),
    ...(company.parentCompanyId
      ? []
      : testAbilityPrefix(
          'Client.Offset',
          {
            to: '/offset',
            messageKey: 'sidenav.offset',
            icon: <SVG.Sidenav.OffsetOpportunity />,
          },
          false,
          ['offsets', 'marketplace'],
        )),
    ...testAbilityPrefix('Client.Communication', {
      to: `/communications/discover/${company.id}`,
      messageKey: 'sidenav.clientcommunications',
      icon: <SVG.Sidenav.ClientCommunications />,
      activeFn: (location) =>
        location.pathname.startsWith('/communications') &&
        location.pathname.includes(company.id),
    }),
    ...testAbilityPrefix('ZeroMe.Communication', {
      to: '/communications/discover',
      messageKey: 'sidenav.zmcommunications',
      icon: <SVG.Sidenav.ZeroMeCommunication />,
      activeFn: (location) =>
        location.pathname.startsWith('/communications') &&
        !location.pathname.includes(company.id),
    }),
    ...testAbilityPrefix('Client.Employees', {
      to: '/employees',
      messageKey: 'sidenav.employees',
      icon: <SVG.Sidenav.Team />,
    }),
    ...testAbilityPrefix('ZeroMe.TraderDashboard', {
      to: '/trader/dashboard',
      messageKey: 'sidenav.trader.dashboard',
      icon: <SVG.Sidenav.OffsetDashboard />,
    }),
    ...testAbilityPrefix('ZeroMe.Marketplace', {
      to: '/trader/projects',
      messageKey: 'sidenav.trader.projects',
      actionItemsProperty: 'pendingPurchaseCount',
      icon: <SVG.Sidenav.ProjectManagement />,
    }),
    ...testAbilityPrefix('ZeroMe.Clients', {
      to: '/trader/clients',
      messageKey: 'sidenav.trader.clients',
      icon: <SVG.Sidenav.Organization />,
      actionItemsProperty: 'companyActionsCount',
    }),
    ...testAbilityPrefix('Configuration', {
      to: '/settings',
      messageKey: 'sidenav.settings',
      icon: <SVG.Sidenav.Settings />,
    }),
    ...testAbilityPrefix('ZeroMe.Habits', {
      to: '/habits',
      messageKey: 'sidenav.habits',
      icon: <SVG.Sidenav.OffsetDashboard />,
    }),
  ];
};
type Props = {
  drawer: boolean;
  openCallback(): void;
  closeCallback(): void;
};
const SidebarNavigation: React.FC<Props> = ({
  drawer,
  openCallback,
  closeCallback,
}) => {
  const location = useLocation();
  const navItems = useGetNavItemsForUser();
  const { isImpersonating, userHasAccess } = useContext(AuthorizationContext);
  const { data: { companyActionsCount = 0 } = {} } = useQuery(
    GET_COMPANY_ACTIONS_COUNT,
    {
      skip: !userHasAccess('ZeroMe.ClientActions', 'VIEW'),
    },
  );

  const { data: { offsetProjects = [] } = {} } = useQuery<{
    offsetProjects: ProjectPurchaseHistory[];
  }>(GET_MARKETPLACE_PURCHASEHIST(), {
    variables: {
      filter: {
        status: ['REQUESTED', 'APPROVED'],
        isDemoProject: ['false'],
      },
    },
    skip: !userHasAccess('ZeroMe.Marketplace', 'VIEW'),
  });

  let pendingPurchaseCount = 0;

  if (offsetProjects.length) {
    const pendingCutoff = subHours(new Date(), 24);

    pendingPurchaseCount =
      offsetProjects.filter((o) =>
        isBefore(new Date(o.requestedDate), pendingCutoff),
      ).length ?? 0;
  }

  const actionItemData: { [k: string]: number } = {
    companyActionsCount,
    pendingPurchaseCount,
  };
  const getActive = (item: NavigationItem): boolean => {
    const { to, activeFn } = item;
    if (activeFn) {
      return activeFn(location);
    }
    return to === '/'
      ? location.pathname === to
      : location.pathname.startsWith(to);
  };
  return (
    <List
      style={{
        marginTop: isImpersonating ? 40 : 0,
        paddingBottom: 90,
        overflowX: 'hidden',
      }}>
      <SidebarBrand
        drawer={drawer}
        openCallback={openCallback}
        closeCallback={closeCallback}
      />
      {navItems.map((item) => (
        <SidebarNavigationItem
          drawer={drawer}
          key={item.messageKey}
          label={item.messageKey}
          to={item.to}
          active={getActive(item)}
          icon={item.icon}
          actionsCount={
            item.actionItemsProperty
              ? actionItemData[item.actionItemsProperty] || 0
              : undefined
          }
        />
      ))}
      <SidebarNavigationProfileItem
        drawer={drawer}
        clickHandler={() => openCallback()}
      />
    </List>
  );
};

export default SidebarNavigation;
