import React from 'react';
import { Backdrop } from '@mui/material';
import Loading from './Loading';

type Props = {
  open: boolean;
};

const LoadingBackdrop: React.FC<Props> = ({ open }) => (
  <Backdrop open={open} style={{ zIndex: 10000 }}>
    <Loading />
  </Backdrop>
);

export default LoadingBackdrop;
