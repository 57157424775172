import { Grid, Typography, lighten, useTheme } from '@mui/material';
import { clamp } from 'lodash';
import { ResponsivePie } from '@nivo/pie';
import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PROJECTED_EMISSIONS } from '../../../graphql/user/user';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import SVG from '../../../assets/svg-v2';
import { MTCO2E } from '../../../utils/stringConstants';
import { Colors } from '../../../theme/shared';
import AppInfo from '../../common/AppInfo';

const NetEmissionsChart = () => {
  const theme = useTheme();
  const { user, company } = useContext(AuthorizationContext);
  const skip = !user?.id || !company.id;

  const {
    loading,
    error,
    data: { projectedEmissions } = {},
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: company.id,
    },
    skip,
  });

  const { currentFootprint, habitReductions, offsetReductions } =
    projectedEmissions || {
      currentFootprint: 0,
      habitReductions: 0,
      offsetReductions: 0,
    };

  const CHART_SIZE = 300;
  const CHART_RADIUS = CHART_SIZE / 2;
  const LEAF_DIAMETER = 52;
  const LEAF_RADIUS = LEAF_DIAMETER / 2;

  const PIE_OUTER_RADIUS = (CHART_SIZE - LEAF_DIAMETER) / 2;
  const PIE_RATIO = 0.88;
  const PIE_INNER_RADIUS = PIE_OUTER_RADIUS * PIE_RATIO;

  const CHART_OFFSET_DEGREES = 90;
  const CHART_START_DEGRESS = -140;
  const CHART_END_DEGREES = 140;
  const TOTAL_DEGREES = 280;

  const MAX = currentFootprint;
  const TOTAL_REDUCTIONS = habitReductions + offsetReductions;
  const SCORE = currentFootprint - TOTAL_REDUCTIONS;

  /**
   * @description do not want the chart to "wrap" around and actually show a negative number
   */
  const CLAMPED_SCORE = clamp(SCORE, 0, MAX);
  // we subtract from 1 to get the inverse score
  // this is easier than inversing the chart
  const PERCENTAGE = 1 - CLAMPED_SCORE / MAX;

  const DEGREES =
    TOTAL_DEGREES * PERCENTAGE - (CHART_END_DEGREES + CHART_OFFSET_DEGREES);

  const RADIANS = DEGREES * (Math.PI / 180);

  const OUTER_X = CHART_RADIUS + PIE_OUTER_RADIUS * Math.cos(RADIANS);
  const OUTER_Y = CHART_RADIUS + PIE_OUTER_RADIUS * Math.sin(RADIANS);

  const INNER_X = CHART_RADIUS + PIE_INNER_RADIUS * Math.cos(RADIANS);
  const INNER_Y = CHART_RADIUS + PIE_INNER_RADIUS * Math.sin(RADIANS);

  const X = (OUTER_X + INNER_X) / 2;
  const Y = (OUTER_Y + INNER_Y) / 2;

  return (
    <DataStateHandler loading={loading || skip} error={error}>
      <Grid container flexDirection="column" alignItems="center">
        <div style={{ position: 'absolute', right: 0, top: 60 }}>
          <AppInfo id="net_emissions_chart" />
        </div>
        <Grid item style={{ position: 'relative' }}>
          <div
            style={{
              height: CHART_SIZE,
              position: 'relative',
              width: CHART_SIZE,
            }}>
            <div
              style={{
                position: 'absolute',
                height: CHART_SIZE,
                width: CHART_SIZE,
                padding: LEAF_RADIUS,
              }}>
              <ResponsivePie
                data={[
                  { id: '1', value: TOTAL_REDUCTIONS },
                  { id: '2', value: SCORE },
                ]}
                colors={[
                  theme.palette.primary.main,
                  'url(#netEmissionsGradient)',
                ]}
                startAngle={CHART_START_DEGRESS}
                endAngle={CHART_END_DEGREES}
                innerRadius={PIE_RATIO}
                layers={['arcs']}
                legends={[]}
              />
            </div>
            <div
              style={{
                position: 'absolute',
              }}>
              <svg
                aria-label="Net Emissions Chart"
                height={CHART_SIZE}
                width={CHART_SIZE}>
                <defs>
                  <linearGradient
                    id="netEmissionsGradient"
                    x1={0}
                    y1={0}
                    x2={1}
                    y2={1}>
                    <stop
                      offset="0%"
                      stopColor={Colors.ElectricGreen}
                      stopOpacity={1}
                    />
                    <stop
                      offset="100%"
                      stopColor={lighten(Colors.ElectricGreen, 0.5)}
                      stopOpacity={0.5}
                    />
                  </linearGradient>
                </defs>
                <g transform={`translate(${X},${Y})`}>
                  <g
                    transform={`translate(${LEAF_RADIUS * -1},${
                      LEAF_RADIUS * -1
                    })`}>
                    <SVG.ZeroMeBadge
                      height={LEAF_DIAMETER}
                      width={LEAF_DIAMETER}
                      strokeWidth={0}
                    />
                  </g>
                </g>
              </svg>
            </div>
            <Grid
              container
              flexDirection="column"
              style={{
                position: 'absolute',
                alignItems: 'center',
                marginTop: 110,
              }}>
              <Grid item>
                <Typography variant="h1" style={{ fontSize: 72 }}>
                  {new Intl.NumberFormat('en-us', {
                    maximumFractionDigits: 1,
                  }).format(SCORE / 1000)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h1">{MTCO2E}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" style={{ color: '#A9A9A9' }}>
                  Net Emissions
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item container style={{ position: 'relative', top: -25 }}>
          <Grid item container flexDirection="row" justifyContent="center">
            <Grid item>
              <Typography variant="h3">
                {new Intl.NumberFormat('en-us', {
                  maximumFractionDigits: 1,
                }).format(currentFootprint / 1000)}{' '}
                MT
              </Typography>
            </Grid>
            <Grid item style={{ width: CHART_SIZE / 2 }} />
            <Grid item>
              <Typography variant="h3">0.0 MT</Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" pt={5}>
            <Typography
              variant="h2"
              style={{ color: theme.palette.primary.main }}>
              {new Intl.NumberFormat('en-us', {
                maximumFractionDigits: 1,
              }).format(TOTAL_REDUCTIONS / 1000)}{' '}
              {MTCO2E} Reduced
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default NetEmissionsChart;
