import { UserPointBreakout } from '../types/user/types';

/**
 * @description sums up user points based on a list of categories.
 * @param userPoints a breakdown of the users points.
 * @param pointCategories a list of point categories to include in the sum. If no categories are provided then all will be included.
 * @return total points for the user within the given categories.
 */
const getUserContestPoints = (
  userPoints?: UserPointBreakout[],
  pointCategories?: string[],
): number => {
  if (!userPoints?.length) {
    return 0;
  }

  if (pointCategories?.length) {
    return userPoints?.reduce(
      (acc, cur) =>
        acc +
        cur.pointBreakouts
          .filter((pb) => pointCategories?.includes(pb.eventType))
          .reduce((acc2, cur2) => acc2 + cur2.pointTotals, 0),
      0,
    );
  }

  return userPoints?.reduce((acc, cur) => acc + cur.totalPoints, 0);
};

export default getUserContestPoints;
