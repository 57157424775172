import React from 'react';
import { Grid, Typography } from '@mui/material';
import { UserActivityType } from '../../../types/user/activityTypes';
import { UserActivityListItemProps } from './UserActivityListItem';
import CompanyLogo from '../../common/Company/CompanyLogo';
import UserActivityRenderer, {
  LEFT_COLUMN_WIDTH,
  renderActivityDate,
} from './UserActivityRenderer';

const UserActivityAdditionalAllotmentListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    if (
      userActivity.activityType !==
      UserActivityType.ALLOTMENT_CREDIT_ADDITIONAL_ALLOCATION
    ) {
      return null;
    }

    return (
      <UserActivityRenderer
        leftComponent={
          <CompanyLogo
            companyId={userActivity.data.companyId}
            style={{
              width: LEFT_COLUMN_WIDTH,
              height: LEFT_COLUMN_WIDTH,
              borderRadius: LEFT_COLUMN_WIDTH / 2,
            }}
          />
        }
        centerComponent={
          <Grid container flexDirection="column">
            <Typography variant="body1">
              Your organization reduced your footprint.
            </Typography>
            <Grid item container flexDirection="row" alignItems="center">
              {renderActivityDate(userActivity)}
            </Grid>
          </Grid>
        }
        rightComponent={
          <Typography variant="h4">{`${new Intl.NumberFormat('en-us', {
            maximumFractionDigits: 1,
          }).format(userActivity.data.amount)} MT`}</Typography>
        }
      />
    );
  },
);

export default UserActivityAdditionalAllotmentListItem;
