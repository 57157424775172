import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

import DashboardLayout from '../../../components/layouts/DashboardLayout';
import HeaderTabs from '../../../components/common/HeaderTabs/HeaderTabs';
import HeaderTab from '../../../components/common/HeaderTabs/HeaderTab';
import Participation from '../../../components/dashboards/Engagement/Participation';
import HabitMetrcs from '../../../components/dashboards/Engagement/HabitMetrics';

const useStyles = makeStyles(() => ({
  tab: {
    maxWidth: '150px !important',
  },
}));

enum EngagementTabs {
  ParticipationTab = 0,
  MyHabitsTab = 1,
}

const Engagement: React.FC = () => {
  const classes = useStyles();

  const [tab, setTab] = useState(EngagementTabs.ParticipationTab);

  return (
    <DashboardLayout>
      <Grid
        container
        spacing={0}
        direction="column"
        style={{ height: '100%', marginBottom: 32 }}>
        <>
          <Grid item container style={{ marginBottom: 16 }}>
            <HeaderTabs value={tab} style={{ marginBottom: 24, width: '100%' }}>
              <HeaderTab
                label="Participation"
                classes={{ root: classes.tab }}
                onClick={() => setTab(EngagementTabs.ParticipationTab)}
              />
              <HeaderTab
                label="My Habits"
                classes={{ root: classes.tab }}
                onClick={() => setTab(EngagementTabs.MyHabitsTab)}
              />
            </HeaderTabs>
          </Grid>
          {tab === EngagementTabs.ParticipationTab && <Participation />}
          {tab === EngagementTabs.MyHabitsTab && <HabitMetrcs />}
        </>
      </Grid>
    </DashboardLayout>
  );
};
export default Engagement;
