import { Grid } from '@mui/material';
import React from 'react';
import ChartLabel from '../ChartLabel/ChartLabel';

type Props = {
  keys: Array<{
    color?: string;
    imageUri?: string;
    label: string;
  }>;
  labelColor?: string;
};

const ChartLegend: React.FC<Props> = ({ keys, labelColor }) => (
  <Grid container direction="row" spacing={2}>
    {keys.map((k) => (
      <Grid item key={k.label}>
        <ChartLabel
          color={k.color}
          imageUri={k.imageUri}
          label={k.label}
          labelColor={labelColor}
        />
      </Grid>
    ))}
  </Grid>
);

export default ChartLegend;
