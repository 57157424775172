import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import DataStateHandler from '../DataStateHandler/DataStateHandler';

const useStyles = makeStyles({
  category: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
  wrapper: {
    position: 'relative',
  },
  circle: {
    borderRadius: '100%',
  },
});

type Props = {
  categoryId?: string;
};

const CategoryIconCircle: React.FC<Props> = ({ categoryId }) => {
  const circleSize = 32;

  const { loading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const category = projectCategories.find((x) => x.id === categoryId);
  const classes = useStyles({ circleSize, color: category?.color });

  return (
    <DataStateHandler loading={loading}>
      {category ? (
        <div className={classes.wrapper}>
          <div
            className={classes.circle}
            style={{
              backgroundColor: category.color,
              height: circleSize,
              width: circleSize,
            }}
          />
          <ReactSVG
            src={category.iconUri}
            className={classes.category}
            beforeInjection={(svg) => {
              svg.setAttribute('fill', 'currentColor');
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </DataStateHandler>
  );
};

export default CategoryIconCircle;
