import { AppBar, Box, Button, LinearProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from 'react-router';

import React, { useContext, useState } from 'react';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import SVG from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
  impersonationHeader: {
    color: theme.palette.text.secondary,
    backgroundColor: '#FFB84E',
    height: 40,
    zIndex: 2000,
    padding: 6,
  },
  endImpersonationButton: {
    position: 'absolute',
    top: -5,
    '& svg': {
      marginRight: 5,
    },
  },
}));

const ImpersonationHeader: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isExiting, setIsExiting] = useState(false);
  const { setImpersonatedCompanyId, company, isImpersonating } =
    useContext(AuthorizationContext);

  if (!isImpersonating) {
    return null;
  }

  return (
    <AppBar position="fixed" className={classes.impersonationHeader}>
      <div className={classes.endImpersonationButton}>
        <Button
          size="small"
          color="inherit"
          disabled={isExiting}
          data-testid="impersonate-exit-button"
          onClick={async () => {
            setIsExiting(true);
            await setImpersonatedCompanyId();
            history.push('/');
          }}>
          {isExiting ? (
            <Box width={24} mr={1}>
              <LinearProgress color="inherit" />
            </Box>
          ) : (
            <SVG.Close />
          )}
          Exit Company View
        </Button>
      </div>
      <Typography align="center" style={{ fontSize: 14 }}>
        Viewing as {company.name}
      </Typography>
    </AppBar>
  );
};

export default ImpersonationHeader;
