import React, { useEffect, useRef, useState } from 'react';
import { DocumentNode } from 'graphql';
import {
  Typography,
  Grid,
  IconButton,
  Popper,
  Grow,
  Tooltip,
  useTheme,
  GridProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Variant } from '@mui/material/styles/createTypography';
import SVG from '../../../assets/svg';
import StickyCell from './StickyCell';
import { FilterState, OnApplyFilterParams, SelectTableColumn } from './types';

const useStyles = makeStyles((theme) => ({
  headerCell: {
    background: theme.palette.background.default,
    flexWrap: 'nowrap',
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerCellTypography: {
    background: theme.palette.background.default,
    color: theme.palette.text.secondary,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  headerWithAppliedFilter: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  columnFilterPopper: {
    zIndex: 2000,
  },
  filterWrapper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    color: theme.palette.text.primary,
    width: 275,
    borderRadius: 8,
    padding: 8,
  },
  whiteSvg: {
    '& path': {
      fill: '#FFF',
    },
  },
}));

const HeaderCell: React.FC<Props> = ({
  normalBackgroundColor,
  containerClassName,
  headerCellClassName,
  containerStyle,
  children,
  variant = 'subtitle2',
  position = 'top',
  filter = {},
  setFilter = () => {
    // Do nothing
  },
  tableData,
  getPropertyQuery,
  queryVariables,
  overlineText,
  justifyContent = 'flex-start',
  tooltip = '',
  column,
  wrapText = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const headerCellRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(
    normalBackgroundColor || theme.palette.background.paper,
  );
  const baseTextStyle: React.CSSProperties = {
    whiteSpace: wrapText ? undefined : 'nowrap',
  };
  const [textStyle, setTextStyle] = useState(baseTextStyle);

  useEffect(() => {
    if (open) {
      setBackgroundColor(theme.palette.secondary.dark);
      setTextStyle({
        ...baseTextStyle,
        color: theme.palette.common.white,
      });
    } else {
      setBackgroundColor(
        normalBackgroundColor || theme.palette.background.paper,
      );
      setTextStyle(baseTextStyle);
    }
  }, [open]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (headerCellRef.current?.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const handleApplyFilter = ({ event, searchResults }: OnApplyFilterParams) => {
    handleClickAway(event as unknown as MouseEvent);
    setFilter({
      ...filter,
      [column.key]: searchResults,
    });
  };

  const showColumnFilter =
    column.type && !!Object.entries(children || {}).length;
  const columnFilter = filter[column.key];
  const hasFilter = columnFilter && columnFilter.length > 0;
  const ColumnFilterType = column.type;

  return (
    <StickyCell
      containerStyle={{
        backgroundColor,
        ...(containerStyle || {}),
      }}
      containerClassName={`${containerClassName}`}
      position={position}>
      <Tooltip title={tooltip} ref={headerCellRef}>
        <Grid
          container
          justifyContent={justifyContent}
          alignItems="center"
          direction="row"
          className={`${headerCellClassName} ${classes.headerCell}`}
          style={{ backgroundColor }}>
          <Typography
            className={`${classes.headerCellTypography} ${
              hasFilter ? classes.headerWithAppliedFilter : ''
            }`}
            style={{
              backgroundColor,
              ...textStyle,
            }}
            variant={variant}
            data-testid="headerText">
            {children}
          </Typography>
          {overlineText != null && (
            <Typography variant="overline">{overlineText}</Typography>
          )}

          {showColumnFilter && (
            <>
              <IconButton
                aria-owns={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                data-testid="iconButton"
                size="large">
                <SVG.TableFilter
                  className={open ? classes.whiteSvg : undefined}
                />
              </IconButton>
              <Popper
                open={open}
                anchorEl={headerCellRef.current}
                transition
                className={classes.columnFilterPopper}
                placement="bottom-start">
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start'
                          ? 'center top'
                          : 'center bottom',
                    }}>
                    <div className={classes.filterWrapper}>
                      {ColumnFilterType ? (
                        <ColumnFilterType
                          onApplyFilter={handleApplyFilter}
                          onClickAway={handleClickAway}
                          column={column}
                          filter={filter}
                          getPropertyQuery={getPropertyQuery}
                          queryVariables={queryVariables}
                          tableData={tableData}
                        />
                      ) : (
                        <div />
                      )}
                    </div>
                  </Grow>
                )}
              </Popper>
            </>
          )}
        </Grid>
      </Tooltip>
    </StickyCell>
  );
};

type Props = {
  normalBackgroundColor?: string;
  containerClassName?: string;
  headerCellClassName?: string;
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
  variant?: Variant;
  position?: 'left' | 'top' | 'both';
  filter?: FilterState;
  setFilter?: (f: FilterState) => void;
  tableData: unknown[];
  getPropertyQuery?: DocumentNode;
  queryVariables?: Record<string, unknown>;
  overlineText?: React.ReactNode;
  justifyContent?: GridProps['justifyContent'];
  tooltip?: string;
  column: SelectTableColumn;
  wrapText?: boolean;
};

export default HeaderCell;
