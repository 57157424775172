import { useMutation, useQuery } from '@apollo/client';
import React, { createContext, FC, useContext, useMemo, useState } from 'react';
import { GET_ALL_CATEGORIES } from '../graphql/projects/projectCategories';
import { USER_CART } from '../graphql/user/user';
import {
  CLEAR_CART_VALIDATION_DATA,
  REQUEST_TO_PURCHASE_CART,
  UPDATE_CART,
} from '../graphql/user/userMutations';
import { Project, ProjectCategory } from '../types/project/types';
import { ValidatedCart } from '../types/user/types';
import { AuthorizationContext } from './AuthorizationContext';
import { GET_SUBSCRIPTION_DATA } from '../graphql/company/company';

type MarketplaceContext = {
  project?: Project;
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>;
  categories: ProjectCategory[];
  cartVisible: boolean;
  cart: ValidatedCart;
  askATraderVisible: boolean;
  setAskATraderVisible: (visible: boolean) => void;
  setCartVisible: (visible: boolean) => void;
  purchaseCart: () => Promise<'SUCCESS' | 'FAILED' | 'GENERAL_ERROR'>;
  updateCart: (
    id: string,
    quantity: number,
    onExisting: 'INCREMENT' | 'UPDATE' | 'REMOVE',
  ) => void;
  reloadCart: () => void;
  clearCartValidationData: () => void;
  cartLoading: boolean;
  creditBalance: number;
};
const initialContext: MarketplaceContext = {
  project: undefined,
  setProject: () => null,
  categories: [],
  cartVisible: false,
  setCartVisible: () => null,
  askATraderVisible: false,
  setAskATraderVisible: () => null,
  cart: {
    items: [],
    validationData: [],
  },
  purchaseCart: () => Promise.resolve('FAILED'),
  updateCart: () => null,
  reloadCart: () => null,
  clearCartValidationData: () => null,
  cartLoading: false,
  creditBalance: 0,
};
export const MarketplaceContext = createContext(initialContext);

const MarketplaceProvider: FC = ({ children }) => {
  const { userHasAccess, company } = useContext(AuthorizationContext);
  const [project, setProject] = useState<Project | undefined>(undefined);
  const [categories, setCategories] = useState<ProjectCategory[]>([]);

  const [cartVisible, setCartVisible] = useState(false);
  const [askATraderVisible, setAskATraderVisible] = useState(false);
  const [cart, setCart] = useState<ValidatedCart>({
    items: [],
    validationData: [],
  });

  const { loading: loadingCart, refetch: refetchCart } = useQuery(USER_CART, {
    skip: !userHasAccess('Client.Marketplace', 'EDIT'),
    fetchPolicy: 'network-only',
    onCompleted(data) {
      setCart(data.me.cart);
    },
  });

  const {
    loading: loadingBalance,
    data: {
      company: { subscriptionData: { creditBalance = 0 } = {} } = {},
    } = {},
  } = useQuery(GET_SUBSCRIPTION_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      id: company.id,
    },
  });

  useQuery(GET_ALL_CATEGORIES, {
    onCompleted(data) {
      setCategories(
        data.projectCategories.map((item: ProjectCategory) => item),
      );
    },
  });

  const [updateCartMutation, { loading: updatingCart }] =
    useMutation(UPDATE_CART);

  const [requestToPurchaseCartMutation, { loading: purchasingCart }] =
    useMutation(REQUEST_TO_PURCHASE_CART);

  const [clearCartValidationData] = useMutation(CLEAR_CART_VALIDATION_DATA);

  const updateCart = async (
    id: string,
    quantity: number,
    onExisting: 'INCREMENT' | 'UPDATE' | 'REMOVE',
  ) => {
    setCart(
      (
        await updateCartMutation({
          variables: {
            input: {
              projectId: id,
              quantity,
              onExisting,
            },
          },
        })
      ).data?.updateCart as ValidatedCart,
    );
  };

  const purchaseCart = async () => {
    try {
      const result = await requestToPurchaseCartMutation();
      setCart(result.data?.requestToPurchaseCart as ValidatedCart);

      const purchaseSucceeded =
        result.data?.requestToPurchaseCart.validationData.length === 0;
      return purchaseSucceeded ? 'SUCCESS' : 'FAILED';
    } catch {
      return 'GENERAL_ERROR';
    }
  };

  const cartLoading =
    loadingCart || loadingBalance || updatingCart || purchasingCart;

  const value = useMemo(
    () => ({
      project,
      setProject,
      categories,
      cart,
      cartVisible,
      setCartVisible,
      askATraderVisible,
      setAskATraderVisible,
      updateCart,
      reloadCart: refetchCart,
      purchaseCart,
      clearCartValidationData: () => {
        clearCartValidationData();
        setCart({
          ...cart,
          validationData: [],
        });
      },
      cartLoading,
      creditBalance,
    }),
    [project, categories, cart, cartVisible, cartLoading, askATraderVisible],
  );

  return (
    <MarketplaceContext.Provider value={value}>
      {children}
    </MarketplaceContext.Provider>
  );
};

export default MarketplaceProvider;
