import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import CategoryIconCircle from './CategoryIconCircle';

const useStyles = makeStyles(() => ({
  categories: {
    marginLeft: '8px',
    display: 'flex',
    '& > div': {
      marginLeft: '-8px',
    },
  },
}));

type Props = {
  categoryIds: string[];
};

const CategoryIconCircleGroup: React.FC<Props> = ({ categoryIds }) => {
  const classes = useStyles();
  return (
    <div className={classes.categories}>
      {categoryIds.map((id) => (
        <CategoryIconCircle categoryId={id} key={id} />
      ))}
    </div>
  );
};

export default CategoryIconCircleGroup;
