import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import { keyBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import SVG from '../../assets/svg';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import { GET_QUIZ_METRICS } from '../../graphql/communications/communications';
import NumberUtils from '../../utils/numberUtils';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import ProgressBar from '../common/ProgressBar/ProgressBar';
import CommunicationDetailsHeader from './CommunicationDetailsHeader';
import QuizMetricCard from './QuizMetricCard';
import { QuizType } from './types';

type Props = {
  quizId?: string;
  onClose: () => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 64px',
  },
  title: {
    fontWeight: 700,
  },
  progress: {
    borderRadius: 8,
    height: 8,
  },
  centered: {
    textAlign: 'center',
  },
  metrics: {
    marginBottom: 24,
    justifyContent: 'center',
  },
  answer: { padding: '6px 0' },
  answerText: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    color: '#6B6B6B',
  },
  answerMetric: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  answerMetricBar: { width: '85%' },
  question: {
    '&.MuiGrid-item': { paddingTop: 24 },
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    '&:last-child': { borderBottom: 'none ' },
  },
  questionText: { paddingBottom: 12, textAlign: 'center' },
}));

const QuizMetrics: React.FC<Props> = ({ quizId, onClose }) => {
  const { companyId } = useContext(CommunicationsContext);

  const classes = useStyles();

  const {
    loading,
    error,
    data: { quiz } = {},
  } = useQuery(GET_QUIZ_METRICS, {
    variables: { quizId, companyId },
    skip: !quizId,
  });

  const statusText = useMemo(() => {
    const expiredDate = moment(quiz?.expireDate);
    if (expiredDate.isValid() && expiredDate.isBefore(moment())) {
      return `${quiz?.quizType} closed ${expiredDate.format('MM/DD/YYYY')}`;
    }
    return t('active');
  }, [quiz?.expireDate]);

  const answerMetricDictionay = useMemo(
    () =>
      keyBy(
        quiz?.metrics?.answers?.map((a) => ({
          ...a,
          percentage: a.totalOccurances / (quiz?.metrics?.quizCount ?? 1),
        })),
        'answerId',
      ),
    [quiz],
  );

  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container direction="column" className={classes.root} spacing={2}>
        <Grid item>
          <CommunicationDetailsHeader
            title={`${
              quiz?.quizType === QuizType.QUIZ ? t('quiz.quiz') : t('poll.poll')
            } ${t('results')}`}
            canEdit={false}
            onClose={onClose}
            saveButton={undefined}
            onSave={() => undefined}
          />
        </Grid>
        <Grid item className={classes.title}>
          <Typography variant="h2" className={classes.centered}>
            {quiz?.title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" className={classes.centered}>
            {statusText}
          </Typography>
        </Grid>
        <Grid item container spacing={2} className={classes.metrics}>
          <Grid item xs={6}>
            <QuizMetricCard
              title={t('attempts')}
              icon={<SVG.ReturnArrow />}
              value={quiz?.metrics?.quizCount ?? 0}
            />
          </Grid>
          {quiz?.quizType === QuizType.QUIZ && (
            <Grid item xs={6}>
              <QuizMetricCard
                title={t('avgAccuracy')}
                icon={<SVG.ListChecked />}
                value={NumberUtils.format(
                  quiz?.metrics?.averageAccuracy ?? 0,
                  'wholePercent',
                )}
              />
            </Grid>
          )}
        </Grid>
        {(quiz?.questions ?? []).map((q) => (
          <Grid item className={classes.question}>
            <Typography variant="h3" className={classes.questionText}>
              {q.questionText}
            </Typography>
            {q.options?.map((o) => (
              <Grid container className={classes.answer}>
                <Grid item xs={12} className={classes.answerText}>
                  <Typography variant="body2" color="#6B6B6B">
                    {o.answerText}
                  </Typography>
                  {q.correctAnswerId === o.answerId && <SVG.Check />}
                </Grid>
                <Grid item xs={12}>
                  <ProgressBar
                    value={answerMetricDictionay?.[o.answerId]?.percentage ?? 0}
                    maxValue={1}
                    label={`
                    ${NumberUtils.format(
                      answerMetricDictionay?.[o.answerId]?.percentage || 0,
                      'wholePercent',
                    )} (${
                      answerMetricDictionay?.[o.answerId]?.totalOccurances ?? 0
                    })`}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>
    </DataStateHandler>
  );
};

export default QuizMetrics;
