import React, { useState } from 'react';
import { matchPath } from 'react-router';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  Button,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { t } from 'i18next';

import ActivityIcon from './activities/ActivityIcon';
import NetEmissionsPreview from './dashboard/NetEmissionsPreview';
import CheckFeature from '../common/CheckFeature/CheckFeature';
import SVG from '../../assets/svg-v2';
import theme from '../../theme/memberTheme';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    marginRight: 8,
    '& h3': {
      padding: 8,
      paddingTop: 12,
    },
    '&.active h3': {
      color: theme.palette.primary.main,
    },
    '&.active div svg': {
      color: theme.palette.primary.main,
    },
    '& div svg': {
      color: theme.palette.text.primary,
    },
  },
  button: {
    marginRight: 8,
    padding: 8,
    paddingTop: 12,
  },
  buttonInactive: {
    color: theme.palette.primary.dark,
  },
  buttonActive: {
    color: theme.palette.primary.main,
  },
  root: {
    marginBottom: 32,
  },
}));

const MemberNavigation = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const moreMenuItems = [
    { value: 'about-me', label: t('nav.aboutMe'), path: '/member/about-me' },
    {
      value: 'sustainability-interests',
      label: t('nav.sustainabilityInterests'),
      path: '/member/sustainability-interests',
    },
    {
      value: 'quarterly-leaderboard',
      label: t('nav.quarterlyLeaderboard'),
      path: '/member/leaderboard',
    },
    {
      value: 'team-challenges',
      label: t('nav.teamChallenges'),
      path: '/member/challenge',
    },
    { value: 'badge', label: t('badge.badges'), path: '/member/badge' },
    {
      value: 'poll-results',
      label: t('poll.pollResults'),
      path: '/member/poll-results',
    },
    {
      value: 'my-project-history',
      label: t('nav.myProjectHistory'),
      path: '/member/my-project-history',
    },
    {
      value: 'settings',
      label: t('sidenav.settings'),
      path: '/member/settings',
    },
  ];

  const isMoreActive = moreMenuItems
    .map((mi) => mi.path)
    .some((path) => matchPath(pathname.toLowerCase(), { path, exact: false }));

  const generateCommonLinks = () => (
    <>
      <NavLink to="/member/dashboard" className={classes.link}>
        <Typography variant="h3">{t('nav.home')}</Typography>
      </NavLink>
      <NavLink to="/member/myhabits" className={classes.link}>
        <Typography variant="h3">{t('nav.myHabits')}</Typography>
      </NavLink>
      <CheckFeature featureIds={['marketplace']}>
        <NavLink to="/member/projects" className={classes.link}>
          <Typography variant="h3">{t('nav.projects')}</Typography>
        </NavLink>
      </CheckFeature>
      <NavLink to="/member/discover" className={classes.link}>
        <Typography variant="h3">{t('nav.discover')}</Typography>
      </NavLink>
    </>
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.root}>
      <Grid item>
        <Link
          aria-label="Go to Dashboard"
          to="/member/dashboard"
          className={classes.link}>
          <SVG.ZeroMeLogo />
        </Link>
      </Grid>
      {!isSm && (
        <Grid item>
          <Grid container alignItems="center">
            {generateCommonLinks()}
            <Button
              className={`${classes.button} ${
                isMoreActive ? classes.buttonActive : classes.buttonInactive
              }`}
              variant="text"
              endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
              onClick={(event) => setAnchorEl(event.currentTarget)}>
              {t('nav.more')}
            </Button>
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}>
              {moreMenuItems.map(({ value, label, path }) => (
                <MenuItem key={value}>
                  <NavLink to={path} className={classes.link}>
                    <Typography variant="h3">{label}</Typography>
                  </NavLink>
                </MenuItem>
              ))}
            </Menu>
            <NavLink
              aria-label="Activities"
              to="/member/activities"
              className={classes.link}>
              <ActivityIcon />
            </NavLink>
            <Grid item pl={3}>
              <NetEmissionsPreview />
            </Grid>
          </Grid>
        </Grid>
      )}
      {isSm && (
        <>
          <Grid item style={{ paddingTop: 12 }}>
            <IconButton
              aria-label="Expand Menu"
              onClick={() => setDrawerOpen(true)}>
              <SVG.Hamburger />
            </IconButton>
          </Grid>
          <Drawer anchor="right" open={drawerOpen}>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              style={{ width: 200 }}>
              <Grid item container justifyContent="flex-end">
                <IconButton
                  aria-label="Close Menu"
                  onClick={() => setDrawerOpen(false)}>
                  <SVG.Close />
                </IconButton>
              </Grid>
              <NetEmissionsPreview />
              {generateCommonLinks()}
              <NavLink
                aria-label="Activities"
                to="/member/activities"
                className={classes.link}>
                <ActivityIcon textual />
              </NavLink>
              <Button
                className={`${classes.button} ${
                  isMoreActive ? classes.buttonActive : classes.buttonInactive
                }`}
                variant="text"
                endIcon={expand ? <ArrowDropUp /> : <ArrowDropDown />}
                onClick={() => setExpand(!expand)}>
                {t('nav.more')}
              </Button>
              <Collapse in={expand}>
                {moreMenuItems.map(({ label, path }) => (
                  <Grid item display="flex" justifyContent="center">
                    <NavLink to={path} className={classes.link}>
                      <Typography variant="h3">{label}</Typography>
                    </NavLink>
                  </Grid>
                ))}
              </Collapse>
            </Grid>
          </Drawer>
        </>
      )}
    </Grid>
  );
};

export default MemberNavigation;
