import { ReactComponent as Bang } from './bang.svg';
import { ReactComponent as BannerLeft } from './banner-left.svg';
import { ReactComponent as BannerRight } from './banner-right.svg';
import { ReactComponent as Bell } from '../svg/bell.svg';
import { ReactComponent as ThickCaret } from './caret.svg';
import { ReactComponent as CaretLeftSmall } from '../svg/caret-left-small.svg';
import { ReactComponent as CaretRightSmall } from '../svg/caret-right-small.svg';
import { ReactComponent as CartEmpty } from '../svg/cart-empty.svg';
import { ReactComponent as ChallengeActivity } from './challenge-activity.svg';
import { ReactComponent as Check } from './ellipsecheck.svg';
import { ReactComponent as Close } from '../svg/close.svg';
import { ReactComponent as Complete } from './complete.svg';
import { ReactComponent as Dislike } from './dislike.svg';
import { ReactComponent as Ellipse } from './ellipse.svg';
import { ReactComponent as FaucetHead } from './faucet-head.svg';
import { ReactComponent as FaucetPipe } from './faucet-pipe.svg';
import { ReactComponent as HalfEllipse } from './halfEllipse.svg';
import { ReactComponent as Hamburger } from './hamburger.svg';
import { ReactComponent as Info } from './info.svg';
import { ReactComponent as Leaf } from '../svg/leaf.svg';
import { ReactComponent as Leafy } from './leafy.svg';
import { ReactComponent as Like } from './like.svg';
import { ReactComponent as Plus } from './plus.svg';
import { ReactComponent as Reforestation } from './reforestation.svg';
import { ReactComponent as ResultsList } from './results-list.svg';
import { ReactComponent as Star } from './star.svg';
import { ReactComponent as Thumb } from './like-thumb.svg';
import { ReactComponent as Trash } from './trash.svg';
import { ReactComponent as Warning } from '../svg/warning-triangle.svg';
import { ReactComponent as ZeroMeBadge } from './zerome-badge.svg';
import { ReactComponent as ZeroMeLogo } from './zerome-logo.svg';

export default {
  Bang,
  BannerLeft,
  BannerRight,
  Bell,
  ThickCaret,
  CaretLeftSmall,
  CaretRightSmall,
  CartEmpty,
  ChallengeActivity,
  Check,
  Close,
  Complete,
  Dislike,
  Ellipse,
  FaucetHead,
  FaucetPipe,
  HalfEllipse,
  Hamburger,
  Info,
  Leaf,
  Leafy,
  Like,
  Plus,
  Reforestation,
  ResultsList,
  Star,
  Thumb,
  Trash,
  Warning,
  ZeroMeBadge,
  ZeroMeLogo,
};
