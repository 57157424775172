import React, { useContext, useEffect } from 'react';
import { Field } from 'formik';
import AppTextField from '../common/AppTextField';
import CompanyNameWithLogo from '../common/Company/CompanyNameWithLogo';
import { Company } from '../../types/company/types';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';

const ParentCompanyForm: React.FC = () => {
  const { company, allCompanies } = useContext(CompanySettingsContext);
  const { values, setTypedFieldValue } = useTypedFormikContext<Company>();

  const selectedParentCompany = allCompanies.find(
    (x) => x.id === values.parentCompanyId,
  );

  useEffect(() => {
    if (
      selectedParentCompany &&
      selectedParentCompany.name !== values.parentCompanyName
    ) {
      setTypedFieldValue('parentCompanyName', selectedParentCompany.name);
      setTypedFieldValue(
        'parentCompanyLogoUris',
        selectedParentCompany.logoUris,
      );
    }
  }, [values.parentCompanyId]);

  return (
    <Field
      name="parentCompanyId"
      component={AppTextField}
      placeholder="Parent Company"
      select
      fullWidth
      SelectProps={{
        displayEmpty: true,
      }}
      options={[
        {
          value: '',
          label: 'No Parent Company',
        },
        ...allCompanies
          // .filter((x) => x.id !== company.id && !x.parentCompanyId)
          // Removing code to prevent deep nesting for QA to play around with
          .filter((x) => x.id !== company.id)
          .map((x) => ({
            value: x.id,
            label: <CompanyNameWithLogo company={x} />,
          })),
      ]}
    />
  );
};

export default ParentCompanyForm;
