import React from 'react';
import { useQuery } from '@apollo/client';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  GET_PLATFORM_STATS,
  GET_TOTAL_PURCHASE_DATA,
} from '../../../graphql/trader/dashboard';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import AppCard from '../../common/AppCard/AppCard';
import PieChart from '../../common/PieChart/PieChart';
import { ProjectPurchaseStatus } from '../../../types/offsetProject/types';
import { ProjectPurchaseType } from '../../../types/project/types';
import { CompanyFiscalYearSettings } from '../../../types/company/types';
import AppInfo from '../../common/AppInfo';
import NumberUtils from '../../../utils/numberUtils';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  label: {
    paddingLeft: 8,
    paddingTop: 4,
  },
  value: {
    marginTop: 8,
    color: Colors.CobaltBlue,
  },
  legend: {
    color: '#6B6B6B',
    fontSize: 12,
    lineHeight: '14px',
  },
  legendDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 4,
    display: 'inline-block',
  },
  icon: {
    height: 32,
    width: 32,
    padding: 8,
    fill: '#0A3751',
    background: '#F1F8FD',
    borderRadius: 8,
  },
}));

type Props = {
  fiscalYear?: CompanyFiscalYearSettings;
};

const MarketplaceParticipationCard: React.FC<Props> = ({ fiscalYear }) => {
  const classes = useStyles();

  const {
    loading: purchasesLoading,
    error: purchasesError,
    data: { totalSpend } = {},
  } = useQuery(GET_TOTAL_PURCHASE_DATA, {
    variables: {
      purchaseTypes: [ProjectPurchaseType.USER, ProjectPurchaseType.COMPANY],
      statuses: [
        ProjectPurchaseStatus.COMPLETE,
        ProjectPurchaseStatus.REQUESTED,
      ],
      startDate: fiscalYear?.startDate as string,
      endDate: fiscalYear?.endDate as string,
    },
    skip: !fiscalYear,
    fetchPolicy: 'network-only',
  });

  const {
    data: { platformStats } = {},
    loading: statsLoading,
    error: errorLoading,
  } = useQuery(GET_PLATFORM_STATS);

  const percentage = NumberUtils.format(
    (totalSpend?.companyCount || 0) / (platformStats?.totalCompanies || 0),
    'wholePercent',
  );

  const clients = NumberUtils.format(totalSpend?.companyCount, 'integer');

  const total = NumberUtils.format(platformStats?.totalCompanies, 'integer');

  return (
    <AppCard height={350}>
      <DataStateHandler
        loading={purchasesLoading || statsLoading}
        error={purchasesError || errorLoading}>
        <Grid container direction="column">
          <Grid item container direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              style={{ fontSize: 12, marginBottom: 24, color: '#2A2A2A' }}>
              MARKETPLACE PARTICIPATION
            </Typography>
            <AppInfo id="marketplaceParticipation" />
          </Grid>
          <div style={{ margin: '0 auto' }}>
            <PieChart
              chartSize={175}
              margin={{}}
              borderWidth={0}
              donutLabel={{
                title: percentage,
                subtitle: `${clients} of ${total}`,
                radius: 0.75,
                titleStyle: {
                  paddingTop: 5,
                  color: Colors.CobaltBlue,
                  fontWeight: 700,
                  fontSize: 24,
                },
                subtitleStyle: {
                  color: '#6B6B6B',
                  fontSize: 12,
                  fontWeight: 500,
                  marginTop: 0,
                },
              }}
              data={[
                {
                  id: 'Active',
                  label: 'Active',
                  value: totalSpend?.companyCount || 0,
                  color: Colors.CobaltBlue,
                },
                {
                  id: 'Inactive',
                  label: 'Inactive',
                  value:
                    (platformStats?.totalCompanies || 0) -
                    (totalSpend?.companyCount || 0),
                  color: '#EFEFEF',
                },
              ]}
            />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 24, marginBottom: 16 }}>
            <Grid item>
              <div
                className={classes.legendDot}
                style={{
                  backgroundColor: Colors.CobaltBlue,
                }}
              />
              <Typography variant="body2" style={{ display: 'inline-block' }}>
                Participants
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">{clients}</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid item>
              <div
                className={classes.legendDot}
                style={{
                  backgroundColor: '#EFEFEF',
                }}
              />
              <Typography variant="body2" style={{ display: 'inline-block' }}>
                Total
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">{total}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DataStateHandler>
    </AppCard>
  );
};

export default MarketplaceParticipationCard;
