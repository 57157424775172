type LatLng = {
  lng: number;
  lat: number;
};

type MapboxResponse = {
  features: {
    center: [lng: number, lat: number];
  }[];
};

const cache: {
  location: string;
  coords?: LatLng;
}[] = [];

// eslint-disable-next-line import/prefer-default-export
export const mapboxGeocode = async (location: string): Promise<LatLng | undefined> => {
  const cachedLocation = cache.find((x) => x.location === location);

  if (cachedLocation) {
    return cachedLocation.coords;
  }

  const response = (await (
    await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?limit=1&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
    )
  ).json()) as MapboxResponse;

  let coords: LatLng | undefined;

  if (response.features && response.features.length) {
    const [lng, lat] = response.features[0].center;

    coords = {
      lng,
      lat,
    };
  }

  cache.push({
    location,
    coords,
  });

  if (cache.length > 100) {
    cache.shift();
  }

  return coords;
};
