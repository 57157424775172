/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Alert, Snackbar, Typography } from '@mui/material';
import { ApolloError } from '@apollo/client';
import Loading from '../Loading';

type Props = {
  loading?: boolean;
  error?: boolean | string | ApolloError;
  children: JSX.Element | JSX.Element[] | null;
  loadingPaddingY?: number;
  extraCodeMessages?: Record<string, string>;
};

export const DEFAULT_CODE_MESSAGES: Record<string, string> = {
  INVALID_FILE: 'The file you uploaded was invalid.  Please try another.',
};

const DataStateHandler: React.FC<Props> = ({
  loading,
  error,
  children,
  loadingPaddingY,
  extraCodeMessages,
}) => {
  const [messageClosed, setMessageClosed] = useState(false);

  useEffect(() => setMessageClosed(false), [error]);

  let codeMessage = '';

  if (error instanceof ApolloError) {
    const allCodeMessages: Record<string, string> = {
      ...DEFAULT_CODE_MESSAGES,
      ...extraCodeMessages,
    };

    const code = error.graphQLErrors[0]?.extensions?.code;

    if (code && allCodeMessages[code]) {
      codeMessage = allCodeMessages[code];
    }
  }

  return (
    <>
      {error && !codeMessage && (
        <Typography align="center">
          <Trans i18nKey="fetchError" />
        </Typography>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={!!codeMessage && !messageClosed}>
        <Alert severity="error" onClose={() => setMessageClosed(true)}>
          {codeMessage}
        </Alert>
      </Snackbar>
      {loading && <Loading paddingY={loadingPaddingY} />}
      {!loading && (!error || !!codeMessage) && children}
    </>
  );
};

export default DataStateHandler;
