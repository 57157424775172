import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Grid, Typography, ListItemButton } from '@mui/material';
import { useHistory } from 'react-router';
import MemberLayout from '../../../components/layouts/MemberLayout';
import SVG from '../../../assets/svg';
import { UPDATE_RESET_QUESTIONS } from '../../../graphql/user/userMutations';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';

const MemberDevelopment: React.FC = () => {
  const { user } = useContext(AuthorizationContext);
  const history = useHistory();
  const [resetQuestions] = useMutation(UPDATE_RESET_QUESTIONS);

  const internalResetQuestions = async () => {
    await resetQuestions();

    // reload page, user should be taken to onboarding
    window.location.reload();
  };

  if (!user?.roles.includes('platform_admin')) {
    history.push('/member');
  }

  return (
    <MemberLayout>
      <Grid container direction="column">
        <Typography variant="h2" sx={{ mb: 2 }}>
          Development Settings
        </Typography>
        <ListItemButton sx={{ height: 72 }} onClick={internalResetQuestions}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h3">Reset Profile Questions</Typography>
            <SVG.CaretRightSmall />
          </Grid>
        </ListItemButton>
      </Grid>
    </MemberLayout>
  );
};

export default MemberDevelopment;
