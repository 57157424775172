import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import SVG from '../../assets/svg';
import { OffsetContext } from '../../contexts/OffsetContext';
import { ProjectPurchaseStatus } from '../../types/offsetProject/types';
import OffsetCard from './cards/OffsetCard';
import AllocatedHistory from './drawers/AllocatedHistory';
import MyOffsetInventory from './drawers/MyOffsetInventory';

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 1338,
    [theme.breakpoints.up('lg')]: {
      width: 1338,
    },
  },
  drawerWrapper: {
    height: '100%',
  },
  drawerHeader: {
    borderBottom: '1px solid',
  },
}));

const enum OffsetMetric {
  none,
  offsetInventory = 'My Offset Inventory',
  allocated = 'Allocated Offsets',
}

const Metrics = () => {
  const classes = useStyles();
  const {
    projectPurchases,
    projectCategoryOfInterest,
    userHasViewAccess,
    loading,
  } = useContext(OffsetContext);

  const completedProjectPurchases = projectPurchases?.filter(
    ({ status }) => status === ProjectPurchaseStatus.COMPLETE,
  );
  const totalAllocated = completedProjectPurchases?.reduce(
    (total, { allocated }) => total + allocated || 0,
    0,
  );
  const totalUnallocated = completedProjectPurchases?.reduce(
    (total, { remaining }) => total + remaining || 0,
    0,
  );

  const [openDrawer, setOpenDrawer] = useState(OffsetMetric.none);
  const onClose = () => setOpenDrawer(OffsetMetric.none);

  return <>
    <Grid item container spacing={2} alignItems="stretch">
      <Grid item xs={3}>
        <OffsetCard
          title={OffsetMetric.offsetInventory as string}
          values={[
            {
              value: completedProjectPurchases?.length || 0,
              label: 'Active Projects',
            },
          ]}
          loading={loading}
          onClick={
            (userHasViewAccess &&
              (() => setOpenDrawer(OffsetMetric.offsetInventory))) ||
            undefined
          }
        />
      </Grid>
      <Grid item xs={3}>
        <OffsetCard
          title={OffsetMetric.allocated as string}
          values={[
            {
              value: totalAllocated || 0,
              label: 'MT C0₂E',
            },
          ]}
          loading={loading}
          onClick={
            (userHasViewAccess &&
              totalAllocated &&
              (() => setOpenDrawer(OffsetMetric.allocated))) ||
            undefined
          }
        />
      </Grid>
      <Grid item xs={3}>
        <OffsetCard
          title="Total Unallocated Credits"
          values={[
            {
              value: totalUnallocated || 0,
              label: 'MT C0₂E',
            },
          ]}
          loading={loading}
        />
      </Grid>
      <Grid item xs={3}>
        <OffsetCard
          title="Top Category of Interest"
          tooltipId="offsetTopCategoryOfInterest"
          values={[
            {
              categoryId: projectCategoryOfInterest?.id || '',
              label: projectCategoryOfInterest?.label || '--',
            },
          ]}
          loading={loading}
        />
      </Grid>
    </Grid>

    <Drawer open={!!openDrawer} onClose={onClose} anchor="right">
      <Box className={classes.drawer} p={5} height="100%">
        <Grid
          container
          direction="column"
          spacing={3}
          wrap="nowrap"
          className={classes.drawerWrapper}>
          <Grid item>
            <Typography variant="h1" className={classes.drawerHeader}>
              <IconButton aria-label="close" onClick={onClose} size="large">
                <SVG.Back />
              </IconButton>{' '}
              {openDrawer}
            </Typography>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            {openDrawer === OffsetMetric.offsetInventory && (
              <MyOffsetInventory />
            )}
            {openDrawer === OffsetMetric.allocated && <AllocatedHistory />}
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  </>;
};

export default Metrics;
