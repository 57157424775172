import { Grid, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100%',
  },
}));

type Props = {
  title: string;
  children?: JSX.Element;
};

const PageTitle: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid
        item
        container
        direction="row"
        className={classes.title}
        justifyContent="space-between"
        alignItems="center">
        <Grid item>
          <Typography variant="h1">{title}</Typography>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </Grid>
  );
};

export default PageTitle;
