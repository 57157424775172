import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../../assets/svg';
import Logo from '../Brand/Logo';
import LogoIcon from '../Brand/LogoIcon';

const useStyles = makeStyles(() => ({
  openRoot: {
    paddingLeft: 24,
  },
  closedButton: {
    padding: 0,
  },
}));
type Props = {
  drawer: boolean;
  openCallback(): void;
  closeCallback(): void;
};
const SidebarBrand: React.FC<Props> = ({
  drawer,
  openCallback,
  closeCallback,
}) => {
  const classes = useStyles();
  return (
    <>
      {drawer ? (
        <Grid
          className={classes.openRoot}
          alignItems="center"
          justifyContent="space-between"
          container
          direction="row">
          <Grid item xs={8}>
            <Logo />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              onClick={() => closeCallback()}
              style={{ color: 'white' }}
              size="large">
              <SVG.Collapse />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Grid item>
              <IconButton
                className={classes.closedButton}
                onClick={() => openCallback()}
                size="large">
                <LogoIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item>
            <IconButton
              onClick={() => openCallback()}
              style={{ color: 'white' }}
              size="large">
              <SVG.Expand />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Box height="1px" bgcolor="#032133" mb={2} mx={drawer ? '32px' : '8px'} />
    </>
  );
};

export default SidebarBrand;
