import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { getUserManager } from '../auth/userManager';
import { AuthorizationContextEvents } from '../contexts/AuthorizationContext';

const apiUrl = process.env.REACT_APP_SERVER_URL;

export const replaceApiUrl = (url?: string) =>
  (url || '').replace('__API_URL__', apiUrl || '');

const uploadLink = createUploadLink({
  uri: `${apiUrl}/graphql`,
});

let companyId: string | undefined;

setTimeout(() => {
  document.addEventListener(
    AuthorizationContextEvents.SET_COMPANY_ID,
    ({ detail }: CustomEventInit<string>) => {
      companyId = detail;
    },
  );
}, 10);

const authLink = setContext(async (_, { headers }) => {
  const token = (await getUserManager()?.getUser())?.id_token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      ...(companyId ? { 'zerome-user-companyid': companyId } : {}),
    },
  };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  let event: AuthorizationContextEvents | undefined;

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (extensions?.code === 'UNAUTHENTICATED') {
        event = AuthorizationContextEvents.LOGOUT;
      } else if (extensions?.code === 'INACTIVE_USER') {
        event = AuthorizationContextEvents.LOGOUT_INACTIVE;
      }
    });
  }

  // eslint-disable-next-line no-console
  if (networkError) console.log(`[Network error]: ${networkError}`);

  // clear token and redirect
  // TODO: append something to the query string to show a snackbar,
  // or provide some feedback as to what happened
  if (event) {
    // Need to send an event so the auth context can be reset since this is outside of react
    document.dispatchEvent(new CustomEvent(event));
  }
});

const client = new ApolloClient({
  uri: apiUrl,
  link: ApolloLink.from([
    errorLink,
    authLink.concat(uploadLink as unknown as ApolloLink),
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Role: {
        keyFields: false,
      },
      CompanyAction: {
        keyFields: false,
      },
      WaterFootprint: {
        keyFields: false,
      },
      Company: {
        fields: {
          goals: {
            // make sure ignoreCache isn't seperately cached
            keyArgs: [],
          },
        },
      },
    },
  }),
  connectToDevTools: process.env.REACT_APP_CONNECT_TO_DEV_TOOLS === 'true',
});

export default client;
