import { ImageSize } from '../common';
import { User, UserPointBreakout } from '../user/types';

export enum ContestDurationInterval {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum ContestStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  COMPLETE = 'COMPLETE',
}

export enum LeaderboardRollup {
  COMPANY = 'COMPANY',
  PARENT = 'PARENT',
}

export enum ContestType {
  AUTOMATIC_QUARTERLY = 'AUTOMATIC_QUARTERLY',
  COMPANY = 'COMPANY',
}

export type ContestOptions = {
  pointCategories: ContestPointCategory[];
  images: ContestImage[];
};

export type ContestPointCategory = {
  id: string;
  label: string;
};

export type ContestImage = {
  label: string;
  imageFilename: string;
  imageUrl: string;
};

type ContestTier = {
  minNumberOfWinners: number;
  points: number;
};

export type ContestRanking = {
  userId: string;
  user: User;
  contestTotal: number;
  rank: number;
};

export type Contest = {
  id: string;
  title: string;
  description?: string;
  companyIds: string[];
  contestType: ContestType;
  duration: {
    interval: ContestDurationInterval;
    startPeriod: string;
    startDate: string;
    endPeriod: string;
    endDate: string;
  };
  pointCategories?: string[];
  imageFilename?: string;
  imageUrl?: string;
  tiers: ContestTier[];
  status: ContestStatus;
  isDraft?: boolean;
  templateId: string;
  template: ContestTemplate;
  userPoints?: UserPointBreakout[];
  optedOutUsers?: string[];
  createdDate: string;
  updatedDate: string;
};

export type Leaderboard = {
  id: string;
  contestId: string;
  companyId: string;
  rollup: LeaderboardRollup;
  frequency: string;
  eventMonth: string;
  contestType: ContestType;
  tiers: ContestTier[];
  contestRanking?: ContestRanking[];
  timestamp: string;
};

export type ContestTemplate = {
  id: string;
  title: string;
  description: string;
  longDescription: string;
  habitFilter: {
    scoreFactorIds: string[];
  };
  imageUrls: Record<ImageSize, string>;
  blurHash: string;
  rules: {
    scoreUnits: {
      id: string;
      label: string;
      abbreviation: string;
      uomLabel: string;
    };
  };
  shareText: string;
};

export type ContestDashboardInfo = {
  active: number;
  complete: number;
  totalScoreByTemplate: {
    templateId: string;
    totalScore: number;
  }[];
};
