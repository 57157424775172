import { useQuery } from '@apollo/client';

import { GET_ALL_COUNTRIES } from '../graphql/settings';
import { ISOCountry } from '../types/common';

export const useCountryName = (code: string) => {
  const { data: { allCountries = [] } = {} } =
    useQuery<{ allCountries: ISOCountry[] }>(GET_ALL_COUNTRIES);

  return allCountries.find((a) => a.code === code)?.name || code;
};

export default useCountryName;
