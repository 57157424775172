import habitConfirmedDisabled from './HabitConfirmedDisabled';
import habitConfirmedEnabled from './HabitConfirmedEnabled';

const habitConfirmed = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? habitConfirmedDisabled(level, size)
    : habitConfirmedEnabled(level, size);

export default habitConfirmed;
