import { useQuery } from '@apollo/client';
import React, {
  createContext,
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { getUserManager } from '../auth/userManager';
import { ME } from '../graphql/auth';
import { generateCompanyQuery } from '../graphql/company/company';
import { LocalStorageKeys } from '../types/common';
import { BaseCompany } from '../types/company/types';
import { AbilityType, OperationType, User } from '../types/user/types';
import client from '../apollo/client';

export type UserHasAccessFn = (
  abilityName: AbilityType,
  operation: OperationType,
) => boolean;

export type AuthorizationContextType = {
  isAuthenticated: boolean;
  // Omitting companyId here because it shouldn't be counted on during impersonation
  user: Omit<User, 'companyId'> | null;
  logout: () => void;
  refetchMe: () => void;
  loading: boolean;
  company: BaseCompany;
  isImpersonating: boolean;
  setImpersonatedCompanyId: (companyId?: string) => void;
  userHasAccess: UserHasAccessFn;
  hasAdminAccess: boolean;
  isOnboarding: boolean;
  isInheritingSubscriptionData: boolean;
};

const initialContext: AuthorizationContextType = {
  isAuthenticated: false,
  user: null,
  logout: () => null,
  refetchMe: () => null,
  loading: false,
  company: {
    id: ' ',
    policyId: ' ',
    name: ' ',
    parentCompanyId: ' ',
    parentCompanyName: ' ',
    createdDate: '',
    currentFiscalYearDateRange: {
      startDate: '',
      endDate: '',
    },
    fiscalYearDateRanges: [],
    targetMt: 0,
  },
  isImpersonating: false,
  setImpersonatedCompanyId: () => null,
  userHasAccess: () => false,
  hasAdminAccess: false,
  isOnboarding: true,
  isInheritingSubscriptionData: false,
};

export enum AuthorizationContextEvents {
  LOGOUT = 'AuthorizationContext_LOGOUT',
  LOGOUT_INACTIVE = 'AuthorizationContext_LOGOUT_INACTIVE',
  SET_COMPANY_ID = 'AuthorizationContext_SET_COMPANY_ID',
}

export const AuthorizationContext = createContext(initialContext);

const AuthorizationProvider: FC = ({ children }) => {
  const [initializing, setInitializing] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [impersonatedCompanyId, setImpersonatedCompanyId] = useState<string>();

  const [company, setCompany] = useState<BaseCompany>({
    id: '',
    policyId: '',
    name: '',
    parentCompanyId: '',
    parentCompanyName: '',
    createdDate: '',
    currentFiscalYearDateRange: {
      startDate: '',
      endDate: '',
    },
    fiscalYearDateRanges: [],
    targetMt: 0,
  });

  const isInheritingSubscriptionData =
    !!company.subscriptionData?.isParentSubscriptionData;

  const hasAdminAccess =
    !!user &&
    !user?.roles.includes('member') &&
    !user?.roles.includes('member_contest_admin');

  const logout = async (url?: string) => {
    const userManager = await getUserManager();
    const oidcUser = await userManager?.getUser();

    setUser(null);
    document.dispatchEvent(
      new Event(AuthorizationContextEvents.SET_COMPANY_ID),
    );
    userManager?.removeUser();

    if (oidcUser) {
      userManager?.signoutRedirect({
        id_token_hint: oidcUser.id_token,
        post_logout_redirect_uri: url
          ? `${process.env.REACT_APP_AUTH_REDIRECT_URI}${url}`
          : process.env.REACT_APP_AUTH_REDIRECT_URI,
      });
    }
  };

  const logoutInactive = () => {
    logout('?inactive=1');
  };

  useEffect(() => {
    document.addEventListener(AuthorizationContextEvents.LOGOUT, () => logout);
    document.addEventListener(
      AuthorizationContextEvents.LOGOUT_INACTIVE,
      logoutInactive,
    );
    return () => {
      document.removeEventListener(
        AuthorizationContextEvents.LOGOUT,
        () => logout,
      );
      document.removeEventListener(
        AuthorizationContextEvents.LOGOUT_INACTIVE,
        logoutInactive,
      );
    };
  }, []);

  const {
    data: { me } = {},
    loading: userLoading,
    refetch,
  } = useQuery(ME, {
    skip: !isAuthenticated,
    onError: () => {
      logout();
    },
  });

  useEffect(() => {
    setUser(me ?? null);

    if (me) {
      document.dispatchEvent(
        new CustomEvent<string>(AuthorizationContextEvents.SET_COMPANY_ID, {
          detail: me.companyId,
        }),
      );
    }
  }, [me]);

  const userHasAccess = useCallback(
    (abilityName: AbilityType, operation: OperationType) =>
      user?.roleConfig.abilities.some((x) => {
        if (x.name !== abilityName) {
          return false;
        }

        // If the user has an ability and we're checking view they should always have permission
        if (operation === 'VIEW') {
          return true;
        }

        return operation === x.operation;
      }) || false,
    [user],
  );

  const { loading: companyLoading } = useQuery(
    generateCompanyQuery(userHasAccess),
    {
      variables: { id: impersonatedCompanyId || user?.companyId },
      onCompleted(data) {
        setCompany(data.company);
      },
      skip: !impersonatedCompanyId && !user?.companyId,
    },
  );

  const checkForToken = async () => {
    const oidcUser = await getUserManager()?.getUser();

    if (oidcUser) {
      setIsAuthenticated(true);
    }
    setInitializing(false);

    return !!oidcUser;
  };

  useEffect(() => {
    (async () => {
      // Copy values out of location in case location changes during async calls...
      const { href, pathname, search } = window.location;

      // On startup, first check for a state token (redirect coming back)
      const searchParams = new URLSearchParams(window.location.search);
      const state = searchParams.get('state');

      if (state) {
        await getUserManager()?.signinCallback(href);
        await checkForToken();
        window.history.pushState({}, '', '/');
      } else if (!(await checkForToken())) {
        // If a user doesn't exist, store the path requested for after login
        if (pathname.length <= 1 || pathname.endsWith('/login')) {
          localStorage.removeItem(LocalStorageKeys.redirectAfterLoginUrl);
        } else {
          localStorage.setItem(
            LocalStorageKeys.redirectAfterLoginUrl,
            `${pathname}${search}`,
          );
        }
      }
    })();
  }, []);

  const loading =
    (isAuthenticated && !user) || initializing || userLoading || companyLoading;

  const value = useMemo(
    () => ({
      isAuthenticated,
      logout,
      refetchMe: refetch,
      user,
      loading,
      company,
      hasAdminAccess,
      isInheritingSubscriptionData,
      setImpersonatedCompanyId: async (companyId?: string) => {
        setImpersonatedCompanyId(companyId);
        await client.resetStore();
      },
      isImpersonating:
        !!impersonatedCompanyId && impersonatedCompanyId === company.id,
      userHasAccess,
      isOnboarding: !user?.hasCompletedOnboardingQuestionnaire,
    }),
    [
      isAuthenticated,
      logout,
      user,
      loading,
      company,
      refetch,
      userHasAccess,
      isInheritingSubscriptionData,
    ],
  );

  return (
    <AuthorizationContext.Provider value={value}>
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationProvider;
