import React from 'react';
import { Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SVG from '../../assets/svg';
import useTooltipData from '../../hooks/useTooltipData';

const BlueTooltipStyle = withStyles({
  tooltip: {
    backgroundColor: '#0A3751',
    lineHeight: 1.5,
    maxWidth: 250,
  },
  arrow: {
    color: '#0A3751',
  },
})(Tooltip);

type Props = {
  id: string;
  iconColor?: 'white';
};

const AppInfo: React.FC<Props> = ({ id, iconColor }) => {
  const title = useTooltipData(id);

  if (!title) return null;

  return (
    <BlueTooltipStyle
      data-testid="app-info"
      arrow
      placement="bottom-end"
      style={{ marginTop: 4 }}
      title={title}>
      <SVG.Info style={iconColor ? { color: iconColor } : {}} />
    </BlueTooltipStyle>
  );
};

export default AppInfo;
