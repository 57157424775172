/* eslint-disable import/prefer-default-export */
import EarthFriendly from '../assets/img/myHabits/earth-friendly.png';
import Emissions from '../assets/img/myHabits/emissions.png';
import FullyGrownTrees from '../assets/img/myHabits/fully-grown-trees.png';
import LitersSaved from '../assets/img/myHabits/liters-saved.png';
import Materials from '../assets/img/myHabits/materials.png';
import { HabitBannerType } from '../types/habit/types.js';

export const getHabitBannerImage = (bannerType: HabitBannerType) => {
  switch (bannerType) {
    case 'EARTH_FRIENDLY':
      return EarthFriendly;
    case 'EMISSIONS':
      return Emissions;
    case 'FULLY_GROWN_TREES':
      return FullyGrownTrees;
    case 'LITERS_SAVED':
      return LitersSaved;
    case 'SINGLE_USE_MATERIAL':
      return Materials;
    default:
      return null;
  }
};

export const getHabitBannerText = (bannerType: HabitBannerType) => {
  switch (bannerType) {
    case 'EARTH_FRIENDLY':
      return 'Earth Friendly Habit!';
    case 'EMISSIONS':
      return `MT C0₂E Saved`;
    case 'FULLY_GROWN_TREES':
      return 'Fully Grown Trees';
    case 'LITERS_SAVED':
      return 'Liters Saved';
    case 'SINGLE_USE_MATERIAL':
      return 'kg of Materials Saved';
    default:
      return '';
  }
};
