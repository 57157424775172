import { Box, Typography } from '@mui/material';
import React from 'react';
import { VictoryPie } from 'victory';
import theme from '../../../theme/memberTheme';

const ProgressSize = 40;

export type ProgressIndicatorProps = {
  progress: number;
  total: number;
};

export function ProgressIndicator({
  progress,
  total,
}: ProgressIndicatorProps): JSX.Element {
  return (
    <Box
      style={{
        position: 'relative',
      }}>
      <VictoryPie
        height={ProgressSize}
        width={ProgressSize}
        padding={0}
        animate
        innerRadius={ProgressSize * 0.4}
        labels={[]}
        colorScale={[theme.palette.primary.main, theme.palette.secondary.main]}
        data={[{ y: progress }, { y: total - progress }]}
      />
      <Typography
        variant="h4"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          textAlign: 'center',
          color: theme.palette.primary.main,
          width: ProgressSize,
          lineHeight: `${ProgressSize}px`,
        }}>
        {progress}/{total}
      </Typography>
    </Box>
  );
}
