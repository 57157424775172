import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useLocation } from 'react-router';
import App from '../App';
import theme from './theme';
import memberTheme from './memberTheme';

const ThemeSelector: React.FC = () => {
  const location = useLocation();

  const memberLocations = ['/member/', '/login'];

  return (
    <ThemeProvider
      theme={
        memberLocations.some((x) => location.pathname.toLowerCase().includes(x))
          ? memberTheme
          : theme
      }>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
};

export default ThemeSelector;
