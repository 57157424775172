import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { AuthorizationContext } from '../contexts/AuthorizationContext';
import {
  AllCompaniesQueryOption,
  generateAllCompaniesQuery,
} from '../graphql/company/company';

export const useAllCompanies = (
  options?: Partial<AllCompaniesQueryOption> & {
    skip?: boolean;
  },
) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const {
    loading,
    data: { allCompanies = [] } = {},
    error,
  } = useQuery(generateAllCompaniesQuery(userHasAccess, options), {
    skip: options?.skip,
  });

  return { loading, allCompanies, error };
};

export default useAllCompanies;
