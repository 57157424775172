import React from 'react';
import { ButtonBase, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { MemberCommunicationListItem } from '../../communications/types';
import CommunicationHeaderImage from './CommunicationHeaderImage';
import CommunicationStarRating from './CommunicationStarRating';

type Props = {
  communication: MemberCommunicationListItem;
  imageHeight: number;
  onClick: (id: string) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    margin: '16px 0px',
    borderRadiuus: 8,
  },
  author: {
    color: '#6B6B6B',
  },
}));

const CommunicationCard: React.FC<Props> = ({
  communication,
  imageHeight,
  onClick,
}) => {
  const { author, title, id } = communication;
  const classes = useStyles();

  return (
    <ButtonBase
      style={{ width: '100%', textAlign: 'left' }}
      className={classes.root}
      onClick={() => onClick(id)}>
      <Grid container direction="column">
        <CommunicationHeaderImage
          communication={communication}
          imageHeight={imageHeight}
        />
        <Grid item style={{ marginBottom: 8 }}>
          <CommunicationStarRating
            size="md"
            rating={communication.ratings.global}
          />
        </Grid>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="caption" className={classes.author}>
          {author}
        </Typography>
      </Grid>
    </ButtonBase>
  );
};

export default CommunicationCard;
