import {
  Box,
  Typography,
  Dialog,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import SVG from '../../assets/svg';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
    padding: 16,
  },
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.text.primary,
  },
  warningIcon: {
    padding: 12,
    color: '#D33050',
  },
}));

type Props = {
  onClose: (removeBudget: boolean) => void;
};

const BudgetPanelChangePeriodModal: React.FC<Props> = ({ onClose }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const close = (removeBudget: boolean) => {
    setOpen(false);
    onClose(removeBudget);
  };

  return <>
    <Button size="small" onClick={() => setOpen(true)}>
      Edit
    </Button>
    <Dialog open={open} onClose={() => close(false)}>
      <Box className={classes.dialog}>
        <Grid container direction="column" alignItems="stretch">
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            style={{ position: 'relative' }}>
            <div className={classes.warningIcon}>
              <SVG.WarningTriangle />
            </div>
            <IconButton onClick={() => close(false)} className={classes.closeIcon} size="large">
              <SVG.Close />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ margin: '32px 0' }}>
            <Typography
              variant="h2"
              align="center"
              style={{ marginBottom: 8 }}>
              Changing the way you manage your budget will cause you to lose
              any values you currently have entered.
            </Typography>
            <Typography variant="body1">
              Are you sure you want to continue?
            </Typography>
          </Grid>
          <Grid item container direction="row" spacing={1}>
            <Grid item xs>
              <SmallPaddedButton
                variant="outlined"
                fullWidth
                onClick={() => close(false)}>
                Go back
              </SmallPaddedButton>
            </Grid>
            <Grid item xs>
              <SmallPaddedButton fullWidth onClick={() => close(true)}>
                Continue
              </SmallPaddedButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  </>;
};

export default BudgetPanelChangePeriodModal;
