import React from 'react';
import CommunicationsContainer from '../../components/communications/CommunicationsContainer';
import MainLayout from '../../components/layouts/MainLayout';
import CommunicationsProvider from '../../contexts/CommunicationsContext';

const Communications: React.FC = () => (
  <CommunicationsProvider>
    <MainLayout disableContainer childBoxStyle={{ padding: 0 }}>
      <CommunicationsContainer />
    </MainLayout>
  </CommunicationsProvider>
);

export default Communications;
