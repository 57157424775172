import { IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React from 'react';

const useStyles = makeStyles(() => ({
  button: {
    width: 40,
    height: 40,
    border: '1px solid',
    borderRadius: 8,
  },
}));

type Props = {
  onClick: () => void;
  color?: string;
};

const CardButton: React.FC<Props> = ({ onClick, color }) => {
  const classes = useStyles();
  return (
    <IconButton
      style={{ color: color || 'inherit' }}
      onClick={onClick}
      className={classes.button}
      size="large">
      <SvgIcon fontSize="large" component={ChevronRightIcon} />
    </IconButton>
  );
};

export default CardButton;
