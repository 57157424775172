import React, { useState } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';
import PieChartCard from '../../../common/PieChartCard/PieChartCard';
import { chartColors } from '../../../../theme/theme';
import { TooltipFormat } from '../../../common/ChartTooltips/TooltipContainer';
import NumberUtils from '../../../../utils/numberUtils';
import { CurrentBudget } from '../../../../types/dashboard/types';
import { ZeroMeTM } from '../../../../utils/stringConstants';

type Props = {
  currentBudget: CurrentBudget;
  style?: React.CSSProperties;
};

const TotalInvestmentsPie: React.FC<Props> = ({ currentBudget, style }) => {
  const [value, setValue] = useState<'value' | 'mt'>('value');

  const spent = currentBudget.spentValueByUom.mt || 0;
  const spentMt = currentBudget.spentMt || 0;
  const allocatedOffPlatform = currentBudget.allocatedOffPlatform || 0;
  const allocatedOffPlatformMt = currentBudget.allocatedOffPlatformMt || 0;
  const allocated = (currentBudget.allocated || 0) - allocatedOffPlatform;
  const allocatedMt = (currentBudget.allocatedMt || 0) - allocatedOffPlatformMt;
  const unallocated = currentBudget.spentValueByUom.mt - allocated;
  const unallocatedMt = spentMt - allocatedMt;

  const data = {
    mt: {
      tooltipFormat: 'number',
      allocatedData: allocatedMt,
      unallocatedData: unallocatedMt,
      offPlatformData: allocatedOffPlatformMt,
      labels: [
        NumberUtils.format(spentMt, 'integer'),
        NumberUtils.format(allocatedMt, 'integer'),
        NumberUtils.format(allocatedOffPlatformMt, 'integer'),
        NumberUtils.format(unallocatedMt, 'integer'),
      ],
    },
    value: {
      tooltipFormat: 'currency',
      allocatedData: allocated,
      unallocatedData: unallocated,
      offPlatformData: allocatedOffPlatform,
      labels: [
        NumberUtils.format(spent, 'currency'),
        NumberUtils.format(allocated, 'currency'),
        NumberUtils.format(allocatedOffPlatform, 'currency'),
        NumberUtils.format(unallocated, 'currency'),
      ],
    },
  }[value];

  return (
    <PieChartCard
      pieStyle={style}
      tooltipFormat={data.tooltipFormat as TooltipFormat}
      headerProps={{
        title: 'OFFSET ALLOCATION - LIFETIME TO DATE',
        rightContent: (
          <FormControl variant="outlined">
            <SelectMenu
              id="demo-simple-select"
              value={value}
              onChange={(e) => setValue(e.target.value as 'value' | 'mt')}>
              <MenuItem key="value" value="value">
                $ USD
              </MenuItem>
              <MenuItem key="mt" value="mt">
                MT
              </MenuItem>
            </SelectMenu>
          </FormControl>
        ),
        tooltipId: 'offsetAllocations',
      }}
      caption={`Note: RECs cannot be allocated through ${ZeroMeTM}`}
      data={[
        {
          id: 'Purchased Offsets',
          label: 'Purchased Offsets (Lifetime to date)',
          value: currentBudget.spentValueByUom.mt || 0,
          labelOnly: true,
          color: '',
        },
        {
          id: 'Allocated',
          label: 'Allocated (VCC)',
          value: data.allocatedData,
          color: chartColors.blue,
        },
        {
          id: 'AllocatedOffPlatform',
          label: 'Allocated (Off Platform)',
          value: data.offPlatformData,
          color: chartColors.lightBlue,
        },
        {
          id: 'Unallocated',
          label: 'Unallocated (VCC)',
          value: data.unallocatedData,
          color: chartColors.darkBlue,
        },
      ]}
      dataLabels={data.labels}
      showTooltip
    />
  );
};

export default TotalInvestmentsPie;
