import { useMutation } from '@apollo/client';
import { Typography, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import * as yup from 'yup';
import { CONFIRM_PROJECT_PURCHASES } from '../../../graphql/projects/projectMutations';
import {
  ProjectPurchaseHistory,
  ProjectPurchaseStatus,
} from '../../../types/offsetProject/types';
import { fancyMoment } from '../../../utils/dateUtils';
import AppDatePicker from '../../common/AppDatePicker';
import AppTextField from '../../common/AppTextField';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import OffsetRequestProjectDetails from './OffsetRequestProjectDetails';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 24,
    width: '100%',
    marginBottom: 24,
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
  element: {
    marginTop: 24,
  },
  root: {
    padding: '24px 48px',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  divider: {
    width: '556px',
    height: '0px',
  },
  diamondIcon: {
    marginLeft: 24,
    marginRight: 10,
    marginTop: 10,
  },
  indicator: {
    marginTop: 10,
  },
}));

type Props = {
  projectHistory: ProjectPurchaseHistory;
  onSave: () => void;
  onClose: () => void;
};

const FulfillOffsetRequestDetails: React.FC<Props> = ({
  projectHistory,
  onSave,
  onClose,
}) => {
  const classes = useStyles();
  // Form validation
  const schema = yup.object().shape({
    fulfilledDate: yup
      .date()
      .required()
      .test({
        name: 'notAFutureDate',
        message: 'Retirement Date cannot be in the future.',
        test: function test(retirementDate): boolean {
          if (!retirementDate) {
            // Don't show an error for an undefined date
            return true;
          }

          // Today's date
          const todayLocal = moment().startOf('day');

          const inputLocal = moment(retirementDate).startOf('day');

          return inputLocal.isSameOrBefore(todayLocal, 'day');
        },
      })
      .test({
        name: 'notAnOldDate',
        message: 'Retirement Date cannot be more than 30 days ago.',
        test: function test(retirementDate): boolean {
          if (!retirementDate) {
            // Don't show an error for an undefined date
            return true;
          }

          // Today's date
          const todayLocal = moment().startOf('day');

          // Oldest possible retirement date
          const oldestDate = todayLocal.clone().subtract(30, 'days');

          const inputLocal = moment(retirementDate).startOf('day');

          return inputLocal.isAfter(oldestDate, 'day');
        },
      }),
    serialNumber: yup.string().required('Serial number is required.'),
  });

  const [confirmProjectPurchases, { loading, error }] = useMutation(
    CONFIRM_PROJECT_PURCHASES,
  );

  if (projectHistory.status !== ProjectPurchaseStatus.APPROVED) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        fulfilledDate: new Date(),
        serialNumber: '',
      }}
      validationSchema={schema}
      onSubmit={async ({ fulfilledDate, serialNumber }) => {
        await confirmProjectPurchases({
          variables: {
            companyId: projectHistory.companyId,
            purchaseIds: [projectHistory.id],
            completedDate: moment(fulfilledDate).format('YYYY-MM-DD'),
            serialNumber,
          },
        });

        onClose();
        onSave();
      }}>
      {({ submitForm }) => (
        <DataStateHandler loading={loading} error={error}>
          <Grid container className={classes.root}>
            <OffsetRequestProjectDetails
              projectHistory={projectHistory}
              onClose={onClose}
            />
            <Paper className={classes.paper}>
              <Grid item container direction="row">
                <Typography variant="h3">
                  Fulfill Carbon Credit Purchase
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                className={classes.element}
              />
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="body2" color="textPrimary">
                    Requested By:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {projectHistory.requestedByUser?.email}
                  </Typography>
                </Grid>
                <Grid item />
                <Grid item xs={4}>
                  <Typography variant="body2" color="textPrimary">
                    Source:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2" color="textSecondary">
                    {projectHistory.project.source}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <Typography variant="body2">Serial number</Typography>
                <Field
                  name="serialNumber"
                  label=""
                  component={AppTextField}
                  placeholder="Specify the serial number"
                  fullWidth
                  type="text"
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <Typography variant="body2">
                  Select the day that these credits were retired
                </Typography>
                <Field
                  name="fulfilledDate"
                  component={AppDatePicker}
                  disableFuture
                  minDate={moment().subtract(30, 'days').toDate()}
                  labelFunc={(x: string) => fancyMoment(x, 'MMMM DD, YYYY')}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <SmallPaddedButton fullWidth onClick={() => submitForm()}>
                  Fulfill Carbon Credit Purchase
                </SmallPaddedButton>
              </Grid>
            </Paper>
          </Grid>
        </DataStateHandler>
      )}
    </Formik>
  );
};

export default FulfillOffsetRequestDetails;
