import {
  Box,
  Chip,
  Typography,
  Dialog,
  IconButton,
  Link,
  Grid,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DoneIcon from '@mui/icons-material/Done';
import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { GET_CATEGORY_STOCK_IMAGES } from '../../../graphql/projects/projectCategories';
import SVG from '../../../assets/svg';
import Loading from '../../common/Loading';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
    padding: 40,
  },
  additionalImage: {
    height: 100,
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
    color: theme.palette.text.primary,
  },
  selectedChip: {
    position: 'absolute',
    top: 10,
    right: 10,
    opacity: 0.75,
  },
}));

type Props = {
  categoryId: string;
  onSave: (urls: string[]) => void;
};

const AdditionalProjectImagesModal: React.FC<Props> = ({
  categoryId,
  onSave,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedUrls, setSelectedUrls] = useState<string[]>([]);

  const { loading, data: { projectCategoryStockImages = [] } = {} } = useQuery<{
    projectCategoryStockImages: string[];
  }>(GET_CATEGORY_STOCK_IMAGES, {
    skip: !open,
    variables: {
      id: categoryId,
    },
  });

  const classes = useStyles();

  const toggleUrl = (url: string) => {
    const index = selectedUrls.indexOf(url);

    if (index < 0) {
      setSelectedUrls([...selectedUrls, url]);
    } else {
      setSelectedUrls(selectedUrls.filter((x, i) => i !== index));
    }
  };

  const close = () => {
    setOpen(false);
    setSelectedUrls([]);
  };

  return <>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link
      component="button"
      variant="body2"
      onClick={() => setOpen(true)}
      underline="hover">
      Add Image from Library
    </Link>
    <Dialog open={open} onClose={close}>
      <IconButton className={classes.closeIcon} onClick={close} size="large">
        <SVG.Close />
      </IconButton>
      <Box className={classes.dialog}>
        {loading && <Loading />}
        {!loading && !!projectCategoryStockImages.length && (
          <>
            <Typography align="center" variant="h1">
              Additional Project Images
            </Typography>
            <Typography align="center" variant="body1">
              Select a prepopulated image
            </Typography>
            <Grid container direction="column" alignItems="stretch">
              <Grid
                item
                container
                direction="row"
                spacing={2}
                style={{ margin: '24px 0' }}>
                {projectCategoryStockImages.map((url) => (
                  <Grid
                    key={url}
                    item
                    xs={3}
                    style={{ position: 'relative' }}
                    onClick={() => toggleUrl(url)}>
                    <img
                      alt="Additional"
                      className={classes.additionalImage}
                      src={url}
                      style={{ cursor: 'pointer' }}
                    />
                    {selectedUrls.indexOf(url) >= 0 && (
                      <Chip
                        label={<DoneIcon color="primary" />}
                        className={classes.selectedChip}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={() => {
                    onSave(selectedUrls);
                    close();
                  }}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {!loading && !projectCategoryStockImages.length && (
          <>
            <Typography align="center" variant="h1">
              No Images Available in Library
            </Typography>
            <Grid container direction="column" alignItems="stretch">
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  size="medium"
                  color="primary"
                  onClick={close}>
                  OK
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Dialog>
  </>;
};

export default AdditionalProjectImagesModal;
