import React, { useMemo } from 'react';
import moment from 'moment';

import StatusChip from './StatusChip';
import {
  LocalizedQuiz,
  QuizDisplayStatus,
  QuizStatus,
  QuizType,
} from './types';

type Props = {
  quiz: LocalizedQuiz;
};

const resolveDisplayStatus = (quiz: LocalizedQuiz) => {
  // Statuses for quizType of `QUIZ` directly flow, as does `DRAFT` for all types.
  if (quiz.quizType === QuizType.POLL && quiz.status === QuizStatus.PUBLISHED) {
    const now = moment();
    const isActive = now.isSameOrAfter(moment(quiz.publishDate));
    const isExpired = now.isSameOrAfter(moment(quiz.expireDate));

    if (isExpired) {
      return QuizDisplayStatus.COMPLETED;
    }
    if (isActive) {
      return QuizDisplayStatus.ACTIVE;
    }
  }

  return quiz.status;
};

const QuizStatusChip: React.FC<Props> = ({ quiz }) => {
  const resolvedStatus = useMemo(() => resolveDisplayStatus(quiz), [quiz]);

  return <StatusChip status={resolvedStatus} label={resolvedStatus} />;
};

export default QuizStatusChip;
