import { useQuery } from '@apollo/client';

import { GET_TOOLTIPS } from '../graphql/settings';

export const useTooltipData = (id: string) => {
  const { data: { tooltips = [] } = {}, error } = useQuery(GET_TOOLTIPS, {
    variables: {
      platform: 'PORTAL',
    },
  });

  if (error) {
    return 'Error fetching tooltip data';
  }

  const tooltip = tooltips.find((t) => t.id === id)?.value;

  if (tooltip) {
    return tooltip;
  }

  return `Information coming soon. TOOLTIP ID: ${id}`;
};

export default useTooltipData;
