import allQuestionsCompleted from './AllQuestionsCompleted';
import articleRated from './ArticleRated';
import articleRead from './ArticleRead';
import habitAdded from './HabitAdded';
import habitConfirmed from './HabitConfirmed';
import leaderboardCompanyPlacementFirst from './LeaderboardCompanyFirst';
import leaderboardCompanyPlacementSecond from './LeaderboardCompanySecond';
import leaderboardCompanyPlacementThird from './LeaderboardCompanyThird';
import leaderboardDivisionPlacementFirst from './LeaderboardDivisionFirst';
import leaderboardDivisionPlacementSecond from './LeaderboardDivisionSecond';
import leaderboardDivisionPlacementThird from './LeaderboardDivisionThird';
import onboardingQuestions from './OnboardingQuestions';
import pollCompleted from './PollCompleted';
import questionsCompletedPersonal from './QuestionsCompletedPersonal';
import questionsCompletedResidential from './QuestionsCompletedResidential';
import questionsCompletedWork from './QuestionsCompletedWork';
import quizCompleted from './QuizCompleted';
import redeemProjectCredits from './RedeemProjectCredits';
import { BadgeId } from '../../../types/badge/types';
import { challengePlacementFirst } from './ChallengeFirst';
import { challengePlacementSecond } from './ChallengeSecond';
import { challengePlacementThird } from './ChallengeThird';

const badgeMap: Record<
  BadgeId,
  ({
    level,
    disabled,
    size,
  }: {
    level: number | undefined;
    disabled: boolean;
    size: number;
  }) => JSX.Element
> = {
  [BadgeId.ALL_QUESTIONS_COMPLETED]: allQuestionsCompleted,
  [BadgeId.ARTICLE_RATED]: articleRated,
  [BadgeId.ARTICLE_READ]: articleRead,
  [BadgeId.HABIT_ADDED]: habitAdded,
  [BadgeId.HABIT_CONFIRMED]: habitConfirmed,
  [BadgeId.ALL_HABITS_CONFIRMED]: habitConfirmed,
  [BadgeId.ONBOARDING_QUESTIONS]: onboardingQuestions,
  [BadgeId.POLL_COMPLETED]: pollCompleted,
  [BadgeId.QUESTIONS_COMPLETED_PERSONAL]: questionsCompletedPersonal,
  [BadgeId.QUESTIONS_COMPLETED_RESIDENTIAL]: questionsCompletedResidential,
  [BadgeId.QUESTIONS_COMPLETED_WORK]: questionsCompletedWork,
  [BadgeId.QUIZ_COMPLETED]: quizCompleted,
  [BadgeId.REDEEM_PROJECT_CREDITS]: redeemProjectCredits,
  [BadgeId.LEADERBOARD_PLACEMENT_DIVISION_FIRST]:
    leaderboardDivisionPlacementFirst,
  [BadgeId.LEADERBOARD_PLACEMENT_DIVISION_SECOND]:
    leaderboardDivisionPlacementSecond,
  [BadgeId.LEADERBOARD_PLACEMENT_DIVISION_THIRD]:
    leaderboardDivisionPlacementThird,
  [BadgeId.LEADERBOARD_PLACEMENT_COMPANY_FIRST]:
    leaderboardCompanyPlacementFirst,
  [BadgeId.LEADERBOARD_PLACEMENT_COMPANY_SECOND]:
    leaderboardCompanyPlacementSecond,
  [BadgeId.LEADERBOARD_PLACEMENT_COMPANY_THIRD]:
    leaderboardCompanyPlacementThird,
  [BadgeId.CHALLENGE_PLACEMENT_FIRST]: challengePlacementFirst,
  [BadgeId.CHALLENGE_PLACEMENT_SECOND]: challengePlacementSecond,
  [BadgeId.CHALLENGE_PLACEMENT_THIRD]: challengePlacementThird,
};

const BadgeIcon = ({
  badgeId,
  level,
  disabled,
  size = 120,
}: {
  badgeId: BadgeId;
  level: number | undefined;
  disabled: boolean;
  size?: number;
}) => {
  const svgFunction = badgeMap[badgeId];

  return svgFunction ? svgFunction({ level, disabled, size }) : null;
};

export default BadgeIcon;
