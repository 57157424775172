import { TypedDocumentNode, gql } from '@apollo/client';
import { AvatarMetadata } from '../types/common';

export const COMPANY_LOGO_URIS = `
  logoUris {
    large
    medium
    thumb
  }
`;

export const COMPANY_IMAGE_URIS = `
  imageUris {
    large
  }
  imagePosition
`;

export const PROJECT_HEADER_IMAGE_URIS = `
  headerImageUris {
    large
    medium
    thumb
  }
  headerImagePosition
`;
export const PROJECT_ADDITIONAL_SCALED_IMAGE_URIS = `
  additionalScaledImageUris {
    medium
  }`;

export const USER_PROFILE_IMAGE_URIS = `
  profileImageUris {
    medium
  }`;

export const GET_AVATAR_METADATA = gql`
  query GET_AVATAR_METADATA($language: String) {
    avatarMetadata(language: $language) {
      id
      title
      exclusiveComponents
      components {
        id
        title
        allowNone
        style
        colors
        sameColorAs
        options {
          id
          title
          svg
          style
        }
      }
    }
  }
` as TypedDocumentNode<{
  avatarMetadata: AvatarMetadata[];
}>;
