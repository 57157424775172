import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  header: {
    paddingTop: 16,
    marginBottom: '32px',
  },
  subHeader: {
    margin: '32px 0px 32px 0px',
  },
  nav: {
    float: 'right',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },

  name: {
    float: 'left',
  },
}));

export default useStyles;
