import leaderboardCompanySecondDisabled from './LeaderboardCompanySecondDisabled';
import leaderboardCompanySecondEnabled from './LeaderboardCompanySecondEnabled';

const leaderboardCompanyPlacementSecond = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? leaderboardCompanySecondDisabled(level, size)
    : leaderboardCompanySecondEnabled(level, size);

export default leaderboardCompanyPlacementSecond;
