import { Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles({
  divider: {
    flex: 1,
  },
});

/**
 * It returns a horizontal grid with a divider on either side of the children
 * @param  - `children` - The text to display in the middle of the divider.
 * @returns The children with a divider on either side.
 */
const DividerWithText = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      alignItems="center"
      spacing={1}>
      <Grid item className={classes.divider}>
        <Divider />
      </Grid>
      <Grid item>{children}</Grid>
      <Grid item className={classes.divider}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default DividerWithText;
