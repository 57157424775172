import { Grid } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { CategoryInterest } from '../../../../types/dashboard/types';
import ChartUtils from '../../../../utils/chartUtils';
import NumberUtils from '../../../../utils/numberUtils';
import AppCard from '../../../common/AppCard/AppCard';
import LogoTick from '../../../common/ChartTick/LogoTick';
import FormattedTooltip from '../../../common/ChartTooltips/FormattedTooltip';
import NoDataAvailable from '../../../common/NoDataAvailable';

type Props = {
  data: CategoryInterest[];
  height?: number;
};
const ProjectCategoryInterest: React.FC<Props> = ({ data, height }) => {
  const getIconProps = (label: string) => {
    const cat = data.find((d) => d.label === label);
    return {
      iconUri: cat?.iconUri || '',
      color: cat?.color || '',
    };
  };
  const dataPercents = data.map((item) => ({
    ...item,
    [item.id]: item.percent,
  }));
  const hasData = data.findIndex(({ percent }) => percent) > -1;

  return (
    <AppCard
      headerProps={{
        title: 'INVESTMENT INTEREST BY PROJECT CATEGORY',
        tooltipId: 'projectCategoryInterest',
      }}
      height={height || 635}>
      <Grid container spacing={0} direction="column">
        <div style={{ height: (height || 635) - 35 }}>
          {hasData ? (
            <ResponsiveBar
              data={dataPercents}
              keys={data.map((d) => d.id)}
              indexBy="id"
              margin={{ top: 10, right: 0, bottom: 125, left: 28 }}
              padding={0.8}
              innerPadding={1}
              groupMode="stacked"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={data.map((d) => d.color)}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              valueFormat=" >(,~%"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
                renderTick: (tick) => (
                  <LogoTick tick={tick} {...getIconProps(tick.value)} />
                ),
              }}
              axisLeft={{
                format: (value) => NumberUtils.format(value, 'percent'),
                tickSize: 0,
                tickPadding: 1,
                tickRotation: 0,
                tickValues: hasData ? 6 : [0, 0.2, 0.4, 0.6, 0.8, 1],
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              tooltip={(props) => (
                <FormattedTooltip
                  id={props.id}
                  format="integer"
                  value={props.data.value}
                />
              )}
              role="application"
              ariaLabel="Project Category Interest Chart"
              {...ChartUtils.configureChartMinMaxValues(hasData)}
            />
          ) : (
            <NoDataAvailable style={{ height: 600 }} />
          )}
        </div>
      </Grid>
    </AppCard>
  );
};

export default ProjectCategoryInterest;
