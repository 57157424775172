import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';

const useStyles = makeStyles((theme) => ({
  text: { color: theme.palette.common.white },
}));

const Logout: React.FC = () => {
  const classes = useStyles();
  const { logout } = useContext(AuthorizationContext);
  const onLogoutClick = () => {
    logout();
  };
  return (
    <Button onClick={onLogoutClick} className={classes.text} variant="text">
      <Trans i18nKey="logout" />
    </Button>
  );
};

export default Logout;
