import { useMutation, useQuery } from '@apollo/client';
import {
  Alert,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import omitDeep from 'omit-deep-lodash';
import makeStyles from '@mui/styles/makeStyles';
import { Field, FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext, useMemo, useState } from 'react';
import { t } from 'i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationDetailsHeader from '../CommunicationDetailsHeader';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import AppTextField from '../../common/AppTextField';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import {
  CommunicationListItem,
  EditableQuiz,
  EditableQuizQuestion,
  EditableQuizQuestionOption,
  LocalizedQuiz,
  QuizStatus,
  QuizType,
} from '../types';
import SVG from '../../../assets/svg';
import QuizIcon from '../../../assets/svg/quiz.svg';
import { LanguageKey } from '../../../types/common';
import {
  GET_EDITABLE_QUIZ,
  UPSERT_QUIZ,
} from '../../../graphql/communications/communications';
import {
  findLanguageIndex,
  genLanguageKeySchema,
  getLanguageText,
} from '../../../utils/languageUtils';
import { QuestionnaireProvider } from '../../../contexts/QuestionnaireContext';
import CurrentQuestionnaireCard from '../../common/Questionnaire/CurrentQuestionnaireCard';
import PublishFields from './PublishFields';
import ConfirmCancelModal from '../../common/ConfirmCancelModal/ConfirmCancelModal';
import CompanyInput from './CompanyInput';
import { SplitButton } from '../../common/Buttons/SplitButton';

enum EditMode {
  DRAFT = 'DRAFT',
  PUBLISH = 'PUBLISH',
}

type Props = {
  quizId?: string;
  newQuizType: QuizType;
  onClose: () => void;
};

type Expandable<T> = T & { expanded?: boolean };

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 64px',
  },
  form: {
    padding: '16px 0px !important',
    '&.MuiGrid-item': {
      maxWidth: '100%',
    },
  },
  paper: {
    padding: 24,
  },
  field: {
    backgroundColor: '#fff',
    borderRadius: 8,
  },
  questionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  questionHeader: {
    paddingBottom: 8,
  },
  discoverContentChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  pollPublishWarning: {
    paddingTop: 16,
  },
}));

const generateSchema = (
  type: QuizType,
  communications: CommunicationListItem[],
) =>
  yup.object().shape({
    editMode: yup.string().oneOf(Object.values(EditMode)),
    title: genLanguageKeySchema(t('titleIsRequired')),
    status: yup.string().oneOf(Object.values(QuizStatus)),
    ...(type === QuizType.POLL
      ? {
          publishDate: yup.date().when('editMode', {
            is: EditMode.PUBLISH,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema,
          }),
          expireDate: yup
            .date()
            .when('editMode', {
              is: EditMode.PUBLISH,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema,
            })
            .when('publishDate', (publishDate, schema) =>
              publishDate && moment(publishDate).isValid()
                ? schema.min(publishDate, t('minExpireDate'))
                : schema,
            ),
        }
      : {}),
    communicationIds: yup.array().when(['quizType', 'editMode'], {
      is: (quizType: QuizType, editMode: EditMode) =>
        quizType === QuizType.QUIZ && editMode === EditMode.PUBLISH,
      then: yup
        .array()
        .of(yup.string())
        .min(1, t('discoverContentIsRequired'))
        .test({
          name: 'valid-communication',
          message: t('discoverContentAlreadyAssigned'),
          test: function validCommunicationTest(value) {
            return (value ?? []).every((v) => {
              const communication = communications?.find((c) => c.id === v);
              return (
                !communication?.quiz ||
                communication?.quiz?.id === this.parent.id
              );
            });
          },
        }),
      otherwise: yup.array().nullable(),
    }),
    companyIds: yup.array().when(['quizType', 'editMode'], {
      is: (quizType: QuizType, editMode: EditMode) =>
        quizType === QuizType.POLL && editMode === EditMode.PUBLISH,
      then: yup.array().of(yup.string()).min(1),
      otherwise: yup.array().nullable(),
    }),
    questions: yup.array().when('editMode', (editMode, schema) =>
      schema
        .of(
          yup.object().shape({
            ...(type === QuizType.QUIZ
              ? {
                  correctAnswerId:
                    editMode === EditMode.PUBLISH
                      ? yup.string().required(t('correctAnswerIsRequired'))
                      : yup.string(),
                }
              : {}),
            questionText: genLanguageKeySchema(t('questionTextIsRequired'), {
              maxLength: 250,
              required: editMode === EditMode.PUBLISH,
            }),
            options: yup
              .array()
              .of(
                yup.object().shape({
                  answerText: genLanguageKeySchema(t('answerTextIsRequired'), {
                    maxLength: 30,
                    required: editMode === EditMode.PUBLISH,
                  }),
                  ...(type === QuizType.QUIZ
                    ? {
                        answerExplanation: genLanguageKeySchema(
                          t('answerExplanationIsRequired'),
                          {
                            required: editMode === EditMode.PUBLISH,
                          },
                        ),
                      }
                    : {}),
                }),
              )
              .min(2)
              .max(6),
          }),
        )
        .min(1),
    ),
  });

const QuizDetails: React.FC<Props> = ({ quizId, newQuizType, onClose }) => {
  const { companyId, refetchCommunicationsQuery, communications } = useContext(
    CommunicationsContext,
  );

  const {
    loading,
    error,
    data: { editableQuiz } = {},
  } = useQuery(GET_EDITABLE_QUIZ, {
    variables: { quizId, companyId },
    skip: !quizId,
  });

  // const [lang, setLang] = useState('en');
  const lang = 'en'; // TODO: allow changing language / autotranslate?
  const [showPreview, setShowPreview] = useState(false);
  const [unpublishWarningOpen, setUnpublishWarningOpen] = useState(false);

  const [upsert, { loading: upsertLoading, error: upsertError }] =
    useMutation(UPSERT_QUIZ);

  const genLanguageKey = (language = 'en'): LanguageKey => ({
    language,
    text: '',
  });

  const genDefaultLanguageKeys = () => [genLanguageKey()];

  const genAnswer = (): EditableQuizQuestionOption => ({
    answerId: crypto.randomUUID(),
    answerText: genDefaultLanguageKeys(),
    answerExplanation: genDefaultLanguageKeys(),
  });

  const genQuestion = (
    quizType?: QuizType,
  ): EditableQuizQuestion & { expanded: boolean } => {
    const answer1 = genAnswer();

    return {
      questionId: crypto.randomUUID(),
      questionText: genDefaultLanguageKeys(),
      options: [answer1, genAnswer()],
      expanded: true,
      ...(quizType === QuizType.QUIZ
        ? { correctAnswerId: answer1.answerId }
        : {}),
    };
  };

  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const initialValues = useMemo<
    EditableQuiz & {
      editMode: EditMode;
    }
  >(
    () =>
      editableQuiz
        ? {
            ...editableQuiz,
            editMode:
              editableQuiz.status === QuizStatus.PUBLISHED
                ? EditMode.PUBLISH
                : EditMode.DRAFT,
          }
        : {
            quizType: newQuizType,
            status: QuizStatus.DRAFT,
            title: genDefaultLanguageKeys(),
            communicationIds: [],
            questions: [genQuestion(newQuizType)],
            companyIds: companyId ? [companyId] : [],
            ...(newQuizType === QuizType.POLL
              ? { publishDate: '', expireDate: '' }
              : {}),
            editMode: EditMode.DRAFT,
          },
    [editableQuiz],
  );

  const editableToLocalized = (editable: EditableQuiz): LocalizedQuiz => ({
    ...editable,
    id: editableQuiz?.id ?? 'draft-quiz',
    title: getLanguageText(editable.title, lang),
    iconUrl: editable.iconUrl ?? QuizIcon,
    questions: editable.questions.map((q) => ({
      ...q,
      questionText: getLanguageText(q.questionText, lang),
      options: q.options.map((o) => ({
        ...o,
        answerText: getLanguageText(o.answerText, lang),
        answerExplanation: getLanguageText(o.answerExplanation, lang),
      })),
    })),
  });

  const hasEditAccess = companyId
    ? userHasAccess('Client.Communication.Quizzes', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Quizzes', 'EDIT');

  const isPublished = editableQuiz?.status === QuizStatus.PUBLISHED;
  // todo: add status != COMPLETED for polls
  const canEdit =
    editableQuiz?.status !== QuizStatus.UNPUBLISHED && hasEditAccess;

  const quizType = newQuizType || editableQuiz?.quizType || QuizType.QUIZ;
  const isValidCommunicationOption = (
    communication: CommunicationListItem | undefined,
  ) =>
    !!communication &&
    (!communication.quiz || communication?.quiz?.id === quizId);

  return (
    <DataStateHandler
      loading={loading || upsertLoading}
      error={error || upsertError}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={generateSchema(quizType, communications)}
        onSubmit={async (values) => {
          await upsert({
            variables: {
              quiz: omitDeep(values, [
                'createdDate',
                'updatedDate',
                'expanded',
                'iconUrl',
                'editMode',
                '__typename',
              ]),
              companyId,
            },
            onCompleted: () => onClose(),
          });
          refetchCommunicationsQuery();
        }}
        validateOnMount>
        {(props) => (
          <>
            <Grid
              container
              direction="column"
              className={classes.root}
              spacing={2}>
              <Grid item>
                <CommunicationDetailsHeader
                  title={
                    quizId
                      ? `${canEdit ? t('edit') : t('view')} ${
                          quizType === QuizType.QUIZ
                            ? t('quiz.quiz')
                            : t('poll.poll')
                        }`
                      : t('createNew')
                  }
                  canEdit={canEdit}
                  onClose={onClose}
                  saveButton={
                    isPublished ? (
                      <SmallPaddedButton
                        disabled={
                          !props.isValid || props.isSubmitting || !canEdit
                        }
                        variant="contained"
                        color="primary"
                        onClick={props.submitForm}>
                        {t('save')}
                      </SmallPaddedButton>
                    ) : (
                      <SplitButton
                        options={[
                          {
                            text: {
                              value: t('saveAsDraft'),
                              variant: 'caption',
                            },
                            id: 'draft',
                            onClick: async () => {
                              props.setFieldValue('status', QuizStatus.DRAFT);
                              await props.submitForm();
                            },
                            disabled:
                              !props.isValid || props.isSubmitting || !canEdit,
                          },
                          {
                            text: {
                              value: t('publish'),
                              variant: 'caption',
                            },
                            id: 'published',
                            onClick: async () => {
                              props.setFieldValue(
                                'status',
                                QuizStatus.PUBLISHED,
                              );
                              await props.submitForm();
                            },
                            disabled:
                              !props.isValid || props.isSubmitting || !canEdit,
                          },
                        ]}
                        onOptionSelected={(index) => {
                          // index is dependent on the order of the options array above!
                          props.setFieldValue(
                            'editMode',
                            index ? EditMode.PUBLISH : EditMode.DRAFT,
                          );
                        }}
                      />
                    )
                  }
                  additionalActions={
                    <>
                      {isPublished && props.values.quizType === QuizType.QUIZ && (
                        <Grid item>
                          <SmallPaddedButton
                            disabled={
                              !props.isValid || props.isSubmitting || !canEdit
                            }
                            variant="contained"
                            color="error"
                            // if the quiz is published, you no longer have the option to save as draft.
                            onClick={async () => {
                              setUnpublishWarningOpen(true);
                            }}>
                            {t('unpublish')}
                          </SmallPaddedButton>
                        </Grid>
                      )}
                      <Grid item>
                        <SmallPaddedButton
                          onClick={() => setShowPreview(true)}
                          variant="contained"
                          color="primary">
                          {t('preview')}
                        </SmallPaddedButton>
                      </Grid>
                    </>
                  }
                />
              </Grid>
              <Grid item className={classes.form}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">{t('title')}</Typography>
                    <Field
                      name={`title[${findLanguageIndex(
                        props.values.title,
                        lang,
                      )}].text`}
                      inputProps={{ maxLength: 60 }}
                      label=""
                      component={AppTextField}
                      disabled={!canEdit}
                      placeholder={
                        quizType === QuizType.QUIZ
                          ? t('quiz.quizTitle')
                          : t('poll.pollTitle')
                      }
                      fullWidth
                    />
                  </Grid>
                  {props.values.quizType === QuizType.POLL && (
                    <Grid item xs={12}>
                      <CompanyInput
                        disabled={!canEdit}
                        initialCompanyIds={
                          props.values.companyIds ||
                          (companyId ? [companyId] : [])
                        }
                        rootCompanyId={companyId}
                        label={t('audience')}
                        onChange={(v) => props.setFieldValue('companyIds', v)}
                      />
                    </Grid>
                  )}
                  {props.values.quizType === QuizType.QUIZ && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          {t('connectedDiscoverContent')}
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            multiple
                            disabled={!canEdit || isPublished}
                            error={
                              // Convoluted error check is the standard "touched + error", but also if we come
                              // into the screen with an invalid value here, we want to call it out.  This could
                              // happen if you have a DRAFT quiz for an article that has since had another
                              // published quiz assigned.
                              !!props.errors.communicationIds &&
                              (!!props.touched.communicationIds ||
                                (props.values.communicationIds ?? []).length >
                                  0)
                            }
                            value={
                              (props.values.communicationIds ??
                                []) as unknown as string
                            }
                            onChange={(event: SelectChangeEvent<string>) => {
                              const selectedCommunicationIds = event.target
                                .value as unknown as string[];

                              props.setFieldValue(
                                'communicationIds',
                                selectedCommunicationIds ?? [],
                              );
                            }}
                            onBlur={() =>
                              props.setFieldTouched('communicationIds', true)
                            }
                            input={<OutlinedInput />}
                            renderValue={(selected: unknown) => (
                              <div className={classes.discoverContentChips}>
                                {(selected as string[]).map(
                                  (communicationId) => (
                                    <Chip
                                      key={communicationId}
                                      {...(!isValidCommunicationOption(
                                        communications?.find(
                                          (c) => c.id === communicationId,
                                        ),
                                      )
                                        ? { color: 'error' }
                                        : {})}
                                      label={
                                        communications?.find(
                                          (c) => c.id === communicationId,
                                        )?.title
                                      }
                                      deleteIcon={
                                        <SVG.Close
                                          onMouseDown={(e) =>
                                            e.stopPropagation()
                                          }
                                        />
                                      }
                                      onDelete={() => {
                                        props.setFieldValue(
                                          'communicationIds',
                                          props.values.communicationIds?.filter(
                                            (cid) => cid !== communicationId,
                                          ),
                                        );
                                      }}
                                    />
                                  ),
                                )}
                              </div>
                            )}>
                            {communications?.map((communication) => (
                              <MenuItem
                                disabled={
                                  !!communication.quiz &&
                                  communication.id !== quizId
                                }
                                key={communication.id}
                                value={communication.id}>
                                {communication.title}
                              </MenuItem>
                            ))}
                          </Select>
                          {!!props.errors.communicationIds &&
                            (!!props.touched.communicationIds ||
                              (props.values.communicationIds ?? []).length >
                                0) && (
                              <FormHelperText error>
                                {props.errors.communicationIds}
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    {props.values.status !== 'DRAFT' ||
                    props.values.quizType !== 'QUIZ' ? null : (
                      <Alert severity="warning">
                        {t('quiz.quizPublishWarning')}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="questions"
                      render={(arrayHelpers) => (
                        <DragDropContext
                          onDragEnd={async ({ source, destination }) => {
                            arrayHelpers.swap(
                              source.index,
                              destination?.index as number,
                            );
                          }}>
                          <Droppable droppableId="questions">
                            {(provided) => (
                              <div
                                className={classes.questionContainer}
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {props.values.questions?.map(
                                  (question, index) => (
                                    <Draggable
                                      key={question.questionId}
                                      isDragDisabled={!canEdit || isPublished}
                                      draggableId={question.questionId}
                                      index={index}>
                                      {(dragProvided) => (
                                        <div
                                          ref={dragProvided.innerRef}
                                          {...dragProvided.draggableProps}>
                                          <Paper className={classes.paper}>
                                            <div
                                              className={classes.questionHeader}
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 16,
                                              }}>
                                              {canEdit && !isPublished && (
                                                <span>
                                                  <div
                                                    {...dragProvided.dragHandleProps}>
                                                    <SVG.DragHandle />
                                                  </div>
                                                </span>
                                              )}
                                              <h3
                                                style={{
                                                  marginTop: 0,
                                                  marginBottom: 0,
                                                }}>
                                                {t('questionN', {
                                                  index: index + 1,
                                                })}
                                              </h3>
                                              <div style={{ flex: 1 }} />
                                              {!(
                                                question as Expandable<EditableQuizQuestion>
                                              ).expanded && (
                                                <Button
                                                  variant="text"
                                                  size="small"
                                                  onClick={() => {
                                                    arrayHelpers.replace(
                                                      index,
                                                      {
                                                        ...question,
                                                        expanded: true,
                                                      },
                                                    );
                                                  }}>
                                                  {t('responseOptions')}
                                                  <SVG.CaretDownSmall />
                                                </Button>
                                              )}
                                              {(
                                                question as Expandable<EditableQuizQuestion>
                                              ).expanded && (
                                                <Button
                                                  variant="text"
                                                  size="small"
                                                  onClick={() => {
                                                    arrayHelpers.replace(
                                                      index,
                                                      {
                                                        ...question,
                                                        expanded: false,
                                                      },
                                                    );
                                                  }}>
                                                  {t('collapse')}
                                                  <SVG.CaretUpSmall />
                                                </Button>
                                              )}
                                              {props.values.questions?.length >
                                                1 &&
                                                canEdit &&
                                                !isPublished && (
                                                  <IconButton
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                    size="large">
                                                    <SVG.Delete />
                                                  </IconButton>
                                                )}
                                            </div>
                                            <Field
                                              name={`questions[${index}].questionText[${findLanguageIndex(
                                                question.questionText,
                                                lang,
                                              )}].text`}
                                              label=""
                                              className={classes.field}
                                              component={AppTextField}
                                              disabled={!canEdit}
                                              placeholder={t('questionText')}
                                              fullWidth
                                              multiline
                                              rows={3}
                                              inputProps={{
                                                maxLength: 250,
                                              }}
                                            />
                                            {(
                                              question as Expandable<EditableQuizQuestion>
                                            ).expanded && (
                                              <>
                                                <h3>{t('responseOptions')}</h3>
                                                <FieldArray
                                                  name={`questions[${index}].options`}
                                                  render={(
                                                    optionArrayHelpers,
                                                  ) => (
                                                    <Grid
                                                      container
                                                      spacing={2}
                                                      alignItems="center">
                                                      {props.values.questions?.[
                                                        index
                                                      ]?.options?.map(
                                                        (
                                                          option,
                                                          optionIndex,
                                                        ) => (
                                                          <React.Fragment
                                                            key={
                                                              option.answerId
                                                            }>
                                                            <Grid item xs={12}>
                                                              <Divider />
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              xs={
                                                                props.values
                                                                  .questions?.[
                                                                  index
                                                                ]?.options
                                                                  ?.length > 2
                                                                  ? 11
                                                                  : 12
                                                              }>
                                                              <Typography variant="body2">
                                                                {t(
                                                                  'answerNText',
                                                                  {
                                                                    index:
                                                                      optionIndex +
                                                                      1,
                                                                  },
                                                                )}
                                                              </Typography>
                                                              <Field
                                                                name={`questions[${index}].options[${optionIndex}].answerText[${findLanguageIndex(
                                                                  option.answerText,
                                                                  lang,
                                                                )}].text`}
                                                                label=""
                                                                className={
                                                                  classes.field
                                                                }
                                                                component={
                                                                  AppTextField
                                                                }
                                                                disabled={
                                                                  !canEdit
                                                                }
                                                                placeholder={t(
                                                                  'answerNText',
                                                                  {
                                                                    index:
                                                                      optionIndex +
                                                                      1,
                                                                  },
                                                                )}
                                                                fullWidth
                                                                rows={3}
                                                                inputProps={{
                                                                  maxLength: 30,
                                                                }}
                                                              />
                                                            </Grid>

                                                            {props.values
                                                              .questions?.[
                                                              index
                                                            ]?.options?.length >
                                                              2 && (
                                                              <Grid item xs={1}>
                                                                <Grid
                                                                  container
                                                                  justifyContent="flex-end">
                                                                  <IconButton
                                                                    onClick={() =>
                                                                      optionArrayHelpers.remove(
                                                                        optionIndex,
                                                                      )
                                                                    }
                                                                    size="large">
                                                                    <SVG.Delete />
                                                                  </IconButton>
                                                                </Grid>
                                                              </Grid>
                                                            )}
                                                            {quizType ===
                                                              QuizType.QUIZ && (
                                                              <Grid
                                                                item
                                                                xs={12}>
                                                                <Typography variant="body2">
                                                                  {t(
                                                                    'answerNExplanation',
                                                                    {
                                                                      index:
                                                                        optionIndex +
                                                                        1,
                                                                    },
                                                                  )}
                                                                </Typography>
                                                                <Field
                                                                  name={`questions[${index}].options[${optionIndex}].answerExplanation[${findLanguageIndex(
                                                                    option.answerText,
                                                                    lang,
                                                                  )}].text`}
                                                                  label=""
                                                                  className={
                                                                    classes.field
                                                                  }
                                                                  component={
                                                                    AppTextField
                                                                  }
                                                                  disabled={
                                                                    !canEdit
                                                                  }
                                                                  placeholder={t(
                                                                    'answerNExplanation',
                                                                    {
                                                                      index:
                                                                        optionIndex +
                                                                        1,
                                                                    },
                                                                  )}
                                                                  fullWidth
                                                                  multiline
                                                                  rows={3}
                                                                />
                                                              </Grid>
                                                            )}
                                                          </React.Fragment>
                                                        ),
                                                      )}
                                                      <Grid item xs={12}>
                                                        <Divider />
                                                      </Grid>
                                                      {quizType ===
                                                        QuizType.QUIZ && (
                                                        <Grid item xs={12}>
                                                          <Typography variant="body2">
                                                            {t('correctAnswer')}
                                                          </Typography>
                                                          <Field
                                                            name={`questions[${index}].correctAnswerId`}
                                                            label=""
                                                            component={
                                                              AppTextField
                                                            }
                                                            disabled={
                                                              !canEdit ||
                                                              isPublished
                                                            }
                                                            className={
                                                              classes.field
                                                            }
                                                            fullWidth
                                                            select
                                                            options={question.options.map(
                                                              (x, i) => ({
                                                                label:
                                                                  getLanguageText(
                                                                    x.answerText,
                                                                    lang,
                                                                  ) ||
                                                                  t(
                                                                    'answerNText',
                                                                    {
                                                                      index:
                                                                        i + 1,
                                                                    },
                                                                  ),
                                                                value:
                                                                  x.answerId,
                                                              }),
                                                            )}
                                                          />
                                                        </Grid>
                                                      )}
                                                      {canEdit &&
                                                        !isPublished &&
                                                        (
                                                          props.values
                                                            .questions[index]
                                                            .options ?? []
                                                        ).length < 6 && (
                                                          <Grid item xs={12}>
                                                            <SmallPaddedButton
                                                              fullWidth
                                                              onClick={() =>
                                                                optionArrayHelpers.push(
                                                                  genAnswer(),
                                                                )
                                                              }>
                                                              +{' '}
                                                              {t('addAnAnswer')}
                                                            </SmallPaddedButton>
                                                          </Grid>
                                                        )}
                                                    </Grid>
                                                  )}
                                                />
                                              </>
                                            )}
                                          </Paper>
                                        </div>
                                      )}
                                    </Draggable>
                                  ),
                                )}
                                {canEdit && !isPublished && (
                                  <>
                                    <Grid item xs={12} mt={2} mb={2}>
                                      <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <SmallPaddedButton
                                        fullWidth
                                        onClick={() =>
                                          arrayHelpers.push(
                                            genQuestion(quizType),
                                          )
                                        }>
                                        + {t('addAQuestion')}
                                      </SmallPaddedButton>
                                    </Grid>
                                  </>
                                )}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    />
                    {quizType === QuizType.POLL && (
                      <>
                        <Grid
                          item
                          xs={12}
                          className={classes.pollPublishWarning}>
                          {props.values.status === 'DRAFT' && (
                            <Alert severity="warning">
                              {t('poll.pollPublishWarning')}
                            </Alert>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <PublishFields minDate={new Date()} />
                        </Grid>
                        <Grid item xs={12}>
                          <PublishFields
                            minDate={
                              props.values.publishDate
                                ? new Date(props.values.publishDate)
                                : new Date()
                            }
                            overrides={{
                              fieldName: 'expireDate',
                              label: t('expirationDate'),
                              labelDetail: t(
                                'poll.whenWouldYouLikeThePollToClose',
                              ),
                            }}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Dialog
              open={showPreview}
              onClose={() => setShowPreview(false)}
              maxWidth="md">
              <DialogTitle>
                <Grid container justifyContent="space-between">
                  <Typography variant="h1">
                    {t('preview')}{' '}
                    {quizType === QuizType.QUIZ
                      ? t('quiz.quiz')
                      : t('poll.poll')}
                  </Typography>
                  <IconButton onClick={() => setShowPreview(false)}>
                    <SVG.Close />
                  </IconButton>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <QuestionnaireProvider
                  quiz={editableToLocalized(props.values as EditableQuiz)}
                  testing
                  cleanup={{
                    navigationType: 'FUNCTION',
                    function: () => setShowPreview(false),
                  }}>
                  <CurrentQuestionnaireCard />
                </QuestionnaireProvider>
              </DialogContent>
            </Dialog>
            <ConfirmCancelModal
              title={t('headsUp')}
              message={t('quiz.unpublishWarning')}
              isOpen={!!unpublishWarningOpen}
              disableCancel={props.isSubmitting}
              disableConfirm={props.isSubmitting}
              onCancel={() => setUnpublishWarningOpen(false)}
              onConfirm={async () => {
                props.setFieldValue('status', 'UNPUBLISHED');
                await props.submitForm();
                setUnpublishWarningOpen(false);
              }}
            />
          </>
        )}
      </Formik>
    </DataStateHandler>
  );
};

export default QuizDetails;
