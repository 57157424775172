import { useEffect, useRef, useState } from 'react';

const useInitializedRef = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [, forceRerender] = useState<string>();

  useEffect(() => {
    forceRerender(`doesn't matter just can't be void`);
  }, [ref]);

  return ref;
};

export default useInitializedRef;
