import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../components/common/Project/CategoryIconSmall';
import AlphaColumnFilter from '../../components/common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../components/common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../components/common/Table/types';
import RoundedFlexBox from '../../components/trader/RoundedFlexBox';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { GET_ALL_CATEGORIES } from '../../graphql/projects/projectCategories';
import { GET_COMPANY_PURCHASE_HISTORY } from '../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../types/offsetProject/types';
import { ProjectCategory } from '../../types/project/types';
import { formatDateStringUtc } from '../../utils/dateUtils';
import NumberUtils from '../../utils/numberUtils';
import {
  projectCategoryFilterLabel,
  projectCategorySearchFunction,
} from '../../utils/projectUtils';
import EmptyProjects from './EmptyProjects';
import DownloadClientCertificate from '../../components/trader/Projects/DownloadClientCertificate';
import { replaceApiUrl } from '../../apollo/client';

type PurchaseHistoryTableItem = {
  id: string;
  projectCategoryId: string;
  projectId?: string;
  title: string;
  price: number;
  totalPurchased: number;
  requestedDateOnly: string;
  completedDateOnly: string | null;
  serialNumber?: string;
  totalSpent: number;
  invoicedAmount: number;
  certificateUrl: string;
};

const PurchaseHistory: React.FC = () => {
  const { company } = useContext(AuthorizationContext);
  const {
    loading,
    error,
    data: { offsetProjectsByCompany: { items = [] } = {} } = {},
  } = useQuery(GET_COMPANY_PURCHASE_HISTORY(false), {
    variables: {
      filter: {
        status: ['COMPLETE'],
        purchaseType: ['COMPANY'],
      },
      companyId: company?.id,
    },
    skip: !company || company.id === '',
  });
  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { projectCategories = [] } = {},
  } = useQuery<{ projectCategories: ProjectCategory[] }>(GET_ALL_CATEGORIES);

  const tableStructure: GroupableTableStructure<PurchaseHistoryTableItem>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'projectCategoryId',
          display: 'Cat',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          searchFunction: projectCategorySearchFunction(projectCategories),
          resolveFilterLabel: projectCategoryFilterLabel(projectCategories),
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
        },
        {
          key: 'projectId',
          display: 'ID',
          searchable: true,
          type: AlphaColumnFilter,
        },
        {
          key: 'title',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          render: ({ title }) => (
            <Typography noWrap variant="body2" style={{ width: 250 }}>
              {title}
            </Typography>
          ),
        },
        {
          key: 'displayPrice',
          display: 'Purchase Price',
        },
        {
          key: 'totalSpent',
          display: 'Total Purchased ($)',
          resolveFilterLabel: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
          format: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
        },
        {
          key: 'displayTotalPurchased',
          display: 'Total Purchased',
        },
        {
          key: 'creditUsed',
          display: 'Credit Used',
          render: ({ totalSpent, invoicedAmount }) => (
            <Typography noWrap variant="body2">
              {NumberUtils.format(
                totalSpent - (invoicedAmount || 0),
                'currency2',
              )}
            </Typography>
          ),
        },
        {
          key: 'invoicedAmount',
          display: 'Invoiced Amount',
          resolveFilterLabel: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
          format: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
        },
        {
          key: 'requestedDateOnly',
          type: AlphaColumnFilter,
          display: 'Purchase Requested',
          searchable: true,
          searchPlaceholder: 'Search Dates',
        },
        {
          key: 'completedDateOnly',
          type: AlphaColumnFilter,
          display: 'Retirement Date',
          searchable: true,
          searchPlaceholder: 'Search Dates',
        },
        {
          key: 'serialNumber',
          type: AlphaColumnFilter,
          display: 'Serial Number',
          searchable: true,
          searchPlaceholder: 'Search SN',
        },
        {
          key: 'pdf-certificate',
          display: 'Retirement Certificate',
          style: { textAlign: 'center' },
          render: (data) =>
            data.serialNumber && data.completedDateOnly ? (
              <DownloadClientCertificate
                certificateUrl={replaceApiUrl(data.certificateUrl)}
              />
            ) : null,
        },
      ],
    },
  ];

  const tableData = items?.map((purchase: ProjectPurchaseHistory) => ({
    ...purchase,
    title: purchase.project?.title || '',
    totalPurchased: purchase.allocated + purchase.remaining,
    totalSpent: purchase.price * (purchase.allocated + purchase.remaining),
    displayPrice: NumberUtils.formatCurrencyAndUom(
      purchase.price,
      purchase.uom,
      purchase.project.uom,
    ),
    displayTotalPurchased: NumberUtils.formatQuantityAndUom(
      purchase.allocated + purchase.remaining,
      purchase.uom,
      purchase.project.uom,
    ),
    requestedDateOnly: formatDateStringUtc(
      purchase.requestedDate,
      'MM/DD/YYYY',
    ),
    completedDateOnly: purchase.completedDate
      ? formatDateStringUtc(purchase.completedDate, 'MM/DD/YYYY')
      : null,
    projectId: purchase.project?.projectId,
  }));
  return (
    <Grid item xs={12} style={{ height: 'calc(100vh - 170px)' }}>
      <DataStateHandler
        loading={loading || loadingCategories}
        error={errorCategories || error}>
        <RoundedFlexBox>
          {!tableData?.length ? (
            <EmptyProjects
              message="Make your first purchase!"
              showBrowseMarketplaceButton
            />
          ) : (
            <GroupableSelectTable<PurchaseHistoryTableItem>
              tableData={tableData || []}
              tableStructure={tableStructure}
              queryVariables={{ 'sort-list': projectCategories }}
              hideStickyCol
            />
          )}
        </RoundedFlexBox>
      </DataStateHandler>
    </Grid>
  );
};

export default PurchaseHistory;
