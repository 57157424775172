import { Box, Container } from '@mui/material';
import React, { useContext } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { SidenavContext } from '../../contexts/SidenavContext';
import Sidebar from '../common/sidebar/Sidebar';
import ImpersonationHeader from '../common/ImpersonationHeader/ImpersonationHeader';

type Props = {
  children: JSX.Element | null;
  childBoxStyle?: React.CSSProperties;
  disableContainer?: boolean;
};

const MainLayout: React.FC<Props> = ({
  children,
  childBoxStyle,
  disableContainer,
}) => {
  const { isImpersonating } = useContext(AuthorizationContext);
  const { isDrawerOpen, setIsDrawerOpen } = useContext(SidenavContext);

  const finalStyle = {
    ...childBoxStyle,
    ...(isImpersonating ? { marginTop: 40 } : {}),
  };

  return (
    <>
      <ImpersonationHeader />
      <Box
        display="grid"
        gridTemplateColumns={isDrawerOpen ? '260px auto' : '64px auto'}
        height="100%">
        <Sidebar
          open={isDrawerOpen}
          openCallback={() => setIsDrawerOpen(true)}
          closeCallback={() => setIsDrawerOpen(false)}
        />
        {disableContainer ? (
          <Box p={1} style={finalStyle}>
            {children}
          </Box>
        ) : (
          <Container>
            <Box p={1} style={finalStyle}>
              {children}
            </Box>
          </Container>
        )}
      </Box>
    </>
  );
};

export default MainLayout;
