import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { TextFieldProps } from '@mui/material';
import moment from 'moment';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import CompanyNameWithLogo from '../components/common/Company/CompanyNameWithLogo';
import CompanyClientSecretExpirationDateForm from '../components/settings/CompanyClientSecretExpirationDateForm';
import CompanyImagePositionForm from '../components/settings/CompanyImagePositionForm';
import CompanyLocationForm from '../components/settings/CompanyLocationForm';
import CompanyTypeForm from '../components/settings/CompanyTypeForm';
import ParentCompanyForm from '../components/settings/ParentCompanyForm';
import UploadCompanyImage from '../components/settings/UploadCompanyImage';
import UploadCompanyLogo from '../components/settings/UploadCompanyLogo';
import {
  generateCompanyQuery,
  GET_COMPANY_TYPES,
} from '../graphql/company/company';
import {
  UPDATE_COMPANY_PLATFORM_DATA_MUTATION,
  UPSERT_COMPANY_MUTATION,
} from '../graphql/company/companyMutations';
import { companySubsidiariesQuery } from '../graphql/company/companySubsidiaries';
import { GET_ALL_COUNTRIES } from '../graphql/settings';
import useAllCompanies from '../hooks/useAllCompanies';
import { FileAttachment, ISOCountry } from '../types/common';
import { Company } from '../types/company/types';
import ImageUtils from '../utils/imageUtils';
import { validPostalCodeTest } from '../utils/yupTests';
import { AuthorizationContext } from './AuthorizationContext';
import { WelcomeEmailForm } from '../components/settings/WelcomeEmailForm';

export const COMPANY_LOGO_BACKGROUND_COLORS = [
  {
    color: '#FFFFFF',
    name: 'White',
  },
  {
    color: '#000000',
    name: 'Black',
  },
  {
    color: '#0A3751',
    name: 'Dark Blue',
  },
];

export type EditableCompany = Company & {
  logo?: FileAttachment;
  image?: FileAttachment;
  // Needed to clean up object
  __typename?: string;
};

type MutationCompany = Omit<
  Partial<EditableCompany>,
  'id' | 'parentCompanyName' | 'features'
> & {
  features?: { featureId: string; isEnabled: boolean }[];
  companyTypeId?: string;
};

type CompanySettingsPathData = {
  section: string;
  id?: string;
};

export type SaveCompanyOptions = {
  saveNewToServer?: boolean;
  savePlatform?: boolean;
};

export type CompanySettingsContextType = {
  company: EditableCompany;
  allCompanies: Company[];
  divisions: Company[];
  loading: boolean;
  upserting: boolean;
  upsertError?: ApolloError;
  createNew: boolean;
  allCountries: ISOCountry[];
  companyTypes: { id: string; label: string }[];
  pathData?: CompanySettingsPathData;
  saveCompany: (
    newCompany: Partial<EditableCompany>,
    options?: SaveCompanyOptions,
  ) => Promise<void>;
  refetch: () => Promise<unknown>;
  isInheritingSubscriptionData: boolean;
};

const initialContext: CompanySettingsContextType = {
  company: {
    name: '',
    parentCompanyId: '',
    phoneNumber: '',
    primaryEmail: '',
    country: '',
    postalCode: '',
    address: '',
    place: '',
    region: '',
    technicalContactEmail: '',
    technicalContactName: '',
    technicalContactPhoneNumber: '',
    clientSecretExpirationDate: '',
    companyTypeId: 'company',
  } as EditableCompany,
  allCompanies: [],
  divisions: [],
  loading: false,
  upserting: false,
  createNew: false,
  allCountries: [],
  companyTypes: [],
  saveCompany: () => Promise.resolve(),
  refetch: () => Promise.resolve(),
  isInheritingSubscriptionData: false,
};

class Metadata {
  public label = '';

  public valueField?: keyof EditableCompany;

  public valueRenderer?: (company: EditableCompany) => JSX.Element | string;

  public createInitialValues?: (
    company: EditableCompany,
  ) => Partial<EditableCompany>;

  public textFieldProps?: TextFieldProps;

  public displayUnderneath?: boolean;

  public validationSchema?: unknown;

  public fullWidthSave?: boolean;

  public modalOnly?: boolean;

  public formComponent?: React.ComponentType;

  public noLabelInModal?: boolean;

  constructor(data: Readonly<Metadata>) {
    Object.assign(this, data);
  }
}

const DEFAULT_WELCOME_EMAIL_TEXT = (name: string) =>
  `You are well on your way to reducing your carbon footprint. As a team member of ${name}, you are now eligible for your free ZeroMe account.`;

const fieldMetadata = {
  name: new Metadata({
    label: 'Client Name',
    valueField: 'name',
    validationSchema: yup.object().shape({
      name: yup.string().trim().required('Required'),
    }),
  }),
  parentCompany: new Metadata({
    formComponent: ParentCompanyForm,
    label: 'Parent Organization',
    valueField: 'parentCompanyId',
    valueRenderer: (company) => (
      <CompanyNameWithLogo
        company={{
          name: company.parentCompanyName,
          logoUris: company.parentCompanyLogoUris,
        }}
      />
    ),
  }),
  logo: new Metadata({
    fullWidthSave: true,
    label: 'Client Logo',
    modalOnly: true,
    noLabelInModal: true,
    createInitialValues: ({ logo, logoBackgroundColor }) => ({
      logo,
      logoBackgroundColor:
        logoBackgroundColor || COMPANY_LOGO_BACKGROUND_COLORS[0].color,
    }),
    validationSchema: yup.object().shape({
      logoBackgroundColor: yup.string().required(),
    }),
    formComponent: UploadCompanyLogo,
  }),
  image: new Metadata({
    label: 'Client Image',
    createInitialValues: ({ image }) => ({
      image,
    }),
    formComponent: UploadCompanyImage,
    fullWidthSave: true,
    noLabelInModal: true,
  }),
  imagePosition: new Metadata({
    formComponent: CompanyImagePositionForm,
    label: 'Adjust Wide Format Cropping',
    modalOnly: true,
    createInitialValues: ({ imagePosition }) => ({
      imagePosition: imagePosition || ImageUtils.DefaultVerticalObjectPosition,
    }),
  }),
  phone: new Metadata({
    label: 'Phone Number',
    valueField: 'phoneNumber',
    validationSchema: yup.object().shape({
      phoneNumber: yup.string().required('Required'),
    }),
  }),
  email: new Metadata({
    label: 'Primary Email',
    valueField: 'primaryEmail',
    validationSchema: yup.object().shape({
      primaryEmail: yup
        .string()
        .required('Required')
        .email('Please enter a valid email address'),
    }),
  }),
  address: new Metadata({
    formComponent: CompanyLocationForm,
    label: 'Address',
    valueRenderer: ({ country, address, place, region, postalCode }) =>
      country ? `${address} ${place}, ${region} ${postalCode}` : '',
    createInitialValues: ({ country, address, place, region, postalCode }) => ({
      country,
      address,
      place,
      region,
      postalCode,
    }),
    validationSchema: yup.object().shape({
      country: yup.string().required('Required'),
      postalCode: yup.string().test(validPostalCodeTest()),
      address: yup.string().required('Required'),
      place: yup.string().required('Required'),
      region: yup.string().required('Required'),
    }),
  }),
  technicalContactName: new Metadata({
    label: 'Name',
    valueField: 'technicalContactName',
  }),
  technicalContactEmail: new Metadata({
    label: 'Email',
    valueField: 'technicalContactEmail',
    validationSchema: yup.object().shape({
      technicalContactEmail: yup
        .string()
        .email('Please enter a valid email address'),
    }),
  }),
  technicalContactPhoneNumber: new Metadata({
    label: 'Phone Number',
    valueField: 'technicalContactPhoneNumber',
  }),
  welcomeEmailAdditionalText: new Metadata({
    formComponent: () => WelcomeEmailForm(),
    label: 'Welcome Email Text',
    createInitialValues: ({
      name,
      welcomeEmailAdditionalText,
      welcomeEmailAdditionalTextEditor,
    }) => ({
      welcomeEmailAdditionalText:
        welcomeEmailAdditionalText || DEFAULT_WELCOME_EMAIL_TEXT(name),
      welcomeEmailAdditionalTextEditor,
    }),
  }),
  clientSecretExpirationDate: new Metadata({
    formComponent: CompanyClientSecretExpirationDateForm,
    label: 'Secured Key Expiration Date for Active Directory',
    valueField: 'clientSecretExpirationDate',
    valueRenderer: ({ clientSecretExpirationDate }) =>
      moment(clientSecretExpirationDate).format('M/D/YYYY'),
    validationSchema: yup.object().shape({
      clientSecretExpirationDate: yup.date(),
    }),
  }),
  companyTypeId: new Metadata({
    formComponent: CompanyTypeForm,
    label: 'Type',
    valueField: 'companyTypeId',
    validationSchema: yup.object().shape({
      companyTypeId: yup.string().trim().required('Required'),
    }),
    valueRenderer: ({ companyTypeId }) => _.upperFirst(companyTypeId),
  }),
};

const findCompanyDivisions = (
  companyId: string,
  allCompanies: Company[],
): Company[] => {
  const result = allCompanies.filter((x) => x.parentCompanyId === companyId);

  return [
    ...result,
    ...result.flatMap((x) => findCompanyDivisions(x.id, allCompanies)),
  ];
};

export type CompanySettingsFields = keyof typeof fieldMetadata;

export const CompanySettingsFieldMetadata: Record<
  CompanySettingsFields,
  Metadata
> = fieldMetadata;

export const CompanySettingsContext = createContext(initialContext);

const CompanySettingsProvider: FC = ({ children }) => {
  const { company: authCompany, userHasAccess } =
    useContext(AuthorizationContext);
  const [company, setCompany] = useState(initialContext.company);
  const location = useLocation();
  const history = useHistory();
  const isInheritingSubscriptionData =
    !!company.subscriptionData?.isParentSubscriptionData;

  const matchResult = matchPath<CompanySettingsPathData>(location.pathname, {
    path: '/settings/:section/:id?',
    exact: true,
  });

  const createNew = matchResult?.params.id === 'new';

  const {
    loading: companyLoading,
    refetch,
    data,
  } = useQuery<{
    company: EditableCompany;
  }>(generateCompanyQuery(userHasAccess), {
    variables: {
      id: matchResult?.params.id || authCompany.id,
    },
    skip: createNew,
    fetchPolicy: 'network-only',
    onCompleted: ({ company: companyData }) => {
      setCompany(companyData);
    },
  });

  useEffect(() => {
    if (data?.company && !_.isEqual(data?.company, company)) {
      setCompany(data?.company);
    }
  }, [company, data?.company]);

  const loadAllCompanies = userHasAccess('ZeroMe.Clients', 'VIEW');

  const { loading: allCompaniesLoading, allCompanies = [] } = useAllCompanies({
    skip: !loadAllCompanies,
  });

  const {
    loading: subsidiariesLoading,
    data: { companySubsidiaries = [] } = {},
  } = useQuery<{ companySubsidiaries: Company[] }>(companySubsidiariesQuery, {
    variables: { parentCompanyId: matchResult?.params.id || authCompany.id },
    skip: loadAllCompanies,
  });

  const { loading: countriesLoading, data: { allCountries = [] } = {} } =
    useQuery<{ allCountries: ISOCountry[] }>(GET_ALL_COUNTRIES);

  const { loading: companyTypesLoading, data: { companyTypes = [] } = {} } =
    useQuery<{ companyTypes: { id: string; label: string }[] }>(
      GET_COMPANY_TYPES,
    );

  const [
    upsertCompanyMutation,
    { loading: upsertingCompany, error: upsertingCompanyError },
  ] = useMutation<
    { company: Company },
    {
      company: MutationCompany;
      companyId?: string;
    }
  >(UPSERT_COMPANY_MUTATION);

  const [
    updateCompanyPlatformData,
    { loading: updatingPlatform, error: updatingPlatformError },
  ] = useMutation<
    { company: Company },
    {
      company: MutationCompany;
      companyId?: string;
    }
  >(UPDATE_COMPANY_PLATFORM_DATA_MUTATION);

  const saveCompany = useCallback(
    async (
      newCompany: Partial<EditableCompany>,
      options: {
        saveNewToServer?: boolean;
        savePlatform?: boolean;
      } = {},
    ) => {
      if (!options.saveNewToServer && createNew) {
        setCompany({
          ...company,
          ...newCompany,
        });
      } else {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        const {
          id: removeId,
          logoUris,
          imageUris,
          parentCompanyName,
          parentCompanyLogoUris,
          features,
          __typename,
          ...cleanCompany
        } = newCompany;
        /* eslint-enable @typescript-eslint/no-unused-vars */

        let result: Company | undefined;

        const upsertFn = options.savePlatform
          ? updateCompanyPlatformData
          : upsertCompanyMutation;

        try {
          result = (
            await upsertFn({
              variables: {
                company: {
                  ...cleanCompany,
                  features: features?.map((f) => ({
                    featureId: f.id,
                    isEnabled: f.isEnabled,
                  })),
                },
                companyId: createNew ? undefined : company.id,
              },
            })
          ).data?.company;
        } catch {
          return;
        }

        if (result !== undefined) {
          if (createNew) {
            history.push(`/settings/company/${result.id}`);
          } else {
            setCompany(result);
          }
        }
      }
    },
    // There are missing dependencies. Not sure if this was done intentionally,
    // so ignoring the warning for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [company],
  );

  const loading =
    companyLoading ||
    allCompaniesLoading ||
    subsidiariesLoading ||
    companyTypesLoading ||
    countriesLoading;
  const upserting = upsertingCompany || updatingPlatform;
  const upsertError = upsertingCompanyError || updatingPlatformError;

  const value = useMemo(
    () => ({
      company,
      allCompanies: loadAllCompanies ? allCompanies : companySubsidiaries,
      divisions: findCompanyDivisions(
        company?.id,
        loadAllCompanies ? allCompanies : companySubsidiaries,
      ),
      companyTypes,
      loading,
      upserting,
      upsertError,
      createNew,
      allCountries,
      saveCompany,
      refetch,
      pathData: matchResult?.params,
      isInheritingSubscriptionData,
    }),
    // There are missing dependencies. Not sure if this was done intentionally,
    // so ignoring the warning for now.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      company,
      loading,
      upserting,
      allCountries,
      location.pathname,
      refetch,
      isInheritingSubscriptionData,
    ],
  );

  return (
    <CompanySettingsContext.Provider value={value}>
      {children}
    </CompanySettingsContext.Provider>
  );
};

export default CompanySettingsProvider;
