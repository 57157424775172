import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';

type Props = {
  value?: number;
  maxValue?: number;
  maxWidth?: number;
  height?: number;
  color?: string;
  label?: string;
  showLabel?: boolean;
};

type StyleProps = {
  maxWidth?: number;
  height?: number;
  color?: string;
};

const useStyles = makeStyles((theme) => ({
  root: ({ maxWidth }: StyleProps) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
    flexWrap: 'nowrap',
    width: '100%',
    ...(maxWidth && { maxWidth }),
  }),
  bar: ({ height, color }: StyleProps) => ({
    backgroundColor: color ?? theme.palette.primary.main,
    height,
    borderRadius: 2,
    // always show a sliver, so we can tell there is a bar there
    minWidth: 1,
  }),
  percentage: {
    fontSize: 13,
    fontWeight: 700,
    color: '#6B6B6B',
    // ensure the bar can't be long enough to push the text off
    whiteSpace: 'nowrap',
  },
}));

const ProgressBar = (props: Props): React.ReactElement => {
  const {
    value = 0,
    maxValue = 100,
    maxWidth,
    height = 24,
    color,
    label = undefined,
    showLabel = true,
  } = props;

  const classes = useStyles({ color, height, maxWidth });
  const percentage = useMemo(() => value / maxValue, [value, maxValue]);

  return (
    <div className={classes.root} data-testid="progressbar.root">
      <div
        className={classes.bar}
        style={{ width: `${percentage * 100}%` }}
        data-testid="progressbar.bar"
      />
      {showLabel && (
        <div className={classes.percentage} data-testid="progressbar.label">
          {label ?? value}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
