import { Company } from '../company/types';
import {
  Project,
  ProjectCategory,
  ProjectPurchaseType,
} from '../project/types';
import { User } from '../user/types';

export enum ProjectPurchaseStatus {
  REQUESTED = 'REQUESTED',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  APPROVED = 'APPROVED',
}

export type ProjectPurchaseHistory = {
  id: string;
  projectId: Project['id'];
  projectCategoryId: ProjectCategory['id'];
  purchaseType: ProjectPurchaseType;
  userId: string;
  companyId: Company['id'];
  price: number;
  quantity: number;
  uom?: string;
  requestedDate: string;
  completedDate: string; // Populated by the user via a CSV upload or via the UI
  allocated: number;
  remaining: number;
  sysProjectId: string;
  status: ProjectPurchaseStatus;
  serialNumber?: string; // Populated by the user via a CSV upload or via the UI
  allocations?: ProjectPurchaseAllocation[];
  project: Pick<
    Project,
    'projectId' | 'title' | 'source' | 'indicator' | 'uom'
  >;
  company: Pick<Company, 'id' | 'name'>;
  user: Pick<User, 'id' | 'name' | 'profileImageUris'>;
  requestedByUser?: Pick<User, 'id' | 'name' | 'email'>;
  inventory: { projectInventoryHistoryId: string; quantity: number }[];
  updateNotes?: { notes: string; date: string }[];
  exportedToCompassDate?: string;
  approvalDate?: string;
  approvedByUser?: User;
  invoicedAmount: number;
  certificateUrl: string;
};

export enum ProjectPurchaseAllocationStatus {
  REQUESTED = 'REQUESTED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}

export type ProjectPurchaseAllocation = {
  requestId: string;
  requestedDate: string;
  status: ProjectPurchaseAllocationStatus;
  lastModifiedDate: string;
  quantity: number;
  periodStart: string;
  periodEnd: string;
  isOffPlatform?: boolean;
  notes?: boolean;
  company?: Company;
} & Pick<EmisisonsSetting, 'allocType' | 'allocSubType'>;

export type ProjectOffsetAllocation = {
  id: string;
  quantity: number;
  startDate: Date;
  endDate: Date;
} & Pick<EmisisonsSetting, 'allocType' | 'allocSubType'>;

export type ProjectOffsetAssignment = {
  project: ProjectPurchaseHistory;
  quantity: number;
};

export type EmisisonsSetting = {
  type: string;
  subType?: string;
  allocType: string;
  allocSubType: string;
  allocTypeLabel: string;
  allocSubTypeLabel: string;
};

export type ProjectPurchaseAllocationInput = {
  quantity: number;
  periodStart: string;
  periodEnd: string;
  allocType: string;
  allocSubType: string;
};

export type ProjectPurchaseHistoryInput = {
  purchaseHistoryId: ProjectPurchaseHistory['id'];
  allocations: ProjectPurchaseAllocationInput[];
};

export type EmissionsCalcResult = {
  emissionsTotals: {
    allocType: string;
    allocSubType: string;
    purposeType: string;
    emissions: number;
    type: string;
    subType: string;
    typeLabel: string;
    subTypeLabel: string;
    graphColor: string;
    typeGraphColor: string;
  }[];
  offsetTotals: {
    allocType: string;
    allocSubType: string;
    purposeType: string;
    emissions: number;
    offsetType: string;
  }[];
  behaviorTotals: {
    purposeType: string;
    emissions: number;
    type: string;
    subType: string;
  }[];
  avgAnnualEmissions: {
    avgTotalEmissions: number;
    avgTotalHabitCredits: number;
    avgFinalEmissions: number;
    avgEmissionBreakdown: AvgEmissionBreakdown[];
  };
};

export type AvgEmissionBreakdown = {
  calculationType: string;
  type: string;
  avgEmissions: number;
};
