import React, { useEffect, useState } from 'react';
import { Snackbar } from '@mui/material';
import MemberAlert, { MemberAlertContent } from './MemberAlert';

type Props = {
  open?: boolean;
  snackbarContent: MemberAlertContent;
};
const MemberSnackbar: React.FC<Props> = ({
  snackbarContent,
  open: initialOpen,
}) => {
  const [open, setOpen] = useState(initialOpen);

  useEffect(() => {
    setOpen(initialOpen);
  }, [initialOpen]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={4000}>
      <MemberAlert
        messages={snackbarContent.messages}
        onClose={() => setOpen(false)}
      />
    </Snackbar>
  );
};

export default MemberSnackbar;
