export enum BadgeId {
  ALL_HABITS_CONFIRMED = 'ALL_HABITS_CONFIRMED',
  ALL_QUESTIONS_COMPLETED = 'ALL_QUESTIONS_COMPLETED',
  ARTICLE_READ = 'ARTICLE_READ',
  ARTICLE_RATED = 'ARTICLE_RATED',
  HABIT_ADDED = 'HABIT_ADDED',
  HABIT_CONFIRMED = 'HABIT_CONFIRMED',
  ONBOARDING_QUESTIONS = 'ONBOARDING_QUESTIONS',
  QUESTIONS_COMPLETED_WORK = 'QUESTIONS_COMPLETED_WORK',
  QUESTIONS_COMPLETED_PERSONAL = 'QUESTIONS_COMPLETED_PERSONAL',
  QUESTIONS_COMPLETED_RESIDENTIAL = 'QUESTIONS_COMPLETED_RESIDENTIAL',
  REDEEM_PROJECT_CREDITS = 'REDEEM_PROJECT_CREDITS',
  POLL_COMPLETED = 'POLL_COMPLETED',
  QUIZ_COMPLETED = 'QUIZ_COMPLETED',
  LEADERBOARD_PLACEMENT_DIVISION_FIRST = 'LEADERBOARD_PLACEMENT_DIVISION_FIRST',
  LEADERBOARD_PLACEMENT_DIVISION_SECOND = 'LEADERBOARD_PLACEMENT_DIVISION_SECOND',
  LEADERBOARD_PLACEMENT_DIVISION_THIRD = 'LEADERBOARD_PLACEMENT_DIVISION_THIRD',
  LEADERBOARD_PLACEMENT_COMPANY_FIRST = 'LEADERBOARD_PLACEMENT_COMPANY_FIRST',
  LEADERBOARD_PLACEMENT_COMPANY_SECOND = 'LEADERBOARD_PLACEMENT_COMPANY_SECOND',
  LEADERBOARD_PLACEMENT_COMPANY_THIRD = 'LEADERBOARD_PLACEMENT_COMPANY_THIRD',
  CHALLENGE_PLACEMENT_FIRST = 'CHALLENGE_PLACEMENT_FIRST',
  CHALLENGE_PLACEMENT_SECOND = 'CHALLENGE_PLACEMENT_SECOND',
  CHALLENGE_PLACEMENT_THIRD = 'CHALLENGE_PLACEMENT_THIRD',
}

export type Badge = {
  badgeId: BadgeId;
  level?: number;
  earnedOn?: string;
  title?: string;
  subtitle?: string;
  description?: string;
  levelUpTitle?: string;
  levelUpSubtitle?: string;
  category?: string;
};

export type BadgeCategory = {
  id: string;
  category?: string;
  title?: string;
  subtitle?: string;
};
