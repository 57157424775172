import { useMutation, useQuery } from '@apollo/client';
import { Typography, Grid, Paper } from '@mui/material';
import { Field, Formik } from 'formik';
import React, { useContext } from 'react';
import * as yup from 'yup';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import {
  CANCEL_PURCHASE,
  UPDATE_PURCHASE,
} from '../../../graphql/projects/projectMutations';
import { generateProjectByIdQuery } from '../../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';
import AppTextField from '../../common/AppTextField';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { useStyles } from './FulfillOffsetRequestDetails';
import OffsetRequestNotes from './OffsetRequestNotes';
import OffsetRequestProjectDetails from './OffsetRequestProjectDetails';

type Props = {
  projectHistory: ProjectPurchaseHistory;
  onSave?: () => void;
  onClose: () => void;
};

const UpdateOffsetRequestDetails: React.FC<Props> = ({
  projectHistory,
  onSave,
  onClose,
}) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);

  const {
    loading,
    error,
    data: { project } = {},
  } = useQuery(generateProjectByIdQuery(userHasAccess), {
    variables: { projectId: projectHistory.projectId },
    skip: !projectHistory.projectId,
  });

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .positive()
      .required()
      .max((project?.remainingQuantity || 0) + projectHistory.quantity),
    notes: yup.string().required(),
  });

  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_PURCHASE);

  const [cancel, { loading: cancelLoading, error: cancelError }] =
    useMutation(CANCEL_PURCHASE);

  return (
    <Formik
      initialValues={{ ...projectHistory, notes: '', action: 'update' }}
      validationSchema={schema}
      enableReinitialize
      validateOnMount
      onSubmit={async ({ quantity, notes, action }) => {
        const variables =
          action === 'update'
            ? {
                projectPurchaseId: projectHistory.id,
                projectPurchase: {
                  quantity,
                },
                notes,
              }
            : {
                projectPurchaseId: projectHistory.id,
                notes,
              };

        const fn = action === 'update' ? update : cancel;

        fn({
          variables,
          onCompleted: () => {
            onClose();
            if (onSave) {
              onSave();
            }
          },
        });
      }}>
      {({ submitForm, isValid, isSubmitting, values }) => (
        <DataStateHandler
          loading={updateLoading || cancelLoading || loading}
          error={updateError || cancelError || error}>
          <Grid container className={classes.root}>
            <OffsetRequestProjectDetails
              projectHistory={projectHistory}
              onClose={onClose}
            />
            <Paper className={classes.paper}>
              <Grid item container direction="row">
                <Typography variant="h3">Update Purchase Details</Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                className={classes.element}
              />
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <Typography variant="body2">Request Type</Typography>
                <Field
                  name="action"
                  label=""
                  component={AppTextField}
                  placeholder=""
                  fullWidth
                  type="select"
                  select
                  options={[
                    {
                      label: 'Update',
                      value: 'update',
                    },
                    {
                      label: 'Cancel',
                      value: 'cancel',
                    },
                  ]}
                />
              </Grid>
              {values.action === 'update' ? (
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  className={classes.element}>
                  <Typography variant="body2">Quantity</Typography>
                  <Field
                    name="quantity"
                    label=""
                    component={AppTextField}
                    placeholder="Update Quantity"
                    fullWidth
                    type="number"
                  />
                </Grid>
              ) : null}
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <Typography variant="body2">Notes</Typography>
                <Field
                  name="notes"
                  label=""
                  component={AppTextField}
                  placeholder="Add any additional comments (required)"
                  fullWidth
                  multiline
                  rows={5}
                  type="text"
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <OffsetRequestNotes projectHistory={projectHistory} />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <SmallPaddedButton
                  disabled={!isValid || isSubmitting}
                  fullWidth
                  onClick={() => submitForm()}>
                  Save
                </SmallPaddedButton>
              </Grid>
            </Paper>
          </Grid>
        </DataStateHandler>
      )}
    </Formik>
  );
};

export default UpdateOffsetRequestDetails;
