import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Grid, Box } from '@mui/material';
import SVG2 from '../../../assets/svg-v2';
import { Project, ProjectCategory } from '../../../types/project/types';
import NumberUtils from '../../../utils/numberUtils';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  pill: {
    border: '1px solid #FFFFFF',
    borderRadius: 80,
    padding: '6px 10px',
    display: 'inline-block',
    fontWeight: 'bolder',
    position: 'absolute',
    right: 0,
    color: 'white',
  },
}));

export type ProjectDataPillDataType =
  | 'REMAINING_QUANTITY'
  | 'SELLING_PRICE'
  | 'INDICATIVE_OFFER'
  | 'LEARN_MORE'
  | 'UNSPONSORED'
  | 'UNAVAILABLE'
  | 'REDEEM';

type Props = {
  category: ProjectCategory;
  project: Pick<Project, 'remainingQuantity' | 'sellingPrice' | 'uom'>;
  style?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  dataType: ProjectDataPillDataType;
};

const ProjectDataPill: React.FC<Props> = ({
  category,
  project,
  dataType,
  style,
  iconStyle,
}) => {
  const classes = useStyles();

  let data: string;
  let icon: JSX.Element | undefined;
  let background = category.color || 'black';

  switch (dataType) {
    case 'REMAINING_QUANTITY':
      data = `${NumberUtils.format(
        project.remainingQuantity || 0,
        'integer',
      )} ${project.uom} Available`;
      break;

    case 'SELLING_PRICE':
      data = `${NumberUtils.format(project.sellingPrice || 0, 'currency')} / ${
        project.uom || ''
      }`;
      break;
    case 'INDICATIVE_OFFER':
      data = 'Indicative Offer';
      break;
    case 'LEARN_MORE':
      icon = <SVG2.Info style={iconStyle} />;
      data = 'Learn More';
      break;
    case 'UNSPONSORED':
      data = 'Unsponsored Project';
      break;
    case 'UNAVAILABLE':
      data = 'Unavailable';
      icon = <SVG2.Warning style={iconStyle} />;
      background = Colors.DisabledGrey;
      break;
    case 'REDEEM':
      icon = <SVG2.CartEmpty style={iconStyle} />;
      data = 'Redeem';
      background = Colors.CobaltBlue;
      break;
    default:
      throw new Error(`Unrecognized ProjectDataPill type: ${dataType}`);
  }

  return (
    <div
      className={classes.pill}
      style={{
        ...style,
        background,
      }}>
      <Grid container alignItems="center" justifyContent="center">
        {icon ? (
          <Box component="span" mr="4px" position="relative" top="1px">
            {icon}
          </Box>
        ) : null}

        {data}
      </Grid>
    </div>
  );
};

export default ProjectDataPill;
