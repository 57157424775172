import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import EditProjectStepModal from './EditProjectStepModal';

const ProjectDescription: React.FC = () => {
  const { project } = useContext(ProjectContext);

  return (
    <Grid
      item
      container
      spacing={2}
      direction="column"
      style={{ marginTop: 16 }}>
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Description
        </Typography>
        <EditProjectStepModal step={ProjectDetailsStep.description} />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          style={{ paddingTop: 8, paddingBottom: 16, whiteSpace: 'pre-wrap' }}>
          {project?.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectDescription;
