import questionsCompletedPersonalDisabled from './QuestionsCompletedPersonalDisabled';
import questionsCompletedPersonalEnabled from './QuestionsCompletedPersonalEnabled';

const questionsCompletedPersonal = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? questionsCompletedPersonalDisabled(level, size)
    : questionsCompletedPersonalEnabled(level, size);

export default questionsCompletedPersonal;
