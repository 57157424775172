import { Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useContext } from 'react';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../../contexts/ProjectContext';
import { Project } from '../../../../types/project/types';
import CreateProjectInput from '../CreateProjectInput';
import createProjectSchema from '../createProjectSchema';
import ResetProjectFormListener from '../ResetProjectFormListener';
import ProjectContextFieldValidator from './ProjectContextFieldValidator';

const makeInitialValues = (project: Project) => ({
  verAuditReportDate: project.verAuditReportDate || '',
  ccbSdgAuditor: project.ccbSdgAuditor || '',
  ccbSdgReportDate: project.ccbSdgReportDate || '',
});

const CreateProjectAuditInformation: React.FC = () => {
  const { project, projectCcbSdgAuditors } = useContext(ProjectContext);
  return (
    <Formik
      initialValues={makeInitialValues(project)}
      // formik requires an onSubmit handler but we don't actually
      // do any submitting here - just using formik to handle validation
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      validationSchema={createProjectSchema.auditInformationSchema}>
      {({ resetForm }) => (
        <>
          <ProjectContextFieldValidator step={ProjectDetailsStep.audit} />
          <ResetProjectFormListener
            resetForm={resetForm}
            makeInitialValues={makeInitialValues}
          />
          <Typography variant="h3">Audit Information</Typography>
          <Form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CreateProjectInput
                  label="VCC Audit Report Date"
                  name="verAuditReportDate"
                  fieldType="Date"
                />
              </Grid>
              <Grid item xs={12}>
                <CreateProjectInput
                  label="CCB/SDG Auditor"
                  name="ccbSdgAuditor"
                  fieldType="Select"
                  options={projectCcbSdgAuditors.map((x) => ({
                    value: x.id,
                    label: x.name,
                  }))}
                />
              </Grid>
              <Grid item xs={12}>
                <CreateProjectInput
                  label="CCB/SDG Report Date"
                  fieldType="Date"
                  name="ccbSdgReportDate"
                />
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
export default CreateProjectAuditInformation;
