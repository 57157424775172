import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../assets/svg';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
    borderBottom: `1px solid #DADADA`,
  },
  dataRow: {
    padding: '16px 0',
  },
  displayUnderneath: {
    paddingBottom: 24,
    whiteSpace: 'pre-wrap',
  },
}));

type Props = {
  label: React.ReactNode;
  value: React.ReactNode;
  displayUnderneath?: boolean;
  onClick?: () => void;
};

const SettingsFieldRow: React.FC<Props> = ({
  label,
  value,
  displayUnderneath,
  onClick,
  children,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid
        container
        item
        direction="row"
        justifyContent="space-between"
        onClick={onClick}
        className={classes.dataRow}
        style={onClick ? { cursor: 'pointer' } : {}}>
        <Grid item xs={4}>
          <Typography variant="body2">{label}</Typography>
        </Grid>
        <Grid item xs>
          <Typography
            variant="body2"
            component="div"
            style={{ textAlign: 'right' }}>
            {!displayUnderneath && value}
          </Typography>
        </Grid>
        <Grid item xs={1} style={{ textAlign: 'right' }}>
          {!!onClick && <SVG.CaretRightSmall />}
        </Grid>
      </Grid>
      {!!displayUnderneath && (
        <Grid item className={classes.displayUnderneath}>
          {value}
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default SettingsFieldRow;
