import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import SVG from '../../assets/svg';
import { QuestionProfile } from '../../types/question/types';

type Props = {
  onEdit: () => void;
  questionAnswer: { questionId: string; answerId: string };
  questions: QuestionProfile[];
};

const HabitDisplayQuestionAnswer: React.FC<Props> = ({
  onEdit,
  questionAnswer,
  questions,
}) => {
  const question = questions
    .flatMap((x) => x.questions)
    .find((x) => x.questionId === questionAnswer.questionId);
  const answer = question?.options?.find(
    (x) => x.answerId === questionAnswer.answerId,
  );

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={2}
      style={{ marginBottom: 16 }}
      alignItems="flex-start">
      <Grid item xs={7}>
        <Typography variant="body2">Question</Typography>
        <Typography variant="body1">
          {question?.questionText || questionAnswer.questionId}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">Answer</Typography>
        <Typography variant="body1">
          {answer?.answerText || questionAnswer.answerId}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onEdit} size="large">
          <SVG.Edit />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default HabitDisplayQuestionAnswer;
