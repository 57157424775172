import leaderboardDivisionFirstDisabled from './LeaderboardDivisionFirstDisabled';
import leaderboardDivisionFirstEnabled from './LeaderboardDivisionFirstEnabled';

const leaderboardDivisionPlacementFirst = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? leaderboardDivisionFirstDisabled(level, size)
    : leaderboardDivisionFirstEnabled(level, size);

export default leaderboardDivisionPlacementFirst;
