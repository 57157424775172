import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import SVG from '../../../../assets/svg';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../../contexts/ProjectContext';
import { Project } from '../../../../types/project/types';
import ImageUtils from '../../../../utils/imageUtils';
import ImagePosition from '../../../common/ImagePosition';
import AddPrimaryProjectImageModal from '../AddPrimaryProjectImageModal';
import CategoryIconSelect from '../CategoryIconSelect';
import CreateProjectInput from '../CreateProjectInput';
import createProjectSchema from '../createProjectSchema';
import ResetProjectFormListener from '../ResetProjectFormListener';
import ProjectContextFieldValidator from './ProjectContextFieldValidator';
import DefaultImage from '../../../common/DefaultImage';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    height: 285,
    backgroundColor: '#ccc',
    position: 'relative',
  },
  categoryContainer: {
    position: 'relative',
    bottom: 12,
  },
  closeIcon: {
    position: 'absolute',
    top: 15,
    right: 15,
    color: theme.palette.text.primary,
  },
  featuredImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
  },
}));

const makeInitialValues = (project: Project) => ({
  title: project.title || '',
  category: project.category || '',
});

const CreateProjectTitle: React.FC = () => {
  const classes = useStyles();
  const { project, setTypedProjectField } = useContext(ProjectContext);
  const [overImage, setOverImage] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);

  const image =
    project.headerImage?.publicUrl || project.headerImageUris?.large;

  return (
    <>
      <Formik
        initialValues={makeInitialValues(project)}
        // formik requires an onSubmit handler but we don't actually
        // do any submitting here - just using formik to handle validation
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onSubmit={() => {}}
        validationSchema={createProjectSchema.titleSchema}>
        {({ resetForm }) => (
          <Form noValidate>
            <ProjectContextFieldValidator step={ProjectDetailsStep.title} />
            <ResetProjectFormListener
              resetForm={resetForm}
              makeInitialValues={makeInitialValues}
            />
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.imageContainer}
                onMouseOver={() => setOverImage(true)}
                onMouseOut={() => setOverImage(false)}>
                <IconButton
                  onClick={() => setShowUploadModal(true)}
                  style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    color: '#fff',
                  }}
                  size="large">
                  <SVG.Edit />
                </IconButton>
                {image ? (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                  <img
                    alt="Project"
                    className={classes.featuredImage}
                    src={image}
                    onClick={image ? () => setShowCropModal(true) : undefined}
                  />
                ) : (
                  <DefaultImage height={285} />
                )}

                {overImage && !!image && (
                  <Typography
                    variant="h3"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      textAlign: 'center',
                      width: '100%',
                      color: '#fff',
                      textShadow: '0 0 2px #000',
                      pointerEvents: 'none',
                    }}>
                    Adjust Wide Format Cropping
                  </Typography>
                )}
                <div style={{ position: 'absolute', bottom: -54, left: 20 }}>
                  <CategoryIconSelect />
                </div>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 50 }}>
                <CreateProjectInput
                  name="title"
                  fieldType="Text"
                  placeholder="Enter Project Title"
                  titleEditor
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Dialog onClose={() => setShowUploadModal(false)} open={showUploadModal}>
        <IconButton
          className={classes.closeIcon}
          onClick={() => setShowUploadModal(false)}
          size="large">
          <SVG.Close />
        </IconButton>
        <AddPrimaryProjectImageModal
          onSave={(fd) => {
            setTypedProjectField('headerImage', {
              file: fd.file,
              publicUrl: fd.imageDataUrl,
            });
            setShowUploadModal(false);
          }}
        />
      </Dialog>
      <Dialog onClose={() => setShowCropModal(false)} open={showCropModal}>
        <IconButton
          className={classes.closeIcon}
          onClick={() => setShowCropModal(false)}
          size="large"
          style={{ top: 10 }}>
          <SVG.Close />
        </IconButton>
        <Formik
          initialValues={{
            headerImagePosition:
              project.headerImagePosition ||
              ImageUtils.DefaultVerticalObjectPosition,
          }}
          onSubmit={({ headerImagePosition }) => {
            setTypedProjectField('headerImagePosition', headerImagePosition);
            setShowCropModal(false);
          }}>
          {({ submitForm }) => (
            <Box style={{ width: 600, padding: 16 }}>
              <Grid item style={{ paddingBottom: 16 }}>
                <Typography variant="h3">
                  Adjust Wide Format Cropping
                </Typography>
              </Grid>
              <ImagePosition
                fieldName="headerImagePosition"
                imageUri={image || ''}
              />
              <Button
                fullWidth
                variant="contained"
                size="medium"
                color="primary"
                onClick={submitForm}
                style={{ marginTop: 16 }}>
                Save
              </Button>
            </Box>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default CreateProjectTitle;
