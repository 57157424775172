import React, { useState } from 'react';
import {
  Collapse,
  Grid,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import moment from 'moment';

import { Colors } from '../../../theme/shared';
import SVG from '../../../assets/svg';

const formatTitle = (title: string) => {
  const date = moment(title);
  return date.isValid() ? date.format('MMMM') : title;
};

const useStyles = makeStyles(() => ({
  category: {
    textTransform: 'capitalize',
  },
  section: {
    marginLeft: 16,
    width: '100%',
  },
}));

type Item = {
  title: string;
  totalPoints: number;
  subItems?: Item[];
};

type Props = {
  item: Item;
};

const LeaderboardCategoryPointsItem = (props: Props) => {
  const {
    item: { title: tempTitle, totalPoints, subItems = [] },
  } = props;
  const title = formatTitle(tempTitle);

  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Grid container key={title}>
      <ListItemButton onClick={() => setExpanded(!expanded)}>
        <Grid container className={classes.category} alignItems="center">
          <Typography variant="h4">{title.toLowerCase()}</Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Typography variant="h4" color={Colors.CobaltBlue}>
            {totalPoints} {t('points')}
          </Typography>
          {expanded ? <SVG.CaretUpSmall /> : <SVG.CaretDownSmall />}
        </Grid>
      </ListItemButton>
      <Grid container item>
        <Collapse className={classes.section} in={expanded}>
          {subItems.map((pbm) =>
            pbm.subItems ?? [].length > 0 ? (
              <LeaderboardCategoryPointsItem key={pbm.title} item={pbm} />
            ) : (
              <ListItem key={pbm.title}>
                <Grid container item justifyContent="space-between">
                  <Grid item>
                    <Typography variant="body2">
                      {formatTitle(pbm.title)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {pbm.totalPoints} {t('points')}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ),
          )}
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default LeaderboardCategoryPointsItem;
