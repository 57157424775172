import { Checkbox, Dialog, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { FieldArray, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { CompanyFeature } from '../../types/company/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import SettingsFieldRow from './SettingsFieldRow';
import SVG from '../../assets/svg';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { GET_PLATFORM_FEATURES } from '../../graphql/company/company';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    minWidth: 600,
  },
  row: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingTop: 8,
    paddingBottom: 8,
  },
  buttons: {
    paddingTop: 16,
    marginLeft: 'auto',
    width: 125,
  },
}));

type Props = {
  features?: CompanyFeature[];
};

const PlatformFeatures: React.FC<Props> = ({
  features: companyFeatures = [],
}) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const { saveCompany } = useContext(CompanySettingsContext);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const {
    data: { platformFeatures = [] } = {},
    loading,
    error,
  } = useQuery(GET_PLATFORM_FEATURES);

  const features = platformFeatures.map((p) => ({
    ...p,
    isEnabled:
      companyFeatures?.find((cf) => cf.id === p.id)?.isEnabled ?? false,
  }));
  return (
    <DataStateHandler loading={loading} error={error}>
      <>
        <Grid container direction="column">
          {features.map((feature) => (
            <SettingsFieldRow
              key={feature.id}
              onClick={
                userHasAccess('Configuration.Company', 'EDIT')
                  ? () => setIsOpen(true)
                  : undefined
              }
              label={feature.name}
              value={feature.isEnabled ? 'Enabled' : 'Disabled'}
            />
          ))}
        </Grid>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <Formik
            initialValues={{ features }}
            validateOnMount={isOpen}
            onSubmit={(values) => {
              saveCompany(values);
              setIsOpen(false);
            }}>
            {({ values, submitForm, setFieldValue }) => (
              <Grid container direction="column" className={classes.root}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h3">Platform Features</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => setIsOpen(false)} size="large">
                      <SVG.Close />
                    </IconButton>
                  </Grid>
                </Grid>
                <FieldArray
                  name="tips"
                  render={() => (
                    <Grid item>
                      {values.features.map((f, index) => (
                        <Grid
                          className={classes.row}
                          key={f.id}
                          container
                          alignItems="center"
                          justifyContent="space-between">
                          <Grid item>
                            <Typography variant="body2">{f.name}</Typography>
                            <Typography variant="caption">
                              {f.description}
                            </Typography>
                          </Grid>

                          <Checkbox
                            checked={f.isEnabled}
                            onClick={() =>
                              setFieldValue(
                                `features[${index}].isEnabled`,
                                !f.isEnabled,
                              )
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                />
                <Grid item className={classes.buttons}>
                  <SmallPaddedButton
                    fullWidth
                    variant="contained"
                    onClick={submitForm}>
                    Save
                  </SmallPaddedButton>
                </Grid>
              </Grid>
            )}
          </Formik>
        </Dialog>
      </>
    </DataStateHandler>
  );
};

export default PlatformFeatures;
