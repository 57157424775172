import articleRatedDisabled from './ArticleRatedDisabled';
import articleRatedEnabled from './ArticleRatedEnabled';

const articleRated = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? articleRatedDisabled(level, size)
    : articleRatedEnabled(level, size);

export default articleRated;
