import { Grid, PopperProps, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import React, { ComponentProps } from 'react';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.error.light,
    fontSize: '1.5rem',
  },
  tooltip: {
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    maxWidth: 'none',
  },
  text: {
    color: theme.palette.error.dark,
  },
}));
type Props = {
  /** The error message. An empy title will hide the tooltip regardless of open state */
  title?: string;
  /** Hides the error icon */
  hideIcon?: true;
  children: JSX.Element;
  popperProps?: Partial<PopperProps>;
} & Pick<ComponentProps<typeof Tooltip>, 'open' | 'placement'>;

/**
 * Displays a persistent tooltip formated as an error.
 */
const TooltipError = ({
  children,
  open,
  placement,
  title,
  hideIcon,
  popperProps = {
    disablePortal: true,
  },
}: Props) => {
  const { text, ...classes } = useStyles();
  return (
    <Tooltip
      title={
        (title && (
          <Grid
            alignItems="center"
            container
            direction="row"
            wrap="nowrap"
            spacing={1}>
            {(!hideIcon && (
              <Grid item>
                <WarningRoundedIcon className={text} />
              </Grid>
            )) ||
              null}
            <Grid item>
              <Typography className={text}>{title}</Typography>
            </Grid>
          </Grid>
        )) ||
        ''
      }
      open={open}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
      classes={classes}
      placement={placement}
      PopperProps={popperProps}>
      {children}
    </Tooltip>
  );
};

export default TooltipError;
