import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
    borderRadius: 16,
    backgroundColor: '#f1f8fd',
  },
  container: {
    margin: 24,
  },
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  dismissButton: {
    color: Colors.CobaltBlue,
  },
  startButton: {
    height: 64,
    marginTop: 16,
  },
}));

type Props = {
  title: string;
  onClick: () => void;
  onDismiss: () => void;
};

const PollCard: React.FC<Props> = ({ title, onClick, onDismiss }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid container item className={classes.container} direction="column">
        <Grid container item className={classes.header} direction="row">
          <Grid item>
            <Typography variant="h3">{t('poll.newPoll')}</Typography>
          </Grid>
          <Grid item>
            <Button onClick={onDismiss}>{t('dismiss')}</Button>
          </Grid>
        </Grid>
        <Typography variant="h2">{title}</Typography>
        <Button
          className={classes.startButton}
          variant="contained"
          onClick={onClick}>
          {t('poll.startPoll')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PollCard;
