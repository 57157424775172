import React from 'react';
import { useQuery } from '@apollo/client';

import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CurrentQuestionnaireCard from '../../common/Questionnaire/CurrentQuestionnaireCard';
import { QuestionnaireProvider } from '../../../contexts/QuestionnaireContext';
import { GET_MEMBER_QUIZ } from '../../../graphql/communications/memberCommunications';

type Props = {
  quizId: string;
};

const Quiz: React.FC<Props> = ({ quizId }) => {
  const {
    loading,
    error,
    data: { memberQuiz } = {},
  } = useQuery(GET_MEMBER_QUIZ, {
    variables: {
      quizId,
    },
  });

  return (
    <DataStateHandler loading={loading} error={error}>
      <QuestionnaireProvider
        quiz={memberQuiz}
        cleanup={{ navigationType: 'GO_BACK' }}>
        <CurrentQuestionnaireCard />
      </QuestionnaireProvider>
    </DataStateHandler>
  );
};

export default Quiz;
