import React from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_BUDGET_STATS } from '../../../graphql/trader/dashboard';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import AppCard from '../../common/AppCard/AppCard';
import PieChart from '../../common/PieChart/PieChart';
import AppInfo from '../../common/AppInfo';
import NumberUtils from '../../../utils/numberUtils';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  legendDot: {
    width: 8,
    height: 8,
    borderRadius: 8,
    marginRight: 4,
    display: 'inline-block',
  },
}));

type Props = {
  budgetDate?: string;
};

const ClientAnnualBudgetCard: React.FC<Props> = ({ budgetDate }) => {
  const classes = useStyles();

  const {
    data: { clientBudgetStats } = {},
    loading,
    error,
  } = useQuery(GET_CLIENT_BUDGET_STATS, {
    fetchPolicy: 'network-only',
    variables: {
      budgetDate,
    },
  });

  return (
    <AppCard height={350}>
      <DataStateHandler loading={loading} error={error}>
        <>
          <Grid item container direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              style={{ fontSize: 12, marginBottom: 24, color: '#2A2A2A' }}>
              CLIENT ANNUAL BUDGET
            </Typography>
            <AppInfo id="clientAnnualBudget" />
          </Grid>
          <div style={{ margin: '0 auto' }}>
            <PieChart
              chartSize={175}
              margin={{}}
              borderWidth={0}
              tooltipFormat="currency"
              donutLabel={{
                title: new Intl.NumberFormat('en-us', {
                  maximumFractionDigits: 0,
                  currency: 'USD',
                  style: 'currency',
                  notation: 'compact',
                }).format(clientBudgetStats?.totalBudget || 0),
                subtitle: 'TOTAL BUDGET',
                radius: 0.75,
                titleStyle: {
                  paddingTop: 5,
                  color: Colors.CobaltBlue,
                  fontWeight: 700,
                  fontSize: 24,
                },
                subtitleStyle: {
                  color: '#6B6B6B',
                  fontSize: 12,
                  fontWeight: 500,
                  marginTop: 0,
                },
              }}
              data={[
                {
                  id: 'spent',
                  label: 'Spent',
                  value: clientBudgetStats?.totalSpend || 0,
                  color: Colors.CobaltBlue,
                },
                {
                  id: 'remaining',
                  label: 'Remaining',
                  value:
                    (clientBudgetStats?.totalBudget || 0) -
                    (clientBudgetStats?.totalSpend || 0),
                  color: '#EFEFEF',
                },
              ]}
            />
          </div>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 24, marginBottom: 16 }}>
            <Grid item>
              <div
                className={classes.legendDot}
                style={{
                  backgroundColor: Colors.CobaltBlue,
                }}
              />
              <Typography variant="body2" style={{ display: 'inline-block' }}>
                Spent
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {NumberUtils.format(clientBudgetStats?.totalSpend, 'currency')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid item>
              <div
                className={classes.legendDot}
                style={{
                  backgroundColor: '#EFEFEF',
                }}
              />
              <Typography variant="body2" style={{ display: 'inline-block' }}>
                Remaining
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4">
                {NumberUtils.format(
                  (clientBudgetStats?.totalBudget || 0) -
                    (clientBudgetStats?.totalSpend || 0),
                  'currency',
                )}
              </Typography>
            </Grid>
          </Grid>
        </>
      </DataStateHandler>
    </AppCard>
  );
};

export default ClientAnnualBudgetCard;
