import moment from 'moment';

export const formatDateStringUtc = (date: string, dateFormat: string): string =>
  moment.utc(date).format(dateFormat);

export const fancyMoment = (date: string, fallbackFormat?: string) =>
  moment(date).calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    // "Last" is just confusing here - if it's wednesday, two days ago will be "Last Monday"
    // lastWeek: '[Last] dddd',
    lastWeek: fallbackFormat || 'MM/DD/YYYY',
    sameElse: fallbackFormat || 'MM/DD/YYYY',
  });

export const dateRangeFromEndDate = (endDate: Date, numMonths = 6) => ({
  startDate: moment(endDate)
    .utc()
    .subtract(numMonths - 1, 'months')
    .startOf('month')
    .toISOString(),
  endDate: moment(endDate).utc().endOf('month').toISOString(),
});

export const dateRange = (numMonths = 6, startMonthsBack = 0) => ({
  startDate: moment()
    .utc()
    .subtract(numMonths + startMonthsBack - 1, 'months')
    .startOf('month')
    .toISOString(),
  endDate: moment()
    .utc()
    .subtract(startMonthsBack, 'months')
    .endOf('month')
    .toISOString(),
});

export const yyyyMMRange = (numMonths?: number, startMonthsBack?: number) => {
  const dr = dateRange(numMonths, startMonthsBack);
  return {
    startYYYYMM: parseInt(moment(dr.startDate).format('YYYYMM'), 10),
    endYYYYMM: parseInt(moment(dr.endDate).format('YYYYMM'), 10),
  };
};

export default {
  formatDateStringUtc,
};
