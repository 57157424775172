import { Box, Typography, Link } from '@mui/material';
import React from 'react';

const Error = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    height="100%">
    <Typography align="center" variant="h4">
      We&apos;ve encountered an error.
    </Typography>
    <Link href="/" underline="hover">
      <Typography align="center" variant="body2">
        Click here to return to your dashboard
      </Typography>
    </Link>
  </Box>
);

export default Error;
