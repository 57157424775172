import React, { useContext, useState } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import moment, { Moment } from 'moment';

import { CommunicationInput } from '../types';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import { COMMUNICATIONS_QUERY } from '../../../graphql/communications/communications';
import { ALL_HABITS } from '../../../graphql/habit/habits';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { Disableable } from '../../../types/common';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
  },
  chip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 5,
  },
  menuItem: {
    fontSize: 18,
  },
}));

type OptionItem = {
  value: string;
  label: string;
};

type Props = Disableable & {
  options: OptionItem[];
  field: keyof CommunicationInput;
};

const menuProps = {
  PaperProps: {
    style: {
      maxHeight: '600px',
      maxWidth: '1000px',
      overflow: 'auto',
    },
  },
};

const RelatedContentSelectInput: React.FC<Props> = ({
  options,
  field,
  disabled,
}) => {
  const classes = useStyles();
  const { setTypedFieldValue, values } =
    useTypedFormikContext<CommunicationInput>();

  const selectedValues: string[] = (values[field] || []) as string[];

  const handleSelection = (event: SelectChangeEvent<string[]>) => {
    const selected = event.target.value;

    if (selected?.length <= 4) {
      setTypedFieldValue(field, selected);
    }
  };

  return (
    <Select
      className={classes.select}
      disabled={disabled}
      multiple
      value={selectedValues}
      renderValue={(selected) => (
        <Box className={classes.chip}>
          {selected.map((value) => (
            <Chip
              key={value}
              label={options.find((d) => d.value === value)?.label}
            />
          ))}
        </Box>
      )}
      onChange={handleSelection}
      input={<OutlinedInput />}
      MenuProps={menuProps}>
      {options.map(({ value, label }) => {
        const selected = selectedValues.includes(value);

        return (
          <MenuItem
            key={value}
            value={value}
            className={classes.menuItem}
            disabled={selectedValues.length >= 4 && !selected}>
            <Checkbox checked={selected} />
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

const RelatedArticlesAndHabits: React.FC<Disableable> = ({ disabled }) => {
  const { communications, companyId } = useContext(CommunicationsContext);
  const { values } = useTypedFormikContext<CommunicationInput>();
  // Using state to avoid an endless query loop, since current time changes on each render
  const [now] = useState<Moment>(moment());

  // At the company level, we don't store global communications in context so get them here
  const {
    loading: articlesLoading,
    error: articlesError,
    data: { communications: { items: globalArticles = [] } = {} } = {},
  } = useQuery(COMMUNICATIONS_QUERY, {
    variables: {
      includeGlobalCommunications: true,
      isPublished: true,
    },
    skip: !companyId,
  });

  const {
    loading: habitsLoading,
    error: habitsError,
    data: { habits = [] } = {},
  } = useQuery(ALL_HABITS, {
    variables: {
      includeDraft: false,
      publishDate: now,
    },
  });

  const articleOptions: OptionItem[] = [...communications, ...globalArticles]
    .filter(
      (a) => a.id !== values.id && moment(a.publishDate).isBefore(moment()),
    )
    .map((a) => ({
      value: a.id,
      label: a.title,
    }));
  const habitOptions: OptionItem[] = habits.map((h) => ({
    value: h.id,
    label: h.name,
  }));

  return (
    <DataStateHandler
      loading={articlesLoading || habitsLoading}
      error={articlesError || habitsError}>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body2">
            {t('communication.relatedArticlesLimited')}
          </Typography>
          <RelatedContentSelectInput
            options={articleOptions}
            field="relatedArticleIds"
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {t('communication.relatedHabitsLimited')}
          </Typography>
          <RelatedContentSelectInput
            options={habitOptions}
            field="relatedHabitIds"
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default RelatedArticlesAndHabits;
