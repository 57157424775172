import redeemProjectCreditsDisabled from './RedeemProjectCreditsDisabled';
import redeemProjectCreditsEnabled from './RedeemProjectCreditsEnabled';

const redeemProjectCredits = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? redeemProjectCreditsDisabled(level, size)
    : redeemProjectCreditsEnabled(level, size);

export default redeemProjectCredits;
