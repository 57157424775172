import { useMutation, useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import { omit } from 'lodash';
import React, { useContext, useState } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import {
  GET_COMMUNICATION_SETTINGS,
  UPSERT_COMMUNICATION_SETTINGS,
} from '../../graphql/communications/communications';
import AppTextField from '../common/AppTextField';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import PageTitle from '../common/PageTitle';
import SettingsModalInput from '../settings/SettingsModalInput';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import { CommunicationSettings } from './types';

const useStyles = makeStyles(() => ({
  table: {
    marginTop: 16,
  },
}));

const CommunicationsSettings: React.FC = () => {
  const classes = useStyles();
  const [settings, setSettings] = useState<CommunicationSettings>({});
  const { userHasAccess } = useContext(AuthorizationContext);
  const { companyId, communications } = useContext(CommunicationsContext);

  const { loading, error } = useQuery(GET_COMMUNICATION_SETTINGS, {
    variables: {
      companyId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      setSettings(omit(data.getCommunicationSettings, '__typename')),
  });

  const [upsert, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPSERT_COMMUNICATION_SETTINGS,
  );

  const saveSettings = (newSettings: CommunicationSettings) => {
    const finalSettings = {
      ...settings,
      ...newSettings,
    };

    setSettings(finalSettings);
    upsert({
      variables: {
        settings: finalSettings,
        companyId,
      },
    });
  };

  const canEdit = companyId
    ? userHasAccess('Client.Communication.Settings', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Settings', 'EDIT');

  const getCommunicationTitle = (id?: string) =>
    communications.find((x) => x.id === id)?.title || 'Not Set';

  return (
    <Box px={8} py={4}>
      <Grid container direction="column">
        <Grid item>
          <PageTitle title="Settings" />
        </Grid>
        <DataStateHandler
          loading={loading || upsertLoading}
          error={error || upsertError}>
          <Grid item className={classes.table}>
            <Grid
              container
              direction="column"
              style={{ height: 'calc(100vh - 110px)' }}>
              <RoundedFlexBox>
                <Grid style={{ padding: 8 }}>
                  <SettingsModalInput<CommunicationSettings>
                    label="Welcome Video"
                    modalLabel="Select Welcome Video"
                    initialValues={{
                      welcomeVideoId: settings.welcomeVideoId || '',
                    }}
                    displayValue={getCommunicationTitle(
                      settings.welcomeVideoId,
                    )}
                    disabled={!canEdit}
                    onSave={saveSettings}
                    formNode={
                      <Grid item>
                        <Field
                          name="welcomeVideoId"
                          component={AppTextField}
                          fullWidth
                          autoFocus
                          select
                          options={[
                            { value: '', label: 'Not Set' },
                            ...communications
                              .filter((x) => x.communicationType === 'VIDEO')
                              .map((x) => ({
                                value: x.id,
                                label: x.title,
                              })),
                          ]}
                          SelectProps={{
                            displayEmpty: true,
                            renderValue: (e: string) =>
                              getCommunicationTitle(e),
                          }}
                        />
                      </Grid>
                    }
                  />
                </Grid>
              </RoundedFlexBox>
            </Grid>
          </Grid>
        </DataStateHandler>
      </Grid>
    </Box>
  );
};

export default CommunicationsSettings;
