import { TypedDocumentNode, gql } from '@apollo/client';
import { User } from '../../types/user/types';
import { QuestionAnswer, Tip } from '../../types/question/types';

export const GET_USER_EMISSION_SOURCES = gql`
  query GET_USER_EMISSION_SOURCES {
    me {
      id
      userEmissionSources {
        profileId
        answeredQuestions {
          questionId
          answerIds
          readings {
            key
            calcInput
            calcInputUnit
          }
          estimateSchedule {
            period
            generateOn
          }
        }
        lastModified
      }
    }
  }
` as TypedDocumentNode<{
  me: Pick<User, 'id' | 'userEmissionSources'>;
}>;

export const UPDATE_USER_EMISSION_SOURCES = gql`
  mutation UPDATE_USER_EMISSION_SOURCES(
    $categoryId: String!
    $answers: [AnswerAndProfile]
  ) {
    updateProfileAnswers(categoryId: $categoryId, answers: $answers) {
      id
      text
      entityType
      categoryId
      profileId
      questionId
    }
  }
` as TypedDocumentNode<
  Tip[],
  {
    categoryId: string;
    answers: {
      answer: QuestionAnswer | null;
      profileId: string;
    }[];
  }
>;
