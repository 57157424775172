import React from 'react';
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ActivityCount } from './ActivityCount';
import SVG from '../../../assets/svg-v2';
import { GET_UNREAD_USER_ACTIVITY_IDS } from '../../../graphql/activities/activities';
import { Colors } from '../../../theme/shared';

export default function ActivityIcon({ textual }: { textual?: boolean }) {
  const { data } = useQuery(GET_UNREAD_USER_ACTIVITY_IDS);

  const count = data?.me?.activities.items?.length || 0;

  return textual ? (
    <Typography variant="h3">
      Activity{' '}
      {count > 0 && (
        <Box
          style={{
            backgroundColor: Colors.CobaltBlue,
            width: 10,
            height: 10,
            borderRadius: 5,
            marginBottom: 2,
            display: 'inline-block',
          }}
        />
      )}
    </Typography>
  ) : (
    <Box style={{ position: 'relative', top: 4 }}>
      <SVG.Bell />
      <Box style={{ position: 'absolute', top: -4, right: -8 }}>
        <ActivityCount count={count} />
      </Box>
    </Box>
  );
}
