import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import {
  Grid,
  Dialog,
  Typography,
  IconButton,
  TextFieldProps,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Form, Field } from 'formik';
import SVG from '../../assets/svg';
import AppTextField from '../common/AppTextField';
import SettingsFieldRow from './SettingsFieldRow';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { Disableable } from '../../types/common';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
    padding: 16,
    paddingTop: 24,
    position: 'relative',
  },
  closeIcon: {
    position: 'relative',
    top: -5,
    color: theme.palette.text.primary,
  },
  content: {
    paddingBottom: 10,
  },
}));

type Props<T> = Disableable & {
  label: string;
  modalLabel?: string;
  modalContent?: string;
  displayValue: React.ReactNode;
  initialValues: Partial<T>;
  textFieldProps?: TextFieldProps;
  displayUnderneath?: boolean;
  validationSchema?: unknown;
  fullWidthSave?: boolean;
  modalOnly?: boolean;
  formComponent?: React.ComponentType;
  formNode?: React.ReactNode;
  noLabelInModal?: boolean;
  children?: React.ReactNode;
  onSave: (data: T) => void;
};

export type SettingsModalInputRef = {
  setModalOpen: (open: boolean) => void;
};

function SettingsModalInputInner<T>(
  {
    label,
    modalLabel,
    modalContent,
    displayValue,
    initialValues,
    textFieldProps,
    displayUnderneath,
    validationSchema,
    fullWidthSave,
    modalOnly,
    formComponent: FormComponent,
    formNode,
    noLabelInModal,
    disabled,
    onSave,
    children,
  }: Props<T>,
  ref: Ref<SettingsModalInputRef>,
) {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    setModalOpen,
  }));

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={modalOpen}
      onSubmit={(values) => {
        setModalOpen(false);
        onSave(values as T);
      }}
      validationSchema={validationSchema}>
      {({ isValid }) => (
        <>
          {!modalOnly && (
            <SettingsFieldRow
              label={label}
              value={displayValue}
              displayUnderneath={displayUnderneath}
              onClick={disabled ? undefined : () => setModalOpen(true)}>
              {children}
            </SettingsFieldRow>
          )}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          {modalOnly && (
            <Grid
              container
              direction="column"
              onClick={disabled ? undefined : () => setModalOpen(true)}>
              {children}
            </Grid>
          )}
          <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
            <Form noValidate>
              <Grid container direction="column" className={classes.dialog}>
                {!noLabelInModal && (
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    style={{ marginBottom: 8 }}>
                    <Grid item>
                      <Typography variant="h3">
                        {modalLabel || label}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        className={classes.closeIcon}
                        onClick={() => setModalOpen(false)}
                        size="large">
                        <SVG.Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
                {noLabelInModal && (
                  <IconButton
                    className={classes.closeIcon}
                    onClick={() => setModalOpen(false)}
                    style={{ position: 'absolute', top: 5, right: 5 }}
                    size="large">
                    <SVG.Close />
                  </IconButton>
                )}
                {modalContent && (
                  <Grid item className="content">
                    <Typography variant="body2">{modalContent}</Typography>
                  </Grid>
                )}
                {!!FormComponent && <FormComponent />}
                {!!formNode && formNode}
                {!FormComponent && !formNode && (
                  <Grid item>
                    <Field
                      name={Object.keys(initialValues)[0]}
                      component={AppTextField}
                      fullWidth
                      autoFocus
                      {...(textFieldProps || {})}
                    />
                  </Grid>
                )}
                <Grid item container direction="row" justifyContent="flex-end">
                  <SmallPaddedButton
                    type="submit"
                    fullWidth={fullWidthSave}
                    disabled={!isValid}
                    wide
                    style={{ marginTop: 16 }}>
                    Save
                  </SmallPaddedButton>
                </Grid>
              </Grid>
            </Form>
          </Dialog>
        </>
      )}
    </Formik>
  );
}

const SettingsModalInput = forwardRef(SettingsModalInputInner);

export default SettingsModalInput as <T>(
  props: Props<T> & { ref?: Ref<SettingsModalInputRef> },
) => ReturnType<typeof SettingsModalInput>;
