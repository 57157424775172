import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router';
import AuthorizedRoute from '../../components/common/AuthorizedRoute';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import MainLayout from '../../components/layouts/MainLayout';
import PendingOrders from '../../components/trader/Projects/PendingOrders';
import ProjectDrafts from '../../components/trader/Projects/ProjectDrafts';
import ProjectsHeader from '../../components/trader/Projects/ProjectsHeader';
import ProjectsList from '../../components/trader/Projects/ProjectsList';
import PurchaseHistory from '../../components/trader/Projects/PurchaseHistory';
import useShowDemoProjects from '../../components/trader/Projects/useShowDemoProjects';
import {
  GET_MARKETPLACE_PURCHASEHIST,
  SEARCH_PROJECTS,
} from '../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../types/offsetProject/types';
import { Project } from '../../types/project/types';

const TraderProjects: React.FC = () => {
  const { path } = useRouteMatch();
  const showDemoProjects = useShowDemoProjects();

  const {
    loading: draftsLoading,
    error: draftsError,
    data: { searchProjects: { items: draftProjects = [] } = {} } = {},
    refetch,
  } = useQuery<{
    searchProjects: { items: Project[]; continuationToken: string };
  }>(SEARCH_PROJECTS, {
    variables: {
      pageSize: 1000,
      filter: {
        draft: ['true'],
        isDemoProject: [showDemoProjects ? 'true' : 'false'],
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    loading: pendingProjectsLoading,
    error: pendingProjectsError,
    refetch: refetchOffsetProjects,
    data: { offsetProjects: pendingProjects = [] } = {},
  } = useQuery<{ offsetProjects: ProjectPurchaseHistory[] }>(
    GET_MARKETPLACE_PURCHASEHIST(),
    {
      variables: {
        filter: {
          status: ['REQUESTED', 'APPROVED'],
          isDemoProject: [showDemoProjects ? 'true' : 'false'],
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  return (
    <MainLayout childBoxStyle={{ height: '100%' }} disableContainer>
      <Box px={8} height="100%">
        <Grid container direction="column" style={{ height: '100%' }}>
          <DataStateHandler
            loading={draftsLoading || pendingProjectsLoading}
            error={draftsError || pendingProjectsError}>
            <>
              <ProjectsHeader
                draftProjectCount={draftProjects.length}
                pendingProjectCount={pendingProjects.length}
              />
              <Switch>
                <AuthorizedRoute
                  ability="ZeroMe.Marketplace"
                  path={`${path}/list`}>
                  <ProjectsList />
                </AuthorizedRoute>
                <AuthorizedRoute
                  ability="ZeroMe.Marketplace"
                  path={`${path}/pending`}>
                  <PendingOrders
                    projects={pendingProjects}
                    refetch={refetchOffsetProjects}
                  />
                </AuthorizedRoute>
                <AuthorizedRoute
                  ability="ZeroMe.Marketplace"
                  path={`${path}/history`}>
                  <PurchaseHistory />
                </AuthorizedRoute>
                <AuthorizedRoute
                  ability="ZeroMe.Marketplace"
                  path={`${path}/drafts/`}>
                  <ProjectDrafts projects={draftProjects} refetch={refetch} />
                </AuthorizedRoute>
                <Redirect to={`${path}/list`} />
              </Switch>
            </>
          </DataStateHandler>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default TraderProjects;
