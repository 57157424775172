import React, { useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import useMapboxHighlightCountry from '../../../hooks/useMapboxHighlightCountry';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import EditProjectStepModal from './EditProjectStepModal';

const ProjectLocation: React.FC = () => {
  const { project } = useContext(ProjectContext);
  const mapNode = useMapboxHighlightCountry(project);

  return (
    <Grid
      item
      container
      spacing={2}
      direction="column"
      style={{ marginTop: 40 }}>
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Location
        </Typography>
        <EditProjectStepModal step={ProjectDetailsStep.location} />
      </Grid>
      <Grid item>
        <div
          ref={mapNode}
          style={{ width: '100%', height: 270, borderRadius: 8 }}
        />
      </Grid>
    </Grid>
  );
};

export default ProjectLocation;
