import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { Trans } from 'react-i18next';

import SvgV2 from '../../../assets/svg-v2';
import HabitCategoryChip from '../../../components/common/Habit/HabitCategoryChip';
import Loading from '../../../components/common/Loading';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberHabitBanner from '../../../components/member/myhabits/MemberHabitBanner';
import {
  CONFIRM_HABITS,
  MEMBER_LIFESTYLE_HABITS,
} from '../../../graphql/habit/memberHabits';
import useRemoveQuestionRelatedData from '../../../hooks/cacheModifiers/useRemoveQuestionRelatedData';
import useCheckForPointAwards from '../../../hooks/useCheckForPointAwards';
import { MemberHabit } from '../../../types/habit/types';

const ConfirmHabitsPage: React.FC = () => {
  const history = useHistory();
  const [habitIndex, setHabitIndex] = useState(0);
  const [habits, setHabits] = useState<MemberHabit[]>();
  const [state, setState] = useState<'START' | 'HABITS' | 'CONFIRMING' | 'END'>(
    'START',
  );
  const [pointsAdded, setPointsAdded] = useState(0);
  const [habitsToRemove, setHabitsToRemove] = useState<string[]>([]);
  const { remove } = useRemoveQuestionRelatedData();
  const { checkForPointAwards } = useCheckForPointAwards();

  const { data: { me: { lifestyleHabits = null } = {} } = {} } = useQuery(
    MEMBER_LIFESTYLE_HABITS,
  );
  const [removeHabit] = useMutation(CONFIRM_HABITS);

  const nextHabit = async () => {
    if (habitIndex < (habits?.length ?? 0) - 1) {
      setHabitIndex(habitIndex + 1);
    } else {
      setState('CONFIRMING');

      await removeHabit({
        variables: {
          toRemove: habitsToRemove,
        },
      });

      await remove();
      const { points } = await checkForPointAwards(true);
      setPointsAdded(points);
      setState('END');
    }
  };

  useEffect(() => {
    if (lifestyleHabits && !habits) {
      setHabits(lifestyleHabits);
    }
  }, [lifestyleHabits, habits]);

  if (!habits) {
    return <Loading />;
  }

  const habit = habits[habitIndex];

  return (
    <MemberLayout>
      <>
        {state === 'START' && (
          <Grid container direction="column" alignItems="center">
            <SvgV2.Star />
            <Typography variant="h2" style={{ marginTop: 8, marginBottom: 24 }}>
              {t('habit.confirmMyHabits')}
            </Typography>
            <Typography variant="body2">
              {t('habit.confirmMyHabitsDescription')}
            </Typography>
            <Button
              variant="contained"
              size="small"
              style={{ width: 400, marginTop: 24 }}
              onClick={() => setState('HABITS')}>
              {t('continue')}
            </Button>
          </Grid>
        )}
        {state === 'HABITS' && (
          <Grid
            container
            spacing={4}
            direction="row"
            justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <Typography variant="h1">{habit.name}</Typography>
              <Grid item>
                <Box component="span" mr={1}>
                  <HabitCategoryChip category={habit.category} />
                </Box>
              </Grid>
              <Grid item style={{ marginTop: 32, marginBottom: 32 }}>
                <Typography variant="body1">{habit.description}</Typography>
              </Grid>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs>
                  <Button
                    onClick={async () => {
                      setHabitsToRemove([
                        ...new Set([...habitsToRemove, habit.id]),
                      ]);
                      await nextHabit();
                    }}
                    fullWidth
                    variant="outlined"
                    color="primary">
                    {t('habit.removeHabit')}
                  </Button>
                </Grid>
                <Grid item xs>
                  <Button
                    onClick={async () => {
                      setHabitsToRemove(
                        habitsToRemove.filter((x) => x !== habit.id),
                      );
                      await nextHabit();
                    }}
                    fullWidth
                    variant="contained"
                    color="primary">
                    {t('habit.confirmHabit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6} xs={12}>
              <MemberHabitBanner habit={habit} />
            </Grid>
          </Grid>
        )}
        {state === 'CONFIRMING' && <Loading />}
        {state === 'END' && (
          <Grid container direction="column" alignItems="center">
            <SvgV2.Star />
            <Typography variant="h2" style={{ marginTop: 8, marginBottom: 24 }}>
              {t('habit.habitsConfirmed')}
            </Typography>
            <Typography variant="body2">
              <Trans
                i18nKey="habit.habitsConfirmedDescription"
                values={{
                  confirmedHabits: habits.length - habitsToRemove.length,
                  totalHabits: habits.length,
                  points: pointsAdded,
                }}
              />
            </Typography>
            <Button
              variant="contained"
              size="small"
              style={{ width: 400, marginTop: 24 }}
              onClick={() => history.push(`/member/dashboard`)}>
              {t('continue')}
            </Button>
            <Button
              variant="text"
              size="small"
              onClick={() => history.push('/member/myhabits?tab=myhabits')}>
              {t('habit.viewMyHabits')}
            </Button>
            <Button
              variant="text"
              size="small"
              style={{ color: '#A9A9A9' }}
              onClick={() => history.push('/member/about-me')}>
              {t('viewProfile')}
            </Button>
          </Grid>
        )}
      </>
    </MemberLayout>
  );
};

export default ConfirmHabitsPage;
