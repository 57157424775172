import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useQuery } from '@apollo/client';
import { ReactSVG } from 'react-svg';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import SVG from '../../../assets/svg';

const useStyles = makeStyles(() => ({
  category: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
  },
  large: {
    '& svg': {
      height: 40,
      width: 40,
    },
  },
  small: {
    '& svg': {
      height: 24,
      width: 24,
    },
  },
}));

type Props = {
  categoryId?: string;
  size?: 'large' | 'medium' | 'small';
};

const CategoryIconDiamond: React.FC<Props> = ({
  categoryId,
  size = 'large',
}) => {
  const classes = useStyles();

  const { loading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const category = projectCategories.find((x) => x.id === categoryId);
  let diamondSize = 80;
  let className: string | null = classes.large;

  switch (size) {
    case 'medium':
      diamondSize = 50;
      className = null;
      break;
    case 'small':
      diamondSize = 36;
      className = classes.small;
      break;
    default:
      break;
  }

  return (
    <DataStateHandler loading={loading}>
      {category ? (
        <div
          style={{
            position: 'relative',
            height: diamondSize,
            width: diamondSize,
          }}>
          <SVG.Diamond
            style={{
              color: category.color,
              height: diamondSize,
              width: diamondSize,
            }}
          />
          <ReactSVG
            src={category.iconUri}
            className={`${classes.category} ${className}`}
            beforeInjection={(svg) => {
              svg.setAttribute('fill', 'currentColor');
            }}
          />
        </div>
      ) : (
        <div />
      )}
    </DataStateHandler>
  );
};

export default CategoryIconDiamond;
