import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BadgeCard from './BadgeCard';
import { Badge, BadgeCategory } from '../../../types/badge/types';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: 16,
    marginBottom: 24,
  },
  card: {
    boxShadow: 'none !important',
    width: '100%',
  },
}));

type Props = {
  badgeCategory: BadgeCategory;
  badges: Badge[];
  showDivider?: boolean;
};

const BadgeSection: React.FC<Props> = ({
  badgeCategory,
  badges,
  showDivider,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { category, subtitle, title } = badgeCategory;

  const categoryBadges = badges.filter((badge) => badge.category === category);

  return (
    <Grid container direction="column">
      {showDivider && <Divider className={classes.divider} flexItem />}
      <Typography variant="h2">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      <Grid container item>
        {categoryBadges.map((badge) => (
          <Grid container lg={2} md={3} sm={4} xs={6}>
            <Card className={classes.card}>
              <CardActionArea
                onClick={() => history.push(`/member/badge/detail`, badge)}>
                <CardContent>
                  <BadgeCard badge={badge} />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default BadgeSection;
