import React from 'react';
import { Grid } from '@mui/material';
import CategoryIconSmall from '../components/common/Project/CategoryIconSmall';
import { EditableProject } from '../contexts/ProjectContext';
import { ProjectPurchaseStatus } from '../types/offsetProject/types';
import { ProjectCategory } from '../types/project/types';

export const getProjectDocumentList = (project: EditableProject) =>
  (project.documentUris || [])
    .filter((x, i) => (project.documentIndexesToRemove || []).indexOf(i) < 0)
    .map((url) => {
      let name = url.substring(url.lastIndexOf('/') + 1);
      name = name.substring(0, name.indexOf('?'));
      // Extra 14 characters to remove the timestamp prefix
      name = name.substring(name.lastIndexOf('%2F') + 3 + 14);

      return {
        name,
        url,
      };
    });

export const getProjectAdditionalImageUris = (project: EditableProject) =>
  (project.additionalImageUris || []).filter(
    (x, i) => (project.additionalImageIndexesToRemove || []).indexOf(i) < 0,
  );

export const projectStatusLabel = (status: ProjectPurchaseStatus) => {
  switch (status) {
    case ProjectPurchaseStatus.COMPLETE:
      return 'Completed';

    case ProjectPurchaseStatus.REQUESTED:
      return 'Requested';

    case ProjectPurchaseStatus.CANCELLED:
      return 'Cancelled';

    case ProjectPurchaseStatus.APPROVED:
      return 'Pending';

    default:
      throw new Error(`invalid project status: ${status}`);
  }
};

/**
 * Get a friendly display for the SD Vista value.
 * @param sdVista - SD Vista value.
 * @returns Friendly text for display.
 */
export const getSDVistaDisplay = (sdVista: string | boolean | undefined) => {
  switch (sdVista) {
    case 'true':
    case true: {
      return 'Yes';
    }
    case 'false':
    case false: {
      return 'No';
    }
    case 'n/a': {
      return 'N/A';
    }
    default: {
      return '';
    }
  }
};

export const projectCategoryFilterLabel =
  (projectCategories: ProjectCategory[]) => (value: unknown) =>
    (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={1}
        style={{ position: 'relative', top: 3 }}>
        <Grid item>
          <CategoryIconSmall categoryId={value as string} />
        </Grid>
        <Grid item>
          {projectCategories.find((cat) => cat.id === value)?.label || ''}
        </Grid>
      </Grid>
    );

export const projectCategorySearchFunction =
  (projectCategories: ProjectCategory[]) =>
  (collection: string[] | string[][], filter: string) => {
    // If collection is [][] and the first element is a null,
    // checking Array.isArray(collection[0]) is returning false
    // and then the code incorrectly assumes that it is a
    // 1-dimensional array. Flatten and filter any nulls prior
    // to the isArray() check. This flatten works for both [] and [][].
    const allIds = collection
      .flatMap((x) => x)
      .filter((f) => f !== null && f !== undefined);

    // const allIds = [
    //   ...new Set(
    //     Array.isArray(collection[0])
    //       ? collection.flatMap((x) => x)
    //       : (collection as string[]),
    //   ),
    // ];

    const mappedCategories = projectCategories
      .filter(
        (p) =>
          allIds.includes(p.id) &&
          p.label.toLowerCase().includes(filter.toLowerCase()),
      )
      .map((p) => p.id);

    return mappedCategories;
  };
