import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Project } from '../../../types/project/types';
import CategoryIconDiamond from './CategoryIconDiamond';
import SVG from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
  },
  button: {
    marginTop: 40,
    width: 270,
  },
  project: {
    margin: 'auto',
    padding: 16,
    width: 'fit-content',
    borderRadius: 16,
    border: '1px solid #DADADA',
    marginBottom: 8,
  },
  projectTitle: {
    margin: '16px 0',
  },
  projectQty: {
    color: '#6B6B6B',
  },
  projectPrice: {
    color: theme.palette.buttons.primary.main,
  },
}));

type Props = {
  project: Project;
  quantity: number;
  price: number;
  onClose: (openCart: boolean) => void;
};

const AddedToCartModal: React.FC<Props> = ({
  project,
  quantity,
  price,
  onClose,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      alignItems="center"
      justifyContent="center">
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          direction="column"
          className={classes.project}
          justifyContent="space-around">
          <CategoryIconDiamond size="small" categoryId={project.category} />
          <Typography
            variant="h3"
            align="center"
            className={classes.projectTitle}>
            {project.title}
          </Typography>
          <Typography variant="body1" className={classes.projectQty}>
            {quantity} {project.uom}{' '}
            <span className={classes.projectPrice}>
              {new Intl.NumberFormat('en-us', {
                maximumFractionDigits: 2,
                currency: 'USD',
                style: 'currency',
                minimumFractionDigits: 2,
              }).format(price || 0)}
            </span>
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h1" align="center">
          This project has been added to your cart!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Button
            className={classes.button}
            variant="outlined"
            color="primary"
            onClick={() => onClose(true)}>
            <Box component="span" mr={1}>
              <SVG.Marketplace.CartEmpty />
            </Box>
            View My Cart
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => onClose(false)}>
            Continue Shopping
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddedToCartModal;
