import React, { useContext, useState } from 'react';
import {
  Box,
  Grid,
  ListItemButton,
  Typography,
  Dialog,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';

import SVG from '../../assets/svg';
import { ContactType, UserDataRequest } from '../../types/user/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { USER_FULFILL_DATA_REQUEST } from '../../graphql/user/userMutations';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import {
  GET_COMPANY_ACTIONS,
  GET_COMPANY_ACTIONS_COUNT,
} from '../../graphql/company/company';
import UserDataStatusChip from '../common/UserDataStatusChip';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    width: '100%',
  },
  inner: {
    borderBottom: '1px solid #BDD9C5',
    paddingBottom: 16,
    paddingTop: 16,
  },
  requestedChip: {
    backgroundColor: '#FFDDE4',
    color: theme.palette.error.dark,
  },
  fulfilledChip: {
    color: '#3D2552',
    backgroundColor: '#E6DFED',
  },
  dialog: {
    padding: '40px 32px',
    width: 640,
  },
  dialogRow: {
    marginBottom: 24,
  },
}));

type Props = {
  dataRequest: UserDataRequest;
  userId: string;
  companyId: string;
  onDelete: () => void;
};

const labels = {
  [ContactType.EXPORT_DATA_REQUEST]: {
    rowTitle: 'Export Data',
    modalTitle: `Export User's Data`,
    modalText: 'I certify that the data for this user has been exported',
  },
  [ContactType.DELETE_ACCOUNT_REQUEST]: {
    rowTitle: 'Delete User Account',
    modalTitle: `Delete User's Account`,
    modalText:
      'I confirm that this user should be deleted.  When the Save button is pressed the user will be removed and will not be able to be readded.',
  },
};

const UserDataRequestRow: React.FC<Props> = ({
  dataRequest,
  userId,
  companyId,
  onDelete,
}) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { rowTitle, modalTitle, modalText } = labels[dataRequest.type] || {
    rowTitle: dataRequest.type,
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalConfirmationChecked, setIsModalConfirmationChecked] =
    useState(false);
  const [fulfilledDate, setFulfilledDate] = useState(dataRequest.fulfilledDate);
  const [fulfill, { loading, error }] = useMutation(USER_FULFILL_DATA_REQUEST);

  const canFulfill =
    !!modalTitle &&
    !fulfilledDate &&
    userHasAccess('ZeroMe.Employees.FulfillDataRequest', 'EDIT');

  const itemProps = canFulfill
    ? { onClick: () => setIsModalOpen(true) }
    : {
        disableRipple: true,
        disableTouchRipple: true,
        style: { cursor: 'default' },
      };

  return (
    <DataStateHandler loading={loading} error={error}>
      <>
        <ListItemButton {...itemProps}>
          <Grid item className={classes.root}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className={classes.inner}>
              <>
                <Typography variant="body2">{rowTitle}</Typography>
                <Grid item>
                  <Grid container alignItems="center">
                    <UserDataStatusChip fulfilledDate={fulfilledDate} />
                    {canFulfill && (
                      <Box ml="16px">
                        <SVG.CaretRightSmall />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </ListItemButton>
        <Dialog
          open={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setIsModalConfirmationChecked(false);
          }}
          maxWidth="md">
          <Grid container className={classes.dialog} direction="column">
            <Typography variant="h3" className={classes.dialogRow}>
              {modalTitle}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isModalConfirmationChecked}
                  onChange={() =>
                    setIsModalConfirmationChecked(!isModalConfirmationChecked)
                  }
                />
              }
              componentsProps={{ typography: { variant: 'body2' } }}
              label={modalText}
              className={classes.dialogRow}
            />
            <Grid item>
              <Grid container justifyContent="flex-end">
                <SmallPaddedButton
                  disabled={!isModalConfirmationChecked}
                  onClick={() => {
                    setIsModalOpen(false);
                    setIsModalConfirmationChecked(false);
                    fulfill({
                      variables: {
                        userId,
                        companyId,
                        contactType: dataRequest.type,
                      },
                      refetchQueries: [
                        GET_COMPANY_ACTIONS_COUNT,
                        GET_COMPANY_ACTIONS,
                      ],
                      onCompleted: () => {
                        if (
                          dataRequest.type ===
                          ContactType.DELETE_ACCOUNT_REQUEST
                        ) {
                          onDelete();
                        } else {
                          setFulfilledDate(new Date().toISOString());
                        }
                      },
                    });
                  }}>
                  Save
                </SmallPaddedButton>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </>
    </DataStateHandler>
  );
};

export default UserDataRequestRow;
