import { useQuery } from '@apollo/client';
import { Box, Button, Divider, Drawer, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import SVG from '../../../assets/svg';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import useAllCompanies from '../../../hooks/useAllCompanies';
import { Company } from '../../../types/company/types';
import NumberUtils from '../../../utils/numberUtils';
import CompanyNameWithLogo from '../../common/Company/CompanyNameWithLogo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconCircle from '../../common/Project/CategoryIconCircle';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import ClientDetails from '../Clients/ClientDetails';
import RoundedFlexBox from '../RoundedFlexBox';

const useStyles = makeStyles((theme) => ({
  categories: {
    marginLeft: '8px',
    display: 'flex',
    '& > div': {
      marginLeft: '-8px',
    },
  },
  drawer: {
    maxWidth: 1338,
    [theme.breakpoints.up('lg')]: {
      width: 1338,
    },
  },
  root: {
    padding: 24,
    height: '100%',
    overflow: 'hidden',
  },
  tableWrapper: {
    flex: '1',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  subHeader: {
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: '24px 0',
    color: '#DADADA',
  },
  companyScope: {
    flex: '1',
    border: '1px solid #DADADA',
    borderRadius: 8,
    padding: 16,
    overflow: 'hidden auto',
  },
  largeButton: {
    width: 315,
    height: 115,
    marginRight: 24,
    marginTop: 24,
    fontSize: 18,
    color: theme.palette.text.primary,
    fontWeight: 600,
    '& svg': {
      height: 40,
      width: 40,
      marginBottom: 8,
    },
    '&.MuiButton-contained': {
      color: '#fff',
    },
  },
}));

const CreateProjectAssign: React.FC = () => {
  const classes = useStyles();
  const [company, setCompany] = useState<Company>();
  const { setTypedProjectField, project } = useContext(ProjectContext);

  const { loading, allCompanies = [] } = useAllCompanies({
    includePurchaseStats: true,
    includeCurrentBudget: true,
  });

  // Required for categories column filtering
  const { loading: categoriesLoading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const tableStructure: GroupableTableStructure<Company>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'name',
          display: 'Client',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Names',
          render: (c) => <CompanyNameWithLogo company={c} />,
        },
        {
          key: 'currentBudget',
          display: 'Budget',
          render: ({ currentBudget }) => (
            <Typography variant="body2">
              {NumberUtils.format(currentBudget?.total, 'currency')}
            </Typography>
          ),
        },
        {
          key: 'categoryIds',
          display: 'Interests',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Interests',
          resolveFilterLabel: (value) =>
            projectCategories.find((cat) => cat.id === value)?.label || '',
          render: ({ categoryIds }) =>
            (categoryIds?.length && (
              <div className={classes.categories}>
                {categoryIds.map((id) => (
                  <CategoryIconCircle categoryId={id} key={id} />
                ))}
              </div>
            )) ||
            null,
        },
        {
          key: 'activeProjects',
          display: 'Active Projects',
          render: ({ purchaseStats }) => (
            <Typography variant="body2">
              {NumberUtils.format(
                purchaseStats?.activeProjects || 0,
                'integer',
              )}
            </Typography>
          ),
        },
        {
          key: 'profile',
          render: (selectedCompany) => (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setCompany(selectedCompany)}>
              View Profile
            </Button>
          ),
        },
      ],
    },
  ];

  const availableCompanies: Company[] = allCompanies?.filter(
    (c) => (c.isDemoCompany || false) === (project.isDemoProject || false),
  );

  const initialSelected = (
    project.companies ||
    availableCompanies?.map(({ id }) => id) ||
    []
  ).map((id) => ({ id }));

  return (
    <RoundedFlexBox>
      <Grid container className={classes.root} direction="column">
        <Grid item container>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h2">Assign Scope of Offering</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" className={classes.subHeader}>
                Do you want this project to be available for clients or for
                individuals through the mobile app?
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.largeButton}
                variant={!project.individualScope ? 'contained' : 'outlined'}
                size="large"
                color="primary"
                onClick={() => setTypedProjectField('individualScope', false)}>
                <Grid container direction="column">
                  <Grid item>
                    <SVG.Building />
                  </Grid>
                  <Grid item>Clients</Grid>
                </Grid>
              </Button>

              <Button
                className={classes.largeButton}
                variant={project.individualScope ? 'contained' : 'outlined'}
                size="large"
                color="primary"
                onClick={() => setTypedProjectField('individualScope', true)}>
                <Grid container direction="column">
                  <Grid item>
                    <SVG.User />
                  </Grid>
                  <Grid item>Individuals</Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.companyScope}>
            <Grid item>
              <Typography variant="h3" style={{ marginBottom: 24 }}>
                The project will be available for all clients unless a selection
                has been made.
              </Typography>
            </Grid>
            <Grid item container className={classes.tableWrapper}>
              <DataStateHandler loading={loading || categoriesLoading}>
                <Grid container direction="column" style={{ flex: '1' }}>
                  <GroupableSelectTable<Company>
                    tableData={availableCompanies}
                    tableStructure={tableStructure}
                    onSelected={(rows, { allSelected }) => {
                      setTypedProjectField(
                        'companies',
                        allSelected ? undefined : rows.map(({ id }) => id),
                      );
                    }}
                    allSelectedBannerText={(count) =>
                      `All ${count} clients are selected to receive this project in their Marketplace.`
                    }
                    errorText={
                      !project.individualScope &&
                      !!project.companies &&
                      !project.companies.length
                        ? 'At least one company must be assigned'
                        : ''
                    }
                    initialSelected={initialSelected}
                  />
                </Grid>
              </DataStateHandler>
            </Grid>
          </Grid>
        </>

        <Drawer
          open={!!company}
          onClose={() => setCompany(undefined)}
          anchor="right">
          {company && (
            <Box className={classes.drawer}>
              <ClientDetails
                companyId={company.id}
                handleClose={() => setCompany(undefined)}
              />
            </Box>
          )}
        </Drawer>
      </Grid>
    </RoundedFlexBox>
  );
};

export default CreateProjectAssign;
