import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';

import Leaderboards from './Leaderboards';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { getContestsQuery } from '../../../graphql/contest/contests';
import ChallengeCard from './ChallengeCard';

const useStyles = makeStyles(() => ({
  description: {
    marginTop: 24,
    textAlign: 'center',
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
  },
}));

type Props = {
  contestId: string;
};

const ChallengeLeaderboard: React.FC<Props> = ({ contestId }) => {
  const classes = useStyles();

  const {
    loading,
    error,
    data: { findCompanyContests: [challenge] = [undefined] } = {},
  } = useQuery(getContestsQuery(true), {
    variables: {
      contestIds: [contestId],
    },
  });

  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container item direction="column" alignItems="center">
        <ChallengeCard
          challenge={challenge}
          titlePosition="top"
          imageHeight={500}
          showSummary
          disabled
        />
        <Typography className={classes.description} variant="body1">
          {challenge?.description}
        </Typography>
      </Grid>
      <Divider className={classes.divider} flexItem />
      <Leaderboards contest={challenge} />
    </DataStateHandler>
  );
};

export default ChallengeLeaderboard;
