import { Button, Grid } from '@mui/material';
import React, { useContext } from 'react';
import { OffsetContext, Step } from '../../contexts/OffsetContext';
import EnumUtils from '../../utils/enumUtils';
import SVG from '../../assets/svg';

const AllocationStepButton = () => {
  const { currentStep, goToStep, applyAllocations, steps } =
    useContext(OffsetContext);

  const onHandleNextStep = () => {
    if (EnumUtils.values(Step).includes(currentStep + 1)) {
      goToStep(currentStep + 1);
    } else {
      applyAllocations();
    }
  };

  return (
    <Grid item sx={{ marginLeft: 'auto' }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ height: 40 }}
        endIcon={<SVG.ArrowRight />}
        onClick={onHandleNextStep}
        disabled={!steps[currentStep].valid}>
        {steps[currentStep].nextButton}
      </Button>
    </Grid>
  );
};

export default AllocationStepButton;
