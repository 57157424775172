import React from 'react';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
  TooltipProps,
} from 'recharts';
import { Divider, Grid } from '@mui/material';
import { DateGraphCoordinate } from '../../../../../types/dashboard/types';
import { formatDateStringUtc } from '../../../../../utils/dateUtils';
import TooltipContainer from '../../../../common/ChartTooltips/TooltipContainer';
import ChartLabel from '../../../../common/ChartLabel/ChartLabel';
import { formatTooltip } from '../../../../common/ChartTooltips/FormattedTooltip';
import ChartLegend from '../../../../common/ChartLegend/ChartLegend';
import CardFooter from '../../../../common/CardFooter/CardFooter';
import NumberUtils, { NumberFormat } from '../../../../../utils/numberUtils';

type Props = {
  footprints: Array<
    DateGraphCoordinate & {
      offsetValue: number;
      emissions: number;
      behaviorCredits: number;
    }
  >;
  goal?: number;
  includeBehaviorCredits: boolean; // Display the behavior credits in the tooltips?
};

const EmptyDot = () => <svg />;

const COLORS = {
  emissions: '#E96B52',
  offsets: '#6140A5',
  behaviorCredits: '#0099ff',
};

const TotalFootprintChart: React.FC<Props> = ({
  footprints,
  goal,
  includeBehaviorCredits,
}) => {
  const data = footprints.map((fp) => ({
    month: formatDateStringUtc(fp.date, 'MMM YYYY'),
    footprint: fp.value / 1000,
    offset: fp.offsetValue / 1000,
    emissions: fp.emissions / 1000,
    behaviorCredits: fp.behaviorCredits / 1000,
    goal,
    zero: 0,
  }));

  const total = footprints.reduce((t, f) => t + f.value / 1000, 0);

  const renderLeftTick = ({
    x,
    y,
    payload,
  }: {
    x: number;
    y: number;
    payload: { value: number };
  }) => (
    <text
      orientation="left"
      fontSize="10"
      stroke="none"
      width="60"
      height="215"
      type="number"
      x={x}
      y={y}
      fill="#FFFFFF"
      className="recharts-text recharts-cartesian-axis-tick-value"
      textAnchor="end">
      <tspan x="39" dy="0.455em">
        {payload.value}
      </tspan>
    </text>
  );
  const renderTooltipContents = ({
    active,
    payload,
  }: TooltipProps<number, string>) => {
    if (active) {
      if (payload && payload.length > 0) {
        const payloadData = payload[0].payload;
        if (payloadData) {
          const monthName = (payloadData.month || '') as string;

          let formatPrecision: NumberFormat;
          let emissions = 0;
          if (includeBehaviorCredits) {
            // User detail mode, only
            emissions = payloadData.emissions || 0;
            formatPrecision = 'precisionNumber';
          } else {
            // Company
            emissions = payloadData.footprint || 0;
            formatPrecision = 'number';
          }
          const emissionsDisplay = formatTooltip(emissions, formatPrecision);

          const offsets = (payloadData.offset || 0) as number;
          const offsetValue = formatTooltip(offsets, formatPrecision);

          // User detail mode, only
          let behaviorCredits = 0;
          let behaviorCreditsDisplay = '';
          if (includeBehaviorCredits) {
            // Get user's behavior credits
            behaviorCredits = (payloadData.behaviorCredits || 0) as number;
            behaviorCreditsDisplay = formatTooltip(
              behaviorCredits,
              formatPrecision,
            );
          }

          let adjusted = emissions - offsets;
          if (includeBehaviorCredits) {
            // User detail mode, only - also subtract behavior credits
            adjusted -= behaviorCredits;
          }
          const adjustedValue = formatTooltip(adjusted, formatPrecision);
          return (
            <TooltipContainer>
              <>
                <ChartLabel
                  labelColor="#6B6B6B"
                  color="#FFFFFF"
                  value=""
                  label={monthName}
                />
                <Divider />
                <ChartLabel
                  labelColor="#6B6B6B"
                  color={COLORS.emissions}
                  label="Emissions"
                  value={emissionsDisplay}
                />
                <ChartLabel
                  labelColor="#6B6B6B"
                  color={COLORS.offsets}
                  label="Offsets"
                  value={offsetValue}
                />
                {includeBehaviorCredits && (
                  <ChartLabel
                    key="behaviorCredits"
                    labelColor="#6B6B6B"
                    color={COLORS.behaviorCredits}
                    label="Behavior Credits"
                    value={behaviorCreditsDisplay}
                  />
                )}
                <Divider />
                <ChartLabel
                  key="adjusted"
                  labelColor="#6B6B6B"
                  label="Adjusted Footprint"
                  value={adjustedValue}
                />
              </>
            </TooltipContainer>
          );
        }
      }
    }
    return <svg />;
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <div style={{ height: 215 }}>
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={data}
              margin={{
                top: 10,
                right: 10,
                left: 0,
                bottom: 10,
              }}>
              <CartesianGrid vertical={false} color="#D1FFBB" opacity={0.2} />
              <XAxis
                dataKey="month"
                orientation="bottom"
                stroke="#FFFFFF"
                fontSize={10}
                tickMargin={24}
                tickSize={0}
                interval={0}
                padding={{ right: 25 }}
              />
              <YAxis
                orientation="left"
                tickMargin={16}
                tickSize={0}
                fontSize={10}
                tick={renderLeftTick}
                stroke="#FFFFFF"
              />
              <Tooltip
                content={renderTooltipContents}
                cursor={{ stroke: '#D1FFBB' }}
              />
              <defs>
                <linearGradient id="emissions" x1={0} y1={0} x2={0} y2={1}>
                  <stop
                    offset="0%"
                    stopColor={COLORS.emissions}
                    stopOpacity={1}
                  />
                  <stop
                    offset="100%"
                    stopColor={COLORS.emissions}
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="offsets" x1={0} y1={0} x2={0} y2={1}>
                  <stop
                    offset="0%"
                    stopColor={COLORS.offsets}
                    stopOpacity={0.75}
                  />
                  <stop offset="100%" stopColor="#C9BDD9" stopOpacity={0} />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="footprint"
                strokeWidth={2}
                stroke={COLORS.emissions}
                fillOpacity={1}
                activeDot={{ stroke: COLORS.emissions, fill: COLORS.emissions }}
                fill="url(#emissions)"
              />
              <Area
                type="monotone"
                dataKey="offset"
                strokeWidth={2}
                stroke={COLORS.offsets}
                fillOpacity={1}
                activeDot={{ stroke: COLORS.offsets, fill: COLORS.offsets }}
                fill="url(#offsets)"
              />
              <Line
                dot={<EmptyDot />}
                type="monotone"
                dataKey="zero"
                activeDot={false}
                stroke="#FFFFFF"
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Grid>
      <Grid item>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between">
          <Grid item>
            <ChartLegend
              keys={[
                {
                  color: COLORS.emissions,
                  label: 'Footprint',
                },
                {
                  color: COLORS.offsets,
                  label: 'Offset',
                },
              ]}
              labelColor="#FFFFFF"
            />
          </Grid>
          <Grid item>
            <CardFooter
              dataStyle={{ color: '#FFFFFF' }}
              labelStyle={{
                color: '#FFFFFF',
                opacity: 0.5,
                whiteSpace: 'pre-line',
                textAlign: 'right',
                lineHeight: 1.5,
              }}
              data={NumberUtils.format(total, 'integer')}
              label={`MT C0₂E
              Footprint YTD`}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalFootprintChart;
