import React from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { ProfileCardTip } from '../../../types/question/types';
import {
  QuestionnaireActionTypes,
  useCleanupQuestionnaire,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';
import theme from '../../../theme/memberTheme';
import QuestionnaireButton from './QuestionnaireButton';

export type ProfileTipsProps = {
  profileCardTip: ProfileCardTip;
};

export function ProfileTips({ profileCardTip }: ProfileTipsProps): JSX.Element {
  const state = useQuestionnaire();
  const dispatch = useQuestionnaireDispatch();
  const cleanupQuestionnaire = useCleanupQuestionnaire();

  const title =
    profileCardTip.profileTipTitle || profileCardTip.categoryTipTitle;

  return (
    <Grid
      container
      flexDirection="column"
      style={{ maxWidth: 500, margin: 'auto' }}
      p={2}>
      <Grid
        item
        container
        flexDirection="row"
        alignSelf="flex-start"
        alignItems="center"
        style={{
          backgroundColor: theme.palette.primary.light,
          borderRadius: 10,
          padding: 4,
          marginBottom: 16,
        }}>
        <Grid item style={{ marginTop: 8 }}>
          <ReactSVG
            src={profileCardTip.questionTreeCategory.iconUrl ?? ''}
            beforeInjection={(svg) => {
              svg.setAttribute('fill', theme.palette.primary.main);
              svg.setAttribute('height', '20');
              svg.setAttribute('width', '20');
            }}
          />
        </Grid>
        <Grid item pl={1}>
          <Typography variant="h2">{profileCardTip?.iconLabel}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h2">Thank you for your answers!</Typography>
      </Grid>
      <Grid item pt={2}>
        {!!title?.trim() && <Typography variant="body1">{title}</Typography>}
        {profileCardTip ? (
          profileCardTip.tips.map((tip) => (
            <Typography variant="body1" key={tip.id}>
              {`\u2022 ${tip.text}`}
            </Typography>
          ))
        ) : (
          <Typography variant="body1">
            {`\u2022 We are working hard to provide helpful tips for reducing your footprint`}
          </Typography>
        )}
      </Grid>
      <Grid item pt={4}>
        <QuestionnaireButton
          variant="contained"
          onClick={async () => {
            if (
              state.carouselIndex === state.profileCards.length - 1 &&
              state.cleanup
            ) {
              await cleanupQuestionnaire(state.cleanup);
            } else {
              dispatch({ type: QuestionnaireActionTypes.NEXT });
            }
          }}
          fullWidth>
          Back To Profile
        </QuestionnaireButton>
      </Grid>
    </Grid>
  );
}
