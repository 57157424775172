import { Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import SVG from '../../../assets/svg';
import { MarketplaceContext } from '../../../contexts/MarketplaceContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    border: '1px solid #0A3751',
    borderRadius: 8,
    marginTop: 16,
    marginBottom: 16,
  },
  projectTitle: {
    color: theme.palette.buttons.primary.active,
  },
  closeButton: {
    color: theme.palette.text.primary,
    padding: 0,
  },
}));

const CartValidationInfo: React.FC = () => {
  const { cart, clearCartValidationData } = useContext(MarketplaceContext);
  const classes = useStyles();

  if (!cart.validationData.length) {
    return null;
  }

  // Dynamic UOM
  const displayUom = (uom: string | undefined) => (uom ? ` / ${uom}` : '');

  return (
    <Grid container className={classes.root}>
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        style={{ marginBottom: 24 }}>
        <Typography variant="h3">
          <SVG.WarningTriangle style={{ marginRight: 8 }} />
          There has been a change to your cart!
        </Typography>
        <IconButton
          onClick={() => clearCartValidationData()}
          className={classes.closeButton}
          size="large">
          <SVG.Close />
        </IconButton>
      </Grid>
      <Typography variant="body2">
        The price and/or inventory of ({cart.validationData.length}) of your
        projects have changed. The current price in your cart will always
        reflect the most recent price displayed on their project detail page.
      </Typography>
      <ul style={{ marginTop: 24 }}>
        {cart.validationData.map((vd) => (
          <li key={vd.project.id}>
            {vd.action === 'PRICE_CHANGED' && (
              <Typography variant="body2">
                <span className={classes.projectTitle}>{vd.project.title}</span>{' '}
                has
                {vd.to > vd.from
                  ? ' has increased'
                  : ' has decreased'} from{' '}
                <strong>
                  {vd.from.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                  {displayUom(vd.uom)}
                </strong>{' '}
                to{' '}
                <strong>
                  {vd.to.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                  {displayUom(vd.uom)}
                </strong>
              </Typography>
            )}
            {vd.action === 'QUANTITY_CHANGED' && (
              <Typography variant="body2">
                <span className={classes.projectTitle}>{vd.project.title}</span>{' '}
                available quantity has
                {vd.to > vd.from
                  ? ' has increased'
                  : ' has decreased'} from{' '}
                <strong>
                  {vd.from.toLocaleString('en-US')} {vd.uom}
                </strong>{' '}
                to{' '}
                <strong>
                  {vd.to.toLocaleString('en-US')} {vd.uom}
                </strong>
                {vd.to === 0 ? ', and has been removed from your cart.' : ''}
              </Typography>
            )}
          </li>
        ))}
      </ul>
    </Grid>
  );
};

export default CartValidationInfo;
