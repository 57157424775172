import { Divider } from '@mui/material';
import React from 'react';
import { NumberFormat } from '../../../utils/numberUtils';

import ChartLabel from '../ChartLabel/ChartLabel';
import { formatTooltip } from './FormattedTooltip';
import TooltipContainer from './TooltipContainer';

type Props = {
  format: NumberFormat;
  suffix?: string;
  items: Array<{
    value: number;
    color?: string;
    imageUri?: string;
    id: string | number;
  }>;
};

const TotalsTooltip: React.FC<Props> = ({ format, suffix, items }) => {
  const total = items.reduce((sum, item) => sum + (item.value || 0), 0);
  return (
    <TooltipContainer>
      <>
        {items.map((item) => (
          <ChartLabel
            key={item.id}
            labelColor="#6B6B6B"
            color={item.color}
            imageUri={item.imageUri}
            label={item.id as string}
            value={`${formatTooltip(item.value, format)}${suffix || ''}`}
          />
        ))}
        <Divider />
        <ChartLabel
          key="total"
          labelColor="#6B6B6B"
          label="Total"
          value={`${formatTooltip(total, format)}${suffix || ''}`}
        />
      </>
    </TooltipContainer>
  );
};

export default TotalsTooltip;
