import React from 'react';
import { Variant } from '@mui/material/styles/createTypography';
import { Grid, Typography } from '@mui/material';
import { CommunicationRatingAggregate } from '../../communications/types';
import CommunicationStar from './CommunicationStar';
import { Colors } from '../../../theme/shared';

type Props = {
  rating?: CommunicationRatingAggregate;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  hideText?: boolean;
};

const CommunicationStarRating: React.FC<Props> = ({
  rating,
  size = 'sm',
  hideText,
}) => {
  let starSize = 10;
  let variant: Variant = 'h5';

  switch (size) {
    case 'md':
      starSize = 16;
      variant = 'h4';
      break;

    case 'lg':
      starSize = 25;
      variant = 'h3';
      break;

    case 'xl':
      starSize = 40;
      variant = 'h2';
      break;

    default:
      // Do nothing
      break;
  }

  return (
    <Grid container direction="row" alignItems="center">
      <CommunicationStar
        disabled={!rating?.count}
        fillRatio={(rating?.score ?? 0) / 5}
        sizePx={starSize}
      />
      <Grid item>
        {!hideText && rating && (
          <Typography variant={variant} style={{ color: Colors.CobaltBlue }}>
            {rating?.score} out of 5
          </Typography>
        )}
        {!hideText && !rating && (
          <Typography variant={variant} style={{ color: Colors.TertiaryText }}>
            No ratings yet
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default CommunicationStarRating;
