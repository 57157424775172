import React from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import { t } from 'i18next';
import { Field } from 'formik';

import CategoryIdSelect from './CategoryIdSelect';
import HeadingInput from './HeadingInput';
import PublishFields from './PublishFields';
import RelatedArticlesAndHabits from './RelatedArticlesAndHabits';
import { CommunicationInput } from '../types';
import AppTextField from '../../common/AppTextField';
import { AppRichTextField } from '../../common/AppRichTextField';
import FileUpload from '../../common/FileUpload/FileUpload';
import ImageUpload from '../../common/ImageUpload';
import SVG from '../../../assets/svg';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { Disableable } from '../../../types/common';

const AudioForm: React.FC<Disableable> = ({ disabled }) => {
  const { setTypedFieldValue, values, initialValues } =
    useTypedFormikContext<CommunicationInput>();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">
              {t('communication.featuredProject')}
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              disabled={disabled}
              checked={values.isFeatured || false}
              onChange={(e, checked) =>
                setTypedFieldValue('isFeatured', checked)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CategoryIdSelect disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <HeadingInput disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{t('author')}</Typography>
        <Field
          name="author"
          disabled={disabled}
          label=""
          component={AppTextField}
          placeholder="Author Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUpload
          disabled={disabled}
          value={values.headerImageUrl || ''}
          onChange={(file) => setTypedFieldValue('headerImage', file)}
        />
      </Grid>
      {!disabled && (
        <Grid item xs={12}>
          <Typography variant="body2">
            {t('communication.audioFile')}
          </Typography>
          <FileUpload
            showFiles
            onFileChange={(files) => {
              if (files.length) {
                setTypedFieldValue('contentUpload', {
                  file: files[0].file,
                });
              }
            }}
            accept=".aac,.mp3"
            uploadDivStyle={{ padding: 64 }}
            uploadIcon={<SVG.Communications.Audio />}
            uploadLabel={
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h4">
                    {t('communication.dropAudioFile')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" style={{ fontSize: 10 }}>
                    {t('communication.clickToBrowse')}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      )}
      {values.contentUploadUrl ? (
        <Grid item xs={12}>
          <Typography variant="body2">
            <a href={values.contentUploadUrl}>
              {t('communication.currentAudio')}
            </a>
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant="body2">
          {t('communication.transcription')}
        </Typography>
        <Field
          name="content"
          component={AppRichTextField}
          initialValue={initialValues.content}
          disabled={disabled}
        />
      </Grid>
      <RelatedArticlesAndHabits disabled={disabled} />
      <Grid item xs={12}>
        <PublishFields disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default AudioForm;
