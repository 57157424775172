import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import Content from '../../components/offset/Content';
import Metrics from '../../components/offset/Metrics';
import OffsetHeader from '../../components/offset/OffsetHeader';
import OffsetProvider from '../../contexts/OffsetContext';
import HeaderTabs from '../../components/common/HeaderTabs/HeaderTabs';
import HeaderTab from '../../components/common/HeaderTabs/HeaderTab';
import OffPlatform from '../../components/offset/OffPlatform';

const useStyles = makeStyles({
  main: { height: '100%' },
  content: { flex: 1 },
  tab: {
    maxWidth: '240px !important',
  },
});

const Tabs = {
  carbon: 0,
  offplatform: 1,
};

const Offset: React.FC = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<number>(Tabs.carbon);
  return (
    <MainLayout childBoxStyle={{ height: '100%' }} disableContainer>
      <OffsetProvider mtOnly>
        <Box px={8} py={5} height="100%">
          <Grid
            container
            direction="column"
            wrap="nowrap"
            spacing={4}
            className={classes.main}>
            <Grid item>
              <OffsetHeader />
            </Grid>
            <Grid item>
              <Metrics />
            </Grid>
            <Grid item className={classes.content} xs={12}>
              <HeaderTabs
                value={tab}
                style={{ marginBottom: 24, width: '100%' }}>
                <HeaderTab
                  className={classes.tab}
                  label="Carbon Emissions"
                  onClick={() => setTab(Tabs.carbon)}
                />
                <HeaderTab
                  label="Off-platform Allocation"
                  className={classes.tab}
                  onClick={() => setTab(Tabs.offplatform)}
                />
              </HeaderTabs>
              {tab === Tabs.carbon && <Content />}
              {tab === Tabs.offplatform && <OffPlatform />}
            </Grid>
          </Grid>
        </Box>
      </OffsetProvider>
    </MainLayout>
  );
};

export default Offset;
