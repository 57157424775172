import React from 'react';
import MemberLayout from '../../../components/layouts/MemberLayout';
import SustainabilityInterests from '../../../components/member/dashboard/SustainabilityInterests';

const MemberSustainabilityInterests: React.FC = () => (
  <MemberLayout>
    <SustainabilityInterests />
  </MemberLayout>
);

export default MemberSustainabilityInterests;
