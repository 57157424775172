import { Box, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AppInfo from '../../common/AppInfo';
import CardButton from '../../common/CardButton';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import OffsetCardValue from './OffsetCardValue';

const useStyles = makeStyles(() => ({
  paper: {
    height: '100%',
  },
  metrics: {
    maxWidth: 800,
  },
  title: {
    fontWeight: 700,
  },
}));

type Props = {
  title: string;
  loading?: boolean;
  tooltipId?: string;
  onClick?: () => void;
  values:
    | { label?: string; value: number | string; categoryId?: never }[]
    | { label?: string; value?: never; categoryId: string }[];
};

const OffsetCard = ({ title, values, loading, onClick, tooltipId }: Props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Box paddingLeft={2} paddingRight={2} paddingTop={3} paddingBottom={3}>
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between">
            <Typography variant="h3" className={classes.title}>
              {title}
            </Typography>
            {tooltipId ? <AppInfo id={tooltipId} /> : null}
          </Grid>
        </Grid>
        <DataStateHandler loading={loading}>
          <Grid
            container
            className={classes.metrics}
            justifyContent="space-between"
            direction="row"
            alignItems="center">
            {values.map((v) => (
              <OffsetCardValue
                key={v.label}
                value={v.value}
                categoryId={v.categoryId}
                label={v.label}
              />
            ))}

            {onClick && (
              <Grid item>
                <CardButton onClick={onClick} />
              </Grid>
            )}
          </Grid>
        </DataStateHandler>
      </Box>
    </Paper>
  );
};

export default OffsetCard;
