import { useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SVG from '../../../assets/svg';
import ConfirmCancelModal from '../../common/ConfirmCancelModal/ConfirmCancelModal';
import { DELETE_PROJECT_MUTATION } from '../../../graphql/projects/projectMutations';
import LoadingBackdrop from '../../common/LoadingBackdrop';

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  projectId: string;
  refetch: () => void;
  onCancel: () => void;
  onConfirm: () => void;
};

const DeleteProjectModal: React.FC<Props> = ({
  projectId,
  refetch,
  onCancel,
  onConfirm,
}) => {
  const classes = useStyles();

  const [deleteProjectMutation, { loading: deleteLoading }] = useMutation<
    boolean,
    { projectId: string }
  >(DELETE_PROJECT_MUTATION);

  return (
    <>
      <ConfirmCancelModal
        title="Heads Up!"
        icon={
          <Grid item>
            <SVG.WarningTriangle className={classes.deleteIcon} />
          </Grid>
        }
        message="You are about to delete this project. This cannot be reversed."
        isOpen={!!projectId}
        disableConfirm={deleteLoading}
        disableCancel={deleteLoading}
        onCancel={() => onCancel()}
        onConfirm={async () => {
          await deleteProjectMutation({
            variables: {
              projectId,
            },
          });
          onConfirm();
          refetch();
        }}
      />
      <LoadingBackdrop open={deleteLoading} />
    </>
  );
};
export default DeleteProjectModal;
