import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import {
  CompanySettingsContext,
  EditableCompany,
} from '../../contexts/CompanySettingsContext';
import ImagePosition from '../common/ImagePosition';

export const getCompanyImage = (company: EditableCompany) =>
  (company.image?.file && URL.createObjectURL(company.image.file)) ||
  company.image?.publicUrl ||
  company.imageUris?.large;

const CompanyImagePositionForm: React.FC = () => {
  const { company } = useContext(CompanySettingsContext);
  return (
    <Grid item xs={12}>
      <ImagePosition
        fieldName="imagePosition"
        imageUri={getCompanyImage(company) || ''}
      />
    </Grid>
  );
};
export default CompanyImagePositionForm;
