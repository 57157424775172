import React from 'react';
import { Colors } from '../../../theme/shared';

type Props = {
  disabled?: boolean;
  fillRatio?: number;
  sizePx?: number;
};

const CommunicationStar: React.FC<Props> = ({
  disabled,
  fillRatio,
  sizePx = 8,
}) => {
  const FILLED_COLOR = Colors.CobaltBlue;
  const EMPTY_COLOR = '#CAD8FD';
  const NA_STROKE = Colors.DisabledGrey;
  const NA_FILL = '#EFEFEF';

  const stroke = disabled ? NA_STROKE : FILLED_COLOR;
  const empty = disabled ? NA_FILL : EMPTY_COLOR;

  const scorePct = (fillRatio ?? 0) * 88 + 6;

  const starSvg = `<svg width="9" height="8" viewBox="-0.75 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <linearGradient id="grad1">
            <stop offset="0%" stop-color="${stroke}"/>
            <stop offset="${scorePct}%" stop-color="${stroke}"/>
            <stop offset="${scorePct}%" stop-color="${empty}"/>
            <stop offset="100%" stop-color="${empty}" />
        </linearGradient>
    </defs>
    <path d="M8.22173 3.55816L6.20382 5.0145L6.97438 7.37018C7.0392 7.56809 6.96832 7.78524 6.79871 7.90796C6.7139 7.96932 6.61334 8 6.51399 8C6.41343 8 6.31408 7.96992 6.22928 7.90796L4.21075 6.45162L2.19284 7.90736C2.02321 8.03008 1.79302 8.03128 1.62279 7.90736C1.45256 7.78525 1.38169 7.56749 1.44712 7.36958L2.21768 5.0139L0.199769 3.55756C0.0295369 3.43544 -0.0413354 3.21769 0.0240933 3.01977C0.0889124 2.82126 0.27489 2.68711 0.485102 2.68711H2.97911L3.74968 0.332047C3.8145 0.134131 4.00108 0 4.21069 0C4.42029 0 4.60687 0.134145 4.6717 0.332663L5.44226 2.68773H7.93627C8.14588 2.68773 8.33246 2.82187 8.39728 3.02039C8.4621 3.2183 8.39135 3.43604 8.22173 3.55816Z" fill="url(#grad1)" stroke="${stroke}" stroke-width="0.5"/>
    </svg>`;

  return (
    <img
      alt="star"
      src={`data:image/svg+xml;base64,${btoa(starSvg)}`}
      style={{ width: sizePx, height: sizePx, marginRight: 4 }}
    />
  );
};

export default CommunicationStar;
