import React, { FormEvent, useState } from 'react';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  clearSelectionGrid: { flexGrow: 1, padding: '5px 0' },
  fullWidth: { width: '100%' },
  closeIcon: {
    padding: 10,
  },
  clearSelection: {
    color: theme.palette.primary.light,
    fontSize: '0.75rem',
  },
  formGridItem: { flexGrow: 1, margin: '0 10px' },
}));

const ColumnSearchBar: React.FC<Props> = ({
  className,
  style,
  height,
  width,
  searchOnKeypress,
  placeholder = '',
  onSearch = () => {
    // Do nothing
  },
  value: defaultValue = '',
  showSearchBar = true,
}) => {
  const classes = useStyles();
  const [searchCriteria, setSearchCriteria] = useState(defaultValue);

  const handleChange = ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    setSearchCriteria(value);
    if (searchOnKeypress) {
      onSearch(value);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSearch(searchCriteria);
  };

  if (!showSearchBar) {
    return null;
  }

  return (
    <Grid container>
      <Grid item className={classes.formGridItem}>
        <form
          style={{ ...style, height, width }}
          className={className}
          onSubmit={handleSubmit}>
          <FormControl
            variant="standard"
            style={{
              width: '100%',
              height,
            }}
            data-testid="formControl">
            <OutlinedInput
              onChange={handleChange}
              value={searchCriteria}
              placeholder={placeholder}
              style={{ borderRadius: 4 }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton data-testid="submitSearchButton" type="submit" size="large">
                    <SearchIcon htmlColor="#000" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>
      </Grid>
    </Grid>
  );
};

type Props = {
  className?: string;
  style?: React.CSSProperties;
  height?: number;
  width?: string | number;
  placeholder?: string;
  searchOnKeypress?: boolean;
  onSearch?: (criteria: string) => void;
  value?: string;
  showSearchBar?: boolean;
};

export default ColumnSearchBar;
