import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import { Colors } from '../../../theme/shared';
import NumberUtils from '../../../utils/numberUtils';

const useStyles = makeStyles((theme) => ({
  cardsContainer: {
    justifyContent: 'space-evenly',
    marginTop: 8,
    padding: 16,
    borderRadius: 16,
  },
  cardContainer: {
    padding: '0px 8px',
  },
  card: {
    alignItems: 'center',
    flexDirection: 'column',
    padding: 8,
    borderRadius: 16,
    backgroundColor: theme.palette.backgrounds.white,
  },
}));

type Props = {
  rank?: number;
  points?: number;
  optedOut?: boolean;
  backgroundColor?: string;
  boxShadow?: number;
};

const LeaderboardSummaryBar: React.FC<Props> = ({
  rank,
  points = 0,
  optedOut,
  backgroundColor,
  boxShadow = 0,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      item
      className={classes.cardsContainer}
      style={{ backgroundColor }}>
      {!optedOut && rank && (
        <Grid container item className={classes.cardContainer} xs={6}>
          <Grid container item className={classes.card} sx={{ boxShadow }}>
            <Typography variant="h2" color={Colors.CobaltBlue}>
              {NumberUtils.formatOrdinal(rank)}
            </Typography>
            <Typography variant="body2" color={Colors.TertiaryText}>
              {t('place')}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        item
        className={classes.cardContainer}
        xs={optedOut || !rank ? 12 : 6}>
        <Grid container item className={classes.card} sx={{ boxShadow }}>
          <Typography variant="h2" color={Colors.CobaltBlue}>
            {points}
          </Typography>
          <Typography variant="body2" color={Colors.TertiaryText}>
            {t('contest.pointsEarnedSoFar')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeaderboardSummaryBar;
