import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { get, isNil, sortBy } from 'lodash';
import {
  ClickAwayListener,
  Grid,
  Typography,
  Button,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery, gql } from '@apollo/client';
import ColumnSearchBar from './ColumnSearchBar';
import CheckList, { CheckListOption } from '../CheckList/CheckList';
import { BaseColumnFilter, ColumnFilterProps } from './types';
import DataStateHandler from '../DataStateHandler/DataStateHandler';

const useStyles = makeStyles((theme) => ({
  applyFilterContainer: {
    padding: '10px 0 0',
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid #DADADA',
  },
  labelDisplayedRows: {
    padding: 10,
    color: theme.palette.common.white,
  },
  fetching: {
    background: theme.palette.common.black,
    width: 275,
  },
  button: {
    border: '1px solid #BDD9C5',
    borderRadius: 4,
    marginRight: 8,
  },
  buttonText: {
    fontSize: 12,
    fontWeight: 600,
    color: '#056433',
  },
}));

type SearchResults = {
  checked: boolean;
  visible: boolean;
  value: string;

  /**
   * Optional sortable property.
   */
  sort?: string;
};

/**
 * Used in a lookup for foreign key lists.
 */
type Lookup = {
  id: string;
  label: string;
};

const AlphaColumnFilter: BaseColumnFilter = Object.assign(
  ({
    onApplyFilter,
    onClickAway,
    column,
    filter,
    getPropertyQuery,
    queryVariables = {},
    tableData,
  }: ColumnFilterProps) => {
    const classes = useStyles();
    const [clientFilter, setClientFilter] = useState('');
    const [searchResults, setSearchResults] = useState<SearchResults[]>([]);

    // Exclude the current column's key from the filter since we don't want to
    // exclude unselected options.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [column.key]: columnKeyValue, ...newFilter } = filter;

    const variables = {
      ...queryVariables,
      property: column.key,
      filter: newFilter,
    };

    // Any lookups defined? They must contain an array with id and label properties. { id: string, label: string }
    const sortLookups = queryVariables['sort-list'] as Lookup[] | undefined;

    const {
      loading,
      error,
      data: { properties: serverProperties = [] } = {},
      refetch,
    } = useQuery<{ properties: string[] }>(
      getPropertyQuery ||
        gql`
          query {
            fake
          }
        `,
      {
        skip: !getPropertyQuery,
        variables,
        fetchPolicy: 'no-cache',
      },
    );

    const filterData = filter[column.key] as string[];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const resolveFilter = (collection: any[]) => {
      if (column.searchFunction) {
        return column.searchFunction(collection, clientFilter);
      }

      // Keep track of unique items to avoid duplicate filter items
      const uniqueCollection: string[] = [];

      return collection.filter((x) => {
        // Remove duplicates. lodash's uniq() does not appear to work with an array of objects.
        // Deserialize 'x' to JSON (it works for both objects and strings) to check for uniqueness.
        const deserializedItem = JSON.stringify(x);
        if (uniqueCollection.includes(deserializedItem)) {
          return false;
        }
        uniqueCollection.push(deserializedItem);

        if (clientFilter) {
          if (typeof x === 'string') {
            return x.toLowerCase().includes(clientFilter.toLowerCase());
          }

          if (Array.isArray(x)) {
            return (
              x.findIndex((item) =>
                item.toLowerCase().includes(clientFilter.toLowerCase()),
              ) > -1
            );
          }
        }

        return !isNil(x);
      });
    };

    const properties = useMemo(
      () =>
        getPropertyQuery
          ? serverProperties
          : [
              ...new Set(
                resolveFilter(
                  tableData.map(
                    (x) =>
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      get(x as any, column.key) as string | string[],
                  ),
                ).flat(),
              ),
            ].sort(),
      [getPropertyQuery ? serverProperties : tableData, clientFilter],
    );

    useEffect(() => {
      if (properties.length) {
        const mappedSearchResults = properties.map<SearchResults>((value) => ({
          value,
          description: column.resolveFilterLabel
            ? column.resolveFilterLabel(value)
            : value,
          checked: !!(filterData && filterData.includes(value)),
          visible: true,
          // If sortLookups are defined, populate this property with the label's contents
          sort: sortLookups
            ? sortLookups.find((f) => f.id === value)?.label
            : undefined,
        }));

        if (sortLookups) {
          // Sort the results via the 'sort' property
          const sortedMappedSearchResults = sortBy(mappedSearchResults, [
            'sort',
          ]);
          setSearchResults(sortedMappedSearchResults);
        } else {
          // Unsorted results
          setSearchResults(mappedSearchResults);
        }
      } else if (!properties.length && searchResults.length) {
        setSearchResults([]);
      }
    }, [column.key, filter, properties]);

    const clearSelection = useCallback(() => {
      setSearchResults(searchResults.map((r) => ({ ...r, checked: false })));
    }, [searchResults]);

    const handleSearchResultItemChange = useCallback(
      ({ value, checked }: CheckListOption) => {
        const newSearchResults = [...searchResults];
        const i = newSearchResults.findIndex((r) => {
          if (typeof r.value === 'object') {
            return r.value === value;
          }
          return r.value.toString() === value.toString();
        });
        newSearchResults[i].checked = checked;
        setSearchResults(newSearchResults);
      },
      [searchResults],
    );

    const handleApplyFilter = useCallback(
      (event: React.MouseEvent<unknown>) => {
        const selectedSearchResultValues = searchResults
          .filter((r) => r.checked)
          .map((r) => r.value);
        onApplyFilter({ event, searchResults: selectedSearchResultValues });
      },
      [onApplyFilter, searchResults],
    );

    return (
      <DataStateHandler loading={loading} error={error}>
        <ClickAwayListener onClickAway={onClickAway}>
          <Grid container>
            {column.searchable && (
              <Grid item style={{ marginLeft: -8, marginRight: -8 }}>
                <ColumnSearchBar
                  showSearchBar
                  searchOnKeypress={!getPropertyQuery}
                  onSearch={(searchCriteria) => {
                    if (getPropertyQuery) {
                      refetch({
                        ...variables,
                        searchText: searchCriteria,
                      });
                    } else {
                      setClientFilter(searchCriteria);
                    }
                  }}
                  placeholder={column.searchPlaceholder}
                />
              </Grid>
            )}
            <Box overflow="auto" maxHeight={400} width="100%">
              <CheckList
                options={searchResults}
                onChange={handleSearchResultItemChange}
              />
            </Box>

            <Grid
              container
              justifyContent="center"
              className={classes.applyFilterContainer}>
              <Grid item container justifyContent="flex-end">
                <Button
                  aria-label="close"
                  onClick={handleApplyFilter}
                  className={classes.button}>
                  <Typography variant="body1" className={classes.buttonText}>
                    Apply Filter
                  </Typography>
                </Button>
                <Button
                  aria-label="close"
                  onClick={clearSelection}
                  className={classes.button}>
                  <CloseIcon fontSize="small" color="primary" />
                  <Typography variant="body1" className={classes.buttonText}>
                    Clear Selection
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ClickAwayListener>
      </DataStateHandler>
    );
  },
  {
    filterFn: (rowValue: null | string | string[], filterValues: string[]) =>
      !filterValues.length ||
      (!isNil(rowValue) &&
        (Array.isArray(rowValue)
          ? !!filterValues.filter((fv) => rowValue?.indexOf(fv) >= 0).length
          : filterValues.indexOf(rowValue) >= 0)),
  },
);

export default AlphaColumnFilter;
