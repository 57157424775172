import { ResponsiveBar } from '@nivo/bar';
import { useQuery } from '@apollo/client';

import React, { useContext, useState } from 'react';
import { Divider, FormControl, Grid, MenuItem } from '@mui/material';

import CardFooter from '../../../common/CardFooter/CardFooter';
import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import AppCard from '../../../common/AppCard/AppCard';
import { chartColors } from '../../../../theme/theme';
import ChartLegend from '../../../common/ChartLegend/ChartLegend';
import { AVERAGE_EMPLOYEE_FOOTPRINT } from '../../../../graphql/dashboard/emissions/averageEmployeeFootprint';
import {
  dateRangeFromEndDate,
  formatDateStringUtc,
} from '../../../../utils/dateUtils';
import NumberUtils from '../../../../utils/numberUtils';
import { FULL_Y_BAR_TOOLTIP_LAYERS } from '../../../common/ChartTooltips/FullYBarTooltipLayer';
import ChartLabel from '../../../common/ChartLabel/ChartLabel';
import TooltipContainer from '../../../common/ChartTooltips/TooltipContainer';
import { formatTooltip } from '../../../common/ChartTooltips/FormattedTooltip';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';
import { DashboardContext } from '../../../../contexts/DashboardContext';

// Example:
// {
// "enabledEmployees": 22,
// "month": "Dec, 2022",
// "Business Travel": 1.23,
// "Commuting": 2.22,
// "Work from Home": 1.11
// }
// Each data element to render in the chart (plus dynamically added properties)
type ChartDataElement = {
  enabledEmployees: number;
  month: string;
};

// GraphQL data element
type DataElement = {
  enabledEmployees: number;
  date: string;
  category6: number;
  category7: number;
  wfh: number;
};

const AverageEmployeeFootprint: React.FC = () => {
  const { companyId, includeChildren } = useCurrentCompany();
  const [category, setCategory] = useState('all');
  const { endDate } = useContext(DashboardContext);

  const KeyCategory6 = 'Business Travel';
  const KeyCategory7 = 'Commuting';
  const KeyWorkFromHome = 'Work from Home';

  // Average of all amounts
  let totalFootprintAmount = 0;

  // Dynamically assign a property to an object
  const setDynamicProperty = (
    obj: ChartDataElement,
    prop: string,
    value: number,
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = obj;
    data[prop] = value;

    if (value > 0) {
      totalFootprintAmount += value;
    }
  };

  const {
    data: {
      averageEmployeeFootprintByDateRange: { footprintsByRange = [] } = {},
    } = {},
    loading,
    error,
  } = useQuery(AVERAGE_EMPLOYEE_FOOTPRINT, {
    variables: {
      dateGrain: 'MONTHLY',
      companyId,
      includeChildren,
      ...dateRangeFromEndDate(endDate),
    },
  });

  // All data items
  const allDataItems: { key: string; color: string; label: string }[] = [];
  allDataItems.push({
    key: 'category6',
    color: chartColors.blue,
    label: KeyCategory6,
  });
  allDataItems.push({
    key: 'category7',
    color: chartColors.darkBlue,
    label: KeyCategory7,
  });
  allDataItems.push({
    key: 'wfh',
    color: chartColors.darkGreen,
    label: KeyWorkFromHome,
  });

  // Dynamic legend, keys, and colors based on chosen category
  const labels: string[] = [];
  const colors: string[] = [];
  const legendKeys: { color: string; label: string }[] = [];
  switch (category) {
    case 'all': {
      allDataItems.forEach((item) => {
        legendKeys.push({ color: item.color, label: item.label });
      });
      break;
    }
    default: {
      const item = allDataItems.find((f) => f.key === category);
      if (item) {
        legendKeys.push({ color: item.color, label: item.label });
      }
      break;
    }
  }

  // Populate the index-based labels and colors arrays (for the tooltips) from the legend details
  legendKeys.forEach((l) => {
    labels.push(l.label);
    colors.push(l.color);
  });

  // Render the items for the dropdown list
  const listMenuItems: JSX.Element[] = [];
  allDataItems.forEach((l) => {
    listMenuItems.push(
      <MenuItem key={l.key} value={l.key}>
        {l.label}
      </MenuItem>,
    );
  });

  // Build the chart data with dynamic properties based on the chosen category from the dropdown list
  const chartData = footprintsByRange.map((e: DataElement) => {
    const chartItem: ChartDataElement = {
      enabledEmployees: e.enabledEmployees,
      month: formatDateStringUtc(e.date, 'MMM, yyyy'),
    };

    switch (category) {
      case 'all': {
        setDynamicProperty(chartItem, KeyCategory6, e.category6 / 1000);
        setDynamicProperty(chartItem, KeyCategory7, e.category7 / 1000);
        setDynamicProperty(chartItem, KeyWorkFromHome, e.wfh / 1000);
        break;
      }
      case 'category6': {
        setDynamicProperty(chartItem, KeyCategory6, e.category6 / 1000);
        break;
      }
      case 'category7': {
        setDynamicProperty(chartItem, KeyCategory7, e.category7 / 1000);
        break;
      }
      case 'wfh': {
        setDynamicProperty(chartItem, KeyWorkFromHome, e.wfh / 1000);
        break;
      }
      default:
        break;
    }

    return chartItem;
  }) as ChartDataElement[];

  // Calculate the Average
  let avg = 0;
  if (chartData.length > 0) {
    avg = totalFootprintAmount / chartData.length;
  }

  // Get the tooltop content with employee count and totals
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderTooltipContents = (tooltipData: any) => {
    // Get the Category 6 (Business Travel), Category 7 (Commuting), and/or Work from Home values
    const nivoData = tooltipData.data as {
      [k: string]: string | number;
    };

    // Get the employee count total
    const monthName = tooltipData.indexValue; // The tooltip's indexValue contains the monthName, i.e. Aug, 2022
    const dataItem = chartData.find((val) => val.month === monthName);
    const employeeCount = NumberUtils.format(
      dataItem?.enabledEmployees,
      'integer',
    );

    let total = 0;
    const keysWithValues = Object.keys(nivoData);
    const renderTooltips = keysWithValues.map((k) => {
      const i = labels.indexOf(k);
      const color = colors[i];

      // Get the the numeric property dynamically by name
      const value = nivoData[k] as number;
      total += value;

      // Category 6, Category 7 data, and/or Work from Home elements
      return (
        <ChartLabel
          key={k}
          labelColor="#6B6B6B"
          color={color}
          label={k}
          value={formatTooltip(value, 'number')}
        />
      );
    });

    return (
      <TooltipContainer>
        <>
          <ChartLabel
            key="month"
            labelColor="#6B6B6B"
            color="#FFFFFF"
            value=""
            label={monthName}
          />
          <ChartLabel
            key="empCount"
            labelColor="#6B6B6B"
            color="#FFFFFF"
            value={employeeCount}
            label="Employees"
          />
          <Divider />
          {renderTooltips}
          <Divider />
          <ChartLabel
            key="total"
            labelColor="#6B6B6B"
            label="Total"
            value={`${formatTooltip(total, 'number')}`}
          />
        </>
      </TooltipContainer>
    );
  };

  return (
    <AppCard
      height={460}
      headerProps={{
        title: 'AVERAGE MEMBER FOOTPRINT - SCOPE 3',
        tooltipId: 'averageEmployeeFootprint',
        rightContent: (
          <FormControl variant="outlined">
            <SelectMenu
              id="category"
              value={category}
              inputStyle={{ background: 'white' }}
              onChange={(e) => setCategory(e.target.value)}>
              <MenuItem key="all" value="all">
                All Categories
              </MenuItem>
              {listMenuItems}
            </SelectMenu>
          </FormControl>
        ),
      }}>
      <DataStateHandler loading={loading} error={error}>
        <>
          <div style={{ height: 300 }}>
            <ResponsiveBar
              data={chartData}
              keys={labels}
              indexBy="month"
              margin={{ top: 20, right: 0, bottom: 55, left: 35 }}
              padding={0.8}
              innerPadding={1}
              groupMode="stacked"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={colors}
              axisTop={null}
              axisRight={null}
              gridYValues={4}
              axisBottom={{
                tickSize: 0,
                tickPadding: 24,
                tickRotation: 0,
              }}
              axisLeft={{
                tickValues: 4,
                tickSize: 0,
                tickPadding: 1,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -20,
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              layers={FULL_Y_BAR_TOOLTIP_LAYERS}
              tooltip={(props) => renderTooltipContents(props)}
              role="application"
              ariaLabel="Average Employee Footprint Chart"
            />
          </div>
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between">
            <Grid item>
              <ChartLegend keys={legendKeys} />
            </Grid>
            <Grid item>
              <CardFooter
                data={NumberUtils.format(avg, 'number')}
                label="Avg MT C0₂E per Month"
              />
            </Grid>
          </Grid>
        </>
      </DataStateHandler>
    </AppCard>
  );
};

export default AverageEmployeeFootprint;
