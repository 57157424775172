import { Box, Chip, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { findIndex } from 'lodash';
import React, { ComponentProps, useContext } from 'react';
import { Trans } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import HeaderTab from '../../common/HeaderTabs/HeaderTab';
import HeaderTabs from '../../common/HeaderTabs/HeaderTabs';

const useStyles = makeStyles(() => ({
  headerRow: {
    marginTop: 16,
    marginBottom: 28,
  },
}));

type Props = {
  draftProjectCount: number;
  pendingProjectCount: number;
};

const ProjectsHeader: React.FC<Props> = ({
  draftProjectCount,
  pendingProjectCount,
}) => {
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const { userHasAccess } = useContext(AuthorizationContext);
  const showDemoProjects = !!new URLSearchParams(location.search).get('demo');

  const canEdit = userHasAccess('ZeroMe.Marketplace', 'EDIT');

  const navigate = (newUrl: string, demoProjects: boolean) => {
    const params = new URLSearchParams(location.search);

    if (demoProjects) {
      params.set('demo', 'true');
    }

    history.push(`${newUrl}${demoProjects ? `?${params}` : ''}`);
  };

  const tabs: ComponentProps<typeof HeaderTab>[] = [
    {
      key: `${url}/list`,
      label: 'Projects',
      style: { minWidth: 50 },
    },
    ...(canEdit
      ? [
          {
            key: `${url}/drafts`,
            style: { minWidth: 130 },
            label: (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignContent="center">
                <Grid item>Drafts</Grid>
                <Grid item>
                  <Chip style={{ marginLeft: 8 }} label={draftProjectCount} />
                </Grid>
              </Grid>
            ),
          },
        ]
      : []),
    {
      key: `${url}/pending`,
      style: { minWidth: 204 },
      label: (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center">
          <Grid item>Credit Requests</Grid>
          <Grid item>
            <Chip style={{ marginLeft: 8 }} label={pendingProjectCount} />
          </Grid>
        </Grid>
      ),
    },
    {
      key: `${url}/history`,
      label: 'Purchase History',
      style: { minWidth: 160 },
    },
  ];

  const tabIndex = findIndex(tabs, { key: location.pathname });

  return (
    <Grid container item direction="column">
      <Grid container item direction="row" className={classes.headerRow}>
        <Grid container item alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h1">
              <Trans i18nKey="trader.projects.header" />
            </Typography>
          </Grid>
          {canEdit && (
            <Grid item>
              <Link
                to="/trader/create-project/new"
                style={{ textDecoration: 'none' }}>
                <SmallPaddedButton>
                  <Trans i18nKey="trader.projects.createNewProject" />
                </SmallPaddedButton>
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: '#A5B7BF',
          marginBottom: '24px',
        }}>
        <HeaderTabs value={tabIndex}>
          {tabs.map((tab) => (
            <HeaderTab
              {...tab}
              onClick={() => navigate(tab.key as string, showDemoProjects)}
            />
          ))}
        </HeaderTabs>
        <div style={{ position: 'absolute', top: 104, right: 64 }}>
          <Grid container direction="row">
            <Typography variant="body2" style={{ paddingTop: 8 }}>
              Show Demo Projects
            </Typography>
            <Switch
              checked={showDemoProjects}
              onChange={() =>
                navigate(tabs[tabIndex].key as string, !showDemoProjects)
              }
            />
          </Grid>
        </div>
      </Box>
    </Grid>
  );
};

export default ProjectsHeader;
