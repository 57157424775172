import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Typography,
  lighten,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../../assets/svg';

import { MarketplaceContext } from '../../../contexts/MarketplaceContext';
import ShoppingCartItemView from '../Project/ShoppingCartItem';
import CartValidationInfo from './CartValidationInfo';
import SmallPaddedButton from '../Buttons/SmallPaddedButton';
import LoadingBackdrop from '../LoadingBackdrop';

type Props = {
  onClose: () => void;
  askATrader: () => void;
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginRight: 8,
  },
  container: {
    maxWidth: 1338,
    minWidth: 500,
    padding: 32,
    height: '100%',
  },
  button: {
    width: '100%',
    marginTop: 32,
  },
  customColor: {
    marginTop: 32,
    color: theme.palette.divider,
  },
  footer: {
    backgroundColor: theme.palette.primary.light,
    marginTop: 'auto',
    padding: 32,
    borderRadius: '0px 0px 16px 16px',
  },
  success: {
    margin: 'auto',
  },
  section: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    padding: 16,
    marginTop: 16,
  },
  lineItem: {
    padding: 8,
    borderRadius: 4,
    margin: '4px 0',
  },
}));

const ShoppingCartContainer: React.FC<Props> = ({
  onClose: closeHandler,
  askATrader,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { cart, updateCart, purchaseCart, cartLoading, creditBalance } =
    useContext(MarketplaceContext);
  const [allowInvoice, setAllowInvoice] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [checkoutError, setCheckoutError] = useState(false);
  const [invalidProjects, setInvalidProjects] = useState<string[]>([]);

  const cartLength = cart.items.length;
  const isAnyCartItemEmpty = cart.items.some((c) => c.quantity < 1);

  const subTotal = cart.items.reduce(
    (partialSum, a) => partialSum + (a.project.sellingPrice || 0) * a.quantity,
    0,
  );
  const remainingBalance = creditBalance - subTotal;

  const requestDisabled =
    cartLength <= 0 ||
    isAnyCartItemEmpty ||
    !!invalidProjects.length ||
    (remainingBalance < 0 && !allowInvoice);

  return (
    <>
      <LoadingBackdrop open={cartLoading} />
      <Grid
        container
        direction="column"
        className={classes.container}
        wrap="nowrap">
        {requestSent ? (
          <Grid
            container
            direction="column"
            alignContent="center"
            alignItems="center"
            className={classes.success}>
            <Grid item>
              <Typography variant="h1" align="center" color="textPrimary">
                Success! Your request to purchase has been sent.
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                align="center"
                className={classes.customColor}>
                Once your request has been processed, you will receive an email
                confirmation with instructions to view your updated inventory.
                When the offset has reached your inventory, you will receive an
                invoice for the amount due.
              </Typography>
            </Grid>
            <Link href="/" underline="none" style={{ width: '100%' }}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                fullWidth>
                Back to My Dashboard
              </Button>
            </Link>
          </Grid>
        ) : (
          <>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item className={classes.buttonContainer}>
                  <IconButton
                    edge="start"
                    color="default"
                    onClick={closeHandler}
                    aria-label="close"
                    size="large">
                    <SVG.Back />
                  </IconButton>
                </Grid>
                <Grid item>
                  <SVG.Marketplace.CartEmpty
                    style={{
                      color: theme.palette.text.primary,
                      display: 'inline-block',
                      position: 'relative',
                      left: -12,
                      top: 1,
                    }}
                  />
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    style={{ display: 'inline-block' }}>
                    My Cart {cartLength > 0 ? `(${cartLength})` : ' '}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ color: '#DADADA' }} />
            <Grid item>
              <CartValidationInfo />
            </Grid>
            <Grid
              item
              container
              flexDirection="row"
              justifyContent="space-between">
              {!!cartLength && (
                <Grid item style={{ width: '55%' }}>
                  <Grid
                    container
                    flexDirection="column"
                    className={classes.section}>
                    <Grid item pb={3}>
                      <Typography variant="h3" color="#6B6B6B">
                        Items
                      </Typography>
                    </Grid>
                    <Grid item>
                      <List>
                        {cart.items.map((item) => (
                          <ListItem key={item.project.id} disableGutters>
                            <ShoppingCartItemView
                              item={item}
                              updateValid={(valid) => {
                                if (valid) {
                                  setInvalidProjects(
                                    invalidProjects.filter(
                                      (x) => x !== item.project.id,
                                    ),
                                  );
                                } else {
                                  setInvalidProjects([
                                    ...invalidProjects,
                                    item.project.id,
                                  ]);
                                }
                              }}
                              updateTotal={(value, id, remove) =>
                                updateCart(
                                  id,
                                  value,
                                  remove ? 'REMOVE' : 'UPDATE',
                                )
                              }
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Grid item>
                      {cartLength > 0 && (
                        <Typography
                          variant="body2"
                          className={classes.customColor}>
                          *Please note, these project credits are not available
                          for direct purchase. A request will be sent to an
                          Authorized Purchaser and you will receive a
                          confirmation when they have been added to your
                          inventory.
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item style={{ width: cartLength ? '42%' : '100%' }}>
                <Grid
                  container
                  flexDirection="column"
                  className={classes.section}>
                  <Grid item pb={3}>
                    <Typography variant="h3" color="#6B6B6B">
                      Order Summary
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    className={classes.lineItem}
                    style={{
                      border: `1px solid ${theme.palette.secondary.light}`,
                      backgroundColor: lighten(
                        theme.palette.secondary.light,
                        0.8,
                      ),
                    }}>
                    <Typography variant="body1">Available Credits</Typography>
                    <Typography variant="body1">
                      {creditBalance.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    className={classes.lineItem}>
                    <Typography variant="h3">Items Subtotal</Typography>
                    <Typography variant="h3">
                      {subTotal.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.lineItem}>
                    <Divider />
                  </Grid>
                  <Grid
                    item
                    container
                    flexDirection="row"
                    justifyContent="space-between"
                    className={classes.lineItem}>
                    <Typography variant="body1">
                      Remaining Credit Balance
                    </Typography>
                    <Typography variant="body1">
                      {remainingBalance.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      })}
                    </Typography>
                  </Grid>
                  {remainingBalance < 0 && (
                    <>
                      <Grid item className={classes.lineItem}>
                        <Typography variant="body2" color="error">
                          You currently do not have enough credits for your
                          requested purchase. Please adjust the quantity of your
                          items or check the box aggreeing to be invoiced for
                          the remaining balance.
                        </Typography>
                      </Grid>
                      <Grid item className={classes.lineItem}>
                        <Grid container flexDirection="row">
                          <Grid
                            item
                            style={{
                              position: 'relative',
                              left: -10,
                              top: -5,
                            }}>
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={allowInvoice}
                              onClick={() => setAllowInvoice(!allowInvoice)}
                            />
                          </Grid>
                          <Grid item style={{ width: '92%' }}>
                            <Typography variant="body1">
                              I understand that I will be responsible for the
                              remaining balance of this request.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item className={classes.lineItem} mt={4}>
                    <Button
                      disabled={requestDisabled}
                      onClick={async () => {
                        const result = await purchaseCart();
                        if (result === 'SUCCESS') {
                          setRequestSent(true);
                        }

                        if (result === 'GENERAL_ERROR') {
                          setCheckoutError(true);
                        }
                      }}
                      variant="contained"
                      color="primary"
                      fullWidth>
                      Request to Purchase
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.section}
                  style={{
                    backgroundColor: theme.palette.primary.light,
                    height: 175,
                  }}>
                  <Typography variant="h3">
                    Questions about a project?
                  </Typography>
                  <Button variant="text" size="small" onClick={askATrader}>
                    Contact the Trader
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Dialog open={checkoutError}>
              <Box style={{ padding: 16 }}>
                <Grid item>
                  <Typography variant="h2">
                    There was an error submitting your request to purchase.
                    Please try again or contact support.
                  </Typography>
                </Grid>
                <Grid item style={{ paddingTop: 24 }}>
                  <Box textAlign="center">
                    <SmallPaddedButton
                      style={{ margin: 'auto' }}
                      onClick={() => setCheckoutError(false)}>
                      OK
                    </SmallPaddedButton>
                  </Box>
                </Grid>
              </Box>
            </Dialog>
          </>
        )}
      </Grid>
    </>
  );
};

export default ShoppingCartContainer;
