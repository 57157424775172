import leaderboardCompanyFirstDisabled from './LeaderboardCompanyFirstDisabled';
import leaderboardCompanyFirstEnabled from './LeaderboardCompanyFirstEnabled';

const leaderboardCompanyPlacementFirst = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? leaderboardCompanyFirstDisabled(level, size)
    : leaderboardCompanyFirstEnabled(level, size);

export default leaderboardCompanyPlacementFirst;
