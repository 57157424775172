import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { EditorState, LexicalEditor } from 'lexical';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { AutoLinkNode } from '@lexical/link';
import { HeadingNode } from '@lexical/rich-text';
import { Colors } from '../../../theme/shared';
import { ImportPlugin, ToolbarPlugin, ImagePlugin, ImageNode } from './plugins';
import { MATCHERS } from './constants';
import { getHtmlWithInlineStyles } from '../../../utils/styleUtils';
import { AppRichTextFieldProps } from './types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 8,
    width: '100%',
  },
  label: {
    position: 'relative',
    bottom: 8,
  },
  editTextBox: {
    minHeight: 350,
    borderRadius: 8,
    border: `2px solid ${Colors.CobaltBlue}`,
    width: '100%',
  },
  displayTextBox: {
    overflowWrap: 'break-word',
    overflowX: 'auto',
    padding: theme.spacing(1),
  },
  editInput: {
    minHeight: 150,
    resize: 'none',
    position: 'relative',
    tabSize: 1,
    outline: 0,
  },
  editorBase: ({
    color,
    backgroundColor,
  }: {
    color: string;
    backgroundColor: string;
  }) => ({
    color,
    backgroundColor,
    fontFamily: 'Arial',
  }),
  editorItalic: {
    fontStyle: 'italic',
  },
  editorBold: {
    fontWeight: 'bold',
  },
  editorUnderline: {
    textDecoration: 'underline',
  },
  editorStrikethrough: {
    textDecoration: 'line-through',
  },
  editorUnderlineStrikethrough: {
    textDecoration: 'underline line-through',
  },
  h1: theme.typography.h1,
  h2: theme.typography.h2,
  h3: theme.typography.h3,
  h4: theme.typography.h4,
  h5: theme.typography.h5,
  '@global': {
    '.ql-container.ql-snow, .ql-toolbar.ql-snow': {
      border: '0 !important',
    },
  },
  ol1: {
    fontFamily: 'Arial',
    listStylePosition: 'outside',
  },
  ol2: {
    fontFamily: 'Arial',
    listStylePosition: 'outside',
    listStyleType: 'upper-alpha',
  },
  ol3: {
    fontFamily: 'Arial',
    listStyleType: 'lower-alpha',
    listStylePosition: 'outside',
  },
  ol4: {
    fontFamily: 'Arial',
    listStyleType: 'upper-roman',
    listStylePosition: 'outside',
  },
  ol5: {
    fontFamily: 'Arial',
    listStyleType: 'lower-roman',
    listStylePosition: 'outside',
  },
  ul: {
    fontFamily: 'Arial',
    listStylePosition: 'outside',
  },
  nestedListItem: {
    listStyleType: 'none',
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
}));

// eslint-disable-next-line import/prefer-default-export
export const EmailRichTextField = ({
  labelText,
  disabled,
  editorStateFieldName,
  field,
  form,
}: AppRichTextFieldProps) => {
  const editorId = `rich-text-editor-${field.name}`;
  const theme = useTheme();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const fontColor = theme.palette.text.primary as `#${string}`;
  const backgroundColor = theme.palette.backgrounds.white as `#${string}`;

  const classes = useStyles({ color: fontColor, backgroundColor });

  const initialState = form.values[editorStateFieldName];
  const initialContent = field.value;

  const initialConfig: React.ComponentProps<
    typeof LexicalComposer
  >['initialConfig'] = {
    namespace: 'app-rich-text-field',
    onError: (error) => {
      throw error;
    },
    theme: {
      list: {
        nested: {
          listitem: classes.nestedListItem,
        },
        olDepth: [
          classes.ol1,
          classes.ol2,
          classes.ol3,
          classes.ol4,
          classes.ol5,
        ],
        ul: classes.ul,
      },
      heading: {
        h1: classes.h1,
        h2: classes.h2,
        h3: classes.h3,
        h4: classes.h4,
        h5: classes.h5,
      },
      text: {
        base: classes.editorBase,
        bold: classes.editorBold,
        italic: classes.editorItalic,
        underline: classes.editorUnderline,
        strikethrough: classes.editorStrikethrough,
        underlineStrikethrough: classes.editorUnderlineStrikethrough,
      },
    },
    nodes: [AutoLinkNode, HeadingNode, ListItemNode, ListNode, ImageNode],
  };

  const handleOnChange = (_editorState: EditorState, editor: LexicalEditor) => {
    editor.update(() => {
      if (editorStateFieldName) {
        const htmlString = editor.getEditorState().toJSON();
        form.setFieldValue(editorStateFieldName, JSON.stringify(htmlString));
      }

      // Debounce processing the form field update since it is an expensive operation
      // and can cause noticeable lag when typing. With a 1 sec delay we could technically
      // lose some of the last typed characters if someone try's to save very quickly,
      // but it's a tradeoff for performance.
      const timeout = setTimeout(async () => {
        const doc = document.getElementById(editorId);
        const response = await getHtmlWithInlineStyles(doc as HTMLElement);
        form.setFieldValue(field.name, response);
        setTimeoutId(undefined);
      }, 1000);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(timeout);
    });
  };

  if (disabled) {
    return (
      <Box className={classes.root}>
        <div className={classes.editTextBox}>
          <div className={classes.displayTextBox}>
            <div className={classes.editInput}>
              {field.value ? (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: field.value ?? '' }} />
              ) : null}
            </div>
          </div>
        </div>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="body2">
        {labelText}
      </Typography>
      <LexicalComposer initialConfig={initialConfig}>
        <div className={classes.editTextBox}>
          <ToolbarPlugin
            textColor={fontColor}
            textBackgroundColor={backgroundColor}
          />
          <div className={classes.displayTextBox} id={editorId}>
            <ImportPlugin
              initialState={initialState}
              initialContent={initialContent}
            />
            <RichTextPlugin
              contentEditable={
                <ContentEditable className={classes.editInput} />
              }
              placeholder={<span />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin onChange={handleOnChange} />
            <ImagePlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <TabIndentationPlugin />
            <AutoLinkPlugin matchers={MATCHERS} />
          </div>
        </div>
      </LexicalComposer>
    </Box>
  );
};
