import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import GroupableSelectTable from './GroupableSelectTable';
import { SelectTableProps } from './SelectTable';
import { ExportableGroupableTableStructure, SelectTableRowId } from './types';
import SVG from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.text.primary,
  },
  dialogExportButton: {
    width: '100%',
  },
  dialogContent: {
    margin: 8,
    padding: 0,
  },
  csvLink: {
    width: '100%',
    cursor: 'auto',
    textDecoration: 'auto',
  },
}));

type ExposedSelectTableProps<TData extends SelectTableRowId> = Pick<
  SelectTableProps<TData>,
  'initialSelected' | 'onSelected'
>;

// Default Export button appearance
const DefaultExportButton: JSX.Element = (
  <>
    <SVG.FileExport style={{ marginBottom: -3 }} /> Export
  </>
);

type Props<TData extends SelectTableRowId> = {
  /** The pluralized description of content */
  contentPlural?: string;
  /** The filename to use for export */
  filename?: string;
  tableStructure: ExportableGroupableTableStructure<TData>[];
  tableData: TData[];

  /**
   * Custom action items for other import/export buttons.
   */
  importExportActionItems?: React.ReactNode;

  /**
   * Markup that represents the Export table button.
   */
  exportButtonUI?: JSX.Element;
} & ExposedSelectTableProps<TData>;

const ExportDialog = <T extends SelectTableRowId>({
  contentPlural,
  filename,
  tableStructure,
  tableData,
  initialSelected = tableData,
  onSelected,
  importExportActionItems,
  exportButtonUI,
}: Props<T>) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  // If the Export button appearance/markup is not defined, use the default
  const exportButton = exportButtonUI ?? DefaultExportButton;

  const isRowSelected = ({ id }: T) =>
    initialSelected &&
    initialSelected.findIndex(({ id: selectedId }) => id === selectedId) > -1;

  const processRow = (data: T) =>
    tableStructure.reduce(
      (exportGroupData, group) => ({
        ...exportGroupData,
        ...group.columns.reduce((exportColumnData, column) => {
          const columnData = (
            data as unknown as Record<string, typeof data[keyof typeof data]>
          )[column.key];

          if (column.export !== false) {
            const value: string | number =
              column.export?.format?.(data) ??
              (column.render?.(data) as string) ??
              column.format?.(columnData) ??
              columnData ??
              '';

            return {
              ...exportColumnData,
              [column.export?.heading ?? column.display ?? column.key]: value,
            };
          }
          return exportColumnData;
        }, {}),
      }),
      {},
    );

  const exportData = tableData.filter(isRowSelected).map(processRow);

  const previewDataStructure = [
    {
      key: 'preview',
      columns: tableStructure.flatMap(({ columns }) =>
        columns
          .filter(({ export: e }) => e && e?.preview)
          .map((column) => ({
            ...column,
            type: undefined,
            stickyLeft: undefined,
          })),
      ),
    },
  ] as ExportableGroupableTableStructure<T>[];

  return (
    <>
      {importExportActionItems}
      <SVG.Divider style={{ marginBottom: -7 }} />
      <Button variant="text" onClick={openDialog}>
        <Typography variant="body2">{exportButton}</Typography>
      </Button>
      <Dialog open={open} onClose={closeDialog} maxWidth="md">
        <DialogTitle>
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <Typography variant="h1">
                Export Selected {contentPlural}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {initialSelected?.length || 0} {contentPlural} Selected
              </Typography>
            </Grid>
          </Grid>
          <IconButton
            onClick={closeDialog}
            className={classes.closeIcon}
            size="large">
            <SVG.Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <GroupableSelectTable
            tableData={tableData}
            tableStructure={previewDataStructure}
            initialSelected={initialSelected}
            onSelected={onSelected}
          />
        </DialogContent>
        <DialogActions>
          <CSVLink
            data={exportData}
            filename={filename}
            className={classes.csvLink}
            onClick={() => !!exportData.length}>
            <Button
              variant="contained"
              color="primary"
              className={classes.dialogExportButton}
              disabled={!exportData.length}>
              Export
            </Button>
          </CSVLink>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExportDialog;
