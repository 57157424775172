import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ComponentProps, useContext } from 'react';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { OffsetContext } from '../../../contexts/OffsetContext';
import NumberUtils from '../../../utils/numberUtils';
import ProjectPurchaseTable from '../../common/ProjectPurchase/ProjectPurchaseTable';
import OffsetCard from '../cards/OffsetCard';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  tableGrid: {
    height: '100%',
  },
});

const MyOffsetInventory = () => {
  const classes = useStyles();
  const { projectPurchases } = useContext(OffsetContext);
  const { userHasAccess, company } = useContext(AuthorizationContext);

  // #reagion Metrics
  const tableData = projectPurchases?.map(
    ({ allocated, remaining, quantity, ...rest }) => ({
      ...rest,
      quantity,
      allocated,
      remaining,
      totalPurchased: quantity,
      totalSpent: rest.price * quantity,
    }),
  ) as ComponentProps<typeof ProjectPurchaseTable>['tableData'];

  const metrics = (tableData || []).reduce(
    (
      { price: prevPrice, allocated: prevAllocated, remaining: prevRemaining },
      {
        price,
        allocated: currAllocated,
        remaining: currRemaining,
        totalPurchased,
      },
    ) => ({
      price: prevPrice + (price * totalPurchased || 0),
      allocated: prevAllocated + (currAllocated || 0),
      remaining: prevRemaining + (currRemaining || 0),
    }),
    { price: 0, allocated: 0, remaining: 0 },
  );

  const { price: totalSpent = 0, allocated = 0, remaining = 0 } = metrics;

  const showOffsets = userHasAccess('Client.Offset', 'VIEW');

  // #endregion

  return (
    <Grid
      container
      direction="column"
      spacing={3}
      wrap="nowrap"
      className={classes.root}>
      <Grid item container spacing={2} alignItems="stretch">
        <Grid item xs={4}>
          <OffsetCard
            title="Total Purchased"
            values={[
              {
                value: NumberUtils.format(totalSpent, 'currency'),
              },
              {
                value: allocated + remaining,
                label: 'MT',
              },
            ]}
            loading={!projectPurchases}
          />
        </Grid>
        <Grid item xs={4}>
          <OffsetCard
            title="Member Redemption"
            values={[
              {
                value: company.redeemedCredits || 0,
                label: 'MT',
              },
            ]}
            loading={!projectPurchases}
          />
        </Grid>
        {showOffsets && (
          <Grid item xs={4}>
            <OffsetCard
              title="Offset Allocations"
              values={[
                {
                  value: allocated,
                  label: 'Allocated',
                },
                {
                  value: remaining,
                  label: 'Remaining',
                },
              ]}
              loading={!projectPurchases}
            />
          </Grid>
        )}
      </Grid>

      <Grid item className={classes.tableGrid}>
        <ProjectPurchaseTable tableData={tableData} />
      </Grid>
    </Grid>
  );
};

export default MyOffsetInventory;
