import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router';
import MemberNavigation from '../member/MemberNavigation';
import MemberFooter from '../member/MemberFooter';

type Props = {
  children: JSX.Element;
  styleOverrides?: React.CSSProperties;
};

const MemberLayout: React.FC<Props> = ({ children, styleOverrides }) => {
  const location = useLocation();

  const showNav =
    !location.pathname.toLowerCase().includes('/member/onboarding') &&
    !location.pathname.toLowerCase().includes('/terms') &&
    !location.pathname.toLowerCase().includes('/privacy');

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight="100vh"
      style={styleOverrides}>
      <Box
        p={4}
        mx={4}
        pb={4}
        maxWidth="xl"
        width="100%"
        margin="0 auto"
        display="flex"
        flexDirection="column">
        {showNav && <MemberNavigation />}
        <Box flex={2} mt={4}>
          {children}
        </Box>
      </Box>
      <MemberFooter />
    </Box>
  );
};

export default MemberLayout;
