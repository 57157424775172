import { useContext } from 'react';
import { useTheme } from '@mui/material';
import { ApolloError, useQuery } from '@apollo/client';
import { t } from 'i18next';
import moment from 'moment';

import { AuthorizationContext } from '../contexts/AuthorizationContext';
import {
  EMISSIONS_BENCHMARKS,
  GET_COMPANY_EMISSIONS,
} from '../graphql/dashboard/emissions/emissions';
import { GET_PROJECTED_EMISSIONS } from '../graphql/user/user';
import { Colors } from '../theme/shared';
import { AvgEmissionBreakdown } from '../types/offsetProject/types';
import {
  EmissionsBenchmarkCategoryType,
  EmissionsBenchmarkChartData,
} from '../types/user/types';
import { KGCO2ETOMTCO2E, roundMetric } from '../utils/emissionUtils';

const useEmissionsBenchmarkData = (): {
  benchmarks: {
    totalGeographical: {
      user?: number;
      global?: number;
      country?: number;
      region?: number;
      postal?: number;
    };
    totalOrganizational: {
      user?: number;
      company?: number;
      division?: number;
    };
    categoryBreakdown: Record<
      EmissionsBenchmarkCategoryType,
      {
        user?: number;
        global?: number;
        country?: number;
        region?: number;
        postal?: number;
        company?: number;
        division?: number;
      }
    >;
  };
  chartData: {
    totalGeographical: {
      label: string;
      data: EmissionsBenchmarkChartData[];
    };
    totalOrganizational: {
      label: string;
      data: EmissionsBenchmarkChartData[];
    };
    categoryBreakdown: Record<
      EmissionsBenchmarkCategoryType,
      {
        label: string;
        data: EmissionsBenchmarkChartData[];
      }
    >;
  };
  errors?: ApolloError[];
  loading: boolean;
} => {
  const theme = useTheme();
  const { user, company: userCompany } = useContext(AuthorizationContext);
  const isSingleCompany =
    !userCompany.parentCompanyId && !userCompany.childCompanyIds?.length;

  const companyEmissionsVariables = {
    companyId: userCompany.id,
    startYYYYMM: parseInt(moment().format('yyyyMM'), 10),
    endYYYYMM: parseInt(moment().format('yyyyMM'), 10),
  };

  const {
    data: { projectedEmissions: userEmissionsData } = {},
    loading: loadingUserEmissions,
    error: errorUserEmissions,
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: userCompany.id,
    },
  });

  const {
    loading: loadingGlobal,
    error: errorGlobal,
    data: {
      averageCarbonFootprintForGlobe: {
        total: globalAvgTotalEmissions = undefined,
        categoryBreakdown: globalAvgEmissionBreakdown = [],
      } = {},
      averageCarbonFootprintForCountry: {
        total: countryAvgTotalEmissions = undefined,
        categoryBreakdown: countryAvgEmissionBreakdown = [],
      } = {},
    } = {},
  } = useQuery(EMISSIONS_BENCHMARKS, {
    variables: {
      country: user?.country,
    },
  });

  const {
    data: {
      emissionsCalculations: {
        avgAnnualEmissions: {
          avgTotalEmissions: companyAvgTotalEmissions = undefined,
          avgEmissionBreakdown: companyAvgEmissionBreakdown = [],
        } = {},
      } = {},
    } = {},
    error: errorCompany,
    loading: loadingCompany,
  } = useQuery(GET_COMPANY_EMISSIONS, {
    variables: {
      ...companyEmissionsVariables,
      includeFamilyTree: true,
    },
  });

  const {
    data: {
      emissionsCalculations: {
        avgAnnualEmissions: {
          avgTotalEmissions: divisionAvgTotalEmissions = undefined,
          avgEmissionBreakdown: divisionAvgEmissionBreakdown = [],
        } = {},
      } = {},
    } = {},
    error: errorDivision,
    loading: loadingDivision,
  } = useQuery(GET_COMPANY_EMISSIONS, {
    variables: companyEmissionsVariables,
    skip: isSingleCompany,
  });

  const {
    data: {
      emissionsCalculations: {
        avgAnnualEmissions: {
          avgTotalEmissions: regionAvgTotalEmissions = undefined,
          avgEmissionBreakdown: regionAvgEmissionBreakdown = [],
        } = {},
      } = {},
    } = {},
    error: errorRegion,
    loading: loadingRegion,
  } = useQuery(GET_COMPANY_EMISSIONS, {
    variables: {
      ...companyEmissionsVariables,
      countryCode: user?.country,
      regionCode: user?.region,
    },
    skip: !user?.country || !user?.region,
  });

  const {
    data: {
      emissionsCalculations: {
        avgAnnualEmissions: {
          avgTotalEmissions: postalAvgTotalEmissions = undefined,
          avgEmissionBreakdown: postalAvgEmissionBreakdown = [],
        } = {},
      } = {},
    } = {},
    error: errorPostal,
    loading: loadingPostal,
  } = useQuery(GET_COMPANY_EMISSIONS, {
    variables: {
      ...companyEmissionsVariables,
      countryCode: user?.country,
      regionCode: user?.region,
      postalCode: user?.postalCode,
    },
    skip: !user?.country || !user?.region || !user?.postalCode,
  });

  const getCategoryBenchmarkValue = (
    data: AvgEmissionBreakdown[],
    type: EmissionsBenchmarkCategoryType,
  ) =>
    KGCO2ETOMTCO2E(
      data.find((d) => d.type === type && d.calculationType === 'emission')
        ?.avgEmissions,
    );

  const getCategoryBenchmark = (type: EmissionsBenchmarkCategoryType) => ({
    user: KGCO2ETOMTCO2E(
      userEmissionsData?.categories.find((ued) => ued.type === type)?.emissions,
    ),
    global: roundMetric(
      globalAvgEmissionBreakdown.find((eb) => eb.type === type)?.emissions,
    ),
    country: roundMetric(
      countryAvgEmissionBreakdown.find((eb) => eb.type === type)?.emissions,
    ),
    region: getCategoryBenchmarkValue(regionAvgEmissionBreakdown, type),
    postal: getCategoryBenchmarkValue(postalAvgEmissionBreakdown, type),
    ...(isSingleCompany && {
      company: getCategoryBenchmarkValue(companyAvgEmissionBreakdown, type),
    }),
    division: getCategoryBenchmarkValue(divisionAvgEmissionBreakdown, type),
  });

  const benchmarks = {
    totalGeographical: {
      user: KGCO2ETOMTCO2E(userEmissionsData?.currentFootprint),
      global: roundMetric(globalAvgTotalEmissions),
      country: roundMetric(countryAvgTotalEmissions),
      region: KGCO2ETOMTCO2E(regionAvgTotalEmissions),
      postal: KGCO2ETOMTCO2E(postalAvgTotalEmissions),
    },
    totalOrganizational: {
      user: KGCO2ETOMTCO2E(userEmissionsData?.currentFootprint),
      company: KGCO2ETOMTCO2E(companyAvgTotalEmissions),
      division: KGCO2ETOMTCO2E(divisionAvgTotalEmissions),
    },
    categoryBreakdown: {
      diet: getCategoryBenchmark('diet'),
      home: getCategoryBenchmark('home'),
      transportation: getCategoryBenchmark('transportation'),
      shopping: getCategoryBenchmark('shopping'),
    },
  };

  const getChartData = ({
    userFootprint,
    global,
    country,
    region,
    postal,
    division,
    company,
  }: {
    userFootprint?: number;
    global?: number;
    country?: number;
    region?: number;
    postal?: number;
    division?: number;
    company?: number;
  }) => {
    const response: EmissionsBenchmarkChartData[] = [];

    if (userFootprint != null) {
      response.push({
        x: 'user',
        y: userFootprint,
        legendLabel: t('benchmarking.myFootprint'),
        fill: Colors.ElectricGreen,
      });
    }

    if (global != null) {
      response.push({
        x: 'global',
        y: global,
        legendLabel: t('benchmarking.global'),
        fill: Colors.CobaltBlue,
      });
    }

    if (company != null) {
      response.push({
        x: 'company',
        y: company,
        legendLabel: t('myCompany'),
        fill: Colors.CobaltBlue,
      });
    }

    if (division != null) {
      response.push({
        x: 'division',
        y: division,
        legendLabel: t('myDivision'),
        fill: theme.palette.secondary.main,
      });
    }

    if (country != null && user?.country) {
      response.push({
        x: 'country',
        y: country,
        legendLabel: user.country,
        fill: '#4C7AFA',
      });
    }

    if (region != null && user?.region) {
      response.push({
        x: 'region',
        y: region,
        legendLabel: user.region,
        fill: '#6C92FB',
      });
    }

    if (postal != null && user?.postalCode) {
      response.push({
        x: 'postal',
        y: postal,
        legendLabel: user.postalCode,
        fill: '#8BA9FC',
      });
    }

    return response;
  };

  const chartData = {
    totalGeographical: {
      label: t('benchmarking.geographicalBenchmarks'),
      data: getChartData({
        userFootprint: benchmarks.totalGeographical.user,
        global: benchmarks.totalGeographical.global,
        country: benchmarks.totalGeographical.country,
        region: benchmarks.totalGeographical.region,
        postal: benchmarks.totalGeographical.postal,
      }),
    },
    totalOrganizational: {
      label: t('benchmarking.organizationalBenchmarks'),
      data: getChartData({
        userFootprint: benchmarks.totalOrganizational.user,
        company: benchmarks.totalOrganizational.company,
        division: benchmarks.totalOrganizational.division,
      }),
    },
    categoryBreakdown: {
      diet: {
        label: t('benchmarking.foodBenchmarks'),
        data: getChartData({
          userFootprint: benchmarks.categoryBreakdown.diet.user,
          global: benchmarks.categoryBreakdown.diet.global,
          company: benchmarks.categoryBreakdown.diet.company,
          division: benchmarks.categoryBreakdown.diet.division,
          country: benchmarks.categoryBreakdown.diet.country,
          region: benchmarks.categoryBreakdown.diet.region,
          postal: benchmarks.categoryBreakdown.diet.postal,
        }),
      },
      home: {
        label: t('benchmarking.residentialBenchmarks'),
        data: getChartData({
          userFootprint: benchmarks.categoryBreakdown.home.user,
          global: benchmarks.categoryBreakdown.home.global,
          company: benchmarks.categoryBreakdown.home.company,
          division: benchmarks.categoryBreakdown.home.division,
          country: benchmarks.categoryBreakdown.home.country,
          region: benchmarks.categoryBreakdown.home.region,
          postal: benchmarks.categoryBreakdown.home.postal,
        }),
      },
      transportation: {
        label: t('benchmarking.transportationBenchmarks'),
        data: getChartData({
          userFootprint: benchmarks.categoryBreakdown.transportation.user,
          global: benchmarks.categoryBreakdown.transportation.global,
          company: benchmarks.categoryBreakdown.transportation.company,
          division: benchmarks.categoryBreakdown.transportation.division,
          country: benchmarks.categoryBreakdown.transportation.country,
          region: benchmarks.categoryBreakdown.transportation.region,
          postal: benchmarks.categoryBreakdown.transportation.postal,
        }),
      },
      shopping: {
        label: t('benchmarking.shoppingBenchmarks'),
        data: getChartData({
          userFootprint: benchmarks.categoryBreakdown.shopping.user,
          global: benchmarks.categoryBreakdown.shopping.global,
          company: benchmarks.categoryBreakdown.shopping.company,
          division: benchmarks.categoryBreakdown.shopping.division,
          country: benchmarks.categoryBreakdown.shopping.country,
          region: benchmarks.categoryBreakdown.shopping.region,
          postal: benchmarks.categoryBreakdown.shopping.postal,
        }),
      },
    },
  };

  return {
    benchmarks,
    chartData,
    errors: [
      errorUserEmissions,
      errorGlobal,
      errorCompany,
      errorDivision,
      errorRegion,
      errorPostal,
    ].filter(Boolean) as ApolloError[],
    loading:
      loadingUserEmissions ||
      loadingGlobal ||
      loadingCompany ||
      loadingDivision ||
      loadingRegion ||
      loadingPostal,
  };
};

export default useEmissionsBenchmarkData;
