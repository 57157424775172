import React from 'react';
import { useQuery } from '@apollo/client';
import { ALL_ROLES } from '../../../graphql/auth';
import DataStateHandler from '../DataStateHandler/DataStateHandler';

type Props = {
  roleId: string | undefined;
};

const RoleLabel: React.FC<Props> = ({ roleId }) => {
  const { loading, data: { allRolesAndAbilities } = {} } = useQuery(ALL_ROLES);

  const role = allRolesAndAbilities?.roles.find((x) => x.id === roleId);

  return (
    <DataStateHandler loading={loading} loadingPaddingY={0}>
      <span>{role?.name || roleId}</span>
    </DataStateHandler>
  );
};

export default RoleLabel;
