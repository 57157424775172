import { challengeSecondDisabled } from './ChallengeSecondDisabled';
import { challengeSecondEnabled } from './ChallengeSecondEnabled';

// eslint-disable-next-line import/prefer-default-export
export const challengePlacementSecond = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? challengeSecondDisabled(level, size)
    : challengeSecondEnabled(level, size);
