import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Grid, Tab, Tabs } from '@mui/material';
import { isEqual } from 'lodash';
import {
  GET_USER_PROJECT_CATEGORY_IDS,
  UPDATE_USER_PROJECT_CATEGORIES,
} from '../../../graphql/projects/projectCategories';
import MemberCategoryCards from '../project/MemberCategoryCards';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import useDebounce from '../../../hooks/useDebounce';

const SustainabilityInterests = () => {
  const [tab, setTab] = useState('all');
  const [categoryIds, setCategoryIds] = useState<string[]>();

  const {
    loading: userCategoriesLoading,
    error: userCategoriesError,
    data: { me } = {},
  } = useQuery(GET_USER_PROJECT_CATEGORY_IDS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!categoryIds && me) {
      setCategoryIds(me?.categoryIds || []);
    }
  }, [me?.categoryIds]);

  const [updateCategories] = useMutation(UPDATE_USER_PROJECT_CATEGORIES);

  const addCategory = (categoryId: string) => {
    if (categoryIds) {
      const newCategoryIds = new Set<string>(categoryIds);
      newCategoryIds.add(categoryId);
      setCategoryIds([...newCategoryIds]);
    }
  };

  const removeCategory = (categoryId: string) => {
    if (categoryIds) {
      const newCategoryIds = categoryIds.filter((x) => x !== categoryId);
      setCategoryIds(newCategoryIds);
    }
  };

  const debouncedCategories = useDebounce(categoryIds, 500);

  useEffect(() => {
    if (
      debouncedCategories &&
      !isEqual(me?.categoryIds || [], debouncedCategories)
    ) {
      updateCategories({
        variables: {
          categoryIds: debouncedCategories,
        },
      });
    }
  }, [debouncedCategories]);

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <Tabs value={tab} onChange={(e, v) => setTab(v)}>
          <Tab label="Sustainability Interests" value="all" />
          <Tab label="My Selections" value="selections" />
        </Tabs>
      </Grid>
      <DataStateHandler
        loading={userCategoriesLoading}
        error={userCategoriesError}>
        <Grid item container flexDirection="row" pt={3}>
          {tab === 'all' && (
            <MemberCategoryCards
              categoryIds={categoryIds}
              categoryIdsMode="REMOVE"
              onClick={addCategory}
              clickIcon="plus"
              noCategoriesText="No unselected sustainability interests available."
            />
          )}
          {tab === 'selections' && (
            <MemberCategoryCards
              categoryIds={categoryIds}
              categoryIdsMode="SELECT"
              onClick={removeCategory}
              clickIcon="trash"
              noCategoriesText="You have no sustainability interest selections."
            />
          )}
        </Grid>
      </DataStateHandler>
    </Grid>
  );
};

export default SustainabilityInterests;
