/* eslint-disable arrow-body-style */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';
import { $patchStyleText } from '@lexical/selection';
import React, { useCallback, useState } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import {
  Divider,
  Grid,
  InputAdornment,
  Popover,
  TextField,
  useTheme,
} from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { ToolbarButton } from './ToolbarButton';

type Props = {
  initialColor: `#${string}`;
  icon: SvgIconComponent;
  styleKey: 'color' | 'background-color';
};

// eslint-disable-next-line import/prefer-default-export
export const ColorPicker = (props: Props) => {
  const { icon: Icon, styleKey, initialColor } = props;
  const theme = useTheme();
  const [editor] = useLexicalComposerContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [colorInput, setColorInput] = useState(initialColor);
  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: 'historic' } : {},
      );
    },
    [editor],
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    applyStyleText({ [styleKey]: colorInput });
  };

  const handleColorChange = (newColor: string) => {
    const reg = /^(?:[0-9a-fA-F]{1})$/;
    if (newColor.length <= 6 && [...newColor].every((char) => reg.test(char))) {
      setColorInput(`#${newColor}`);
    }
  };

  return (
    <>
      <ToolbarButton
        icon={<Icon />}
        onClick={handleClick}
        commandPayload={undefined}
        commandType={{
          type: undefined,
        }}
      />
      <Popover
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Grid
          container
          flexDirection="column"
          alignContent="center"
          justifyContent="center"
          justifyItems="center"
          alignItems="center"
          padding={1}>
          <HexColorPicker
            color={colorInput}
            onChange={(value) => {
              setColorInput(`#${value.replace('#', '')}`);
            }}
          />
          <Divider style={{ margin: theme.spacing(0.5) }} />
          <TextField
            value={colorInput.replace('#', '')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
            onChange={(e) => handleColorChange(e.target.value)}
          />
        </Grid>
      </Popover>
    </>
  );
};
