import React from 'react';
import _ from 'lodash';

import { FormControl, Grid, MenuItem } from '@mui/material';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';
import { CategoryData } from '../../../../types/dashboard/types';
import AppCard from '../../../common/AppCard/AppCard';
import CardFooter from '../../../common/CardFooter/CardFooter';
import CategoryDataBarChart from '../../../common/CategoryDataBarChart/CategoryDataBarChart';
import ChartLegend from '../../../common/ChartLegend/ChartLegend';
import MissingInvestmentData from './MissingInvestmentData';
import NumberUtils from '../../../../utils/numberUtils';

type Props = {
  investments: Array<CategoryData>;
};

const CategoryInvestments: React.FC<Props> = ({ investments }) => {
  const [tabValue, setTabValue] = React.useState(
    investments.length ? investments[0].id : '',
  );
  const handleTabChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setTabValue(event.target.value as string);
  };

  const currentData = investments.find(
    (d) => d.id === tabValue,
  ) as CategoryData;

  // category investments will always have 2 elements
  // first is allocated second is remaining
  const totalAllocated = currentData.data[0].data.reduce(
    (sum, point) => sum + (point.value || 0),
    0,
  );
  const totalRemaining = currentData.data[1].data.reduce(
    (sum, point) => sum + (point.value || 0),
    0,
  );

  const total = totalAllocated + totalRemaining;

  const keys = currentData.data.map((d) => ({
    label: d.label,
    color: d.color || '',
  }));

  const data = _.flatten(currentData.data.map((i) => i.data));
  const isEmpty = data.reduce((sum, e) => sum + e.value, 0) === 0;

  return (
    <AppCard
      headerProps={{
        title: 'CATEGORY INVESTMENTS',
        rightContent: (
          <FormControl variant="outlined">
            <SelectMenu
              id="demo-simple-select"
              value={tabValue}
              onChange={handleTabChange}>
              {investments.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.label}
                </MenuItem>
              ))}
            </SelectMenu>
          </FormControl>
        ),
        tooltipId: 'categoryInvestments',
      }}>
      {isEmpty ? (
        <MissingInvestmentData
          category={{ id: currentData.id, label: currentData.label }}
          showViewProjectsButton
        />
      ) : (
        <>
          <CategoryDataBarChart categoryData={currentData} format="currency" />
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Grid item>
              <ChartLegend keys={keys} />
            </Grid>
            <Grid item>
              <CardFooter
                data={NumberUtils.format(total, 'currency')}
                label="Invested"
              />
            </Grid>
          </Grid>
        </>
      )}
    </AppCard>
  );
};

export default CategoryInvestments;
