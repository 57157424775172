import { Grid, Typography } from '@mui/material';
import React from 'react';
import theme from '../../../theme/memberTheme';

export type ActivityCountProps = {
  count?: number;
};

export function ActivityCount(props: ActivityCountProps) {
  const { count } = props;

  if (count) {
    return (
      <Grid
        style={{
          height: 17,
          width: 17,
          backgroundColor: theme.palette.primary.main,
          borderRadius: 8,
        }}>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 600,
            color: '#fff',
            fontSize: 10,
            paddingTop: 1,
            textAlign: 'center',
          }}>
          {count}
        </Typography>
      </Grid>
    );
  }

  return null;
}
