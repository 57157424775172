type EnumLike<T> = {
  [id: string]: T | string;
  [nu: number]: string;
};

export default class EnumUtils {
  /**
   * It returns an array of strings that are the keys of the enum object passed in
   * @param {T} enumObject - The enum object that you want to get the keys from.
   * @returns An array of strings that are the keys of the enumObject.
   */
  static keys<T extends EnumLike<unknown>>(enumObject: T): string[] {
    return Object.keys(enumObject).filter((e) => Number.isNaN(Number(e)));
  }

  /**
   * It takes an enum object and returns an array of all the enum values
   * @param {T} enumObject - The enum object that you want to get the values from.
   * @returns An array of the enum values.
   */
  static values<T extends EnumLike<unknown>>(enumObject: T): T[keyof T][] {
    return Object.keys(enumObject)
      .filter((e) => !Number.isNaN(Number(e)))
      .map((e) => parseInt(e, 10) as T[keyof T]);
  }
}
