import React, { useContext, useState } from 'react';
import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useRouteMatch } from 'react-router';
import { useHistory, Link } from 'react-router-dom';
import HeaderTabs from '../common/HeaderTabs/HeaderTabs';
import HeaderTab from '../common/HeaderTabs/HeaderTab';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 24,
    position: 'sticky',
    zIndex: 1,
    top: 0,
    background: theme.palette.background.default,
  },
  headerRow: {
    marginTop: 24,
    marginBottom: 28,
  },
  linkButton: {
    textDecoration: 'none',
  },
}));

const CompanySettingsHeader: React.FC = ({ children }) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const {
    company,
    saveCompany,
    createNew,
    pathData,
    loading,
    upserting,
    upsertError,
  } = useContext(CompanySettingsContext);
  const [showRequiredFieldsDialog, setShowRequiredFieldsDialog] =
    useState(false);
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();

  const pathCompanyId = pathData?.id ? `/${pathData.id}` : '';

  const platformAccess = userHasAccess('Configuration.Platform', 'VIEW');
  const employeeAccess = userHasAccess('Client.Employees', 'VIEW');
  const subscriptionAccess = userHasAccess(
    'ZeroMe.SubscriptionManagement',
    'VIEW',
  );

  return (
    <DataStateHandler
      loading={loading || (!createNew && !company?.id)}
      error={upsertError}>
      <>
        <LoadingBackdrop open={upserting} />
        <Grid container direction="column" className={classes.header}>
          <Grid
            item
            container
            direction="row"
            className={classes.headerRow}
            justifyContent="space-between">
            <Grid item>
              <Typography variant="h1">
                {createNew ? 'Create New Organization' : company?.name}
              </Typography>
            </Grid>
            {createNew && (
              <Grid item>
                <Link to="/trader/clients" className={classes.linkButton}>
                  <SmallPaddedButton variant="outlined">
                    Cancel
                  </SmallPaddedButton>
                </Link>
                <SmallPaddedButton
                  style={{ marginLeft: 16 }}
                  onClick={() => {
                    if (!company.name.trim()) {
                      setShowRequiredFieldsDialog(true);
                    } else {
                      saveCompany(company, {
                        saveNewToServer: true,
                      });
                    }
                  }}>
                  Save and Add
                </SmallPaddedButton>
              </Grid>
            )}
          </Grid>
          <HeaderTabs
            value={
              {
                [`${url}/company`]: 0,
                ...(!createNew
                  ? {
                      ...(platformAccess ? { [`${url}/platform`]: 1 } : {}),
                      ...(employeeAccess
                        ? { [`${url}/employees`]: platformAccess ? 2 : 1 }
                        : {}),
                      ...(subscriptionAccess
                        ? { [`${url}/subscription`]: platformAccess ? 3 : 2 }
                        : {}),
                    }
                  : {}),
              }[`/settings/${pathData?.section}`] || 0
            }>
            {[
              <HeaderTab
                key="company"
                label="Client Settings"
                style={{ minWidth: 175 }}
                onClick={() => history.push(`${url}/company${pathCompanyId}`)}
              />,
              ...(createNew
                ? []
                : [
                    ...(platformAccess
                      ? [
                          <HeaderTab
                            key="platform"
                            label="Platform Features"
                            style={{ minWidth: 170 }}
                            onClick={() =>
                              history.push(`${url}/platform${pathCompanyId}`)
                            }
                          />,
                        ]
                      : []),
                    ...(employeeAccess
                      ? [
                          <HeaderTab
                            key="employees"
                            label="Members"
                            style={{ minWidth: 110 }}
                            onClick={() =>
                              history.push(`${url}/employees${pathCompanyId}`)
                            }
                          />,
                        ]
                      : []),
                    ...(subscriptionAccess
                      ? [
                          <HeaderTab
                            key="susbscription"
                            label="Subscription Details"
                            style={{ minWidth: 180 }}
                            onClick={() =>
                              history.push(
                                `${url}/subscription${pathCompanyId}`,
                              )
                            }
                          />,
                        ]
                      : []),
                  ]),
            ]}
          </HeaderTabs>
        </Grid>
        <Dialog
          open={showRequiredFieldsDialog}
          onClose={() => setShowRequiredFieldsDialog(false)}>
          <DialogTitle>
            <Typography variant="h1">Client Name Required</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You must set the Client Name to save.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowRequiredFieldsDialog(false)}
              color="primary"
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        {children}
      </>
    </DataStateHandler>
  );
};

export default CompanySettingsHeader;
