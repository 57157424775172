import { Box, ButtonBase, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router';
import SVG from '../../../assets/svg';

const useStyles = makeStyles(() => ({
  stepText: {
    fontSize: '1.125rem',
  },
  stepCount: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    border: '1px solid #9e9e9e',
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  projectId: string;
  completedSteps: [number];
  possibleSteps: number;
};

const ProjectCompletionStatus: React.FC<Props> = ({
  completedSteps,
  possibleSteps,
  projectId,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const completionStatuses = Array.from(Array(possibleSteps).keys()).map(
    (x) => {
      const s = x + 1;
      if (completedSteps && completedSteps.includes(s)) {
        return { step: s, completed: true };
      }
      return { step: s, completed: false };
    },
  );

  return (
    <Box display="flex" flexDirection="row">
      {completionStatuses.map((step) => (
        <Box className={classes.stepCount} key={step.step}>
          <ButtonBase
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/trader/create-project/${projectId}?step=${step.step}`,
              );
              return false;
            }}>
            <Typography variant="body1" className={classes.stepText}>
              {step.completed ? <SVG.Check /> : step.step}
            </Typography>
          </ButtonBase>
        </Box>
      ))}
    </Box>
  );
};

export default ProjectCompletionStatus;
