import { Box, Typography } from '@mui/material';
import React from 'react';
import SVG from '../../../assets/svg';

const LogoIcon: React.FC = () => (
  <Box color="#FFF">
    <Typography style={{ display: 'flex' }} color="inherit">
      <SVG.LogoIcon />
    </Typography>
  </Box>
);

export default LogoIcon;
