import {
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  FormControlLabel,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import useStickyRef from '../../../hooks/useStickyRef';
import { ProjectCategory } from '../../../types/project/types';
import SelectMenu from '../SelectMenu/SelectMenu';

// Checkbox selection modes of select all/deselect all/indeterminate (mixed)
export const enum BatchSelectedState {
  all = 'ALL',
  none = 'NONE',
  indeterminate = 'INDETERMINATE',
}

const useStyles = makeStyles((theme) => ({
  browse: {
    marginRight: 'auto',
  },
  separator: {
    background: '#A5B7BF',
    width: 1,
    height: 32,
    display: 'inline-block',
    position: 'relative',
    top: 12,
    margin: '0px 16px',
  },
  sticky: {
    paddingBottom: 16,
    position: 'fixed',
    zIndex: 1,
    background: theme.palette.background.default,
  },
  categories: {
    padding: '8px 0px',
    width: 220,
    textOverflow: 'ellipsis',
  },
  types: {
    padding: '8px 0px 8px 8px',
  },
  selectAllTopMenu: {
    background: '#A5B7BF',
    fontWeight: 'bold',
  },
  toggle: {
    marginTop: 8,
  },
}));

export const enum Sort {
  whatsNew = "What's New",
  priceHighLow = 'Price High to Low',
  priceLowHigh = 'Price Low to High',
}

type Props = {
  data: ProjectCategory[];
  selectedItems: ProjectCategory[];
  filterCallback: (
    categories: ProjectCategory[],
    selectAllMode: BatchSelectedState,
    indicator: string,
  ) => void;
  sort: Sort;
  onSort: (sort: Sort) => void;
  indicator: string;
  projectIndicators: { id: string; name: string }[];
  isIndicativeChecked: boolean;
  onToggleIndicative: () => void;
};

const FilterToolbar: React.FC<Props> = ({
  data,
  selectedItems,
  filterCallback,
  sort,
  onSort,
  indicator,
  projectIndicators,
  isIndicativeChecked,
  onToggleIndicative,
}) => {
  const classes = useStyles();
  const { stickyRef, isSticky } = useStickyRef();
  const { isImpersonating } = useContext(AuthorizationContext);

  // State for current batch selection mode for project categories
  const [categoryBatchSelectionMode, setCategoryBatchSelectionMode] = useState(
    BatchSelectedState.all,
  );

  // Alias the data item
  const originalItems = [...data];

  // Select All menu item ID
  const SelectAllMenuId = 'select-all-menu-item';

  /**
   * For a Project Category item, either display Select All, Deselect All, or the Project Category itself.
   * @param item - Current ProjectCategory being rendered.
   * @returns - A string item to display.
   */
  const getSelectedCategoryDisplay = (item: ProjectCategory) => {
    if (item.id === SelectAllMenuId) {
      // Select All/Deselect All was chosen
      if (
        !categoryBatchSelectionMode ||
        categoryBatchSelectionMode === BatchSelectedState.all
      ) {
        return 'Deselect all';
      }

      return 'Select all';
    }

    return item.label;
  };

  /**
   * For a Project Category item, either check or uncheck the checkbox.
   * @param item - Current ProjectCategory being rendered.
   * @returns A boolean indicating checked or unchecked.
   */
  const getSelectedCategoryChecked = (item: ProjectCategory) => {
    if (item.id === SelectAllMenuId) {
      // Select All/Deselect All was chosen
      if (
        !categoryBatchSelectionMode ||
        categoryBatchSelectionMode === BatchSelectedState.all
      ) {
        // Select All - check the item
        return true;
      }

      // Deselect All - do not check the item
      return false;
    }

    // Check this category?
    return selectedItems.map((s) => s.id).indexOf(item.id) > -1;
  };

  /**
   * For a Project Category item, determine its indeterminate state.
   * @param item - Current ProjectCategory being rendered.
   * @returns A boolean indicating indeterminate state.
   */
  const getSelectedCategoryIndeterminate = (item: ProjectCategory) => {
    if (
      item.id === SelectAllMenuId &&
      categoryBatchSelectionMode === BatchSelectedState.indeterminate
    ) {
      // Select All/Deselect All is indeterminate
      return true;
    }

    return false;
  };

  /**
   * Based on the items in the selected menu items list, determine the 'Select All' state.
   * @param values - Selected menu item id's.
   * @returns The BatchSelectedState mode.
   */
  const determineSelectedCategoryMode = (values: string[]) => {
    let newMode: BatchSelectedState;
    if (values.find((d) => d === SelectAllMenuId)) {
      // Select All/Deselect All was chosen
      if (
        !categoryBatchSelectionMode ||
        categoryBatchSelectionMode === BatchSelectedState.all
      ) {
        // Deselect All
        newMode = BatchSelectedState.none;
      } else {
        // Select All
        newMode = BatchSelectedState.all;
      }
    } else if (values.length > 0 && values.length < data.length) {
      // The user checked at least one checkbox but not all
      newMode = BatchSelectedState.indeterminate;
    } else if (values.length > 0 && values.length >= data.length) {
      // The user checked all checkboxes individually
      newMode = BatchSelectedState.all;
    } else {
      // No checkboxes were chosen
      newMode = BatchSelectedState.none;
    }
    setCategoryBatchSelectionMode(newMode);

    return newMode;
  };

  /**
   * It the ProjectCategory is a top-level menu item, get a CSS class.
   * @param item - Current ProjectCategory being rendered.
   * @returns A string CSS class or undefined.
   */
  const getSelectedCategoryCssClass = (item: ProjectCategory) => {
    if (item.id === SelectAllMenuId) {
      // Select All/Deselect All menu CSS
      return classes.selectAllTopMenu;
    }

    return undefined;
  };

  /**
   * Gets the text to display depending on the number of selected Project Categories.
   * @param item - Current ProjectCategory being rendered.
   * @returns A string CSS class or undefined.
   */
  const getCategorySelectedDisplay = (values: string[]) => {
    if (!values.length) {
      return 'No Categories';
    }

    if (values.length === originalItems.length) {
      return 'All Categories';
    }

    // Comma-separated list of chosen items
    return values
      .map((value) => originalItems.find((d) => d.id === value)?.label || '')
      .join(', ');
  };

  // Add a Select All option to the menu items.
  const renderedItems = [
    {
      id: SelectAllMenuId,
      name: 'Select all',
      label: 'Select all',
      sortOrder: 0,
      iconUri: '',
    } as ProjectCategory,
    ...originalItems,
  ];

  return (
    <Grid
      ref={stickyRef}
      container
      direction="row"
      justifyContent="space-between"
      className={isSticky ? classes.sticky : undefined}
      style={{ top: isImpersonating ? 200 : 160 }}>
      <Grid item style={{ maxWidth: 1360, width: '100%' }}>
        <Grid container justifyContent="flex-end" alignItems="center">
          {isSticky ? null : (
            <Grid item className={classes.browse}>
              <Typography variant="h3">
                <Trans i18nKey="marketplace.browse" />
              </Typography>
            </Grid>
          )}
          <Grid item>
            <FormControlLabel
              className={classes.toggle}
              componentsProps={{
                typography: {
                  style: { fontSize: 12 },
                },
              }}
              control={
                <Switch
                  checked={isIndicativeChecked}
                  onChange={onToggleIndicative}
                />
              }
              label="Indicative Projects"
            />
            <SelectMenu
              className={classes.categories}
              key="marketplace-project-categories-filter"
              id="marketplace-project-categories-filter"
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                const values: string[] = e.target.value as string[];

                // Get the current Select All mode based on chosen checkbox(es)
                const newMode = determineSelectedCategoryMode(values);

                filterCallback(
                  values.map(
                    (id) =>
                      originalItems.find((d) => d.id === id) as ProjectCategory,
                  ),
                  newMode,
                  indicator,
                );
              }}
              SelectProps={{
                multiple: true,
                displayEmpty: true,
                renderValue: (v) => getCategorySelectedDisplay(v as string[]),
              }}
              value={selectedItems.map((s) => s.id)}>
              {renderedItems.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  className={getSelectedCategoryCssClass(item)}>
                  <Checkbox
                    indeterminate={getSelectedCategoryIndeterminate(item)}
                    checked={getSelectedCategoryChecked(item)}
                  />
                  {getSelectedCategoryDisplay(item)}
                </MenuItem>
              ))}
            </SelectMenu>
            <SelectMenu
              className={classes.types}
              key="marketplace-project-type-filter"
              id="marketplace-project-type-filter"
              value={indicator || 'all'}
              onChange={(e) => {
                filterCallback(
                  selectedItems,
                  categoryBatchSelectionMode,
                  e.target.value,
                );
              }}>
              <MenuItem key="all" value="all">
                All Types
              </MenuItem>
              {projectIndicators.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </SelectMenu>
            <SelectMenu
              key="marketplace-project-sort"
              id="marketplace-project-sort"
              value={sort}
              onChange={(e) => {
                onSort(e.target.value as unknown as Sort);
              }}
              SelectProps={{
                renderValue: (value) => `Sort By: ${value}`,
              }}>
              <MenuItem key={Sort.whatsNew} value={Sort.whatsNew}>
                What&apos;s New
              </MenuItem>
              <MenuItem key={Sort.priceHighLow} value={Sort.priceHighLow}>
                Price High to Low
              </MenuItem>
              <MenuItem key={Sort.priceLowHigh} value={Sort.priceLowHigh}>
                Price Low to High
              </MenuItem>
            </SelectMenu>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterToolbar;
