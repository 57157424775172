import React from 'react';
import { Chip } from '@mui/material';
import { HabitCategory } from '../../../types/habit/types';

type Props = {
  category: HabitCategory;
};

const HabitCategoryChip: React.FC<Props> = ({ category }) => (
  <Chip
    size="small"
    style={{
      backgroundColor: category?.secondaryColor,
      color: category?.primaryColor,
    }}
    label={category.label}
  />
);

export default HabitCategoryChip;
