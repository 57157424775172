import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_ALL_FAQS = gql`
  query GET_ALL_FAQS {
    faqQuestions {
      id
      sortOrder
      questionText
      questionAnswer
    }
  }
` as TypedDocumentNode<{
  faqQuestions: {
    id: string;
    questionText: string;
    questionAnswer: string;
  }[];
}>;

export default {
  GET_ALL_FAQS,
};
