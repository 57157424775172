import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_LIKED_PROJECTS } from '../graphql/user/userMutations';

import { AuthorizationContext } from '../contexts/AuthorizationContext';

export type UseToggleProjectLikeProps = {
  id: string;
};

export default function useToggleProjectLike(props: UseToggleProjectLikeProps) {
  const { id } = props;
  const { user } = useContext(AuthorizationContext);

  const likesProject = user?.likedProjectIds?.find((l) => l === id);

  const [update, { loading: toggleLikeLoading, error: toggleLikeError }] =
    useMutation(UPDATE_USER_LIKED_PROJECTS);

  const toggleLike = () => {
    const collection = user?.likedProjectIds || [];
    const likedProjectIds = likesProject
      ? collection.filter((o) => o !== id)
      : [...collection, id];

    update({ variables: { likedProjectIds } });
  };

  return { toggleLike, toggleLikeLoading, toggleLikeError, likesProject };
}
