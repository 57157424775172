import { List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { navItems } from '../../contexts/CommunicationsContext';
import { CommunicationsParams } from './CommunicationsContainer';
import CommunicationsNavItem from './CommunicationsNavItem';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    background: theme.palette.primary.dark,
    padding: 16,
  },
}));

const CommunicationsNavigation: React.FC = () => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { contentType, companyId } = useParams<CommunicationsParams>();
  return (
    <List className={classes.root}>
      {navItems
        .filter((x) => userHasAccess(x.ability, 'VIEW'))
        .filter((x) => (companyId ? !x.isGlobal : x.isGlobal))
        .map(({ id, label, icon }) => (
          <CommunicationsNavItem
            key={id}
            id={id}
            label={label}
            companyId={companyId}
            active={id === contentType}
            icon={icon(id === contentType)}
          />
        ))}
    </List>
  );
};

export default CommunicationsNavigation;
