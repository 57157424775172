import { gql, TypedDocumentNode } from '@apollo/client';
import {
  CommunicationCategory,
  CommunicationSettings,
  EditableQuiz,
  LocalizedQuiz,
} from '../../components/communications/types';
import { UserHasAccessFn } from '../../contexts/AuthorizationContext';
import { ContestType } from '../../types/contest/types';
import { EmailCommunication } from '../../types/communication/email';

export const COMMUNICATION_RATINGS_CORE = `
      id
      global {
        score
        count
      }
      company {
        score
        count
      }
      user {
        score
        count
      }
`;

const COMMUNICATION_LIST_FIELDS = `
    items {
      id
      title
      createdDate
      updatedDate
      publishDate
      isFeatured
      viewsCount
      bookmarksCount
      communicationType
      companyIds
      ratings {
        ${COMMUNICATION_RATINGS_CORE}
      }
      quiz {
        id
      }
    }
`;

const COMMUNICATION_FIELDS = `
    id
    author
    title
    content
    headerImageUrl
    contentUploadUrl
    communicationType
    categoryIds
    isFeatured
    includeChildren
    publishDate
    relatedArticleIds
    relatedHabitIds
    createdDate
    updatedDate
`;

const EMAIL_FIELDS = `
    id
    title
    content
    contentState
    companyIds
    includeChildren
    isGlobalCommunication
    publishDate
    createdDate
    updatedDate
    userFilter {
      onlyPreboardingUsers
      contestIds
    }
`;

const QUOTATION_FIELDS = `
    id
    quote
    author
    status
`;

const NOTIFICATION_FIELDS = `
    id
    title
    content
    linkType
    linkId
    createdDate
    updatedDate
    publishDate
    sentDate
    includeChildren
    companyId
`;

const QUIZ_LIST_FIELDS = `
    id
    status
    quizType
    publishDate
    expireDate
    createdDate
    title
    metrics {
      quizCount
      averageAccuracy
    }
`;

const QUIZ_EDIT_FIELDS = `
    id
    status
    quizType
    iconUrl
    createdDate
    companyIds
    publishDate
    expireDate
    communicationIds
    title {
      language
      text
    }
    questions {
      correctAnswerId
      questionId
      questionText {
        language
        text
      }
      options {
        answerId
        answerText {
          language
          text
        }
        answerExplanation {
          language
          text
        }
      }
    }
`;

const CHALLENGE_LIST_FIELDS = `
    id
    title
    description
    companyIds
    isDraft
    pointCategories
    imageFilename
    duration {
      interval
      startDate
      endDate
    }
    updatedDate
`;

export const QUIZ_METRIC_FIELDS = `
    id
    status
    quizType
    createdDate
    title
    expireDate
    questions {
      correctAnswerId
      questionId
      questionText
      options {
        answerId
        answerText
      }
    }
    metrics {
      quizCount
      averageAccuracy
      answers {
        questionId
        answerId
        totalOccurances
      }
    }
`;

export const QUIZ_ANSWER_FIELDS = `
    id
    status
    quizType
    createdDate
    title
    expireDate
    iconUrl
    questions {
      questionId
      questionText
      correctAnswerId
      options {
        answerId
        answerText
        answerExplanation
      }
    }
    answers {
      isHidden
      answers {
        questionId
        answerIds
      }
    }
`;

export const generateCommunicationsQuery = (
  userHasAccessFn: UserHasAccessFn,
  isClient: boolean,
) => gql`
  query communications($includeGlobalCommunications: Boolean, $companyId: String, $ignoreCache: Boolean) {
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.Discover`,
        'VIEW',
      )
        ? `communications(options: { 
      types: [VIDEO, HINT, BLOG_POST, AUDIO], 
      includeGlobalCommunications: $includeGlobalCommunications, 
      companyId: $companyId,
      ignoreCache: $ignoreCache }) {
      ${COMMUNICATION_LIST_FIELDS}
    }`
        : ''
    }
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.Emails`,
        'VIEW',
      )
        ? `
        emailCommunications(
          options: {
            companyId: $companyId,
            ignoreCache: $ignoreCache,
            includeChildren: true
          }
        ) {
          items {
            id
            title
            publishDate
            createdDate
            updatedDate
            openedPercent
            isGlobalCommunication
            companyIds
          }
        }`
        : ''
    }
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.PushNotifications`,
        'VIEW',
      )
        ? `notifications(options: {companyId: $companyId, ignoreCache: $ignoreCache}) {
      items {
        ${NOTIFICATION_FIELDS}
      }
    }`
        : ''
    }
    ${
      userHasAccessFn('ZeroMe.Communication.Quotes', 'VIEW')
        ? `
        quotations(options: {ignoreCache: $ignoreCache}) {
          ${QUOTATION_FIELDS}
        }
      `
        : ''
    }
    ${
      userHasAccessFn(
        `${isClient ? 'Client' : 'ZeroMe'}.Communication.Quizzes`,
        'VIEW',
      )
        ? `
        quizzes(
          options: {
            companyId: $companyId,
            ignoreCache: $ignoreCache,
            includeChildren: true
          }
        ) {
          items {
            ${QUIZ_LIST_FIELDS}
          }
        }
      `
        : ''
    }
    ${
      userHasAccessFn('Client.Communication.Challenges', 'VIEW')
        ? `
        findContests(
          companyId: $companyId,
          filter: {
            ignoreCache: $ignoreCache
            contestType: "${ContestType.COMPANY}"
            includeChildren: true
            sortBy: "updatedDate"
            sortDirection: "DESC"
          }
        ) {
          items {
            ${CHALLENGE_LIST_FIELDS}
          }
        }
      `
        : ''
    }
  }
`;

export const EMAIL_COMMUNICATION_QUERY = gql`
query emailCommunication($id: String!) {
  emailCommunication(id: $id) {
    ${EMAIL_FIELDS}
  }
}
`;

export const UPSERT_EMAIL_COMMUNICATION = gql`
  mutation upsertEmailCommunication($input: EmailCommunicationInput!) {
    upsertEmailCommunication(emailCommunication: $input) {
      ${EMAIL_FIELDS}
    }
  }
` as TypedDocumentNode<
  {
    upsertEmailCommunication: EmailCommunication;
  },
  {
    input: Pick<
      EmailCommunication,
      | 'id'
      | 'author'
      | 'title'
      | 'content'
      | 'contentState'
      | 'companyIds'
      | 'includeChildren'
      | 'isGlobalCommunication'
      | 'publishDate'
      | 'userFilter'
    >;
  }
>;

export const DELETE_EMAIL_COMMUNICATION = gql`
  mutation deleteEmailCommunication($id: String!) {
    deleteEmailCommunication(id: $id)
  }
`;

export const PREVIEW_EMAIL_COMMUNICATION = gql`
mutation previewEmail($id: String!) {
  previewEmailCommunication(id: $id) {
    ${EMAIL_FIELDS}
  }
}
`;

export const UPSERT_QUOTATION = gql`
  mutation upsertQuotation($input: QuotationInput!) {
    upsertQuotation(quotation: $input) {
      ${QUOTATION_FIELDS}
    }
  }
`;

export const UPSERT_NOTIFICATION = gql`
  mutation upsertNotification($input: NotificationInput!) {
    upsertNotification(notification: $input) {
      ${NOTIFICATION_FIELDS}
    }
  }
`;

export const COMMUNICATION_QUERY = gql`
  query communication($id: String!) {
    communication(id: $id) {
      ${COMMUNICATION_FIELDS}
    }
  }
`;

export const COMMUNICATIONS_QUERY = gql`
  query communications(
    $includeGlobalCommunications: Boolean
    $companyId: String
    $isPublished: Boolean
    $ignoreCache: Boolean
  ) {
    communications(
      options: {
        types: [VIDEO, HINT, BLOG_POST, AUDIO]
        includeGlobalCommunications: $includeGlobalCommunications
        companyId: $companyId
        isPublished: $isPublished
        ignoreCache: $ignoreCache
      }
    ) {
      items {
        id
        title
        publishDate
      }
    }
  }
`;

export const COMMUNICATION_CATEGORIES = gql`
  query communicationCategories {
    communicationCategories {
      id
      label
    }
  }
` as TypedDocumentNode<{ communicationCategories: CommunicationCategory[] }>;

export const UPSERT_COMMUNICATION = gql`
  mutation upsertCommunication($input: CommunicationInput!) {
    upsertCommunication(communication: $input) {
      ${COMMUNICATION_FIELDS}
    }
  }
`;

export const DELETE_COMMUNICATION = gql`
  mutation deleteCommunication($communicationId: String!) {
    deleteCommunication(communicationId: $communicationId)
  }
`;

export const COMPANY_LIST_QUERY = gql`
  query {
    allCompanies {
      id
      name
      parentCompanyId
    }
  }
`;

export const GET_COMMUNICATION_SETTINGS = gql`
  query getCommunicationSettings($companyId: String) {
    getCommunicationSettings(companyId: $companyId) {
      welcomeVideoId
    }
  }
` as TypedDocumentNode<{ getCommunicationSettings: CommunicationSettings }>;

export const UPSERT_COMMUNICATION_SETTINGS = gql`
  mutation upsertCommunicationSettings(
    $settings: CommunicationSettingsInput!
    $companyId: String
  ) {
    upsertCommunicationSettings(settings: $settings, companyId: $companyId) {
      welcomeVideoId
    }
  }
` as TypedDocumentNode<
  { upsertCommunicationSettings: CommunicationSettings },
  { settings: CommunicationSettings; companyId?: string }
>;

export const GET_QUIZ_METRICS = gql`
  query quiz($quizId: String!, $companyId: String) {
    quiz(quizId: $quizId, companyId: $companyId) {
      ${QUIZ_METRIC_FIELDS}
    }
  }
` as TypedDocumentNode<{ quiz: LocalizedQuiz }>;

export const GET_EDITABLE_QUIZ = gql`
  query editableQuiz($quizId: String!, $companyId: String) {
    editableQuiz(quizId: $quizId, companyId: $companyId) {
      ${QUIZ_EDIT_FIELDS}
    }
  }
` as TypedDocumentNode<{ editableQuiz: EditableQuiz }>;

export const UPSERT_QUIZ = gql`
  mutation upsertQuiz($quiz: EditableQuizInput!, $companyId: String) {
    upsertQuiz(quiz: $quiz, companyId: $companyId) {
      ${QUIZ_EDIT_FIELDS}
    }
  }
`;
