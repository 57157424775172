import React, { useState } from 'react';
import {
  Drawer,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik';
import SettingsFieldRow from './SettingsFieldRow';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import SVG from '../../assets/svg';
import { Disableable } from '../../types/common';

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    width: 620,
    padding: 32,
    paddingTop: 16,
  },
  headerRoot: {
    padding: 32,
    paddingBottom: 0,
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 2,
  },
  header: {
    paddingBottom: 8,
    borderBottom: '1px solid #DADADA',
  },
  launchButton: {
    fontSize: 12,
    color: theme.palette.primary.main,
    lineHeight: '16px',
  },
  iconButton: {
    minWidth: 24,
    margin: '0 4px',
  },
}));

type Props<T> = Disableable & {
  launchRow?: {
    label: React.ReactNode;
    value: React.ReactNode;
  };
  launchButton?: {
    label: React.ReactNode;
  };
  title: React.ReactNode;
  formikProps: FormikConfig<T>;
  forceOpen?: boolean;
  confirmDeleteLabel?: string;
  hideSaveButton?: boolean;
  dontCloseOnSave?: boolean;
  onClose?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  children: (props: FormikProps<T>) => React.ReactNode;
};

const SettingsPanel = <T extends FormikValues>({
  launchRow,
  launchButton,
  disabled,
  title,
  formikProps,
  forceOpen,
  confirmDeleteLabel,
  hideSaveButton,
  dontCloseOnSave,
  onClose,
  onCopy,
  onDelete,
  children,
}: Props<T>) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const close = () => {
    setDrawerOpen(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      {!!launchRow && (
        <SettingsFieldRow {...launchRow} onClick={() => setDrawerOpen(true)} />
      )}
      {!!launchButton && (
        <Button
          className={classes.launchButton}
          onClick={() => setDrawerOpen(true)}>
          {launchButton.label}
        </Button>
      )}
      <Drawer open={forceOpen || drawerOpen} onClose={close} anchor="right">
        <Formik<T>
          validateOnMount
          {...{
            ...formikProps,
            onSubmit: async (...args) => {
              await formikProps.onSubmit(...args);

              if (!dontCloseOnSave) {
                close();
              }
            },
          }}>
          {(props) => (
            <Form noValidate>
              <Grid item container className={classes.headerRoot}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  className={classes.header}>
                  <Grid item>
                    <Button style={{ marginTop: 4 }} onClick={close}>
                      <SVG.CaretLeftSmall style={{ marginRight: 15 }} />
                      {title}
                    </Button>
                  </Grid>
                  {!disabled && (
                    <Grid item>
                      {!!onCopy && (
                        <Button className={classes.iconButton} onClick={onCopy}>
                          <SVG.Copy />
                        </Button>
                      )}
                      {!!onDelete && (
                        <Button
                          className={classes.iconButton}
                          onClick={() => setConfirmDelete(true)}
                          style={{ marginRight: 20 }}>
                          <SVG.Delete />
                        </Button>
                      )}
                      {!hideSaveButton && (
                        <SmallPaddedButton
                          type="submit"
                          disabled={!props.isValid}>
                          Save
                        </SmallPaddedButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid container direction="column" className={classes.drawerRoot}>
                {children(props)}
              </Grid>
            </Form>
          )}
        </Formik>
      </Drawer>
      <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
        <DialogTitle>
          <Typography variant="h1">Confirm Delete</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDeleteLabel}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDelete(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setConfirmDelete(false);
              if (onDelete) {
                onDelete();
              }
            }}
            color="primary"
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SettingsPanel;
