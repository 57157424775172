import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import SVG from '../../../assets/svg';
import {
  QuestionnaireActionTypes,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';
import { Colors } from '../../../theme/shared';
import {
  ProfileAnswerCard,
  QuestionAnswer,
} from '../../../types/question/types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    top: 12,
    left: 12,
    color: Colors.TertiaryText,
  },
  nextButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    color: Colors.TertiaryText,
  },
  container: {
    margin: '70px 24px 24px 24px',
    alignItems: 'center',
  },
  icon: {
    height: 40,
    width: '100%',
    color: theme.palette.error.main,
    marginBottom: 16,
  },
  explanation: {
    marginTop: 48,
  },
}));

type Props = {
  profileCard: ProfileAnswerCard;
};

const QuizAnswer: React.FC<Props> = ({ profileCard }) => {
  const classes = useStyles();
  const state = useQuestionnaire();
  const dispatch = useQuestionnaireDispatch();
  const { questionId, correctAnswerId, options } = profileCard.question;

  const answers: QuestionAnswer[] = state.testing
    ? state.testingAnswers || []
    : state.userEmissionSources.flatMap((x) => x.answeredQuestions);
  const answer = answers.find((a) => a.questionId === questionId);

  const answerExplanation = options?.find(
    (x) => x.answerId === answer?.answerIds?.[0],
  )?.answerExplanation;

  const isCorrectAnswer = answer?.answerIds?.[0] === correctAnswerId;

  return (
    <Grid container className={classes.root}>
      {!!state.carouselIndex && (
        <Button
          className={classes.backButton}
          onClick={() => dispatch({ type: QuestionnaireActionTypes.GO_BACK })}>
          {t('back')}
        </Button>
      )}
      {state.carouselIndex < state.profileCards.length - 1 && (
        <Button
          className={classes.nextButton}
          onClick={() => dispatch({ type: QuestionnaireActionTypes.NEXT })}>
          {t('next')}
        </Button>
      )}
      <Grid container item direction="column" className={classes.container}>
        {isCorrectAnswer ? (
          <SVG.Check className={classes.icon} />
        ) : (
          <SVG.Close className={classes.icon} />
        )}
        <Typography variant="h2">
          {isCorrectAnswer
            ? t('quiz.answerCorrect')
            : t('quiz.answerIncorrect')}
        </Typography>
        <Typography variant="body1" className={classes.explanation}>
          {answerExplanation}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default QuizAnswer;
