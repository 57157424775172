import React, { useContext, useRef, useState } from 'react';
import { Grid, Portal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import UploadUserCsvModal from '../settings/UploadUserCsvModal';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import UserGrid, {
  DEFAULT_USER_GRID_REF,
  UserGridColumnKey,
  UserGridRef,
} from './UserGrid';
import { User } from '../../types/user/types';
import EditEmployeePanel from '../settings/EditEmployeePanel';

import CompanySelect from '../common/CompanySelect/CompanySelect';
import ActivateEnrollingModal from '../settings/ActivateEnrollingModal';

const useStyles = makeStyles(() => ({
  companySelect: {
    padding: '0px 8px',
    minWidth: 120,
  },
}));

type Props = {
  portalRef?: React.RefObject<HTMLDivElement | null>;
  companyId: string;
  divisionId: string;
  includeChildren?: boolean;
};

const EmployeesList: React.FC<Props> = ({
  portalRef,
  companyId,
  divisionId,
  includeChildren = false,
}) => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const userGridRef = useRef<UserGridRef>(DEFAULT_USER_GRID_REF);

  const [selectedUser, setSelectedUser] = useState<User>();
  const [editOpen, setEditOpen] = useState(false);

  // Can the user edit an employee?
  const canEditEmployee = userHasAccess('Client.Employees', 'EDIT');

  // Client-related roles should not be able to view employee details
  const canViewEmployeeDetails = userHasAccess(
    'Client.Employees.Details',
    'VIEW',
  );

  const disableActions = includeChildren || divisionId !== companyId;

  return (
    <>
      {portalRef && (
        <Portal container={portalRef.current}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            alignContent="center"
            xs>
            {canEditEmployee && (
              <>
                <ActivateEnrollingModal
                  refetch={() => userGridRef.current?.refetch()}
                  companyId={companyId}
                  disabled={disableActions}
                />
                <UploadUserCsvModal
                  refetch={() => userGridRef.current?.refetch()}
                  companyId={companyId}
                  disabled={disableActions}
                />
                <SmallPaddedButton
                  disabled={disableActions}
                  onClick={() => setEditOpen(true)}>
                  + Add New Member
                </SmallPaddedButton>
              </>
            )}
            {userHasAccess('Api.AllowParentQueries', 'VIEW') && (
              <CompanySelect className={classes.companySelect} />
            )}
          </Grid>
        </Portal>
      )}
      <RoundedFlexBox>
        <UserGrid
          companyId={divisionId}
          includeChildCompanyUsers={includeChildren}
          columns={[
            ...(includeChildren ? ['company' as UserGridColumnKey] : []),
            'name',
            'email',
            'postalCode',
            'country',
            'role',
            'status',
            'userTypeId',
            'dataRequestStatus',
            'edit',
          ]}
          ref={userGridRef}
          onRowClick={
            canEditEmployee || canViewEmployeeDetails
              ? (user) => {
                  setSelectedUser(user);
                  setEditOpen(true);
                }
              : undefined
          }
        />
      </RoundedFlexBox>
      <EditEmployeePanel
        user={selectedUser}
        companyId={companyId}
        open={editOpen}
        onClose={(doRefetch) => {
          setSelectedUser(undefined);

          setEditOpen(false);

          if (doRefetch) {
            userGridRef.current?.refetch();
          }
        }}
      />
    </>
  );
};

export default EmployeesList;
