import React from 'react';
import { useParams } from 'react-router';

import MemberLayout from '../../../components/layouts/MemberLayout';
import Quiz from '../../../components/member/quiz/Quiz';

const MemberQuiz: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <MemberLayout>
      <Quiz quizId={id} />
    </MemberLayout>
  );
};

export default MemberQuiz;
