import quizCompletedDisabled from './QuizCompletedDisabled';
import quizCompletedEnabled from './QuizCompletedEnabled';

const quizCompleted = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? quizCompletedDisabled(level, size)
    : quizCompletedEnabled(level, size);

export default quizCompleted;
