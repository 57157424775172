import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useRef, useState } from 'react';
import SVG from '../../assets/svg';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import { GET_COMPANY_CONTACTS } from '../../graphql/company/company';
import { UPDATE_COMPANY_CONTACTS_MUTATION } from '../../graphql/company/companyMutations';
import { CompanyContactType } from '../../types/company/types';
import { User } from '../../types/user/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import CompanyLogo from '../common/Company/CompanyLogo';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import LoadingBackdrop from '../common/LoadingBackdrop';
import UserImage from '../common/User/UserImage';
import UserGrid, { DEFAULT_USER_GRID_REF, UserGridRef } from '../user/UserGrid';
import SettingsPanel from './SettingsPanel';
import { ZeroMeTM } from '../../utils/stringConstants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 16px 16px',
    marginBottom: 30,
  },
  updateButton: {
    size: 12,
    color: theme.palette.primary.main,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  contactButton: {
    backgroundColor: '#0A3751',
    color: '#FFF',
    padding: '12px 16px',
    marginBottom: 8,
    fontSize: 12,
    marginLeft: 8,
  },
  closeIcon: {
    color: theme.palette.text.primary,
  },
  dialog: {
    padding: '24px 16px',
  },
  select: {
    '&.Mui-focused .MuiSelect-select': {
      backgroundColor: '#fff',
    },
  },
  companyLogo: {
    width: 16,
    height: 16,
    position: 'absolute',
    top: 24,
    left: 24,
  },
  emailText: {
    color: '#6B6B6B',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  userInfo: {
    overflow: 'hidden',
  },
  gridHost: {
    margin: '24px 0',
    maxHeight: 300,
    overflow: 'auto',
    paddingRight: 16,
  },
}));

type Props = {
  contactType: CompanyContactType;
};

const CompanyContacts: React.FC<Props> = ({ contactType }) => {
  const classes = useStyles();
  const [panelOpen, setPanelOpen] = useState(false);
  const userGridRef = useRef<UserGridRef>(DEFAULT_USER_GRID_REF);
  const [selectUserModalOpen, setSelectUserModalOpen] = useState(false);
  const { userHasAccess } = useContext(AuthorizationContext);
  const { company } = useContext(CompanySettingsContext);

  const canEdit = userHasAccess('Configuration.Platform', 'EDIT');

  const {
    data: { company: { contacts: companyContacts = [] } = {} } = {},
    loading: loadingContacts,
  } = useQuery(GET_COMPANY_CONTACTS, { variables: { id: company.id } });

  const [updateCompanyContactsMutation, { loading }] = useMutation<{
    type: string;
    companyId: string;
    userIds: string[];
  }>(UPDATE_COMPANY_CONTACTS_MUTATION);

  const metadata = {
    ADMIN: {
      title: `${ZeroMeTM} Admin`,
      description: `The ${ZeroMeTM} Administrator contact is the individual who will serve as the point of contact within your organization for stakeholders and employee questions.`,
    },
    HR: {
      title: 'HR',
      description: `The Human Resources contact is the individual who will serve as a point of contact for engagement activities in ${ZeroMeTM}.`,
    },
    SUSTAINABILITY: {
      title: 'Sustainability',
      description:
        'The sustainability contact is the individual in your organization who serves as the point of contact for your sustainability activities and goals.',
    },
  }[contactType];

  const contacts =
    companyContacts?.find((x) => x.type === contactType)?.users || [];

  return (
    <>
      <LoadingBackdrop open={loading} />
      <Paper className={classes.root}>
        <Grid container direction="column">
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            style={{ marginBottom: 8 }}>
            <Grid item>
              <Typography variant="h3">{metadata.title} Contact</Typography>
            </Grid>
            {canEdit && !!contacts.length && (
              <Grid item>
                <Button
                  size="small"
                  className={classes.updateButton}
                  onClick={() => setPanelOpen(true)}>
                  Update
                </Button>
              </Grid>
            )}
          </Grid>
          <DataStateHandler loading={loadingContacts}>
            <>
              {!contacts.length && (
                <>
                  <Grid item style={{ marginBottom: 16 }}>
                    <Typography variant="body2">
                      {metadata.description}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {canEdit && (
                      <SmallPaddedButton
                        variant="outlined"
                        onClick={() => setPanelOpen(true)}>
                        + Assign User
                      </SmallPaddedButton>
                    )}
                  </Grid>
                </>
              )}
              {!!contacts.length && (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={2}
                  style={{ paddingTop: 32, paddingBottom: 8 }}>
                  {contacts.map((c) => (
                    <Grid key={c.id} item xs={4}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 8, position: 'relative' }}>
                          <UserImage size="md" user={c} />
                          <CompanyLogo
                            companyId={c.companyId}
                            className={classes.companyLogo}
                          />
                        </div>
                        <div className={classes.userInfo}>
                          <Typography variant="body2">{c.name}</Typography>
                          <Typography
                            variant="body2"
                            className={classes.emailText}>
                            {c.email}
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          </DataStateHandler>
        </Grid>
      </Paper>
      <SettingsPanel<{ contacts: User[] }>
        forceOpen={panelOpen}
        onClose={() => setPanelOpen(false)}
        title={`${metadata.title} Contact`}
        formikProps={{
          initialValues: {
            contacts,
          },
          onSubmit: async (values) => {
            await updateCompanyContactsMutation({
              variables: {
                type: contactType,
                companyId: company.id,
                userIds: values.contacts.map((x) => x.id),
              },
              update: (cache) => {
                cache.updateQuery(
                  {
                    query: GET_COMPANY_CONTACTS,
                    variables: { id: company.id },
                  },
                  (cacheData) =>
                    cacheData?.company && {
                      company: {
                        ...(cacheData?.company || {}),
                        contacts: [
                          ...(cacheData?.company?.contacts || []).filter(
                            (x) => x.type !== contactType,
                          ),
                          {
                            type: contactType,
                            users: values.contacts,
                          },
                        ],
                      },
                    },
                );
              },
            });
          },
        }}>
        {({ values, setFieldValue }) => (
          <>
            <Typography style={{ marginBottom: 40 }}>
              {metadata.description}
            </Typography>
            <Typography variant="body2">Assignee(s)</Typography>
            <Select
              variant="standard"
              multiple
              open={false}
              value={values.contacts}
              style={{ padding: 6, paddingBottom: 0 }}
              disableUnderline
              displayEmpty
              className={classes.select}
              renderValue={() => (
                <div className={classes.chips}>
                  {values.contacts.map((value) => (
                    <Button
                      key={value.id}
                      size="small"
                      variant="contained"
                      className={classes.contactButton}
                      onClickCapture={(e) => {
                        setFieldValue(
                          'contacts',
                          values.contacts.filter((x) => x.id !== value.id),
                        );
                        e.stopPropagation();
                        e.preventDefault();
                        return false;
                      }}>
                      {value.name}{' '}
                      <SVG.Close style={{ marginLeft: 8, height: 10 }} />
                    </Button>
                  ))}
                  {!values.contacts.length && (
                    <Typography
                      variant="h3"
                      style={{ padding: '12px 16px 18px' }}>
                      Click here to assign users...
                    </Typography>
                  )}
                </div>
              )}
              onClick={() => setSelectUserModalOpen(true)}
            />
            <Dialog
              open={selectUserModalOpen}
              onClose={() => setSelectUserModalOpen(false)}
              maxWidth="lg">
              <Grid
                container
                direction="column"
                alignItems="stretch"
                className={classes.dialog}>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between">
                  <Typography align="center" variant="h3">
                    Select the user(s) that you would like to assign as the
                    Leadership (HR) contact.
                  </Typography>
                  <IconButton
                    onClick={() => setSelectUserModalOpen(false)}
                    className={classes.closeIcon}
                    size="large">
                    <SVG.Close />
                  </IconButton>
                </Grid>
                <Grid item className={classes.gridHost}>
                  <UserGrid
                    companyId={company.id}
                    ref={userGridRef}
                    initialSelected={values.contacts.map((c) => c.id)}
                    includeChildCompanyUsers
                    ignoreMembers
                    showCheckboxes
                    activeOnly
                    columns={['name', 'email', 'role', 'company']}
                  />
                </Grid>
                <Grid item container direction="row" justifyContent="flex-end">
                  <SmallPaddedButton
                    onClick={async () => {
                      setFieldValue('contacts', [
                        ...values.contacts,
                        ...userGridRef.current
                          .getSelectedUsers()
                          .filter(
                            (x) =>
                              values.contacts.map((c) => c.id).indexOf(x.id) <
                              0,
                          ),
                      ]);
                      setSelectUserModalOpen(false);
                    }}>
                    Save
                  </SmallPaddedButton>
                </Grid>
              </Grid>
            </Dialog>
          </>
        )}
      </SettingsPanel>
    </>
  );
};

export default CompanyContacts;
