import { Grid } from '@mui/material';
import React from 'react';
import SVG from '../../../../assets/svg';
import SVG2 from '../../../../assets/svg-v2';
import AppSection from '../../../common/AppSection/AppSection';
import ParticipationActivityCard from './ParticipationActivityCard';

type Props = {
  data: {
    total: number;
    onboarded: number;
    onboardedDelta: number;
    notOnboarded: number;
    requestedAccountDelete: number;
    creditsRedeemed: number;
    totalRedemptions: number;
  };
};
const ParticipationActivity: React.FC<Props> = ({ data }) => (
  <AppSection header="Participation Activity">
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <ParticipationActivityCard
          title="TOTAL INVITATIONS SENT"
          tooltipId="participationActivityTotalInvites"
          value={data.total}
          icon={<SVG.ReturnArrow />}
        />
      </Grid>
      <Grid item xs={3}>
        <ParticipationActivityCard
          title="ONBOARDED"
          tooltipId="participationActivityOnboarded"
          value={data.onboarded}
          icon={<SVG.ListChecked />}
          footerLabel="Accounts Removed Per User Request"
          footerMetric={data.requestedAccountDelete}
        />
      </Grid>
      <Grid item xs={3}>
        <ParticipationActivityCard
          title="NOT ONBOARDED"
          tooltipId="participationActivityNotOnboarded"
          value={data.notOnboarded}
          icon={<SVG.ListUnchecked />}
        />
      </Grid>
      <Grid item xs={3}>
        <ParticipationActivityCard
          title="TOTAL CREDITS REDEEMED"
          value={data.creditsRedeemed}
          icon={<SVG2.Reforestation />}
          footerLabel="Total Member Redemptions"
          footerMetric={data.totalRedemptions}
        />
      </Grid>
    </Grid>
  </AppSection>
);

export default ParticipationActivity;
