import React from 'react';
import { Chip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 4,
    color: 'rgba(0,0,0,.6)',
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  gray: {
    border: '1px solid #DADADA',
    backgroundColor: 'transparent',
    color: '#6B6B6B',
  },
  blue: {
    backgroundColor: theme.palette.primary.light,
    color: '#3E7DA2',
  },
  green: {
    backgroundColor: '#E7F1EA',
    color: '#398360',
  },
  red: {
    backgroundColor: '#FCECEC',
    color: '#D04444',
  },
}));

type Props = {
  status:
    | 'DRAFT'
    | 'PENDING'
    | 'ACTIVE'
    | 'PUBLISHED'
    | 'COMPLETE'
    | 'COMPLETED'
    | 'UNPUBLISHED';
  label: string;
};

const StatusChip: React.FC<Props> = ({ status, label }) => {
  const classes = useStyles();

  const statusToClass = {
    DRAFT: classes.gray,
    PENDING: classes.blue,
    ACTIVE: classes.blue,
    PUBLISHED: classes.blue,
    COMPLETE: classes.green,
    COMPLETED: classes.green,
    UNPUBLISHED: classes.red,
  };

  return (
    <Chip
      className={`${classes.root} ${statusToClass[status]}`}
      label={label.toLowerCase()}
    />
  );
};

export default StatusChip;
