import React, { useContext } from 'react';
import { IconButton, Badge, Typography, Drawer, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Trans } from 'react-i18next';
import SVG from '../../../assets/svg';
import ShoppingCartContainer from './ShoppingCartContainer';
import { MarketplaceContext } from '../../../contexts/MarketplaceContext';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import AskATrader from './AskATrader';

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
  },
  addBorder: {
    paddingBottom: '15px',
    flex: 1,
  },
  badge: {
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.primary.dark,
  },
  badgePosition: {
    right: '-8px',
    bottom: '-4px',
  },
  header: {
    display: 'flex',
    margin: '16px 0px 16px 0px',
    alignItems: 'center',
  },
  button: {
    height: 40,
    marginRight: 8,
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const {
    cart,
    cartVisible,
    reloadCart,
    setCartVisible,
    askATraderVisible,
    setAskATraderVisible,
  } = useContext(MarketplaceContext);

  const handleClickOpen = () => {
    reloadCart();
    setCartVisible(true);
  };
  const handleClose = () => {
    setCartVisible(false);
    setAskATraderVisible(false);
  };

  const handleOpenAskATrader = () => {
    setCartVisible(false);
    setAskATraderVisible(true);
  };

  return (
    <>
      <Drawer
        open={cartVisible}
        onClose={handleClose}
        anchor="right"
        className={classes.drawer}>
        <ShoppingCartContainer
          onClose={handleClose}
          askATrader={handleOpenAskATrader}
        />
      </Drawer>
      <Drawer
        open={askATraderVisible}
        onClose={handleClose}
        anchor="right"
        className={classes.drawer}>
        <AskATrader onClose={handleClose} header="Ask A Trader" />
      </Drawer>
      <div className={classes.header}>
        <Typography variant="h1" className={classes.addBorder}>
          <Trans i18nKey="marketplace.header" />
        </Typography>
        {userHasAccess('Client.Marketplace.ContactTrader', 'VIEW') && (
          <Button
            className={classes.button}
            onClick={handleOpenAskATrader}
            variant="contained">
            Ask a Trader
          </Button>
        )}
        {userHasAccess('Client.Marketplace', 'EDIT') && (
          <IconButton aria-label="cart" onClick={handleClickOpen} size="large">
            <Badge
              classes={{
                badge: classes.badge,
                anchorOriginBottomRightCircular: classes.badgePosition,
              }}
              overlap="circular"
              badgeContent={cart.items.length}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <SVG.Marketplace.Cart />
            </Badge>
          </IconButton>
        )}
      </div>
    </>
  );
};

export default Header;
