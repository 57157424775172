import React from 'react';
import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StickyCell from './StickyCell';

const useStyles = makeStyles(() => ({
  noWrap: { whiteSpace: 'nowrap' },
}));

const TextCell: React.FC<Props> = ({
  containerClassName,
  containerStyle,
  children,
  sticky = false,
  ...props
}) => {
  const classes = useStyles();
  const noWrapChildren = <span className={classes.noWrap}>{children}</span>;

  if (sticky) {
    return (
      <StickyCell
        {...props}
        containerClassName={containerClassName}
        containerStyle={containerStyle}>
        {noWrapChildren}
      </StickyCell>
    );
  }

  return (
    <TableCell {...props} className={containerClassName} style={containerStyle}>
      {noWrapChildren}
    </TableCell>
  );
};

type Props = {
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  sticky?: boolean;
  position?: 'left' | 'top' | 'both';
};

export default TextCell;
