import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import SVG from '../../../../assets/svg';
import {
  ProjectContext,
  ProjectDetailsStepData,
} from '../../../../contexts/ProjectContext';
import { Colors } from '../../../../theme/shared';

type Props = {
  children: JSX.Element | null;
  step: ProjectDetailsStepData;
};

const useStyles = makeStyles((theme) => ({
  outerActive: {
    borderRadius: 16,
    padding: 4,
    background: Colors.CobaltBlue,
  },
  outerInactive: { filter: 'blur(3px)', opacity: 0.5, padding: 4 },
  inner: {
    backgroundColor: '#fafafa',
    borderRadius: 14,
  },
  buttonContainer: {
    position: 'relative',
    marginTop: 45,
  },
  button: {
    position: 'absolute',
    right: 0,
    bottom: '-5px',
    height: 40,
    width: 40,
    minWidth: 'auto',
    borderRadius: '50%',
    '&:hover': {
      color: theme.palette.backgrounds.white,
      background: theme.palette.buttons.primary.active,
    },
  },
}));

const CreateProjectStep: React.FC<Props> = ({ children, step: { step } }) => {
  const {
    currentDetailsStep,
    incrementCurrentDetailsStep,
    setCurrentDetailsStep,
    projectDetailsSteps,
  } = useContext(ProjectContext);
  const isActive = step === currentDetailsStep;
  const classes = useStyles();
  return (
    <Box
      id={`create-project-${step}`}
      className={isActive ? classes.outerActive : classes.outerInactive}
      onMouseDownCapture={(e) => {
        // Need to use capture events to catch event on the way down, non-capture events start at the most specific element targeted.
        // Using onMouseDown to prevent dropdowns from opening, onClickCapture didn't prevent this.
        if (currentDetailsStep !== step) {
          e.stopPropagation();
          e.preventDefault();
          setCurrentDetailsStep(step);
        }
      }}
      onFocus={() =>
        currentDetailsStep !== step && setCurrentDetailsStep(step)
      }>
      <Box p={2} className={classes.inner}>
        <section>{children}</section>
        {isActive && currentDetailsStep < projectDetailsSteps.length ? (
          <div className={classes.buttonContainer}>
            <Button
              onClick={incrementCurrentDetailsStep}
              className={classes.button}
              variant="contained">
              <Box width="12px" ml="-11px" mt="2px">
                <SVG.ArrowDownMedium width={24} height={24} />
              </Box>
            </Button>
          </div>
        ) : null}
      </Box>
    </Box>
  );
};
export default CreateProjectStep;
