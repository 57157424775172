import React from 'react';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberSettingsList from '../../../components/member/settings/MemberSettingsList';

const MemberSettings: React.FC = () => (
  <MemberLayout>
    <MemberSettingsList />
  </MemberLayout>
);

export default MemberSettings;
