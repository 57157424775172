import leaderboardDivisionSecondDisabled from './LeaderboardDivisionSecondDisabled';
import leaderboardDivisionSecondEnabled from './LeaderboardDivisionSecondEnabled';

const leaderboardDivisionPlacementSecond = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? leaderboardDivisionSecondDisabled(level, size)
    : leaderboardDivisionSecondEnabled(level, size);

export default leaderboardDivisionPlacementSecond;
