/* eslint-disable react/jsx-no-useless-fragment */
import { Box, ListItem, Typography, Tooltip, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import AppBadge from '../AppBadge';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    width: '100%',
  },
  text: {
    color: theme.palette.common.white,
  },
  textActive: {
    background: Colors.ElectricGreen,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  iconActive: {
    color: Colors.ElectricGreen,
  },
  itemActive: {
    borderRight: `5px solid ${Colors.ElectricGreen}`,
    backgroundColor: theme.palette.primary.dark,
    backgroundPosition: 'bottom 10px right 20px',
  },
  tooltip: {
    padding: 16,
    background: theme.palette.primary.dark,
  },
}));

type Props = {
  to: string;
  label: string;
  icon?: JSX.Element;
  active?: boolean;
  drawer?: boolean;
  hideTooltip?: boolean;
  clickHandler?(arg: number | string | undefined): void;
  actionsCount?: number;
};

const SidebarNavigationItem: React.FC<Props> = ({
  to,
  label,
  active,
  icon,
  drawer,
  hideTooltip,
  clickHandler = () => 0,
  actionsCount,
}) => {
  const classes = useStyles();
  return (
    <Box
      py={drawer ? 2 : 1}
      style={{ paddingLeft: drawer ? '24px' : '16px' }}
      className={active ? classes.itemActive : undefined}
      onClick={() => {
        clickHandler('clicked');
      }}>
      <Tooltip
        placement="left"
        arrow
        classes={{ tooltip: classes.tooltip }}
        title={drawer || hideTooltip ? '' : <Trans i18nKey={label} />}>
        <ListItem button disableGutters>
          <Link to={to} className={classes.link}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '100%' }}>
              <Typography
                variant="h3"
                className={active ? classes.textActive : classes.text}>
                {icon && (
                  <Box
                    component="span"
                    position="relative"
                    mr="12px"
                    top="2px"
                    className={active ? classes.iconActive : undefined}>
                    <>{icon}</>
                  </Box>
                )}{' '}
                {drawer && <Trans i18nKey={label} />}
              </Typography>
              {actionsCount ? <AppBadge actionsCount={actionsCount} /> : null}
            </Grid>
          </Link>
        </ListItem>
      </Tooltip>
    </Box>
  );
};

export default SidebarNavigationItem;
