import { challengeThirdDisabled } from './ChallengeThirdDisabled';
import { challengeThirdEnabled } from './ChallengeThirdEnabled';

// eslint-disable-next-line import/prefer-default-export
export const challengePlacementThird = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? challengeThirdDisabled(level, size)
    : challengeThirdEnabled(level, size);
