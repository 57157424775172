import React from 'react';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberProjectHistoryList from '../../../components/member/projecthistory/MemberProjectHistoryList';

const MemberProjectHistory: React.FC = () => (
  <MemberLayout>
    <MemberProjectHistoryList />
  </MemberLayout>
);

export default MemberProjectHistory;
