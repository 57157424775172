import { Dialog, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import EnumUtils from '../../../utils/enumUtils';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import RoundedFlexBox from '../RoundedFlexBox';
import CreateProjectLocationModal from './CreateProjectLocationModal';
import CreateProjectNavigation from './CreateProjectNavigation';
import CreateProjectStep from './CreateProjectSteps/CreateProjectStep';

const CreateProjectDetails: React.FC = () => {
  const {
    loading,
    projectDetailsSteps,
    shouldShowLocationModalNextStep,
    setShouldShowLocationModalNextStep,
    setCurrentDetailsStep,
  } = useContext(ProjectContext);

  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const stepParam = params.get('step');
    const step = EnumUtils.values(ProjectDetailsStep).find(
      (x) => Number(stepParam) === x,
    );
    if (step) {
      setCurrentDetailsStep(step);
    } else {
      setCurrentDetailsStep(ProjectDetailsStep.title);
    }
  }, [search, loading]);

  return (
    <DataStateHandler loading={loading}>
      <>
        <RoundedFlexBox padding={32} overflow="visible">
          <Grid
            container
            justifyContent="space-between"
            spacing={4}
            style={{ height: '100%' }}>
            <Grid item xs={6}>
              <CreateProjectNavigation />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                overflowY: 'scroll',
                height: '100%',
                scrollBehavior: 'smooth',
              }}>
              {projectDetailsSteps.map((step) => (
                <CreateProjectStep step={step} key={step.title}>
                  <step.stepComponent />
                </CreateProjectStep>
              ))}
            </Grid>
          </Grid>
        </RoundedFlexBox>
        <Dialog
          onClose={() => setShouldShowLocationModalNextStep(false)}
          open={!!shouldShowLocationModalNextStep}>
          <CreateProjectLocationModal />
        </Dialog>
      </>
    </DataStateHandler>
  );
};

export default CreateProjectDetails;
