import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import { Colors } from '../../theme/shared';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 90,
    borderRadius: 8,
  },
  active: {
    background: theme.palette.backgrounds.dark,
  },
  link: {
    textDecoration: 'none',
  },
  text: {
    color: '#FFFFFF',
  },
  activeText: {
    color: Colors.ElectricGreen,
  },
}));

type Props = {
  label: string;
  icon: JSX.Element | null;
  id: string;
  active?: boolean;
  companyId?: string;
};

const CommunicationsNavItem: React.FC<Props> = ({
  label,
  icon,
  id,
  active,
  companyId,
}) => {
  const classes = useStyles();
  return (
    <Link
      to={`/communications/${id}${companyId ? `/${companyId}` : ''}`}
      className={classes.link}>
      <Grid
        container
        direction="column"
        className={`${active ? `${classes.active}` : ''} ${classes.root}`}
        alignItems="center"
        justifyContent="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography
            align="center"
            className={active ? classes.activeText : classes.text}
            variant="body2">
            {label}
          </Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default CommunicationsNavItem;
