import { useMutation, useQuery } from '@apollo/client';
import { Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { OffsetContext } from '../../contexts/OffsetContext';
import { GET_ALL_CATEGORIES } from '../../graphql/projects/projectCategories';
import {
  ProjectPurchaseHistory,
  ProjectPurchaseStatus,
} from '../../types/offsetProject/types';
import {
  projectCategoryFilterLabel,
  projectCategorySearchFunction,
} from '../../utils/projectUtils';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../common/Project/CategoryIconSmall';
import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../common/Table/types';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import {
  CREATE_OFF_PLATFORM_ALLOCATION,
  GET_OFFSET_METRICS,
} from '../../graphql/offsetProject/offsetProject';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import AllocationAppliedDialog from './AllocationAppliedDialog';

const useStyles = makeStyles({
  projectName: {
    width: '35ch',
  },
  input: {
    padding: '8px',
  },
});

const OffPlatform = () => {
  const classes = useStyles();
  const { projectPurchases, userHasEditAccess } = useContext(OffsetContext);
  const { company } = useContext(AuthorizationContext);
  const [offsetTotal, setOffsetTotal] = useState<number | undefined>();

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const [mutationData, setMutationData] = useState<{
    [k: string]: { quantity: number; notes: string };
  }>({});

  const [apply, { loading, error }] = useMutation(
    CREATE_OFF_PLATFORM_ALLOCATION,
  );

  const applyDisabled = (id: string, remaining: number) =>
    !(
      mutationData[id] &&
      mutationData[id].quantity > 0 &&
      mutationData[id].quantity <= remaining
    );

  const getErrorProps = (id: string, remaining: number) => {
    if (mutationData[id] && mutationData[id].quantity > remaining) {
      return {
        error: true,
        helperText: 'Quantity cannot be greater than remaining',
      };
    }
    return undefined;
  };

  const onApply = async (id: string) => {
    const { quantity, notes = '' } = mutationData[id];

    await apply({
      variables: {
        purchaseHistoryId: id,
        companyId: company.id,
        quantity,
        notes,
      },
      refetchQueries: [
        {
          query: GET_OFFSET_METRICS,
          variables: {
            companyId: company.id,
            language: 'en',
            filter: {
              purchaseType: ['COMPANY'],
              uom: ['MT'],
            },
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setOffsetTotal(quantity);
        setMutationData({
          ...mutationData,
          [id]: {
            notes: '',
            quantity: 0,
          },
        });
      },
    });
  };

  const tableStructure: GroupableTableStructure<ProjectPurchaseHistory>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'projectCategoryId',
          display: 'Cat',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          searchFunction: projectCategorySearchFunction(projectCategories),
          resolveFilterLabel: projectCategoryFilterLabel(projectCategories),
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
        },
        {
          key: 'projectTitle',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Project Name',
          render: ({ project: { title } }) => (
            <Typography variant="body2" noWrap className={classes.projectName}>
              {title}
            </Typography>
          ),
        },
        {
          key: 'remaining',
          display: 'Remaining',
          searchable: false,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Remaining',
          format: (remaining) =>
            `${(
              remaining as ProjectPurchaseHistory['remaining']
            ).toLocaleString()} MT`,
        },
        {
          key: 'allocationQuantity',
          display: 'Apply Amount',
          render: ({ id, remaining }) => (
            <Grid container alignItems="center">
              <TextField
                disabled={!userHasEditAccess}
                type="number"
                {...getErrorProps(id, remaining)}
                sx={{ padding: 1, width: 200 }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                value={mutationData[id]?.quantity}
                onChange={(e) =>
                  setMutationData({
                    ...mutationData,
                    [id]: {
                      ...mutationData[id],
                      quantity: parseInt(e.target.value, 10),
                    },
                  })
                }
              />
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                MT
              </Typography>
            </Grid>
          ),
        },
        {
          key: 'allocationNotes',
          display: 'Notes',
          render: ({ id }) => (
            <Grid container alignItems="center">
              <TextField
                disabled={!userHasEditAccess}
                placeholder="Click to add notes"
                type="text"
                sx={{ padding: 1, width: 350 }}
                InputProps={{
                  classes: { input: classes.input },
                }}
                value={mutationData[id]?.notes}
                onChange={(e) =>
                  setMutationData({
                    ...mutationData,
                    [id]: {
                      ...mutationData[id],
                      notes: e.target.value,
                    },
                  })
                }
              />
            </Grid>
          ),
        },
        {
          key: 'apply',
          display: '',
          render: ({ id, remaining }) => (
            <SmallPaddedButton
              onClick={() => onApply(id)}
              disabled={applyDisabled(id, remaining)}>
              Apply
            </SmallPaddedButton>
          ),
        },
      ],
    },
  ];

  const tableDataItems = (projectPurchases || []).map((x) => ({
    ...x,
    projectTitle: x.project.title,
  }));

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="column"
        wrap="nowrap"
        style={{
          height: '100%',
          flex: 1,
        }}>
        <Grid item xs={7} style={{ flex: 1 }}>
          <DataStateHandler
            loading={!projectPurchases || loadingCategories || loading}
            error={errorCategories || error}>
            <RoundedFlexBox>
              <GroupableSelectTable<ProjectPurchaseHistory>
                tableData={
                  tableDataItems?.filter(
                    ({ status, remaining }) =>
                      status === ProjectPurchaseStatus.COMPLETE &&
                      remaining > 0,
                  ) || []
                }
                tableStructure={tableStructure}
                queryVariables={{ 'sort-list': projectCategories }}
                hideStickyCol
              />
            </RoundedFlexBox>
          </DataStateHandler>
        </Grid>
      </Grid>
      <AllocationAppliedDialog
        amount={offsetTotal}
        onClose={() => setOffsetTotal(undefined)}
        open={!!offsetTotal}
        error={!!error}
        loading={loading || loadingCategories}
      />
    </>
  );
};

export default OffPlatform;
