import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import ChartLabel, { ChartLabelProps } from './ChartLabel';
import SVG from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
  rootInner: {
    marginLeft: 24,
    background: '#EFEFEF',
  },
  rootParent: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type Props = ChartLabelProps & {
  childLabels?: ChartLabelProps[];
  arrowClass?: string;
};

const CollapsibleChartLabel: React.FC<Props> = ({
  childLabels: children,
  size,
  arrowClass,
  ...labelProps
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Grid container alignItems="center">
      <Grid item xs={11}>
        <ChartLabel size={size} {...labelProps} />
      </Grid>
      <Grid item xs={1} className={arrowClass}>
        {children ? (
          <IconButton
            data-testid="collapsible-chart-label-arrow"
            onClick={() => setOpen(!open)}>
            <SVG.ArrowDown />
          </IconButton>
        ) : null}
      </Grid>
      {open ? (
        <Grid item xs={11}>
          {children?.map((c) => (
            <ChartLabel
              key={c.label}
              size={size}
              rootClass={classes.rootInner}
              labelColor="#6B6B6B"
              valueColor="#6B6B6B"
              {...c}
            />
          ))}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CollapsibleChartLabel;
