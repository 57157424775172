import { useQuery } from '@apollo/client';
import { Button, Grid, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';

import React from 'react';
import { GET_ALL_FAQS } from '../../../graphql/faq/faq';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import FAQListItem from './FAQListItem';

const useStyles = makeStyles(() => ({
  contactButton: {
    width: '100%',
    maxWidth: 400,
  },
}));

const FAQList: React.FC = () => {
  const classes = useStyles();
  const {
    data: { faqQuestions = [] } = {},
    loading,
    error,
  } = useQuery(GET_ALL_FAQS);
  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container direction="column">
        {faqQuestions.length > 0 ? (
          <List>
            {faqQuestions.map((q) => (
              <FAQListItem key={q.id} question={q} />
            ))}
          </List>
        ) : null}
        <Link to="/member/contact" className={classes.contactButton}>
          <Button
            className={classes.contactButton}
            sx={{ mt: 2, mb: 2 }}
            variant="contained">
            Contact Us
          </Button>
        </Link>
      </Grid>
    </DataStateHandler>
  );
};

export default FAQList;
