import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import TotalInvestments from '../CompanyInvestments/TotalInvestments';
import CategoryInvestments from '../CompanyInvestments/CategoryInvestments';
import {
  CategoryData,
  CurrentBudget,
  InvestmentsData,
} from '../../../../types/dashboard/types';
import OffsetAllocationCard from './OffsetAllocationCard';
import OffsetBudgetCard from './OffsetBudgetCard';
import CheckFeature, {
  isFeatureEnabled,
} from '../../../common/CheckFeature/CheckFeature';
import { AuthorizationContext } from '../../../../contexts/AuthorizationContext';

type Props = {
  currentBudget: CurrentBudget;
  lifetimeBudget: CurrentBudget;
  totalInvestments: InvestmentsData;
  categoryInvestments: Array<CategoryData>;
};

const OffsetOpportunities: React.FC<Props> = ({
  currentBudget,
  totalInvestments,
  categoryInvestments,
  lifetimeBudget,
}) => {
  const { company } = useContext(AuthorizationContext);
  const showingBoth =
    isFeatureEnabled(company, 'offsets') && isFeatureEnabled(company, 'budget');
  return (
    <Grid item container spacing={4}>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h3" color="textPrimary">
            Offset Opportunities
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={showingBoth ? 6 : 12}>
        <CheckFeature featureIds={['budget', 'marketplace']}>
          <OffsetBudgetCard
            style={showingBoth ? undefined : { margin: 'auto' }}
            currentBudget={currentBudget}
          />
        </CheckFeature>
      </Grid>
      <Grid item xs={showingBoth ? 6 : 12}>
        <CheckFeature featureIds={['offsets']}>
          <OffsetAllocationCard
            style={showingBoth ? undefined : { margin: 'auto' }}
            budget={lifetimeBudget}
          />
        </CheckFeature>
      </Grid>
      <Grid item xs={6}>
        <TotalInvestments
          spent={currentBudget.spent || 0}
          investments={totalInvestments}
          context="offset-opportunities"
        />
      </Grid>
      <Grid item xs={6}>
        <CategoryInvestments investments={categoryInvestments} />
      </Grid>
    </Grid>
  );
};

export default OffsetOpportunities;
