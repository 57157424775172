import React from 'react';
import { Typography } from '@mui/material';
import { ComputedDatum, ResponsiveCirclePacking } from '@nivo/circle-packing';
import ProjectBubbleChartLabel, {
  ProjectCircleData,
} from './ProjectBubbleChartLabel';
import TooltipContainer from './ChartTooltips/TooltipContainer';

type Props = {
  data: ProjectCircleData;
  onClick?: (x: ComputedDatum<ProjectCircleData>) => void;
};

const ProjectBubbleChart: React.FC<Props> = ({ data, onClick }) => (
  <>
    {!data?.children?.length && (
      <Typography variant="h1" style={{ margin: 'auto' }}>
        No Project Data Available
      </Typography>
    )}
    {!!data?.children?.length && (
      <div style={{ height: 600 }}>
        <ResponsiveCirclePacking
          data={data}
          value="count"
          colors={(x) => x.data.color || '#fff'}
          borderColor={(x) => x.data.borderColor || x.data.color || ''}
          borderWidth={1}
          padding={4}
          enableLabels
          labelComponent={ProjectBubbleChartLabel}
          tooltip={(x) =>
            x.depth ? (
              <div style={{ maxWidth: 200 }}>
                <TooltipContainer>
                  <span>{x.data.name}</span>
                </TooltipContainer>
              </div>
            ) : (
              <span />
            )
          }
          onClick={onClick}
        />
      </div>
    )}
  </>
);

export default ProjectBubbleChart;
