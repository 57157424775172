import React from 'react';
import NumberUtils, { NumberFormat } from '../../../utils/numberUtils';

import ChartLabel from '../ChartLabel/ChartLabel';
import TooltipContainer from './TooltipContainer';

type Props = {
  format: NumberFormat;
  value: number;
  color?: string;
  id: string | number;
};

export const formatTooltip = (value: number, format: NumberFormat) => {
  let formatted = '';
  switch (format) {
    case 'currency':
      formatted = NumberUtils.format(value, 'currency');
      break;
    case 'percent':
      formatted = NumberUtils.format(value, 'percent');
      break;
    case 'wholePercent':
      formatted = NumberUtils.format(value, 'wholePercent');
      break;
    case 'number':
      formatted = NumberUtils.format(value, 'number');
      break;
    case 'integer':
      formatted = NumberUtils.format(value, 'integer');
      break;
    case 'precisionNumber':
      formatted = NumberUtils.format(value, 'precisionNumber');
      break;
    default:
      formatted = value.toString();
      break;
  }
  return formatted;
};

const FormattedTooltip: React.FC<Props> = ({ format, value, color, id }) => {
  const formatted = formatTooltip(value, format);
  return (
    <TooltipContainer>
      <ChartLabel
        labelColor="#6B6B6B"
        color={color}
        label={id as string}
        value={formatted}
      />
    </TooltipContainer>
  );
};

export default FormattedTooltip;
