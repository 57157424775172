import { Box, Grid, Drawer, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { ProjectInventoryHistory } from '../../../../types/project/types';
import SVG from '../../../../assets/svg';
import { formatDateStringUtc } from '../../../../utils/dateUtils';
import NumberUtils from '../../../../utils/numberUtils';
import RoundedFlexBox from '../../../trader/RoundedFlexBox';
import SmallPaddedButton from '../../Buttons/SmallPaddedButton';
import AlphaColumnFilter from '../../Table/AlphaColumnFilter';
import GroupableSelectTable from '../../Table/GroupableSelectTable';
import {
  GroupableTableStructure,
  SelectTableColumnWithData,
} from '../../Table/types';
import ProjectInventoryHistoryDetails from './ProjectInventoryHistoryDetails';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import ProjectInventoryHistoryWarningModal from './ProjectInventoryHistoryWarningModal';
import { REMOVE_PROJECT_INVENTORY } from '../../../../graphql/projects/projectMutations';
import DataStateHandler from '../../DataStateHandler/DataStateHandler';
import { AuthorizationContext } from '../../../../contexts/AuthorizationContext';

const useStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

type Props = {
  inventoryHistory?: ProjectInventoryHistory[];
};

const ProjectInventoryHistoryTable: React.FC<Props> = ({
  inventoryHistory = [],
}) => {
  const { project } = useContext(ProjectContext);
  const { userHasAccess } = useContext(AuthorizationContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [inventory, setInventory] = useState<
    ProjectInventoryHistory | undefined
  >(undefined);
  const classes = useStyles();
  const [remove, { loading, error }] = useMutation(REMOVE_PROJECT_INVENTORY);

  const canEdit = userHasAccess('ZeroMe.Marketplace', 'EDIT');

  const inventoryHistoryItems = inventoryHistory.map((x) => ({
    ...x,
    displayPricePerUnit: NumberUtils.formatCurrencyAndUom(
      x.pricePerUnit,
      undefined,
      project.uom,
    ),
    displayQuantity: NumberUtils.formatQuantityAndUom(
      x.quantity,
      undefined,
      project.uom,
    ),
  }));

  const sorted = [...inventoryHistoryItems].sort((a, b) => {
    const aDt = new Date(a.purchasedDate).valueOf();
    const bDt = new Date(b.purchasedDate).valueOf();
    return bDt - aDt;
  });

  const tableStructure: GroupableTableStructure<typeof sorted[0]>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'purchasedDate',
          type: AlphaColumnFilter,
          display: 'Purchased On',
          searchable: true,
          searchPlaceholder: 'Search Dates',
          resolveFilterLabel: (data) =>
            data ? formatDateStringUtc(data as string, 'MM/DD/YYYY') : '',
          format: (data) =>
            data ? formatDateStringUtc(data as string, 'MM/DD/YYYY') : '',
        },
        {
          key: 'displayPricePerUnit',
          display: 'Purchase Price',
          type: AlphaColumnFilter,
        },
        {
          key: 'displayQuantity',
          display: 'Total Purchased',
          type: AlphaColumnFilter,
        },
        {
          key: 'totalPurchaseAmount',
          display: 'Purchase Amount',
          resolveFilterLabel: (data) =>
            `${NumberUtils.format((data as number) || 0, 'currency')}`,
          format: (data) =>
            `${NumberUtils.format((data as number) || 0, 'currency')}`,
        },
        ...(canEdit
          ? [
              {
                key: 'delete',
                render: (data) => (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setInventory(data);
                      setIsDeleting(true);
                      return false;
                    }}
                    size="large">
                    <SVG.Delete />
                  </IconButton>
                ),
              } as SelectTableColumnWithData<ProjectInventoryHistory>,
            ]
          : []),
      ],
    },
  ];

  let deleteMessage = `You are about to remove ${inventory?.quantity}${project.uom} from this project.`;
  let disableDelete = false;

  if (inventory?.remainingQuantity !== inventory?.quantity) {
    disableDelete = true;
    deleteMessage = 'Unable to delete inventory that has already been sold';
  }

  if (inventoryHistory.length < 2) {
    disableDelete = true;
    deleteMessage = 'Unable to delete last inventory record';
  }

  // const disableDelete = inventory?.remainingQuantity !== inventory?.quantity;

  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid item>
        <Grid
          container
          style={{ height: 'calc(100vh - 700px)', position: 'relative' }}>
          <RoundedFlexBox>
            <>
              <Grid container justifyContent="flex-end">
                {canEdit && (
                  <Box mr={2} mt={1}>
                    <SmallPaddedButton
                      onClick={() => {
                        setInventory(undefined);
                        setIsEditing(true);
                      }}>
                      + Add More Inventory
                    </SmallPaddedButton>
                  </Box>
                )}
              </Grid>
              <GroupableSelectTable<typeof sorted[0]>
                tableData={sorted}
                tableStructure={tableStructure}
                onRowClick={
                  canEdit
                    ? (selected) => {
                        setInventory(selected);
                        setIsEditing(true);
                      }
                    : undefined
                }
                hideStickyCol
              />
            </>
          </RoundedFlexBox>
        </Grid>
        <Drawer
          open={!!isEditing}
          onClose={() => {
            setInventory(undefined);
            setIsEditing(false);
          }}
          anchor="right">
          <Box className={classes.drawer}>
            <ProjectInventoryHistoryDetails
              onClose={() => {
                setInventory(undefined);
                setIsEditing(false);
              }}
              inventoryHistory={inventory}
            />
          </Box>
        </Drawer>
        <ProjectInventoryHistoryWarningModal
          isOpen={isDeleting}
          message={deleteMessage}
          confirmLabel={
            disableDelete ? 'Cannot Delete' : 'Yes, Remove Inventory'
          }
          onClose={() => {
            setInventory(undefined);
            setIsDeleting(false);
          }}
          disableConfirm={disableDelete}
          onConfirm={() => {
            remove({
              variables: {
                projectId: project.id,
                projectInventoryHistoryId: inventory?.id,
              },
            });
            setInventory(undefined);
            setIsDeleting(false);
          }}
        />
      </Grid>
    </DataStateHandler>
  );
};

export default ProjectInventoryHistoryTable;
