import React from 'react';
import { TableCell } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DomEvents } from './types';

const useStyles = makeStyles(() => ({
  root: {},
  top: {
    position: 'sticky',
    top: 0,
  },
  left: {
    position: 'sticky',
    left: 0,
    zIndex: 999,
  },
}));

const StickyCell: React.FC<Props> = ({
  children,
  position = 'left',
  containerClassName,
  containerStyle,
  sticky = true,
  colSpan,
}) => {
  const classes = useStyles();

  return (
    <TableCell
      colSpan={colSpan}
      style={containerStyle}
      className={(() => {
        if (!sticky) return `${containerClassName} ${classes.root}`;

        switch (position) {
          case 'both':
            return `${containerClassName} ${classes.root} ${classes.left} ${classes.top}`;
          case 'top':
            return `${containerClassName} ${classes.root} ${classes.top}`;
          default:
            return `${containerClassName} ${classes.root} ${classes.left}`;
        }
      })()}>
      {children}
    </TableCell>
  );
};

type StickyPositions = 'left' | 'top' | 'both';

type Props = {
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
  position?: StickyPositions;
  sticky?: boolean;
  colSpan?: number;
} & DomEvents<HTMLTableCellElement>;

export default StickyCell;
