import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import React, { ComponentProps, useState } from 'react';
import { GET_COMPANY_PURCHASE_HISTORY } from '../../../graphql/projects/projects';
import EmptyProjects from '../../../pages/marketplace/EmptyProjects';
import { Company } from '../../../types/company/types';
import EnumUtils from '../../../utils/enumUtils';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import HeaderTab from '../../common/HeaderTabs/HeaderTab';
import HeaderTabs from '../../common/HeaderTabs/HeaderTabs';
import ProjectPurchaseTable from '../../common/ProjectPurchase/ProjectPurchaseTable';

enum Tabs {
  active,
  retired,
}

const tabData: Record<Tabs, string> = {
  [Tabs.active]: 'Active Offset Projects',
  [Tabs.retired]: 'Retired Projects',
};

type Props = {
  companyId: Company['id'];
};

const ProjectGrids = ({ companyId }: Props) => {
  const [value, setValue] = useState(Tabs.active);

  const handleChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setValue(newValue);
  };

  const {
    loading,
    error,
    data: {
      offsetProjectsByCompany: { items: projectPurchases } = {
        items: undefined,
      },
    } = {},
  } = useQuery(GET_COMPANY_PURCHASE_HISTORY(true), {
    variables: {
      companyId,
      filter: {
        purchaseType: ['COMPANY'],
      },
    },
  });

  const tableData = projectPurchases?.map((projectPurchase) => {
    const { allocated, remaining } = projectPurchase;

    return {
      ...projectPurchase,
      totalPurchased: allocated + remaining,
      totalSpent: projectPurchase.price * (allocated + remaining),
    };
  }) as ComponentProps<typeof ProjectPurchaseTable>['tableData'];

  const active = tableData?.filter(({ remaining }) => !!remaining);
  const retired = tableData?.filter(({ remaining }) => !remaining);

  const activeData = (value === Tabs.active ? active : retired) || [];
  const noDataMessage =
    value === Tabs.active
      ? 'No projects have been purchased.'
      : 'No projects have been retired.';

  return (
    <Box
      sx={{
        paddingBottom: 24,
      }}>
      <HeaderTabs value={value} onChange={handleChange}>
        {EnumUtils.values(Tabs).map((tab) => (
          <HeaderTab
            key={tab}
            label={tabData[tab]}
            style={{
              minWidth: `${tabData[tab].replaceAll(' ', '').length - 2}ch`,
            }}
          />
        ))}
      </HeaderTabs>
      <DataStateHandler loading={loading} error={error}>
        <div style={{ height: 500 }}>
          {(!activeData?.length && (
            <EmptyProjects
              message={noDataMessage}
              showBrowseMarketplaceButton={false}
            />
          )) || <ProjectPurchaseTable tableData={activeData} />}
        </div>
      </DataStateHandler>
    </Box>
  );
};

export default ProjectGrids;
