import React, { useContext, useState } from 'react';
import { Box, Drawer, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { t } from 'i18next';

import QuizDetails from './forms/QuizDetails';
import QuizMetrics from './QuizMetrics';
import QuizStatusChip from './QuizStatusChip';
import { LocalizedQuiz, QuizType } from './types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import PageTitle from '../common/PageTitle';
import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../common/Table/types';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import SVG from '../../assets/svg';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import NumberUtils from '../../utils/numberUtils';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 16,
  },
  drawer: {
    maxWidth: 960,
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      minWidth: 680,
    },
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
}));

enum QuizPanel {
  DETAILS = 0,
  METRICS = 1,
}

type Props = {
  quizType: QuizType;
};

const QuizList = (props: Props) => {
  const { quizType } = props;
  const classes = useStyles();
  const [quizId, setQuizId] = useState<string>();
  const [openPanel, setOpenPanel] = useState<QuizPanel | undefined>();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { companyId, quizzes, polls } = useContext(CommunicationsContext);

  const canEdit = companyId
    ? userHasAccess('Client.Communication.Quizzes', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Quizzes', 'EDIT');

  const openDetails = (quiz: LocalizedQuiz) => {
    setQuizId(quiz.id);
    setOpenPanel(QuizPanel.DETAILS);
  };

  const quizData = quizType === QuizType.QUIZ ? quizzes : polls;

  const tableStructure: GroupableTableStructure<LocalizedQuiz>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'title',
          display: t('title'),
          searchable: true,
          type: AlphaColumnFilter,
          render: (c) => (
            <Typography
              variant="body2"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: 700,
              }}>
              {c.title}
            </Typography>
          ),
        },
        {
          key: 'status',
          display:
            quizType === QuizType.QUIZ
              ? t('quiz.quizStatus')
              : t('poll.pollStatus'),
          searchable: true,
          type: AlphaColumnFilter,
          render: (c) => <QuizStatusChip quiz={c} />,
        },
        {
          key: 'publishDate',
          type: AlphaColumnFilter,
          display: t('publishDate'),
          resolveFilterLabel: (value) =>
            value ? format(new Date(value as string), 'M/d/yy') : '',
          render: (c) => (
            <Typography variant="body2">
              {c.publishDate ? format(new Date(c.publishDate), 'M/d/yy') : '--'}
            </Typography>
          ),
        },
        {
          key: 'metrics.attempts',
          display: quizType === QuizType.QUIZ ? t('attempts') : t('responses'),
          render: (c) => (
            <Typography
              variant="body2"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: 700,
              }}>
              {c?.metrics?.quizCount ?? 0}
            </Typography>
          ),
        },
        ...(quizType === QuizType.QUIZ
          ? [
              {
                key: 'metrics.averageAccuracy',
                display: t('avgAccuracy'),
                render: (c: LocalizedQuiz) => (
                  <Typography
                    variant="body2"
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      maxWidth: 700,
                    }}>
                    {c?.metrics?.averageAccuracy != null
                      ? NumberUtils.format(
                          c?.metrics?.averageAccuracy,
                          'wholePercent',
                        )
                      : '--'}
                  </Typography>
                ),
              },
            ]
          : []),
        {
          key: 'actions',
          display: '',
          render: (c) => (
            <>
              <IconButton
                aria-label={t('viewDetails')}
                title={t('viewDetails')}
                onClick={(e) => {
                  e.stopPropagation();
                  openDetails(c);
                }}>
                <SVG.Edit />
              </IconButton>
              <IconButton
                aria-label={t('results')}
                title={t('results')}
                onClick={(e) => {
                  e.stopPropagation();
                  setQuizId(c.id);
                  setOpenPanel(QuizPanel.METRICS);
                }}>
                <SVG.ListChecked />
              </IconButton>
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <Box px={8} py={4}>
        <Grid container direction="column">
          <Grid item>
            <PageTitle
              title={
                quizType === QuizType.QUIZ ? t('quiz.quizzes') : t('poll.polls')
              }>
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {canEdit && (
                  <SmallPaddedButton
                    onClick={() => {
                      setQuizId(undefined);
                      setOpenPanel(QuizPanel.DETAILS);
                    }}>
                    + {t('createNew')}
                  </SmallPaddedButton>
                )}
              </>
            </PageTitle>
          </Grid>
          <Grid item className={classes.table}>
            <Grid
              container
              direction="column"
              style={{ height: 'calc(100vh - 110px)' }}>
              <RoundedFlexBox>
                <GroupableSelectTable<LocalizedQuiz>
                  tableData={quizData}
                  tableStructure={tableStructure}
                  hideStickyCol
                  onRowClick={openDetails}
                />
              </RoundedFlexBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        open={openPanel === QuizPanel.DETAILS}
        onClose={() => {
          setQuizId(undefined);
          setOpenPanel(undefined);
        }}
        anchor="right">
        <Box className={classes.drawer}>
          <QuizDetails
            quizId={quizId}
            newQuizType={quizType}
            onClose={() => {
              setOpenPanel(undefined);
              setQuizId(undefined);
            }}
          />
        </Box>
      </Drawer>
      <Drawer
        open={openPanel === QuizPanel.METRICS}
        onClose={() => {
          setQuizId(undefined);
          setOpenPanel(undefined);
        }}
        anchor="right">
        <Box className={classes.drawer}>
          {quizId && (
            <QuizMetrics
              quizId={quizId}
              onClose={() => {
                setQuizId(undefined);
                setOpenPanel(undefined);
              }}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default QuizList;
