import habitAddedDisabled from './HabitAddedDisabled';
import habitAddedEnabled from './HabitAddedEnabled';

const habitAdded = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled ? habitAddedDisabled(level, size) : habitAddedEnabled(level, size);

export default habitAdded;
