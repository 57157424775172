import { Badge } from '../../../types/badge/types';

// eslint-disable-next-line import/prefer-default-export
export const getPreviousNumBadges = (num: number, badge: Badge) => {
  const badges = [];
  for (let i = 0; i < num; i += 1) {
    badges.push({
      ...badge,
      level: (badge.level ?? 0) - i - 1,
    });
  }
  return badges.filter((b) => b.level > 0);
};
