import { Grid } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import AppDatePicker from '../common/AppDatePicker';

const CompanyClientSecretExpirationDateForm: React.FC = () => (
  <Grid item xs={12}>
    <Field
      name="clientSecretExpirationDate"
      component={AppDatePicker}
      placeholder=""
      fullWidth
    />
  </Grid>
);

export default CompanyClientSecretExpirationDateForm;
