import React, { useEffect, useState } from 'react';
import { IconButton, Grid, Typography, Button, Box } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { orderBy } from 'lodash';
import omitDeep from 'omit-deep-lodash';

import { Avatar } from '../../../types/common';
import { GET_AVATAR_METADATA } from '../../../graphql/shared';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import SVG from '../../../assets/svg';
import { CartoonSvgAvatar } from '../../common/CartoonSVGAvatar';
import { UPDATE_USER_AVATAR } from '../../../graphql/user/userMutations';

const useStyles = makeStyles(() => ({
  root: {
    padding: 16,
    minWidth: 300,
  },
}));

type Props = {
  avatar?: Avatar;
  onClose: () => void;
};

const EditAvatar: React.FC<Props> = ({ onClose, avatar: initialAvatar }) => {
  const classes = useStyles();
  const [update, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_USER_AVATAR);
  const {
    data: { avatarMetadata } = {},
    loading,
    error,
  } = useQuery(GET_AVATAR_METADATA);

  const cleanExclusive = (
    cleanExclusiveAvatar: Avatar,
    keepComponentId?: string,
  ) => {
    if (avatarMetadata?.length) {
      let finalComponents = [...cleanExclusiveAvatar.components];

      avatarMetadata[0].exclusiveComponents.forEach((each) => {
        const selectedEc = cleanExclusiveAvatar.components.filter((filter) =>
          each.includes(filter.componentId),
        );
        if (selectedEc.length > 1) {
          const cleanEc = selectedEc.filter(
            (filter) => filter.componentId !== keepComponentId,
          );

          // Remove random
          const toRemove = cleanEc[Math.floor(Math.random() * cleanEc.length)];
          finalComponents = finalComponents.filter(
            (filter) => filter.componentId !== toRemove.componentId,
          );
        }
      });

      return {
        ...cleanExclusiveAvatar,
        components: finalComponents,
      };
    }

    return null;
  };

  const orderComponents = (internalAvatar: Avatar | null | undefined) => {
    if (internalAvatar) {
      const components = [...internalAvatar.components];
      const orderedComponents = orderBy(
        components,
        (item) => item.componentId,
        'asc',
      );

      return { ...internalAvatar, components: orderedComponents };
    }

    return null;
  };

  const randomize = () => {
    if (avatarMetadata?.length) {
      return cleanExclusive({
        ...initialAvatar,
        metadataId: avatarMetadata[0].id,
        components: avatarMetadata[0].components.flatMap((component) => {
          const options = [
            ...component.options.map((map) => map.id),
            ...(component.allowNone ? [null] : []),
          ];

          const optionId = options[Math.floor(Math.random() * options.length)];

          if (optionId) {
            return [
              {
                componentId: component.id,
                optionId,
                color: component.colors
                  ? component.colors[
                      Math.floor(Math.random() * component.colors.length)
                    ]
                  : undefined,
              },
            ];
          }

          return [];
        }),
      });
    }

    return null;
  };

  const [avatar, setAvatar] = useState<Avatar | undefined | null>(
    initialAvatar,
  );

  useEffect(() => {
    if (!initialAvatar && avatarMetadata?.length) {
      setAvatar(randomize());
    }
  }, [avatarMetadata, initialAvatar]);
  const metaData = avatarMetadata?.[0]?.components ?? [];

  const toggleComponentOption = (id: string) => {
    const component = metaData.find((m) => m.id === id);

    if (!component) {
      return;
    }
    let newComponents = [...(avatar?.components || [])];

    const current = newComponents.find((x) => x.componentId === id);

    if (current) {
      const curIndex = component.options.findIndex(
        (x) => x.id === current.optionId,
      );

      if (curIndex === component.options.length - 1) {
        if (component.allowNone) {
          newComponents = newComponents.filter((x) => x.componentId !== id);
        } else {
          current.optionId = component.options[0].id;
        }
      } else {
        current.optionId = component.options[curIndex + 1].id;
      }
    } else {
      newComponents.push({
        componentId: id,
        color: component.colors?.[0] ?? '',
        optionId: component.options?.[0].id,
      });
    }

    setAvatar(
      orderComponents(
        cleanExclusive(
          {
            ...avatar,
            metadataId: avatarMetadata?.[0].id,
            components: newComponents,
          },
          id,
        ),
      ),
    );
  };

  const toggleComponentColor = (id: string) => {
    if (avatarMetadata?.length && avatar) {
      const metaComponent = avatarMetadata[0].components.find(
        (find) => find.id === id,
      );
      const colors = metaComponent?.colors;
      const current = avatar.components.find((find) => find.componentId === id);

      if (!metaComponent || !colors || !current) {
        return;
      }

      setAvatar(
        orderComponents({
          ...avatar,
          components: avatar.components.map((map) => {
            if (map.componentId !== id) {
              return map;
            }
            const nextIndex = current
              ? colors.findIndex((y) => y === current.color) + 1
              : 0;
            return {
              ...map,
              color:
                colors[
                  nextIndex === metaComponent.colors?.length ? 0 : nextIndex
                ],
            };
          }),
        }),
      );
    }
  };

  const toggleRandom = () => {
    setAvatar(orderComponents(randomize()));
  };

  const saveAvatar = async () => {
    if (avatar) {
      await update({
        variables: { avatar: omitDeep(avatar, '__typename') },
        onCompleted: () => onClose(),
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      className={classes.root}
      justifyContent="space-between"
      alignItems="start">
      <DataStateHandler
        loading={loading || updateLoading}
        error={error || updateError}>
        <>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h3">Edit Avatar</Typography>
              <IconButton onClick={onClose}>
                <SVG.Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box margin="auto" width="max-content">
              {avatar ? <CartoonSvgAvatar avatar={avatar} size="lg" /> : null}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Button
              sx={{ mb: 1 }}
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => toggleRandom()}>
              Randomize
            </Button>
          </Grid>

          {metaData.map((meta) => {
            const avatarComponent = avatar?.components.find(
              (find) => find.componentId === meta.id,
            );

            const selectedOption = meta.options.find(
              (find) => find.id === avatarComponent?.optionId,
            );
            return (
              <Grid item xs={12} key={meta.id}>
                {(meta.options?.length || 0) > 1 && (
                  <Button
                    sx={{ mb: 1 }}
                    color="primary"
                    variant="outlined"
                    fullWidth
                    onClick={() => toggleComponentOption(meta.id)}>
                    {meta.title}: {selectedOption?.title || 'None'}
                  </Button>
                )}

                {(meta.colors?.length || 0) > 1 && (
                  <Button
                    color="primary"
                    variant="outlined"
                    fullWidth
                    onClick={() => toggleComponentColor(meta.id)}>
                    {meta.title} Color
                  </Button>
                )}
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button
              sx={{ mb: 1 }}
              color="primary"
              variant="contained"
              fullWidth
              disabled={!avatar}
              onClick={saveAvatar}>
              Save
            </Button>
          </Grid>
        </>
      </DataStateHandler>
    </Grid>
  );
};

export default EditAvatar;
