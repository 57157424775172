import { CSSProperties } from '@mui/styles/withStyles';

const remToPx = (size?: string | number) => {
  if (typeof size === 'string') {
    if (size.toLowerCase().includes('rem')) {
      return (
        parseFloat(size) *
        parseFloat(getComputedStyle(document.documentElement).fontSize)
      );
    }
  }

  return size;
};

// eslint-disable-next-line import/prefer-default-export
export const typographyToVictory = (props: CSSProperties) => ({
  ...props,
  // Not sure why victory requires px?
  fontSize: remToPx(props.fontSize),
  lineHeight: remToPx(props.lineHeight),
});
