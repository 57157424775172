import { useContext, useEffect } from 'react';
import { useFormikContext, setNestedObjectValues } from 'formik';
import {
  ProjectContext,
  ProjectDetailsStep,
  ProjectStep,
} from '../../../../contexts/ProjectContext';

const ProjectContextFieldValidator = ({
  step,
}: {
  step: ProjectDetailsStep;
}) => {
  const context = useFormikContext();
  const { steps, setProjectDetailsStepIsValid } = useContext(ProjectContext);

  useEffect(() => {
    if (steps[ProjectStep.details].forceValidation) {
      context.validateForm().then((errors) => {
        if (Object.keys(errors).length > 0) {
          context.setTouched(setNestedObjectValues(errors, true));
        }
      });
    }
  }, [steps, step]);

  useEffect(() => {
    setProjectDetailsStepIsValid(step, context.isValid);
  }, [context.errors, step]);

  return null;
};

export default ProjectContextFieldValidator;
