import React, { useContext, useState } from 'react';
import { Drawer, Grid, IconButton, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';
import moment from 'moment';

import ChallengeStatusChip from './ChallengeStatusChip';
import ChallengeDetails from './forms/ChallengeDetails';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import PageTitle from '../common/PageTitle';
import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../common/Table/types';
import RoundedFlexBox from '../trader/RoundedFlexBox';
import SVG from '../../assets/svg';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import { getLeaderboardsQuery } from '../../graphql/contest/contests';
import LeaderboardTable from '../member/contest/LeaderboardTable';
import { Contest, LeaderboardRollup } from '../../types/contest/types';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    padding: '32px 64px',
  },
  table: {
    flexDirection: 'column',
    marginTop: 16,
    height: 'calc(100vh - 110px)',
  },
  drawer: {
    overflowX: 'hidden',
    [theme.breakpoints.up(680)]: {
      width: 680,
    },
    [theme.breakpoints.down(680)]: {
      width: '100vw',
    },
  },
  leaderboardDrawer: {
    padding: '8px 24px',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 700,
  },
}));

enum ChallengePanel {
  DETAILS = 0,
  LEADERBOARD = 1,
}

const ChallengesList: React.FC = () => {
  const classes = useStyles();
  const [selectedContest, setSelectedContest] = useState<Contest>();
  const [openPanel, setOpenPanel] = useState<ChallengePanel | undefined>();
  const { company, userHasAccess } = useContext(AuthorizationContext);
  const { challenges } = useContext(CommunicationsContext);

  const canEdit = userHasAccess('Client.Communication.Challenges', 'EDIT');

  const {
    loading,
    error,
    data: { leaderboards: [leaderboard] = [undefined] } = {},
  } = useQuery(getLeaderboardsQuery(true, true), {
    variables: {
      contestId: selectedContest?.id,
      companyId: company.id,
      rollup: LeaderboardRollup.PARENT,
    },
    skip: !selectedContest || openPanel !== ChallengePanel.LEADERBOARD,
  });

  const openDetails = (contest?: Contest) => {
    setSelectedContest(contest);
    setOpenPanel(ChallengePanel.DETAILS);
  };

  const openLeaderboard = (contest: Contest) => {
    setSelectedContest(contest);
    setOpenPanel(ChallengePanel.LEADERBOARD);
  };

  const closePanel = () => {
    setOpenPanel(undefined);
  };

  const tableStructure: GroupableTableStructure<Contest>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'title',
          display: t('title'),
          searchable: true,
          type: AlphaColumnFilter,
          render: ({ title }) => (
            <Typography className={classes.label} variant="body2">
              {title}
            </Typography>
          ),
        },
        {
          key: 'status',
          display: t('status'),
          searchable: true,
          type: AlphaColumnFilter,
          render: (contest) => <ChallengeStatusChip contest={contest} />,
        },
        {
          key: 'updatedDate',
          display: t('createdModified'),
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) =>
            value ? moment(value as string).format('M/D/YY') : '',
          render: ({ updatedDate }) => (
            <Typography variant="body2">
              {updatedDate ? moment(updatedDate).format('M/D/YY') : '--'}
            </Typography>
          ),
        },
        {
          key: 'duration.startDate',
          display: t('startDate'),
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) =>
            value ? moment(value as string).format('M/D/YY') : '',
          render: ({ duration }) => (
            <Typography variant="body2">
              {duration.startDate
                ? moment(duration.startDate).format('M/D/YY')
                : '--'}
            </Typography>
          ),
        },
        {
          key: 'duration.endDate',
          display: t('endDate'),
          type: AlphaColumnFilter,
          resolveFilterLabel: (value) =>
            value ? moment(value as string).format('M/D/YY') : '',
          render: ({ duration }) => (
            <Typography variant="body2">
              {duration.endDate
                ? moment(duration.endDate).format('M/D/YY')
                : '--'}
            </Typography>
          ),
        },
        {
          key: 'actions',
          display: '',
          render: (contest) => {
            const isActive =
              !contest.isDraft &&
              moment().isSameOrAfter(moment(contest.duration.startDate));
            return (
              <>
                <IconButton
                  aria-label={t('viewDetails')}
                  title={t('viewDetails')}
                  onClick={(e) => {
                    e.stopPropagation();
                    openDetails(contest);
                  }}>
                  <SVG.Edit />
                </IconButton>
                {isActive && (
                  <IconButton
                    aria-label={t('contest.viewLeaderboard')}
                    title={t('contest.viewLeaderboard')}
                    onClick={(e) => {
                      e.stopPropagation();
                      openLeaderboard(contest);
                    }}>
                    <SVG.ListChecked />
                  </IconButton>
                )}
              </>
            );
          },
        },
      ],
    },
  ];

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item>
          <PageTitle title={t('contest.challenges')}>
            {canEdit ? (
              <SmallPaddedButton onClick={() => openDetails()}>
                + {t('createNew')}
              </SmallPaddedButton>
            ) : undefined}
          </PageTitle>
        </Grid>
        <Grid container item className={classes.table}>
          <RoundedFlexBox>
            <GroupableSelectTable<Contest>
              tableData={challenges}
              tableStructure={tableStructure}
              hideStickyCol
              onRowClick={openDetails}
            />
          </RoundedFlexBox>
        </Grid>
      </Grid>
      <Drawer
        open={openPanel === ChallengePanel.DETAILS}
        onClose={closePanel}
        anchor="right">
        <Grid container className={classes.drawer}>
          <ChallengeDetails contest={selectedContest} onClose={closePanel} />
        </Grid>
      </Drawer>
      <Drawer
        open={openPanel === ChallengePanel.LEADERBOARD}
        onClose={closePanel}
        anchor="right">
        <Grid
          container
          className={`${classes.drawer} ${classes.leaderboardDrawer}`}>
          <IconButton
            aria-label="close"
            onClick={closePanel}
            size="large"
            edge="start">
            <SvgIcon component={SVG.Back} />
          </IconButton>
          <DataStateHandler loading={loading} error={error}>
            {leaderboard?.contestRanking?.length ? (
              <LeaderboardTable
                leaderboard={leaderboard}
                alwaysShowCurrentUser={false}
                maxHeight={780}
              />
            ) : (
              <Grid>
                <Typography variant="h2" align="center">
                  {t('contest.calculatingResults')}
                </Typography>
                <Typography variant="body1" align="center">
                  {t('contest.calculatingResultsDescription')}
                </Typography>
              </Grid>
            )}
          </DataStateHandler>
        </Grid>
      </Drawer>
    </>
  );
};

export default ChallengesList;
