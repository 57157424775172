import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Grid, List, ListItemButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import moment from 'moment';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { QuizType } from '../../communications/types';
import SVG from '../../../assets/svg';
import { GET_MEMBER_QUIZZES } from '../../../graphql/communications/memberCommunications';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: 16,
  },
  item: {
    height: 80,
    color: theme.palette.primary.dark,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
  },
  expireDate: {
    color: Colors.TertiaryText,
    marginRight: 14,
  },
}));

const MemberPollResultsList: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const {
    loading,
    error,
    data: { memberQuizzes: { items: polls = [] } = {} } = {},
  } = useQuery(GET_MEMBER_QUIZZES, {
    variables: {
      types: [QuizType.POLL],
      sortBy: 'expireDate',
      sortDirection: 'DESC',
    },
  });

  return (
    <DataStateHandler loading={loading} error={error}>
      <Typography className={classes.title} variant="h3">
        {t('poll.pollResults')}
      </Typography>
      <Grid container direction="column">
        {!polls.length && (
          <Typography align="center" variant="body1">
            {t('poll.noPollResults')}
          </Typography>
        )}
        <List>
          {polls.map((poll) => {
            const expireDate = moment(poll?.expireDate);
            const isPollClosed =
              expireDate.isValid() && expireDate.isBefore(moment());

            const isPollCompleted = poll.questions?.every(
              (p) =>
                poll.answers?.answers?.find(
                  (a) => a.questionId === p.questionId,
                )?.answerIds?.length,
            );

            const handleClick = () =>
              isPollCompleted || isPollClosed
                ? history.push(`/member/poll-results/${poll.id}`)
                : history.push(`/member/poll/${poll.id}`);

            return (
              <ListItemButton className={classes.item} onClick={handleClick}>
                <Grid container>
                  <Typography variant="body1">{poll.title}</Typography>
                </Grid>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Typography
                    className={classes.expireDate}
                    variant="subtitle1">
                    {isPollClosed ? expireDate.format('M/D/YYYY') : 'Active'}
                  </Typography>
                  <SVG.CaretRightSmall />
                </Grid>
              </ListItemButton>
            );
          })}
        </List>
      </Grid>
    </DataStateHandler>
  );
};

export default MemberPollResultsList;
