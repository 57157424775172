import React from 'react';
import { useLocation } from 'react-router-dom';

import MemberLayout from '../../../components/layouts/MemberLayout';
import BadgeDetails from '../../../components/member/badge/BadgeDetails';
import { Badge } from '../../../types/badge/types';

const MemberBadgeDetails: React.FC = () => {
  const { state } = useLocation();

  return (
    <MemberLayout>
      <BadgeDetails badge={state as Badge} />
    </MemberLayout>
  );
};

export default MemberBadgeDetails;
