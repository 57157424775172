import { Box, Typography, Grid, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';
import { format } from 'date-fns';

import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import RoundedFlexBox from '../trader/RoundedFlexBox';

import { GroupableTableStructure } from '../common/Table/types';
import { Notification } from './types';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import PageTitle from '../common/PageTitle';
import NotificationDetails from './forms/NotificationDetails';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 16,
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const tableStructure: GroupableTableStructure<
  Notification & { publishDateOrSentDate: string }
>[] = [
  {
    key: 'main',
    columns: [
      {
        key: 'title',
        display: 'Title',
        searchable: true,
        type: AlphaColumnFilter,
        searchPlaceholder: 'Search notification',
        render: (c) => (
          <Typography
            variant="body2"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 700,
            }}>
            {c.title}
          </Typography>
        ),
      },
      {
        key: 'status',
        display: 'Status',
        render: (c) => (
          <Typography variant="body2">
            {c.sentDate ? (
              <span style={{ color: '#398360' }}>Sent</span>
            ) : (
              'Scheduled'
            )}
          </Typography>
        ),
      },
      {
        key: 'createdDate',
        type: AlphaColumnFilter,
        display: 'Created Date',
        resolveFilterLabel: (value) =>
          value ? format(new Date(value), 'M/d/yy') : '',
        render: (c) => (
          <Typography variant="body2">
            {c.createdDate ? format(new Date(c.createdDate), 'M/d/yy') : ''}
          </Typography>
        ),
      },
      {
        key: 'publishDateOrSentDate',
        type: AlphaColumnFilter,
        display: 'Publish Date',
        resolveFilterLabel: (value) =>
          value ? format(new Date(value), 'M/d/yy H:mm a') : '',
        render: (c) => (
          <Typography variant="body2">
            {c.publishDateOrSentDate
              ? format(new Date(c.publishDateOrSentDate), 'M/d/yy h:mm a')
              : ''}
          </Typography>
        ),
      },
    ],
  },
];

const NotificationsList: React.FC = () => {
  const classes = useStyles();
  const [notification, setNotification] = useState<Notification | undefined>(
    undefined,
  );
  const [isOpen, setIsOpen] = useState(false);
  const { userHasAccess } = useContext(AuthorizationContext);
  const { notifications, companyId } = useContext(CommunicationsContext);

  const canEdit = companyId
    ? userHasAccess('Client.Communication.PushNotifications', 'EDIT')
    : userHasAccess('ZeroMe.Communication.PushNotifications', 'EDIT');

  return (
    <>
      <Box px={8} py={4}>
        <Grid container direction="column">
          <Grid item>
            <PageTitle title="Notifications">
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {canEdit && (
                  <SmallPaddedButton
                    onClick={() => {
                      setIsOpen(true);
                      setNotification(undefined);
                    }}>
                    + Create New
                  </SmallPaddedButton>
                )}
              </>
            </PageTitle>
          </Grid>
          <Grid item className={classes.table}>
            <Grid
              container
              direction="column"
              style={{ height: 'calc(100vh - 110px)' }}>
              <RoundedFlexBox>
                <GroupableSelectTable<
                  Notification & { publishDateOrSentDate: string }
                >
                  tableData={notifications.map((t) => ({
                    ...t,
                    publishDateOrSentDate: t.publishDate || t.sentDate || '',
                  }))}
                  tableStructure={tableStructure}
                  hideStickyCol
                  onRowClick={(q) => {
                    const originalNotification = notifications.find(
                      (find) => find.id === q.id,
                    );

                    if (originalNotification) {
                      setNotification(originalNotification);
                      setIsOpen(true);
                    }
                  }}
                />
              </RoundedFlexBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        open={!!isOpen}
        onClose={() => {
          setNotification(undefined);
          setIsOpen(false);
        }}
        anchor="right">
        <Box className={classes.drawer}>
          <NotificationDetails
            notification={notification}
            onClose={() => {
              setIsOpen(false);
              setNotification(undefined);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default NotificationsList;
