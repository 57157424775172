import { Bar, BarCanvas, ResponsiveBar } from '@nivo/bar';
import { ComponentProps } from 'react';

type MinMaxValues = Pick<
  ComponentProps<typeof ResponsiveBar | typeof Bar | typeof BarCanvas>,
  'minValue' | 'maxValue'
>;

export default class ChartUtils {
  /**
   * Creates an object that contains the minValue and maxValue props to be used with @nivo/bar charts.
   * @param hasData Whether the chart has valid data to display
   * @returns An object that can be spread into the chart props.
   * @example
   * <ResponsiveBar
   *   {...barProps}
   *   {...ChartUtils.configureChartMinMaxValues(hasData)}
   * />
   */
  static configureChartMinMaxValues = (hasData: boolean): MinMaxValues => ({
    minValue: hasData ? 'auto' : 0,
    maxValue: hasData ? 'auto' : 1,
  });
}
