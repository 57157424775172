import React from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { t } from 'i18next';

import SVG from '../../../assets/svg-v2';
import { UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS } from '../../../graphql/questionnaire/questions';

export type PostalCodeChangedCardProps = {
  snooze: () => void;
  refetch: () => void;
};

export function PostalCodeChangedCard({
  snooze,
  refetch,
}: PostalCodeChangedCardProps) {
  const history = useHistory();

  const [updateUserOnboardingQuestionnaireStatus] = useMutation(
    UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS,
  );

  return (
    <Grid
      container
      flexDirection="column"
      style={{
        padding: 16,
        border: '1px dashed grey',
        borderRadius: 8,
        marginBottom: 24,
      }}>
      <Grid
        item
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}>
        <Grid item>
          <Typography variant="h2">{t('postalCodeChanged.title')}</Typography>
        </Grid>
        <Grid item>
          <IconButton onClick={snooze}>
            <SVG.Close color="black" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item pb={2}>
        <Typography variant="body2">
          {t('postalCodeChanged.description')}
          <br />
          <br />
          {t('postalCodeChanged.subDescription')}
        </Typography>
      </Grid>
      <Grid item container flexDirection="row" spacing={2}>
        <Grid item xs>
          <Button
            variant="outlined"
            color="secondary"
            onClick={async () => {
              await updateUserOnboardingQuestionnaireStatus({
                variables: {
                  postalCodeChanged: false,
                },
              });

              refetch();
            }}
            fullWidth
            size="small">
            {t('postalCodeChanged.nothingHasChanged')}
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              snooze();
              history.push(`/member/onboarding`);
            }}
            fullWidth>
            {t('postalCodeChanged.letsReview')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
