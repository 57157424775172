import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AuthorizedRoute from '../common/AuthorizedRoute';
import Loading from '../common/Loading';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import CreateDashboardProvider from '../../contexts/DashboardContext';
import MarketplaceProvider from '../../contexts/MarketplaceContext';
import Communications from '../../pages/communications/Communications';
import Engagement from '../../pages/dashboards/Engagement/Engagement';
import Impact from '../../pages/dashboards/Impact/Impact';
import Employees from '../../pages/employees/Employees';
import Marketplace from '../../pages/marketplace/Marketplace';
import Offset from '../../pages/offset/Offset';
import Settings from '../../pages/settings/Settings';
import CreateProject from '../../pages/Trader/CreateProject';
import TraderClients from '../../pages/Trader/TraderClients';
import OffsetDashboard from '../../pages/Trader/OffsetDashboard';
import TraderProjects from '../../pages/Trader/TraderProjects';
import Habits from '../../pages/habits/Habits';

export const ClientDashboardProvider = CreateDashboardProvider();
export const TraderDashboardProvider = CreateDashboardProvider();

const AdminRouter = () => {
  const { loading, user, isInheritingSubscriptionData } =
    useContext(AuthorizationContext);

  if (loading) {
    return <Loading />;
  }

  const defaultRoute = [
    { ability: 'Client.Impact', url: '/impact' },
    { ability: 'Client.Marketplace', url: '/marketplace' },
    { ability: 'ZeroMe.TraderDashboard', url: '/trader/dashboard' },
    { ability: 'ZeroMe.Communication', url: '/communications/discover' },
    { ability: 'ZeroMe.Clients', url: '/trader/clients' },
  ].find((x) =>
    user?.roleConfig?.abilities.some((y) => y.name.startsWith(x.ability)),
  )?.url;

  return (
    <Switch>
      <AuthorizedRoute
        ability="ZeroMe.Communication"
        exact
        path="/communications/:contentType">
        <Communications />
      </AuthorizedRoute>
      <AuthorizedRoute
        ability="Client.Communication"
        exact
        path="/communications/:contentType/:companyId">
        <Communications />
      </AuthorizedRoute>
      <AuthorizedRoute ability="ZeroMe.Clients" exact path="/trader/clients">
        <TraderClients />
      </AuthorizedRoute>
      <AuthorizedRoute ability="ZeroMe.Marketplace" path="/trader/projects">
        <TraderProjects />
      </AuthorizedRoute>
      <AuthorizedRoute
        ability="ZeroMe.Marketplace"
        abilityEdit
        exact
        path="/trader/create-project/:id">
        <CreateProject />
      </AuthorizedRoute>
      <AuthorizedRoute
        ability="ZeroMe.TraderDashboard"
        exact
        path="/trader/dashboard">
        <TraderDashboardProvider>
          <OffsetDashboard />
        </TraderDashboardProvider>
      </AuthorizedRoute>
      <AuthorizedRoute ability="Client.Employees" exact path="/employees">
        <ClientDashboardProvider>
          <Employees />
        </ClientDashboardProvider>
      </AuthorizedRoute>
      <AuthorizedRoute path="/settings">
        <Settings />
      </AuthorizedRoute>
      {isInheritingSubscriptionData ? null : (
        <AuthorizedRoute
          ability="Client.Marketplace"
          path="/marketplace"
          featureIds={['marketplace']}>
          <MarketplaceProvider>
            <Marketplace />
          </MarketplaceProvider>
        </AuthorizedRoute>
      )}

      <AuthorizedRoute exact path="/(engagement|impact)">
        <ClientDashboardProvider>
          <Switch>
            <AuthorizedRoute
              ability="Client.Engagement"
              exact
              path="/engagement">
              <Engagement />
            </AuthorizedRoute>
            <AuthorizedRoute ability="Client.Impact" exact path="/impact">
              <Impact />
            </AuthorizedRoute>
          </Switch>
        </ClientDashboardProvider>
      </AuthorizedRoute>
      {isInheritingSubscriptionData ? null : (
        <AuthorizedRoute
          ability="Client.Offset"
          exact
          path="/offset"
          featureIds={['offsets', 'marketplace']}>
          <Offset />
        </AuthorizedRoute>
      )}

      <AuthorizedRoute ability="ZeroMe.Habits" exact path="/habits">
        <Habits />
      </AuthorizedRoute>
      {defaultRoute && <Redirect to={defaultRoute} />}
    </Switch>
  );
};

export default AdminRouter;
