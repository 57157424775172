import * as yup from 'yup';
import { t } from 'i18next';
import { LanguageKey } from '../types/common';

export const genLanguageKeySchema = (
  message: string,
  options?: { maxLength?: number; required?: boolean },
) => {
  const { maxLength, required } = options ?? { required: true };
  const textSchema = required ? yup.string().required(message) : yup.string();

  return yup
    .array()
    .of(
      yup.object().shape({
        language: required ? yup.string().required() : yup.string(),
        text: maxLength
          ? textSchema.max(maxLength, t('maxLength', { max: maxLength }))
          : textSchema,
      }),
    )
    .min(1);
};

export const findLanguageIndex = (languageKeys: LanguageKey[], lang: string) =>
  languageKeys.findIndex((x) => x.language === lang);

export const getLanguageText = (languageKeys?: LanguageKey[], lang?: string) =>
  languageKeys?.find((x) => x.language === lang)?.text || '';
