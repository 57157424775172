import { Box, Grid, Typography, Dialog } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router';
import {
  EditableProject,
  SaveProjectProps,
  SaveState,
} from '../../../contexts/ProjectContext';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import CompanyList from '../../common/Company/CompanyList';
import { ZeroMeTM } from '../../../utils/stringConstants';

const useStyles = makeStyles(() => ({
  dialog: {
    width: 600,
    padding: 40,
    textAlign: 'center',
  },
  textMargin: {
    marginTop: 16,
    marginBottom: 40,
  },
}));

type Props = {
  project: EditableProject;
  saveState?: SaveState;
  setSaveState: (saveState?: SaveState) => void;
  saveProject: (props?: SaveProjectProps) => void;
  resetContext: () => void;
  createProjectFlow?: boolean;
};

const ProjectSavingModal: React.FC<Props> = ({
  project,
  saveState,
  setSaveState,
  saveProject,
  resetContext,
  createProjectFlow,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const projectDesc = `${project.isDraft ? 'Draft ' : ''}Project`;

  return (
    <Dialog open={!!saveState}>
      <Box className={classes.dialog}>
        <Grid container direction="column" alignContent="center" spacing={2}>
          {saveState?.state === 'PENDING' && (
            <Grid item>
              <Typography variant="h1">Saving {projectDesc}...</Typography>
            </Grid>
          )}
          {saveState?.state === 'COST_PER_MT_WARNING' && (
            <Grid item>
              <Typography variant="h1">
                The price you set is currently below the{' '}
                <span style={{ color: '#59A380' }}>
                  {saveState.costPerUnitWarningPrice?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{' '}
                  Purchase price!
                </span>
              </Typography>
              <Typography variant="body1" className={classes.textMargin}>
                If you proceed you risk losing money on the sale of this
                project.
              </Typography>
              <Grid item container direction="row" spacing={1}>
                <Grid item xs>
                  <SmallPaddedButton
                    onClick={() => setSaveState()}
                    variant="outlined"
                    fullWidth>
                    Cancel
                  </SmallPaddedButton>
                </Grid>
                <Grid item xs>
                  <SmallPaddedButton
                    onClick={() =>
                      saveProject({
                        forceNotDraft: true,
                        ignoreCostPerUnitCheck: true,
                      })
                    }
                    fullWidth>
                    Set Price Anyways
                  </SmallPaddedButton>
                </Grid>
              </Grid>
            </Grid>
          )}
          {(saveState?.state === 'SUCCESS' || saveState?.state === 'ERROR') && (
            <>
              {!!saveState.error && (
                <Grid item>
                  <Typography variant="h1">
                    Error Saving {projectDesc}!
                  </Typography>
                  <Typography variant="body1" className={classes.textMargin}>
                    {saveState.error}
                  </Typography>
                </Grid>
              )}
              {!saveState.error &&
                (project.isDraft || !createProjectFlow ? (
                  <Grid item>
                    <Typography variant="h1">Saved {projectDesc}!</Typography>
                  </Grid>
                ) : (
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography variant="h1">
                        Your project has been added!
                      </Typography>
                    </Grid>
                    <Grid item>
                      <CompanyList
                        companyIds={project.companies}
                        allCompaniesContent={
                          <Typography
                            variant="body1"
                            className={classes.textMargin}>
                            <strong>&quot;{project.title}&quot;</strong> has
                            been added{' '}
                            {project.individualScope
                              ? 'for mobile user purchases!'
                              : `to the ${ZeroMeTM} Marketplace!`}
                          </Typography>
                        }
                        selectedCompaniesContent={
                          <Typography
                            variant="body1"
                            className={classes.textMargin}>
                            This project is now available in the {ZeroMeTM}
                            Marketplace for:
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                ))}
              {saveState?.state === 'SUCCESS' &&
              !project.isDraft &&
              createProjectFlow ? (
                <Grid item container direction="row" spacing={1}>
                  <Grid item xs>
                    <SmallPaddedButton
                      onClick={() => {
                        resetContext();
                        history.push('/trader/create-project/new');
                      }}
                      variant="outlined"
                      fullWidth>
                      Create another project
                    </SmallPaddedButton>
                  </Grid>
                  <Grid item xs>
                    <SmallPaddedButton
                      onClick={() => {
                        setSaveState();
                        history.push('/');
                      }}
                      fullWidth>
                      Back to My Dashboard
                    </SmallPaddedButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <Box textAlign="center">
                    <SmallPaddedButton
                      style={{ margin: 'auto' }}
                      onClick={() => setSaveState()}>
                      OK
                    </SmallPaddedButton>
                  </Box>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ProjectSavingModal;
