import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';

const IdleTimeout: React.FC = () => {
  const { logout } = useContext(AuthorizationContext);
  const [showIdleWarning, setShowIdleWarning] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(60);

  const timeoutMinutes = parseInt(
    process.env.REACT_APP_IDLE_TIMEOUT_MINUTES || '',
    10,
  );

  let idleTimer: IIdleTimer;

  const updateSecondsRemaining = () => {
    setSecondsRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000));
  };

  const closeWarning = () => {
    setShowIdleWarning(false);
    idleTimer.reset();
  };

  idleTimer = useIdleTimer({
    timeout: (Number.isNaN(timeoutMinutes) ? 30 : timeoutMinutes) * 60 * 1000,
    promptTimeout: 60 * 1000,
    onIdle: () => {
      closeWarning();
      logout();
    },
    onActive: () => {
      setShowIdleWarning(false);
    },
    onPrompt: () => {
      updateSecondsRemaining();
      setShowIdleWarning(true);
    },
    crossTab: true,
    syncTimers: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => updateSecondsRemaining(), 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog open={showIdleWarning} onClose={closeWarning} maxWidth="md">
      <DialogTitle>
        <Typography variant="h1">Are you still here?</Typography>
      </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <DialogContentText>
          You will be logged out in {secondsRemaining} seconds...
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <SmallPaddedButton onClick={closeWarning} fullWidth>
          I&apos;m here!
        </SmallPaddedButton>
      </DialogActions>
    </Dialog>
  );
};

export default IdleTimeout;
