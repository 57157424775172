import { useMutation } from '@apollo/client';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useState } from 'react';
import SVG from '../../../assets/svg';
import { UPLOAD_PROJECT_PURCHASE_HISTORY_CSV } from '../../../graphql/projects/projectMutations';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import FileUpload, { FileData } from '../../common/FileUpload/FileUpload';
import LoadingBackdrop from '../../common/LoadingBackdrop';
import { downloadFileUrl } from '../../../utils/domUtils';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
    padding: 16,
  },
  closeIcon: {
    position: 'relative',
    top: -8,
    color: theme.palette.text.primary,
  },
  csvTemplate: {
    textAlign: 'center',
    color: '#398360',
    fontSize: 12,
    fontWeight: 600,
    marginTop: 24,
  },
  downloadErrors: {
    textAlign: 'center',
    color: '#398360',
    fontSize: 16,
    fontWeight: 600,
    margin: 32,
  },
}));

/**
 * Results from API of the parsing and record processing.
 */
type UploadResult = {
  result: 'SUCCESS' | 'PARSE_FAILURE' | 'NO_DATA' | 'INVALID_DATA';
  container: ProjectPurchaseHistoryParseResults;
};

/**
 * Returns the parsed status when uploading a project purchase history CSV file.
 */
type ProjectPurchaseHistoryParseResults = {
  responseCsv: string;
  errorCount: number;
  successCount: number;
  skippedCount: number;
};

type Props = {
  refetch?: () => void;
  onClose?: () => void;
};

const UploadProjectPurchaseHistoryCsvModal: React.FC<Props> = ({
  refetch,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const [file, setFile] = useState<FileData>();
  const [resultDialogOpen, setResultDialogOpen] = useState(false);
  const [uploadResult, setUploadResult] = useState<UploadResult>();

  const classes = useStyles();

  const close = () => {
    setOpen(false);
    setResultDialogOpen(false);
    setFile(undefined);

    if (onClose) {
      onClose();
    }
  };

  const [uploadProjectPurchaseHistoryCsv, { loading }] = useMutation<
    { uploadProjectPurchaseHistoryCsv: UploadResult },
    {
      upload: {
        csvData: File;
      };
    }
  >(UPLOAD_PROJECT_PURCHASE_HISTORY_CSV);

  // Create a text file based on the string array.
  const createFileUrl = (container: ProjectPurchaseHistoryParseResults) => {
    const csv = container.responseCsv;
    return URL.createObjectURL(new Blob([csv], { type: 'text/plain' }));
  };

  return (
    <>
      <LoadingBackdrop open={loading} />
      <Dialog open={open} onClose={close}>
        <Box className={classes.dialog}>
          <Grid container direction="column" alignItems="stretch">
            <Grid item container direction="row" justifyContent="space-between">
              <Typography align="center" variant="h3">
                Upload &apos;Credit Requests&apos; CSV
              </Typography>
              <IconButton
                onClick={close}
                className={classes.closeIcon}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
            <div style={{ margin: '40px 0' }}>
              <FileUpload
                accept=".csv"
                showFiles
                uploadIcon={<SVG.CsvIcon />}
                onFileChange={(f) => setFile(f.length ? f[0] : undefined)}
              />
            </div>
            <Grid item>
              <SmallPaddedButton
                fullWidth
                disabled={!file}
                onClick={async () => {
                  setOpen(false);
                  setUploadResult(undefined);

                  const result = await uploadProjectPurchaseHistoryCsv({
                    variables: {
                      upload: {
                        csvData: file?.file as File,
                      },
                    },
                  });

                  setResultDialogOpen(true);
                  setUploadResult(result.data?.uploadProjectPurchaseHistoryCsv);

                  if (refetch) {
                    refetch();
                  }
                }}>
                Save
              </SmallPaddedButton>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        onClose={() => close()}
        open={!!resultDialogOpen}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <Grid container direction="column" alignContent="center" spacing={2}>
            <Grid item container direction="row" justifyContent="space-between">
              <Typography variant="h1">Upload Completed</Typography>
              <IconButton
                onClick={close}
                className={classes.closeIcon}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
            {uploadResult?.result === 'PARSE_FAILURE' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  There was an error reading the file you uploaded. Please try
                  again.
                </Typography>
              </Grid>
            )}
            {uploadResult?.result === 'NO_DATA' && (
              <Grid item>
                <Typography variant="body1" style={{ margin: '16px 0' }}>
                  There were no rows of data in the file you uploaded. Please
                  try again.
                </Typography>
              </Grid>
            )}
            <Grid item>
              {uploadResult && (
                <>
                  <Grid item>
                    {uploadResult?.container?.errorCount > 0 && (
                      <Typography variant="body1" style={{ margin: '16px 0' }}>
                        The data you uploaded was invalid. The link below
                        contains your CSV file with an error column that will
                        help you diagnose the issues with your file.
                      </Typography>
                    )}
                    {uploadResult?.container?.errorCount === 0 && (
                      <Typography variant="body1" style={{ margin: '16px 0' }}>
                        Your request fulfillment data was successfully uploaded.
                      </Typography>
                    )}
                    <Typography variant="h2">Results</Typography>
                    <Typography
                      variant="body1"
                      style={{ marginBottom: '20px' }}>
                      <div>Errors: {uploadResult.container.errorCount}</div>
                      <div>Skipped: {uploadResult.container.skippedCount}</div>
                      <div>Success: {uploadResult.container.successCount}</div>
                    </Typography>
                  </Grid>
                  {uploadResult?.container?.errorCount > 0 && (
                    <SmallPaddedButton
                      fullWidth
                      style={{ marginBottom: '5px' }}
                      onClick={() => {
                        downloadFileUrl(
                          createFileUrl(uploadResult.container),
                          `errors_zerome_credit_requests_${moment().format(
                            'YYYYMMDD_HHmm',
                          )}.csv`,
                        );

                        close();
                      }}>
                      Download Results
                    </SmallPaddedButton>
                  )}
                </>
              )}

              {uploadResult?.result !== 'INVALID_DATA' && (
                <SmallPaddedButton fullWidth onClick={close}>
                  OK
                </SmallPaddedButton>
              )}
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default UploadProjectPurchaseHistoryCsvModal;
