import {
  Chip,
  Typography,
  Grid,
  SvgIcon,
  IconButton,
  Dialog,
  Button,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation } from '@apollo/client';

import CalendarToday from '@mui/icons-material/CalendarToday';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { OffsetContext } from '../../../contexts/OffsetContext';
import {
  EmisisonsSetting,
  ProjectPurchaseAllocation,
  ProjectPurchaseAllocationStatus,
  ProjectPurchaseHistory,
} from '../../../types/offsetProject/types';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import RoundedFlexBox from '../../trader/RoundedFlexBox';
import NumberUtils from '../../../utils/numberUtils';
import SVG from '../../../assets/svg';
import { UPDATE_OFF_PLATFORM_ALLOCATION } from '../../../graphql/offsetProject/offsetProject';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  tableGrid: {
    height: '100%',
  },
  name: {
    width: 250,
  },
  chipBlue: {
    backgroundColor: '#F1F8FD',
    color: '#3E7DA2',
  },
  chipComplete: {
    backgroundColor: '#E7F1EA',
    color: '#398360',
  },
});

type TableData = Pick<
  ProjectPurchaseHistory,
  'projectCategoryId' | 'project' | 'status'
> &
  ProjectPurchaseAllocation & {
    id: ProjectPurchaseAllocation['requestId'];
  } & Pick<EmisisonsSetting, 'allocTypeLabel' | 'allocSubTypeLabel'>;

const AllocatedHistory = () => {
  const classes = useStyles();
  const { projectPurchases, emissionsSettings } = useContext(OffsetContext);
  const { company } = useContext(AuthorizationContext);
  const [modalData, setModalData] = useState<
    { requestId: string; purchaseHistoryId: string; notes?: string } | undefined
  >(undefined);

  const [notes, setNotes] = useState('');
  const [update, { loading, error }] = useMutation(
    UPDATE_OFF_PLATFORM_ALLOCATION,
  );

  useEffect(() => {
    if (modalData) {
      setNotes(modalData?.notes || '');
    } else {
      setNotes('');
    }
  }, [modalData]);

  // #region Metrics
  const tableData = _.orderBy(
    projectPurchases?.flatMap(
      ({
        allocations,
        projectCategoryId,
        projectId,
        project,
        id,
        company: { name },
      }) =>
        allocations?.map(
          ({ requestId, requestedDate, ...allocation }) =>
            ({
              ...allocation,
              periodStart: allocation.periodStart
                ? format(
                    parse(allocation.periodStart, 'yyyy-MM', new Date()),
                    'MMM, yyyy',
                  )
                : '',
              periodEnd: allocation.periodEnd
                ? format(
                    parse(allocation.periodEnd, 'yyyy-MM', new Date()),
                    'MMM, yyyy',
                  )
                : '',
              allocTypeLabel:
                emissionsSettings?.find(
                  ({ allocType }) => allocType === allocation.allocType,
                )?.allocTypeLabel || 'All',
              allocSubTypeLabel:
                emissionsSettings?.find(
                  ({ allocSubType }) =>
                    allocSubType === allocation.allocSubType,
                )?.allocSubTypeLabel || 'All',
              id: requestId,
              requestId,
              requestedDate: format(new Date(requestedDate), 'M/d/yy'),
              requestedDateSort: requestedDate,
              projectCategoryId,
              projectId,
              project,
              purchaseHistoryId: id,
              companyName: allocation.company?.name || name,
              displayQuantity: NumberUtils.formatQuantityAndUom(
                allocation.quantity,
                undefined,
                project.uom,
                ' ',
              ),
            } as TableData),
        ) || [],
    ),
    'requestedDateSort',
    'desc',
  );
  // #endregion

  const statusMap: Record<ProjectPurchaseAllocation['status'], JSX.Element> = {
    [ProjectPurchaseAllocationStatus.COMPLETED]: (
      <Chip label="Applied" className={classes.chipComplete} />
    ),
    [ProjectPurchaseAllocationStatus.REQUESTED]: (
      <Chip label="Pending" className={classes.chipBlue} />
    ),
    [ProjectPurchaseAllocationStatus.PROCESSING]: (
      <Chip label="Processing" className={classes.chipBlue} />
    ),
  };

  const tableStructure: GroupableTableStructure<TableData>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'projectCategoryId',
          display: 'Cat',
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
        },
        {
          key: 'project.projectId',
          display: 'ID',
          render: ({ project: { projectId } }) => (
            <Typography variant="body2">{projectId}</Typography>
          ),
        },
        {
          key: 'project.title',
          display: 'Project Name',
          render: ({ project: { title } }) => (
            <Typography noWrap variant="body2" className={classes.name}>
              {title}
            </Typography>
          ),
        },
        {
          key: 'displayQuantity',
          display: 'Amount Allocated',
        },
        {
          key: 'appliedTo',
          display: 'Applied To',
          render: ({
            allocTypeLabel,
            allocSubTypeLabel,
            companyName,
            periodStart,
            periodEnd,
            isOffPlatform,
          }) => (
            <Grid container spacing={1} direction="column">
              <Grid item container spacing={1} direction="row">
                <Grid item>
                  <Chip
                    label={
                      isOffPlatform ? 'Off-platform Allocation' : allocTypeLabel
                    }
                    className={classes.chipBlue}
                  />
                </Grid>
                {isOffPlatform ? null : (
                  <>
                    <Grid item>
                      <Chip
                        label={allocSubTypeLabel}
                        className={classes.chipBlue}
                      />
                    </Grid>
                    <Grid item>
                      <Chip label={companyName} className={classes.chipBlue} />
                    </Grid>
                  </>
                )}
              </Grid>
              {periodStart && periodEnd ? (
                <Grid
                  item
                  container
                  direction="row"
                  spacing={1}
                  alignItems="center">
                  <Grid item>
                    <Typography variant="body2">
                      <SvgIcon component={CalendarToday} fontSize="inherit" />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{periodStart}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">-</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{periodEnd}</Typography>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          ),
        },
        {
          key: 'actions',
          render: ({
            isOffPlatform,
            requestId,
            purchaseHistoryId,
            notes: mdNotes,
          }) => (
            <IconButton
              disabled={!isOffPlatform}
              onClick={() =>
                setModalData({ requestId, purchaseHistoryId, notes: mdNotes })
              }>
              <SVG.Edit />
            </IconButton>
          ),
        },
        {
          key: 'requestedDate',
          display: 'Date Allocated',
        },
        {
          key: 'status',
          display: 'Status',
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Status',
          resolveFilterLabel: (value) =>
            statusMap[value as TableData['status']],
          render: ({ status }) => statusMap[status],
        },
      ],
    },
  ];

  const saveNotes = () => {
    setModalData(undefined);
    update({
      variables: {
        companyId: company.id,
        purchaseHistoryId: modalData?.purchaseHistoryId,
        allocationRequestId: modalData?.requestId,
        notes,
      },
    });
  };

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={3}
        wrap="nowrap"
        className={classes.root}>
        <Grid item className={classes.tableGrid}>
          <DataStateHandler loading={!tableData || loading} error={error}>
            <RoundedFlexBox>
              <GroupableSelectTable<TableData>
                tableData={tableData || []}
                tableStructure={tableStructure}
                hideStickyCol
              />
            </RoundedFlexBox>
          </DataStateHandler>
        </Grid>
      </Grid>
      <Dialog
        open={!!modalData}
        maxWidth="md"
        onClose={() => setModalData(undefined)}>
        <Grid
          sx={{ minHeight: 320, padding: 2, width: 620 }}
          container
          alignItems="center"
          direction="column">
          <Grid item sx={{ marginLeft: 'auto', pb: 2 }}>
            <IconButton onClick={() => setModalData(undefined)}>
              <SVG.Close />
            </IconButton>
          </Grid>
          <SVG.Edit />
          <Grid item sx={{ p: 2, width: '100%' }}>
            <TextField
              sx={{ width: '100%' }}
              multiline
              onChange={(e) => setNotes(e.target.value)}
              rows={5}
              value={notes || ''}
            />
          </Grid>
          <Grid item sx={{ mt: 'auto', width: '100%', px: 2, pb: 2 }}>
            <Button fullWidth variant="contained" onClick={() => saveNotes()}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AllocatedHistory;
