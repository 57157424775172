import { useQuery } from '@apollo/client';
import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL } from '../../../graphql/user/user';
import { LifestyleCategoryStatus } from './LifestyleCategoryStatus';
import { GET_QUESTION_TREE_CATEGORIES } from '../../../graphql/questionnaire/questions';
import { ProgressIndicator } from '../../common/ProgressIndicator/ProgressIndicator';

const AboutMe: React.FC = () => {
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { questionTreeCategories = [] } = {},
  } = useQuery(GET_QUESTION_TREE_CATEGORIES);

  const {
    loading: statusLoading,
    error: statusError,
    data: { me: { questionTreeCategoryStatusDetail = [] } = {} } = {},
  } = useQuery(GET_USER_QUESTION_TREE_CATEGORY_STATUS_DETAIL);

  const numCategories = questionTreeCategoryStatusDetail.length;
  const itemsComplete = questionTreeCategoryStatusDetail.reduce(
    (acc, cur) => acc + (cur.isComplete ? 1 : 0),
    0,
  );

  return (
    <DataStateHandler
      loading={categoriesLoading || statusLoading}
      error={categoriesError || statusError}>
      <Grid container flexDirection="column">
        <Grid
          item
          container
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          pb={3}>
          <Grid item>
            <Typography variant="h3">About Me</Typography>
          </Grid>
          <Grid item>
            <ProgressIndicator progress={itemsComplete} total={numCategories} />
          </Grid>
        </Grid>
        {questionTreeCategoryStatusDetail.map((x) => (
          <Grid
            item
            container
            flexDirection="column"
            key={x.questionTreeCategory}>
            <LifestyleCategoryStatus
              status={x}
              allCategories={questionTreeCategories}
            />
            <Divider style={{}} />
          </Grid>
        ))}
      </Grid>
    </DataStateHandler>
  );
};

export default AboutMe;
