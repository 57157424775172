import React from 'react';
import { Grid, Typography } from '@mui/material';
import { UserActivityListItemProps } from './UserActivityListItem';
import { UserActivityType } from '../../../types/user/activityTypes';
import UserActivityRenderer, {
  LEFT_COLUMN_WIDTH,
  renderActivityDate,
} from './UserActivityRenderer';
import DefaultImage from '../../common/DefaultImage';

const UserActivityWeeklyReminderListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    if (userActivity.activityType !== UserActivityType.WEEKLY_ENCOURAGEMENT) {
      return null;
    }

    return (
      <UserActivityRenderer
        leftComponent={
          userActivity.data.imageUrl ? (
            <img
              src={userActivity.data.imageUrl}
              alt="activity"
              style={{ width: LEFT_COLUMN_WIDTH }}
            />
          ) : (
            <DefaultImage height={40} />
          )
        }
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">
                {userActivity.data.title} {userActivity.data.description}
              </Typography>
            </Grid>
            <Grid item>{renderActivityDate(userActivity)}</Grid>
          </Grid>
        }
      />
    );
  },
);

export default UserActivityWeeklyReminderListItem;
