import { Grid } from '@mui/material';
import React from 'react';
import CompanyWaterFootprint from './CompanyWaterFootprint/CompanyWaterFootprint';
import AverageEmployeeWaterFootprint from './AverageEmployeeWaterFootprint/AverageEmployeeWaterFootprint';
import { WaterFootprintItem } from '../../../types/dashboard/types';

type Props = {
  companyFootprint: WaterFootprintItem[];
};

const WaterFootprint: React.FC<Props> = ({ companyFootprint }) => (
  <Grid
    container
    spacing={3}
    direction="column"
    style={{ height: '100%', marginBottom: 32 }}>
    <Grid item>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <CompanyWaterFootprint waterFootprint={companyFootprint} />
        </Grid>
        <Grid item xs={6}>
          <AverageEmployeeWaterFootprint />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default WaterFootprint;
