import React from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import BadgeIcon from '../../../assets/svg/badges';
import { Colors } from '../../../theme/shared';
import { Badge } from '../../../types/badge/types';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: 8,
    marginBottom: 4,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  subtitle: {
    color: Colors.TertiaryText,
    textAlign: 'center',
  },
}));

type Props = {
  badge: Badge;
};

const BadgeCard: React.FC<Props> = ({ badge }) => {
  const classes = useStyles();
  const { badgeId, earnedOn, level, subtitle, title } = badge;

  return (
    <Grid container direction="column" alignItems="center">
      <BadgeIcon badgeId={badgeId} level={level} disabled={!earnedOn} />
      <Typography className={classes.title} variant="h4">
        {title}
      </Typography>
      <Typography className={classes.subtitle} variant="subtitle1">
        {subtitle}
      </Typography>
    </Grid>
  );
};

export default BadgeCard;
