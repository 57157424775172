import { PaletteMode } from '@mui/material';

export const Colors = {
  ElectricGreen: '#2EF699',
  CobaltBlue: '#2D63F9',
  LightBlue: '#AEBFF1',
  LightGrey: '#F9F9F9',
  DisabledGrey: '#DADADA',
  TertiaryText: '#6B6B6B',
  DarkGrey: '#022131',
};

const shared = {
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: 'Inter',
    allVariants: {
      color: Colors.DarkGrey,
    },
    h1: {
      fontSize: '2rem',
      lineHeight: '3rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '0.875rem',
      lineHeight: '1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 600,
    },
    body1: {
      fontSize: '1.125rem',
      lineHeight: '1.8rem',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '1.4rem',
    },
    subtitle1: {
      fontSize: '0.75rem',
      lineHeight: '0.9rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.625rem',
      lineHeight: '1rem',
      fontWeight: 600,
    },
    button: {
      fontSize: '1.125rem',
      lineHeight: '1.25rem',
      fontWeight: 600,
      textTransform: 'none' as const,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12) !important',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#398360',
          '&$checked': {
            color: '#398360',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 12,
          color: '#0A3751',
          backgroundColor: 'transparent',
          '&$selected': {
            color: '#398360',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: 12,
        },
        outlined: {
          padding: 12,
        },
        outlinedSecondary: {
          border: '1px solid #CCCCCC',
          color: '#6B6B6B',
          '&:hover': {
            border: '1px solid #6B6B6B',
          },
          '&:disabled': {
            backgroundColor: '#EFEFEF',
            color: Colors.DisabledGrey,
          },
          '&:active': {
            backgroundColor: '#909090',
            color: '#FFFFFF',
            border: '1px solid #CCCCCC',
          },
        },
        containedError: {
          background: '#f7bac6',
          color: '#d33050',
          '&:hover': { background: '#d33050', color: '#ffffff' },
          '&:active': { background: '#d33050', color: '#ffffff' },
          '&:disabled': { background: '#f7bac6', color: Colors.DisabledGrey },
        },
        containedSecondary: {
          backgroundColor: Colors.ElectricGreen,
          '&:hover': {
            backgroundColor: Colors.ElectricGreen,
            color: '#FFFFFF',
          },
          '&:disabled': {
            backgroundColor: '#EFEFEF',
            color: Colors.DisabledGrey,
          },
          '&:active': {
            backgroundColor: Colors.ElectricGreen,
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: 40,
          maxHeight: 28,
          borderRadius: 25,
          padding: '6px',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          whiteSpace: 'nowrap' as any,
          marginRight: 12,
          //   border: '1px solid #59A380',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${Colors.DisabledGrey}`,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          paddingLeft: '4px',
          paddingRight: '4px',
        },
      },
    },
  },
  mixins: {},
  palette: {
    text: {
      primary: '#0A3751',
      secondary: '#2A2A2A',
      disabled: '#A5B7BF',
    },
    mode: 'light' as PaletteMode,
    // type: 'light',
    primary: {
      dark: Colors.DarkGrey,
      main: Colors.CobaltBlue,
      light: '#F1F8FD',
      // teriary: '#9E9E9E',
    },
    secondary: {
      main: '#96B1FC',
      dark: '#056433',
      light: '#D1FFBB',
    },
    error: {
      main: '#EA4767',
      dark: '#C71A3C',
      light: '#F7BAC6',
    },
    category: {
      solar: '#6141A5',
      methane: '#E96B52',
      wind: '#EF9C43',
      pipeline: '#59A380',
      farming: '#0A3751',
      ocean: '#4080A5',
    },
    backgrounds: {
      white: '#FFFFFF',
      light: '#F1F8FD',
      medium: '#E6EFF5',
      dark: '#0A3751',
    },
    divider: Colors.DisabledGrey,
    buttons: {
      primary: {
        main: Colors.CobaltBlue,
        active: Colors.CobaltBlue,
        disabled: '#EFEFEF',
        hover: '#056433',
      },
      secondary: {
        main: '#96B1FC',
        active: '#96B1FC',
        disabled: '#EFEFEF',
        hover: '#0A3751',
      },
    },
  },
};

export default shared;
