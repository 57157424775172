import React, { useContext, useState } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Box,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { Field, Formik } from 'formik';
import SVG from '../../../../assets/svg';
import DataStateHandler from '../../DataStateHandler/DataStateHandler';
import SmallPaddedButton from '../../Buttons/SmallPaddedButton';
import { ProjectInventoryHistory } from '../../../../types/project/types';
import AppTextField from '../../AppTextField';
import AppDatePicker from '../../AppDatePicker';
import { UPSERT_PROJECT_INVENTORY } from '../../../../graphql/projects/projectMutations';
import { ProjectContext } from '../../../../contexts/ProjectContext';
import ProjectInventoryHistoryWarningModal from './ProjectInventoryHistoryWarningModal';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 32,
  },
  header: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  input: {
    border: 'none',
  },
}));

type Props = {
  inventoryHistory?: ProjectInventoryHistory;
  onClose: () => void;
};

type input = {
  id?: string | undefined;
  quantity?: number | undefined;
  purchasedDate?: string | undefined;
  pricePerUnit?: number | undefined;
  notes?: string | undefined;
};

const ProjectInventoryHistoryDetails: React.FC<Props> = ({
  inventoryHistory,
  onClose,
}) => {
  const { project } = useContext(ProjectContext);
  const [isConfirming, setIsConfirming] = useState(false);
  const classes = useStyles();
  const [upsert, { loading, error }] = useMutation(UPSERT_PROJECT_INVENTORY);
  const [input, setInput] = useState<input | null>(null);

  const min = inventoryHistory
    ? inventoryHistory.quantity - inventoryHistory.remainingQuantity
    : 0;

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .required()
      .positive()
      .min(
        min,
        'Quantity must be positive and higher than the amount already sold',
      ),
    purchasedDate: yup.string().required(),
    pricePerUnit: yup.number().required().positive(),
  });

  const initialValues = {
    purchasedDate: '',
    quantity: 0,
    pricePerUnit: 0,
    notes: '',
    displayQuantity: '',
    displayPricePerUnit: '',
    ...inventoryHistory,
  };
  return (
    <DataStateHandler loading={loading} error={error}>
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={(values) => {
            setIsConfirming(true);
            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              __typename,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              remainingQuantity,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              totalPurchaseAmount,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              displayQuantity,
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              displayPricePerUnit,
              ...rest
            } = values;
            setInput(rest as input);
          }}
          validateOnMount>
          {(props) => (
            <Grid
              className={classes.root}
              container
              direction="column"
              spacing={8}>
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  className={classes.header}>
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center">
                      <IconButton
                        aria-label="close"
                        onClick={onClose}
                        size="large">
                        <SvgIcon component={SVG.Back} />
                      </IconButton>
                      <Typography variant="h3">
                        {inventoryHistory ? 'Edit' : 'Add'} Project Inventory
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center">
                      <SmallPaddedButton onClick={onClose} variant="outlined">
                        Cancel
                      </SmallPaddedButton>
                      <Box ml={1} />
                      <SmallPaddedButton
                        onClick={props.submitForm}
                        disabled={!props.isValid}>
                        Save
                      </SmallPaddedButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={4}>
                    <Typography variant="body2">Date:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="purchasedDate"
                      label=""
                      component={AppDatePicker}
                      placeholder=""
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Typography variant="body2">Purchase Price:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="pricePerUnit"
                      label=""
                      type="number"
                      component={AppTextField}
                      placeholder="0.00"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box mr="12px">
                              {project.uom ? `/ ${project.uom}` : ''}
                            </Box>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Typography variant="body2">Total Purchased:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name="quantity"
                      label=""
                      type="number"
                      component={AppTextField}
                      placeholder="Total"
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.input,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Box mr="12px">
                              {project.uom ? `/ ${project.uom}` : ''}
                            </Box>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={12}>
                    <Typography variant="body2">Notes:</Typography>
                    <Field
                      name="notes"
                      label=""
                      component={AppTextField}
                      fullWidth
                      multiline
                      rows={20}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
        <ProjectInventoryHistoryWarningModal
          isOpen={isConfirming}
          message={`You are about to add ${input?.quantity}${
            project.uom || ''
          } of inventory to this project`}
          confirmLabel="Save"
          onClose={() => {
            setIsConfirming(false);
          }}
          onConfirm={() => {
            setIsConfirming(false);
            upsert({
              variables: {
                projectId: project.id,
                input,
              },
              onCompleted: () => {
                onClose();
              },
            });
          }}
        />
      </>
    </DataStateHandler>
  );
};

export default ProjectInventoryHistoryDetails;
