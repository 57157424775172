import React from 'react';
import { Grid, Slider } from '@mui/material';
import { Field, FieldProps } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import ImageUtils from '../../utils/imageUtils';

type Props = {
  imageUri: string;
  fieldName: string;
};

const useStyles = makeStyles(() => ({
  image: {
    height: 150,
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
    borderRadius: 8,
  },
}));

const ImagePosition: React.FC<Props> = ({ imageUri, fieldName }) => {
  const classes = useStyles();

  return (
    <Field name={fieldName}>
      {({ field }: FieldProps<number>) => (
        <Grid container direction="row">
          <Grid item xs>
            <img
              alt="Client"
              src={imageUri}
              className={classes.image}
              style={ImageUtils.ObjectPositionStyle(field.value)}
            />
          </Grid>
          <Grid item xs={1} style={{ paddingLeft: 15 }}>
            <Slider min={0} max={100} orientation="vertical" {...field} />
          </Grid>
        </Grid>
      )}
    </Field>
  );
};

export default ImagePosition;
