import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import SVG from '../../assets/svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    width: '100%',
    height: '100%',
    background: '#FFFFFF',
    borderRadius: 8,
  },
  warning: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  size?: 'large' | 'small';
  style?: React.CSSProperties;
};

const NoDataAvailable: React.FC<Props> = ({ size = 'large', style }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
      style={style}
      spacing={1}
      className={classes.root}>
      <Grid item>
        <SVG.WarningTriangle className={classes.warning} />
      </Grid>
      <Grid item>
        <Typography variant={size === 'large' ? 'h2' : 'h3'} color="error">
          No Data Available
        </Typography>
      </Grid>
      {size === 'large' && (
        <Grid item>
          <Typography variant="body1" color="error">
            Try selecting a more recent date to see results
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default NoDataAvailable;
