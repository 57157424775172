import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_PROJECTED_EMISSIONS } from '../../../graphql/user/user';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import SVG from '../../../assets/svg-v2';
import { Colors } from '../../../theme/shared';

type Props = {
  size?: number;
  headerFontSize?: number;
  subTitleStyle?: React.CSSProperties;
  subtitle?: string;
  hideUnits?: boolean;
};

const NetEmissionsOverview: React.FC<Props> = ({
  size = 175,
  headerFontSize = 56,
  subTitleStyle,
  subtitle = 'Annual Projection',
  hideUnits,
}) => {
  const { user, company } = useContext(AuthorizationContext);
  const skip = !user?.id || !company.id;

  const {
    loading,
    error,
    data: { projectedEmissions } = {},
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: company.id,
    },
    skip,
  });

  return (
    <DataStateHandler loading={loading || skip} error={error}>
      <Box style={{ position: 'relative' }}>
        <SVG.Ellipse width={size} height={size} fill={Colors.ElectricGreen} />
        <Grid
          container
          flexDirection="column"
          style={{
            position: 'absolute',
            top: 0,
            width: size,
            height: size,
          }}
          justifyContent="center"
          alignItems="center">
          <Typography variant="h1" style={{ fontSize: headerFontSize }}>
            {new Intl.NumberFormat('en-us', {
              maximumFractionDigits: 1,
            }).format(
              ((projectedEmissions?.currentFootprint || 0) -
                (projectedEmissions?.habitReductions || 0) -
                (projectedEmissions?.offsetReductions || 0)) /
                1000,
            )}
          </Typography>
          {!hideUnits && (
            <Typography variant="h3">
              MT CO<sub>2</sub>E
            </Typography>
          )}
          <Typography variant="subtitle1" style={subTitleStyle}>
            {subtitle}
          </Typography>
        </Grid>
      </Box>
    </DataStateHandler>
  );
};

export default NetEmissionsOverview;
