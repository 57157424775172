import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { OffsetContext } from '../../../contexts/OffsetContext';
import { ProjectOffsetAssignment } from '../../../types/offsetProject/types';
import TooltipError from '../../common/Tooltip/TooltipError';

const useStyles = makeStyles({
  amount: {
    minWidth: '7ch',
  },
});

const Amount = ({ project }: Pick<ProjectOffsetAssignment, 'project'>) => {
  const classes = useStyles();
  const {
    projectOffsetAssignments: projects,
    updateProject,
    removeProject,
    offsetTotal,
    projectTotal,
  } = useContext(OffsetContext);
  const savedAmount =
    projects.find((p) => p.project.id === project.id)?.quantity || 0;
  const [amount, setAmount] = useState(savedAmount);

  const updateAmount = () =>
    amount > 0
      ? updateProject(
          project,
          typeof amount === 'string' ? parseInt(amount, 10) : amount,
        )
      : removeProject(project.id);

  useEffect(() => {
    setAmount(savedAmount);
  }, [savedAmount]);

  const error =
    amount > savedAmount &&
    projectTotal - (savedAmount || 0) + amount > offsetTotal;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      spacing={1}
      alignItems="center">
      <Grid item>
        <TooltipError
          title="The project total cannot be greater than the offset total"
          open={error}
          placement="bottom-end">
          <TextField
            type="number"
            size="small"
            variant="outlined"
            color="primary"
            error={error}
            className={classes.amount}
            value={amount || ''}
            disabled={!savedAmount && projectTotal >= offsetTotal}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{project.uom}</InputAdornment>
              ),
              inputProps: {
                inputMode: 'numeric',
                pattern: '[0-9]*',
              },
            }}
            onChange={({ target: { value } }) => {
              if (value === '') {
                setAmount(0);
              } else {
                const parsedValue = parseInt(value, 10);

                if (parsedValue > -1 && parsedValue <= project.remaining) {
                  setAmount(parsedValue);
                }
              }
            }}
          />
        </TooltipError>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={
            error ||
            savedAmount === amount ||
            (amount < 1 && savedAmount < 1) ||
            (savedAmount < 1 && projectTotal >= offsetTotal)
          }
          onClick={updateAmount}>
          {savedAmount > 0 && amount < 1 ? 'Remove' : 'Apply'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Amount;
