import React from 'react';
import { CurrentBudget } from '../../../../types/dashboard/types';
import BrowseMarketplaceCard from './BrowseMarketplaceCard';
import NoBudgetNoPurchasesCard from './NoBudgetNoPurchasesCard';
import TotalInvestmentsPie from './TotalInvestmentsPie';

type Props = {
  budget: CurrentBudget;
  style?: React.CSSProperties;
};

const OffsetAllocationCard: React.FC<Props> = ({
  budget: currentBudget,
  style,
}) => {
  let Component = null;
  if (currentBudget.spent) {
    Component = (
      <TotalInvestmentsPie style={style} currentBudget={currentBudget} />
    );
  } else if (!currentBudget.spent && !currentBudget.total) {
    Component = <NoBudgetNoPurchasesCard />;
  } else if (!currentBudget.spent) {
    Component = <BrowseMarketplaceCard />;
  }

  return Component;
};

export default OffsetAllocationCard;
