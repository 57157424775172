import React from 'react';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import ResponseQuestion from './ResponseQuestion';
import { useQuestionnaire } from '../../../contexts/QuestionnaireContext';
import Loading from '../Loading';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import theme from '../../../theme/memberTheme';

const useStyles = makeStyles(() => ({
  question: {
    cursor: 'pointer',
    padding: 8,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

export default function ResponseQuestionList() {
  const classes = useStyles();
  const history = useHistory();
  const state = useQuestionnaire();

  if (state.loading || !state.profileCards.length) {
    return <Loading />;
  }

  return (
    <DataStateHandler loading={state.loading}>
      <>
        {state.profileCards.map((item, index) => (
          <Box
            pb={5}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={classes.question}
            onClick={() =>
              history.push(
                `/member/questionnaire/${state.questionTreeCategory?.id}/${index}`,
              )
            }>
            <ResponseQuestion count={index + 1} profileCard={item} />
          </Box>
        ))}
      </>
    </DataStateHandler>
  );
}
