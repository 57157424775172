import React, {
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Typography, Grid, IconButton, Button, Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDropzone } from 'react-dropzone';
import SVG from '../../../assets/svg';

const useStyles = makeStyles(() => ({
  uploadDiv: {
    border: '1px dashed #BDD9C5',
    borderRadius: 8,
    padding: 16,
    textAlign: 'center',
  },
  preview: {
    height: 50,
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
  },
}));

export type FileData = {
  imageDataUrl: string;
  file: File;
};

type Props = {
  allowMultiple?: boolean;
  showFiles?: boolean;
  initialFiles?: File[];
  onAdd?: (file: FileData) => void;
  onFileChange?: (files: FileData[]) => void;
  onRemove?: (file: FileData) => void;
  uploadIcon?: React.ReactNode;
  uploadLabel?: React.ReactNode;
  uploadDivStyle?: React.CSSProperties;
  accept?: string;
};

export type FileUploadRef = {
  setFiles: (files: FileData[]) => void;
};

const FileUpload = forwardRef<FileUploadRef, Props>(
  (
    {
      onAdd = () => {
        // Do nothing
      },
      onFileChange = () => {
        // Do nothing
      },
      onRemove = () => {
        // Do nothing
      },
      allowMultiple = false,
      showFiles = false,
      initialFiles = [],
      uploadIcon = <SVG.Upload />,
      uploadLabel = (
        <>
          <Typography variant="body2">Drag and Drop</Typography>
          <Typography variant="body2">or</Typography>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            style={{ padding: 12 }}>
            Browse my Files
          </Button>
        </>
      ),
      uploadDivStyle = {},
      accept,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [badFile, setBadFile] = useState(false);

    const makeFileData = (files: File[]) =>
      files.map<FileData>((file) => ({
        file,
        imageDataUrl: URL.createObjectURL(file),
      }));

    const [files, setFiles] = useState(makeFileData(initialFiles));

    const onDrop = useCallback(
      (list: File[]) => {
        let newFiles = files;

        if (!allowMultiple) {
          newFiles = [];
        }

        const dataList = makeFileData(list);

        const difference = dataList.filter(
          (f) => !files.find((l) => l.file.name === f.file.name),
        );

        newFiles = newFiles.concat(difference);

        if (showFiles) {
          setFiles(newFiles);
        }

        if (onAdd) {
          difference.forEach((f) => onAdd(f));
        }
        onFileChange(newFiles);
      },
      [files, onAdd, onFileChange],
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop: (list: File[]) => {
        setBadFile(false);
        onDrop(list);
      },
      onDropRejected: () => setBadFile(true),
      multiple: allowMultiple,
      accept,
    });

    const removeItem = (index: number) => {
      const newFiles = [...files];
      const removedFiles = newFiles.splice(index, 1);
      setFiles(newFiles);
      onFileChange(newFiles);
      removedFiles.forEach((f) => {
        onRemove(f);
      });
    };

    useImperativeHandle(ref, () => ({
      setFiles,
    }));

    return (
      <>
        <div
          {...getRootProps()}
          className={classes.uploadDiv}
          style={uploadDivStyle}>
          <input {...getInputProps()} />
          {uploadIcon}
          {uploadLabel}
        </div>
        {badFile && (
          <Alert severity="error" style={{ marginTop: 16 }}>
            The selected file was invalid, please try again.
          </Alert>
        )}
        {showFiles &&
          files.map((f, i) => (
            <Grid
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: i === 0 ? 16 : 0, marginBottom: 16 }}>
              {f.file.type.startsWith('image/') && (
                <Grid item>
                  <img
                    alt="Preview"
                    src={f.imageDataUrl}
                    className={classes.preview}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography>{f.file.name}</Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => removeItem(i)} size="large">
                  <SVG.Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
      </>
    );
  },
);

export default FileUpload;
