import React from 'react';
import { useParams } from 'react-router';

import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberHabitDetails from '../../../components/member/myhabits/MemberHabitDetails';

const MemberHabitDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <MemberLayout>
      <MemberHabitDetails id={id} />
    </MemberLayout>
  );
};

export default MemberHabitDetailsPage;
