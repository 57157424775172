import { useCallback, useEffect, useState } from 'react';
import { findAncestor } from '../utils/domUtils';

const useStickyRef = (targetClass?: string) => {
  const [initialToolbarPos, setInitialToolbarPos] = useState(-1);
  const [stickyEl, setStickyEl] = useState<HTMLDivElement>();
  const stickyRef = useCallback((el: HTMLDivElement) => {
    setStickyEl(el);
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    let scrollHost: EventTarget | null = targetClass ? null : window;

    if (targetClass && stickyEl) {
      scrollHost = findAncestor(stickyEl, targetClass);
    }

    if (!scrollHost) {
      return () => {
        // do nothing
      };
    }

    const handleScroll = () => {
      const position =
        scrollHost instanceof Window
          ? scrollHost.scrollY
          : (scrollHost as HTMLDivElement).scrollTop;
      setScrollPosition(position);
    };

    scrollHost.addEventListener('scroll', handleScroll);

    return () => {
      scrollHost?.removeEventListener('scroll', handleScroll);
    };
  }, [stickyEl]);

  let isSticky = false;

  if (stickyEl) {
    if (initialToolbarPos < 0) {
      setInitialToolbarPos(stickyEl.getBoundingClientRect().top);
    } else {
      isSticky = scrollPosition > initialToolbarPos;
    }
  }

  return {
    stickyRef,
    isSticky,
  };
};

export default useStickyRef;
