import { useMutation } from '@apollo/client';
import { Typography, Grid, Paper } from '@mui/material';
import { Field, Formik } from 'formik';

import React from 'react';
import * as yup from 'yup';
import { CONTACT_TRADER } from '../../../graphql/projects/projectMutations';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';
import AppTextField from '../AppTextField';
import SmallPaddedButton from '../Buttons/SmallPaddedButton';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import { useStyles } from '../../trader/Projects/FulfillOffsetRequestDetails';
import OffsetRequestProjectDetails from '../../trader/Projects/OffsetRequestProjectDetails';
import { ContactTraderType } from '../../../types/common';

type Props = {
  projectHistory: ProjectPurchaseHistory;
  onClose: () => void;
};

const RequestOffsetInventoryChange: React.FC<Props> = ({
  projectHistory,
  onClose,
}) => {
  const classes = useStyles();

  const schema = yup.object().shape({
    notes: yup.string().required('Required'),
    requestType: yup.string().required(),
  });

  const [contact, { loading, error }] = useMutation(CONTACT_TRADER);

  return (
    <Formik
      initialValues={{
        notes: '',
        requestType: 'update',
      }}
      validationSchema={schema}
      onSubmit={async ({ requestType, notes }) => {
        const type: ContactTraderType =
          requestType === 'cancel' ? 'PURCHASE_CANCEL' : 'PURCHASE_UPDATE';
        const variables = {
          type,
          projectId: projectHistory.projectId,
          content: notes,
        };

        contact({ variables, onCompleted: () => onClose() });
      }}>
      {({ submitForm, isValid, isSubmitting }) => (
        <DataStateHandler loading={loading} error={error}>
          <Grid container className={classes.root}>
            <OffsetRequestProjectDetails
              projectHistory={projectHistory}
              onClose={onClose}
            />
            <Paper className={classes.paper}>
              <Grid item container direction="row">
                <Typography variant="h3">
                  Request to Update Purchase Details
                </Typography>
                <Typography variant="body2">
                  This request will be reviewed by the trader. If this request
                  can be fulfilled you will receive a notification within 3-5
                  business days.
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                className={classes.element}
              />
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <Typography variant="body2">Request Type</Typography>
                <Field
                  name="requestType"
                  label=""
                  component={AppTextField}
                  placeholder=""
                  fullWidth
                  select
                  options={[
                    {
                      value: 'cancel',
                      label: 'Cancel Purchase',
                    },
                    {
                      value: 'update',
                      label: 'Update Quantity',
                    },
                  ]}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <Typography variant="body2">Notes</Typography>
                <Field
                  name="notes"
                  label=""
                  component={AppTextField}
                  placeholder="Add any additional comments"
                  fullWidth
                  multiline
                  rows={5}
                  type="text"
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="space-between"
                className={classes.element}>
                <SmallPaddedButton
                  disabled={!isValid || isSubmitting}
                  fullWidth
                  onClick={() => submitForm()}>
                  Submit Request to Trader
                </SmallPaddedButton>
              </Grid>
            </Paper>
          </Grid>
        </DataStateHandler>
      )}
    </Formik>
  );
};

export default RequestOffsetInventoryChange;
