import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { LocalStorageKeys } from '../types/common';

let userManager: UserManager | undefined;
const initialPolicyId = localStorage.getItem(LocalStorageKeys.policyId);

export const createUserManager = (policyId: string): UserManager => {
  if (userManager) {
    userManager.removeUser();
  }

  localStorage.setItem(LocalStorageKeys.policyId, policyId);

  const fullTenant = process.env.REACT_APP_AUTH_TENANT;
  const shortTenant = fullTenant?.split('.')[0];

  userManager = new UserManager({
    authority: `https://${shortTenant}.b2clogin.com/${fullTenant}/${policyId}/v2.0`,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID || '',
    scope: `${process.env.REACT_APP_AUTH_CLIENT_ID} offline_access`,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI || '',
    userStore: new WebStorageStateStore({ store: localStorage }),
  });

  return userManager;
};

if (initialPolicyId) {
  // If we have a stored policyId, initialize our user manager...
  userManager = createUserManager(initialPolicyId);
}

export const getUserManager: () => UserManager | undefined = () => userManager;
