import { useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import { GET_MARKETPLACE_PURCHASEHIST } from '../../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';
import { ProjectPurchaseType } from '../../../types/project/types';
import { User } from '../../../types/user/types';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import NumberUtils from '../../../utils/numberUtils';
import CompanyName from '../../common/Company/CompanyName';
import CompanyNameWithLogo from '../../common/Company/CompanyNameWithLogo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { ExportableGroupableTableStructure } from '../../common/Table/types';
import UserImage from '../../common/User/UserImage';
import RoundedFlexBox from '../RoundedFlexBox';
import OffsetRequestNotes from './OffsetRequestNotes';
import useShowDemoProjects from './useShowDemoProjects';
import DownloadClientCertificate from './DownloadClientCertificate';
import { replaceApiUrl } from '../../../apollo/client';

const useStyles = makeStyles(() => ({
  filterButton: {
    padding: 16,
  },
  dialog: {
    padding: 24,
  },
}));

const PurchaseHistory = () => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const showDemoProjects = useShowDemoProjects();
  const [purchaseType, setPurchaseType] = useState(ProjectPurchaseType.COMPANY);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<
    undefined | Pick<ProjectPurchaseHistory, 'updateNotes'>
  >(undefined);
  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const {
    loading: purchHistoryLoading,
    error: purchHistoryError,
    data: { offsetProjects: purchaseHistory = [] } = {},
  } = useQuery<{
    offsetProjects: (ProjectPurchaseHistory & {
      project: {
        inventoryHistory: {
          pricePerUnit: number;
          id: string;
        }[];
      };
    })[];
  }>(GET_MARKETPLACE_PURCHASEHIST(true), {
    variables: {
      filter: {
        status: ['COMPLETE', 'CANCELLED'],
        isDemoProject: [showDemoProjects ? 'true' : 'false'],
      },
    },
    fetchPolicy: 'network-only',
  });

  const grouped = _.groupBy(
    purchaseHistory,
    ({ purchaseType: pt }) => pt || ProjectPurchaseType.COMPANY,
  );

  const offsetProjects = grouped?.[purchaseType] || [];

  const flattenedProjects = offsetProjects.map((x) => {
    const investment = x.inventory
      ? x.inventory
          .map((i) => {
            const history = x.project.inventoryHistory.find(
              (h) => h.id === i.projectInventoryHistoryId,
            ) || { pricePerUnit: 0 };
            return history.pricePerUnit * i.quantity;
          })
          .reduce((acc, s) => acc + s, 0)
      : 0;
    return {
      ...x,
      projectTitle: x.project.title,
      investment,
      purchaseAmount: x.price * x.quantity,
      displayQuantity: NumberUtils.formatQuantityAndUom(
        x.quantity,
        x.uom,
        x.project.uom,
      ),
      displayAllocated: NumberUtils.formatQuantityAndUom(
        x.allocated,
        x.uom,
        x.project.uom,
      ),
      displayPrice: NumberUtils.formatCurrencyAndUom(
        x.price,
        x.uom,
        x.project.uom,
      ),
      totalSpent: x.price * (x.allocated + x.remaining),
      companyUser:
        purchaseType === ProjectPurchaseType.COMPANY
          ? ({
              name: x.requestedByUser?.name,
              email: x.requestedByUser?.email,
              id: x.requestedByUser?.id,
            } as User)
          : undefined,
      requestedDateOnly: formatDateStringUtc(x.requestedDate, 'MM/DD/YYYY'),
      completedDateOnly: x.completedDate
        ? formatDateStringUtc(x.completedDate, 'MM/DD/YYYY')
        : '',
    };
  });

  const tableStructure: ExportableGroupableTableStructure<
    typeof flattenedProjects[0]
  >[] = [
    {
      key: 'sticky',
      sticky: true,
      columns: [
        {
          key: 'requestedDateOnly',
          type: AlphaColumnFilter,
          display:
            purchaseType === ProjectPurchaseType.COMPANY
              ? 'Purchase Requested'
              : 'Purchased Date',
          searchable: true,
          searchPlaceholder: 'Search Dates',
          width: 140,
          stickyLeft: 0,
        },
        {
          key: 'sysProjectId',
          display: 'ID',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Project IDs',
          export: { preview: true },
          width: 100,
          stickyLeft: 140,
        },
        {
          key: 'projectCategoryId',
          display: 'Category',
          searchable: false,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          resolveFilterLabel: (value) => (
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CategoryIconSmall categoryId={value as string} />
              </Grid>
              <Grid item>
                {projectCategories.find((cat) => cat.id === value)?.label || ''}
              </Grid>
            </Grid>
          ),
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
          export: {
            format: ({ projectCategoryId }) =>
              projectCategories.find((x) => x.id === projectCategoryId)
                ?.label || '',
            preview: true,
          },
          width: 130,
          stickyLeft: 240,
        },
        {
          key: 'projectTitle',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Projects',
          export: { preview: true },
          width: 300,
          stickyLeft: 370,
        },
      ],
    },
    {
      key: 'main',
      columns: _.compact([
        purchaseType === ProjectPurchaseType.COMPANY && {
          key: 'companyUser',
          display: 'Member',
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Members',
          resolveFilterLabel: (value) => (value as User).name,
          render: (c) =>
            c.companyUser ? (
              <>
                <UserImage user={c.companyUser} size="sm" />{' '}
                <span>{c.companyUser.name}</span>
              </>
            ) : null,
          export: { format: ({ companyUser }) => companyUser?.name || '' },
        },
        {
          key: 'companyId',
          display: 'Client',
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Company',
          resolveFilterLabel: (value) => (
            <CompanyName
              companyId={value as ProjectPurchaseHistory['companyId']}
            />
          ),
          render: (c) => <CompanyNameWithLogo companyId={c.companyId} />,
          export: { format: ({ company: c }) => c?.name || '' },
        },
        purchaseType === ProjectPurchaseType.USER && {
          key: 'user',
          display: 'Member',
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Members',
          resolveFilterLabel: (value) => (value as User).name,
          render: (c) =>
            c.user ? (
              <>
                <UserImage user={c.user as User} size="sm" />{' '}
                <span>{c.user.name}</span>
              </>
            ) : null,
          export: { format: ({ user }) => user?.name || '' },
        },
        {
          key: 'investment',
          display: 'USV Investment',
          searchable: false,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Investments',
          format: (data) => NumberUtils.format(data as number, 'currency2'),
          export: {
            format: ({ investment }) => investment,
          },
        },
        {
          key: 'purchaseAmount',
          display: 'Purchase Amount',
          searchable: false,
          type: AlphaColumnFilter,
          format: (data) => NumberUtils.format(data as number, 'currency2'),
          export: { format: ({ purchaseAmount }) => purchaseAmount },
        },
        {
          key: 'displayQuantity',
          display: 'Total Purchased',
          export: {
            format: ({ quantity }) => quantity,
            heading: 'Total Purchased',
          },
        },
        {
          key: 'creditUsed',
          display: 'Credit Used',
          render: ({ totalSpent, invoicedAmount }) => (
            <Typography noWrap variant="body2">
              {NumberUtils.format(
                totalSpent - (invoicedAmount || 0),
                'currency2',
              )}
            </Typography>
          ),
        },
        {
          key: 'invoicedAmount',
          display: 'Invoiced Amount',
          resolveFilterLabel: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
          format: (data) =>
            (data && NumberUtils.format(data as number, 'currency2')) || '',
        },
        {
          key: 'displayAllocated',
          display: 'Allocated',
          export: {
            format: ({ allocated }) => allocated,
            heading: 'Allocated',
          },
        },
        {
          key: 'displayPrice',
          display: 'Price Sold',
          type: AlphaColumnFilter,
          export: { format: ({ price }) => price },
        },
        {
          key: 'completedDateOnly',
          type: AlphaColumnFilter,
          display: 'Retirement Date',
          searchable: true,
          searchPlaceholder: 'Search Dates',
        },
        {
          key: 'serialNumber',
          display: 'Serial Number',
          searchable: true,
          searchPlaceholder: 'Search SN',
          export: { format: ({ serialNumber }) => serialNumber ?? '' },
        },
        {
          key: 'notes',
          display: 'Notes',
          render: (data) => {
            if (data.updateNotes?.length) {
              return (
                <IconButton
                  onClick={() => {
                    setIsNotesModalOpen(true);
                    setSelectedItem(data);
                  }}
                  size="large">
                  <SVG.Certificate />
                </IconButton>
              );
            }

            return null;
          },
        },
        {
          key: 'pdf-certificate',
          display: 'Retirement Certificate',
          style: { textAlign: 'center' },
          render: (data) =>
            data.serialNumber && data.completedDateOnly ? (
              <DownloadClientCertificate
                certificateUrl={replaceApiUrl(data.certificateUrl)}
              />
            ) : null,
        },
      ]),
    },
  ];

  return (
    <DataStateHandler
      loading={categoriesLoading || purchHistoryLoading}
      error={categoriesError || purchHistoryError}>
      <Grid
        container
        direction="column"
        style={{ height: 'calc(100vh - 190px)', position: 'relative' }}>
        <RoundedFlexBox>
          <GroupableSelectTable<typeof flattenedProjects[0]>
            tableData={flattenedProjects}
            tableStructure={tableStructure}
            queryVariables={{ 'sort-list': projectCategories }}
            exportable={userHasAccess('ZeroMe.Marketplace', 'EDIT')}
            exportOptions={{
              contentPlural: 'Projects',
              filename: 'purchase_history',
            }}
            actionItems={
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={
                      purchaseType === ProjectPurchaseType.COMPANY
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    onClick={() => setPurchaseType(ProjectPurchaseType.COMPANY)}
                    startIcon={<SVG.Company />}
                    className={classes.filterButton}>
                    Companies (
                    {grouped?.[ProjectPurchaseType.COMPANY]?.length || 0})
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={
                      purchaseType === ProjectPurchaseType.USER
                        ? 'contained'
                        : 'outlined'
                    }
                    color="primary"
                    onClick={() => setPurchaseType(ProjectPurchaseType.USER)}
                    startIcon={<SVG.User />}
                    className={classes.filterButton}>
                    Individuals (
                    {grouped?.[ProjectPurchaseType.USER]?.length || 0})
                  </Button>
                </Grid>
              </Grid>
            }
          />
        </RoundedFlexBox>
        <Dialog
          open={isNotesModalOpen}
          onClose={() => {
            setIsNotesModalOpen(false);
            setSelectedItem(undefined);
          }}>
          <DialogContent>
            {!!selectedItem && (
              <OffsetRequestNotes projectHistory={selectedItem} />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIsNotesModalOpen(false);
                setSelectedItem(undefined);
              }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </DataStateHandler>
  );
};

export default PurchaseHistory;
