import React from 'react';
import MainLayout from '../../components/layouts/MainLayout';
import Clients from '../../components/trader/Clients/Clients';

const TraderClients: React.FC = () => (
  <MainLayout>
    <Clients />
  </MainLayout>
);

export default TraderClients;
