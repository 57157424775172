import { Grid } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import React from 'react';
import { chartColors } from '../../../../theme/theme';
import { MonthlyUsersPoint } from '../../../../types/dashboard/types';
import ChartUtils from '../../../../utils/chartUtils';
import { formatDateStringUtc } from '../../../../utils/dateUtils';
import NumberUtils from '../../../../utils/numberUtils';
import AppCard from '../../../common/AppCard/AppCard';
import CardFooter from '../../../common/CardFooter/CardFooter';
import ChartLegend from '../../../common/ChartLegend/ChartLegend';
import { FULL_Y_BAR_TOOLTIP_LAYERS } from '../../../common/ChartTooltips/FullYBarTooltipLayer';
import TotalsTooltip from '../../../common/ChartTooltips/TotalsTooltip';
import NoDataAvailable from '../../../common/NoDataAvailable';

type Props = {
  total: number;
  data: Array<MonthlyUsersPoint>;
};
const MonthlyUsersGraph: React.FC<Props> = ({ total, data }) => {
  const getTooltipData = (nivoData: { [k: string]: string | number }) => {
    const keysWithValues = ['Active', 'Inactive'];
    return keysWithValues.map((k) => ({
      color: k === 'Inactive' ? chartColors.darkBlue : chartColors.blue,
      id: k,
      value: nivoData[k] as number,
    }));
  };

  const monthlyUsers = data.map((item) => {
    const monthTotal = item.inactive + item.active;
    return {
      ...item,
      Inactive: item.inactive / monthTotal,
      Active: item.active / monthTotal,
      month: formatDateStringUtc(item.date, 'MMM'),
    };
  });

  const hasData =
    monthlyUsers.findIndex(({ Inactive, Active }) => Inactive + Active) > -1;

  return (
    <AppCard
      headerProps={{ title: 'MONTHLY USERS', tooltipId: 'monthlyUsers' }}>
      <Grid container spacing={0} direction="column">
        <div style={{ height: 275 }}>
          {hasData ? (
            <ResponsiveBar
              data={monthlyUsers}
              keys={['Inactive', 'Active']}
              indexBy="month"
              margin={{ top: 20, right: 0, bottom: 55, left: 28 }}
              padding={0.5}
              innerPadding={4}
              groupMode="grouped"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors={[chartColors.darkBlue, chartColors.blue]}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              valueFormat=" >(,~%"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32,
              }}
              axisLeft={{
                format: (value) => NumberUtils.format(value, 'percent'),
                tickSize: 0,
                tickPadding: 1,
                tickRotation: 0,
                tickValues: [0, 0.2, 0.4, 0.6, 0.8, 1],
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              legendLabel={(datum) =>
                `Enrolled ${
                  String(datum.id).charAt(0).toUpperCase() +
                  String(datum.id).slice(1)
                }`
              }
              layers={FULL_Y_BAR_TOOLTIP_LAYERS}
              tooltip={(props) => (
                <TotalsTooltip
                  format="percent"
                  items={getTooltipData(props.data)}
                />
              )}
              role="application"
              ariaLabel="Participation Activity Chart"
              {...ChartUtils.configureChartMinMaxValues(hasData)}
            />
          ) : (
            <NoDataAvailable />
          )}
        </div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Grid item>
            <ChartLegend
              keys={[
                {
                  color: chartColors.darkBlue,
                  label: 'Inactive Users',
                },
                {
                  color: chartColors.blue,
                  label: 'Active Users',
                },
              ]}
            />
          </Grid>
          <Grid item>
            <CardFooter
              data={NumberUtils.format(total, 'integer')}
              label="Total Members"
            />
          </Grid>
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default MonthlyUsersGraph;
