/* eslint-disable no-param-reassign */

// https://github.com/lukehorvat/computed-style-to-inline-style/blob/master/lib/index.ts
const computedStyleToInlineStyle = (
  element: HTMLElement | SVGElement,
  getComputedStyle: (
    elt: Element,
    pseudoElt?: string | null | undefined,
  ) => CSSStyleDeclaration,
  options?: {
    recursive?: boolean;
    properties?: string[];
  },
) => {
  if (!element) {
    throw new Error(
      'htmlUtils - computedStyleToInlineStyle - No element found',
    );
  }

  if (options?.recursive) {
    Array.prototype.forEach.call(element.children, (child) => {
      computedStyleToInlineStyle(child, getComputedStyle, options);
    });
  }

  const computedStyle = getComputedStyle(element);
  Array.prototype.forEach.call(
    options?.properties || computedStyle,
    (property) => {
      const computedValue = computedStyle.getPropertyValue(property);

      // Adding support for text-decoration since emails don't like it
      // and work better with <u> and <s> tags
      if (property === 'text-decoration') {
        if (computedValue.includes('underline')) {
          element.innerHTML = `<u>${element.innerHTML}</u>`;
        }
        if (computedValue.includes('line-through')) {
          element.innerHTML = `<s>${element.innerHTML}</s>`;
        }
      }
      element.style[property] = computedStyle.getPropertyValue(property);
    },
  );
};

// eslint-disable-next-line import/prefer-default-export
export const getHtmlWithInlineStyles = async (
  element: HTMLElement,
): Promise<string | undefined> => {
  const doc = document.createElement('div');
  doc.innerHTML = element.innerHTML;
  element.appendChild(doc);

  if (doc) {
    computedStyleToInlineStyle(doc, window.getComputedStyle, {
      recursive: true,
    });
  }
  const response = doc.innerHTML;

  element.removeChild(doc);
  doc.remove();
  return response;
};
