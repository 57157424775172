import React from 'react';
import { Grid, Typography } from '@mui/material';
import { UserActivityType } from '../../../types/user/activityTypes';
import { UserActivityListItemProps } from './UserActivityListItem';
import CompanyLogo from '../../common/Company/CompanyLogo';
import UserActivityRenderer, {
  LEFT_COLUMN_WIDTH,
  renderActivityDate,
} from './UserActivityRenderer';
import { replaceApiUrl } from '../../../apollo/client';

const UserActivityUserAllocationListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    if (
      userActivity.activityType !==
      UserActivityType.ALLOTMENT_CREDIT_DISBURSEMENT
    ) {
      return null;
    }

    return (
      <UserActivityRenderer
        leftComponent={
          <CompanyLogo
            companyId={userActivity.data.companyId}
            style={{
              width: LEFT_COLUMN_WIDTH,
              height: LEFT_COLUMN_WIDTH,
              borderRadius: LEFT_COLUMN_WIDTH / 2,
            }}
          />
        }
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">
                {userActivity.message}{' '}
                <a
                  href={replaceApiUrl(userActivity.data.certificateUrl)}
                  target="certificate">
                  Download your certificate!
                </a>
              </Typography>
            </Grid>
            <Grid item container flexDirection="row" alignItems="center">
              {renderActivityDate(userActivity)}
            </Grid>
          </Grid>
        }
        rightComponent={
          <Typography variant="h4">{`${new Intl.NumberFormat('en-us', {
            maximumFractionDigits: 1,
          }).format(userActivity.data.amount)} MT`}</Typography>
        }
      />
    );
  },
);

export default UserActivityUserAllocationListItem;
