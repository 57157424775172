import { MutationUser } from '../types/user/types';

export const enum UserStatus {
  enrolling = 'Enrolling',
  active = 'Active',
  inactive = 'Inactive',
}

// eslint-disable-next-line import/prefer-default-export
export const getUserStatus = (user: MutationUser) => {
  let status = UserStatus.inactive;

  if (user.isActive) {
    status = user.isEnrolling ? UserStatus.enrolling : UserStatus.active;
  }

  return status;
};
