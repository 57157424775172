/* eslint-disable import/prefer-default-export */
import { TypedDocumentNode, gql } from '@apollo/client';

import { Contest } from '../../types/contest/types';

export const GET_CONTEST = gql`
  query GET_CONTEST($contestId: String!) {
    findContest(contestId: $contestId) {
      id
      title
      contestType
      duration {
        startPeriod
        endPeriod
        endDate
        startDate
      }
      userPoints {
        month
        totalPoints
        pointsByCategory {
          category
          points
        }
        pointBreakouts {
          eventType
          eventTypeDescription
          pointTotals
          category
        }
      }
    }
  }
` as TypedDocumentNode<{
  findContest: Contest;
}>;
