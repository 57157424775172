import React, { useMemo } from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import theme from '../../../theme/memberTheme';
import { Colors } from '../../../theme/shared';
import SVG from '../../../assets/svg-v2';

export type WaterComparisonFaucetProps = {
  userTotal: number;
  organizationTotal: number;
  countryTotal: number;
};

export function WaterComparisonFaucet(props: WaterComparisonFaucetProps) {
  const { userTotal, organizationTotal, countryTotal } = props;
  const hasData = userTotal > 0;

  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const dropletMaxSize = { width: 118, height: 173 };

  const largest = Math.max(userTotal, organizationTotal, countryTotal);

  let dropletSizeType = useMemo(
    () => [
      { dropletSvgType: 'personal', value: userTotal },
      { dropletSvgType: 'company', value: organizationTotal },
      { dropletSvgType: 'country', value: countryTotal },
    ],
    [organizationTotal, countryTotal, userTotal],
  );

  dropletSizeType = dropletSizeType.sort((a, b) => b.value - a.value);

  const personalSvg = useMemo(() => {
    const scale = userTotal / largest;

    return (
      <div style={{ position: 'absolute', width: '100%' }}>
        <svg
          key="personal"
          width={dropletMaxSize.width * scale}
          height={dropletMaxSize.height * scale}
          viewBox="0 0 76 114"
          style={{ display: 'block', margin: 'auto' }}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 80.62C0 91.0141 9.47258 113.102 37.877 113.102C66.2815 113.102 75.7543 89.8841 75.7543 79.49C75.7543 47.658 37.877 0.101562 37.877 0.101562C37.877 0.101562 0 47.5616 0 80.62Z"
            fill={Colors.LightGrey}
          />
        </svg>
      </div>
    );
  }, [dropletMaxSize.height, dropletMaxSize.width, largest, userTotal, theme]);

  const companySvg = useMemo(() => {
    const scale = organizationTotal / largest;

    return (
      <div style={{ position: 'absolute', width: '100%' }}>
        <svg
          key="company"
          width={dropletMaxSize.width * scale}
          height={dropletMaxSize.height * scale}
          viewBox="0 0 118 173"
          style={{ display: 'block', margin: 'auto' }}>
          <path
            fillRule="evenodd"
            fill="#CAD8FD"
            stroke={theme.palette.primary.main}
            d="M1 122.948c0 15.729 14.505 49.154 58 49.154 43.494 0 58-35.135 58-50.864C117 73.068 59 1.102 59 1.102S1 72.922 1 122.948Z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    );
  }, [
    dropletMaxSize.height,
    dropletMaxSize.width,
    largest,
    organizationTotal,
    theme,
  ]);

  const countrySvg = useMemo(() => {
    const scale = countryTotal / largest;

    return (
      <div style={{ position: 'absolute', width: '100%' }}>
        <svg
          key="country"
          width={dropletMaxSize.width * scale}
          height={dropletMaxSize.height * scale}
          viewBox="0 0 66 98"
          style={{ display: 'block', margin: 'auto' }}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1 69.5066C1 78.337 9.00277 97.1016 32.9999 97.1016C56.9969 97.1016 64.9999 77.377 64.9999 68.5466C64.9999 41.5035 32.9999 1.10156 32.9999 1.10156C32.9999 1.10156 1 41.4216 1 69.5066Z"
            fill="none"
            stroke={theme.palette.primary.dark}
            strokeDasharray="6"
          />
        </svg>
      </div>
    );
  }, [
    dropletMaxSize.height,
    dropletMaxSize.width,
    largest,
    countryTotal,
    theme,
  ]);

  const renderContent = useMemo(
    () =>
      dropletSizeType.map((map) => {
        switch (map.dropletSvgType) {
          case 'personal':
            return personalSvg;
          case 'company':
            return companySvg;
          case 'country':
            return countrySvg;
          default:
            return null;
        }
      }),
    [dropletSizeType, personalSvg, countrySvg, companySvg],
  );

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <SVG.FaucetPipe
          fill="#CAD8FD"
          style={{ position: 'relative', left: -16 }}
        />
        <SVG.FaucetHead
          fill="#CAD8FD"
          style={{ position: 'relative', left: isSm ? -16 : -65, top: 18 }}
        />
      </Grid>
      {hasData ? (
        <Grid
          item
          style={{
            position: 'relative',
            height: 200,
            top: 12,
            left: isSm ? 0 : 20,
          }}>
          {renderContent}
        </Grid>
      ) : (
        <Grid
          sx={{ position: 'relative', top: 50, height: 150 }}
          item
          container
          direction="column"
          alignItems="center">
          <Typography sx={{ color: 'white', mb: 1 }} variant="h3">
            Water use data unavailable
          </Typography>
          <Typography align="center" variant="body1" sx={{ color: 'white' }}>
            Update your Water Use Profile to calculate your Water Footprint.
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
