import { Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { ProjectContext } from '../../../contexts/ProjectContext';
import NumberUtils from '../../../utils/numberUtils';
import AppInfo from '../AppInfo';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 12,
  },
  paper: {
    padding: 16,
    height: '100%',
  },
  pending: {
    color: '#3E7DA2',
    backgroundColor: theme.palette.backgrounds.light,
    padding: '0 4px',
  },
}));

const ProjectTraderInfo: React.FC = () => {
  const classes = useStyles();
  const { project } = useContext(ProjectContext);

  return (
    <Grid item container spacing={2} direction="row" alignContent="stretch">
      <Grid item xs>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between">
            <Typography variant="subtitle1" className={classes.subtitle}>
              AMOUNT SOLD
            </Typography>
            <AppInfo id="projectAmountSold" />
          </Grid>
          <Typography variant="h2">
            {NumberUtils.format(project.totalRevenue?.revenue || 0, 'currency')}{' '}
            / {NumberUtils.format(project.totalRevenue?.mt || 0, 'integer')}{' '}
            {project.uom}
          </Typography>
          {(project.allocationTotals &&
            (project.allocationTotals.pending ||
              project.allocationTotals?.pendingMT) && (
              <Grid container spacing={1}>
                <Grid item>
                  <Typography variant="subtitle1">
                    {project.allocationTotals?.pending &&
                      NumberUtils.format(
                        project.allocationTotals?.pending,
                        'currency',
                      )}{' '}
                    /{' '}
                    {project.allocationTotals?.pendingMT &&
                      NumberUtils.format(
                        project.allocationTotals.pendingMT,
                        'integer',
                      )}
                    {project.uom ? ` ${project.uom}` : ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" className={classes.pending}>
                    Pending
                  </Typography>
                </Grid>
              </Grid>
            )) ||
            null}
        </Paper>
      </Grid>
      <Grid item xs>
        <Paper className={classes.paper}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            AMOUNT AVAILABLE
          </Typography>
          <Typography variant="h2">
            {NumberUtils.format(project?.remainingQuantity || 0, 'integer')}{' '}
            {project.uom ?? ''}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs>
        <Paper className={classes.paper}>
          <Grid container justifyContent="space-between">
            <Typography variant="subtitle1" className={classes.subtitle}>
              TOTAL PURCHASED
            </Typography>
            <AppInfo id="projectTotalPurchased" />
          </Grid>
          <Typography variant="h2">
            {NumberUtils.format(
              project?.inventoryHistory?.reduce(
                (acc, x) => acc + x.totalPurchaseAmount,
                0,
              ) || 0,
              'currency',
            )}{' '}
            / {NumberUtils.format(project?.availableQuantity || 0, 'integer')}
            {project.uom ? ` ${project.uom}` : ''}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProjectTraderInfo;
