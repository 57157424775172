import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { Company } from '../../../types/company/types';
import { COMPANY_DISPLAY_INFO_QUERY } from '../../../graphql/company/company';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import DefaultImage from '../DefaultImage';

const useStyles = makeStyles(() => ({
  logo: {
    width: 32,
    height: 32,
    objectFit: 'cover',
    display: 'block',
    borderRadius: 16,
  },
}));

type Props = {
  companyId?: string;
  logoUris?: Record<'thumb', string>;
  className?: string;
  style?: React.CSSProperties;
};

const CompanyLogo: React.FC<Props> = ({
  companyId,
  logoUris,
  className,
  style,
}) => {
  const classes = useStyles();

  const { loading, data: { company } = {} } = useQuery<{
    company: Company;
  }>(COMPANY_DISPLAY_INFO_QUERY, {
    skip: !companyId || !!logoUris?.thumb,
    variables: {
      id: companyId,
    },
  });

  // const hasImage = logoUris?.thumb || company?.logoUris?.thumb;
  const hasImage = false;

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasImage ? (
        <DataStateHandler loading={loading} loadingPaddingY={0}>
          <img
            key={companyId}
            alt="Client"
            src={logoUris?.thumb || company?.logoUris?.thumb}
            className={`${classes.logo} ${className || ''}`}
            style={style}
          />
        </DataStateHandler>
      ) : (
        <DefaultImage style={{ borderRadius: 16, width: 32 }} height={32} />
      )}
    </>
  );
};

export default CompanyLogo;
