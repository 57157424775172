import { useMutation } from '@apollo/client';
import { Grid, Menu, MenuItem, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext } from 'react';

import { UPSERT_QUOTATION } from '../../../graphql/communications/communications';
import useMenuAnchor from '../../../hooks/useMenuAnchor';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationDetailsHeader from '../CommunicationDetailsHeader';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import { Quotation } from '../types';
import AppTextField from '../../common/AppTextField';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';

type Props = {
  quotation: Quotation | undefined;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 64px',
  },
  form: {
    padding: '16px 0px !important',
  },
  paper: {
    padding: 24,
  },
}));

const schema = yup.object().shape({
  quote: yup.string().required(),
  author: yup.string().required(),
  status: yup.string().oneOf(['DRAFT', 'PUBLISHED']),
});

const QuotationDetails: React.FC<Props> = ({ quotation = {}, onClose }) => {
  const { refetchCommunicationsQuery } = useContext(CommunicationsContext);
  const { onMenuClick, isMenuOpen, anchorEl, setAnchorEl } = useMenuAnchor();

  const [upsert, { loading: upsertLoading, error: upsertError }] =
    useMutation(UPSERT_QUOTATION);

  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const initialValues = {
    quote: '',
    author: '',
    status: 'DRAFT',
    __typename: 'Quotation',
    ...quotation,
  };

  const canEdit = userHasAccess('ZeroMe.Communication.Quotes', 'EDIT');

  return (
    <DataStateHandler loading={upsertLoading} error={upsertError}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={async (values) => {
          const {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            __typename,
            ...input
          } = values;

          await upsert({
            variables: { input },
            onCompleted: () => onClose(),
          });
          refetchCommunicationsQuery();
        }}
        validateOnMount>
        {(props) => (
          <Grid
            container
            direction="column"
            className={classes.root}
            spacing={2}>
            <Grid item>
              <CommunicationDetailsHeader
                title={
                  quotation
                    ? `${canEdit ? 'Edit' : 'View'} Details`
                    : 'Create New'
                }
                canEdit={canEdit}
                onClose={onClose}
                saveButton={
                  <div>
                    <SmallPaddedButton
                      disabled={
                        !props.isValid || props.isSubmitting || !canEdit
                      }
                      variant="contained"
                      color="primary"
                      onClick={onMenuClick}>
                      Save
                    </SmallPaddedButton>
                    {anchorEl ? (
                      <Menu
                        open={isMenuOpen}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        keepMounted>
                        <MenuItem
                          id="draft"
                          onClick={() => {
                            props.setFieldValue('status', 'DRAFT');
                            props.submitForm();
                          }}>
                          <Typography variant="caption">
                            Save as Draft
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          id="published"
                          onClick={() => {
                            props.setFieldValue('status', 'PUBLISHED');
                            props.submitForm();
                          }}>
                          <Typography variant="caption">Publish</Typography>
                        </MenuItem>
                      </Menu>
                    ) : null}
                  </div>
                }
              />
            </Grid>

            <Grid item className={classes.form}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">Description</Typography>
                    <Field
                      name="quote"
                      label=""
                      component={AppTextField}
                      disabled={!canEdit}
                      placeholder="Quote Text"
                      fullWidth
                      multiline
                      rows={20}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">Author</Typography>
                    <Field
                      name="author"
                      label=""
                      component={AppTextField}
                      disabled={!canEdit}
                      placeholder="Author Name"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Formik>
    </DataStateHandler>
  );
};

export default QuotationDetails;
