import { Box, Typography, Grid, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import React, { useState, useContext } from 'react';

import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import RoundedFlexBox from '../trader/RoundedFlexBox';

import { GroupableTableStructure } from '../common/Table/types';
import { Quotation } from './types';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import QuotationDetails from './forms/QuotationDetails';
import PageTitle from '../common/PageTitle';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 16,
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const tableStructure: GroupableTableStructure<Quotation>[] = [
  {
    key: 'main',
    columns: [
      {
        key: 'quote',
        display: 'Quote',
        searchable: true,
        type: AlphaColumnFilter,
        searchPlaceholder: 'Search Quote',
        render: (c) => (
          <Typography
            variant="body2"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 700,
            }}>
            {c.quote}
          </Typography>
        ),
      },
      {
        key: 'author',
        display: 'Author',
        searchable: true,
        type: AlphaColumnFilter,
        searchPlaceholder: 'Search Author',
        render: (c) => <Typography variant="body2">{c.author}</Typography>,
      },
      {
        key: 'status',
        display: 'Status',
        type: AlphaColumnFilter,
        render: (c) => (
          <Typography
            variant="body2"
            style={c.status === 'PUBLISHED' ? { color: '#398360' } : undefined}>
            {_.startCase(c.status.toLowerCase())}
          </Typography>
        ),
      },
    ],
  },
];

const QuotationsList: React.FC = () => {
  const classes = useStyles();
  const [quote, setQuote] = useState<Quotation | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const { userHasAccess } = useContext(AuthorizationContext);
  const { quotations } = useContext(CommunicationsContext);

  const canEdit = userHasAccess('ZeroMe.Communication.Quotes', 'EDIT');

  return (
    <>
      <Box px={8} py={4}>
        <Grid container direction="column">
          <Grid item>
            <PageTitle title="Quotes">
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {canEdit && (
                  <SmallPaddedButton
                    onClick={() => {
                      setIsOpen(true);
                      setQuote(undefined);
                    }}>
                    + Create New
                  </SmallPaddedButton>
                )}
              </>
            </PageTitle>
          </Grid>
          <Grid item className={classes.table}>
            <Grid
              container
              direction="column"
              style={{ height: 'calc(100vh - 110px)' }}>
              <RoundedFlexBox>
                <GroupableSelectTable<Quotation>
                  tableData={quotations}
                  tableStructure={tableStructure}
                  hideStickyCol
                  onRowClick={(q) => {
                    setQuote(q);
                    setIsOpen(true);
                  }}
                />
              </RoundedFlexBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Drawer
        open={!!isOpen}
        onClose={() => {
          setQuote(undefined);
          setIsOpen(false);
        }}
        anchor="right">
        <Box className={classes.drawer}>
          <QuotationDetails
            quotation={quote}
            onClose={() => {
              setIsOpen(false);
              setQuote(undefined);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
};

export default QuotationsList;
