import { Grid } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from '../../components/layouts/MainLayout';
import CreateProjectHeader from '../../components/trader/CreateProject/CreateProjectHeader';
import CreateProjectSteps from '../../components/trader/CreateProject/CreateProjectSteps';
import ProjectProvider from '../../contexts/ProjectContext';

const CreateProject: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  return (
    <MainLayout childBoxStyle={{ height: '100%' }}>
      <ProjectProvider projectId={id} editable createProjectFlow>
        <Grid container direction="column" style={{ height: '100%' }}>
          <CreateProjectHeader />
          <CreateProjectSteps />
        </Grid>
      </ProjectProvider>
    </MainLayout>
  );
};

export default CreateProject;
