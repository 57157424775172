import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@mui/material';
import MemberLayout from '../../../components/layouts/MemberLayout';
import UserActivityList from '../../../components/member/activities/UserActivityList';

type ActivityTabTypes = 'ALL' | 'UNREAD';

const MemberActivities: React.FC = () => {
  const [tabValue, setTabValue] = useState<ActivityTabTypes>('ALL');

  return (
    <MemberLayout>
      <Grid container flexDirection="column">
        <Grid item>
          <Tabs value={tabValue} onChange={(e, v) => setTabValue(v)}>
            <Tab label="All" value="ALL" />
            <Tab label="Unread" value="UNREAD" />
          </Tabs>
        </Grid>
        <Grid item pt={4}>
          <UserActivityList unreadOnly={tabValue === 'UNREAD'} />
        </Grid>
      </Grid>
    </MemberLayout>
  );
};

export default MemberActivities;
