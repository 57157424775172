import allQuestionsCompletedDisabled from './AllQuestionsCompletedDisabled';
import allQuestionsCompletedEnabled from './AllQuestionsCompletedEnabled';

const allQuestionsCompleted = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? allQuestionsCompletedDisabled(level, size)
    : allQuestionsCompletedEnabled(level, size);

export default allQuestionsCompleted;
