import { useMutation } from '@apollo/client';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import SVG from '../../assets/svg';
import { ACTIVATE_ENROLLING_USERS } from '../../graphql/user/userMutations';
import { Company } from '../../types/company/types';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import LoadingBackdrop from '../common/LoadingBackdrop';
import { ZeroMeTM } from '../../utils/stringConstants';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 600,
    padding: 16,
  },
  closeIcon: {
    position: 'relative',
    top: -8,
    color: theme.palette.text.primary,
  },
}));

type Props = {
  refetch?: () => void;
  companyId: Company['id'];
  disabled?: boolean;
};

const ActivateEnrollingModal: React.FC<Props> = ({
  refetch,
  companyId,
  disabled,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [resultDialogOpen, setResultDialogOpen] = useState(false);

  const [activateEnrollingUsers, { loading }] = useMutation(
    ACTIVATE_ENROLLING_USERS,
  );

  const close = () => {
    setOpen(false);
    setResultDialogOpen(false);
  };

  return (
    <>
      <SmallPaddedButton
        variant="outlined"
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}
        disabled={disabled}>
        Activate Enrolling Users
      </SmallPaddedButton>
      <LoadingBackdrop open={loading} />
      <Dialog open={open} onClose={close}>
        <Box className={classes.dialog}>
          <Grid container direction="column" alignItems="stretch">
            <Grid item container direction="row" justifyContent="space-between">
              <Typography align="center" variant="h3">
                Activate Enrolling Users
              </Typography>
              <IconButton
                onClick={close}
                className={classes.closeIcon}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
            <div style={{ margin: '40px 0' }}>
              <Typography variant="body1">
                This will change any users in &quot;Enrolling&quot; status to
                &quot;Active&quot; status, which will send them a Welcome to
                {ZeroMeTM} Email and allow them to log into the mobile
                application.
              </Typography>
            </div>
            <Grid item>
              <SmallPaddedButton
                fullWidth
                onClick={async () => {
                  setOpen(false);

                  await activateEnrollingUsers({
                    variables: {
                      companyId,
                    },
                  });

                  setResultDialogOpen(true);

                  if (refetch) {
                    refetch();
                  }
                }}>
                Activate Users
              </SmallPaddedButton>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        onClose={() => close()}
        open={!!resultDialogOpen}
        maxWidth="sm"
        fullWidth>
        <Box className={classes.dialog}>
          <Grid container direction="column" alignContent="center" spacing={2}>
            <Grid item container direction="row" justifyContent="space-between">
              <Typography variant="h1">Users Activated</Typography>
              <IconButton
                onClick={close}
                className={classes.closeIcon}
                size="large">
                <SVG.Close />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ margin: '16px 0' }}>
                All Enrolling users have been successfully activated!
              </Typography>
            </Grid>
            <Grid item>
              <SmallPaddedButton fullWidth onClick={close}>
                OK
              </SmallPaddedButton>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default ActivateEnrollingModal;
