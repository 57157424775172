import { useQuery, useMutation } from '@apollo/client';
import {
  Dialog,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useContext } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { GET_TERMS_PUBLIC } from '../../graphql/settings';
import client from '../../apollo/settingsClient';
import { UPDATE_USER_PROFILE } from '../../graphql/user/userMutations';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
  },
  title: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: 8,
  },
  terms: {
    marginTop: 8,
    marginBottom: 8,
    padding: 16,
    height: 400,
    overflowY: 'auto',
  },
}));

const TermsAndConditions = () => {
  const { loading, user } = useContext(AuthorizationContext);
  const { loading: termsLoading, data: { termsPublic: terms = [] } = {} } =
    useQuery(GET_TERMS_PUBLIC, {
      client,
      variables: {
        appType: 'portal',
      },
    });
  const [update, { loading: updateLoading }] = useMutation(UPDATE_USER_PROFILE);
  const classes = useStyles();

  if (loading || !user || termsLoading) {
    return null;
  }

  const needsUpdate = terms
    .map((t) => t.lastUpdatedDate)
    .some(
      (lastUpdated) =>
        !user.acceptedTermsDate ||
        moment(user.acceptedTermsDate).isBefore(moment(lastUpdated)),
    );

  return (
    <Dialog maxWidth="md" fullWidth open={needsUpdate}>
      <Grid container direction="column" className={classes.root}>
        <Typography variant="h1" className={classes.title}>
          New Terms are Available
        </Typography>
        <Grid item className={classes.terms}>
          {terms.map((t) => (
            <div
              key={t.type}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t.content }}
            />
          ))}
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                disabled={updateLoading}
                onClick={() => {
                  update({
                    variables: {
                      user: {
                        acceptedTermsDate: new Date().toISOString(),
                        acceptedTermsPlatform: 'portal',
                      },
                    },
                  });
                }}
              />
            }
            label="By checking this box, you are agreeing to our terms of service and privacy policy"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TermsAndConditions;
