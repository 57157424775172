import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useContext } from 'react';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import NumberUtils from '../../../utils/numberUtils';
import { getSDVistaDisplay } from '../../../utils/projectUtils';
import EditProjectStepModal from './EditProjectStepModal';

const useStyles = makeStyles(() => ({
  subHeader: {
    margin: '32px 0px 32px 0px',
  },
  browse: {
    margin: '24px 0px',
  },
  detailsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 16,
    paddingTop: 0,
    background: '#F1F8FD',
    borderRadius: 16,
    margin: '20px 0px',
  },
}));

const ProjectData: React.FC = () => {
  const classes = useStyles();
  const { project, projectAuditors, hideTraderFields } =
    useContext(ProjectContext);
  const auditor = projectAuditors.find((x) => x.id === project.auditor);

  return (
    <Grid
      item
      container
      className={classes.detailsBox}
      spacing={2}
      direction="row">
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Project Details
        </Typography>
        <EditProjectStepModal step={ProjectDetailsStep.details} />
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            START DATE
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.startDate
              ? moment(project.startDate).format('MMM Do, YYYY')
              : ' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            END DATE
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.endDate
              ? moment(project.endDate).format('MMM Do, YYYY')
              : ' '}
          </Typography>
        </Grid>
      </Grid>
      {!hideTraderFields && (
        <Grid item container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              PURCHASE PRICE
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {NumberUtils.format(
                project?.inventoryHistory?.reduce(
                  (acc, x) => acc + x.totalPurchaseAmount,
                  0,
                ) || 0,
                'currency',
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              QUANTITY
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {project?.availableQuantity
                ? `${NumberUtils.format(
                    project.availableQuantity,
                    'integer',
                  )} ${project.uom}`
                : ' '}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            PROJECT ID
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.projectId || ' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            REGISTRY
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.source || ' '}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="textSecondary">
          SPONSOR
        </Typography>
        <Typography variant="body1" color="textPrimary">
          {project?.sponsor || ' '}
        </Typography>
      </Grid>

      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            COUNTRY
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.country || ' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            HQ COUNTRY
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.projectHQ || ' '}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            VINTAGE
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.vintage || ' '}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="textSecondary">
            QUALIFICATION
          </Typography>
          <Typography variant="body1" color="textPrimary">
            {project?.indicator || ' '}
          </Typography>
        </Grid>
      </Grid>
      {hideTraderFields ? null : (
        <Grid item container>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              AUDITOR
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {auditor?.name || ' '}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              SD VISTA
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {getSDVistaDisplay(project?.sdVista)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProjectData;
