import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import FileUpload from './FileUpload/FileUpload';
import SVG from '../../assets/svg';
import { Disableable, FileAttachment } from '../../types/common';

type Props = Disableable & {
  onChange: (file: FileAttachment) => void;
  value: string;
};

const ImageUpload: React.FC<Props> = ({ disabled, onChange, value }) => {
  const [imageUrl, setImageUrl] = useState(value || '');

  const uploadDivStyle = {
    height: 200,
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <Typography variant="body2">Preview Image</Typography>
      {disabled && !!imageUrl && <div style={uploadDivStyle} />}
      {!disabled && !!imageUrl && (
        <FileUpload
          onFileChange={(files) => {
            if (files.length) {
              onChange({ file: files[0].file });
              setImageUrl(files[0].imageDataUrl);
            }
          }}
          uploadDivStyle={uploadDivStyle}
          uploadIcon={
            <Box position="relative" display="flex" justifyContent="flex-end">
              <SVG.Edit />
            </Box>
          }
          uploadLabel={null}
        />
      )}
      {!disabled && !imageUrl && (
        <FileUpload
          showFiles
          onFileChange={(files) => {
            if (files.length) {
              onChange({ file: files[0].file });
            }
          }}
          uploadDivStyle={{ padding: 64 }}
          uploadIcon={<SVG.Portrait />}
          uploadLabel={
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h4">Drag your image here</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" style={{ fontSize: 10 }}>
                  or click to browse for a file
                </Typography>
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
};

export default ImageUpload;
