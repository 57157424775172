import { Box, Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React, { useContext } from 'react';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import AppTextField from '../../common/AppTextField';
import { CommunicationInput } from '../types';
import SVG from '../../../assets/svg';
import { Disableable } from '../../../types/common';

const useStyles = makeStyles((theme) => ({
  chip: {
    padding: 8,
    margin: 8,
    borderRadius: 4,
    marginRight: 16,
    color: '#FFFFFF',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.primary.dark,
  },
  chipDeleteIcon: {
    width: 8,
    height: 8,
  },
  chipLabel: {
    width: '100%',
  },
}));

const CategoryIdSelect: React.FC<Disableable> = ({ disabled }) => {
  const { setTypedFieldValue, values } =
    useTypedFormikContext<CommunicationInput>();
  const { communicationCategories } = useContext(CommunicationsContext);
  const classes = useStyles();

  const handleDelete = (id: string) => {
    const categories = values.categoryIds.filter((c) => c !== id);
    setTypedFieldValue('categoryIds', categories);
  };
  return (
    <>
      <Typography variant="body2">Select Category</Typography>
      <Field
        name="categoryIds"
        label=""
        component={AppTextField}
        fullWidth
        select
        disabled={disabled}
        options={communicationCategories.map((category) => ({
          value: category.id,
          label: category.label,
        }))}
        SelectProps={{
          renderValue: (selected: string[]) => (
            <Box sx={{ mr: 1 }}>
              {selected.map((value) => (
                <Chip
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  className={classes.chip}
                  classes={{
                    deleteIcon: classes.chipDeleteIcon,
                    label: classes.chipLabel,
                  }}
                  key={value}
                  onDelete={disabled ? undefined : () => handleDelete(value)}
                  deleteIcon={disabled ? undefined : <SVG.MultiSelectDelete />}
                  label={
                    communicationCategories.find((c) => c.id === value)?.label
                  }
                />
              ))}
            </Box>
          ),
          multiple: true,
          value: values.categoryIds || [],
          onChange: (e: { target: { value: string[] } }) => {
            setTypedFieldValue('categoryIds', e.target.value);
          },
        }}
      />
    </>
  );
};

export default CategoryIdSelect;
