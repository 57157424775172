import React from 'react';
import { Grid, Typography } from '@mui/material';

import MemberLayout from '../../../components/layouts/MemberLayout';
import FAQList from '../../../components/member/settings/FAQList';

const MemberFAQs: React.FC = () => (
  <MemberLayout>
    <Grid container spacing={2} sx={{ mt: 6 }}>
      <Typography variant="h2" sx={{ mb: 2 }}>
        Below is a list of our most frequently asked questions.
      </Typography>
      <Typography sx={{ mb: 2 }} variant="body1">
        If you can’t find the answer to your question, please feel free to
        contact us below.
      </Typography>
      <FAQList />
    </Grid>
  </MemberLayout>
);

export default MemberFAQs;
