import { useContext } from 'react';
import { AuthorizationContext } from '../contexts/AuthorizationContext';
import { DashboardContext } from '../contexts/DashboardContext';

const useCurrentCompany = () => {
  const { company } = useContext(AuthorizationContext);
  const { division, includeChildren } = useContext(DashboardContext);
  const companyId: string = division || company.id;
  return {
    companyId,
    includeChildren,
  };
};

export default useCurrentCompany;
