import React from 'react';
import { Chip, Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@apollo/client';
import { t } from 'i18next';

import AppInfo from '../../common/AppInfo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { GET_COMPANIES } from '../../../graphql/company/company';
import { GET_CONTEST_OPTIONS } from '../../../graphql/contest/contests';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  title: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  chipContainer: {
    gap: 4,
    fontWeight: 600,
  },
  companyChip: {
    backgroundColor: Colors.ElectricGreen,
  },
  pointCategoryChip: {
    backgroundColor: Colors.CobaltBlue,
    color: 'white',
  },
}));

type Props = {
  companyIds?: string[];
  pointCategoryIds?: string[];
};

const LeaderboardTags: React.FC<Props> = ({ companyIds, pointCategoryIds }) => {
  const classes = useStyles();
  const theme = useTheme();

  const {
    loading: loadingCompanies,
    error: errorCompanies,
    data: { companies = [] } = {},
  } = useQuery(GET_COMPANIES, {
    variables: {
      ids: companyIds,
    },
    skip: !companyIds?.length,
  });

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: {
      contestOptions: { pointCategories: tempPointCategories = [] } = {},
    } = {},
  } = useQuery(GET_CONTEST_OPTIONS, {
    skip: !pointCategoryIds?.length,
  });

  const pointCategories = tempPointCategories.filter((pc) =>
    pointCategoryIds?.includes(pc.id),
  );

  return (
    <DataStateHandler
      loading={loadingCompanies || loadingCategories}
      error={errorCompanies || errorCategories}>
      <Grid container direction="column" spacing={3}>
        {!!companyIds?.length && (
          <Grid container item direction="column">
            <Grid container item className={classes.title}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                {t('participants')}
              </Typography>
              <AppInfo id="challenges_participants" />
            </Grid>
            <Grid container item className={classes.chipContainer}>
              {companies.map(({ id, name }) => (
                <Chip key={id} className={classes.companyChip} label={name} />
              ))}
            </Grid>
          </Grid>
        )}
        {!!pointCategoryIds?.length && (
          <Grid container item direction="column">
            <Grid container item className={classes.title}>
              <Typography variant="h3" color={theme.palette.text.primary}>
                {t('contest.pointCategories')}
              </Typography>
              <AppInfo id="challenges_point_categories" />
            </Grid>
            <Grid container item className={classes.chipContainer}>
              {pointCategories.map(({ id, label }) => (
                <Chip
                  key={id}
                  className={classes.pointCategoryChip}
                  label={label}
                />
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
    </DataStateHandler>
  );
};

export default LeaderboardTags;
