import React from 'react';
import MemberLayout from '../../../components/layouts/MemberLayout';
import BadgeList from '../../../components/member/badge/BadgeList';

const MemberBadges: React.FC = () => (
  <MemberLayout>
    <BadgeList />
  </MemberLayout>
);

export default MemberBadges;
