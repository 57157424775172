import React from 'react';
import chroma from 'chroma-js';
import makeStyles from '@mui/styles/makeStyles';
import { AvatarMetadataComponentOption } from '../../../../../types/common';

export type CartoonSvgAvatarComponentProps = {
  color?: string;
  option: AvatarMetadataComponentOption;
};

const useStyles = makeStyles(() => ({
  svg: {
    width: '100%',
    height: '100%',
    display: 'block',
    '& div': {
      width: '100%',
      height: '100%',
    },
    '& svg': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  },
}));

export const CartoonSvgAvatarComponent = React.memo(
  ({ color, option }: CartoonSvgAvatarComponentProps): JSX.Element => {
    const classes = useStyles();

    const maskId = `mask${Math.round(Math.random() * 9999999)}`;
    const pathId = `path${Math.round(Math.random() * 9999999)}`;

    /* eslint-disable no-template-curly-in-string */
    const xml = option.svg
      .replaceAll('${color}', color || '#000')
      .replaceAll(
        '${color-brighter}',
        chroma(color || '#000')
          .brighten(2)
          .hex(),
      )
      .replaceAll('${maskId}', maskId)
      .replaceAll('${pathId}', pathId);
    /* eslint-enable no-template-curly-in-string */

    return (
      <div style={option.style}>
        <img
          alt="avatar component"
          src={`data:image/svg+xml;base64,${btoa(xml)}`}
          className={classes.svg}
        />
      </div>
    );
  },
);
