import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppCardHeader, { AppCardHeaderProps } from './AppCardHeader';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  paperContainer: {
    padding: 16,
    background: `white`,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  light: {
    background: 'white',
  },
  dark: {
    background: Colors.DarkGrey,
  },
}));

type Props = {
  children: JSX.Element;
  height?: number | 'auto';
  headerProps?: AppCardHeaderProps;
  dark?: boolean;
};

const AppCard: React.FC<Props> = ({
  height = 420,
  children,
  headerProps,
  dark,
}) => {
  const classes = useStyles();

  return (
    <Paper
      data-testid="app-card"
      className={`${classes.paperContainer} ${
        dark ? classes.dark : classes.light
      }`}
      style={{ height }}>
      {headerProps ? <AppCardHeader {...headerProps} /> : null}
      {children}
    </Paper>
  );
};

export default AppCard;
