import React, { useContext } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import theme from '../../../theme/memberTheme';
import { GET_PROJECTED_EMISSIONS } from '../../../graphql/user/user';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { MTCO2E } from '../../../utils/stringConstants';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 32,
    backgroundColor: theme.palette.backgrounds.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 24,
  },
  link: {
    padding: 0,
  },
}));

const ReductionData = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user, company } = useContext(AuthorizationContext);
  const skip = !user?.id || !company.id;

  const {
    loading,
    error,
    data: { projectedEmissions } = {},
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: company.id,
    },
    skip,
  });

  return (
    <Box className={classes.root}>
      <DataStateHandler loading={loading || skip} error={error}>
        <Grid container flexDirection="column">
          <Grid
            item
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pb={3}>
            <Typography variant="h3">My Habits</Typography>
            <Button
              variant="text"
              size="small"
              className={classes.link}
              onClick={() => history.push('/member/myhabits')}>
              Update My Habits
            </Button>
          </Grid>
          <Grid item pb={3}>
            <Typography variant="subtitle1">MY HABITS SUMMARY</Typography>
          </Grid>
          <Grid
            item
            container
            flexDirection="row"
            justifyContent="space-between"
            pb={5}>
            <Grid item>
              <Typography variant="h4">Total Habits Added</Typography>
              <Typography variant="h1">
                {(user?.lifestyleHabits || []).length}
              </Typography>
            </Grid>
            <Grid item style={{ width: 200 }}>
              <Typography variant="h4">Estimated Annual Reduction</Typography>
              <Typography variant="h1" style={{ display: 'inline' }}>
                {new Intl.NumberFormat('en-us', {
                  maximumFractionDigits: 1,
                }).format((projectedEmissions?.habitReductions || 0) / 1000)}
              </Typography>
              <Typography variant="subtitle1" style={{ display: 'inline' }}>
                &nbsp;{MTCO2E}
              </Typography>
            </Grid>
          </Grid>
          <Grid item pb={5}>
            <Divider style={{ borderColor: Colors.CobaltBlue }} />
          </Grid>
          <Grid
            item
            container
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            pb={3}>
            <Typography variant="h3">Carbon Reduction Projects</Typography>
            <Button
              variant="text"
              size="small"
              className={classes.link}
              onClick={() => history.push('/member/projects')}>
              Browse Projects
            </Button>
          </Grid>
          <Grid item pb={3}>
            <Typography variant="subtitle1">MY PROJECTS SUMMARY</Typography>
          </Grid>
          <Grid
            item
            container
            flexDirection="row"
            justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">Available Project Credits</Typography>
              <Typography variant="h1" style={{ display: 'inline' }}>
                {new Intl.NumberFormat('en-us', {
                  maximumFractionDigits: 0,
                }).format((projectedEmissions?.availableCredits || 0) / 1000)}
              </Typography>
              <Typography variant="subtitle1" style={{ display: 'inline' }}>
                &nbsp;MT
              </Typography>
            </Grid>
            <Grid item style={{ width: 200 }}>
              <Typography variant="h4">Actual Annual Reduction</Typography>
              <Typography variant="h1" style={{ display: 'inline' }}>
                {new Intl.NumberFormat('en-us', {
                  maximumFractionDigits: 1,
                }).format((projectedEmissions?.offsetReductions || 0) / 1000)}
              </Typography>
              <Typography variant="subtitle1" style={{ display: 'inline' }}>
                &nbsp;{MTCO2E}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DataStateHandler>
    </Box>
  );
};

export default ReductionData;
