import React from 'react';
import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import { UserActivityType } from '../../../types/user/activityTypes';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import { UserActivityListItemProps } from './UserActivityListItem';
import UserActivityRenderer, {
  LEFT_COLUMN_WIDTH,
  renderActivityDate,
} from './UserActivityRenderer';
import SVG from '../../../assets/svg-v2';

const UserActivityUserOffsetListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    const { data: { projectCategories } = {} } = useQuery(GET_ALL_CATEGORIES);

    if (
      !projectCategories?.length ||
      userActivity.activityType !== UserActivityType.USER_OFFSET
    ) {
      return null;
    }

    return (
      <UserActivityRenderer
        leftComponent={
          <Grid
            item
            style={{
              position: 'relative',
              backgroundImage: 'linear-gradient(#5A086F, #040333)',
              width: LEFT_COLUMN_WIDTH,
              height: LEFT_COLUMN_WIDTH,
              borderRadius: LEFT_COLUMN_WIDTH / 2,
            }}>
            <SVG.Leaf
              style={{
                color: '#fff',
                marginLeft: LEFT_COLUMN_WIDTH / 3.5,
                marginTop: LEFT_COLUMN_WIDTH / 3,
              }}
            />
          </Grid>
        }
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">{userActivity.message}</Typography>
            </Grid>
            <Grid item>
              <div style={{ display: 'inline-block', marginRight: 8 }}>
                {renderActivityDate(userActivity)}
              </div>
              <Typography variant="h4" style={{ display: 'inline-block' }}>
                {userActivity.data.price.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </Typography>
            </Grid>
          </Grid>
        }
        rightComponent={
          <Typography variant="h4">{`${userActivity.data.amount} MT`}</Typography>
        }
      />
    );
  },
);

export default UserActivityUserOffsetListItem;
