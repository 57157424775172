import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useContext } from 'react';

import { CompanySubscription } from '../../../types/company/types';
import NumberUtils from '../../../utils/numberUtils';
import CompanySubscriptionModal from '../CompanySubscriptionModal';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CompanySettingsContext } from '../../../contexts/CompanySettingsContext';
import { subscriptionDateRangeDisplay } from '../../../pages/settings/SubscriptionSettings';

type Props = {
  activeSubscription?: CompanySubscription;
  creditBalance?: number;
};

const ActiveSubscriptionCard: React.FC<Props> = ({
  activeSubscription,
  creditBalance,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { userHasAccess } = useContext(AuthorizationContext);
  const { isInheritingSubscriptionData } = useContext(CompanySettingsContext);
  const canEdit =
    userHasAccess('ZeroMe.SubscriptionManagement', 'EDIT') &&
    !isInheritingSubscriptionData;

  const handleSubmit = () => {
    setModalOpen(false);
  };
  return (
    <Card style={{ margin: 8 }}>
      <CardContent>
        <Grid container direction="column">
          {activeSubscription ? (
            <>
              <Grid item mb={2}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center">
                  <Typography variant="h3">Active Subscription</Typography>
                  <Grid item>
                    {canEdit ? (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between">
                        <Button onClick={() => setModalOpen(true)} size="small">
                          Create New
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item mb={2}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between">
                  <Grid item>
                    <Grid container direction="column">
                      <Typography variant="subtitle1">Dates</Typography>
                      <Typography variant="body1">
                        {subscriptionDateRangeDisplay(activeSubscription)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Typography variant="subtitle1">ZeroMe Fee</Typography>
                      <Typography variant="body1">
                        {NumberUtils.format(
                          activeSubscription.licenseFee,
                          'currency',
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Typography variant="subtitle1">
                        Marketplace Credits
                      </Typography>
                      <Typography variant="body1">
                        {NumberUtils.format(creditBalance, 'currency')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item mr={2}>
                    <Grid container direction="column">
                      <Typography variant="subtitle1">
                        Credits Per User
                      </Typography>
                      <Typography variant="body1">
                        {activeSubscription.allotments[0]
                          ?.allotmentCreditsPerUser ?? 0}{' '}
                        MT
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="body1">Notes</Typography>
                <TextField
                  fullWidth
                  label=""
                  value={activeSubscription.notes}
                  disabled
                  multiline
                  rows={4}
                />
              </Grid>
            </>
          ) : (
            <>
              <Typography mt={2} mb={4} align="center" variant="h3">
                This company doesn&apos;t have an active subscription setup.
              </Typography>
              {canEdit ? (
                <Button
                  variant="contained"
                  onClick={() => setModalOpen(true)}
                  style={{ maxWidth: 300, margin: 'auto' }}>
                  Create New Subscription
                </Button>
              ) : null}
            </>
          )}
        </Grid>
        <CompanySubscriptionModal
          onCancel={() => setModalOpen(false)}
          onSubmit={handleSubmit}
          open={modalOpen}
        />
      </CardContent>
    </Card>
  );
};

export default ActiveSubscriptionCard;
