import React from 'react';
import { IconButton } from '@mui/material';
import SVG from '../../../assets/svg';

type Props = {
  certificateUrl: string;
};

const DownloadClientCertificate: React.FC<Props> = ({ certificateUrl }) => (
  <IconButton
    onClick={async () => {
      const link = document.createElement('a');
      link.href = certificateUrl;
      link.target = 'certificate';
      link.click();
    }}
    size="large">
    <SVG.Certificate />
  </IconButton>
);

export default DownloadClientCertificate;
