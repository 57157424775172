import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';
import AppCard from '../../../common/AppCard/AppCard';
import SVG from '../../../../assets/svg';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  button: {
    width: '100%',
  },
  text: {
    flex: 2,
  },
  linkButton: {
    textDecoration: 'none',
  },
}));

const ConfigureBudgetCard: React.FC = () => {
  const classes = useStyles();
  return (
    <AppCard height={366} headerProps={{ title: 'OFFSET BUDGET' }}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}>
        <Grid item>
          <SVG.ConfigureBudget />
        </Grid>
        <Grid item className={classes.text}>
          <Typography variant="h2" align="center">
            After your budget is set, you&apos;ll be able to track your spending
            and the amount still available
          </Typography>
        </Grid>
        <Grid item className={classes.button}>
          <Link to="/settings/platform" className={classes.linkButton}>
            <Button variant="contained" color="primary" fullWidth>
              Set Budget
            </Button>
          </Link>
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default ConfigureBudgetCard;
