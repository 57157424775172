import React from 'react';

const onboardingQuestionsDisabled = (
  level: number | undefined,
  size: number,
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3236 62.9206C3.97867 53.5756 3.97867 38.4244 13.3236 29.0794L29.0798 13.3233C38.4247 3.97833 53.5759 3.97833 62.9209 13.3233L78.677 29.0794C88.022 38.4244 88.022 53.5756 78.677 62.9206L62.9209 78.6767C53.5759 88.0217 38.4247 88.0217 29.0798 78.6767L13.3236 62.9206Z"
      fill="white"
    />
    <path
      d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
      fill="url(#paint0_angular_6519_131483)"
    />
    <mask
      id="mask0_6519_131483"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9292 61.4262C7.40734 52.9044 7.40734 39.0878 15.9292 30.5659L30.5703 15.9248C39.0921 7.40298 52.9088 7.40299 61.4306 15.9248L76.0717 30.5659C84.5936 39.0878 84.5936 52.9044 76.0717 61.4262L61.4306 76.0674C52.9088 84.5892 39.0921 84.5892 30.5703 76.0674L15.9292 61.4262Z"
        fill="url(#paint1_angular_6519_131483)"
      />
    </mask>
    <g mask="url(#mask0_6519_131483)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.4727 35.8046C67.8876 35.3898 68.5599 35.3895 68.9744 35.804L79.2978 46.1274C83.7888 50.6184 83.7858 57.9026 79.2911 62.3973C74.7964 66.892 67.5122 66.895 63.0212 62.404L23.5932 22.9759C19.3491 18.7319 12.4653 18.7347 8.21773 22.9822C3.97017 27.2298 3.96735 34.1136 8.21141 38.3577L45.8037 75.95C46.2182 76.3645 46.2179 77.0368 45.8031 77.4517C45.3882 77.8665 44.7159 77.8668 44.3014 77.4523L6.70907 39.86C1.63597 34.7869 1.63934 26.5584 6.71662 21.4811C11.7939 16.4039 20.0224 16.4005 25.0955 21.4736L64.5236 60.9017C68.1855 64.5636 74.1251 64.5611 77.79 60.8962C81.4549 57.2313 81.4574 51.2917 77.7955 47.6298L67.4721 37.3064C67.0576 36.8918 67.0579 36.2195 67.4727 35.8046Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88961 53.8896 2.88961 38.1104 12.6219 28.3781L28.3781 12.6219C38.1104 2.88961 53.8896 2.88961 63.6219 12.6219L79.3781 28.3781C89.1104 38.1104 89.1104 53.8896 79.3781 63.6219L63.6219 79.3781C53.8896 89.1104 38.1104 89.1104 28.3781 79.3781L12.6219 63.6219ZM14.0247 29.7808C5.06706 38.7384 5.06706 53.2616 14.0247 62.2192L29.7808 77.9753C38.7384 86.9329 53.2616 86.9329 62.2192 77.9753L77.9753 62.2192C86.9329 53.2616 86.9329 38.7384 77.9753 29.7808L62.2192 14.0247C53.2616 5.06707 38.7384 5.06706 29.7808 14.0247L14.0247 29.7808Z"
      fill="#6B6B6B"
    />
    <path
      d="M30.0073 27.3421C30.0073 23.3131 33.2735 20.0469 37.3025 20.0469H54.3631C58.3921 20.0469 61.6583 23.3131 61.6583 27.3421V64.6917C61.6583 68.7208 58.3921 71.9869 54.3631 71.9869H37.3025C33.2735 71.9869 30.0073 68.7208 30.0073 64.6917V27.3421Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.0154 27.3418C29.0154 22.7649 32.7256 19.0547 37.3025 19.0547H54.363C58.9399 19.0547 62.6501 22.765 62.6501 27.3418V64.6914C62.6501 69.2683 58.9399 72.9785 54.363 72.9785H37.3025C32.7256 72.9785 29.0154 69.2683 29.0154 64.6914V27.3418ZM37.3025 21.0385C33.8213 21.0385 30.9992 23.8606 30.9992 27.3418V64.6914C30.9992 68.1727 33.8213 70.9948 37.3025 70.9948H54.363C57.8443 70.9948 60.6664 68.1727 60.6664 64.6914V27.3418C60.6664 23.8606 57.8443 21.0385 54.363 21.0385H37.3025Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.0339 23.8044C42.0339 23.2566 42.478 22.8125 43.0258 22.8125H48.6052C49.153 22.8125 49.5971 23.2566 49.5971 23.8044C49.5971 24.3522 49.153 24.7963 48.6052 24.7963H43.0258C42.478 24.7963 42.0339 24.3522 42.0339 23.8044Z"
      fill="#6B6B6B"
    />
    <path
      d="M47.3653 66.4546C47.3653 67.2763 46.6992 67.9425 45.8775 67.9425C45.0558 67.9425 44.3896 67.2763 44.3896 66.4546C44.3896 65.6329 45.0558 64.9668 45.8775 64.9668C46.6992 64.9668 47.3653 65.6329 47.3653 66.4546Z"
      fill="#6B6B6B"
    />
    <path
      d="M40.8778 43.2615H38.2384C37.7765 43.2615 37.4465 43.6264 37.4465 44.0577V52.0199C37.4465 52.4844 37.7765 52.8162 38.2384 52.8162H40.8778C41.3067 52.8162 41.6696 52.4844 41.6696 52.0199V44.0577C41.6696 43.6264 41.3067 43.2615 40.8778 43.2615ZM39.5581 51.4891C39.0962 51.4891 38.7662 51.1574 38.7662 50.6929C38.7662 50.2616 39.0962 49.8967 39.5581 49.8967C39.987 49.8967 40.3499 50.2616 40.3499 50.6929C40.3499 51.1574 39.987 51.4891 39.5581 51.4891ZM50.1158 38.5505C50.1158 36.0955 48.5321 35.8301 47.7403 35.8301C47.0474 35.8301 46.7505 37.1571 46.6185 37.7543C46.4206 38.4842 46.2556 39.214 45.7607 39.7117C44.7049 40.8065 44.1441 42.1667 42.8244 43.4605C42.7584 43.5601 42.7254 43.6596 42.7254 43.7591V50.8588C42.7254 51.0578 42.8903 51.2237 43.0883 51.2569C43.6162 51.2569 44.309 51.5555 44.8369 51.7877C45.8927 52.2522 47.1794 52.8162 48.7631 52.8162H48.862C50.2807 52.8162 51.9634 52.8162 52.6232 51.8541C52.9201 51.4559 52.9861 50.9583 52.8212 50.3611C53.382 49.7971 53.646 48.7355 53.382 47.8729C53.9429 47.1099 54.0089 46.0151 53.679 45.252C54.0749 44.8539 54.3388 44.2236 54.3058 43.6264C54.3058 42.598 53.448 41.669 52.3593 41.669H48.994C49.2579 40.7401 50.1158 39.9439 50.1158 38.5505Z"
      fill="#A9A9A9"
    />
    {level ? (
      <g clipPath="url(#clip0_6519_131483)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#E6E6E6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#6B6B6B"
        />
        <text
          x="74"
          y="77.5"
          fill="#6B6B6B"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_6519_131483"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(33.1073 56.4745) rotate(-54.211) scale(69.0105 38.1545)">
        <stop offset="0.33972" stopColor="#A9A9A9" />
        <stop offset="0.773179" stopColor="#E6E6E6" />
        <stop offset="1" stopColor="#E6E6E6" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_6519_131483"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1908 45.9918) rotate(-8.79121) scale(53.9478 90.6999)">
        <stop offset="0.33972" stopColor="#2D9AF9" />
        <stop offset="0.512756" stopColor="#2EF699" />
        <stop offset="0.702892" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_6519_131483">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default onboardingQuestionsDisabled;
