import React from 'react';
import {
  ProfileCardType,
  ProfileQuestionInputBase,
} from '../../../types/question/types';
import OptionInput from './OptionInput';
import PostalCodeInput from './PostalCodeInput';
import SliderInput from './SliderInput';
import { UnitInput } from './UnitInput';
import ScheduleInput from './ScheduleInput';

const ProfileQuestionInput = (props: ProfileQuestionInputBase) => {
  const { profileCard } = props;

  if (profileCard.cardType !== ProfileCardType.Question) {
    return null;
  }

  if (profileCard.question.userInputOptions) {
    switch (profileCard.question.userInputOptions.propertyTemplate) {
      case 'scheduleDaysPerWeek':
        return <ScheduleInput {...props} />;
      case 'readingsFloat':
      case 'readingsFloatWithZero':
      case 'readingsInt':
      case 'readingsIntSkippable':
      case 'readingsIntWithZero':
        return <UnitInput {...props} />;
      case 'readingsPostalCode':
        return <PostalCodeInput {...props} />;
      case 'readingsPct':
        return <SliderInput {...props} />;
      default:
        return null;
    }
  } else if (profileCard.question.options) {
    return <OptionInput {...props} />;
  }

  return null;
};

export default ProfileQuestionInput;
