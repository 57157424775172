import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { UserActivityListItemProps } from './UserActivityListItem';
import {
  UserActivityLinkType,
  UserActivityType,
} from '../../../types/user/activityTypes';
import ActivityIcon from '../../../assets/img-v2/activity-icon.png';
import UserActivityRenderer, {
  LEFT_COLUMN_WIDTH,
  renderActivityDate,
} from './UserActivityRenderer';

const UserActivityPushNotificationListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    const history = useHistory();

    if (userActivity.activityType !== UserActivityType.PUSH_NOTIFICATION) {
      return null;
    }

    const link =
      userActivity.data.linkType === UserActivityLinkType.Communication
        ? `/member/discover/${userActivity.data.linkId}`
        : undefined;
    const onClick = link ? () => history.push(link) : undefined;

    return (
      <UserActivityRenderer
        leftComponent={
          <img
            src={ActivityIcon}
            alt="activity"
            style={{ width: LEFT_COLUMN_WIDTH }}
          />
        }
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">{userActivity.data.title}</Typography>
            </Grid>
            <Grid item>{renderActivityDate(userActivity)}</Grid>
          </Grid>
        }
        onClick={onClick}
      />
    );
  },
);

export default UserActivityPushNotificationListItem;
