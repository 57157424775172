import {
  Divider,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import { getProjectDocumentList } from '../../../utils/projectUtils';
import EditProjectStepModal from './EditProjectStepModal';

const useStyles = makeStyles(() => ({
  detailsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    borderRadius: 16,
    margin: '20px 0px',
  },
  docLink: {
    textDecoration: 'none',
    '& p': {
      fontSize: 14,
    },
  },
  divider: {
    margin: '24px 0',
  },
  listDivider: {
    margin: '0 8px',
  },
}));

const ProjectDocuments: React.FC = () => {
  const classes = useStyles();
  const { project } = useContext(ProjectContext);

  const documents = getProjectDocumentList(project);

  return (
    <>
      <Divider className={classes.divider} />
      <Grid
        item
        container
        className={classes.detailsBox}
        spacing={2}
        direction="row">
        <Grid item container direction="row" justifyContent="space-between">
          <Typography variant="h3" color="textPrimary" gutterBottom>
            Documents
          </Typography>
          <EditProjectStepModal step={ProjectDetailsStep.documents} />
        </Grid>
        {!documents.length && (
          <Grid item xs={12}>
            <Typography align="center">
              No documents have been uploaded for this project.
            </Typography>
          </Grid>
        )}
        {!!documents.length && (
          <Paper>
            <List>
              {documents.map((doc, i) => (
                <React.Fragment key={doc.url}>
                  <ListItem>
                    <ListItemText>
                      <a
                        href={doc.url}
                        className={classes.docLink}
                        target="_blank"
                        rel="noreferrer">
                        <Typography>{doc.name}</Typography>
                      </a>
                    </ListItemText>
                  </ListItem>
                  {i < documents.length - 1 && (
                    <Divider className={classes.listDivider} component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        )}
      </Grid>
    </>
  );
};

export default ProjectDocuments;
