import { useQuery } from '@apollo/client';
import { Chip, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useContext,
  useState,
} from 'react';
import { orderBy, startCase } from 'lodash';
import SVG from '../../assets/svg';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import { FilterState, GroupableTableStructure } from '../common/Table/types';
import {
  USERS_BY_COMPANY_ID_QUERY,
  USERS_BY_COMPANY_ID_PROPERTIES,
} from '../../graphql/user/user';
import { User } from '../../types/user/types';
import UserImage from '../common/User/UserImage';
import RoleChip from '../common/User/RoleChip';
import RoleLabel from '../common/User/RoleLabel';
import CompanyNameWithLogo from '../common/Company/CompanyNameWithLogo';
import CompanyName from '../common/Company/CompanyName';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { getUserStatus } from '../../utils/userUtils';
import { GET_COMPANY_TYPE } from '../../graphql/company/company';

const useStyles = makeStyles((theme) => ({
  name: {
    paddingLeft: 8,
  },
  requestsChip: {
    backgroundColor: '#FFDDE4',
    color: theme.palette.error.dark,
  },
}));

export type UserGridColumnKey =
  | 'name'
  | 'email'
  | 'postalCode'
  | 'country'
  | 'role'
  | 'status'
  | 'userTypeId'
  | 'company'
  | 'dataRequestStatus'
  | 'edit';

type Props = {
  companyId: string;
  includeChildCompanyUsers?: boolean;
  ignoreMembers?: boolean;
  columns: UserGridColumnKey[];
  showCheckboxes?: boolean;
  onRowClick?: (user: User, action?: string) => void;
  initialSelected?: string[];
  activeOnly?: boolean;
};

export type UserGridRef = {
  refetch: () => void;
  getSelectedUsers: () => User[];
};

export const DEFAULT_USER_GRID_REF = {
  refetch: () => null,
  getSelectedUsers: () => [],
};

const UserGrid = forwardRef<UserGridRef, Props>(
  (
    {
      companyId,
      includeChildCompanyUsers,
      ignoreMembers,
      columns,
      showCheckboxes,
      onRowClick,
      initialSelected,
      activeOnly,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [filter, setFilter] = useState<FilterState>(
      activeOnly ? { status: ['ACTIVE'] } : {},
    );
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
      initialSelected || [],
    );

    const { userHasAccess } = useContext(AuthorizationContext);

    const variables = {
      companyId,
      includeChildCompanyUsers,
      ignoreMembers,
      filter,
    };

    const {
      loading,
      data: {
        usersByCompanyId: { items: users = [], continuationToken = null } = {},
      } = {},
      fetchMore,
      refetch,
    } = useQuery<{
      usersByCompanyId: {
        items: User[];
        continuationToken: string;
      };
    }>(USERS_BY_COMPANY_ID_QUERY, {
      skip: !companyId,
      variables,
      fetchPolicy: 'network-only',
    });

    const { loading: companyTypeLoading, data: { companyType } = {} } =
      useQuery(GET_COMPANY_TYPE, {
        skip: !companyId,
        variables: {
          companyId,
        },
      });

    useImperativeHandle(ref, () => ({
      refetch: () => {
        refetch({
          ...variables,
          ignoreCache: true,
        });
      },
      getSelectedUsers: () =>
        users.filter((x) => selectedUserIds.indexOf(x.id) >= 0),
    }));

    const loadMoreRows = useCallback(async () => {
      fetchMore({
        variables: {
          continuationToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          return {
            usersByCompanyId: {
              ...fetchMoreResult.usersByCompanyId,
              items: [
                ...previousResult.usersByCompanyId.items,
                ...fetchMoreResult.usersByCompanyId.items,
              ],
            },
          };
        },
      });
    }, [fetchMore, continuationToken]);

    const tableStructure: GroupableTableStructure<User>[] = [
      {
        key: 'main',
        columns: [
          {
            key: 'name',
            display: 'Name',
            render: (user) => (
              <>
                <UserImage user={user} size="sm" />{' '}
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.name}>
                  {user.name}
                </Typography>
              </>
            ),
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Names',
          },
          {
            key: 'email',
            display: 'Email',
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Emails',
            render: (user) => (
              <Typography variant="body2">{user.email}</Typography>
            ),
          },
          {
            key: 'postalCode',
            display: 'Postal Code',
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Postal Codes',
            render: (user) => (
              <Typography variant="body2">{user.postalCode}</Typography>
            ),
          },
          {
            key: 'country',
            display: 'Country',
            searchable: true,
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Countries',
            render: (user) => (
              <Typography variant="body2">{user.country}</Typography>
            ),
          },
          {
            key: 'role',
            display: 'Role',
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Roles',
            resolveFilterLabel: (value) => (
              <RoleLabel roleId={value as User['roles'][0]} />
            ),
            render: (user) => <RoleChip roleId={user.roles[0]} />,
          },
          {
            key: 'status',
            display: 'Status',
            type: AlphaColumnFilter,
            resolveFilterLabel: (value) =>
              startCase((value as string).toLowerCase()),
            render: (user) => (
              <Typography variant="body2">{getUserStatus(user)}</Typography>
            ),
          },
          {
            key: 'userTypeId',
            display: 'User Type',
            type: AlphaColumnFilter,
            render: (user) => (
              <Typography variant="body2">
                {
                  companyType?.userTypes.find((x) => x.id === user.userTypeId)
                    ?.label
                }
              </Typography>
            ),
          },
          {
            key: 'dataRequestStatus',
            display: 'Requests',
            type: AlphaColumnFilter,
            resolveFilterLabel: (value) =>
              startCase((value as string).toLowerCase()),
            render: (user) =>
              user.dataRequestStatus ? (
                <Chip
                  size="small"
                  className={classes.requestsChip}
                  label={startCase(user.dataRequestStatus.toLowerCase())}
                />
              ) : null,
          },
          {
            key: 'company',
            display: 'Company/Division',
            type: AlphaColumnFilter,
            searchPlaceholder: 'Search Company/Division',
            resolveFilterLabel: (value) => (
              <CompanyName companyId={value as User['companyId']} />
            ),
            render: (user) => (
              <CompanyNameWithLogo companyId={user.companyId} />
            ),
          },
          {
            key: 'edit',
            render: (user) =>
              userHasAccess('Client.Employees', 'EDIT') ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onRowClick) {
                      onRowClick(user, 'EDIT');
                    }
                    return false;
                  }}
                  size="large">
                  <SVG.Edit />
                </IconButton>
              ) : null,
          },
        ],
      },
    ];

    if (!users.some((x) => x.userTypeId)) {
      tableStructure[0].columns = tableStructure[0].columns.filter(
        (x) => x.key !== 'userTypeId',
      );
    }

    tableStructure[0].columns = tableStructure[0].columns.filter(
      (x) => columns.indexOf(x.key as UserGridColumnKey) >= 0,
    );

    const sortedUsers = orderBy(users, 'name');

    return (
      <DataStateHandler loading={loading || companyTypeLoading}>
        <GroupableSelectTable<User>
          tableData={sortedUsers}
          tableStructure={tableStructure}
          getPropertyQuery={USERS_BY_COMPANY_ID_PROPERTIES}
          queryVariables={{
            companyId,
            includeChildCompanyUsers,
            ignoreMembers,
          }}
          initialSelected={selectedUserIds.map((s) => ({ id: s }))}
          filter={filter}
          setFilter={setFilter}
          loadMoreRows={loadMoreRows}
          hasMoreRows={!!continuationToken}
          onRowClick={onRowClick}
          onSelected={(rows) =>
            setSelectedUserIds(rows.map((x) => x.id as string))
          }
          hideStickyCol={!showCheckboxes}
        />
      </DataStateHandler>
    );
  },
);

export default UserGrid;
