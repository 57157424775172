import CalendarToday from '@mui/icons-material/CalendarToday';
import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { DashboardContext } from '../../contexts/DashboardContext';
import { Colors } from '../../theme/shared';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 8,
    fontSize: 12,
    padding: 10,
    borderColor: Colors.DisabledGrey,
    color: '#0A3751',
    fontWeight: 'normal',
  },
}));

const DateSelect: React.FC = () => {
  const classes = useStyles();
  const { company } = useContext(AuthorizationContext);
  const { endDate, setEndDate } = useContext(DashboardContext);
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      open={open}
      value={endDate}
      onChange={(date) => {
        if (date) {
          setEndDate(date.toDate());
        }
        setOpen(false);
      }}
      views={['year', 'month']}
      maxDate={moment(new Date())}
      minDate={moment(
        company?.createdDate
          ? new Date(company.createdDate)
          : new Date('01/01/2020'),
      )}
      renderInput={(dpProps) => (
        <Button
          ref={dpProps.inputRef}
          className={classes.button}
          variant="outlined"
          endIcon={<CalendarToday />}
          onClick={() => setOpen(!open)}>
          {format(endDate, 'MMMM, yyyy')}
        </Button>
      )}
    />
  );
};

export default DateSelect;
