import { Chip, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DoneIcon from '@mui/icons-material/Done';
import { useQuery } from '@apollo/client';
import React, { useRef } from 'react';
import { GET_COMPANY_STOCK_IMAGES } from '../../graphql/company/company';
import FileUpload, { FileUploadRef } from '../common/FileUpload/FileUpload';
import useTypedFormikContext from '../../hooks/useTypedFormikContext';
import { EditableCompany } from '../../contexts/CompanySettingsContext';
import DataStateHandler from '../common/DataStateHandler/DataStateHandler';
import ImageUtils from '../../utils/imageUtils';

const useStyles = makeStyles(() => ({
  additionalImage: {
    height: 100,
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
  },
  selectedChip: {
    position: 'absolute',
    top: 10,
    right: 10,
    opacity: 0.75,
  },
}));

const UploadCompanyImage: React.FC = () => {
  const { values, setTypedFieldValue } =
    useTypedFormikContext<EditableCompany>();
  const fileUploadRef = useRef<FileUploadRef>({
    setFiles: () => {
      // Default, do nothing
    },
  });

  const { loading, data: { companyStockImages = [] } = {} } = useQuery<{
    companyStockImages: string[];
  }>(GET_COMPANY_STOCK_IMAGES);

  const classes = useStyles();

  const toggleUrl = (url: string) => {
    fileUploadRef.current?.setFiles([]);

    if (url === values.image?.publicUrl) {
      setTypedFieldValue('image', undefined);
    } else {
      setTypedFieldValue('image', {
        publicUrl: url,
      });
    }
  };

  return (
    <>
      <DataStateHandler loading={loading}>
        <>
          {!!companyStockImages.length && (
            <>
              <Typography align="center" variant="h1">
                Upload or Select an Organization Image
              </Typography>
              <Typography align="center" variant="body1">
                Select a stock image or upload your own
              </Typography>
              <Grid container direction="column" alignItems="stretch">
                <Grid
                  item
                  container
                  direction="row"
                  spacing={2}
                  style={{ margin: '24px 0' }}>
                  {companyStockImages.map((url) => (
                    <Grid
                      key={url}
                      item
                      xs={3}
                      style={{ position: 'relative' }}
                      onClick={() => toggleUrl(url)}>
                      <img
                        alt="Additional"
                        className={classes.additionalImage}
                        src={url}
                        style={{ cursor: 'pointer' }}
                      />
                      {values.image?.publicUrl === url && (
                        <Chip
                          label={<DoneIcon color="primary" />}
                          className={classes.selectedChip}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
          {!companyStockImages.length && (
            <Typography align="center" variant="h1">
              No Images Available in Library
            </Typography>
          )}
        </>
      </DataStateHandler>
      <FileUpload
        showFiles
        accept={ImageUtils.SupportedFileExtensions}
        ref={fileUploadRef}
        onFileChange={(files) => {
          if (files.length) {
            setTypedFieldValue('image', {
              file: files[0].file,
            });
          }
        }}
      />
    </>
  );
};

export default UploadCompanyImage;
