import { TypedDocumentNode, gql } from '@apollo/client';
import { UserActivity } from '../../types/user/activityTypes';

export const GET_USER_ACTIVITIES = gql`
  query GET_USER_ACTIVITIES(
    $unreadDate: String
    $continuationToken: String
    $pageSize: Int
    $activityTypes: [UserActivityItemType]
  ) {
    me {
      id
      activities(
        unreadDate: $unreadDate
        continuationToken: $continuationToken
        pageSize: $pageSize
        activityTypes: $activityTypes
      ) {
        items {
          id
          date
          activityType
          message
          data
          readAt
        }
        continuationToken
      }
    }
  }
` as TypedDocumentNode<{
  me: {
    activities: {
      items: UserActivity[];
      continuationToken: string;
    };
  };
}>;

export const GET_UNREAD_USER_ACTIVITY_IDS = gql`
  query GET_UNREAD_USER_ACTIVITY_IDS {
    me {
      id
      activities(unreadOnly: true) {
        items {
          id
        }
      }
    }
  }
` as TypedDocumentNode<{
  me: {
    activities: {
      items: {
        id: string;
      }[];
    };
  };
}>;
