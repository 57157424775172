import _ from 'lodash';

type ParamType = number | undefined | null;

export const roundMetric = (value: ParamType): number | undefined =>
  value != null ? _.round(value, 1) : undefined;

/**
 * @description receives a metric ton value and returns a kilogram value
 */
export const MTCO2ETOKGCO2E = (
  metricTons: ParamType,
  round = true,
): number | undefined => {
  if (metricTons == null) {
    return undefined;
  }

  const kilograms = metricTons / 0.001;

  return round ? roundMetric(kilograms) : kilograms;
};

/**
 * @description receives a kilogram value and returns a metric ton value
 */
export const KGCO2ETOMTCO2E = (
  kilograms: ParamType,
  round = true,
): number | undefined => {
  if (kilograms == null) {
    return undefined;
  }

  const metricTons = kilograms * 0.001;

  return round ? roundMetric(metricTons) : metricTons;
};
