import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Chip, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';
import moment from 'moment';

import LeaderboardTable from './LeaderboardTable';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { MemberToastContext } from '../../../contexts/MemberToastContext';
import {
  getContestsQuery,
  getLeaderboardsQuery,
  OPT_OUT_OF_CONTEST,
} from '../../../graphql/contest/contests';
import { Colors } from '../../../theme/shared';
import {
  Contest,
  ContestType,
  LeaderboardRollup,
} from '../../../types/contest/types';
import NumberUtils from '../../../utils/numberUtils';
import getUserContestPoints from '../../../utils/contestUtils';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    marginTop: 'auto',
    alignItems: 'center',
  },
  chip: {
    borderRadius: 28,
    padding: 4,
    marginRight: 4,
  },
  unselectedChip: {
    backgroundColor: theme.palette.primary.light,
  },
}));

type Props = {
  contest?: Contest;
};

const Leaderboards: React.FC<Props> = ({ contest }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user: currentUser } = useContext(AuthorizationContext);
  const { showToast } = useContext(MemberToastContext);
  const {
    id: contestId,
    companyIds = [],
    contestType,
    userPoints,
    pointCategories,
    optedOutUsers,
    duration: { endDate = '' } = {},
  } = contest || {};
  const showDivision =
    companyIds.length > 1 && contestType === ContestType.AUTOMATIC_QUARTERLY;
  const [rollup, setRollup] = useState<string>(
    showDivision ? LeaderboardRollup.COMPANY : LeaderboardRollup.PARENT,
  );
  const [optOutOfContest] = useMutation(OPT_OUT_OF_CONTEST, {
    variables: { contestId, optOut: true },
    refetchQueries: [getContestsQuery(true)],
  });
  const optedOut = optedOutUsers?.includes(currentUser?.id || '');

  const {
    loading,
    error,
    data: { leaderboards: [leaderboard] = [undefined] } = {},
  } = useQuery(getLeaderboardsQuery(!optedOut, !optedOut), {
    variables: {
      contestId,
      rollup,
    },
    skip: !contestId,
  });

  const currentUserRanking = leaderboard?.contestRanking?.find(
    (cr) => cr.userId === currentUser?.id,
  );
  const currentUserPoints = getUserContestPoints(userPoints, pointCategories);
  const isContestClosed = moment().isAfter(moment(endDate).endOf('day'));

  const getHeaderMessage = () => {
    if (optedOut) {
      return contestType === ContestType.AUTOMATIC_QUARTERLY
        ? t('contest.optOut.leaderboard.youHaveOptedOut')
        : t('contest.optOut.challenge.youHaveOptedOut');
    }

    if (!currentUserRanking) {
      return t('contest.placementWillBeCalculated');
    }

    return t('contest.placement', {
      place: NumberUtils.formatOrdinal(currentUserRanking.rank),
      rollup:
        rollup === LeaderboardRollup.COMPANY ? t('division') : t('company'),
    });
  };

  const handleOptOut = async () => {
    showToast({
      messages: [
        {
          title:
            contestType === ContestType.AUTOMATIC_QUARTERLY
              ? t('contest.optOut.leaderboard.toastTitle')
              : t('contest.optOut.challenge.toastTitle'),
          description: t('contest.optOut.toastDescription'),
        },
      ],
    });
    await optOutOfContest();
  };

  const handleCurrentUserClick = () =>
    history.push(
      contestType === ContestType.AUTOMATIC_QUARTERLY
        ? `/member/leaderboard/detail/${leaderboard?.contestId}/${leaderboard?.rollup}`
        : `/member/challenge/detail/${leaderboard?.contestId}`,
    );

  return (
    <DataStateHandler loading={loading} error={error}>
      {leaderboard ? (
        <Grid container className={classes.container} spacing={2}>
          <Typography variant="h3" color={Colors.CobaltBlue}>
            {getHeaderMessage()}
          </Typography>
          <Typography variant="subtitle2" color={Colors.TertiaryText}>
            {isContestClosed
              ? t('contest.finalResults')
              : t('contest.lastUpdated', {
                  datetime: moment(leaderboard?.timestamp).format(
                    'M/D/YYYY h:mm',
                  ),
                })}
          </Typography>
          <Grid container item>
            {showDivision && (
              <Chip
                className={`${classes.chip} ${
                  rollup === LeaderboardRollup.PARENT
                    ? classes.unselectedChip
                    : ''
                }`}
                label={t('myDivision')}
                color={
                  rollup === LeaderboardRollup.COMPANY ? 'primary' : 'default'
                }
                onClick={() => setRollup(LeaderboardRollup.COMPANY)}
              />
            )}
            <Chip
              className={`${classes.chip} ${
                rollup === LeaderboardRollup.COMPANY
                  ? classes.unselectedChip
                  : ''
              }`}
              label={t('myCompany')}
              color={
                rollup === LeaderboardRollup.PARENT ? 'primary' : 'default'
              }
              onClick={() => setRollup(LeaderboardRollup.PARENT)}
            />
          </Grid>
          <LeaderboardTable
            leaderboard={leaderboard}
            currentUserPoints={currentUserPoints}
            onCurrentUserClick={handleCurrentUserClick}
          />
          {!optedOut && !isContestClosed && (
            <Button onClick={handleOptOut}>
              <Typography variant="subtitle2" color={Colors.TertiaryText}>
                {contestType === ContestType.AUTOMATIC_QUARTERLY
                  ? t('contest.optOut.leaderboard.button')
                  : t('contest.optOut.challenge.button')}
              </Typography>
            </Button>
          )}
        </Grid>
      ) : (
        <Grid>
          <Typography variant="h2" align="center">
            {t('contest.calculatingResults')}
          </Typography>
          <Typography variant="body1" align="center">
            {t('contest.calculatingResultsDescription')}
          </Typography>
        </Grid>
      )}
    </DataStateHandler>
  );
};

export default Leaderboards;
