import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { ProfileQuestionInputBase } from '../../../types/question/types';
import {
  useAnswerQuestion,
  useQuestionnaire,
} from '../../../contexts/QuestionnaireContext';
import { createQuestionAnswerFromQuestionAndEmissionSources } from '../../../utils/questionnaire';
import QuestionnaireTextField from './QuestionnaireTextField';

function PostalCodeInput({
  profileCard,
  forceDisabled,
  inputOnly,
}: ProfileQuestionInputBase): JSX.Element {
  const state = useQuestionnaire();
  const answerQuestion = useAnswerQuestion();

  const generateQuestionAnswer = useCallback(() => {
    const response = createQuestionAnswerFromQuestionAndEmissionSources(
      state.userEmissionSources,
      profileCard.profileId,
      profileCard.question,
    );

    return {
      ...response,
      readings: response.readings?.map((map) => ({
        ...map,
        calcInputUnit: map.calcInputUnit,
      })),
    };
  }, [profileCard.profileId, profileCard.question, state.userEmissionSources]);

  const [internalValue, setInternalValue] = useState(generateQuestionAnswer());

  useEffect(() => {
    setInternalValue(generateQuestionAnswer());
  }, [generateQuestionAnswer]);

  const onTextChange = (value: string) => {
    if (profileCard.question?.userInputOptions) {
      setInternalValue({
        ...internalValue,
        readings: [
          {
            key: profileCard.question.userInputOptions.propertyTemplateKey,
            calcInputUnit: value,
          },
        ],
      });
    }
  };

  const onComplete = () => {
    // This is a little hacky but the server requires this shape, the 0 isn't used and the actual value
    // is sent back via the calcInputUnit property
    const completeQuestionAnswer = {
      ...internalValue,
      readings: internalValue.readings?.map((map) => ({
        ...map,
        calcInput: 0,
        calcInputUnit: map.calcInputUnit,
      })),
    };

    answerQuestion(profileCard, completeQuestionAnswer);
  };

  let textValue: string | undefined;

  if (internalValue.readings?.length) {
    textValue = internalValue.readings[0].calcInputUnit;
  }

  const readingValue = textValue || '';

  const buttonDisabled =
    Number.isNaN(parseFloat(readingValue)) ||
    readingValue.length !== 5 ||
    forceDisabled;

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item>
        <QuestionnaireTextField
          value={readingValue}
          onChange={(e) => onTextChange(e.target.value)}
          disabled={forceDisabled}
        />
      </Grid>
      {!inputOnly && (
        <Grid item style={{ marginTop: 16 }}>
          <Button
            variant="contained"
            onClick={onComplete}
            disabled={buttonDisabled}
            fullWidth
            style={{ padding: 24 }}>
            Next
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default PostalCodeInput;
