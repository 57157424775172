import { Grid, Typography } from '@mui/material';
import React from 'react';
import { orderBy } from 'lodash';
import { useQuery } from '@apollo/client';
import {
  HabitCategory,
  HabitWithUsageData,
} from '../../../../types/habit/types';
import NumberUtils from '../../../../utils/numberUtils';
import HabitCategoryIcon from '../../../common/Habit/HabitCategoryIcon';
import AlphaColumnFilter from '../../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../../common/Table/types';
import RoundedFlexBox from '../../../trader/RoundedFlexBox';
import NoDataAvailable from '../../../common/NoDataAvailable';
import { HABIT_CATEGORIES } from '../../../../graphql/habit/habits';

type Props = {
  habits: HabitWithUsageData[];
};

const habitCategoryFilterLabel =
  (habitCategories: HabitCategory[]) => (value: unknown) =>
    (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={1}
        style={{ position: 'relative', top: 3 }}>
        <Grid item>
          <HabitCategoryIcon
            category={
              habitCategories.find((cat) => cat.id === value) as HabitCategory
            }
          />
        </Grid>
        <Grid item>
          {habitCategories.find((cat) => cat.id === value)?.label || ''}
        </Grid>
      </Grid>
    );

const habitCategorySearchFunction =
  (habitCategories: HabitCategory[]) =>
  (collection: string[] | string[][], filter: string) => {
    // If collection is [][] and the first element is a null,
    // checking Array.isArray(collection[0]) is returning false
    // and then the code incorrectly assumes that it is a
    // 1-dimensional array. Flatten and filter any nulls prior
    // to the isArray() check. This flatten works for both [] and [][].
    const allIds = collection
      .flatMap((x) => x)
      .filter((f) => f !== null && f !== undefined);

    // const allIds = [
    //   ...new Set(
    //     Array.isArray(collection[0])
    //       ? collection.flatMap((x) => x)
    //       : (collection as string[]),
    //   ),
    // ];

    const mappedCategories = habitCategories
      .filter(
        (p) =>
          allIds.includes(p.id) &&
          p.label.toLowerCase().includes(filter.toLowerCase()),
      )
      .map((p) => p.id);

    return mappedCategories;
  };

const CompanyHabitsList: React.FC<Props> = ({ habits }) => {
  const { data: { habitCategories = [] } = {} } = useQuery(HABIT_CATEGORIES);

  const tableStructure: GroupableTableStructure<HabitWithUsageData>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'habit.category.id',
          display: 'Cat.',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          searchFunction: habitCategorySearchFunction(habitCategories),
          resolveFilterLabel: habitCategoryFilterLabel(habitCategories),
          render: ({ habit }) =>
            habit.category ? (
              <HabitCategoryIcon category={habit.category} />
            ) : null,
        },
        {
          key: 'habit.name',
          display: 'Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Title',
          render: ({ habit }) => (
            <Typography
              variant="body2"
              noWrap
              style={{
                width: '25ch',
                display: 'inline-block',
                fontWeight: 600,
              }}>
              {habit.name}
            </Typography>
          ),
        },
        {
          key: 'totalParticipants',
          display: 'Total Participants',
          resolveFilterLabel: (value) =>
            NumberUtils.format(value as number, 'integer'),
          render: (c) => (
            <Typography variant="body2">
              {NumberUtils.format(c.totalParticipants, 'integer')}
            </Typography>
          ),
        },
      ],
    },
  ];

  const hasData = habits?.some((h) => h.totalParticipants > 0);
  return (
    <div style={{ height: 500 }}>
      <RoundedFlexBox>
        {hasData ? (
          <GroupableSelectTable<HabitWithUsageData>
            tableData={orderBy(
              habits.map((h) => ({ ...h, id: h.habit.id })),
              ['totalParticipants'],
              ['desc'],
            )}
            tableStructure={tableStructure}
            hideStickyCol
          />
        ) : (
          <NoDataAvailable />
        )}
      </RoundedFlexBox>
    </div>
  );
};

export default CompanyHabitsList;
