import React from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import AppCard from '../../../common/AppCard/AppCard';
import CardFooter from '../../../common/CardFooter/CardFooter';
import NumberUtils from '../../../../utils/numberUtils';
import WaterFootprintDropChart from '../../../common/WaterFootprintDropChart';
import ChartLabel from '../../../common/ChartLabel/ChartLabel';
import { WaterFootprintItem } from '../../../../types/dashboard/types';

const useStyles = makeStyles(() => ({
  row: {
    marginTop: 16,
  },
  label: {
    marginBottom: 20,
  },
}));

type Props = {
  waterFootprint: WaterFootprintItem[];
};

const CompanyWaterFootprint: React.FC<Props> = ({ waterFootprint }) => {
  const total = waterFootprint.reduce((acc, cur) => acc + cur.value, 0);
  const classes = useStyles();

  return (
    <AppCard
      height={460}
      headerProps={{
        title: 'COMPANY WATER FOOTPRINT',
        tooltipId: 'impactCompanyWaterFootprint',
      }}>
      <Grid container>
        <Grid
          item
          container
          className={classes.row}
          xs={6}
          justifyContent="center">
          <WaterFootprintDropChart items={waterFootprint} />
        </Grid>
        <Grid item className={classes.row} xs={6}>
          {waterFootprint.map((item) => (
            <ChartLabel
              key={item.id}
              label={item.id}
              color={item.color}
              size="medium"
              rootClass={classes.label}
              value={`${Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 0,
              }).format(item.value)} Liters`}
            />
          ))}
        </Grid>
        <Grid container item xs={12} justifyContent="flex-end">
          <CardFooter
            label="Liters"
            data={NumberUtils.format(total, 'integer')}
          />
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default CompanyWaterFootprint;
