import { useQuery } from '@apollo/client';
import { DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import DeleteProjectModal from './DeleteProjectModal';
import { GET_MARKETPLACE_PURCHASEHIST } from '../../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';

type Props = {
  projectId: string;
  onClose: () => void;
  refetch: () => void;
};

const TraderProjectDetailsHeader: React.FC<Props> = ({
  projectId,
  onClose,
  refetch,
}) => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const [isDeleting, setIsDeleting] = useState(false);

  const canDelete = userHasAccess('ZeroMe.Marketplace', 'EDIT');

  const { loading, data: { offsetProjects = [] } = {} } = useQuery<{
    offsetProjects: ProjectPurchaseHistory[];
  }>(GET_MARKETPLACE_PURCHASEHIST(), {
    variables: {
      filter: {
        status: ['COMPLETE', 'REQUESTED'],
        projectId: [projectId],
      },
    },
    skip: !canDelete,
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item>
            <Typography variant="h1">
              <IconButton aria-label="close" onClick={onClose} size="large">
                <SVG.Back />
              </IconButton>{' '}
              {userHasAccess('Client.Marketplace', 'EDIT') ? 'Edit' : 'View'}{' '}
              Project
              {canDelete && !loading && offsetProjects.length === 0 ? (
                <SmallPaddedButton
                  onClick={() => setIsDeleting(true)}
                  style={{ position: 'absolute', right: 16 }}
                  variant="outlined"
                  color="error">
                  Delete this Project
                </SmallPaddedButton>
              ) : null}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      {isDeleting ? (
        <DeleteProjectModal
          refetch={refetch}
          projectId={projectId}
          onCancel={() => setIsDeleting(false)}
          onConfirm={() => {
            setIsDeleting(false);
            onClose();
          }}
        />
      ) : null}
    </>
  );
};

export default TraderProjectDetailsHeader;
