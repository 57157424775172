import {
  Grid,
  Typography,
  IconButton,
  SvgIcon,
  Box,
  Divider,
  Paper,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import omitDeep from 'omit-deep-lodash';

import SVG from '../../assets/svg';
import DataStateHandler, {
  DEFAULT_CODE_MESSAGES,
} from '../common/DataStateHandler/DataStateHandler';
import AppTextField from '../common/AppTextField';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import ImageUpload from '../common/ImageUpload';
import { Habit } from '../../types/habit/types';
import {
  ALL_HABITS,
  HABIT_CATEGORIES,
  HABIT_DETAILS,
  UPSERT_HABIT,
  ALL_QUESTIONS,
  VALIDATE_HABIT,
} from '../../graphql/habit/habits';
import HabitCondition from './HabitCondtition';
import HabitAction from './HabitAction';
import HabitImpactValue from './HabitImpactValue';
import HabitNestedContainer from './HabitNestedContainer';
import LoadingBackdrop from '../common/LoadingBackdrop';
import ConfirmCancelModal from '../common/ConfirmCancelModal/ConfirmCancelModal';
import AppMultiSelectCheckbox from '../common/AppMultiSelectCheckbox';
import AppDatePicker from '../common/AppDatePicker';

type Props = {
  id: string;
  onClose: (cancelled: boolean) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 48px',
  },
  form: {
    marginTop: 16,
  },
  fieldArraySection: {
    paddingTop: 16,
  },
  paper: {
    padding: 24,
    width: '100%',
    marginBottom: 24,
  },
}));

// most validation happens on the server, only verify minumum data
// necessary here
const schema = yup.object().shape({
  name: yup.string().required(),
});

const HabitDetails: React.FC<Props> = ({ id, onClose }) => {
  const classes = useStyles();
  const [upsertError, setUpsertError] =
    useState<{ message: string; isWarning: boolean }>();
  const { userHasAccess } = useContext(AuthorizationContext);
  const {
    data: { habitCategories = [], scoreFactors = [] } = {},
    loading: categoriesLoading,
    error: categoriesError,
  } = useQuery(HABIT_CATEGORIES);

  const {
    data: { questions = [] } = {},
    loading: questionsLoading,
    error: questionsError,
  } = useQuery(ALL_QUESTIONS);

  const {
    data: { habits = [] } = {},
    loading: habitsLoading,
    error: habitsError,
  } = useQuery(ALL_HABITS);

  const {
    data: { habit } = {},
    loading,
    error,
  } = useQuery(HABIT_DETAILS, {
    skip: id === 'NEW',
    variables: {
      id,
    },
  });
  const [upsert, { loading: upsertLoading }] = useMutation(UPSERT_HABIT);
  const [validate, { loading: validateLoading }] = useMutation(VALIDATE_HABIT);

  const initialValues: Omit<Habit, 'id' | 'category'> & {
    image?: File;
    categoryId: string;
  } = {
    isDraft: true,
    name: '',
    imageUrl: '',
    image: undefined,
    questionText: '',
    impact: '',
    description: '',
    categoryId: '',
    recurrenceSchedule: 'DAILY',
    pointAmount: 0,
    streakAmounts: [],
    tips: [],
    conversionNagCount: 0,
    conversionType: 'LIFESTYLE',
    conversionConfirmationQuestion: '',
    conversionSuccessMessage: '',
    scoreFactorIds: [],
    ...habit,
  };

  const canEdit = userHasAccess('ZeroMe.Habits', 'EDIT');

  // currently can only save in dev
  const canSave = process.env.REACT_APP_ALLOW_EDIT_MANAGED_SETTINGS === 'true';

  return (
    <DataStateHandler
      loading={
        loading || categoriesLoading || habitsLoading || questionsLoading
      }
      error={error || categoriesError || questionsError || habitsError}>
      <>
        <LoadingBackdrop open={upsertLoading || validateLoading} />
        <Formik
          enableReinitialize
          initialValues={{
            ...initialValues,
            isDraft: !!initialValues.isDraft,
          }}
          validationSchema={schema}
          onSubmit={async (values) => {
            const {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              imageUrl,
              condition,
              impactValue,
              addHabitActions,
              addLifestyleHabitActions,
              conversionActions,
              ...rest
            } = values;

            const input = {
              ...rest,
              condition: condition
                ? JSON.stringify(omitDeep(condition, '__typename'))
                : undefined,
              impactValue: impactValue
                ? JSON.stringify(omitDeep(impactValue, '__typename'))
                : undefined,
              addHabitActions: addHabitActions
                ? JSON.stringify(omitDeep(addHabitActions, '__typename'))
                : undefined,
              addLifestyleHabitActions: addLifestyleHabitActions
                ? JSON.stringify(
                    omitDeep(addLifestyleHabitActions, '__typename'),
                  )
                : undefined,
              conversionActions: conversionActions
                ? JSON.stringify(omitDeep(conversionActions, '__typename'))
                : undefined,
              conversionReanswerQuestionId:
                values.conversionReanswerQuestionId || undefined,
              conversionReanswerQuestionTreeCategory:
                values.conversionReanswerQuestionTreeCategory || undefined,
              confirmPointAmount: values.confirmPointAmount || undefined,
              answeredNoPointAmount: values.answeredNoPointAmount || undefined,
            };

            const cleanHabit = omitDeep(input, '__typename');

            upsert({
              variables: {
                habit: cleanHabit,
              },
              onCompleted: () => {
                if (input.isDraft) {
                  validate({
                    variables: {
                      habit: cleanHabit,
                    },
                    onCompleted: () => {
                      onClose(false);
                    },
                    onError: (e) =>
                      setUpsertError({
                        message:
                          DEFAULT_CODE_MESSAGES[
                            e.graphQLErrors[0]?.extensions?.code
                          ] ||
                          e.graphQLErrors[0]?.extensions?.code ||
                          'General Error',
                        isWarning: true,
                      }),
                  });
                } else {
                  onClose(false);
                }
              },
              onError: (e) =>
                setUpsertError({
                  message:
                    DEFAULT_CODE_MESSAGES[
                      e.graphQLErrors[0]?.extensions?.code
                    ] ||
                    e.graphQLErrors[0]?.extensions?.code ||
                    'General Error',
                  isWarning: false,
                }),
              awaitRefetchQueries: true,
              refetchQueries: [ALL_HABITS],
            });
          }}
          validateOnMount>
          {(props) => (
            <Grid
              container
              direction="column"
              className={classes.root}
              spacing={2}>
              <Grid item>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item>
                    <Grid
                      justifyContent="space-around"
                      container
                      alignItems="center">
                      <Box position="relative" right={24}>
                        <IconButton
                          aria-label="close"
                          onClick={() => onClose(true)}
                          size="large">
                          <SvgIcon component={SVG.Back} />
                        </IconButton>
                      </Box>

                      <Typography variant="h1">
                        {id === 'new' ? 'Create Habit' : 'Edit Habit'}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {canEdit && (
                      <Grid container spacing={1} justifyContent="space-around">
                        <Grid item>
                          <SmallPaddedButton
                            onClick={() => onClose(true)}
                            variant="outlined"
                            color="primary">
                            Cancel
                          </SmallPaddedButton>
                        </Grid>
                        <Grid item>
                          <SmallPaddedButton
                            disabled={
                              props.isSubmitting || !props.isValid || !canSave
                            }
                            variant="contained"
                            color="primary"
                            onClick={props.submitForm}>
                            Save
                          </SmallPaddedButton>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.form}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="error">
                        Heads Up! This feature is meant to be used in dev only.
                        Save is disabled in upper environments.
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center">
                      <Grid item>
                        <Typography variant="body2">
                          Is Draft/Test Habit
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={props.values.isDraft || false}
                          disabled={!canEdit}
                          onChange={(e, checked) =>
                            props.setFieldValue('isDraft', checked)
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="h2">General Details</Typography>
                      </Grid>
                      <Divider />
                    </Grid>
                    {!!habit?.id && (
                      <Grid item xs={12}>
                        <Typography variant="body2">ID</Typography>
                        <Typography variant="body1">{habit.id}</Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="body2">Name</Typography>
                      <Field
                        name="name"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Category</Typography>
                      <Field
                        name="categoryId"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        fullWidth
                        select
                        options={habitCategories.map((h) => ({
                          label: h.label,
                          value: h.id,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Publish Date</Typography>
                      <Field
                        name="publishDate"
                        label=""
                        component={AppDatePicker}
                        disabled={!canEdit}
                        placeholder="Publish Date"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Score Factors</Typography>
                      <Field
                        name="scoreFactorIds"
                        label=""
                        data={scoreFactors.map((s) => ({
                          label: s.label,
                          value: s.id,
                        }))}
                        displayEmpty
                        component={AppMultiSelectCheckbox}
                        disabled={!canEdit}
                        renderValue={(selected: string[]) =>
                          selected && selected.length ? (
                            <>
                              {selected.map((s) => {
                                const factor = scoreFactors.find(
                                  (c) => c.id === s,
                                );
                                return (
                                  <div key={factor?.id}>{factor?.label}</div>
                                );
                              })}
                            </>
                          ) : (
                            'Select a Category'
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ImageUpload
                        disabled={!canEdit}
                        value={props.values.imageUrl || ''}
                        onChange={(file) => props.setFieldValue('image', file)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Description</Typography>
                      <Field
                        name="description"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Description"
                        fullWidth
                        multiline
                        rows={5}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="tips"
                        render={(arrayHelpers) => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between">
                              <Grid item xs={12}>
                                <Typography variant="body2">Tips</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <SmallPaddedButton
                                  fullWidth
                                  onClick={() =>
                                    arrayHelpers.push({
                                      text: '',
                                    })
                                  }>
                                  + Add a Tip
                                </SmallPaddedButton>
                              </Grid>
                            </Grid>
                            {props.values.tips?.map((_streak, index) => (
                              <Grid
                                container
                                spacing={2}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                alignItems="center"
                                className={classes.fieldArraySection}>
                                <Grid item xs={11}>
                                  <Field
                                    name={`tips[${index}].text`}
                                    label=""
                                    component={AppTextField}
                                    disabled={!canEdit}
                                    placeholder="Tip Text"
                                    fullWidth
                                    multiline
                                    rows={3}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Grid container justifyContent="flex-end">
                                    <IconButton
                                      onClick={() => arrayHelpers.remove(index)}
                                      size="large">
                                      <SVG.Delete />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Question Text</Typography>
                      <Field
                        name="questionText"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Question Text"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">Point Amount</Typography>
                      <Grid container xs={12} spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant="body2">Initial</Typography>
                          <Field
                            name="pointAmount"
                            label=""
                            component={AppTextField}
                            disabled={!canEdit}
                            placeholder="Initial"
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">Confirmation</Typography>
                          <Field
                            name="confirmPointAmount"
                            label=""
                            component={AppTextField}
                            disabled={!canEdit}
                            placeholder="Confirmation"
                            type="number"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Recurrence Schedule
                      </Typography>
                      <Field
                        name="recurrenceSchedule"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Recurrence Schedule"
                        select
                        options={[
                          {
                            label: 'Daily',
                            value: 'DAILY',
                          },
                          {
                            label: 'Weekly',
                            value: 'WEEKLY',
                          },
                          {
                            label: 'Monthly',
                            value: 'MONTHLY',
                          },
                        ]}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FieldArray
                        name="streakAmounts"
                        render={(arrayHelpers) => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between">
                              <Grid item xs={12}>
                                <Typography variant="body2">Streaks</Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <SmallPaddedButton
                                  fullWidth
                                  onClick={() =>
                                    arrayHelpers.push({
                                      pointAmount: 0,
                                      streakAmount: 0,
                                    })
                                  }>
                                  + Add a Streak
                                </SmallPaddedButton>
                              </Grid>
                            </Grid>
                            {props.values.streakAmounts?.map(
                              (_streak, index) => (
                                <Grid
                                  container
                                  spacing={2}
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={index}
                                  alignItems="center"
                                  className={classes.fieldArraySection}>
                                  <Grid item xs={4}>
                                    <Typography variant="body2">
                                      Point Amount
                                    </Typography>
                                    <Field
                                      name={`streakAmounts[${index}].pointAmount`}
                                      label=""
                                      component={AppTextField}
                                      disabled={!canEdit}
                                      placeholder="Point Amount"
                                      fullWidth
                                      type="number"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography variant="body2">
                                      Streak Amount in Days
                                    </Typography>
                                    <Field
                                      name={`streakAmounts[${index}].streakAmount`}
                                      label=""
                                      component={AppTextField}
                                      disabled={!canEdit}
                                      placeholder="Streak Amount (Days)"
                                      fullWidth
                                      type="number"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Grid container justifyContent="flex-end">
                                      <IconButton
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        size="large">
                                        <SVG.Delete />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ),
                            )}
                          </>
                        )}
                      />
                                      </Grid> */}
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="h2">Impact</Typography>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Impact Text</Typography>
                      <Field
                        name="impact"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Impact"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <HabitImpactValue
                        canEdit={canEdit}
                        questions={questions}
                        habits={habits}
                        path="impactValue"
                        showMultipleValuesOption
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Banner Type</Typography>
                      <Field
                        name="bannerType"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        fullWidth
                        select
                        options={[
                          {
                            label: 'Fully Grown Trees',
                            value: 'FULLY_GROWN_TREES',
                          },
                          {
                            label: 'Liters Saved',
                            value: 'LITERS_SAVED',
                          },
                          {
                            label: 'Single-Use Materials',
                            value: 'SINGLE_USE_MATERIAL',
                          },
                          {
                            label: 'Emissions',
                            value: 'EMISSIONS',
                          },
                          {
                            label: 'Earth Friendly',
                            value: 'EARTH_FRIENDLY',
                          },
                        ]}
                      />
                    </Grid>
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body2">
                          Banner Description
                        </Typography>
                        <Field
                          name="bannerDescription"
                          label=""
                          component={AppTextField}
                          disabled={!canEdit}
                          placeholder="Banner Description"
                          fullWidth
                          multiline
                          rows={2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2">Banner Value</Typography>
                        <Field
                          name="bannerValue"
                          label=""
                          component={AppTextField}
                          disabled={!canEdit}
                          placeholder="Banner Value"
                          fullWidth
                          type="number"
                        />
                      </Grid>
                    </>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Times habit is tracked before it becomes a Lifestyle
                      </Typography>
                      <Field
                        name="conversionNagCount"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder=""
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">Conversion Type</Typography>
                      <Field
                        name="conversionType"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder=""
                        fullWidth
                        select
                        options={[
                          {
                            label: 'Conversion Actions',
                            value: 'CONVERSION_ACTIONS',
                          },
                          {
                            label: 'Lifestyle',
                            value: 'LIFESTYLE',
                          },
                          {
                            label: 'Re-Answer Questions',
                            value: 'REANSWER_QUESTIONS',
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Conversion Confirmation Question
                      </Typography>
                      <Field
                        name="conversionConfirmationQuestion"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Conversion Confirmation Question"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Conversion Success Message
                      </Typography>
                      <Field
                        name="conversionSuccessMessage"
                        label=""
                        component={AppTextField}
                        disabled={!canEdit}
                        placeholder="Conversion Success Message"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                    {props.values.conversionType === 'REANSWER_QUESTIONS' && (
                      <>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            Question Tree to redirect to for reanswering
                            questions
                          </Typography>
                          <Field
                            label=""
                            component={AppTextField}
                            fullWidth
                            select
                            name="conversionReanswerQuestionTreeCategory"
                            options={[
                              ...new Set(
                                questions.map((x) => x.questionTreeCategory),
                              ),
                            ].map((q) => ({
                              label: q,
                              value: q,
                            }))}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2">
                            Specific Question to redirect to (optional)
                          </Typography>
                          <Field
                            label=""
                            component={AppTextField}
                            fullWidth
                            select
                            name="conversionReanswerQuestionId"
                            options={questions
                              .filter(
                                (x) =>
                                  x.questionTreeCategory ===
                                  props.values
                                    .conversionReanswerQuestionTreeCategory,
                              )
                              .flatMap((x) => x.questions)
                              .map((q) => ({
                                label: q.questionText,
                                value: q.questionId,
                              }))}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Paper>

                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="h2">Condition</Typography>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Certain Habits are only shown conditionally, based on
                        answers to questions. For example, a user who already
                        bikes to work as part of their commute wouldn&apos;t
                        need to see a habit encouraging them to ride their bike
                        to work.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <HabitCondition
                        questions={questions}
                        habits={habits}
                        path="condition"
                        canEdit={canEdit}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        <Typography variant="h2">Actions</Typography>
                      </Grid>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        Actions are performed when certain conditions are met,
                        such as a habit is added, a lifestyle habit is added, or
                        a habit is converted to a lifestyle habit. For example,
                        if a user converts a &quot;bike to work&quot; habit to a
                        lifestyle, we will want to change their commute to work
                        question response as an action.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="addHabitActions"
                        render={(arrayHelpers) => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between">
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  Add Habit Actions
                                </Typography>
                              </Grid>
                            </Grid>
                            {props.values.addHabitActions?.map(
                              (_streak, index) => (
                                <Grid
                                  item
                                  container
                                  xs={12} // eslint-disable-next-line react/no-array-index-key
                                  key={index}>
                                  <HabitNestedContainer
                                    title={`Action ${index + 1}`}>
                                    <Grid item xs={12}>
                                      <HabitAction
                                        questions={questions}
                                        habits={habits}
                                        path={`addHabitActions[${index}]`}
                                        onRemove={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        depth={1}
                                        canEdit={canEdit}
                                      />
                                    </Grid>
                                  </HabitNestedContainer>
                                </Grid>
                              ),
                            )}
                            <Grid item xs={12}>
                              <SmallPaddedButton
                                fullWidth
                                onClick={() =>
                                  arrayHelpers.push({
                                    type: '',
                                  })
                                }>
                                + Add
                              </SmallPaddedButton>
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="addLifestyleHabitActions"
                        render={(arrayHelpers) => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between">
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  Add Lifestyle Habit Actions
                                </Typography>
                              </Grid>
                            </Grid>
                            {props.values.addLifestyleHabitActions?.map(
                              (_streak, index) => (
                                <Grid
                                  item
                                  container
                                  xs={12} // eslint-disable-next-line react/no-array-index-key
                                  key={index}>
                                  <HabitNestedContainer
                                    title={`Action ${index + 1}`}>
                                    <Grid item xs={12}>
                                      <HabitAction
                                        questions={questions}
                                        habits={habits}
                                        path={`addLifestyleHabitActions[${index}]`}
                                        onRemove={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        depth={1}
                                        canEdit={canEdit}
                                      />
                                    </Grid>
                                  </HabitNestedContainer>
                                </Grid>
                              ),
                            )}
                            <Grid item xs={12}>
                              <SmallPaddedButton
                                fullWidth
                                onClick={() =>
                                  arrayHelpers.push({
                                    type: '',
                                  })
                                }>
                                + Add
                              </SmallPaddedButton>
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldArray
                        name="conversionActions"
                        render={(arrayHelpers) => (
                          <>
                            <Grid
                              container
                              alignItems="center"
                              justifyContent="space-between">
                              <Grid item xs={12}>
                                <Typography variant="body2">
                                  Conversion Actions
                                </Typography>
                              </Grid>
                            </Grid>
                            {props.values.conversionActions?.map(
                              (_streak, index) => (
                                <Grid
                                  item
                                  container
                                  xs={12} // eslint-disable-next-line react/no-array-index-key
                                  key={index}>
                                  <HabitNestedContainer
                                    title={`Action ${index + 1}`}>
                                    <Grid item xs={12}>
                                      <HabitAction
                                        questions={questions}
                                        habits={habits}
                                        path={`conversionActions[${index}]`}
                                        onRemove={() =>
                                          arrayHelpers.remove(index)
                                        }
                                        depth={1}
                                        canEdit={canEdit}
                                      />
                                    </Grid>
                                  </HabitNestedContainer>
                                </Grid>
                              ),
                            )}
                            <Grid item xs={12}>
                              <SmallPaddedButton
                                fullWidth
                                onClick={() =>
                                  arrayHelpers.push({
                                    type: '',
                                  })
                                }>
                                + Add
                              </SmallPaddedButton>
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Formik>
        <ConfirmCancelModal
          isOpen={!!upsertError}
          hideCancel
          onConfirm={() => setUpsertError(undefined)}
          onCancel={() => null}
          title={
            upsertError?.isWarning
              ? "Warning: Draft habit doesn't pass validation"
              : 'Error Saving Habit'
          }
          confirmLabel="OK"
          message={upsertError?.message}
        />
      </>
    </DataStateHandler>
  );
};

export default HabitDetails;
