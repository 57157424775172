import React, { useContext, useState } from 'react';
import { Button, IconButton, Grid, Popover, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  EditableProject,
  ProjectContext,
} from '../../../contexts/ProjectContext';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import CategoryIconDiamond from '../../common/Project/CategoryIconDiamond';
import DefaultLogo from '../../../assets/img/default-logo.png';
import SVG from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
  categoryButton: {
    border: 0,
    justifyContent: 'start',
    color: '#000',
    fontSize: 14,
    fontWeight: 'normal',
  },
  error: {
    color: theme.palette.error.main,
    position: 'relative',
    top: 10,
  },
}));

const CategoryIconSelect: React.FC = () => {
  const classes = useStyles();
  const context = useTypedFormikContext<EditableProject>();
  const { project, projectCategories, setTypedProjectField } =
    useContext(ProjectContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        onClick={(e) => setAnchorEl(!anchorEl ? e.currentTarget : undefined)}
        size="large">
        {project.category && (
          <CategoryIconDiamond categoryId={project.category} />
        )}
        {!project.category && (
          <Box>
            <SVG.Diamond style={{ height: 80, width: 80, color: '#EFEFEF' }} />
            <img
              src={DefaultLogo}
              style={{
                height: 50,
                width: 50,
                position: 'absolute',
                left: 25,
                top: 25,
              }}
              alt="logo"
            />
          </Box>
        )}
      </IconButton>
      <span className={classes.error}>
        {context.getFieldMeta('category').error}
      </span>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Grid container direction="column">
          {[...projectCategories]
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map((x) => (
              <Grid item key={x.id}>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={
                    <CategoryIconDiamond size="small" categoryId={x.id} />
                  }
                  className={classes.categoryButton}
                  onClick={() => {
                    context.setTypedFieldValue('category', x.id);
                    setTypedProjectField('category', x.id);
                    setAnchorEl(undefined);
                  }}>
                  {x.label}
                </Button>
              </Grid>
            ))}
        </Grid>
      </Popover>
    </div>
  );
};

export default CategoryIconSelect;
