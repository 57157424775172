import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PieChart, { PieChartData } from '../PieChart/PieChart';
import CardFooter from '../CardFooter/CardFooter';
import AppCard from '../AppCard/AppCard';
import { AppCardHeaderProps } from '../AppCard/AppCardHeader';
import { NumberFormat } from '../../../utils/numberUtils';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 16,
  },
  label: {
    marginRight: 8,
  },
  caption: {
    textAlign: 'center',
    color: '#6B6B6B',
  },
}));

export type TotalPieChart = {
  data: string | number;
  color: string;
  label: string;
};
export type PieChartCardFooter = {
  data: string | number;
  label: string;
};
type Props = {
  footer?: PieChartCardFooter;
  headerProps?: AppCardHeaderProps;
  data: Array<PieChartData & { labelOnly?: boolean }>;
  dataLabels?: Array<string | number>;
  showTooltip: boolean;
  tooltipFormat?: NumberFormat;
  wide?: boolean;
  cardHeight?: number;
  pieStyle?: React.CSSProperties;
  caption?: string;
};

const PieChartCard: React.FC<Props> = ({
  data,
  headerProps,
  dataLabels,
  footer,
  showTooltip,
  tooltipFormat,
  wide,
  cardHeight,
  pieStyle,
  caption,
}) => {
  const classes = useStyles();

  const pieChartData = data.filter((d) => !d.labelOnly);
  return (
    <AppCard headerProps={headerProps} height={cardHeight || 366}>
      <>
        <Grid
          container
          direction="row"
          className={classes.root}
          style={footer ? {} : { marginTop: 40 }}
          justifyContent="space-between">
          <Grid item xs={wide ? 4 : 6} container>
            <PieChart
              style={pieStyle}
              data={pieChartData}
              showTooltip={showTooltip}
              tooltipFormat={tooltipFormat}
            />
          </Grid>
          <Grid
            item
            xs={wide ? 8 : 6}
            direction="row"
            container
            style={
              data.length <= 2
                ? { marginTop: 32, height: 100 }
                : { margin: '24px 0px' }
            }>
            {data.map((d, idx) => (
              <Grid
                container
                key={d.id}
                item
                xs={wide ? 4 : 12}
                spacing={2}
                className={classes.label}
                alignItems="center"
                justifyContent="space-between">
                <Grid item>
                  <Grid container alignItems="center">
                    {d.color ? (
                      <Grid item>
                        <Box
                          borderRadius="50%"
                          width={8}
                          height={8}
                          bgcolor={d.color}
                          mr={1}
                        />
                      </Grid>
                    ) : null}

                    <Grid item style={{ marginRight: 8, maxWidth: 160 }}>
                      <Typography variant="body1" color="textPrimary">
                        {d.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {dataLabels && (
                  <Grid item>
                    <Typography variant="body1" color="textPrimary">
                      {dataLabels[idx]}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        {caption && (
          <Grid item>
            <Typography className={classes.caption} variant="body2">
              {caption}
            </Typography>
          </Grid>
        )}
        {footer && (
          <CardFooter
            data={footer.data.toLocaleString('en-US')}
            label={footer.label}
          />
        )}
      </>
    </AppCard>
  );
};

export default PieChartCard;
