import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Slider, Typography } from '@mui/material';
import { ProfileQuestionInputBase } from '../../../types/question/types';
import {
  useAnswerQuestion,
  useQuestionnaire,
} from '../../../contexts/QuestionnaireContext';
import { createQuestionAnswerFromQuestionAndEmissionSources } from '../../../utils/questionnaire';

function SliderInput({
  profileCard,
  forceDisabled,
  inputOnly,
}: ProfileQuestionInputBase) {
  const state = useQuestionnaire();
  const answerQuestion = useAnswerQuestion();

  const STATIC_MIN = 5;
  const STATIC_MAX = 95;

  const generateQuestionAnswer = useCallback(() => {
    const response = createQuestionAnswerFromQuestionAndEmissionSources(
      state.userEmissionSources,
      profileCard.profileId,
      profileCard.question,
    );

    if (profileCard.question.userInputOptions) {
      return {
        ...response,
        readings: response.readings
          ? response.readings.map((map) => ({
              ...map,
              calcInput: map.calcInput ? map.calcInput * 100 : 50,
            }))
          : // default value for the slider
            [
              {
                key: profileCard.question.userInputOptions?.propertyTemplateKey,
                calcInput: 50,
              },
            ],
      };
    }
    return {
      ...response,
      readings: response.readings?.map((map) => ({
        ...map,
        calcInput: map.calcInput || 50,
      })),
    };
  }, [profileCard.profileId, profileCard.question, state.userEmissionSources]);

  const [internalValue, setInternalValue] = useState(generateQuestionAnswer());

  useEffect(() => {
    setInternalValue(generateQuestionAnswer());
  }, [generateQuestionAnswer]);

  const onChange = (value: number) => {
    if (profileCard.question?.userInputOptions) {
      setInternalValue({
        ...internalValue,
        readings: [
          {
            key: profileCard.question.userInputOptions.propertyTemplateKey,
            calcInput: value,
          },
        ],
      });
    }
  };

  const onComplete = () => {
    const completeQuestionAnswer = {
      ...internalValue,
      readings: internalValue.readings?.map((map) => ({
        ...map,
        calcInput: map.calcInput / 100,
        calcInputUnit:
          profileCard.question.userInputOptions?.parameters[0].value || 'mult',
      })),
    };

    answerQuestion(profileCard, completeQuestionAnswer);
  };

  let value = 0;

  if (internalValue.readings?.length) {
    value = internalValue.readings[0].calcInput;
  }

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item>
        <Typography variant="h2">{value}%</Typography>
      </Grid>
      <Grid item container flexDirection="row">
        <Grid item xs={1}>
          <Typography variant="body1">{`${STATIC_MIN}%`}</Typography>
        </Grid>
        <Grid item xs>
          <Slider
            step={1}
            min={5}
            max={95}
            value={value}
            disabled={forceDisabled}
            onChange={(e, newValue) => onChange(newValue as number)}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body1">{`${STATIC_MAX}%`}</Typography>
        </Grid>
      </Grid>
      {!inputOnly && (
        <Grid item style={{ marginTop: 16 }}>
          <Button
            variant="contained"
            onClick={onComplete}
            disabled={forceDisabled}
            fullWidth
            style={{ padding: 24 }}>
            Next
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default SliderInput;
