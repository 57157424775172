import { gql, TypedDocumentNode } from '@apollo/client';
import { ZeroMeScoreFactor } from '../types/common';
import { EmisisonsSetting } from '../types/offsetProject/types';

/**
 * Get all global authentication policies that all companies can use.
 */
export const GET_GLOBAL_AUTHENTICATION_POLICIES = gql`
  query globalAuthenticationPolicies {
    globalAuthenticationPolicies {
      name
      value
    }
  }
` as TypedDocumentNode<{
  globalAuthenticationPolicies: { name: string; value: string }[];
}>;

export const GET_ALL_COUNTRIES = gql`
  query {
    allCountries(language: "en") {
      code
      name
    }
  }
`;

export const GET_ALL_REGIONS = gql`
  query ($countryCode: String) {
    allRegions(countryCode: $countryCode, language: "en") {
      code
      name
    }
  }
`;

export const GET_TOOLTIPS = gql`
  query tooltips($platform: TooltipPlatformType) {
    tooltips(filter: { platform: $platform }) {
      id
      platform
      value
      description
    }
  }
` as TypedDocumentNode<
  {
    tooltips: { id: string; value: string }[];
  },
  {
    platform?: 'PORTAL';
  }
>;

/**
 * Query for the public terms and conditions.
 */
export const GET_TERMS_PUBLIC = gql`
  query {
    termsPublic(appType: portal) {
      type
      content
      lastUpdatedDate
    }
  }
` as TypedDocumentNode<{
  termsPublic: { content: string; lastUpdatedDate: string; type: string }[];
}>;

export const GET_SCORE_FACTORS = gql`
  query scoreFactors {
    scoreFactors {
      id
      label
      logoUri
      description
      scoreProperty
    }
  }
` as TypedDocumentNode<{ scoreFactors: ZeroMeScoreFactor[] }>;

export const GET_EMISSIONS_SETTINGS = gql`
  query emissionsSettings {
    emissionsSettings {
      type
      subType
      allocType
      allocSubType
      allocTypeLabel
      allocSubTypeLabel
    }
  }
` as TypedDocumentNode<{
  emissionsSettings: EmisisonsSetting[];
}>;

export default {
  GET_ALL_COUNTRIES,
  GET_ALL_REGIONS,
  GET_TOOLTIPS,
  GET_SCORE_FACTORS,
  GET_EMISSIONS_SETTINGS,
};
