import { Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import CompanySelect from '../common/CompanySelect/CompanySelect';
import DateSelect from '../dashboards/DateSelect';
import MainLayout from './MainLayout';
import useStyles from './styles';

type Props = {
  children: JSX.Element | null;
};

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  const { user } = useContext(AuthorizationContext);

  return (
    <MainLayout>
      <>
        <Grid
          container
          spacing={0}
          className={classes.header}
          alignItems="center">
          <Grid item xs={12}>
            {user && (
              <Typography variant="h1" className={classes.name}>
                <Trans i18nKey="greeting" values={{ name: user.firstName }} />
              </Typography>
            )}
            <div className={classes.nav}>
              <DateSelect />
              <CompanySelect />
            </div>
          </Grid>
        </Grid>
        {children}
      </>
    </MainLayout>
  );
};

export default DashboardLayout;
