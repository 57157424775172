import { Grid } from '@mui/material';
import React from 'react';
import MyOffsetInventory from '../../components/offset/drawers/MyOffsetInventory';
import OffsetProvider from '../../contexts/OffsetContext';

const MyInventory: React.FC = () => (
  <OffsetProvider>
    <Grid item xs={12} style={{ height: 'calc(100vh - 170px)' }}>
      <MyOffsetInventory />
    </Grid>
  </OffsetProvider>
);

export default MyInventory;
