import _ from 'lodash';

type HasId = {
  id: string | number;
};

export const EMPTY_ARRAY = Object.freeze(new Array<unknown>());

export default class ArrayUtils {
  static equal<T>(a: T[], b: T[]) {
    return a.length === b.length && a.every((x, i) => x === b[i]);
  }

  static addOrRemove<T>(arr: T[], elem: T): T[] {
    const selectedIndex = arr.findIndex(
      (other) => JSON.stringify(elem) === JSON.stringify(other),
    );
    const clone = [...arr];

    if (selectedIndex >= 0) {
      clone.splice(selectedIndex, 1);
    } else {
      clone.push(elem);
    }

    return clone;
  }

  static addOrRemoveById<T extends HasId>(arr: readonly T[], elem: T): T[] {
    const found = arr.find((e) => e.id === elem.id);
    if (found) {
      return [...arr.filter((e) => e.id !== elem.id)];
    }
    return [elem, ...arr];
  }

  static getChildren<T extends HasId>(
    arr: readonly T[],
    parentIdProp: keyof T,
    id: string | number,
  ): T[] {
    let allChildren: T[] = [];
    let ids = [id];
    while (ids.length) {
      const children = arr.filter(
        // eslint-disable-next-line no-loop-func
        (a) =>
          a.id !== id &&
          ids.includes(a[parentIdProp] as unknown as string | number),
      );
      allChildren = [...allChildren, ...children];
      ids = children.map((c) => c.id);
    }

    return _.uniqBy(allChildren, 'id');
  }

  static groupAndSortHierarchy<T extends HasId>(
    arr: readonly T[],
    parentIdProp: keyof T,
    currentParentId?: string | number,
    level = 0,
  ): (T & { level: number })[] {
    const parents = arr
      .filter((c) => (c[parentIdProp] || null) === (currentParentId || null))
      .map((c) => ({
        ...c,
        level,
      }));
    const sorted = parents.flatMap((p) => {
      const children = ArrayUtils.groupAndSortHierarchy(
        arr,
        parentIdProp,
        p.id,
        level + 1,
      );
      return [p, ...children];
    });
    return sorted;
  }
}
