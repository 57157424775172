import React from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import { format, isValid, parse, parseISO } from 'date-fns';
import { Field } from 'formik';
import moment, { Moment } from 'moment';

import AppDatePicker from '../../common/AppDatePicker';
import SVG from '../../../assets/svg';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { Disableable } from '../../../types/common';

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: '1px solid #DADADA',
    padding: '16px 0px',
    marginTop: 16,
  },
  published: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: 0,
    paddingBottom: 8,
  },
  input: {
    padding: '18.5px 24px 18.5px 14px',
  },
}));

type ValidPublishFields = {
  publishDate?: string;
  expireDate?: string;
};

type Props = Disableable & {
  maxDate?: Date;
  minDate?: Date;
  alwaysAllowEditing?: boolean;
  overrides?: {
    fieldName: keyof ValidPublishFields;
    label: string;
    labelDetail: string;
  };
};

const PublishFields: React.FC<Props> = ({
  disabled,
  maxDate,
  alwaysAllowEditing,
  minDate,
  overrides,
}) => {
  const { setTypedFieldValue, values, initialValues } = useTypedFormikContext<
    ValidPublishFields & { hasBeenUnpublished?: boolean }
  >();
  const classes = useStyles();

  const {
    fieldName = 'publishDate',
    label = t('publishDate'),
    labelDetail = t('whenWouldYouLikeToPublishThis'),
  } = overrides || {};

  const { publishDate: initialPublishDate } = initialValues;
  const isPublished =
    moment().isSameOrAfter(moment(initialPublishDate)) &&
    !values.hasBeenUnpublished;

  let time = '';
  let partialPublishDate = '';
  const value = values[fieldName];
  if (value && isValid(parseISO(value))) {
    const iso = parseISO(value);
    time = format(iso, 'HH:mm');
    partialPublishDate = format(iso, 'MM/dd/yyyy');
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {(isPublished && !alwaysAllowEditing) || disabled ? (
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="h3">{label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={classes.published}
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-start">
              <Grid item>
                <Typography variant="body1">
                  <Box component="span" mr="8px">
                    <SVG.Calendar />
                  </Box>
                  {partialPublishDate}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <Box component="span" mr="8px">
                    <SVG.Clock />
                  </Box>
                  {value ? format(new Date(value), 'h:mm a') : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        !disabled && (
          <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
              <Typography variant="h3">{labelDetail}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{t('selectADay')}</Typography>
              <Field
                name={fieldName}
                maxDate={maxDate}
                minDate={minDate}
                component={AppDatePicker}
                // preserve time on publish dates
                serializeFn={(m: Moment) => m.toISOString()}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">{t('enterATime')}</Typography>
              <TextField
                variant="outlined"
                value={time}
                InputProps={{ classes: { input: classes.input } }}
                onChange={(e) => {
                  setTypedFieldValue(
                    fieldName,
                    parse(
                      `${partialPublishDate} ${e.target.value}`,
                      'MM/dd/yyyy HH:mm',
                      new Date(),
                    ).toISOString(),
                  );
                }}
                fullWidth
                label=""
                type="time"
              />
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default PublishFields;
