import { useMutation, useQuery } from '@apollo/client';
import {
  Grid,
  Paper,
  Typography,
  Snackbar,
  Alert,
  FormControlLabel,
  Checkbox,
  Switch,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { addHours, isBefore, parseISO, subHours } from 'date-fns';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import { t } from 'i18next';

import {
  DELETE_EMAIL_COMMUNICATION,
  EMAIL_COMMUNICATION_QUERY,
  PREVIEW_EMAIL_COMMUNICATION,
  UPSERT_EMAIL_COMMUNICATION,
} from '../../../graphql/communications/communications';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CommunicationDetailsFooter from '../CommunicationDetailsFooter';
import CommunicationDetailsHeader from '../CommunicationDetailsHeader';
import HeadingInput from './HeadingInput';
import PublishFields from './PublishFields';
import { AppRichTextField } from '../../common/AppRichTextField';
import CompanyInput from './CompanyInput';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { CommunicationsContext } from '../../../contexts/CommunicationsContext';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import ConfirmCancelModal from '../../common/ConfirmCancelModal/ConfirmCancelModal';
import { MultiSelect } from '../../common/MultiSelect';
import { EmailCommunication } from '../../../types/communication/email';

type Props = {
  id: string;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 64px',
  },
  form: {
    padding: '16px 0px !important',
  },
  paper: {
    padding: 24,
  },
}));

const maxDate = addHours(new Date(), 72);

const schema = yup.object().shape({
  title: yup.string().required(),
  content: yup.string(),
  publishDate: yup
    .date()
    .max(maxDate, 'Publish date and time must be within the next 72 hours')
    .nullable(),
  companyIds: yup.array().of(yup.string()).min(1),
});

const EmailCommunicationDetails: React.FC<Props> = ({ id, onClose }) => {
  const {
    loading,
    error,
    data: { emailCommunication = {} } = {},
  } = useQuery(EMAIL_COMMUNICATION_QUERY, {
    variables: { id },
    skip: id === 'new',
  });

  const [isShowingPublishWarning, setIsShowingPublishWarning] = useState(false);

  const [hasAccepteddWarning, setHasAcceptedWarning] = useState(false);

  const [previewComplete, setPreviewComplete] = useState(false);

  const { companyId, refetchCommunicationsQuery, challenges } = useContext(
    CommunicationsContext,
  );

  const [upsert, { loading: upsertLoading, error: upsertError }] = useMutation(
    UPSERT_EMAIL_COMMUNICATION,
  );

  const [deleteEmail, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_EMAIL_COMMUNICATION);

  const [preview, { loading: previewLoading, error: previewError }] =
    useMutation(PREVIEW_EMAIL_COMMUNICATION);

  const onPreview = async () => {
    await preview({
      variables: { id: emailCommunication.id },
      onCompleted: () => setPreviewComplete(true),
    });
  };

  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const initialValues: EmailCommunication = {
    title: '',
    content: '',
    publishDate: '',
    companyIds: companyId ? [companyId] : [],
    includeChildren: false,
    isGlobalCommunication: !companyId,
    userFilter: {
      onlyPreboardingUsers: false,
      contestIds: [],
      ...emailCommunication?.userFilter,
    },
    ...emailCommunication,
    hasBeenUnpublished: false,
  };

  const onUpsert = async (values: EmailCommunication) => {
    await upsert({
      variables: {
        input: {
          id: values.id,
          author: values.author,
          title: values.title,
          content: values.content,
          contentState: values.contentState,
          companyIds: values.companyIds,
          includeChildren: values.includeChildren,
          isGlobalCommunication: values.isGlobalCommunication,
          publishDate: values.publishDate,
          userFilter: {
            onlyPreboardingUsers: values.userFilter?.onlyPreboardingUsers,
            contestIds: values.userFilter?.contestIds,
          },
        },
      },
      onCompleted: () => onClose(),
    });
    refetchCommunicationsQuery();
  };

  const onDelete = async () => {
    await deleteEmail({ variables: { id }, onCompleted: () => onClose() });
    refetchCommunicationsQuery();
  };

  const hasBeenPublished =
    !!initialValues.publishDate &&
    isBefore(
      parseISO(initialValues.publishDate),
      parseISO(new Date().toISOString()),
    );

  const canDelete =
    id !== 'new' &&
    (!initialValues.publishDate ||
      isBefore(
        parseISO(new Date().toISOString()),
        subHours(parseISO(initialValues.publishDate), 4),
      ));

  const canEdit =
    !emailCommunication.publishDate &&
    (companyId
      ? userHasAccess('Client.Communication.Emails', 'EDIT')
      : userHasAccess('ZeroMe.Communication.Emails', 'EDIT'));

  const showIncludeChildren =
    companyId && userHasAccess('Api.AllowParentQueries', 'VIEW');

  const showChallengeSelect = !!companyId;

  return (
    <DataStateHandler
      loading={loading || upsertLoading || previewLoading || deleteLoading}
      error={error || upsertError || previewError || deleteError}>
      <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={async (values) => {
            if (values.publishDate) {
              setIsShowingPublishWarning(true);
            } else {
              await onUpsert(values);
            }
          }}
          validateOnMount>
          {({ handleSubmit, isSubmitting, isValid, setFieldValue, values }) => (
            <Grid
              container
              direction="column"
              className={classes.root}
              spacing={2}>
              <Grid item>
                <CommunicationDetailsHeader
                  title={
                    id === 'new'
                      ? 'Create New'
                      : `${canEdit ? 'Edit' : 'View'} Details`
                  }
                  canEdit={canEdit}
                  onClose={onClose}
                  onSave={handleSubmit}
                  saveDisabled={!isValid || isSubmitting || hasBeenPublished}
                  additionalActions={
                    <Grid item>
                      <SmallPaddedButton
                        onClick={onPreview}
                        disabled={!emailCommunication.id || !canEdit}
                        variant="contained"
                        endIcon={<SendIcon />}
                        color="primary">
                        Preview
                      </SmallPaddedButton>
                    </Grid>
                  }
                />
              </Grid>

              <Grid item className={classes.form}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between">
                      <Grid item>
                        <Typography variant="body2">
                          {t('emailCommunication.sendToNotOnboardedUsers')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Switch
                          checked={values.userFilter?.onlyPreboardingUsers}
                          onChange={(e, checked) =>
                            setFieldValue(
                              'userFilter.onlyPreboardingUsers',
                              checked,
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                    {showChallengeSelect && (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between">
                        <Grid item xs>
                          <Typography variant="body2">
                            {t('emailCommunication.sendToChallengesUsers')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <MultiSelect
                            items={challenges.map((x) => ({
                              ...x,
                              name: x.title,
                              level: 0,
                            }))}
                            initialSelectedItems={challenges
                              .map((x) => ({
                                ...x,
                                name: x.title,
                                level: 0,
                              }))
                              .filter((x) =>
                                (values?.userFilter?.contestIds ?? []).includes(
                                  x.id,
                                ),
                              )}
                            onChange={(items) =>
                              setFieldValue(
                                'userFilter.contestIds',
                                items.map((x) => x.id),
                              )
                            }
                            allSelectedMessage={t(
                              'emailCommunication.allChallenges',
                            )}
                          />
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {(showIncludeChildren || !companyId) && (
                        <CompanyInput
                          rootCompanyId={companyId}
                          disabled={!canEdit}
                          initialCompanyIds={values.companyIds ?? []}
                          onChange={(v) => setFieldValue('companyIds', v)}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {hasBeenPublished ? (
                        <Typography variant="h1">{values.title}</Typography>
                      ) : (
                        <HeadingInput
                          label="Subject"
                          disabled={!canEdit}
                          placeholder="Type your subject heading here"
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="content"
                        editorStateFieldName="contentState"
                        isEmailEditor
                        component={AppRichTextField}
                        labelText="Body"
                        initialValue={initialValues.content}
                        disabled={!canEdit || hasBeenPublished}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {hasBeenPublished ? null : (
                        <Alert severity="warning">
                          Emails with a publish date can no longer be edited.
                          Make sure you are finished before scheduling.
                        </Alert>
                      )}
                      <PublishFields maxDate={maxDate} disabled={!canEdit} />
                    </Grid>
                  </Grid>
                  {hasBeenPublished ? null : (
                    <CommunicationDetailsFooter
                      onSave={handleSubmit}
                      onDelete={canDelete ? onDelete : undefined}
                      saveDisabled={
                        !isValid || isSubmitting || hasBeenPublished || !canEdit
                      }
                    />
                  )}
                </Paper>
                <ConfirmCancelModal
                  isOpen={isShowingPublishWarning}
                  onConfirm={async () => {
                    setIsShowingPublishWarning(false);
                    await onUpsert(values);
                  }}
                  disableConfirm={!hasAccepteddWarning}
                  title="Publishing"
                  confirmLabel="Yes, set date and time and publish"
                  cancelLabel="No, continue editing draft"
                  onCancel={() => setIsShowingPublishWarning(false)}
                  messageFragments={[
                    <Grid container direction="column">
                      <Typography variant="body1">
                        Setting a publish date will cause this email to no
                        longer be in draft status, and will schedule it to be
                        sent. No further edits will be allowed. Are you sure?
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={hasAccepteddWarning}
                            onChange={() =>
                              setHasAcceptedWarning(!hasAccepteddWarning)
                            }
                          />
                        }
                        componentsProps={{ typography: { variant: 'body1' } }}
                        label="I understand"
                      />
                    </Grid>,
                  ]}
                />
              </Grid>
            </Grid>
          )}
        </Formik>
        <Snackbar
          open={previewComplete}
          sx={{ width: 300 }}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => setPreviewComplete(false)}>
          <Alert
            onClose={() => setPreviewComplete(false)}
            severity="success"
            sx={{ width: '100%' }}>
            Preview Sent!
          </Alert>
        </Snackbar>
      </>
    </DataStateHandler>
  );
};

export default EmailCommunicationDetails;
