/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LabelComponent } from '@nivo/circle-packing';
import CategoryIconDiamond from './Project/CategoryIconDiamond';
import SVG from '../../assets/svg';

export type ProjectCircleData = {
  id: string;
  name: string;
  count?: number;
  categoryId?: string;
  color?: string;
  textColor?: string;
  borderColor?: string;
  children?: ProjectCircleData[];
  icon?: JSX.Element;
};

const TITLE_BOX_HEIGHT = 55;

const useStyles = makeStyles(() => ({
  disablePointer: {
    pointerEvents: 'none',
  },
  title: {
    '& h3': {
      color: '#fff',
      margin: 'auto',
      textAlign: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
    },
    display: 'flex',
    height: TITLE_BOX_HEIGHT,
  },
  count: {
    color: '#fff',
    textAlign: 'center',
    '& svg': {
      position: 'relative',
      top: 1,
    },
    '& p': {
      color: '#fff',
      display: 'inline-block',
    },
  },
}));

const ProjectBubbleChartLabel: LabelComponent<ProjectCircleData> = ({
  node,
}) => {
  const classes = useStyles();
  const showCount = !!node.depth;
  const showTitle = showCount && node.radius > 70;
  const showIcon = showCount && node.radius > 40;

  const titleBox = {
    x: node.x - node.radius * 0.9,
    y: node.y - TITLE_BOX_HEIGHT / 2,
    width: node.radius * 1.8,
    height: TITLE_BOX_HEIGHT,
  };

  const iconBox = {
    x: node.x - 18,
    y: node.y - TITLE_BOX_HEIGHT * 1.1,
    width: 36,
    height: 36,
  };

  const viewsBox = {
    x: node.x - 50,
    y: node.y + TITLE_BOX_HEIGHT / 2,
    width: 100,
    height: 25,
  };

  if (!showTitle && !showIcon) {
    viewsBox.y = node.y - 10;
  } else if (!showTitle) {
    iconBox.y = node.y - 26;
    viewsBox.y = node.y + 10;
  }

  const { categoryId, name, count, icon, textColor } = node.data;

  return (
    <>
      {showIcon && (
        <foreignObject {...iconBox} className={classes.disablePointer}>
          <div
            // @ts-ignore
            xmlns="http://www.w3.org/1999/xhtml">
            <CategoryIconDiamond categoryId={categoryId} size="small" />
          </div>
        </foreignObject>
      )}
      {showTitle && (
        <foreignObject {...titleBox} className={classes.disablePointer}>
          <div
            // @ts-ignore
            xmlns="http://www.w3.org/1999/xhtml"
            className={classes.title}>
            <Typography
              variant="h3"
              style={textColor ? { color: textColor } : undefined}>
              {name}
            </Typography>
          </div>
        </foreignObject>
      )}
      {showCount && (
        <foreignObject {...viewsBox} className={classes.disablePointer}>
          <div
            // @ts-ignore
            xmlns="http://www.w3.org/1999/xhtml"
            className={classes.count}>
            {icon || <SVG.Eye />}{' '}
            <Typography
              variant="body2"
              style={textColor ? { color: textColor } : undefined}>
              {count}
            </Typography>
          </div>
        </foreignObject>
      )}
    </>
  );
};

export default ProjectBubbleChartLabel;
