import React, { useState } from 'react';
import { useHistory } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { SUBMIT_USER_CONTACT } from '../../../graphql/user/userMutations';
import ConfirmCancelModal from '../../common/ConfirmCancelModal/ConfirmCancelModal';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: 24,
    marginBottom: 24,
    maxWidth: 400,
  },
}));

const ContactUsForm: React.FC = () => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [submit, { loading }] = useMutation(SUBMIT_USER_CONTACT);
  const history = useHistory();

  const submitForm = () =>
    submit({
      variables: {
        message: text,
        contactType: 'GENERAL',
      },
      onCompleted: () => setShowDialog(true),
    });

  return (
    <Grid container direction="column">
      <Typography variant="h3" sx={{ mb: 4 }}>
        Fill out the form below and someone will contact you within 48 hours.
      </Typography>
      <TextField
        sx={{ mb: 2 }}
        value={text}
        onChange={(e) => setText(e.target.value)}
        multiline
        rows={10}
        placeholder="Tell us how we can help"
      />
      <Button
        disabled={!text || loading}
        className={classes.button}
        variant="contained"
        onClick={submitForm}>
        Submit
      </Button>
      <ConfirmCancelModal
        isOpen={showDialog}
        title="Success!"
        message="We’ve received your question/comment and will respond within 48 hours."
        confirmLabel="Take Me to My Dashboard"
        hideCancel
        onConfirm={() => history.push('/member/dashboard')}
      />
    </Grid>
  );
};

export default ContactUsForm;
