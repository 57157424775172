export function findAncestor(el: HTMLElement, cls: string) {
  let curEl: HTMLElement | null | undefined = el;
  // eslint-disable-next-line no-cond-assign
  while ((curEl = curEl.parentElement) && !curEl.classList.contains(cls));
  return curEl;
}

export async function downloadFileUrl(url: string, filename: string) {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

export async function downloadBlob(blob: Blob, filename: string) {
  downloadFileUrl(window.URL.createObjectURL(blob), filename);
}
