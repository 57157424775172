import React from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryLine } from 'victory';

import AppInfo from '../../common/AppInfo';
import { Colors } from '../../../theme/shared';
import { typographyToVictory } from '../../../utils/victoryUtils';

const useStyles = makeStyles(() => ({
  titleContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  title: {
    color: Colors.TertiaryText,
    textTransform: 'uppercase',
  },
}));

type Props = {
  data: { x: string; y: number }[];
  datePeriod: 'month' | 'week';
  variant: 'bar' | 'line';
};

const PointsBreakdownChart: React.FC<Props> = ({
  data,
  datePeriod,
  variant,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const maxDomain = Math.max(...data.map(({ y }) => y)) || 50;

  if (variant === 'line' && data.length <= 1) {
    return (
      <Typography variant="body2" align="center">
        {t('contest.calculatingChallengeBreakdown')}
      </Typography>
    );
  }

  return (
    <Grid container item direction="column">
      <Grid container item className={classes.titleContainer}>
        <Typography className={classes.title} variant="subtitle1">
          {t(`contest.pointsEarnedBy${datePeriod}`)}
        </Typography>
        <AppInfo id={`leaderboard_points_by_${datePeriod}`} />
      </Grid>
      <Grid container item>
        <VictoryChart
          padding={{ left: 52, bottom: 20 }}
          domainPadding={{ x: 180 / data.length, y: 30 }}
          domain={{ y: [0, maxDomain] }}
          height={200}>
          <VictoryAxis
            data={data}
            crossAxis={false}
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: {
                ...typographyToVictory(theme.typography.h5),
                fill: Colors.TertiaryText,
              },
            }}
          />
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: {
                ...typographyToVictory(theme.typography.h5),
                fill: Colors.TertiaryText,
              },
              grid: { stroke: Colors.DisabledGrey },
            }}
          />
          {variant === 'bar' && (
            <VictoryBar
              data={data}
              labels={({ datum }) => datum.y}
              barWidth={Math.min(250 / data.length, 150)}
              cornerRadius={{
                topLeft: 4,
                topRight: 4,
              }}
              style={{
                data: {
                  fill: Colors.CobaltBlue,
                },
                labels: {
                  ...typographyToVictory(theme.typography.h4),
                  fill: Colors.TertiaryText,
                },
              }}
            />
          )}
          {variant === 'line' && (
            <VictoryLine
              data={data}
              style={{
                data: {
                  stroke: Colors.CobaltBlue,
                  strokeWidth: 5,
                  strokeLinecap: 'round',
                },
                labels: {
                  ...typographyToVictory(theme.typography.h4),
                  fill: Colors.TertiaryText,
                },
              }}
            />
          )}
        </VictoryChart>
      </Grid>
    </Grid>
  );
};

export default PointsBreakdownChart;
