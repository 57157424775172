import { Grid, Typography, IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { CommunicationType } from './types';
import SVG from '../../assets/svg';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import CommunicationTypeChip from './CommunicationTypeChip';

type Props = {
  title: string;
  communicationType?: CommunicationType;
  canEdit: boolean;
  onClose: () => void;
  additionalActions?: JSX.Element;
} & (
  | {
      saveDisabled?: boolean;
      onSave: () => void;
      saveButton?: never;
    }
  | {
      saveDisabled?: never;
      onSave?: never;
      saveButton: JSX.Element;
    }
);

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  right: {
    marginLeft: 'auto',
  },
}));

const CommunicationDetailsHeader: React.FC<Props> = ({
  title,
  communicationType,
  canEdit,
  onClose,
  onSave,
  saveButton,
  saveDisabled,
  additionalActions,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      justifyContent="space-around"
      alignItems="center"
      className={classes.root}>
      <Grid item>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size="large"
          edge="start">
          <SvgIcon component={SVG.Back} />
        </IconButton>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="space-between">
          <Typography variant="h1">{title}</Typography>
          {communicationType ? (
            <CommunicationTypeChip communicationType={communicationType} />
          ) : null}
        </Grid>
      </Grid>
      <Grid item className={classes.right}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-around">
          {canEdit && (
            <>
              <Grid item>
                <SmallPaddedButton
                  onClick={onClose}
                  variant="outlined"
                  color="primary">
                  Cancel
                </SmallPaddedButton>
              </Grid>
              <Grid item>
                {saveButton ? (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>{saveButton}</>
                ) : (
                  <SmallPaddedButton
                    disabled={saveDisabled}
                    variant="contained"
                    color="primary"
                    onClick={onSave}>
                    Save
                  </SmallPaddedButton>
                )}
              </Grid>
              {additionalActions}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommunicationDetailsHeader;
