import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useMemo, useRef, useState } from 'react';
import CompanySettingsModalInput from '../../components/settings/CompanySettingsModalInput';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import LocationMap from '../../components/common/LocationMap';
import SVG from '../../assets/svg';
import useSettingsStyles from './useSettingsStyles';
import { SettingsModalInputRef } from '../../components/settings/SettingsModalInput';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import WhiteSquare from '../../assets/img/fff.png';
import ImageUtils from '../../utils/imageUtils';
import PlatformFeatures from '../../components/settings/PlatformFeatures';
import { CompanyFeature } from '../../types/company/types';
import { getCompanyImage } from '../../components/settings/CompanyImagePositionForm';
import DefaultImage from '../../components/common/DefaultImage';

const useStyles = makeStyles(() => ({
  uploadLogo: {
    width: 180,
    height: 180,
    marginTop: 24,
    borderRadius: 8,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
  },
  logo: {
    marginTop: 16,
    width: 148,
    height: 148,
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
    borderRadius: 120,
  },
  logoEmpty: {
    border: '1px dashed #BDD9C5',
    padding: 20,
    paddingTop: 45,
    textAlign: 'center',
  },
  image: {
    height: 200,
    width: '100%',
    objectFit: 'cover',
    display: 'block',
    margin: 'auto',
    borderRadius: 8,
  },
  imageEmpty: {
    border: '1px dashed #BDD9C5',
    padding: 75,
    textAlign: 'center',
    borderRadius: 8,
  },
  pointer: {
    cursor: 'pointer',
  },
  division: {
    border: '1px solid #DADADA',
    padding: 16,
    borderRadius: 8,
  },
  divisionLogo: {
    border: '1px solid #DADADA',
    borderRadius: 4,
    height: 40,
    width: 40,
  },
}));

const CompanySettings: React.FC = () => {
  const { userHasAccess } = useContext(AuthorizationContext);
  const { company, divisions, loading } = useContext(CompanySettingsContext);
  const logoModalRef = useRef<SettingsModalInputRef>({
    setModalOpen: () => {
      // Do nothing
    },
  });
  const imageModalRef = useRef<SettingsModalInputRef>({
    setModalOpen: () => {
      // Do nothing
    },
  });
  const cropModalRef = useRef<SettingsModalInputRef>({
    setModalOpen: () => {
      // Do nothing
    },
  });
  const settingsClasses = useSettingsStyles();
  const classes = useStyles();
  const canEdit = userHasAccess('Configuration.Company', 'EDIT');
  const pointerClass = canEdit ? classes.pointer : '';

  const logoUri = useMemo(
    () =>
      (company.logo?.file && URL.createObjectURL(company.logo.file)) ||
      company.logoUris?.large,
    [company],
  );

  const imageUri = useMemo(() => getCompanyImage(company), [company]);

  const [overImage, setOverImage] = useState(false);

  return (
    <DataStateHandler loading={loading}>
      <Grid container direction="column">
        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">Organization Details</Typography>
              <CompanySettingsModalInput fieldName="logo" ref={logoModalRef}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                <div
                  className={classes.uploadLogo}
                  style={{
                    backgroundColor: company.logoBackgroundColor,
                    marginBottom: 24,
                  }}
                  onClick={
                    canEdit
                      ? () => logoModalRef.current.setModalOpen(true)
                      : undefined
                  }>
                  {!!logoUri && (
                    <img
                      alt="Company"
                      src={logoUri}
                      className={`${classes.logo} ${pointerClass}`}
                    />
                  )}
                  {!logoUri && (
                    <div
                      className={`${classes.logo} ${classes.logoEmpty} ${pointerClass}`}>
                      <Grid container direction="column">
                        <SVG.Portrait
                          style={{ margin: 'auto', marginBottom: 12 }}
                        />
                        <Typography variant="h4">
                          Add Organization Logo
                        </Typography>
                      </Grid>
                    </div>
                  )}
                </div>
              </CompanySettingsModalInput>
              {canEdit && (
                <Typography variant="body2" color="textSecondary">
                  We recommend uploading an icon of your logo, like you would
                  for a social profile.
                </Typography>
              )}
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <CompanySettingsModalInput fieldName="name" />
            <CompanySettingsModalInput
              disabled={!!company.id}
              fieldName="companyTypeId"
            />
            <CompanySettingsModalInput fieldName="parentCompany" />
            <CompanySettingsModalInput fieldName="image" ref={imageModalRef}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events */}
              <div
                style={{ marginBottom: 8, position: 'relative' }}
                onMouseOver={() => setOverImage(true)}
                onMouseOut={() => setOverImage(false)}
                onClick={
                  canEdit
                    ? () => {
                        if (overImage && !!imageUri) {
                          cropModalRef.current.setModalOpen(true);
                        } else {
                          imageModalRef.current.setModalOpen(true);
                        }
                      }
                    : undefined
                }>
                {!!imageUri && (
                  <>
                    <img
                      alt="Company"
                      src={imageUri}
                      className={`${classes.image} ${pointerClass}`}
                      style={ImageUtils.ObjectPositionStyle(
                        company.imagePosition,
                      )}
                    />
                    {overImage && (
                      <Typography
                        variant="h3"
                        style={{
                          position: 'absolute',
                          top: '50%',
                          textAlign: 'center',
                          width: '100%',
                          color: '#fff',
                          textShadow: '0 0 2px #000',
                          pointerEvents: 'none',
                        }}>
                        Adjust Wide Format Cropping
                      </Typography>
                    )}
                  </>
                )}
                {!imageUri && (
                  <div className={classes.imageEmpty}>
                    <Grid container direction="column">
                      <SVG.Portrait
                        style={{ margin: 'auto', marginBottom: 12 }}
                      />
                      <Typography variant="h4">
                        Add Organization Background Image
                      </Typography>
                    </Grid>
                  </div>
                )}
              </div>
            </CompanySettingsModalInput>
            <CompanySettingsModalInput
              ref={cropModalRef}
              fieldName="imagePosition"
            />
            <CompanySettingsModalInput fieldName="phone" />
            <CompanySettingsModalInput fieldName="email" />
          </Grid>
        </Grid>
        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">Location</Typography>
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <CompanySettingsModalInput fieldName="address">
              {!!company.latitude && (
                <div style={{ marginBottom: 8 }}>
                  <LocationMap
                    latitude={company.latitude}
                    longitude={company.longitude}
                  />
                </div>
              )}
            </CompanySettingsModalInput>
          </Grid>
        </Grid>
        {!!divisions.length && (
          <Grid
            item
            container
            direction="row"
            className={settingsClasses.group}>
            <Grid item xs={3}>
              <div className={settingsClasses.leftCol}>
                <Typography variant="h3">Divisions</Typography>
              </div>
            </Grid>
            <Grid item container direction="row" xs={9} spacing={3}>
              {divisions.map((x) => (
                <Grid item key={x.id} xs={4}>
                  <div className={classes.division}>
                    {x.logoUris?.thumb ? (
                      <img
                        src={x.logoUris?.thumb || WhiteSquare}
                        className={classes.divisionLogo}
                        alt="logo"
                      />
                    ) : (
                      <DefaultImage
                        height={40}
                        style={{
                          width: 40,
                          borderRadius: 4,
                          border: '1px solid #DADADA',
                        }}
                      />
                    )}

                    <Typography variant="h3" style={{ padding: '24px 0 16px' }}>
                      {x.name}
                    </Typography>
                    <div style={{ padding: '8px 0' }}>
                      <Typography variant="body2">
                        {x.address || '\u00A0'}
                      </Typography>
                      <Typography variant="body2">
                        {x.place
                          ? `${x.place}, ${x.region} ${x.postalCode}`
                          : '\u00A0'}
                      </Typography>
                    </div>
                    <div style={{ paddingTop: 8 }}>
                      <Typography variant="body2">
                        {x.phoneNumber || '\u00A0'}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">Technical Contact</Typography>
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <CompanySettingsModalInput fieldName="technicalContactName" />
            <CompanySettingsModalInput fieldName="technicalContactEmail" />
            <CompanySettingsModalInput fieldName="technicalContactPhoneNumber" />
          </Grid>
        </Grid>
        <Grid item container direction="row" className={settingsClasses.group}>
          <Grid item xs={3}>
            <div className={settingsClasses.leftCol}>
              <Typography variant="h3">Features</Typography>
            </div>
          </Grid>
          <Grid item container direction="column" xs={9}>
            <PlatformFeatures features={company.features as CompanyFeature[]} />
          </Grid>
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default CompanySettings;
