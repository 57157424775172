/* eslint-disable arrow-body-style */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';
import React, { useCallback, useEffect, useState } from 'react';
import {
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatClear,
  FormatColorFill,
  FormatColorText,
  FormatIndentDecrease,
  FormatIndentIncrease,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatStrikethrough,
  FormatUnderlined,
} from '@mui/icons-material';
import { ButtonGroup, Grid } from '@mui/material';
import {
  ColorPicker,
  HeaderPicker,
  ImagePicker,
  ToolbarButton,
} from './components';
import { useClearFormatting } from './hooks';

const LowPriority = 1;

type Props = {
  textColor: `#${string}`;
  textBackgroundColor: `#${string}`;
};

// eslint-disable-next-line import/prefer-default-export
export const ToolbarPlugin = (props: Props) => {
  const { textColor, textBackgroundColor } = props;
  const [editor] = useLexicalComposerContext();

  const [isItalic, setIsItalic] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsItalic(selection.hasFormat('italic'));
      setIsBold(selection.hasFormat('bold'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      ),
    );
  }, [editor, $updateToolbar]);

  const clearFormatting = useClearFormatting();

  return (
    <Grid container rowGap={1} columnGap={2} padding={1}>
      <ButtonGroup variant="contained">
        <ToolbarButton
          isHighlighted={isItalic}
          icon={<FormatItalic />}
          commandType={FORMAT_TEXT_COMMAND}
          commandPayload="italic"
        />
        <ToolbarButton
          isHighlighted={isBold}
          icon={<FormatBold />}
          commandType={FORMAT_TEXT_COMMAND}
          commandPayload="bold"
        />
        <ToolbarButton
          isHighlighted={isUnderline}
          icon={<FormatUnderlined />}
          commandType={FORMAT_TEXT_COMMAND}
          commandPayload="underline"
        />
        <ToolbarButton
          isHighlighted={isStrikethrough}
          icon={<FormatStrikethrough />}
          commandType={FORMAT_TEXT_COMMAND}
          commandPayload="strikethrough"
        />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <ToolbarButton
          icon={<FormatAlignLeft />}
          commandType={FORMAT_ELEMENT_COMMAND}
          commandPayload="left"
        />
        <ToolbarButton
          icon={<FormatAlignCenter />}
          commandType={FORMAT_ELEMENT_COMMAND}
          commandPayload="center"
        />
        <ToolbarButton
          icon={<FormatAlignRight />}
          commandType={FORMAT_ELEMENT_COMMAND}
          commandPayload="right"
        />
        <ToolbarButton
          icon={<FormatAlignJustify />}
          commandType={FORMAT_ELEMENT_COMMAND}
          commandPayload="justify"
        />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <ToolbarButton
          icon={<FormatListBulleted />}
          commandType={INSERT_UNORDERED_LIST_COMMAND}
          commandPayload={undefined}
        />
        <ToolbarButton
          icon={<FormatListNumbered />}
          commandType={INSERT_ORDERED_LIST_COMMAND}
          commandPayload={undefined}
        />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <ToolbarButton
          icon={<FormatIndentDecrease />}
          commandType={OUTDENT_CONTENT_COMMAND}
          commandPayload={undefined}
        />
        <ToolbarButton
          icon={<FormatIndentIncrease />}
          commandType={INDENT_CONTENT_COMMAND}
          commandPayload={undefined}
        />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <ToolbarButton
          icon={<FormatClear />}
          onClick={clearFormatting}
          commandPayload={undefined}
          commandType={{
            type: undefined,
          }}
        />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <ColorPicker
          initialColor={textColor}
          icon={FormatColorText}
          styleKey="color"
        />
        <ColorPicker
          initialColor={textBackgroundColor}
          icon={FormatColorFill}
          styleKey="background-color"
        />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <ImagePicker />
      </ButtonGroup>
      <ButtonGroup variant="contained">
        <HeaderPicker />
      </ButtonGroup>
    </Grid>
  );
};
