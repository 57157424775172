import React from 'react';
import { Typography, Grid, ButtonBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import StickyCell from './StickyCell';
import SVG from '../../../assets/svg';

const useStyles = makeStyles((theme) => ({
  headerButton: { width: '100%', height: '100%' },
  noWrap: { flexWrap: 'nowrap' },
  mainHeaderText: {
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    flexGrow: 1,
    textAlign: 'left',
  },
  skipIcon: {
    color: theme.palette.primary.main,
    marginLeft: '1rem',
  },
}));

const GroupHeaderCell: React.FC<Props> = ({
  icon,
  title,
  position = 'top',
  backgroundColor,
  onClick = () => {
    // Do nothing
  },
  headerButtonClassName,
  open = true,
  isCollapsible = true,
  columnLength = 0,
  containerClassName,
  colSpan,
}) => {
  const classes = useStyles();

  return (
    <StickyCell
      containerStyle={{ backgroundColor }}
      position={position}
      containerClassName={containerClassName}
      colSpan={colSpan}>
      <ButtonBase
        onClick={onClick}
        className={`${headerButtonClassName} ${classes.headerButton}`}>
        {icon && title && (
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.noWrap}>
            <Grid item>{icon}</Grid>
            <Grid item className={classes.mainHeaderText}>
              <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item>
              {columnLength > 1 &&
                isCollapsible &&
                (open ? <SVG.Collapse /> : <SVG.Expand />)}
            </Grid>
          </Grid>
        )}
      </ButtonBase>
    </StickyCell>
  );
};

type Props = {
  icon: string | React.ReactNode;
  title: string | React.ReactNode;
  position: 'left' | 'top' | 'both';
  backgroundColor: string;
  headerButtonClassName?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  isCollapsible?: boolean;
  columnLength: number;
  containerClassName?: string;
  colSpan?: number;
};

export default GroupHeaderCell;
