import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { ProjectContext } from '../../../contexts/ProjectContext';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 800,
  },
  locationRoot: {
    padding: 16,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 8,
    marginBottom: 16,
    marginTop: 16,
  },
}));
const CreateProjectLocationModal: React.FC = () => {
  const {
    setCurrentDetailsStep,
    shouldShowLocationModalNextStep,
    setShouldShowLocationModalNextStep,
    project,
  } = useContext(ProjectContext);
  const classes = useStyles();
  return (
    <Box width="100%" p={2}>
      <Typography align="center" variant="h1">
        Confirm Location Address
      </Typography>
      <Grid container className={classes.locationRoot}>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span className={classes.label}>Street: </span>
            {project.address}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span className={classes.label}>City: </span> {project.place}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span className={classes.label}>State / Province / Area: </span>
            {project.region}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span className={classes.label}>Postal Code: </span>
            {project.postalCode}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span className={classes.label}>Country: </span>
            {project.country}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShouldShowLocationModalNextStep(false)}>
            Go back and edit
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              !!shouldShowLocationModalNextStep &&
              setCurrentDetailsStep(shouldShowLocationModalNextStep, true)
            }>
            Yes, looks good
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateProjectLocationModal;
