import React from 'react';
import { Divider, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../../assets/svg';
import SVG2 from '../../../assets/svg-v2';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    flexWrap: 'nowrap',
  },
  crust: {
    background: theme.palette.primary.main,
    padding: 16,
    borderRadius: '8px 0 0 8px',
    textAlign: 'center',
  },
  bang: {
    height: '100%',
  },
  main: {
    background: theme.palette.primary.light,
    padding: 16,
    borderRadius: '0 8px 8px 0',
  },
  content: {
    marginRight: 16,
  },
  gutter: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export type MemberAlertContent = {
  messages?: {
    iconUrl?: string;
    iconUrlAlt?: string;
    title?: React.ReactNode;
    description?: React.ReactNode;
  }[];
};

type Props = MemberAlertContent & {
  onClose?: () => void;
};
const MemberAlert: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ onClose, messages }, ref) => {
    const classes = useStyles();

    return (
      <div ref={ref}>
        <Grid container className={classes.root}>
          <Grid item xs={1} className={classes.crust}>
            <SVG2.Bang className={classes.bang} />
          </Grid>
          <Grid item xs={11} className={classes.main}>
            <Grid
              container
              justifyContent="space-between"
              style={{ flexWrap: 'nowrap' }}>
              <Grid container flexDirection="column">
                {messages?.map(
                  (
                    {
                      title: tempTitle,
                      description: tempDescription,
                      iconUrl,
                      iconUrlAlt: tempIconUrlAlt,
                    },
                    idx,
                  ) => {
                    const title =
                      typeof tempTitle === 'string' ? (
                        <Typography variant="h3">{tempTitle}</Typography>
                      ) : (
                        tempTitle
                      );
                    const description =
                      typeof tempDescription === 'string' ? (
                        <Typography variant="body2">
                          {tempDescription}
                        </Typography>
                      ) : (
                        tempDescription
                      );
                    const iconUrlAlt =
                      tempIconUrlAlt ??
                      (typeof title === 'string' ? title : undefined);

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item key={idx}>
                        <Grid
                          container
                          style={{ flexWrap: 'nowrap' }}
                          spacing={2}
                          alignItems="center"
                          className={classes.content}>
                          {!!iconUrl && (
                            <Grid item>
                              <img src={iconUrl} alt={iconUrlAlt} />
                            </Grid>
                          )}
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              alignItems="flex-start">
                              {title}
                              {description}
                            </Grid>
                          </Grid>
                        </Grid>
                        {idx < messages.length - 1 && (
                          <Grid item className={classes.gutter}>
                            <Divider variant="middle" />
                          </Grid>
                        )}
                      </Grid>
                    );
                  },
                )}
              </Grid>

              {onClose && (
                <Grid item>
                  <IconButton onClick={onClose}>
                    <SVG.Close />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  },
);

export default MemberAlert;
