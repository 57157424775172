export default {
  html: {
    WebkitFontSmoothing: 'auto',
    height: '100%',
  },
  body: {
    height: '100%',
  },
  '::-webkit-scrollbar': {
    width: 16,
    height: 16,
  },
  '::-webkit-scrollbar-track': {
    background: '#F9F9F9',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#DADADA',
  },
  '.carousel .control-dots': {
    top: '400px',
    right: '8px',
    textAlign: 'right' as const,
  },
  '.carousel .control-dots .dot': {
    background: '#A9A9A9',
    opacity: 1,
  },
  '.carousel .control-dots .dot.selected': {
    background: '#2D63F9',
  },
};
