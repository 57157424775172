/* eslint-disable jsx-a11y/media-has-caption */
import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import CommunicationHeaderImage from './CommunicationHeaderImage';
import CommunicationStar from './CommunicationStar';
import CommunicationStarRating from './CommunicationStarRating';
import RelatedContent from './RelatedContent';
import QuizCard from '../quiz/QuizCard';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import Loading from '../../common/Loading';
import { MemberCommunicationListItem } from '../../communications/types';
import useCheckForPointAwards from '../../../hooks/useCheckForPointAwards';
import {
  GET_DISCOVER_COMMUNICATION_DETAILS,
  UPDATE_COMMUNICATION_RATING,
  UPDATE_COMMUNICATION_VIEWS,
} from '../../../graphql/communications/memberCommunications';
import { Colors } from '../../../theme/shared';
import theme from '../../../theme/memberTheme';

const useStyles = makeStyles(() => ({
  caption: {
    color: '#6B6B6B',
    margin: '24px 0px',
  },
  content: {
    whiteSpace: 'pre-line',
  },
}));

type Props = {
  id: string;
};

const MemberCommunicationDetails: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedRating, setSelectedRating] = useState<number | null>();
  const { checkForPointAwards } = useCheckForPointAwards();

  const [updateCommunicationViews] = useMutation(UPDATE_COMMUNICATION_VIEWS);
  const [updateCommunicationRating, { loading: ratingLoading }] = useMutation(
    UPDATE_COMMUNICATION_RATING,
  );

  const {
    data: { communication } = {},
    loading,
    error,
  } = useQuery(GET_DISCOVER_COMMUNICATION_DETAILS, {
    variables: {
      id,
    },
  });

  useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      updateCommunicationViews({
        variables: {
          communicationId: id,
        },
      }).then(() => checkForPointAwards());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();
  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid container spacing={2} direction="column">
        <CommunicationHeaderImage
          communication={communication as MemberCommunicationListItem}
          imageHeight={400}
          imageStyle={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            marginBottom: -8,
          }}
        />
        <Grid
          container
          item
          direction="row"
          style={{
            backgroundColor: '#F1F8FD',
            padding: 16,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
          }}
          justifyContent="space-between">
          <Grid item container direction={isSm ? 'column' : 'row'} flex={1}>
            <Typography variant="h3">Global Rating</Typography>
            <Grid item style={{ marginLeft: isSm ? undefined : 16 }}>
              <CommunicationStarRating
                size="lg"
                rating={communication?.ratings?.global}
              />
              <Typography
                variant="subtitle2"
                style={{
                  marginTop: 4,
                  color: Colors.TertiaryText,
                }}>
                {communication?.ratings?.global?.count
                  ? `${communication?.ratings?.global?.count} global ratings`
                  : 'No global ratings yet'}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction={isSm ? 'column' : 'row'}
            justifyContent="flex-end"
            alignItems={isSm ? 'flex-end' : undefined}
            flex={1}>
            <Typography variant="h3">Company Rating</Typography>
            <Grid item style={{ marginLeft: isSm ? undefined : 16 }}>
              <CommunicationStarRating
                size="lg"
                rating={communication?.ratings?.company}
              />
              <Typography
                variant="subtitle2"
                style={{
                  marginTop: 4,
                  color: Colors.TertiaryText,
                }}>
                {communication?.ratings?.company?.count
                  ? `${communication?.ratings?.company?.count} company ratings`
                  : 'No company ratings yet'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="caption"
          className={classes.caption}
          style={{ margin: 0, marginBottom: '8px' }}>
          {communication?.author}
        </Typography>
        <Typography variant="h1">{communication?.title}</Typography>
        {communication?.readingTime ? (
          <Typography variant="caption" className={classes.caption}>
            {communication?.readingTime} MIN READ
          </Typography>
        ) : null}

        <Typography variant="body2" className={classes.content}>
          <Box
            component="span"
            dangerouslySetInnerHTML={{
              __html: communication?.content || '',
            }}
          />
        </Typography>

        {communication?.communicationType === 'VIDEO' &&
        communication.contentUploadUrl ? (
          <video controls width="100%">
            <source src={communication.contentUploadUrl} />
            This video format is not supported by your browswer.
          </video>
        ) : null}
        {communication?.communicationType === 'AUDIO' &&
        communication?.contentUploadUrl ? (
          <audio controls>
            <source src={communication.contentUploadUrl} />
            This audio format is not supported by your browser.
          </audio>
        ) : null}
        <Box style={{ marginTop: 24, marginBottom: 24 }}>
          {ratingLoading && <Loading />}
          {!ratingLoading && communication?.ratings?.user && (
            <>
              <Typography variant="h4" style={{ paddingBottom: 8 }}>
                You rated this article
              </Typography>
              <CommunicationStarRating
                rating={communication.ratings.user}
                size="xl"
              />
            </>
          )}
          {!ratingLoading && !communication?.ratings?.user && (
            <>
              <Typography variant="h4">
                What did you think of this article?
              </Typography>
              <Grid container direction="row">
                <div>
                  <Grid container direction="row">
                    {Array.from(Array(5).keys()).map((x) => {
                      const active = selectedRating && selectedRating > x;

                      return (
                        <IconButton
                          key={x}
                          onClick={() => setSelectedRating(x + 1)}>
                          <CommunicationStar
                            disabled={!active}
                            fillRatio={active ? 1 : 0}
                            sizePx={40}
                          />
                        </IconButton>
                      );
                    })}
                  </Grid>
                </div>
                {!!selectedRating && (
                  <Button
                    variant="contained"
                    style={{ marginLeft: 32 }}
                    onClick={() =>
                      communication &&
                      updateCommunicationRating({
                        variables: {
                          communicationId: communication.id,
                          score: selectedRating ?? 5,
                        },
                      }).then(() => checkForPointAwards())
                    }>
                    Confirm
                  </Button>
                )}
              </Grid>
            </>
          )}
        </Box>
        {!!communication?.quiz && <QuizCard quiz={communication.quiz} />}
        <Grid item xs={12}>
          <RelatedContent
            relatedArticles={communication?.relatedArticles}
            relatedHabits={communication?.relatedHabits}
            onClick={(link: string) => {
              setSelectedRating(null);
              history.push(link);
            }}
          />
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default MemberCommunicationDetails;
