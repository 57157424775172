import React from 'react';
import { Colors } from '../../../theme/shared';

type Props = {
  active?: boolean;
};

const GearIcon: React.FC<Props> = ({ active }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2188 13.875L17.875 13.125C18.0312 12.375 18.0312 11.6562 17.875 10.9062L19.2188 10.1562C19.375 10.0625 19.4375 9.875 19.375 9.71875C19.0312 8.59375 18.4375 7.59375 17.6875 6.75C17.5625 6.625 17.375 6.59375 17.2188 6.6875L15.875 7.4375C15.3125 6.96875 14.6875 6.59375 14 6.34375V4.8125C14 4.65625 13.875 4.5 13.6875 4.46875C12.5312 4.1875 11.375 4.21875 10.2812 4.46875C10.0938 4.5 10 4.65625 10 4.8125V6.34375C9.28125 6.59375 8.65625 6.96875 8.09375 7.46875L6.75 6.6875C6.59375 6.59375 6.40625 6.625 6.28125 6.75C5.53125 7.59375 4.9375 8.59375 4.59375 9.71875C4.53125 9.875 4.59375 10.0625 4.75 10.1562L6.09375 10.9062C5.96875 11.6562 5.96875 12.375 6.09375 13.125L4.75 13.875C4.59375 13.9688 4.53125 14.1562 4.59375 14.3125C4.9375 15.4375 5.53125 16.4375 6.28125 17.2812C6.40625 17.4062 6.59375 17.4375 6.75 17.3438L8.09375 16.5938C8.65625 17.0625 9.28125 17.4375 10 17.6875V19.2188C10 19.375 10.125 19.5312 10.2812 19.5938C11.4375 19.8438 12.5938 19.8125 13.6875 19.5938C13.875 19.5312 14 19.375 14 19.2188V17.6875C14.6875 17.4375 15.3125 17.0625 15.875 16.5938L17.2188 17.3438C17.375 17.4375 17.5625 17.4062 17.6875 17.2812C18.4688 16.4375 19.0312 15.4375 19.4062 14.3125C19.4375 14.1562 19.375 13.9688 19.2188 13.875ZM12 14.5C10.5938 14.5 9.5 13.4062 9.5 12C9.5 10.625 10.5938 9.5 12 9.5C13.375 9.5 14.5 10.625 14.5 12C14.5 13.4062 13.375 14.5 12 14.5Z"
      fill={active ? `${Colors.ElectricGreen}` : '#FFFFFF'}
    />
  </svg>
);

export default GearIcon;
