import React from 'react';
import { FieldProps, getIn } from 'formik';
import { TextFieldProps, TextField, MenuItem } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  titleEditor: {
    ...theme.typography.h1,
    paddingLeft: 8,
    border: '2px solid #DADADA !important',
    '&.MuiInputBase-root.Mui-focused': {
      border: '2px solid !important',
      '& .MuiInputBase-input::placeholder': {
        opacity: 0.43,
      },
    },
    '&.MuiInputBase-root.Mui-error': {
      border: `2px solid ${theme.palette.error.main} !important`,
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 1,
    },
  },
}));

export type AppTextFieldProps = {
  titleEditor?: boolean;
};

const AppTextField: React.FC<
  AppTextFieldProps &
    FieldProps &
    TextFieldProps & {
      options?: Array<{
        value: string;
        label: React.ReactNode;
      }>;
    }
> = ({ titleEditor, field, form, error, options, helperText, ...rest }) => {
  const classes = useStyles();
  const isTouched = getIn(form.touched, field.name);
  const errorMessage = getIn(form.errors, field.name);

  const otherProps = {
    ...rest,
    ...(titleEditor
      ? {
          multiline: true,
          InputProps: {
            disableUnderline: true,
            className: classes.titleEditor,
          },
        }
      : {}),
  };

  return (
    <TextField
      variant={titleEditor ? 'standard' : 'outlined'}
      className={classes.root}
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      {...otherProps} // includes any Material-UI specific props
      {...field} // includes all props contributed by the Formik Field/FastField
    >
      {options
        ? options.map((o) => (
            <MenuItem
              key={`option_${o.value}`}
              value={o.value}
              style={{ fontSize: 18 }}>
              {o.label}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
};

export default AppTextField;
