import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { FormControl, MenuItem, Select } from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import React, { useState } from 'react';

type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'paragraph';

const HeadingSize: Record<HeadingType, string> = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  paragraph: 'Normal',
};

type HeadingKey = keyof typeof HeadingSize;

// eslint-disable-next-line import/prefer-default-export
export const HeaderPicker = () => {
  const [headingSize, setHeadingSize] = useState<HeadingKey>('paragraph');
  const [editor] = useLexicalComposerContext();

  const handleChange = (size: HeadingKey) => {
    setHeadingSize(size);

    if (size === 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode());
        }
      });
    } else {
      editor.update(() => {
        const selection = $getSelection();
        $setBlocksType(selection, () => $createHeadingNode(size));
      });
    }
  };

  return (
    <FormControl>
      <Select
        size="small"
        value={headingSize}
        onChange={(e) => {
          handleChange(e.target.value as HeadingKey);
        }}>
        {Object.keys(HeadingSize).map((size) => (
          <MenuItem key={size} value={size}>
            {HeadingSize[size as keyof typeof HeadingSize]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
