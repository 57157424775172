import React from 'react';

const questionsCompletedPersonalEnabled = (
  level: number | undefined,
  size: number,
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="-3.58496"
      y="46.0059"
      width="70.1328"
      height="70.1328"
      rx="24.1264"
      transform="rotate(-45 -3.58496 46.0059)"
      fill="white"
    />
    <path
      d="M15.9385 61.4355C7.41764 52.9146 7.41765 39.0997 15.9385 30.5788L30.5779 15.9394C39.0987 7.41862 52.9137 7.41862 61.4345 15.9395L76.0739 30.5788C84.5947 39.0997 84.5947 52.9146 76.0739 61.4355L61.4345 76.0749C52.9137 84.5957 39.0987 84.5957 30.5779 76.0749L15.9385 61.4355Z"
      fill="url(#paint0_angular_5400_144154)"
    />
    <mask
      id="mask0_5400_144154"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <rect
        x="0.508789"
        y="46.0078"
        width="64.3411"
        height="64.3411"
        rx="22.0012"
        transform="rotate(-45 0.508789 46.0078)"
        fill="url(#paint1_angular_5400_144154)"
      />
    </mask>
    <g mask="url(#mask0_5400_144154)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8679 15.4749C46.4676 15.0462 46.4904 14.3744 46.9188 13.9744L57.5873 4.01303C62.2284 -0.320428 69.5066 -0.0679268 73.8437 4.577C78.1807 9.22193 77.9342 16.5004 73.2931 20.8338L15.1702 75.1038C10.7842 79.199 10.5513 86.0773 14.6499 90.4668C18.7484 94.8564 25.6265 95.095 30.0125 90.9998L90.27 34.7367C90.6983 34.3367 91.3701 34.36 91.7704 34.7888C92.1707 35.2175 92.148 35.8893 91.7196 36.2893L31.4621 92.5523C26.2194 97.4475 17.9978 97.1623 13.0986 91.9153C8.19937 86.6683 8.47783 78.4464 13.7205 73.5512L71.8434 19.2812C75.6278 15.7477 75.8288 9.81294 72.2924 6.02547C68.756 2.238 62.8213 2.03211 59.037 5.5656L48.3684 15.5269C47.94 15.9269 47.2682 15.9036 46.8679 15.4749Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6316 63.6257C2.90039 53.8945 2.90039 38.1172 12.6316 28.386L28.3858 12.6317C38.117 2.90057 53.8943 2.90058 63.6255 12.6318L79.3798 28.386C89.111 38.1172 89.111 53.8945 79.3798 63.6257L63.6255 79.38C53.8943 89.1111 38.117 89.1111 28.3858 79.38L12.6316 63.6257ZM14.0341 29.7886C5.07759 38.7451 5.07758 53.2666 14.0341 62.2231L29.7884 77.9774C38.745 86.9339 53.2664 86.934 62.2229 77.9774L77.9772 62.2231C86.9338 53.2666 86.9338 38.7451 77.9772 29.7886L62.2229 14.0343C53.2664 5.07777 38.745 5.07777 29.7884 14.0343L14.0341 29.7886Z"
      fill="#022131"
    />
    <ellipse cx="61.7515" cy="13.584" rx="2.6031" ry="2.78904" fill="white" />
    <path
      d="M62.048 20.9824V26.6432L62.8624 27.8331C63.0104 28.0855 63.4176 28.0855 63.5656 27.8331L64.417 26.6432V20.9824C64.0098 21.0546 63.6027 21.0906 63.2325 21.0906C62.8253 21.0906 62.4182 21.0546 62.048 20.9824ZM63.2325 9.55273C60.2713 9.55273 57.9023 11.8964 57.9023 14.7448C57.9023 17.6293 60.2713 19.9368 63.2325 19.9368C66.1567 19.9368 68.5627 17.6293 68.5627 14.7448C68.5627 11.8964 66.1567 9.55273 63.2325 9.55273ZM63.2325 12.293C61.8259 12.293 60.7155 13.4107 60.7155 14.7448C60.7155 14.9972 60.4934 15.1775 60.2713 15.1775C60.0122 15.1775 59.8271 14.9972 59.8271 14.7448C59.8271 12.942 61.3447 11.4276 63.2325 11.4276C63.4546 11.4276 63.6767 11.644 63.6767 11.8603C63.6767 12.1127 63.4546 12.293 63.2325 12.293Z"
      fill="#2D63F9"
    />
    <path
      d="M19.5986 66.4327V36.0014C19.5986 35.5927 19.8538 35.2279 20.2368 35.0891L36.994 29.0156L54.3893 35.3204L70.49 29.4849C71.1199 29.2565 71.7846 29.725 71.7846 30.3972V60.8284C71.7846 61.2371 71.5294 61.6019 71.1464 61.7407L54.3893 67.8142L36.994 61.5095L20.8933 67.345C20.2634 67.5733 19.5986 67.1049 19.5986 66.4327Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9853 28.0234L54.3969 34.316L70.184 28.6105C71.4451 28.1548 72.7757 29.0898 72.7757 30.4316V60.8039C72.7757 61.6196 72.2649 62.3479 71.4982 62.6249L54.3969 68.8054L36.9853 62.5128L21.1981 68.2183C19.9371 68.674 18.6064 67.739 18.6064 66.3972V36.0249C18.6064 35.2092 19.1173 34.4809 19.884 34.2039L36.9853 28.0234ZM37.9526 60.8039L53.4296 66.3972V36.0249L37.9526 30.4316V60.8039ZM36.018 30.4316V60.8039L20.5411 66.3972V36.0249L36.018 30.4316ZM55.3642 36.0249V66.3972L70.8411 60.8039V30.4316L55.3642 36.0249Z"
      fill="#022131"
    />
    <path
      d="M8.69089 65.0855L27.2134 57.0755C27.9516 56.7563 28.686 57.5325 28.3265 58.252L19.4139 76.0845C19.013 76.8867 17.8061 76.6194 17.7813 75.723L17.576 68.3002C17.5639 67.8644 17.2284 67.5063 16.7944 67.4658L8.953 66.7348C8.06472 66.652 7.87204 65.4396 8.69089 65.0855Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.872 56.2853C28.3484 55.6468 29.8173 57.1994 29.0982 58.6382L20.1856 76.4708C19.3838 78.075 16.9701 77.5405 16.9205 75.7478L16.7152 68.3249L8.87377 67.5939C7.09721 67.4283 6.71185 65.0035 8.34955 64.2953L26.872 56.2853ZM27.5563 57.8675L9.03378 65.8775L16.8752 66.6085C17.7432 66.6894 18.4142 67.4057 18.4383 68.2772L18.6437 75.7001L27.5563 57.8675Z"
      fill="#022131"
    />
    {level ? (
      <g clipPath="url(#clip0_5400_144154)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#2EF699" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#022131"
        />
        <text
          x="74"
          y="77.5"
          fill="#022131"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_5400_144154"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1973 46.0029) rotate(-8.79121) scale(53.9414 54.8175)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_5400_144154"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(27.1608 72.6537) rotate(36.2088) scale(53.9414 54.8175)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_5400_144154">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default questionsCompletedPersonalEnabled;
