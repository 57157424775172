import React, { useState } from 'react';

export const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);

  return {
    anchorEl,
    setAnchorEl,
    onMenuClick,
    isMenuOpen,
  };
};

export default useMenuAnchor;
