import { Box, Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import ImageUtils from '../../../utils/imageUtils';
import FileUpload, { FileData } from '../../common/FileUpload/FileUpload';

const useStyles = makeStyles(() => ({
  dialog: {
    width: 600,
    padding: 40,
  },
}));

type Props = {
  onSave: (file: FileData) => void;
};

const AddPrimaryProjectImageModal: React.FC<Props> = ({ onSave }) => {
  const [file, setFile] = useState<FileData>();

  const classes = useStyles();
  return (
    <Box className={classes.dialog}>
      <Typography align="center" variant="h1">
        Add Primary Project Image
      </Typography>
      <Grid container direction="column" alignItems="stretch">
        <div style={{ margin: '40px 0' }}>
          <FileUpload
            accept={ImageUtils.SupportedFileExtensions}
            showFiles
            onFileChange={(f) => setFile(f.length ? f[0] : undefined)}
          />
        </div>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => onSave(file as FileData)}
            disabled={!file}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddPrimaryProjectImageModal;
