import React, { useContext } from 'react';
import { Grid, Typography, Box, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ProjectContext,
  ProjectDetailsStep,
} from '../../../contexts/ProjectContext';
import EditProjectStepModal from './EditProjectStepModal';

const useStyles = makeStyles(() => ({
  divider: {
    margin: '40px 0',
  },
}));

const ProjectAdditionalImages: React.FC = () => {
  const classes = useStyles();
  const { project } = useContext(ProjectContext);

  return (
    <>
      <Divider className={classes.divider} />
      <Grid item container direction="row" justifyContent="space-between">
        <Typography variant="h3" color="textPrimary" gutterBottom>
          Additional Images
        </Typography>
        <EditProjectStepModal step={ProjectDetailsStep.images} />
      </Grid>
      <Grid container direction="row" spacing={2} style={{ marginTop: 8 }}>
        {!project.additionalScaledImageUris?.length && (
          <Grid item xs={12}>
            <Typography align="center">
              No additional images have been uploaded for this project.
            </Typography>
          </Grid>
        )}
        {(project.additionalScaledImageUris || []).map((image) => (
          <Grid item key={image.medium} xs={3}>
            <Box>
              <img
                src={image.medium}
                alt="Icon"
                style={{
                  height: 100,
                  width: '100%',
                  borderRadius: 16,
                  objectFit: 'cover',
                  backgroundColor: '#F1F8FD',
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProjectAdditionalImages;
