import { useQuery } from '@apollo/client';
import { Grid, IconButton } from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import { Project } from '../../../types/project/types';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';
import ProjectCompletionStatus from '../../common/Project/ProjectCompletionStatus';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import RoundedFlexBox from '../RoundedFlexBox';
import SVG from '../../../assets/svg';
import DeleteProjectModal from './DeleteProjectModal';

type Props = {
  projects: Project[];
  refetch: () => void;
};

const ProjectDrafts: React.FC<Props> = ({ projects, refetch }) => {
  const history = useHistory();
  const [projectToDelete, setProjectToDelete] = useState<Project | undefined>();

  const {
    loading: categoriesLoading,
    error: categoriesError,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const dateFormat = 'M/d/yy';

  const tableStructure: GroupableTableStructure<Project>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'category',
          display: 'Category',
          searchable: false,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          resolveFilterLabel: (value) => (
            <Grid container direction="row" wrap="nowrap" spacing={1}>
              <Grid item>
                <CategoryIconSmall categoryId={value as string} />
              </Grid>
              <Grid item>
                {projectCategories.find((cat) => cat.id === value)?.label || ''}
              </Grid>
            </Grid>
          ),
          render: ({ category }) => <CategoryIconSmall categoryId={category} />,
        },
        {
          key: 'projectId',
          display: 'ID',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Project IDs',
        },
        {
          key: 'title',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Projects',
        },
        {
          key: 'completedSteps',
          display: 'Completion Status',
          render: (p) => (
            <ProjectCompletionStatus
              projectId={p.id}
              completedSteps={p.completedSteps as [number]}
              possibleSteps={7}
            />
          ),
        },
        {
          key: 'createdDate',
          type: AlphaColumnFilter,
          display: 'Created On',
          format: (createdDate) =>
            createdDate
              ? format(new Date(createdDate as string), dateFormat)
              : '',
          resolveFilterLabel: (value) =>
            value ? format(new Date(value as string), dateFormat) : '',
        },
        {
          key: 'delete',
          render: (data) => (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setProjectToDelete(data);
                return false;
              }}
              size="large">
              <SVG.Delete />
            </IconButton>
          ),
        },
        {
          key: 'id',
          render: (p) => (
            <SmallPaddedButton
              variant="outlined"
              onClick={() => history.push(`/trader/create-project/${p.id}`)}>
              Continue
            </SmallPaddedButton>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <DataStateHandler loading={categoriesLoading} error={categoriesError}>
        <Grid
          container
          direction="column"
          style={{ height: 'calc(100vh - 190px)', position: 'relative' }}>
          <RoundedFlexBox>
            <GroupableSelectTable<Project>
              tableData={projects}
              tableStructure={tableStructure}
              hideStickyCol
              onRowClick={(r) => {
                history.push(`/trader/create-project/${r.id}`);
              }}
            />
          </RoundedFlexBox>
        </Grid>
      </DataStateHandler>
      {projectToDelete ? (
        <DeleteProjectModal
          refetch={refetch}
          onCancel={() => setProjectToDelete(undefined)}
          onConfirm={() => setProjectToDelete(undefined)}
          projectId={projectToDelete.id}
        />
      ) : null}
    </>
  );
};
export default ProjectDrafts;
