import React from 'react';
import { Grid, Switch, Typography } from '@mui/material';
import { t } from 'i18next';
import { Field } from 'formik';

import CategoryIdSelect from './CategoryIdSelect';
import HeadingInput from './HeadingInput';
import PublishFields from './PublishFields';
import RelatedArticlesAndHabits from './RelatedArticlesAndHabits';
import { CommunicationInput } from '../types';
import AppTextField from '../../common/AppTextField';
import { AppRichTextField } from '../../common/AppRichTextField';
import ImageUpload from '../../common/ImageUpload';
import useTypedFormikContext from '../../../hooks/useTypedFormikContext';
import { Disableable } from '../../../types/common';

const HintsAndTipsForm: React.FC<Disableable> = ({ disabled }) => {
  const { setTypedFieldValue, values, initialValues } =
    useTypedFormikContext<CommunicationInput>();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="body2">
              {t('communication.featuredProject')}
            </Typography>
          </Grid>
          <Grid item>
            <Switch
              checked={values.isFeatured || false}
              onChange={(e, checked) =>
                setTypedFieldValue('isFeatured', checked)
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CategoryIdSelect disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <HeadingInput disabled={disabled} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{t('author')}</Typography>
        <Field
          name="author"
          disabled={disabled}
          label=""
          component={AppTextField}
          placeholder="Author Name"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <ImageUpload
          disabled={disabled}
          value={values.headerImageUrl || ''}
          onChange={(file) => setTypedFieldValue('headerImage', file)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">{t('description')}</Typography>
        <Field
          name="content"
          component={AppRichTextField}
          initialValue={initialValues.content}
          disabled={disabled}
        />
      </Grid>
      <RelatedArticlesAndHabits disabled={disabled} />
      <Grid item xs={12}>
        <PublishFields disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default HintsAndTipsForm;
