import React from 'react';
import { ReactSVG } from 'react-svg';

import { Chip } from '@mui/material';
import { HabitCategory } from '../../../types/habit/types';

type Props = {
  category: HabitCategory;
};

const HabitCategoryIcon: React.FC<Props> = ({ category }) => (
  <Chip
    size="small"
    style={{
      backgroundColor: category.secondaryColor,
      color: category.primaryColor,
      height: 24,
      width: 24,
      padding: 4,
    }}
    icon={
      <div style={{ marginTop: 4, marginLeft: 12 }}>
        <ReactSVG
          src={category.fillIconUrl}
          beforeInjection={(svg) => {
            svg.setAttribute(
              'style',
              `color: ${category.primaryColor}; fill: ${category.primaryColor}`,
            );
            svg.setAttribute('height', '16');
            svg.setAttribute('width', '16');
          }}
        />
      </div>
    }
  />
);

export default HabitCategoryIcon;
