import { ZeroMeScore } from './dashboard/types';

export type ISOCountry = {
  code: string;
  name: string;
  region: string;
  subregion: string;
};

export type ISORegion = {
  country: string;
  code: string;
  name: string;
};

export type FileAttachment = {
  file?: File;
  publicUrl?: string;
};

export type Disableable = {
  disabled?: boolean;
};

export enum LocalStorageKeys {
  policyId = 'policyId',
  redirectAfterLoginUrl = 'redirectAfterLoginUrl',
}

export type ImageSize = 'large' | 'medium' | 'thumb';

/**
 * ActionMap is a type that takes a map of types and returns a union of action objects with the same
 * keys as the map, but with the values of the map as the payloads of the action objects.
 * Its primary use is for defining the valid input for reducer dispatch methods.
 * @example
 * type StatePayload = {
 *   "MY_NUMBER_ACTION": number;
 *   "MY_STRING_ACTION": string;
 * }
 * type Actions = ActionMap<StatePayload>[keyof ActionMap<StatePayload>];
 * const reducer = (state: State, action: Actions) => {
 *   switch (action.type) {
 *     // define cases based on actions.
 *     // action.payload will be typed based on action.type.
 *     case "MY_NUMBER_ACTION":
 *       // typeof action.payload === number;
 *       break;
 *     case "MY_STRING_ACTION":
 *       // typeof action.payload === string;
 *       break;
 *   }
 * }
 */
export type ActionMap<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export type ZeroMeScoreFactor = {
  id: string;
  label: string;
  description: string;
  logoUri: string;
  scoreProperty: keyof ZeroMeScore;
};

export type ContactTraderType =
  | 'GENERAL'
  | 'PROJECT_INTEREST'
  | 'PURCHASE_CANCEL'
  | 'PURCHASE_UPDATE';

export type Avatar = {
  metadataId?: string;
  components: {
    componentId: string;
    optionId: string;
    color?: string;
  }[];
};

export type AvatarMetadataComponentOption = {
  id: string;
  title: string;
  svg: string;
  style: Record<string, string>;
};

export type AvatarMetadataComponent = {
  id: string;
  title: string;
  allowNone?: boolean;
  options: AvatarMetadataComponentOption[];
  style: Record<string, string>;
  colors?: string[];
  sameColorAs: string;
};

export type AvatarMetadata = {
  id: string;
  title: string;
  exclusiveComponents: string[][];
  components: AvatarMetadataComponent[];
};

export type LanguageKey = {
  country?: string;
  language: string;
  text: string;
};

export type ChangeLanguageKeysToString<T> = {
  [key in keyof T]: T[key] extends LanguageKey[] ? string : T[key];
};
