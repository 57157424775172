import React, { useContext } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';

import CommunicationCard from './CommunicationCard';
import MemberHabitCard from '../myhabits/MemberHabitCard';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { MemberCommunicationListItem } from '../../communications/types';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { ME } from '../../../graphql/auth';
import {
  GET_DISCOVER_COMMUNICATION_DETAILS,
  GET_DISCOVER_COMMUNICATIONS,
} from '../../../graphql/communications/memberCommunications';
import { ADD_HABIT, REMOVE_HABIT } from '../../../graphql/habit/memberHabits';
import useRemoveQuestionRelatedData from '../../../hooks/cacheModifiers/useRemoveQuestionRelatedData';
import useCheckForPointAwards from '../../../hooks/useCheckForPointAwards';
import { MemberHabit } from '../../../types/habit/types';

type Props = {
  relatedArticles?: MemberCommunicationListItem[];
  relatedHabits?: MemberHabit[];
  onClick: (id: string) => void;
};

const RelatedContent: React.FC<Props> = ({
  relatedArticles,
  relatedHabits,
  onClick,
}) => {
  const theme = useTheme();
  const { user, company } = useContext(AuthorizationContext);
  const lessThanLg = useMediaQuery(theme.breakpoints.down('lg'));
  const { checkForPointAwards } = useCheckForPointAwards();
  const displayRelatedArticles = !!relatedArticles?.length;

  const { remove: removeQuestionRelatedData } = useRemoveQuestionRelatedData({
    ignoreHabits: true,
  });
  const [addHabit, { loading: loadingAddHabit, error: errorAddHabit }] =
    useMutation(ADD_HABIT);
  const [
    removeHabit,
    { loading: loadingRemoveHabit, error: errorRemoveHabit },
  ] = useMutation(REMOVE_HABIT);

  // current requirements is to match these to what is on the browse
  // section on the right hand side, so we execute the same query here.
  // Assumption is most users will come from browse so this will be cached
  const {
    data: { communications: { items: featuredArticles = [] } = {} } = {},
    loading: loadingFeaturedArticles,
    error: errorFeaturedArticles,
  } = useQuery(GET_DISCOVER_COMMUNICATIONS, {
    variables: {
      companyId: company?.id,
    },
    skip: !company || displayRelatedArticles,
  });

  const articles = displayRelatedArticles
    ? relatedArticles
    : featuredArticles.filter((i) => !i.isFeatured).slice(4, 8);

  const addedHabitIds = user?.lifestyleHabits?.map((h) => h.id);

  const handleHabitAction = async (id: string, added = false) => {
    const mutation = added ? removeHabit : addHabit;
    await mutation({
      variables: { habitId: id },
      refetchQueries: [ME, GET_DISCOVER_COMMUNICATION_DETAILS],
      awaitRefetchQueries: true,
      onCompleted: async () => {
        await removeQuestionRelatedData();
        await checkForPointAwards();
      },
    });
  };

  return (
    <Grid container direction="column">
      {!!articles?.length && (
        <DataStateHandler
          loading={loadingFeaturedArticles}
          error={errorFeaturedArticles}>
          <Grid container item>
            <Typography variant="h3">
              {displayRelatedArticles
                ? t('communication.relatedArticles')
                : t('communication.featuredArticles')}
            </Typography>
            <Grid container item spacing={2}>
              {articles.map((article) => (
                <Grid item lg={3} sm={6} xs={12}>
                  <CommunicationCard
                    key={article.id}
                    communication={article}
                    imageHeight={lessThanLg ? 234 : 112}
                    onClick={(id: string) => onClick(`/member/discover/${id}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DataStateHandler>
      )}
      {!!relatedHabits?.length && (
        <DataStateHandler
          loading={loadingAddHabit || loadingRemoveHabit}
          error={errorAddHabit || errorRemoveHabit}>
          <Grid container item>
            <Typography variant="h3">
              {t('communication.relatedHabits')}
            </Typography>
            <Grid container item spacing={2}>
              {relatedHabits.map((habit) => {
                const added = addedHabitIds?.includes(habit.id);

                return (
                  <Grid item lg={3} sm={6} xs={12}>
                    <MemberHabitCard
                      key={habit.id}
                      cardType={added ? 'LIFESTYLE' : 'HABIT'}
                      habit={habit}
                      imageHeight={lessThanLg ? 234 : 112}
                      size="sm"
                      onClick={(id: string) =>
                        onClick(`/member/myhabits/${id}`)
                      }
                      onAction={(id) => handleHabitAction(id, added)}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </DataStateHandler>
      )}
    </Grid>
  );
};

export default RelatedContent;
