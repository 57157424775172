import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes } from 'lexical';
import { useEffect } from 'react';

type Props = {
  initialState: string | undefined;
  initialContent: string | undefined;
};

// eslint-disable-next-line import/prefer-default-export
export const ImportPlugin = (props: Props) => {
  const { initialState, initialContent } = props;
  const [editor] = useLexicalComposerContext();

  const applyInitialContent = () => {
    if (initialContent) {
      const parser = new DOMParser();
      const dom = parser.parseFromString(initialContent, 'text/html');
      const nodes = $generateNodesFromDOM(editor, dom);

      $getRoot().selectStart();

      $insertNodes(nodes);
    }
  };

  useEffect(() => {
    editor.update(() => {
      try {
        if (initialState) {
          const state = editor.parseEditorState(JSON.parse(initialState));
          editor.setEditorState(state);
        } else if (initialContent) {
          applyInitialContent();
        }
      } catch (e) {
        applyInitialContent();
      }
    });
    // Only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
