import React, { useState } from 'react';
import { FieldProps } from 'formik';
import moment from 'moment';
import { MenuItem } from '@mui/material';
import SelectMenu from './SelectMenu/SelectMenu';

type Props = FieldProps<string | undefined> & {
  disabled?: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const AppDayPicker = ({ field, form, disabled }: Props) => {
  const getDaysInMonth = (val: number | undefined) =>
    // We just care about the number of days in the month, so we can use any non-leap year or day.
    val ? moment(`${val}-01-2010`).daysInMonth() : undefined;

  const [month, setMonth] = useState<number | undefined>(
    field.value ? Number.parseInt(field.value.slice(0, 2), 10) : undefined,
  );
  const [day, setDay] = useState<number | undefined>(
    field.value ? Number.parseInt(field.value.slice(3), 10) : undefined,
  );
  const [daysInMonth, setDaysInMonth] = useState<number | undefined>(
    getDaysInMonth(month),
  );

  const months = moment.months().map((currMonth, idx) => ({
    value: currMonth,
    key: idx + 1,
  }));

  const days = Array.from({ length: daysInMonth ?? 0 }, (_, i) => i + 1).map(
    (currDay) => ({
      value: currDay,
      key: currDay,
    }),
  );

  const handleChange = (value: number | undefined, type: 'month' | 'day') => {
    const newMonth = type === 'month' ? value : month;
    let newDay = type === 'day' ? value : day;

    const currDaysInMonth = getDaysInMonth(newMonth);
    if ((currDaysInMonth ?? 0) < (newDay ?? 0)) {
      newDay = currDaysInMonth;
    }

    if (currDaysInMonth !== daysInMonth) {
      setDaysInMonth(currDaysInMonth);
    }

    if (newMonth && newDay) {
      form.setFieldValue(
        field.name,
        `${newMonth.toString().padStart(2, '0')}-${newDay
          .toString()
          .padStart(2, '0')}`,
      );
    }

    setMonth(newMonth);
    setDay(newDay);
  };

  return (
    <>
      <SelectMenu
        disabled={disabled}
        testId={`${field.name}-month-select`}
        key="month"
        id="month"
        onChange={(evt) => {
          handleChange(Number(evt.target.value), 'month');
        }}
        inputStyle={{
          height: 42,
          minWidth: 75,
        }}
        value={month}>
        {months.map((item) => (
          <MenuItem value={item.key} key={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </SelectMenu>
      <SelectMenu
        disabled={disabled}
        testId={`${field.name}-day-select`}
        key="day"
        id="day"
        onChange={(evt) => {
          handleChange(Number(evt.target.value), 'day');
        }}
        inputStyle={{
          height: 42,
          minWidth: 75,
        }}
        value={day}>
        {days.map((item) => (
          <MenuItem value={item.key} key={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </SelectMenu>
    </>
  );
};
