import { useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ReactSVG } from 'react-svg';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import DataStateHandler from '../DataStateHandler/DataStateHandler';

const useStyles = makeStyles(() => ({
  category: {
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

type Props = {
  categoryId?: string;
  useCurrentColor?: boolean;
};

const CategoryIconSmall: React.FC<Props> = ({
  categoryId,
  useCurrentColor,
}) => {
  const classes = useStyles();

  const { loading, data: { projectCategories = [] } = {} } =
    useQuery(GET_ALL_CATEGORIES);

  const category = projectCategories.find((x) => x.id === categoryId);

  return (
    <DataStateHandler loading={loading}>
      {category ? (
        <ReactSVG
          src={category.iconUri}
          className={classes.category}
          style={{ color: useCurrentColor ? undefined : category.color }}
          wrapper="span"
          beforeInjection={(svg) => {
            svg.setAttribute('fill', 'currentColor');
          }}
        />
      ) : (
        <div />
      )}
    </DataStateHandler>
  );
};

export default CategoryIconSmall;
