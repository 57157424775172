import React, { useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { Divider, Grid } from '@mui/material';
import { useQuery } from '@apollo/client';
import { DashboardContext } from '../../../../contexts/DashboardContext';

import AppCard from '../../../common/AppCard/AppCard';
import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import CardFooter from '../../../common/CardFooter/CardFooter';
import NumberUtils from '../../../../utils/numberUtils';
import { chartColors } from '../../../../theme/theme';
import TooltipContainer from '../../../common/ChartTooltips/TooltipContainer';
import ChartLabel from '../../../common/ChartLabel/ChartLabel';
import {
  dateRangeFromEndDate,
  formatDateStringUtc,
} from '../../../../utils/dateUtils';
import { AVERAGE_EMPLOYEE_FOOTPRINT } from '../../../../graphql/dashboard/emissions/averageEmployeeFootprint';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';

const AverageEmployeeWaterFootprint: React.FC = () => {
  const { companyId, includeChildren } = useCurrentCompany();
  const { endDate } = useContext(DashboardContext);
  const {
    data: {
      averageEmployeeFootprintByDateRange: { footprintsByRange = [] } = {},
    } = {},
    loading,
    error,
  } = useQuery(AVERAGE_EMPLOYEE_FOOTPRINT, {
    variables: {
      dateGrain: 'MONTHLY',
      companyId,
      includeChildren,
      ...dateRangeFromEndDate(endDate),
    },
  });

  const lineData = [
    {
      id: 'Average Employee',
      data: footprintsByRange.map((fp) => ({
        x: formatDateStringUtc(fp.date, 'MMM, yyyy'),
        y: fp.waterValue,
      })),
    },
  ];

  const filtered = lineData[0].data.filter((o) => o.y > 0);

  const avg =
    filtered.length > 0
      ? filtered.reduce((acc, cur) => acc + cur.y, 0) / filtered.length
      : 0;
  return (
    <AppCard
      height={460}
      headerProps={{
        title: 'EMPLOYEE AVERAGE WATER FOOTPRINT',
        tooltipId: 'impactEmployeeWaterFootprint',
      }}>
      <DataStateHandler loading={loading} error={error}>
        <>
          <div style={{ height: 300 }}>
            <ResponsiveLine
              margin={{ top: 20, right: 30, bottom: 50, left: 50 }}
              animate
              data={lineData}
              colors={[chartColors.blue]}
              enablePoints={false}
              enableGridX={false}
              gridYValues={4}
              enableArea
              lineWidth={3}
              curve="natural"
              axisLeft={{
                format: (value) =>
                  `${Intl.NumberFormat('en-US', {
                    notation: 'compact',
                    maximumFractionDigits: 0,
                  }).format(value)}`,
                tickValues: 4,
                tickSize: 0,
                tickPadding: 16,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
              }}
              sliceTooltip={(props) => (
                <TooltipContainer>
                  <>
                    <ChartLabel
                      labelColor="#6B6B6B"
                      value=""
                      label={props.slice.points[0].data.xFormatted as string}
                    />
                    <Divider />
                    <ChartLabel
                      labelColor="#6B6B6B"
                      color={chartColors.blue}
                      label="Average Employee"
                      value={`${Intl.NumberFormat('en-US', {
                        notation: 'compact',
                        maximumFractionDigits: 0,
                      }).format(
                        props.slice.points[0].data.yFormatted as number,
                      )} L`}
                    />
                  </>
                </TooltipContainer>
              )}
              axisBottom={{ tickSize: 0, tickPadding: 24 }}
              enableSlices="x"
            />
          </div>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end">
            <Grid item>
              <CardFooter
                data={NumberUtils.format(avg, 'integer')}
                label="Liters"
              />
            </Grid>
          </Grid>
        </>
      </DataStateHandler>
    </AppCard>
  );
};

export default AverageEmployeeWaterFootprint;
