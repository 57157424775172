import { gql, TypedDocumentNode } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_USER_MARK_ACTIVITIES_READ = gql`
  mutation UPDATE_USER_MARK_ACTIVITIES_READ(
    $activityItemIds: [String]!
    $readAt: String
  ) {
    markUserActivitiesRead(activityItemIds: $activityItemIds, readAt: $readAt) {
      id
      readAt
    }
  }
` as TypedDocumentNode<boolean, { activityItemIds: string[]; readAt?: string }>;
