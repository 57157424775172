import * as yup from 'yup';
import { SchemaObjectDescription } from 'yup/lib/schema';
import {
  optionalNumber,
  startDateBeforeEndDateTest,
  validPostalCodeTest,
} from '../../../utils/yupTests';

const createProjectSchema = {
  titleSchema: yup.object().shape({
    title: yup.string().trim().required('Required'),
    category: yup.string().required('Please select a Category'),
  }),
  descriptionSchema: yup.object().shape({
    description: yup.string().required('Required'),
    smallSummary: yup.string().required('Required'),
  }),
  locationSchema: yup.object().shape({
    country: yup.string().required('Required'),
    postalCode: yup.string().test(validPostalCodeTest()),
    address: yup.string(),
    place: yup.string(),
    region: yup.string().nullable(),
  }),
  additionalDetailsSchema: yup.object().shape({
    isFeatured: yup.boolean(),
    isDemoProject: yup.boolean(),
    sponsor: yup.string(),
    startDate: yup.date().nullable().test(startDateBeforeEndDateTest()),
    endDate: yup.date().nullable().test(startDateBeforeEndDateTest()),
    costPerUnit: optionalNumber.when('isIndicative', {
      is: (isIndicative: boolean | undefined) => !isIndicative,
      then: yup
        .number()
        .positive()
        .required('Required')
        .test(
          'is-decimal',
          'Please enter a maximum of 2 decimal points',
          (value) =>
            !!(value?.toString() || '').match(/^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/),
        ),
    }),

    availableQuantity: optionalNumber.when('isIndicative', {
      is: (isIndicative: boolean | undefined) => !isIndicative,
      then: yup.number().integer().required(),
    }),
    sellingPrice: optionalNumber.when('isIndicative', {
      is: (isIndicative: boolean | undefined) => !isIndicative,
      then: yup
        .number()
        .positive()
        .required('Required')
        .test(
          'is-decimal',
          'Please enter a maximum of 2 decimal points',
          (value) =>
            !!(value?.toString() || '').match(/^\s*-?[1-9]\d*(\.\d{1,2})?\s*$/),
        ),
    }),
    uom: yup.string().required('Required'),
    source: yup.string().required('Required'),
    projectId: yup.string().required('Required'),
    vintage: yup.string().required('Required'),
    indicator: yup.string().required('Required'),
    auditor: yup.string(),
    sdVista: yup.string(),
    projectHQ: yup.string(),
  }),
  auditInformationSchema: yup.object().shape({
    verAuditReportDate: yup.date().nullable(),
    ccbSdgAuditor: yup.string(),
    ccbSdgReportDate: yup.date().nullable(),
  }),
};

export default createProjectSchema;

const requiredFields = Object.values(createProjectSchema).reduce(
  (allRequiredFields, currSchema) => {
    const { fields } = currSchema.describe();

    return [
      ...allRequiredFields,
      ...Object.keys(fields).reduce(
        (prevField, currField) =>
          (fields[currField] as SchemaObjectDescription).tests.findIndex(
            ({ name }) => name === 'required',
          ) > -1
            ? [...prevField, currField]
            : prevField,
        [] as string[],
      ),
    ];
  },
  [] as string[],
);
/**
 * Returns true if the field is marked as required in the create project schema.
 * @param {string} field - The name of the field to check.
 */
export const isFieldRequired = (field: string) =>
  requiredFields.includes(field);
