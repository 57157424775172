import React, { useContext } from 'react';
import { Grid, List, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';

import LeaderboardRow from './LeaderboardRow';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { Leaderboard } from '../../../types/contest/types';

const useStyles = makeStyles(() => ({
  list: {
    width: '100%',
    overflow: 'auto',
  },
  header: {
    height: 30,
    textTransform: 'uppercase',
    marginLeft: 16,
    marginRight: 16,
  },
}));

type Props = {
  leaderboard?: Leaderboard;
  currentUserPoints?: number;
  onCurrentUserClick?: () => void;
  alwaysShowCurrentUser?: boolean;
  maxHeight?: number;
};

const LeaderboardTable: React.FC<Props> = ({
  leaderboard,
  currentUserPoints = 0,
  onCurrentUserClick,
  alwaysShowCurrentUser = true,
  maxHeight = 600,
}) => {
  const classes = useStyles();
  const { user: currentUser, company } = useContext(AuthorizationContext);

  const currentUserRanking = leaderboard?.contestRanking?.find(
    (cr) => cr.userId === currentUser?.id,
  );

  return (
    <Grid container item>
      <Grid container item className={classes.header}>
        <Grid container item alignItems="center" justifyContent="center" xs={1}>
          <Typography variant="subtitle1" align="center">
            {t('place')}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" xs={10}>
          <Typography variant="subtitle1">{t('name')}</Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="flex-end"
          xs={1}>
          <Typography variant="subtitle1" align="right">
            {t('points')}
          </Typography>
        </Grid>
      </Grid>
      <List className={classes.list} style={{ maxHeight }}>
        {leaderboard?.contestRanking?.map((item) => (
          <LeaderboardRow
            key={item.user.id}
            item={item}
            onCurrentUserClick={onCurrentUserClick}
          />
        ))}
        {alwaysShowCurrentUser && !currentUserRanking && currentUser && (
          // If opted out or rank is unavailable, show current user at the bottom
          <LeaderboardRow
            key={currentUser.id}
            item={{
              userId: currentUser.id,
              user: { ...currentUser, companyId: company.id },
              contestTotal: currentUserPoints,
              rank: -1,
            }}
            onCurrentUserClick={onCurrentUserClick}
          />
        )}
      </List>
    </Grid>
  );
};

export default LeaderboardTable;
