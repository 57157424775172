import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import SVG from '../../assets/svg';

const useStyles = makeStyles((theme) => ({
  value: {
    color: theme.palette.buttons.primary.active,
  },
  delta: {
    padding: 2,
  },
  positive: {
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
  negative: {
    background: theme.palette.error.light,
    color: theme.palette.error.dark,
  },
}));

type Props = {
  value: number;
};

const Delta: React.FC<Props> = ({ value }) => {
  const classes = useStyles();
  return (
    <Grid item>
      <span
        className={`${classes.delta} ${
          value > 0 ? `${classes.positive}` : `${classes.negative}`
        }`}>
        {value > 0 ? <SVG.ChartUp /> : <SVG.ChartDown />}{' '}
        {new Intl.NumberFormat('en-us', {
          maximumFractionDigits: 2,
          style: 'percent',
        }).format(value)}{' '}
        MoM
      </span>
    </Grid>
  );
};

export default Delta;
