import React from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import moment from 'moment';

import BadgeCard from './BadgeCard';
import BadgeIcon from '../../../assets/svg/badges';
import { Colors } from '../../../theme/shared';
import { Badge } from '../../../types/badge/types';
import { getPreviousNumBadges } from './badgeUtils';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.TertiaryText,
    marginBottom: 24,
  },
  date: {
    textAlign: 'center',
    color: '#A9A9A9',
    margin: 16,
  },
  levelUpContainer: {
    backgroundColor: theme.palette.primary.light,
    marginTop: 60,
    padding: 28,
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

type Props = {
  badge: Badge;
};

const BadgeDetails: React.FC<Props> = ({ badge }) => {
  const classes = useStyles();

  if (!badge) {
    return (
      <Grid container justifyContent="center">
        <Typography variant="h3">{t('badge.notFound')}</Typography>
      </Grid>
    );
  }

  const {
    badgeId,
    description,
    earnedOn,
    level,
    levelUpTitle,
    levelUpSubtitle,
    title,
  } = badge;
  const nextBadge = { ...badge, level: (level || 0) + 1, earnedOn: undefined };

  return (
    <Grid container spacing={10} rowGap={5}>
      <Grid container item direction="column" alignItems="center" xs={7}>
        <Typography className={classes.title} variant="h1">
          {title}
        </Typography>
        {earnedOn && (
          <Typography className={classes.date} variant="subtitle2">
            {t('badge.dateEarned', {
              date: moment(earnedOn).format('M/D/YYYY'),
            })}
          </Typography>
        )}
        <Typography className={classes.subtitle} variant="body1">
          {description}
        </Typography>
        {level && levelUpTitle && (
          <Grid container item className={classes.levelUpContainer}>
            <Typography className={classes.title} variant="h3">
              {levelUpTitle}
            </Typography>
            <Typography className={classes.subtitle} variant="body1">
              {levelUpSubtitle}
            </Typography>
            <BadgeCard badge={nextBadge} />
          </Grid>
        )}
      </Grid>
      <Grid container item xs={5}>
        <BadgeIcon
          badgeId={badgeId}
          level={level}
          disabled={!earnedOn}
          size={400}
        />
      </Grid>

      {level && (
        <Grid
          container
          flexDirection="row"
          // columnGap={2}
          rowGap={5}
          justifyContent="center">
          {getPreviousNumBadges(10, {
            ...badge,
            // Automatically set the earnedOn date to the current date
            // so if someone clicks on a unearned badge all the previously
            // earned badges will still show up filled in
            earnedOn: new Date().toISOString(),
          }).map((b) => (
            <Grid item xs={12 / 5}>
              <BadgeCard badge={b} />
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default BadgeDetails;
