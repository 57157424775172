import React, { useContext } from 'react';
import { OffsetContext } from '../../contexts/OffsetContext';
import AllocationAppliedDialog from './AllocationAppliedDialog';
import Allocations from './Allocations';
import Assign from './assign/Assign';

const Dialog = () => {
  const {
    offsetTotal,
    allocationInProgress,
    createAllocationsSuccess,
    createAllocationsError,
    reset,
  } = useContext(OffsetContext);

  return (
    <AllocationAppliedDialog
      amount={offsetTotal}
      onClose={reset}
      open={allocationInProgress}
      error={!!createAllocationsError}
      loading={!createAllocationsSuccess && !createAllocationsError}
    />
  );
};

const Content = () => {
  const { userHasViewAccess, userHasEditAccess } = useContext(OffsetContext);
  return (
    <>
      {(userHasViewAccess && <Allocations />) || null}
      {(userHasEditAccess && <Assign />) || null}
      {(userHasEditAccess && <Dialog />) || null}
    </>
  );
};

export default Content;
