import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import moment from 'moment';
import { UserActivityListItemProps } from './UserActivityListItem';
import UserActivityRenderer, {
  renderActivityDate,
} from './UserActivityRenderer';
import SVG from '../../../assets/svg-v2';
import { Colors } from '../../../theme/shared';
import { UserActivityType } from '../../../types/user/activityTypes';

const UserActivityPollListItem = React.memo(
  ({ userActivity }: UserActivityListItemProps): JSX.Element | null => {
    const history = useHistory();

    if (
      userActivity.activityType !== UserActivityType.POLL_OPENED &&
      userActivity.activityType !== UserActivityType.POLL_CLOSED
    ) {
      return null;
    }

    const { activityType, message } = userActivity;
    const { isCompleted, quizId } = userActivity.data;
    const expireDate = moment(userActivity.data.expireDate);
    const isPollClosed = expireDate.isValid() && expireDate.isBefore(moment());

    const onClick = () =>
      isCompleted ||
      isPollClosed ||
      activityType === UserActivityType.POLL_CLOSED
        ? history.push(`/member/poll-results/${quizId}`)
        : history.push(`/member/poll/${quizId}`);

    return (
      <UserActivityRenderer
        onClick={onClick}
        leftComponent={<SVG.ResultsList />}
        centerComponent={
          <Grid container flexDirection="column">
            <Grid item>
              <Typography variant="body1">{message}</Typography>
            </Grid>
            <Grid item container flexDirection="row" alignItems="center">
              {activityType === UserActivityType.POLL_OPENED ? (
                <Typography variant="body2" color={Colors.TertiaryText}>
                  {t('poll.pollCloses')} {expireDate.format('M/D/YYYY')}
                </Typography>
              ) : (
                renderActivityDate(userActivity)
              )}
            </Grid>
          </Grid>
        }
      />
    );
  },
);

export default UserActivityPollListItem;
