/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  badge: {
    borderRadius: '80px',
    background: theme.palette.buttons.primary.active,
    width: 24,
    height: 24,
    marginRight: 8,
  },
  badgeInner: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 12,
    position: 'relative',
    top: 5,
  },
}));

type Props = {
  actionsCount?: number;
};

const AppBadge: React.FC<Props> = ({ actionsCount }) => {
  const classes = useStyles();
  return (
    <Box className={classes.badge}>
      <Typography variant="subtitle1" className={classes.badgeInner}>
        {actionsCount}
      </Typography>
    </Box>
  );
};

export default AppBadge;
