import React from 'react';
import MemberLayout from '../../../components/layouts/MemberLayout';
import MemberPollResultsList from '../../../components/member/poll/MemberPollResultsList';

const MemberPollResults: React.FC = () => (
  <MemberLayout>
    <MemberPollResultsList />
  </MemberLayout>
);

export default MemberPollResults;
