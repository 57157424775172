import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 24,
    paddingBottom: 8,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

type Props = {
  actions: JSX.Element;
};

const Header: React.FC<Props> = ({ children, actions }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      justifyContent="space-between"
      alignItems="center">
      <Typography variant="h1">{children}</Typography>
      <Grid item>{actions}</Grid>
    </Grid>
  );
};

export default Header;
