import { cloneDeep } from 'lodash';
import {
  ProfileCard,
  ProfileCardType,
  Question,
  QuestionAnswer,
  QuestionProfile,
  QuestionTag,
  UserEmissionSources,
} from '../types/question/types';

export type ProfileAndQuestion = {
  profile: QuestionProfile;
  question: Question;
};

export const DateArray = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

export function getFirstUnansweredQuestionIndex(
  profileCards: ProfileCard[],
  userEmissionSources: UserEmissionSources[],
) {
  let questionIndex = -1;

  profileCards.every((profileQuestion, index) => {
    if (profileQuestion.cardType === ProfileCardType.Question) {
      const emissionSource = userEmissionSources.find(
        (find) => find.profileId === profileQuestion.profileId,
      );

      if (!emissionSource) {
        questionIndex = index;
        return false;
      }

      const question = emissionSource.answeredQuestions.find(
        (find) => find.questionId === profileQuestion.question.questionId,
      );

      if (!question) {
        questionIndex = index;
        return false;
      }

      if (
        !question.answerIds &&
        !question.readings &&
        !question.estimateSchedule
      ) {
        questionIndex = index;
        return false;
      }
    }

    return true;
  });

  return questionIndex;
}

export function isMultiSelectQuestion(profileCard: ProfileCard) {
  return (
    profileCard.cardType === ProfileCardType.Question &&
    profileCard.question.tags?.includes(QuestionTag.ModesOfTransportation)
  );
}

export function createQuestionAnswerFromQuestionAndEmissionSources(
  emissionSources: UserEmissionSources[],
  profileId: string,
  question: Question,
): QuestionAnswer {
  const profileEmissionSource = emissionSources.find(
    (find) => find.profileId === profileId,
  );

  let questionAnswer: QuestionAnswer | undefined;

  if (profileEmissionSource) {
    questionAnswer = profileEmissionSource.answeredQuestions.find(
      (find) => find.questionId === question.questionId,
    );
  }

  if (!questionAnswer) {
    questionAnswer = { questionId: question.questionId };
  } else {
    questionAnswer = cloneDeep(questionAnswer);
    questionAnswer.questionId = question.questionId;
  }

  return questionAnswer;
}

export function isSkippableQuestion(profileCard: ProfileCard) {
  return (
    profileCard.cardType === ProfileCardType.Question &&
    profileCard.question.userInputOptions?.propertyTemplate ===
      'readingsIntSkippable'
  );
}

type DateArrayAsPickerOptionsProps = {
  allowZero?: boolean;
};

export type PickerItem = {
  label: string;
  value: string;
};

export const DateArrayAsPickerOptions = (
  props: DateArrayAsPickerOptionsProps = {
    allowZero: false,
  },
): PickerItem[] => {
  const inputValueArray: PickerItem[] = [];

  if (props.allowZero) {
    inputValueArray.push({ label: '0 days', value: '0' });
  }

  DateArray.forEach((item, index) => {
    const accountForNoneOption = index + 1;
    let label = '';

    if (index > 0) {
      label = `${index + 1} days`;
    } else {
      label = `${index + 1} day`;
    }

    inputValueArray.push({
      label,
      value: accountForNoneOption.toString(),
    });
  });

  return inputValueArray;
};

export function findQuestionByTag(
  profiles: QuestionProfile[],
  tag: QuestionTag,
): ProfileAndQuestion | undefined {
  return profiles
    .flatMap((profile) =>
      profile.questions.map((question) => ({
        profile,
        question,
      })),
    )
    .find((x) => (x.question.tags || []).includes(tag));
}

export function findQuestionAnswerByTag(
  profiles: QuestionProfile[],
  userEmissionSources: UserEmissionSources[],
  tag: QuestionTag,
): QuestionAnswer | undefined {
  const question = findQuestionByTag(profiles, tag);

  const profile = userEmissionSources.find(
    (x) => x.profileId === question?.profile.id,
  );

  return profile?.answeredQuestions.find(
    (x) => x.questionId === question?.question.questionId,
  );
}
