import React from 'react';
import { useQuery } from '@apollo/client';

import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CurrentQuestionnaireCard from '../../common/Questionnaire/CurrentQuestionnaireCard';
import { QuestionnaireProvider } from '../../../contexts/QuestionnaireContext';
import { GET_MEMBER_QUIZ } from '../../../graphql/communications/memberCommunications';

type Props = {
  pollId: string;
};

const Poll: React.FC<Props> = ({ pollId }) => {
  const {
    loading,
    error,
    data: { memberQuiz } = {},
  } = useQuery(GET_MEMBER_QUIZ, {
    variables: {
      quizId: pollId,
    },
  });

  return (
    <DataStateHandler loading={loading} error={error}>
      <QuestionnaireProvider
        quiz={memberQuiz}
        cleanup={{
          navigationType: 'NAVIGATE',
          url: `/member/poll-results/${pollId}`,
        }}>
        <CurrentQuestionnaireCard />
      </QuestionnaireProvider>
    </DataStateHandler>
  );
};

export default Poll;
