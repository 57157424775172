import React from 'react';

export const sanitizeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
  if (e.target.value === '') {
    return 0;
  }
  return Number(e.target.value.replace(/[^0-9]/g, ''));
};

export default {
  sanitizeQuantity,
};
