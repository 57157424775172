import { useApolloClient } from '@apollo/client';

export default function useRemoveReminders() {
  const client = useApolloClient();

  const remove = async () => {
    await client.refetchQueries({
      updateCache(cache) {
        cache.modify({
          fields: {
            aboutMeReminders(_, details) {
              return details.DELETE;
            },
            homepageReminders(_, details) {
              return details.DELETE;
            },
          },
          optimistic: true,
        });
      },
    });
  };

  return {
    remove,
  };
}
