import { Grid } from '@mui/material';
import React, { useContext } from 'react';
import { ProjectContext, ProjectStep } from '../../../contexts/ProjectContext';
import EnumUtils from '../../../utils/enumUtils';

const CreateProjectSteps = () => {
  const { steps, currentStep } = useContext(ProjectContext);
  const step = EnumUtils.values(ProjectStep).find((x) => currentStep === x);
  const stepData = step && steps[step];

  return step && stepData ? (
    <Grid item container key={step} style={{ flex: 1 }}>
      <stepData.stepComponent />
    </Grid>
  ) : null;
};

export default CreateProjectSteps;
