import { ProjectDetailsStep } from '../../contexts/ProjectContext';
import { ImageSize } from '../common';
import { Company } from '../company/types';

type HasIdAndName = {
  id: string;
  name: string;
};

export type ProjectAuditor = HasIdAndName;
export type ProjectCcbSdgAuditor = HasIdAndName & {
  hqCountryCode: string;
};
export type ProjectIndicator = HasIdAndName;
export type ProjectSource = HasIdAndName & {
  uoms: string[];
};
export type ProjectSponsor = HasIdAndName;
export type ProjectUom = HasIdAndName;
export enum ProjectPurchaseType {
  USER = 'USER',
  COMPANY = 'COMPANY',
}
export type ProjectCategory = {
  id: string;
  name: string;
  label: string;
  sortOrder: number;
  iconUri: string;
  backgroundImageUri?: string;
  color?: string;
  description?: string;
};

export type ProjectInventoryHistory = {
  id: string;
  quantity: number;
  remainingQuantity: number;
  purchasedDate: string;
  pricePerUnit: number;
  totalPurchaseAmount: number;
  notes?: number;
  __typename?: string;
};

export type Project = {
  id: string;
  projectId: string | undefined;
  isDraft: boolean | undefined;
  category: string | undefined;
  country: string | undefined;
  region: string | undefined;
  title: string | undefined;
  smallSummary: string | undefined;
  description: string | undefined;
  projectHQ: string | undefined;
  postalCode: string | undefined;
  address: string | undefined;
  place: string | undefined;
  isFeatured: boolean | undefined;
  sponsor: string | undefined;
  startDate: string | undefined | null;
  endDate: string | undefined | null;
  purchasePrice: number | undefined | null;
  source: string | undefined;
  vintage: string | undefined | null;
  indicator: string | undefined;
  sellingPrice: number | undefined | null;
  costPerUnit: number | null | undefined;
  uom: string | undefined;
  auditor: string | undefined;
  sdVista: string | undefined;
  verAuditReportDate: string | undefined | null;
  ccbSdgAuditor: string | undefined;
  ccbSdgReportDate: string | undefined | null;
  createdDate?: string;
  headerImageUris?: Record<ImageSize, string>;
  availableQuantity?: number | null;
  remainingQuantity?: number;
  additionalImageUris?: string[];
  additionalScaledImageUris?: Record<'medium', string>[];
  documentUris?: Array<string>;
  visitedSteps?: ProjectDetailsStep[];
  completedSteps?: ProjectDetailsStep[];
  companies?: Company['id'][];
  individualScope?: boolean;
  sellingPriceHistory?: {
    price: number;
    date: string;
  }[];
  totalRevenue?: {
    mt: number;
    revenue: number;
  };
  allocationTotals?: {
    pending: number;
    pendingMT: number;
  };
  inventoryHistory?: ProjectInventoryHistory[];
  isActive: boolean;
  isDemoProject: boolean;
  headerImagePosition?: number;
  isIndicative?: boolean;
};

export type ProjectAllotmentMetrics = AllotmentMetrics & { projectId: string };

export type UserAllotmentMetrics = AllotmentMetrics & {
  allotmentIds: string[];
  totalRedemptions: number;
};

export type AllotmentMetrics = {
  allotted: number;
  redeemed: number;
  available: number;
};
