import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Button, ButtonBase, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';

import { useMutation, useQuery } from '@apollo/client';

import MemberLayout from '../../../components/layouts/MemberLayout';

import {
  ALLOTMENT_METRICS,
  MEMBER_PROJECT_DETAILS,
} from '../../../graphql/projects/projects';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import ImageUtils from '../../../utils/imageUtils';
import CategoryIconDiamond from '../../../components/common/Project/CategoryIconDiamond';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import ProjectDataPill, {
  ProjectDataPillDataType,
} from '../../../components/common/Project/ProjectDataPill';
import ProjectCardLocation from '../../../components/common/Project/ProjectCardLocation';
import SVG from '../../../assets/svg-v2';
import { Colors } from '../../../theme/shared';
import { REDEEM_CREDITS } from '../../../graphql/user/userMutations';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import MemberAlert from '../../../components/member/myhabits/MemberAlert';
import useRemoveProjectedEmissions from '../../../hooks/cacheModifiers/useRemoveProjectedEmissions';
import useToggleProjectLike from '../../../hooks/useToggleProjectLike';
import useProjectImage from '../../../hooks/useProjectImage';
import useCheckForPointAwards from '../../../hooks/useCheckForPointAwards';

const useStyles = makeStyles(() => ({
  photo: {
    height: 344,
    width: '100%',
    objectFit: 'cover',
  },
}));

export enum ProjectRedemptionAvailablilityStatus {
  AVAILABLE = 'AVAILABLE',
  PROJECT_NOT_ENOUGH = 'PROJECT_NOT_ENOUGH',
  USER_NOT_ENOUGH = 'USER_NOT_ENOUGH',
  PROJECT_NONE = 'PROJECT_NONE',
  NOT_SPONSORED = 'NOT_SPONSORED',
}

export function useProjectRedemptionStatus(props: {
  allotted: number;
  available: number;
  userAvailable: number;
}) {
  const { allotted, available, userAvailable } = props;

  if (allotted === 0) {
    return ProjectRedemptionAvailablilityStatus.NOT_SPONSORED;
  }

  if (available === 0) {
    return ProjectRedemptionAvailablilityStatus.PROJECT_NONE;
  }

  if (userAvailable === 0) {
    return ProjectRedemptionAvailablilityStatus.USER_NOT_ENOUGH;
  }

  if (available < userAvailable) {
    return ProjectRedemptionAvailablilityStatus.PROJECT_NOT_ENOUGH;
  }

  return ProjectRedemptionAvailablilityStatus.AVAILABLE;
}

const MemberProjectDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const history = useHistory();
  const { company } = useContext(AuthorizationContext);
  const { remove: removeProjectedEmissions } = useRemoveProjectedEmissions();
  const { checkForPointAwards } = useCheckForPointAwards();
  const [redeemState, setRedeemState] = useState<
    'INITIAL' | 'VALIDATE' | 'SUCCESS'
  >('INITIAL');

  const { toggleLike, likesProject, toggleLikeLoading, toggleLikeError } =
    useToggleProjectLike({ id });

  const [redeem, { loading: redeemLoading, error: redeemError }] =
    useMutation(REDEEM_CREDITS);

  const {
    data: { me: { allotmentMetrics }, projectAllotmentMetrics } = { me: {} },
    loading: metricsLoading,
    error: metricsError,
    refetch,
  } = useQuery(ALLOTMENT_METRICS, {
    variables: {
      companyId: company.id,
    },
    skip: !company?.id,
  });

  const projectMetrics = projectAllotmentMetrics?.find(
    (x) => x.projectId === id,
  );

  const {
    data: { project } = {},
    loading: detailsLoading,
    error: detailsError,
  } = useQuery(MEMBER_PROJECT_DETAILS, {
    variables: { projectId: id },
  });

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const projectImage = useProjectImage({ project, size: 'large' });

  const category = projectCategories.find((pc) => pc.id === project?.category);

  const status = {
    allotted: projectMetrics?.allotted || 0,
    available: projectMetrics?.available || 0,
    userAvailable: allotmentMetrics?.available || 0,
  };

  const projectState = useProjectRedemptionStatus(status);
  const companySponsored =
    projectState !== ProjectRedemptionAvailablilityStatus.NOT_SPONSORED;

  const pillDataType = {
    NOT_SPONSORED: 'UNSPONSORED',
    AVAILABLE: 'REMAINING_QUANTITY',
    PROJECT_NONE: 'UNAVAILABLE',
    PROJECT_NOT_ENOUGH: 'REMAINING_QUANTITY',
    USER_NOT_ENOUGH: 'REMAINING_QUANTITY',
  }[projectState] as ProjectDataPillDataType;

  return (
    <MemberLayout>
      <DataStateHandler
        loading={detailsLoading || metricsLoading || loadingCategories}
        error={detailsError || metricsError || errorCategories || redeemError}>
        <Grid container direction="column" position="relative">
          {companySponsored && (
            <Box
              position="absolute"
              left="0px"
              style={{
                backgroundColor: Colors.CobaltBlue,
                borderBottomRightRadius: 16,
                padding: '6px 20px',
                fontWeight: 700,
              }}>
              <Typography variant="h4" color="white">
                Company-sponsored
              </Typography>
            </Box>
          )}
          {project ? (
            <>
              <img
                className={classes.photo}
                alt="Project"
                src={projectImage}
                style={ImageUtils.ObjectPositionStyle(
                  project.headerImagePosition,
                )}
              />
              {companySponsored &&
                redeemState !== 'SUCCESS' &&
                (() => {
                  if (
                    projectState ===
                    ProjectRedemptionAvailablilityStatus.PROJECT_NOT_ENOUGH
                  ) {
                    return (
                      <Box mt={2} mb={2}>
                        <MemberAlert
                          messages={[
                            {
                              title:
                                'You have more credits than this project has available.',
                              description:
                                'Your must redeem the exact number of credits you have by allocating all your credits to one project.  Please select a carbon reduction project that has the same or more credits as your credit balance.',
                            },
                          ]}
                        />
                      </Box>
                    );
                  }

                  if (
                    projectState ===
                    ProjectRedemptionAvailablilityStatus.USER_NOT_ENOUGH
                  ) {
                    return (
                      <Box mt={2} mb={2}>
                        <MemberAlert
                          messages={[
                            {
                              title: 'You have no credits remaining.',
                              description:
                                'Check back when you have a sufficient credit balance.',
                            },
                          ]}
                        />
                      </Box>
                    );
                  }

                  if (
                    projectState ===
                    ProjectRedemptionAvailablilityStatus.PROJECT_NONE
                  ) {
                    return (
                      <Box mt={2} mb={2}>
                        <MemberAlert
                          messages={[
                            {
                              title: 'There are no MT left for this project.',
                              description:
                                'All MT for this project have been redeemed.  Please select a different project.',
                            },
                          ]}
                        />
                      </Box>
                    );
                  }

                  return null;
                })()}
              <Grid item sx={{ mt: 2 }}>
                <Grid container spacing={6}>
                  <Grid item md={6} sm={12}>
                    <Grid container direction="column">
                      <CategoryIconDiamond
                        categoryId={project.category}
                        size="medium"
                      />
                      <Typography
                        sx={{
                          mt: 2,
                          mb: 2,
                          color: category?.color || 'inherit',
                        }}
                        variant="h1">
                        {project.title}
                      </Typography>
                      {category ? (
                        <Box position="relative" height={70}>
                          <ProjectDataPill
                            category={{
                              ...category,
                              ...(projectState !==
                              ProjectRedemptionAvailablilityStatus.AVAILABLE
                                ? { color: Colors.DisabledGrey }
                                : {}),
                            }}
                            project={{
                              sellingPrice: -1,
                              uom: 'MT',
                              remainingQuantity: status.available,
                            }}
                            dataType={pillDataType}
                            style={{
                              right: 'auto',
                              width: 243,
                              textAlign: 'center',
                            }}
                          />
                        </Box>
                      ) : null}
                      {redeemState === 'INITIAL' && (
                        <>
                          <Typography sx={{ lineWrap: 'prewrap', mb: 2 }}>
                            {project.smallSummary}
                          </Typography>
                          <ProjectCardLocation project={project} />
                          <Typography sx={{ mt: 2 }} variant="h2">
                            Project Description
                          </Typography>
                          <Typography
                            sx={{ lineWrap: 'prewrap', mt: 2, mb: 2 }}>
                            {project.description}
                          </Typography>
                        </>
                      )}
                      {redeemState === 'VALIDATE' && (
                        <Typography
                          data-testid="member-project-details-validate-message"
                          sx={{ lineWrap: 'prewrap', mb: 2 }}>
                          You are redeeming {status.userAvailable} of{' '}
                          {status.available} available credits. Would you like
                          to continue?
                        </Typography>
                      )}
                      {redeemState === 'SUCCESS' && (
                        <>
                          <Typography
                            variant="h1"
                            sx={{ lineWrap: 'prewrap', mb: 2 }}>
                            Thanks for supporting this project!
                          </Typography>
                          <Typography sx={{ lineWrap: 'prewrap', mb: 2 }}>
                            Your net emissions will be reduced to reflect your
                            contribution towards this project.
                          </Typography>
                          <Button
                            variant="contained"
                            onClick={() => history.push('/member/projects')}>
                            Browse more Carbon Reduction Projects
                          </Button>
                        </>
                      )}
                      {projectState ===
                        ProjectRedemptionAvailablilityStatus.AVAILABLE &&
                        redeemState !== 'SUCCESS' && (
                          <Button
                            variant="contained"
                            data-testid="member-project-details-redeem-button"
                            disabled={redeemLoading}
                            onClick={async () => {
                              if (redeemState === 'INITIAL') {
                                setRedeemState('VALIDATE');
                              }

                              if (redeemState === 'VALIDATE') {
                                await redeem({
                                  variables: {
                                    amount: status.userAvailable,
                                    projectId: id,
                                  },
                                });

                                await checkForPointAwards();

                                setRedeemState('SUCCESS');
                                await removeProjectedEmissions();
                                await refetch();
                              }
                            }}>
                            <SVG.CartEmpty style={{ marginRight: 10 }} />
                            Redeem {status.userAvailable} MT
                          </Button>
                        )}
                    </Grid>
                  </Grid>
                  <Grid item md={6} sm={12}>
                    <Grid container direction="column">
                      <Grid
                        item
                        sx={{
                          pb: 4,
                          mb: 3,
                          mt: 6,
                          borderBottom: `1px solid ${Colors.DisabledGrey}`,
                        }}>
                        <DataStateHandler
                          loading={toggleLikeLoading}
                          error={toggleLikeError}>
                          <ButtonBase
                            onClick={toggleLike}
                            disabled={toggleLikeLoading}>
                            <Grid container alignItems="center">
                              {likesProject ? (
                                <SVG.Dislike />
                              ) : (
                                <>
                                  <SVG.Like />
                                  <Typography
                                    variant="button"
                                    color="primary"
                                    sx={{ ml: 1 }}>
                                    Like this project
                                  </Typography>
                                </>
                              )}
                            </Grid>
                          </ButtonBase>
                        </DataStateHandler>
                      </Grid>
                      <Typography variant="h2">Project Status</Typography>
                      <Typography variant="body1" sx={{ mb: 3 }}>
                        {[
                          ProjectRedemptionAvailablilityStatus.AVAILABLE,
                          ProjectRedemptionAvailablilityStatus.USER_NOT_ENOUGH,
                        ].includes(projectState) &&
                          'This company-sponsored project is currently active. '}
                        {projectState ===
                          ProjectRedemptionAvailablilityStatus.PROJECT_NOT_ENOUGH &&
                          'This company-sponsored project has met its allotment. '}
                        {projectState ===
                          ProjectRedemptionAvailablilityStatus.PROJECT_NONE &&
                          'This company-sponsored project has met its allotment.  Zero Metric Tons remain available for redemption. '}
                        {projectState ===
                          ProjectRedemptionAvailablilityStatus.NOT_SPONSORED &&
                          'This project is not currently supported. '}
                        Like this project to let your company know you&apos;d
                        like to support this and similar projects in the future.
                      </Typography>
                      <Typography variant="h2">Project Details</Typography>
                      <Typography variant="body1">
                        {project.startDate
                          ? `Start Date: ${format(
                              new Date(project.startDate),
                              'MM/dd/yyyy',
                            )}`
                          : ''}
                      </Typography>
                      <Typography variant="body1" sx={{ mb: 3 }}>
                        {project.projectId
                          ? `Project ID: ${project.projectId}`
                          : ''}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </DataStateHandler>
    </MemberLayout>
  );
};

export default MemberProjectDetails;
