import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SVG from '../../../assets/svg';
import { ProjectContext, ProjectStep } from '../../../contexts/ProjectContext';
import EnumUtils from '../../../utils/enumUtils';
import SmallPaddedButton from '../../common/Buttons/SmallPaddedButton';
import HeaderTab from '../../common/HeaderTabs/HeaderTab';
import HeaderTabs from '../../common/HeaderTabs/HeaderTabs';

const useStyles = makeStyles((theme) => ({
  right: {
    marginLeft: 'auto',
  },
  root: {
    marginBottom: 16,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  tabs: {
    position: 'relative',
    top: 4,
  },
  closeLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const CreateProjectHeader: React.FC = () => {
  const classes = useStyles();

  const {
    saveProject,
    currentStep,
    steps,
    forceStepValidation,
    goToStep,
    isStepDisabled,
  } = useContext(ProjectContext);

  const handleTabChange = (
    _event: React.ChangeEvent<unknown>,
    value: ProjectStep,
  ) => goToStep(value);

  return (
    <Grid className={classes.root} container alignItems="center">
      <Grid item>
        <Box mr={2}>
          <Link to="/trader/projects/drafts" className={classes.closeLink}>
            <SVG.Close />
          </Link>
        </Box>
      </Grid>
      <Grid item>
        <Box mr={2} pb={1}>
          <Typography variant="h1">Create New Project</Typography>
        </Box>
      </Grid>
      <Grid item>
        <HeaderTabs
          value={currentStep}
          className={classes.tabs}
          onChange={handleTabChange}>
          {EnumUtils.values(ProjectStep).map((step) => (
            <HeaderTab
              key={step}
              value={step}
              label={`${step}. ${steps[step].title}`}
              style={{ minWidth: `${steps[step].title.length}ch` }}
              disabled={isStepDisabled(step)}
            />
          ))}
        </HeaderTabs>
      </Grid>
      <Grid item className={classes.right}>
        <Grid container>
          <Grid item>
            <Box mr={1}>
              <SmallPaddedButton
                variant="outlined"
                onClick={() => saveProject()}>
                Save as a Draft
              </SmallPaddedButton>
            </Box>
          </Grid>
          <Grid item>
            <SmallPaddedButton
              endIcon={<SVG.ArrowRight />}
              onClick={() => {
                if (currentStep === ProjectStep.review) {
                  saveProject({
                    forceNotDraft: true,
                  });
                } else if (isStepDisabled(currentStep + 1)) {
                  forceStepValidation();
                } else {
                  goToStep(currentStep + 1);
                }
              }}>
              {currentStep === ProjectStep.details && 'Next'}
              {currentStep === ProjectStep.assign && 'Review'}
              {currentStep === ProjectStep.review && 'Create New Project'}
            </SmallPaddedButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateProjectHeader;
