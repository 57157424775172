import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const apiUrl = process.env.REACT_APP_SERVER_URL;
const apiKey = process.env.REACT_APP_SERVER_API_KEY;

const httpLink = createHttpLink({
  uri: `${apiUrl}/graphql-settings`,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: apiKey,
  },
}));

const client = new ApolloClient({
  uri: apiUrl,
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
