import React from 'react';
import { Colors } from '../../../theme/shared';

type Props = {
  active?: boolean;
};

const QuizIcon: React.FC<Props> = ({ active }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 -6 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.75 3.125C16.9062 3.125 17.0312 3.09375 17.125 3.03125L18.875 2.03125C19.0938 1.90625 19.25 1.65625 19.25 1.40625C19.25 0.96875 18.9062 0.65625 18.5 0.65625C18.3438 0.65625 18.2188 0.6875 18.125 0.75L16.375 1.75C16.1562 1.875 16 2.125 16 2.40625C16 2.8125 16.3438 3.125 16.75 3.125ZM4 6C4 5.59375 3.65625 5.25 3.25 5.25H1.25C0.8125 5.25 0.5 5.59375 0.5 6C0.5 6.4375 0.8125 6.75 1.25 6.75H3.25C3.65625 6.75 4 6.4375 4 6ZM2.09375 2.03125L3.84375 3.03125C3.9375 3.09375 4.0625 3.15625 4.21875 3.15625C4.625 3.15625 4.96875 2.8125 4.96875 2.40625C4.96875 2.125 4.8125 1.875 4.59375 1.75L2.84375 0.75C2.75 0.6875 2.59375 0.625 2.46875 0.625C2.0625 0.625 1.71875 0.96875 1.71875 1.375C1.71875 1.65625 1.875 1.90625 2.09375 2.03125ZM19.75 5.25H17.75C17.3125 5.25 17 5.59375 17 6C17 6.4375 17.3125 6.75 17.75 6.75H19.75C20.1562 6.75 20.5 6.4375 20.5 6C20.5 5.59375 20.1562 5.25 19.75 5.25ZM15.4688 6.5C15.4688 4.09375 13.7812 2.15625 11.4688 1.65625V1C11.4688 0.4375 11.0312 0 10.4688 0C9.9375 0 9.46875 0.4375 9.46875 1C9.46875 1 9.46875 1 9.5 1V1.65625C7.1875 2.15625 5.5 4.09375 5.5 6.5C5.5 9.71875 4.34375 10.6875 3.75 11.3438C3.59375 11.5 3.46875 11.75 3.46875 12H3.5C3.5 12.5312 3.90625 13 4.5 13H16.4688C17.0938 13 17.4688 12.5312 17.5 12C17.5 11.75 17.375 11.5 17.2188 11.3438C16.625 10.6875 15.4688 9.71875 15.4688 6.5ZM5.59375 11.5C6.25 10.625 6.96875 9.1875 7 6.53125C7 6.53125 7 6.53125 7 6.5C7 4.59375 8.5625 3 10.5 3C12.4062 3 14 4.59375 14 6.5C14 6.53125 13.9688 6.53125 13.9688 6.53125C14 9.1875 14.7188 10.6562 15.375 11.5H5.59375ZM10.5 16C11.5938 16 12.5 15.125 12.5 14H8.5C8.5 15.125 9.375 16 10.5 16Z"
      fill={active ? `${Colors.ElectricGreen}` : '#FFFFFF'}
    />
  </svg>
);

export default QuizIcon;
