/* eslint-disable import/prefer-default-export */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { IconButton } from '@mui/material';
import { LexicalCommand } from 'lexical';
import React from 'react';

type Props<T> = {
  icon: React.ReactElement;
  commandPayload: T;
  commandType: LexicalCommand<T>;
  isHighlighted?: boolean;
} & React.ComponentProps<typeof IconButton>;

export const ToolbarButton = <T,>({
  icon,
  commandPayload,
  commandType,
  isHighlighted = false,
  ...iconButtonProps
}: Props<T>) => {
  const [editor] = useLexicalComposerContext();

  return (
    <IconButton
      color={isHighlighted ? 'primary' : 'inherit'}
      onClick={() => editor.dispatchCommand(commandType, commandPayload)}
      {...iconButtonProps}>
      {icon}
    </IconButton>
  );
};
