import { Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import theme from '../../../theme/memberTheme';
import { WaterComparisonFaucet } from './WaterComparisonFaucet';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 32,
    backgroundColor: theme.palette.backgrounds.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 24,
  },
  rootWide: {
    position: 'relative',
    left: 200,
    marginLeft: -200,
    paddingRight: 200,
  },
  faucetBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    padding: 16,
    position: 'relative',
  },
  waterValue: {
    fontWeight: 700,
  },
  white: {
    color: '#fff',
  },
}));

type Props = {
  userTotal: number;
  countryTotal: number;
  organizationTotal: number;
};

const WaterComparisonChart: React.FC<Props> = ({
  userTotal,
  countryTotal,
  organizationTotal,
}) => {
  const classes = useStyles();
  const isSm = useMediaQuery(theme.breakpoints.down('md'));

  const organizationPercent = new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 0,
    style: 'percent',
  }).format(Math.abs(userTotal - organizationTotal) / organizationTotal || 1);

  const countryPercent = new Intl.NumberFormat('en-us', {
    maximumFractionDigits: 0,
    style: 'percent',
  }).format(Math.abs(userTotal - countryTotal) / countryTotal || 1);

  const organizationLabel = userTotal > organizationTotal ? 'more' : 'less';

  const countryLabel = userTotal > countryTotal ? 'more' : 'less';

  const hasData = userTotal > 0;

  return (
    <Grid
      item
      container
      flexDirection="column"
      xs={12}
      md={4}
      className={classes.faucetBox}
      style={
        isSm
          ? {}
          : {
              position: 'relative',
              top: 24,
              marginBottom: 24,
            }
      }>
      <Typography variant="subtitle1" className={classes.white}>
        HOW DO I COMPARE?
      </Typography>
      {hasData ? (
        <Grid
          item
          container
          flexDirection="row"
          justifyContent="space-around"
          pt={3}>
          <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h3" className={classes.white}>
              {organizationPercent} {organizationLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.white}>
              than Organization average
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: 'center' }}>
            <Typography variant="h3" className={classes.white}>
              {countryPercent} {countryLabel}
            </Typography>
            <Typography variant="subtitle1" className={classes.white}>
              than U.S. average
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <WaterComparisonFaucet
        userTotal={userTotal}
        countryTotal={countryTotal}
        organizationTotal={organizationTotal}
      />
    </Grid>
  );
};

export default WaterComparisonChart;
