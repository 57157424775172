import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AppCard from '../../../common/AppCard/AppCard';
import SVG from '../../../../assets/svg';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
  },
  button: {
    width: '100%',
  },
  text: {
    flex: 2,
    marginTop: 16,
  },
  linkButton: {
    textDecoration: 'none',
  },
}));

const NoBudgetNoPurchasesCard: React.FC = () => {
  const classes = useStyles();
  return (
    <AppCard height={366} headerProps={{ title: 'OFFSET ALLOCATION' }}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.root}>
        <Grid item>
          <SVG.BrowseMarketplace />
        </Grid>
        <Grid item className={classes.text}>
          <Typography variant="h2" align="center">
            This will populate once you purchase a project
          </Typography>
        </Grid>
        <Grid item className={classes.button} />
      </Grid>
    </AppCard>
  );
};

export default NoBudgetNoPurchasesCard;
