import onboardingQuestionsDisabled from './OnboardingQuestionsDisabled';
import onboardingQuestionsEnabled from './OnboardingQuestionsEnabled';

const onboardingQuestions = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? onboardingQuestionsDisabled(level, size)
    : onboardingQuestionsEnabled(level, size);

export default onboardingQuestions;
