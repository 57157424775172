import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import SVG from '../../../../assets/svg';
import ConfirmCancelModal from '../../ConfirmCancelModal/ConfirmCancelModal';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.error.main,
  },
}));

type Props = {
  isOpen: boolean;
  confirmLabel: string;
  message: string;
  onClose: () => void;
  onConfirm: () => void;
  disableConfirm?: boolean;
};

const ProjectInventoryHistoryWarningModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  confirmLabel,
  message,
  disableConfirm,
}) => {
  const classes = useStyles();

  return (
    <ConfirmCancelModal
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={onConfirm}
      icon={
        <Grid item>
          <SVG.WarningTriangle className={classes.warning} />
        </Grid>
      }
      disableConfirm={disableConfirm}
      confirmLabel={confirmLabel}
      title="Heads Up!"
      message={message}
    />
  );
};

export default ProjectInventoryHistoryWarningModal;
