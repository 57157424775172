import { Grid, TableCell, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DocumentNode } from 'graphql';
import { get } from 'lodash';
import React, {
  ComponentProps,
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
} from 'react';
import arrayUtils from '../../../utils/arrayUtils';
import ExportDialog from './ExportDialog';
import GroupHeaderCell from './GroupHeaderCell';
import HeaderCell from './HeaderCell';
import SelectTable, {
  DEFAULT_STICKY_LEFT,
  HIDDEN_STICKY_LEFT,
  ROW_HEIGHT,
  SelectTableProps,
} from './SelectTable';
import StickyCell from './StickyCell';
import TextCell from './TextCell';
import {
  ExportableGroupableTableStructure,
  FilterState,
  GroupableTableStructure,
  SelectTableColumn,
  SelectTableRowId,
} from './types';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
  },
  tableGrid: {
    position: 'relative',
    flex: 1,
  },
  tableWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'auto',
  },
  groupHeader: {
    zIndex: 2,
    border: 0,
    padding: 8,
  },
  headerNoGroup: {
    zIndex: 2,
    border: 0,
    top: 0,
    height: 60,
  },
  header: {
    top: ROW_HEIGHT,
    zIndex: 2,
    border: 0,
  },
  firstColumn: {
    zIndex: 3,
  },
  firstColumnWithSticky: {
    left: DEFAULT_STICKY_LEFT,
  },
  pending: {
    position: 'relative',
    top: 2,
    left: 8,
  },
  uom: {
    marginLeft: 8,
  },
  spacerCell: {
    borderBottom: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 3,
  },
  spacerDiv: {
    width: 5,
    height: 55,
    marginLeft: 10,
    marginRight: 10,
    borderLeft: '1px solid #DADADA',
    borderRight: '1px solid #DADADA',
  },
  cell: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  cellText: {
    paddingLeft: 16,
    paddingRight: 16,
    overflow: 'hidden',
  },
}));

function GroupableSelectTableInner<TData extends SelectTableRowId>(
  {
    tableData,
    tableStructure,
    onSelected: onSelectedProp,
    onRowClick,
    loadMoreRows,
    hasMoreRows,
    filter,
    setFilter: setFilterProp,
    getPropertyQuery,
    queryVariables,
    initialSelected,
    hideStickyCol,
    allSelectedBannerText,
    errorText,
    exportable,
    exportOptions,
    actionItems,
    importExportActionItems,
    exportButtonUI,
  }: Props<TData>,
  ref: Ref<unknown>,
) {
  const classes = useStyles();
  const [clientSideFilter, setClientSideFilter] = useState<FilterState>({});
  const theme = useTheme();
  const [collapsedGroupIndices, setCollapsedGroupIndices] = useState<number[]>(
    [],
  );

  useImperativeHandle(ref, () => ({
    getCollapsedGroupIndices: () => collapsedGroupIndices,
  }));

  const handleGroupHeaderClick = (event: unknown, id: number) => {
    setCollapsedGroupIndices(arrayUtils.addOrRemove(collapsedGroupIndices, id));
  };

  const isColumnVisible = (column: SelectTableColumn) =>
    column.isVisible || column.isVisible == null;

  const getVisibleGroups = () =>
    tableStructure.filter((group) =>
      group.columns.some((c) => isColumnVisible(c)),
    );

  const backgroundColor = theme.palette.background.paper;

  // If we have a provided filter we're doing server side filtering, so ignore
  const filteredTableData = filter
    ? tableData
    : tableData.filter((row) =>
        Object.keys(clientSideFilter).every((key) => {
          const column = tableStructure
            .flatMap((x) => x.columns)
            .find((x) => x.key === key);

          if (column && column.type) {
            return column.type.filterFn(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              get(row as any, key),
              clientSideFilter[key],
            );
          }

          // OK by default?
          return true;
        }),
      );

  const [selected, setSelected] =
    useState<typeof initialSelected>(initialSelected);
  const onSelected = (
    selectedRows: Pick<TData, 'id'>[],
    params: { allSelected: boolean },
  ) => {
    onSelectedProp?.(selectedRows, params);
    setSelected(selectedRows);
  };

  const setFilter = (f: FilterState) => {
    setSelected(undefined);
    (setFilterProp || setClientSideFilter)?.(f);
  };
  const createSpacer = (
    group: GroupableTableStructure<TData>,
    key: string,
    collapsed: boolean,
    hasDefaultStickyColumn = false,
  ) => {
    const lastColumn = collapsed
      ? group.columns[0]
      : group.columns.slice(-1)[0];

    const stickyLeft = group.sticky
      ? (lastColumn.stickyLeft ?? 0) +
        (lastColumn.width || 0) +
        (collapsed ? 14 : 0) +
        (hasDefaultStickyColumn ? DEFAULT_STICKY_LEFT : 0)
      : undefined;
    return (
      <TableCell
        key={key}
        className={classes.spacerCell}
        style={{
          ...(stickyLeft !== undefined
            ? { position: 'sticky', left: stickyLeft }
            : {}),
        }}>
        <div className={classes.spacerDiv} />
      </TableCell>
    );
  };

  const getTableHeader = () => {
    const groupHeaderCells: React.ReactNode[] = [];
    const headerCells: React.ReactNode[] = [];
    const useGroupHeaders = tableStructure.every(
      (x) => x.groupIcon && x.groupName,
    );

    if (useGroupHeaders && !hideStickyCol) {
      // Add top left empty cell
      groupHeaderCells.push(
        <StickyCell
          key="spaceHolder"
          containerStyle={{
            backgroundColor,
            border: 0,
          }}
          position="both"
          colSpan={1}
        />,
      );
    }

    const visibleGroups = getVisibleGroups();

    visibleGroups.forEach((group, groupIndex) => {
      const isStickyGroup = group.sticky;
      const position = isStickyGroup ? 'both' : 'top';
      const isCollapsed = collapsedGroupIndices.indexOf(groupIndex) !== -1;
      const visibleColumns = group.columns.filter((c) => isColumnVisible(c));
      const colSpan = isCollapsed ? 1 : visibleColumns.length;

      if (useGroupHeaders) {
        groupHeaderCells.push(
          <GroupHeaderCell
            backgroundColor={backgroundColor}
            title={group.groupName}
            icon={group.groupIcon}
            position={position}
            containerClassName={`${isStickyGroup ? classes.firstColumn : ''} 
              ${
                groupIndex !== 0 || hideStickyCol
                  ? ''
                  : classes.firstColumnWithSticky
              }
            ${classes.groupHeader}`}
            key={`${group.groupName}_${group.key}`}
            colSpan={colSpan}
            onClick={(e) => handleGroupHeaderClick(e, groupIndex)}
            open={!collapsedGroupIndices.includes(groupIndex)}
            columnLength={visibleColumns.length}
          />,
        );
      }

      visibleColumns.forEach((column, columnIndex) => {
        if (isCollapsed && columnIndex !== 0) return;

        headerCells.push(
          <HeaderCell
            position={position}
            containerClassName={`${isStickyGroup ? classes.firstColumn : ''}
            ${
              groupIndex !== 0 || hideStickyCol
                ? ''
                : classes.firstColumnWithSticky
            }
            ${useGroupHeaders ? classes.header : classes.headerNoGroup}`}
            normalBackgroundColor={column.style?.backgroundColor}
            key={`header-${column.key}`}
            column={column}
            wrapText={column.wrap}
            filter={filter || clientSideFilter}
            setFilter={setFilter}
            getPropertyQuery={getPropertyQuery}
            queryVariables={queryVariables}
            tableData={filteredTableData}
            containerStyle={{
              left:
                column.stickyLeft !== undefined
                  ? column.stickyLeft +
                    ((!hideStickyCol && DEFAULT_STICKY_LEFT) || 0)
                  : HIDDEN_STICKY_LEFT,
              ...(column.style || {}),
            }}>
            <span
              style={{
                whiteSpace: 'pre-wrap',
              }}>
              {column.display}
            </span>
          </HeaderCell>,
        );
      });

      if (groupIndex < visibleGroups.length - 1) {
        if (useGroupHeaders) {
          groupHeaderCells.push(
            createSpacer(
              group,
              `ghspacer_${groupIndex}`,
              isCollapsed,
              groupIndex === 0 && !hideStickyCol,
            ),
          );
        }
        headerCells.push(
          createSpacer(
            group,
            `hspacer_${groupIndex}`,
            isCollapsed,
            groupIndex === 0 && !hideStickyCol,
          ),
        );
      }
    });

    return {
      groupHeaderCells,
      headerCells,
    };
  };

  const getTableRows = ({ rows }: { rows: TData[] }) => {
    const visibleGroups = getVisibleGroups();

    return rows.map((data) => ({
      id: data.id,
      data,
      node: (
        <>
          {visibleGroups.map((group, groupIndex) => {
            const isCollapsed =
              collapsedGroupIndices.indexOf(groupIndex) !== -1;
            const visibleColumns = group.columns.filter((c) =>
              isColumnVisible(c),
            ) as typeof group.columns;

            return [
              ...visibleColumns.map((column, columnIndex) => {
                if (isCollapsed && columnIndex !== 0) return null;

                const isFirstStickyColumn = groupIndex === 0 && !!group.sticky;

                const columnData = get(
                  data as unknown as Record<
                    string,
                    typeof data[keyof typeof data]
                  >,
                  column.key,
                );

                return (
                  <TextCell
                    sticky={isFirstStickyColumn}
                    position={isFirstStickyColumn ? 'left' : undefined}
                    containerClassName={classes.cell}
                    containerStyle={{
                      left:
                        column.stickyLeft !== undefined
                          ? column.stickyLeft +
                            ((!hideStickyCol && DEFAULT_STICKY_LEFT) || 0)
                          : HIDDEN_STICKY_LEFT,
                      ...(column.style || {}),
                    }}
                    key={`${data.id}${column.key}`}>
                    <div
                      style={{ width: column.width }}
                      className={classes.cellText}>
                      {!!column.render && column.render(data)}
                      {!column.render && (
                        <Typography variant="body2">
                          {column.format?.(columnData) || columnData || ''}
                        </Typography>
                      )}
                    </div>
                  </TextCell>
                );
              }),
              ...(groupIndex < visibleGroups.length - 1
                ? [
                    createSpacer(
                      group,
                      `r_spacer_${groupIndex}`,
                      isCollapsed,
                      groupIndex === 0 && !hideStickyCol,
                    ),
                  ]
                : []),
            ];
          })}
        </>
      ),
    }));
  };

  // The order of these calls matter as getTableRows figures out which headers should be faded based on matching search criteria
  const rows = getTableRows({ rows: filteredTableData });
  const header = getTableHeader();

  const hasActionItems = (exportable && exportOptions) || actionItems;
  return (
    <Grid container direction="column" className={classes.container}>
      {hasActionItems && (
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>{actionItems}</Grid>
          <Grid item>
            {exportable && exportOptions && (
              <ExportDialog
                {...exportOptions}
                tableData={filteredTableData}
                tableStructure={
                  tableStructure as ExportableGroupableTableStructure<TData>[] // assertion required for compilation
                }
                initialSelected={selected}
                onSelected={onSelected}
                importExportActionItems={importExportActionItems}
                exportButtonUI={exportButtonUI}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Grid item className={classes.tableGrid}>
        <div className={(hasActionItems && classes.tableWrapper) || undefined}>
          <SelectTable
            onRowClick={onRowClick}
            onSelected={onSelected}
            tableTitle="GroupableSelectTable"
            groupHeaderCells={header.groupHeaderCells}
            headerCells={header.headerCells}
            rows={rows}
            loadMoreRows={loadMoreRows}
            hasMoreRows={hasMoreRows}
            initialSelected={selected}
            hideStickyCol={hideStickyCol}
            allSelectedBannerText={allSelectedBannerText}
            errorText={errorText}
          />
        </div>
      </Grid>
    </Grid>
  );
}

const GroupableSelectTable = forwardRef(GroupableSelectTableInner);

type ExposedSelectTableProps<TData extends SelectTableRowId> = Pick<
  SelectTableProps<TData>,
  | 'allSelectedBannerText'
  | 'hasMoreRows'
  | 'hideStickyCol'
  | 'initialSelected'
  | 'loadMoreRows'
  | 'onSelected'
  | 'onRowClick'
  | 'errorText'
>;

type Props<TData extends SelectTableRowId> = {
  tableData: TData[];
  filter?: FilterState;
  setFilter?: (f: FilterState) => void;
  getPropertyQuery?: DocumentNode;
  queryVariables?: Record<string, unknown>;
  actionItems?: React.ReactNode;

  /**
   * Custom action items for other import/export buttons.
   */
  importExportActionItems?: React.ReactNode;

  /**
   * Markup that represents the Export table button.
   */
  exportButtonUI?: JSX.Element;
} & ExposedSelectTableProps<TData> &
  (
    | {
        tableStructure: GroupableTableStructure<TData>[];
        exportable?: never;
        exportOptions?: never;
      }
    | {
        tableStructure: ExportableGroupableTableStructure<TData>[];
        /**
         * Adds the export feature to the grid.
         */
        exportable?: boolean;
        exportOptions: Pick<
          ComponentProps<typeof ExportDialog>,
          'contentPlural' | 'filename'
        >;
      }
  );

export default GroupableSelectTable as <T extends SelectTableRowId>(
  props: Props<T> & { ref?: React.ForwardedRef<HTMLTableElement> },
) => ReturnType<typeof GroupableSelectTable>;
