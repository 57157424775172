import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import DefaultLogo from '../../../assets/img/default-logo.png';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { generateCompanyQuery } from '../../../graphql/company/company';
import { OFFSET_OPPORTUNITIES } from '../../../graphql/dashboard/emissions/offsetOpportunities';
import useStickyRef from '../../../hooks/useStickyRef';
import { Company } from '../../../types/company/types';
import { CategoryData } from '../../../types/dashboard/types';
import { dateRange } from '../../../utils/dateUtils';
import NumberUtils from '../../../utils/numberUtils';
import AppInfo from '../../common/AppInfo';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import LocationMap from '../../common/LocationMap';
import TotalInvestments from '../../dashboards/Impact/CompanyInvestments/TotalInvestments';
import CategoryCards from './CategoryCards';
import OverviewCard from './OverviewCard';
import ProjectGrids from './ProjectGrids';
import ImageUtils from '../../../utils/imageUtils';
import { MTCO2E } from '../../../utils/stringConstants';
import DefaultImage from '../../common/DefaultImage';

const useStyles = makeStyles((theme) => ({
  back: {
    position: 'fixed',
    top: 0,
    color: '#fff',
  },
  photo: {
    height: 285,
    width: '100%',
    objectFit: 'cover',
  },
  parentLayer: {
    position: 'relative',
    marginBottom: 10,
  },
  logo: {
    width: 90,
    height: 90,
    objectFit: 'cover',
    display: 'block',
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 10,
  },
  header: {
    borderBottom: `solid 1px ${theme.palette.divider}`,
  },
  linkButton: {
    color: '#3E7DA2',
  },
  stickyClose: {
    color: theme.palette.text.primary,
    position: 'absolute',
    left: -20,
  },
  impersonateChip: {
    fontSize: 12,
    background: '#FFB84E',
    marginLeft: 8,
    marginBottom: 10,
    borderRadius: 4,
    padding: '16px 8px',
    '& svg': {
      width: 18,
      position: 'relative',
      top: 3,
      marginRight: 5,
    },
  },
}));

type Props = {
  companyId: Company['id'];
  handleClose: () => void;
};

const ClientDetails: React.FC<Props> = ({ companyId, handleClose }) => {
  const classes = useStyles();
  const { setImpersonatedCompanyId, userHasAccess } =
    useContext(AuthorizationContext);
  const { stickyRef, isSticky } = useStickyRef('MuiPaper-root');
  const {
    loading,
    error,
    data: { company } = {},
  } = useQuery<{ company: Company }>(generateCompanyQuery(userHasAccess), {
    variables: { id: companyId },
  });

  const loadBudgetInvestments =
    userHasAccess('ZeroMe.TraderDashboard', 'VIEW') ||
    (userHasAccess('Client.Impact', 'VIEW') &&
      userHasAccess('Client.Marketplace', 'VIEW'));

  // Retrieve investments from past 6 months.
  const {
    data: { investments = [] } = {},
    loading: offsetDataLoading,
    error: offsetDataError,
  } = useQuery(OFFSET_OPPORTUNITIES, {
    variables: {
      dateGrain: 'MONTHLY',
      companyId,
      ...dateRange(),
    },
    skip: !loadBudgetInvestments,
    // apollo will try to cache graph data results from
    // this query which results in data being overwritten
    fetchPolicy: 'no-cache',
  });

  // retrieve investments YTD.
  const {
    data: {
      company: {
        currentBudget = {
          total: null,
          remaining: null,
          spent: null,
        },
      } = {},
      investments: ytdInvestments = [],
    } = {},
    loading: ytdOffsetDataLoading,
  } = useQuery(OFFSET_OPPORTUNITIES, {
    variables: {
      dateGrain: 'YEARLY',
      companyId,
      ...company?.currentFiscalYearDateRange,
    },
    skip: !loadBudgetInvestments || !company?.currentFiscalYearDateRange,
    // apollo will try to cache graph data results from
    // this query which results in data being overwritten
    fetchPolicy: 'no-cache',
  });

  const { currentEmployeeMetrics } = company || {};
  const { activeEmployeeCount = 0, enabledEmployeeCount = 0 } =
    currentEmployeeMetrics || {};

  const participation = activeEmployeeCount / enabledEmployeeCount;

  const totalInvestments = investments.find((i: CategoryData) => i.id === '1');

  const topCategory = (ytdInvestments as CategoryData[])
    ?.find((i: CategoryData) => i.id === '1')
    ?.data.reduce((prev, curr) => {
      const currValue = curr.data.reduce((p, c) => p + c.value, 0);

      if (currValue && (!prev.label || currValue > prev.value)) {
        return { label: curr.label, value: currValue };
      }
      return prev;
    }, {} as { label: string; value: number });

  return (
    <DataStateHandler
      loading={loading || offsetDataLoading}
      error={error || offsetDataError}>
      {(company && (
        <div>
          <div className={classes.parentLayer}>
            {company.imageUris?.large ? (
              <img
                className={classes.photo}
                alt="Company"
                src={company.imageUris?.large}
                style={ImageUtils.ObjectPositionStyle(company.imagePosition)}
              />
            ) : (
              <DefaultImage height={285} />
            )}
            {!isSticky && (
              <Box marginTop={2} marginLeft={2}>
                <Typography variant="h1" className={classes.back}>
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    size="large">
                    <SvgIcon component={SVG.Back} htmlColor="#fff" />
                  </IconButton>
                </Typography>
              </Box>
            )}
          </div>
          <Box marginLeft={6} marginRight={6} marginTop={5} position="relative">
            <Box position="absolute" marginTop={-9}>
              <img
                alt="Company Logo"
                src={company.logoUris?.medium || DefaultLogo}
                className={classes.logo}
              />
            </Box>
            <Grid container spacing={6}>
              <Grid
                ref={stickyRef}
                item
                xs={12}
                style={{
                  position: 'sticky',
                  top: 0,
                  backgroundColor: '#fff',
                  zIndex: 1,
                }}>
                <Grid
                  container
                  spacing={0}
                  className={classes.header}
                  justifyContent="space-between"
                  alignItems="flex-start"
                  style={{ position: 'relative' }}>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        {isSticky && (
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            className={classes.stickyClose}
                            size="large">
                            <SvgIcon component={SVG.Back} />
                          </IconButton>
                        )}
                        <Typography
                          variant="h1"
                          style={{ marginLeft: isSticky ? 30 : 0 }}>
                          {company.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={2}>
                          {company.phoneNumber && (
                            <Grid item>
                              <Button
                                variant="text"
                                href={`tel:${company.phoneNumber}`}
                                startIcon={<Phone />}>
                                <Typography
                                  variant="h3"
                                  className={classes.linkButton}>
                                  {NumberUtils.phoneNumberFormat(
                                    company.phoneNumber,
                                  )}
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                          {company.primaryEmail && (
                            <Grid item>
                              <Button
                                variant="text"
                                href={`mailto:${company.primaryEmail}`}
                                startIcon={<Email />}>
                                <Typography
                                  variant="h3"
                                  className={classes.linkButton}>
                                  {company.primaryEmail}
                                </Typography>
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container>
                      <Grid item>
                        {userHasAccess('Configuration.Company', 'VIEW') && (
                          <Link
                            style={{ textDecoration: 'none' }}
                            to={`/settings/company/${company.id}`}>
                            <Button
                              size="small"
                              style={{ height: 32 }}
                              variant="outlined"
                              color="primary">
                              <SVG.SettingsOutline />
                              Client Settings
                            </Button>
                          </Link>
                        )}
                      </Grid>
                      <Grid item>
                        {userHasAccess('Api.AllowGlobalQueries', 'VIEW') && (
                          <Chip
                            className={classes.impersonateChip}
                            onClick={() => {
                              setImpersonatedCompanyId(company.id);
                            }}
                            label={
                              <span>
                                <SVG.Eyeglasses />
                                View as {company.name}
                              </span>
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2} direction="column">
                <Grid item>
                  <Typography variant="h3">Overview</Typography>
                </Grid>
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid item xs={3}>
                    <OverviewCard
                      title="MEMBER CARBON FOOTPRINT"
                      data={
                        company.score
                          ? NumberUtils.format(
                              company.score / 1000 || 0,
                              'number',
                            )
                          : 'N/A'
                      }
                      subtitle={company.score ? MTCO2E : ''}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <OverviewCard
                      title="MEMBERS"
                      loading={loading}
                      data={NumberUtils.format(
                        company.currentEmployeeMetrics?.enabledEmployeeCount,
                        'integer',
                      )}
                      subtitle={`${NumberUtils.format(
                        participation,
                        'wholePercent',
                      )} Participation`}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <OverviewCard
                      title="TOP CATEGORY ENGAGEMENT"
                      loading={ytdOffsetDataLoading}
                      data={topCategory?.label || 'N/A'}
                      subtitle={
                        (topCategory?.value &&
                          `${NumberUtils.format(
                            topCategory.value,
                            'currency',
                          )} Purchased`) ||
                        ''
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    {loadBudgetInvestments && (
                      <OverviewCard
                        title="TOTAL BUDGET"
                        tooltipId="clientTotalBudget"
                        data={NumberUtils.format(
                          currentBudget?.total || 0,
                          'currency',
                        )}
                        subtitle={`${NumberUtils.format(
                          currentBudget?.remaining || 0,
                          'currency',
                        )} Remaining`}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {loadBudgetInvestments && (
                  <TotalInvestments
                    spent={currentBudget.spent || 0}
                    investments={totalInvestments}
                    context="client-details"
                  />
                )}
              </Grid>
              <Grid item container spacing={5}>
                <Grid item xs={6} container spacing={2} direction="column">
                  <Grid item>
                    <Grid container justifyContent="space-between">
                      <Typography variant="h3">
                        Categories of Interest
                      </Typography>
                      <Box p={1}>
                        <AppInfo id="clientCategoriesOfInterest" />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    style={{ flexGrow: 1 }}>
                    <CategoryCards categoryIds={company.categoryIds} />
                  </Grid>
                </Grid>
                <Grid item xs={6} container spacing={2} direction="column">
                  <Grid item>
                    <Typography variant="h3">Location</Typography>
                  </Grid>
                  <Grid item style={{ flexGrow: 1 }}>
                    <LocationMap
                      latitude={company.latitude}
                      longitude={company.longitude}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <ProjectGrids companyId={companyId} />
              </Grid>
            </Grid>
          </Box>
        </div>
      )) ||
        null}
    </DataStateHandler>
  );
};

export default ClientDetails;
