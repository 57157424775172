import React from 'react';

const redeemProjectCreditsEnabled = (
  level: number | undefined,
  size: number,
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3195 62.9206C3.97452 53.5756 3.97452 38.4244 13.3195 29.0794L29.0756 13.3233C38.4206 3.97833 53.5718 3.97833 62.9168 13.3233L78.6729 29.0794C88.0179 38.4244 88.0179 53.5756 78.6729 62.9206L62.9168 78.6767C53.5718 88.0217 38.4206 88.0217 29.0756 78.6767L13.3195 62.9206Z"
      fill="white"
    />
    <path
      d="M15.925 61.4321C7.40319 52.9103 7.40319 39.0936 15.925 30.5718L30.5661 15.9307C39.088 7.40884 52.9046 7.40885 61.4265 15.9307L76.0676 30.5718C84.5894 39.0936 84.5894 52.9103 76.0676 61.4321L61.4265 76.0732C52.9046 84.5951 39.088 84.5951 30.5661 76.0732L15.925 61.4321Z"
      fill="url(#paint0_angular_5400_144350)"
    />
    <mask
      id="mask0_5400_144350"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9245 61.4262C7.4027 52.9044 7.4027 39.0878 15.9245 30.5659L30.5657 15.9248C39.0875 7.40298 52.9041 7.40299 61.426 15.9248L76.0671 30.5659C84.5889 39.0878 84.5889 52.9044 76.0671 61.4262L61.426 76.0674C52.9041 84.5892 39.0875 84.5892 30.5657 76.0674L15.9245 61.4262Z"
        fill="url(#paint1_angular_5400_144350)"
      />
    </mask>
    <g mask="url(#mask0_5400_144350)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8888 13.5838C46.8888 12.9971 47.364 12.5215 47.9502 12.5215H62.5497C68.9009 12.5215 74.0496 17.6744 74.0496 24.0308C74.0496 30.3872 68.9009 35.5401 62.5497 35.5401H27.6196C21.6176 35.5401 16.752 40.4097 16.752 46.4166C16.752 52.4236 21.6176 57.2931 27.6196 57.2931L110.08 57.2931C110.666 57.2931 111.141 57.7688 111.141 58.3555C111.141 58.9422 110.666 59.4178 110.08 59.4178L27.6196 59.4178C20.4452 59.4178 14.6292 53.597 14.6292 46.4166C14.6292 39.2363 20.4452 33.4154 27.6196 33.4154H62.5497C67.7285 33.4154 71.9267 29.2138 71.9267 24.0308C71.9267 18.8478 67.7285 14.6461 62.5497 14.6461L47.9502 14.6461C47.364 14.6461 46.8888 14.1705 46.8888 13.5838Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88961 53.8896 2.88961 38.1104 12.6219 28.3781L28.3781 12.6219C38.1104 2.88961 53.8896 2.88961 63.6219 12.6219L79.3781 28.3781C89.1104 38.1104 89.1104 53.8896 79.3781 63.6219L63.6219 79.3781C53.8896 89.1104 38.1104 89.1104 28.3781 79.3781L12.6219 63.6219ZM14.0247 29.7808C5.06706 38.7384 5.06706 53.2616 14.0247 62.2192L29.7808 77.9753C38.7384 86.9329 53.2616 86.9329 62.2192 77.9753L77.9753 62.2192C86.9329 53.2616 86.9329 38.7384 77.9753 29.7808L62.2192 14.0247C53.2616 5.06707 38.7384 5.06706 29.7808 14.0247L14.0247 29.7808Z"
      fill="#022131"
    />
    <path
      d="M24.2143 47.1879C23.4396 46.4132 23.4396 45.1571 24.2143 44.3824L44.554 24.0427C45.3287 23.268 46.5848 23.268 47.3595 24.0427L67.6992 44.3824C68.474 45.1571 68.474 46.4132 67.6992 47.1879L47.3595 67.5276C46.5848 68.3024 45.3287 68.3024 44.554 67.5276L24.2143 47.1879Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5135 47.8893C22.3515 46.7272 22.3515 44.8431 23.5135 43.681L43.8533 23.3413C45.0154 22.1792 46.8994 22.1792 48.0615 23.3413L68.4013 43.681C69.5633 44.8431 69.5633 46.7272 68.4013 47.8893L48.0615 68.229C46.8994 69.3911 45.0154 69.3911 43.8533 68.229L23.5135 47.8893ZM24.9163 45.0838C24.5289 45.4711 24.5289 46.0992 24.9163 46.4865L45.256 66.8263C45.6434 67.2136 46.2714 67.2136 46.6588 66.8263L66.9985 46.4865C67.3859 46.0992 67.3859 45.4711 66.9985 45.0838L46.6588 24.744C46.2714 24.3567 45.6434 24.3567 45.256 24.744L24.9163 45.0838Z"
      fill="#022131"
    />
    <path
      d="M52.9475 35.582H50.6321C49.1488 35.582 47.8103 36.1593 46.7973 37.0614C47.7017 38.216 48.2806 39.6593 48.2806 41.2469C50.9215 40.7057 52.9475 38.3964 52.9475 35.582ZM44.8437 41.3551V45.3963C44.8437 45.721 45.097 45.9736 45.4226 45.9736H46.5802C46.8697 45.9736 47.1591 45.721 47.1591 45.3963V41.3551C47.1591 38.1799 44.5543 35.582 41.3707 35.582H39.0553C39.0553 38.7933 41.6239 41.3551 44.8437 41.3551ZM56.0226 47.453C55.5884 47.0561 54.9372 47.0921 54.4669 47.453L51.1386 50.0869C50.7406 50.4478 50.198 50.5921 49.6915 50.5921H45.4226C45.097 50.5921 44.8437 50.3395 44.8437 50.0148C44.8437 49.7261 45.097 49.4375 45.4226 49.4375H48.2444C48.8232 49.4375 49.3659 49.0766 49.4383 48.4993C49.5468 47.7777 49.0041 47.1282 48.3168 47.1282H42.5284C41.5516 47.1282 40.5748 47.489 39.815 48.1024L38.1509 49.4375H36.1611C35.8355 49.4375 35.5823 49.7261 35.5823 50.0148V53.4786C35.5823 53.8034 35.8355 54.0559 36.1611 54.0559H49.0403C49.583 54.0559 50.0895 53.9116 50.4874 53.5508L55.9864 49.1849C56.529 48.7519 56.5652 47.922 56.0226 47.453Z"
      fill="#2D63F9"
    />
    {level ? (
      <g clipPath="url(#clip0_5400_144350)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#2EF699" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#022131"
        />
        <text
          x="74"
          y="77.5"
          fill="#022131"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_5400_144350"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(35.211 58.2722) scale(32.7323)">
        <stop offset="2.69661e-05" stopColor="#2D63F9" />
        <stop offset="0.771063" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_5400_144350"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1861 45.9918) rotate(-8.79121) scale(53.9478 54.824)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_5400_144350">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default redeemProjectCreditsEnabled;
