import { Grid } from '@mui/material';
import React from 'react';
import Emissions from './Emissions/Emissions';
import Scope3Container from './Emissions/Scope3/Scope3Container';
import { EmissionsQueryResult } from '../../../types/dashboard/types';
import AverageEmployeeFootprint from './AverageEmployeeFootprint/AverageEmployeeFootprint';
import BehaviorCredits from './BehaviorCredits/BehaviorCredits';
import EmployeeCommuting from './EmployeeCommuting/EmployeeCommuting';
import ProjectedEmissions from './ProjectedEmissions/ProjectedEmissions';

type Props = {
  emissions: EmissionsQueryResult;
  targetMt: number;
};

const CarbonFooprint: React.FC<Props> = ({ emissions, targetMt }) => (
  <Grid
    container
    spacing={2}
    direction="column"
    style={{ height: '100%', marginBottom: 32 }}>
    <Grid item>
      <ProjectedEmissions />
    </Grid>
    <Grid item>
      {emissions && <Emissions emissions={emissions} currentGoal={targetMt} />}
    </Grid>
    <Grid item>
      <Scope3Container emissions={emissions} currentGoal={targetMt} />
    </Grid>
    <Grid item>
      <Grid container spacing={4} direction="row">
        <Grid item xs={6}>
          <AverageEmployeeFootprint />
        </Grid>
        <Grid item xs={6}>
          <BehaviorCredits type="carbon" />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <EmployeeCommuting />
    </Grid>
    {/* <Grid item>
      <CheckFeature featureIds={['individualpurchases']}>
        <EmployeeOffsetPurchases />
      </CheckFeature>
    </Grid>
    <Grid item>
      <CheckFeature featureIds={['marketplace']}>
        <OffsetOpportunities
          totalInvestments={totalInvestments}
          categoryInvestments={categoryInvestments}
          currentBudget={currentBudget}
        />
      </CheckFeature>
    </Grid> */}
  </Grid>
);

export default CarbonFooprint;
