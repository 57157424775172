import React, { useState } from 'react';
import { FormControl, Grid, MenuItem, Typography } from '@mui/material';
import { sortBy } from 'lodash';
import { useQuery } from '@apollo/client';
import { ResponsiveBar } from '@nivo/bar';
import { patternLinesDef } from '@nivo/core';
import TotalsTooltip from '../../common/ChartTooltips/TotalsTooltip';
import {
  GET_CLIENT_PURCHASE_DATA,
  TotalSpendByPeriodResponse,
} from '../../../graphql/trader/dashboard';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import { ProjectPurchaseType } from '../../../types/project/types';
import { ProjectPurchaseStatus } from '../../../types/offsetProject/types';
import ChartLegend from '../../common/ChartLegend/ChartLegend';
import AppCard from '../../common/AppCard/AppCard';
import SelectMenu from '../../common/SelectMenu/SelectMenu';
import { CompanyFiscalYearSettings } from '../../../types/company/types';
import NumberUtils from '../../../utils/numberUtils';
import { FULL_Y_BAR_TOOLTIP_LAYERS } from '../../common/ChartTooltips/FullYBarTooltipLayer';
import { Colors } from '../../../theme/shared';

type ChartData = TotalSpendByPeriodResponse & {
  month: string;
  Individuals?: number;
  Clients?: number;
  MT?: number;
  MWh?: number;
  Pending?: number;
};

type Props = {
  fiscalYear?: CompanyFiscalYearSettings;
};

const SalesPerformance: React.FC<Props> = ({ fiscalYear }) => {
  const [amountOrMt, setAmountOrMt] = useState<'AMOUNT' | 'MT' | 'MWh'>(
    'AMOUNT',
  );

  const {
    loading,
    error,
    data: { totalSpendByPeriod = [] } = {},
  } = useQuery(GET_CLIENT_PURCHASE_DATA, {
    variables: {
      dateGrain: 'MONTHLY',
      purchaseTypes: [ProjectPurchaseType.COMPANY, ProjectPurchaseType.USER],
      statuses: [
        ProjectPurchaseStatus.COMPLETE,
        ProjectPurchaseStatus.REQUESTED,
      ],
      startDate: fiscalYear?.startDate as string,
      endDate: fiscalYear?.endDate as string,
    },
    fetchPolicy: 'network-only',
  });

  const formatValue = (value: number, compact = true) => {
    let formatted;
    if (amountOrMt === 'AMOUNT') {
      if (compact) {
        formatted = new Intl.NumberFormat('en-us', {
          maximumFractionDigits: 0,
          currency: 'USD',
          style: 'currency',
          notation: 'compact',
        }).format(value || 0);
      } else {
        formatted = NumberUtils.format(value, 'currency');
      }
    } else {
      formatted = NumberUtils.format(value, 'integer');
    }

    return `${formatted} ${amountOrMt === 'AMOUNT' ? '' : amountOrMt}`;
  };

  const data = sortBy(
    totalSpendByPeriod.reduce((acc, cur) => {
      let date = acc.find((x) => x.date === cur.date);

      if (!date) {
        date = {
          ...cur,
          month: formatDateStringUtc(cur.date, 'MMM, yyyy'),
        };

        acc.push(date);
      }

      // const value = amountOrMt === 'AMOUNT' ? cur.total : cur.mt;
      let value;
      switch (amountOrMt) {
        case 'AMOUNT':
          value = cur.total;
          break;
        case 'MT':
          value = cur.mt;
          break;
        default:
          value = cur.mwh;
          break;
      }

      if (cur.purchaseType === ProjectPurchaseType.USER) {
        date.Individuals = value;
      } else if (cur.status === ProjectPurchaseStatus.COMPLETE) {
        date.Clients = value;
        date.MT = (date.MT || 0) + cur.totalMt;
        date.MWh = (date.MWh || 0) + cur.totalMWh;
      } else {
        date.Pending = value;
      }

      return acc;
    }, [] as ChartData[]),
    (x) => x.date,
  );

  let totalSold;

  switch (amountOrMt) {
    case 'MT':
      totalSold = totalSpendByPeriod.reduce((acc, cur) => acc + cur.mt, 0);
      break;
    case 'MWh':
      totalSold = totalSpendByPeriod.reduce((acc, cur) => acc + cur.mwh, 0);
      break;
    default:
      totalSold = totalSpendByPeriod.reduce((acc, cur) => acc + cur.total, 0);
      break;
  }

  const buckets: {
    label: string;
    color?: string;
    imageUri?: string;
  }[] = [
    ...(amountOrMt === 'AMOUNT'
      ? [
          {
            label: 'MT',
            color: '#3E7DA2',
          },
          {
            label: 'MWh',
            color: '#398360',
          },
        ]
      : [
          {
            label: 'Clients',
            color: '#3E7DA2',
          },
        ]),
    {
      label: 'Individuals',
      color: '#0A3751',
    },
    {
      label: 'Pending',
      imageUri:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEJSURBVHgBHY+9SsNQAIVPb1EThDRWY0kcDOIkCBZUBCcnn8NH8Al8AwUXR1c3HZ0MCGJpCxV/QBHRYtPYpmlu25Cf9trb3J7pwDec8wFpTqya2Y/HVisY+uflD94cJNd+FJmCZQQsGlrtYFVXSw0XhAC7hoa7b4dKUrZIFFk+2zE0tZcM8dah2NY1eFGMRhCq+3rhEvd1l/fif371WuePv91pv6h+8h8/4mKSbBbytBmEGIwYNrQcXto+luYlLMizeG53VZJF5un2y8bh2gpixlD962Av/dBPRqjY7g15cFpH63mF5uZmUHE8bC0vQkl72XZpwtixMEE6Zwq109I790Lm04hZwk6wCR/Nh2tWn3MzAAAAAElFTkSuQmCC',
    },
  ];

  const getTooltipData = (nivoData: { [k: string]: string | number }) =>
    buckets.map((x) => ({
      ...x,
      id: x.label,
      value: nivoData[x.label] as number,
    }));

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Typography
          variant="h3"
          style={{ marginTop: 'auto', marginBottom: 16 }}>
          Sales Performance
        </Typography>
        <Grid item>
          <FormControl variant="outlined">
            <SelectMenu
              id="demo-simple-select"
              value={amountOrMt}
              onChange={(e) =>
                setAmountOrMt(e.target.value as 'AMOUNT' | 'MT' | 'MWh')
              }>
              <MenuItem key="value" value="AMOUNT">
                $ USD
              </MenuItem>
              <MenuItem key="mt" value="MT">
                VCC
              </MenuItem>
              <MenuItem key="mwh" value="MWh">
                REC
              </MenuItem>
            </SelectMenu>
          </FormControl>
        </Grid>
      </Grid>
      <AppCard
        height={395}
        headerProps={{ tooltipId: 'offsetSalesPerformance' }}>
        <DataStateHandler loading={loading} error={error}>
          <div style={{ padding: 8 }}>
            <div style={{ height: 240 }}>
              <ResponsiveBar
                data={data}
                defs={[
                  patternLinesDef('pendingPattern', {
                    spacing: 5,
                    rotation: -60,
                    lineWidth: 2,
                    background: '#f1f8fd',
                    color: '#7fc1d2',
                  }),
                ]}
                fill={[{ match: { id: 'Pending' }, id: 'pendingPattern' }]}
                keys={buckets.map((x) => x.label)}
                indexBy="month"
                margin={{ top: 10, right: 0, bottom: 50, left: 50 }}
                padding={0.8}
                innerPadding={1}
                gridYValues={5}
                groupMode="stacked"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={buckets.map((x) => x.color || '#FFF')}
                colorBy="id"
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                valueFormat=" >(,~%"
                axisTop={null}
                axisRight={null}
                axisBottom={{ tickSize: 0, tickPadding: 24 }}
                axisLeft={{
                  format: (value) => formatValue(value),
                  tickSize: 0,
                  tickPadding: 1,
                  tickRotation: 0,
                  legend: '',
                  legendPosition: 'middle',
                  legendOffset: -20,
                  tickValues: 5,
                }}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: 'color',
                  modifiers: [['darker', 1.6]],
                }}
                layers={FULL_Y_BAR_TOOLTIP_LAYERS}
                tooltip={(props) => (
                  <TotalsTooltip
                    format={amountOrMt === 'AMOUNT' ? 'currency' : 'integer'}
                    suffix={amountOrMt === 'AMOUNT' ? '' : ` ${amountOrMt}`}
                    items={getTooltipData(props.data)}
                  />
                )}
                role="application"
                ariaLabel="Project Category Interest Chart"
              />
            </div>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs style={{ marginTop: 'auto' }}>
                <ChartLegend keys={buckets} />
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Typography variant="h1" style={{ color: Colors.CobaltBlue }}>
                  {formatValue(totalSold, false)}
                </Typography>
                <Typography variant="h4" style={{ color: '#6B6B6B' }}>
                  Sold in FY {fiscalYear?.fiscalYear}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </DataStateHandler>
      </AppCard>
    </>
  );
};

export default SalesPerformance;
