import {
  Box,
  Typography,
  IconButton,
  Grid,
  SvgIcon,
  Divider,
} from '@mui/material';
import React from 'react';
import SVG from '../../../assets/svg';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';
import { formatDateStringUtc } from '../../../utils/dateUtils';
import NumberUtils from '../../../utils/numberUtils';
import CompanyNameWithLogo from '../../common/Company/CompanyNameWithLogo';
import CategoryIconDiamond from '../../common/Project/CategoryIconDiamond';
import { useStyles } from './FulfillOffsetRequestDetails';

type Props = {
  projectHistory: ProjectPurchaseHistory;
  onClose: () => void;
};

const OffsetRequestProjectDetails: React.FC<Props> = ({
  projectHistory,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item className={classes.element}>
        <Grid justifyContent="space-around" container alignItems="center">
          <Box position="relative" right={24}>
            <IconButton aria-label="close" onClick={onClose} size="large">
              <SvgIcon component={SVG.Back} />
            </IconButton>
          </Box>
          <Typography
            variant="h3"
            className={classes.title}
            color="textPrimary">
            {projectHistory.project.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Divider className={classes.divider} color="textSecondary" />
      </Grid>
      <Grid container spacing={3}>
        <Grid item />
        <Grid container direction="row" alignItems="center">
          <Grid item className={classes.diamondIcon}>
            <CategoryIconDiamond
              categoryId={projectHistory.projectCategoryId}
              size="small"
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              color="textPrimary"
              className={classes.indicator}>
              {projectHistory.project.indicator}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" color="textSecondary">
            {projectHistory.project.title}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary">
            Requested Date:
          </Typography>
        </Grid>
        <Grid item xs={4} color="textSecondary">
          <Typography variant="body2">
            {formatDateStringUtc(projectHistory.requestedDate, 'MM/DD/YYYY')}
          </Typography>
        </Grid>
        <Grid item />
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary">
            Company:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            <CompanyNameWithLogo companyId={projectHistory.companyId} />
          </Typography>
        </Grid>
        <Grid item />
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary">
            Purchase Price:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            {NumberUtils.formatQuantityAndUom(
              projectHistory.quantity,
              projectHistory.uom,
              projectHistory.project.uom,
            )}
          </Typography>
        </Grid>
        <Grid item />
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary">
            Amount Requested:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            {projectHistory.quantity}{' '}
            {projectHistory.uom || projectHistory.project.uom}
          </Typography>
        </Grid>
        <Grid item />
        <Grid item xs={4}>
          <Typography variant="body2" color="textPrimary">
            Total Purchase Price:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="body2" color="textSecondary">
            {NumberUtils.format(
              projectHistory.price * projectHistory.quantity,
              'currency',
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row" className={classes.element} />
    </>
  );
};

export default OffsetRequestProjectDetails;
