import { useMutation } from '@apollo/client';
import { Grid, Paper, Typography, IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import React, { useContext } from 'react';

import moment from 'moment';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import AppTextField from '../AppTextField';
import SVG from '../../../assets/svg';
import SmallPaddedButton from '../Buttons/SmallPaddedButton';
import { CONTACT_TRADER } from '../../../graphql/projects/projectMutations';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { MarketplaceContext } from '../../../contexts/MarketplaceContext';
import { ContactTraderType } from '../../../types/common';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px 64px',
    width: 620,
  },
  spacing: {
    paddingBottom: '24px',
  },
  form: {
    padding: '16px 0px !important',
    maxWidth: '100% !important',
  },
  paper: {
    padding: 24,
  },
  header: {
    paddingLeft: '0 !important',
    borderBottom: `2px solid ${theme.palette.divider}`,
    marginBottom: 16,
    paddingBottom: 8,
  },
  right: {
    marginLeft: 'auto',
  },
}));

const schema = yup.object().shape({
  content: yup.string().required(),
});

type Props = {
  onClose: () => void;
  header: string;
  subHeading?: string;
};

const AskATrader: React.FC<Props> = ({ onClose, header, subHeading }) => {
  const [contact, { loading: contactLoading, error: contactError }] =
    useMutation(CONTACT_TRADER);

  const { user } = useContext(AuthorizationContext);

  const { project, setProject } = useContext(MarketplaceContext);

  const classes = useStyles();
  const initialValues = {
    content: project
      ? `I'm interested in ${project.title}, please contact me with further details!`
      : '',
  };

  const handleClose = () => {
    setProject(undefined);
    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={async (values) => {
        const type: ContactTraderType = project
          ? 'PROJECT_INTEREST'
          : 'GENERAL';
        await contact({
          variables: {
            ...values,
            projectId: project?.id || undefined,
            type,
          },
          onCompleted: () => handleClose(),
        });
      }}
      validateOnMount>
      {(props) => (
        <Grid container direction="column" className={classes.root} spacing={2}>
          <DataStateHandler loading={contactLoading} error={contactError}>
            <>
              <Grid item className={classes.header}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      size="large">
                      <SvgIcon component={SVG.Back} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between">
                      <Typography variant="h1">{header}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.right}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="space-around">
                      <>
                        <Grid item>
                          <SmallPaddedButton
                            onClick={handleClose}
                            variant="outlined"
                            color="primary">
                            Cancel
                          </SmallPaddedButton>
                        </Grid>
                        <Grid item>
                          <SmallPaddedButton
                            disabled={props.isSubmitting}
                            variant="contained"
                            color="primary"
                            onClick={props.submitForm}>
                            Submit
                          </SmallPaddedButton>
                        </Grid>
                      </>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item className={classes.form}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    {project && (
                      <Grid item xs={12}>
                        <Grid container>
                          {subHeading && (
                            <Grid item xs={12} className={classes.spacing}>
                              <Typography variant="h2">{subHeading}</Typography>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Typography
                              variant="h3"
                              color="textPrimary"
                              gutterBottom>
                              {project.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} className={classes.spacing}>
                            {project.smallSummary}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary">
                              Start Date
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {project?.startDate
                                ? moment(project.startDate).format(
                                    'MMM Do, YYYY',
                                  )
                                : ' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary">
                              End Date
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {project?.endDate
                                ? moment(project.endDate).format('MMM Do, YYYY')
                                : ' '}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary">
                              Project ID
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {project.projectId}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary">
                              Qualification
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                              {project.indicator}
                            </Typography>
                          </Grid>{' '}
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">
                        Requested by:
                      </Typography>
                      <Typography variant="body1" color="textPrimary">
                        {user?.name}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {user?.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2">
                        How can we help you?
                      </Typography>
                      <Field
                        name="content"
                        label=""
                        multiline
                        rows={10}
                        component={AppTextField}
                        disabled={props.isSubmitting}
                        placeholder=""
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          </DataStateHandler>
        </Grid>
      )}
    </Formik>
  );
};

export default AskATrader;
