import pollCompletedDisabled from './PollCompletedDisabled';
import pollCompletedEnabled from './PollCompletedEnabled';

const pollCompleted = ({
  level,
  disabled,
  size,
}: {
  level: number | undefined;
  disabled: boolean;
  size: number;
}) =>
  disabled
    ? pollCompletedDisabled(level, size)
    : pollCompletedEnabled(level, size);

export default pollCompleted;
