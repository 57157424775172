import React, { useContext, useState } from 'react';
import { Grid, Paper, Typography, FormControl, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@nivo/core';

import { ApolloError, useQuery } from '@apollo/client';
import {
  CategoryData,
  DateGraphCoordinate,
  EmissionsQueryResult,
} from '../../../../types/dashboard/types';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';
import CategoryDataBarChart from '../../../common/CategoryDataBarChart/CategoryDataBarChart';
import ChartLegend from '../../../common/ChartLegend/ChartLegend';
import TotalFootprintChart from './TotalFootprintChart/TotalFootprintChart';
import AppInfo from '../../../common/AppInfo';
import { BEHAVIOR_CREDITS_BY_COMPANY } from '../../../../graphql/dashboard/emissions/emissions';
import { dateRange } from '../../../../utils/dateUtils';
import { AuthorizationContext } from '../../../../contexts/AuthorizationContext';
import {
  ALL_COMPANIES,
  DashboardContext,
} from '../../../../contexts/DashboardContext';
import ZeroMeCompanySelect from '../../../common/CompanySelect/ZeroMeCompanySelect';
import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import { Colors } from '../../../../theme/shared';

const useStyles = makeStyles(() => ({
  section: {
    marginBottom: '24px',
  },
  paperContainer: {
    background: Colors.DarkGrey,
    padding: 16,
  },
  chartLabel: {
    color: '#FFFFFF',
    opacity: 0.5,
    marginBottom: 8,
  },
}));

type Props = {
  emissions: EmissionsQueryResult;
  currentGoal: number;
  includeCompanySelect?: boolean;
  loading?: boolean;
  error?: boolean | string | ApolloError;
};

export const EmissionsTheme: Theme = {
  textColor: '#FFFFFF',
  grid: {
    line: {
      stroke: '#D1FFBB',
      strokeWidth: 1,
      strokeOpacity: 0.2,
    },
  },
};

const Emissions: React.FC<Props> = ({
  emissions,
  currentGoal,
  includeCompanySelect,
  loading,
  error,
}) => {
  const [tabValue, setTabValue] = useState('totalfootprint');
  const [purposeType, setPurposeType] = useState('all');
  const classes = useStyles();
  const handleTabChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    setPurposeType('all');
    setTabValue(event.target.value as string);
  };

  const category = emissions.categories?.find(
    (c) => c.id === tabValue,
  ) as CategoryData;

  const purposeTypes = category?.purposeTypes || [];

  // returned data is in kg, divide by 1000
  // for mt values
  const formatted = {
    ...category,
    data:
      category?.data.map((d) => ({
        id: d.id,
        label: d.label,
        color: d.color,
        data: d.data
          .filter((x) => x.purposeType === purposeType)
          .map((x) => ({
            date: x.date,
            value: x.value / 1000,
          })),
      })) || [],
  };

  const keys = formatted?.data.map((d) => ({
    label: d.label,
    color: d.color || '',
  }));

  let ChartComponent = null;

  // Map emissions.totalFootprint to a similar structure also containing behavior credits.
  const displayTotalFootPrint = tabValue === 'totalfootprint';
  const { company } = useContext(AuthorizationContext);
  const { division, includeChildren } = useContext(DashboardContext);
  const userCompId: string = division || company.id;

  // Only execute if displaying the totalfootprint view
  const { data: { behaviorCreditsByCompany = [] } = {} } = useQuery(
    BEHAVIOR_CREDITS_BY_COMPANY,
    {
      variables: {
        companyId: userCompId === ALL_COMPANIES ? undefined : userCompId,
        parameters: {
          dateGrain: 'MONTHLY',
          includeChildren,
          ...dateRange(12, 0),
        },
      },
      fetchPolicy: 'network-only',
      skip: !displayTotalFootPrint,
    },
  );

  // Offsets, emissions, and behavior credits structure
  const footprints = Array<
    DateGraphCoordinate & {
      offsetValue: number;
      emissions: number;
      behaviorCredits: number;
    }
  >();

  // Only execute if displaying the totalfootprint view
  if (displayTotalFootPrint) {
    // Flatten the behavior credits and convert to MT
    const flatBehaviorCredits = behaviorCreditsByCompany
      .filter((item) => item.groupId === 'all')
      .flatMap((row) =>
        row.data
          ? row.data.map((c) => ({
              date: c.date,
              value: c.value,
            }))
          : [],
      );

    // Iterate over all footprints and match the behavior credit items based on date
    emissions.totalFootprint?.forEach((footprint) => {
      const bc = flatBehaviorCredits.find((f) => f.date === footprint.date);
      if (bc) {
        footprints.push({
          value: footprint.value,
          offsetValue: footprint.offsetValue,
          emissions: footprint.value,
          date: footprint.date,
          behaviorCredits: bc.value,
        });
      } else {
        footprints.push({
          value: footprint.value,
          offsetValue: footprint.offsetValue,
          emissions: footprint.value,
          date: footprint.date,
          behaviorCredits: 0,
        });
      }
    });
  }

  switch (tabValue) {
    case 'totalfootprint': {
      ChartComponent = (
        <TotalFootprintChart
          footprints={footprints}
          goal={currentGoal}
          includeBehaviorCredits
        />
      );
      break;
    }
    default:
      ChartComponent = (
        <>
          <CategoryDataBarChart
            categoryData={formatted}
            theme={EmissionsTheme}
            format="precisionNumber"
          />
          <ChartLegend keys={keys} labelColor="#FFFFFF" />
        </>
      );
      break;
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.section}>
        <Typography variant="h3" color="textPrimary">
          Member Carbon Emissions
        </Typography>
        {includeCompanySelect && (
          <ZeroMeCompanySelect includeAllCompanies defaultToAll />
        )}
      </Grid>
      <DataStateHandler loading={loading} error={error}>
        <Paper className={classes.paperContainer}>
          <Grid container justifyContent="space-between">
            <Typography variant="caption" className={classes.chartLabel}>
              MT C0₂E
            </Typography>
            <Grid item>
              <FormControl
                sx={{ bottom: 4, position: 'relative' }}
                variant="outlined">
                <Grid container>
                  <SelectMenu
                    id="category-select"
                    key="category-select"
                    value={tabValue}
                    inputStyle={{ background: 'white' }}
                    onChange={handleTabChange}>
                    <MenuItem value="totalfootprint" key="totalfootprint">
                      Total Footprint
                    </MenuItem>
                    {emissions.categories?.map((cat) => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.label}
                      </MenuItem>
                    ))}
                  </SelectMenu>
                  {purposeTypes.length ? (
                    <SelectMenu
                      id="purpose-select"
                      key="purpose-select"
                      value={purposeType}
                      inputStyle={{ background: 'white' }}
                      onChange={(e) => setPurposeType(e.target.value)}>
                      {purposeTypes.map((p) => (
                        <MenuItem key={p.id} value={p.id}>
                          {p.label}
                        </MenuItem>
                      ))}
                    </SelectMenu>
                  ) : null}
                </Grid>
              </FormControl>
              <AppInfo id="employeecarbonemissions" iconColor="white" />
            </Grid>
          </Grid>
          <div>{ChartComponent}</div>
        </Paper>
      </DataStateHandler>
    </>
  );
};

export default Emissions;
