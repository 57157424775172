import React, { useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_PROJECTED_EMISSIONS } from '../../../graphql/user/user';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import SVG from '../../../assets/svg-v2';
import { Colors } from '../../../theme/shared';

const NetEmissionsPreview: React.FC = () => {
  const { user, company } = useContext(AuthorizationContext);
  const skip = !user?.id || !company.id;

  const {
    loading,
    error,
    data: { projectedEmissions } = {},
  } = useQuery(GET_PROJECTED_EMISSIONS, {
    variables: {
      userId: user?.id,
      companyId: company.id,
    },
    skip,
  });

  return (
    <DataStateHandler loading={loading || skip} error={error}>
      <Box style={{ position: 'relative', lineHeight: 0 }}>
        <SVG.HalfEllipse width={88} height={52} fill={Colors.ElectricGreen} />
        <Grid
          container
          flexDirection="column"
          style={{
            position: 'absolute',
            bottom: 0,
          }}
          justifyContent="center"
          alignItems="center">
          <Typography variant="h1" style={{ fontSize: 26, lineHeight: '26px' }}>
            {new Intl.NumberFormat('en-us', {
              maximumFractionDigits: 1,
            }).format(
              ((projectedEmissions?.currentFootprint || 0) -
                (projectedEmissions?.habitReductions || 0) -
                (projectedEmissions?.offsetReductions || 0)) /
                1000,
            )}
          </Typography>
        </Grid>
      </Box>
      <div style={{ marginTop: 6 }}>
        <Typography
          variant="subtitle2"
          style={{ fontSize: '12px', lineHeight: '12px', textAlign: 'center' }}
          noWrap>
          Net Emissions
        </Typography>
      </div>
    </DataStateHandler>
  );
};

export default NetEmissionsPreview;
