import { useQuery } from '@apollo/client';
import { Box, Button, Drawer, Grid, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { subYears } from 'date-fns';
import React, { useContext, useState } from 'react';
import DataStateHandler from '../../components/common/DataStateHandler/DataStateHandler';
import SelectMenu from '../../components/common/SelectMenu/SelectMenu';
import MainLayout from '../../components/layouts/MainLayout';
import ClientAnnualBudgetCard from '../../components/trader/TraderDashboard/ClientAnnualBudgetCard';
import Header from '../../components/trader/TraderDashboard/Header';
import MarketplaceParticipationCard from '../../components/trader/TraderDashboard/MarketplaceParticipationCard';
import PurchaseRequestsBox from '../../components/trader/TraderDashboard/PurchaseRequestsBox';
import PurchasesByCategoryCard from '../../components/trader/TraderDashboard/PurchasesByCategoryCard';
import SalesPerformanceCard from '../../components/trader/TraderDashboard/SalesPerformanceCard';
import TopViewedProjectsCard from '../../components/trader/TraderDashboard/TopViewedProjectsCard';
import YtdInvestmentCard from '../../components/trader/TraderDashboard/YtdInvestmentCard';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import { GET_TRADER_BUDGET_SETTINGS } from '../../graphql/trader/dashboard';
import SVG from '../../assets/svg';
import { GET_MARKETPLACE_PURCHASEHIST } from '../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../types/offsetProject/types';
import AppBadge from '../../components/common/AppBadge';
import EmployeeEmissionsCard from '../../components/trader/TraderDashboard/EmployeeEmissionsCard';

const useStyles = makeStyles((theme) => ({
  boxHeader: {
    fontSize: 12,
  },
  section: {
    marginTop: 40,
  },
  root: {
    position: 'relative',
    maxHeight: 'calc(100vh - 8px)',
  },
  content: {
    overflow: 'auto',
    padding: '24px 16px',
  },
  drawer: {
    maxWidth: 620,
    [theme.breakpoints.up('lg')]: {
      width: 620,
    },
  },
  button: {
    background: '#F1F8FD',
    padding: 8,
    borderRadius: 8,
    position: 'relative',
    top: 6,
  },
  forward: {
    transform: 'rotate(180deg)',
  },
  icon: {
    color: '#000000',
    marginRight: 8,
  },
}));

type DateRangeSelection = 'THIS_YEAR' | 'LAST_YEAR' | 'TWO_YEARS_AGO';

const OffsetDashboard: React.FC = () => {
  const classes = useStyles();
  const { user, loading } = useContext(AuthorizationContext);
  const [rangeSelection, setRangeSelection] =
    useState<DateRangeSelection>('THIS_YEAR');

  const [drawerOpen, setDrawerOpen] = useState(false);

  const twoYearsAgoLabel = subYears(new Date(), 2).getFullYear();

  let budgetDate = '';
  switch (rangeSelection) {
    case 'THIS_YEAR':
      budgetDate = new Date().toISOString().substring(0, 10);
      break;
    case 'LAST_YEAR':
      budgetDate = subYears(new Date(), 1).toISOString().substring(0, 10);
      break;
    case 'TWO_YEARS_AGO':
      budgetDate = subYears(new Date(), 2).toISOString().substring(0, 10);
      break;
    default:
      break;
  }

  const {
    data: {
      traderSettings: { budgetSettings = undefined, budget = undefined } = {},
    } = {},
    loading: budgetLoading,
    error: budgetError,
  } = useQuery(GET_TRADER_BUDGET_SETTINGS, {
    fetchPolicy: 'network-only',
    variables: {
      budgetDate,
    },
  });

  const { loading: countLoading, data: { offsetProjects = [] } = {} } =
    useQuery<{ offsetProjects: ProjectPurchaseHistory[] }>(
      GET_MARKETPLACE_PURCHASEHIST(),
      {
        variables: {
          filter: {
            status: ['REQUESTED', 'APPROVED'],
            isDemoProject: ['false'],
          },
        },
        fetchPolicy: 'network-only',
      },
    );

  const fiscalYear = budgetSettings?.fiscalYears.find(
    (x) => x.startDate <= budgetDate && x.endDate >= budgetDate,
  );

  return (
    <MainLayout childBoxStyle={{ paddingBottom: 0 }}>
      <Box display="flex" flexDirection="column" className={classes.root}>
        <Header
          actions={
            <>
              <SelectMenu
                id="year"
                value={rangeSelection}
                onChange={(e) =>
                  setRangeSelection(e.target.value as DateRangeSelection)
                }>
                <MenuItem value="THIS_YEAR">Year to Date</MenuItem>
                <MenuItem value="LAST_YEAR">Last Year</MenuItem>
                <MenuItem value="TWO_YEARS_AGO">{twoYearsAgoLabel}</MenuItem>
              </SelectMenu>
              <Button
                onClick={() => setDrawerOpen(true)}
                className={classes.button}>
                <SVG.Sidenav.ProjectManagement className={classes.icon} />
                {!countLoading && (
                  <AppBadge actionsCount={offsetProjects.length} />
                )}
                <SVG.Back className={classes.forward} />
              </Button>
            </>
          }>
          Hey, {user?.firstName}!
        </Header>
        <DataStateHandler
          loading={loading || budgetLoading}
          error={budgetError}>
          <Box display="flex" style={{ overflow: 'hidden' }}>
            {fiscalYear ? (
              <Box flexGrow={1} className={classes.content}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <YtdInvestmentCard traderBudgetStats={budget} />
                  </Grid>
                  <Grid item xs={4}>
                    <MarketplaceParticipationCard fiscalYear={fiscalYear} />
                  </Grid>
                  <Grid item xs={4}>
                    <ClientAnnualBudgetCard budgetDate={budgetDate} />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <EmployeeEmissionsCard
                      endDate={new Date(fiscalYear.endDate)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <SalesPerformanceCard fiscalYear={fiscalYear} />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <PurchasesByCategoryCard fiscalYear={fiscalYear} />
                  </Grid>
                  <Grid item xs={12} className={classes.section}>
                    <TopViewedProjectsCard />
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Box py={4} width="100%">
                <Typography align="center" variant="h2">
                  No fiscal year data was found for this timeframe.
                </Typography>
              </Box>
            )}
          </Box>
        </DataStateHandler>
        <Drawer
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          anchor="right">
          <Box className={classes.drawer}>
            <PurchaseRequestsBox onClose={() => setDrawerOpen(false)} />
          </Box>
        </Drawer>
      </Box>
    </MainLayout>
  );
};

export default OffsetDashboard;
