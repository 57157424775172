import React from 'react';
import { orderBy, take } from 'lodash';

import AppCard from '../../common/AppCard/AppCard';
import { LikedProject } from '../../../types/dashboard/types';
import SVG from '../../../assets/svg-v2';
import ProjectBubbleChart from '../../common/ProjectBubbleChart';

type Props = {
  likedProjects: (LikedProject & { isSponsored?: boolean })[];
};

const LikedProjectsChart: React.FC<Props> = ({ likedProjects }) => {
  const data = {
    id: 'root',
    name: 'root',
    color: '#fff',
    children: take(
      orderBy(
        likedProjects.map(
          (x) => ({
            id: x.likedProjectId,
            name: x.project.title,
            count: x.count,
            icon: (
              <SVG.Thumb
                style={{
                  top: 4,
                  position: 'relative',
                  color: x.isSponsored ? '#FFF' : x.project.categoryData.color,
                }}
              />
            ),
            color: x.isSponsored
              ? x.project.categoryData.color
              : x.project.categoryData.highlightColor,
            textColor: x.isSponsored ? '#FFF' : x.project.categoryData.color,
            borderColor: x.project.categoryData.color,
            categoryId: x.project.category,
          }),
          'count',
        ),
        ['desc'],
      ),
      10,
    ),
  };
  return (
    <AppCard
      headerProps={{
        title: 'LIKED PROJECTS',
        tooltipId: 'companyLikedProjects',
      }}
      height={675}>
      <ProjectBubbleChart data={data} />
    </AppCard>
  );
};

export default LikedProjectsChart;
