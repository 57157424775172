import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import NumberUtils from '../../../../utils/numberUtils';
import AppCard from '../../../common/AppCard/AppCard';
import AppInfo from '../../../common/AppInfo';
import Delta from '../../../common/Delta';
import NoDataAvailable from '../../../common/NoDataAvailable';

const useStyles = makeStyles((theme) => ({
  value: {
    color: theme.palette.buttons.primary.active,
    textAlign: 'center',
  },
  label: {
    fontSize: 12,
  },
  metric: {
    margin: '20px 8px',
    width: '100%',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 6,
  },
}));

type Props = {
  title: string;
  icon: JSX.Element;
  value: number;
  delta?: number;
  tooltipId?: string;
  footerLabel?: string;
  footerMetric?: number;
};

const ParticipationActivityCard: React.FC<Props> = ({
  title,
  icon,
  value,
  delta,
  tooltipId,
  footerLabel,
  footerMetric,
}) => {
  const classes = useStyles();
  return (
    <AppCard height={178}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Typography variant="subtitle1" className={classes.label}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>{icon}</Grid>
                <Grid item>{title}</Grid>
              </Grid>
            </Typography>
            {tooltipId ? <AppInfo id={tooltipId} /> : null}
          </Grid>
        </Grid>
        {value ? (
          <Grid item>
            <Grid container alignItems="center">
              <Grid item className={classes.metric}>
                <Typography variant="h1" className={classes.value}>
                  {NumberUtils.format(value || 0, 'integer')}
                </Typography>
              </Grid>
              {delta ? <Delta value={delta} /> : null}
            </Grid>
          </Grid>
        ) : (
          <Grid item>
            <NoDataAvailable size="small" />
          </Grid>
        )}

        {footerLabel && value ? (
          <Grid item className={classes.footer}>
            <Grid container justifyContent="space-between">
              <Typography variant="caption">{footerLabel}</Typography>
              <Typography variant="caption" className={classes.value}>
                {footerMetric}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </AppCard>
  );
};

export default ParticipationActivityCard;
