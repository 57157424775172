import React from 'react';
import { useQuestionnaire } from '../../../contexts/QuestionnaireContext';
import Loading from '../Loading';
import AppInfo from '../AppInfo';

export type ProcessingIndicatorProps = {
  questionId: string;
};

export function ProcessingIndicator(props: ProcessingIndicatorProps) {
  const { questionId } = props;

  const state = useQuestionnaire();

  if (state.loading) {
    return <Loading paddingY={0} />;
  }

  return <AppInfo id={`question_${questionId}`} />;
}
