import React from 'react';
import { Chip } from '@mui/material';
import { useQuery } from '@apollo/client';
import DataStateHandler from '../DataStateHandler/DataStateHandler';
import { ALL_ROLES } from '../../../graphql/auth';

type Props = {
  roleId: string | undefined;
};

const RoleChip: React.FC<Props> = ({ roleId }) => {
  const { loading, data: { allRolesAndAbilities } = {} } = useQuery(ALL_ROLES);

  const role = allRolesAndAbilities?.roles.find((x) => x.id === roleId);

  return (
    <DataStateHandler loading={loading} loadingPaddingY={0}>
      <Chip
        size="small"
        style={{
          backgroundColor: role?.backgroundColor,
          color: role?.fontColor,
        }}
        label={role?.name || roleId}
      />
    </DataStateHandler>
  );
};

export default RoleChip;
