import { Box } from '@mui/material';
import React from 'react';
import SVG from '../../../assets/svg';

const Logo: React.FC = () => (
  <Box display="flex" color="#FFF">
    <SVG.Logo />
  </Box>
);

export default Logo;
