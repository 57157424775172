import React, { createContext, FC, useState, useMemo } from 'react';

type SidenavContext = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const initialContext: SidenavContext = {
  isDrawerOpen: true,
  setIsDrawerOpen: () => null,
};
export const SidenavContext = createContext(initialContext);

const SidenavProvider: FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const value = useMemo(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
    }),
    [isDrawerOpen, setIsDrawerOpen],
  );

  return (
    <SidenavContext.Provider value={value}>{children}</SidenavContext.Provider>
  );
};

export default SidenavProvider;
