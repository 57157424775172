import React from 'react';
import moment from 'moment';

import StatusChip from './StatusChip';
import { Contest, ContestStatus } from '../../types/contest/types';

type Props = {
  contest: Contest;
};

const ChallengeStatusChip: React.FC<Props> = ({ contest }) => {
  const now = moment();
  const isActive = now.isSameOrAfter(moment(contest.duration.startDate));
  const isComplete = now.isSameOrAfter(moment(contest.duration.endDate));

  let status = ContestStatus.DRAFT;
  if (!contest.isDraft) {
    if (isComplete) {
      status = ContestStatus.COMPLETE;
    } else if (isActive) {
      status = ContestStatus.ACTIVE;
    } else {
      status = ContestStatus.PENDING;
    }
  }

  return <StatusChip status={status} label={status} />;
};

export default ChallengeStatusChip;
