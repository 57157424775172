import React from 'react';

const articleReadEnabled = (level: number | undefined, size: number) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3236 62.9206C3.97867 53.5756 3.97867 38.4244 13.3236 29.0794L29.0798 13.3233C38.4247 3.97833 53.5759 3.97833 62.9209 13.3233L78.677 29.0794C88.022 38.4244 88.022 53.5756 78.677 62.9206L62.9209 78.6767C53.5759 88.0217 38.4247 88.0217 29.0798 78.6767L13.3236 62.9206Z"
      fill="white"
    />
    <path
      d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
      fill="url(#paint0_angular_5400_144240)"
    />
    <mask
      id="mask0_5400_144240"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
        fill="url(#paint1_angular_5400_144240)"
      />
    </mask>
    <g mask="url(#mask0_5400_144240)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.1192 31.4217C30.7043 31.0069 30.032 31.0066 29.6174 31.4211L19.294 41.7445C14.8031 46.2355 14.806 53.5198 19.3007 58.0145C23.7954 62.5091 31.0797 62.5121 35.5706 58.0211L91.8131 1.77867C96.0572 -2.46539 102.941 -2.46256 107.189 1.78499C111.436 6.03254 111.439 12.9163 107.195 17.1604L48.8868 75.4685C48.4723 75.883 48.4725 76.5553 48.8874 76.9702C49.3023 77.385 49.9746 77.3853 50.3891 76.9708L108.697 18.6628C113.77 13.5897 113.767 5.36115 108.69 0.283882C103.612 -4.79339 95.3839 -4.79677 90.3108 0.276327L34.0683 56.5188C30.4064 60.1807 24.4668 60.1783 20.8018 56.5133C17.1369 52.8484 17.1344 46.9088 20.7964 43.2469L31.1198 32.9235C31.5343 32.509 31.534 31.8366 31.1192 31.4217Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88961 53.8896 2.88961 38.1104 12.6219 28.3781L28.3781 12.6219C38.1104 2.88961 53.8896 2.88961 63.6219 12.6219L79.3781 28.3781C89.1104 38.1104 89.1104 53.8896 79.3781 63.6219L63.6219 79.3781C53.8896 89.1104 38.1104 89.1104 28.3781 79.3781L12.6219 63.6219ZM14.0247 29.7808C5.06706 38.7384 5.06706 53.2616 14.0247 62.2192L29.7808 77.9753C38.7384 86.9329 53.2616 86.9329 62.2192 77.9753L77.9753 62.2192C86.9329 53.2616 86.9329 38.7384 77.9753 29.7808L62.2192 14.0247C53.2616 5.06707 38.7384 5.06706 29.7808 14.0247L14.0247 29.7808Z"
      fill="#022131"
    />
    <path
      d="M23.1885 34.4301C23.1885 31.9165 25.2261 29.8789 27.7396 29.8789H64.149C66.6625 29.8789 68.7002 31.9165 68.7002 34.4301V56.4274C68.7002 58.9409 66.6625 60.9786 64.149 60.9786H27.7396C25.2261 60.9786 23.1885 58.9409 23.1885 56.4274V34.4301Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.149 31.396H27.7396C26.0639 31.396 24.7055 32.7544 24.7055 34.4301V56.4274C24.7055 58.1031 26.0639 59.4615 27.7396 59.4615H64.149C65.8247 59.4615 67.1831 58.1031 67.1831 56.4274V34.4301C67.1831 32.7544 65.8247 31.396 64.149 31.396ZM27.7396 29.8789C25.2261 29.8789 23.1885 31.9165 23.1885 34.4301V56.4274C23.1885 58.9409 25.2261 60.9786 27.7396 60.9786H64.149C66.6625 60.9786 68.7002 58.9409 68.7002 56.4274V34.4301C68.7002 31.9165 66.6625 29.8789 64.149 29.8789H27.7396Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44.3804 61.3537L41.3463 73.2575L39.8762 72.8828L42.9103 60.979L44.3804 61.3537Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2908 73.114C32.2908 72.6951 32.6304 72.3555 33.0493 72.3555H58.8393C59.2582 72.3555 59.5978 72.6951 59.5978 73.114C59.5978 73.5329 59.2582 73.8725 58.8393 73.8725H33.0493C32.6304 73.8725 32.2908 73.5329 32.2908 73.114Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0125 72.8827L48.9784 60.9788L47.5083 61.3535L50.5424 73.2574L52.0125 72.8827Z"
      fill="#022131"
    />
    <path
      d="M42.9099 60.2207H48.9781V64.0133H42.9099V60.2207Z"
      fill="#022131"
    />
    <path d="M31.4961 4.58594H60.3848V56.2881H31.4961V4.58594Z" fill="white" />
    <path
      d="M30.1318 12.1426L34.5658 4.46281L35.8796 5.22134L31.4456 12.9011L30.1318 12.1426Z"
      fill="#022131"
    />
    <path
      d="M33.5857 14.043L39.0173 4.63513L40.3311 5.39365L34.8995 14.8015L33.5857 14.043Z"
      fill="#022131"
    />
    <path
      d="M38.0312 14.2246L43.5682 4.63439L44.882 5.39292L39.3451 14.9831L38.0312 14.2246Z"
      fill="#022131"
    />
    <path
      d="M42.636 14.1328L48.1198 4.63457L49.4336 5.3931L43.9498 14.8913L42.636 14.1328Z"
      fill="#022131"
    />
    <path
      d="M47.1887 14.1309L52.6709 4.63545L53.9847 5.39397L48.5025 14.8894L47.1887 14.1309Z"
      fill="#022131"
    />
    <path
      d="M51.6919 14.2129L57.222 4.63452L58.5358 5.39305L53.0057 14.9714L51.6919 14.2129Z"
      fill="#022131"
    />
    <path
      d="M56.3474 14.0332L60.4899 6.85823L61.8037 7.61676L57.6612 14.7917L56.3474 14.0332Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9932 5.34518C29.9932 4.50733 30.6724 3.82812 31.5102 3.82812H60.3697C61.2076 3.82812 61.8868 4.50733 61.8868 5.34518V60.2564H60.3697V5.34518L31.5102 5.34518V60.2564H29.9932V5.34518Z"
      fill="#022131"
    />
    <path
      d="M31.5015 13.7383H60.3255V15.2553H31.5015V13.7383Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 23.0144C34.5806 22.5955 34.9202 22.2559 35.3391 22.2559H56.5779C56.9968 22.2559 57.3364 22.5955 57.3364 23.0144C57.3364 23.4333 56.9968 23.7729 56.5779 23.7729H35.3391C34.9202 23.7729 34.5806 23.4333 34.5806 23.0144Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 26.9812C34.5806 26.5623 34.9202 26.2227 35.3391 26.2227H56.5779C56.9968 26.2227 57.3364 26.5623 57.3364 26.9812C57.3364 27.4001 56.9968 27.7397 56.5779 27.7397H35.3391C34.9202 27.7397 34.5806 27.4001 34.5806 26.9812Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 30.9949C34.5806 30.5759 34.9202 30.2363 35.3391 30.2363H53.937C54.3559 30.2363 54.6955 30.5759 54.6955 30.9949C54.6955 31.4138 54.3559 31.7534 53.937 31.7534H35.3391C34.9202 31.7534 34.5806 31.4138 34.5806 30.9949Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 38.8855C34.5806 38.4666 34.9202 38.127 35.3391 38.127H56.5779C56.9968 38.127 57.3364 38.4666 57.3364 38.8855C57.3364 39.3044 56.9968 39.644 56.5779 39.644H35.3391C34.9202 39.644 34.5806 39.3044 34.5806 38.8855Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 42.8523C34.5806 42.4334 34.9202 42.0938 35.3391 42.0938H56.5779C56.9968 42.0938 57.3364 42.4334 57.3364 42.8523C57.3364 43.2712 56.9968 43.6108 56.5779 43.6108H35.3391C34.9202 43.6108 34.5806 43.2712 34.5806 42.8523Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 46.8191C34.5806 46.4002 34.9202 46.0605 35.3391 46.0605H56.5407C56.9596 46.0605 57.2992 46.4002 57.2992 46.8191C57.2992 47.238 56.9596 47.5776 56.5407 47.5776H35.3391C34.9202 47.5776 34.5806 47.238 34.5806 46.8191Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 50.7878C34.5806 50.3689 34.9202 50.0293 35.3391 50.0293H56.5407C56.9596 50.0293 57.2992 50.3689 57.2992 50.7878C57.2992 51.2067 56.9596 51.5464 56.5407 51.5464H35.3391C34.9202 51.5464 34.5806 51.2067 34.5806 50.7878Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 54.8015C34.5806 54.3826 34.9202 54.043 35.3391 54.043H45.5059C45.9249 54.043 46.2645 54.3826 46.2645 54.8015C46.2645 55.2204 45.9249 55.56 45.5059 55.56H35.3391C34.9202 55.56 34.5806 55.2204 34.5806 54.8015Z"
      fill="#2D63F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5806 19.0476C34.5806 18.6287 34.9202 18.2891 35.3391 18.2891H38.3732C38.7921 18.2891 39.1317 18.6287 39.1317 19.0476C39.1317 19.4665 38.7921 19.8061 38.3732 19.8061H35.3391C34.9202 19.8061 34.5806 19.4665 34.5806 19.0476Z"
      fill="#2D63F9"
    />
    <path
      d="M73.3819 56.9598C73.3819 61.9868 69.3067 66.0621 64.2796 66.0621C59.2525 66.0621 55.1772 61.9868 55.1772 56.9598C55.1772 51.9327 59.2525 47.8574 64.2796 47.8574C69.3067 47.8574 73.3819 51.9327 73.3819 56.9598Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.7068 49.4047C65.4254 49.2543 67.1454 49.6778 68.5904 50.6073L67.7498 51.8779C66.5937 51.1343 65.2177 50.7955 63.8429 50.9158C62.468 51.0362 61.1741 51.6087 60.169 52.5415C59.1639 53.4743 58.506 54.7131 58.301 56.059C58.096 57.4048 58.3557 58.7795 59.0386 59.9623C59.7214 61.145 60.7876 62.0671 62.066 62.5804C63.3443 63.0937 64.7606 63.1683 66.0871 62.7924C67.4137 62.4165 68.5736 61.6118 69.3805 60.5076C70.1873 59.4034 70.5943 58.0638 70.536 56.704L72.0732 56.64C72.1461 58.3396 71.6374 60.0141 70.6288 61.3944C69.6202 62.7746 68.1703 63.7805 66.5121 64.2504C64.8539 64.7203 63.0836 64.627 61.4857 63.9854C59.8878 63.3438 58.555 62.1912 57.7014 60.7128C56.8479 59.2344 56.5232 57.5159 56.7794 55.8336C57.0357 54.1513 57.8581 52.6028 59.1145 51.4368C60.3708 50.2709 61.9882 49.5552 63.7068 49.4047ZM64.9196 59.3967L73.5742 50.8633L72.5091 49.783L64.3871 57.7914L61.0731 54.5238L60.008 55.6041L63.8545 59.3967L64.3871 59.9218L64.9196 59.3967Z"
      fill="#2D63F9"
    />
    {level ? (
      <g clipPath="url(#clip0_5400_144240)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#2EF699" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#022131"
        />
        <text
          x="74"
          y="77.5"
          fill="#022131"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_5400_144240"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.0019 60.876) rotate(-42.126) scale(32.3476 40.1619)">
        <stop offset="0.0543017" stopColor="#2EF699" />
        <stop offset="0.411101" stopColor="#2D63F9" />
        <stop offset="0.606103" stopColor="#2D63F9" />
        <stop offset="0.758954" stopColor="#2EF699" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_5400_144240"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1908 45.9977) rotate(-8.79121) scale(53.9478 54.824)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <clipPath id="clip0_5400_144240">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default articleReadEnabled;
