import { Collapse, Grid, ListItemButton, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import React, { useState } from 'react';

import SVG from '../../../assets/svg-v2';

const useStyles = makeStyles(() => ({
  item: {
    minHeight: 72,
  },
  title: {
    maxWidth: '90%',
  },
}));

type Props = {
  question: {
    id: string;
    questionText: string;
    questionAnswer: string;
  };
};

const FAQListItem: React.FC<Props> = ({ question }) => {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();
  const toggleExpand = () => setExpand(!expand);

  // replace any mobile links with their portal counterparts
  const translate = (content: string) =>
    content.replace(
      'msauth.com.zerome.mobile://discover/communicationProfile/',
      '/member/discover/',
    );
  return (
    <ListItemButton className={classes.item} onClick={toggleExpand}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography className={classes.title} variant="h3">
              {translate(question.questionText)}
            </Typography>
            <SVG.ThickCaret />
          </Grid>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Collapse in={expand}>
            <Box
              dangerouslySetInnerHTML={{
                __html: translate(question.questionAnswer),
              }}
            />
          </Collapse>
        </Grid>
      </Grid>
    </ListItemButton>
  );
};

export default FAQListItem;
