import React from 'react';

const questionsCompletedWorkDisabled = (
  level: number | undefined,
  size: number,
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.3236 62.9206C3.97867 53.5756 3.97867 38.4244 13.3236 29.0794L29.0798 13.3233C38.4247 3.97833 53.5759 3.97833 62.9209 13.3233L78.677 29.0794C88.022 38.4244 88.022 53.5756 78.677 62.9206L62.9209 78.6767C53.5759 88.0217 38.4247 88.0217 29.0798 78.6767L13.3236 62.9206Z"
      fill="white"
    />
    <path
      d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
      fill="url(#paint0_angular_5400_144463)"
    />
    <mask
      id="mask0_5400_144463"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="9"
      y="9"
      width="74"
      height="74">
      <path
        d="M15.9292 61.4321C7.40734 52.9103 7.40734 39.0936 15.9292 30.5718L30.5703 15.9307C39.0921 7.40884 52.9088 7.40885 61.4306 15.9307L76.0717 30.5718C84.5936 39.0936 84.5936 52.9103 76.0717 61.4321L61.4306 76.0732C52.9088 84.5951 39.0921 84.5951 30.5703 76.0732L15.9292 61.4321Z"
        fill="url(#paint1_angular_5400_144463)"
      />
    </mask>
    <g mask="url(#mask0_5400_144463)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.4745 16.8065C66.4745 16.2198 65.9993 15.7441 65.4131 15.7441L39.2211 15.7441C32.8699 15.7441 27.7213 20.897 27.7213 27.2534C27.7213 33.6098 32.8699 38.7627 39.2211 38.7627L75.7659 38.7627C81.7679 38.7627 86.6335 43.6323 86.6335 49.6393C86.6335 55.6462 78.4586 67.5703 72.4566 67.5703L13.5869 67.5703C13.0007 67.5703 12.5255 68.0459 12.5255 68.6326C12.5255 69.2193 13.0007 69.6949 13.5869 69.6949L72.4566 69.6949C79.6311 69.6949 88.7564 56.8196 88.7564 49.6393C88.7563 42.4589 82.9403 36.6381 75.7659 36.6381L39.2211 36.6381C34.0424 36.6381 29.8442 32.4364 29.8442 27.2534C29.8442 22.0704 34.0424 17.8688 39.2211 17.8688L65.4131 17.8688C65.9993 17.8688 66.4745 17.3932 66.4745 16.8065Z"
        fill="white"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6219 63.6219C2.88961 53.8896 2.88961 38.1104 12.6219 28.3781L28.3781 12.6219C38.1104 2.88961 53.8896 2.88961 63.6219 12.6219L79.3781 28.3781C89.1104 38.1104 89.1104 53.8896 79.3781 63.6219L63.6219 79.3781C53.8896 89.1104 38.1104 89.1104 28.3781 79.3781L12.6219 63.6219ZM14.0247 29.7808C5.06706 38.7384 5.06706 53.2616 14.0247 62.2192L29.7808 77.9753C38.7384 86.9329 53.2616 86.9329 62.2192 77.9753L77.9753 62.2192C86.9329 53.2616 86.9329 38.7384 77.9753 29.7808L62.2192 14.0247C53.2616 5.06707 38.7384 5.06706 29.7808 14.0247L14.0247 29.7808Z"
      fill="#6B6B6B"
    />
    <path
      d="M57.437 10.5175C57.437 9.74845 58.0605 9.125 58.8295 9.125H69.2733C70.0424 9.125 70.6658 9.74845 70.6658 10.5175V22.3538H57.437V10.5175Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.5271 11.2148H61.6159V13.3036H59.5271V11.2148ZM59.5271 14.6961H61.6159V16.7849H59.5271V14.6961ZM65.0971 11.2148H63.0084V13.3036H65.0971V11.2148ZM63.0084 14.6961H65.0971V16.7849H63.0084V14.6961ZM65.0971 18.1774H63.0084V22.3549H65.0971V18.1774ZM66.4897 11.2148H68.5785V13.3036H66.4897V11.2148ZM68.5785 14.6961H66.4897V16.7849H68.5785V14.6961Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.8309 8.77734C57.8696 8.77734 57.0903 9.55665 57.0903 10.518V22.0061H56.0442V22.7024H57.0903V22.7024H71.0154V22.7024H72.058V22.0061H71.0154V10.518C71.0154 9.55665 70.236 8.77734 69.2747 8.77734H58.8309ZM70.3191 22.0061V10.518C70.3191 9.94118 69.8515 9.4736 69.2747 9.4736H58.8309C58.2541 9.4736 57.7866 9.94118 57.7866 10.518V22.0061H70.3191Z"
      fill="#022131"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.6565 23.1367H55.6104V21.5725H56.6565V10.5183C56.6565 9.31734 57.6301 8.34375 58.831 8.34375H69.2748C70.4758 8.34375 71.4494 9.31734 71.4494 10.5183V21.5725H72.4921V23.1367H71.4494H56.6565ZM58.831 9.9079C58.4939 9.9079 58.2206 10.1812 58.2206 10.5183V21.5725H69.8853V10.5183C69.8853 10.1812 69.612 9.9079 69.2748 9.9079H58.831Z"
      fill="#6B6B6B"
    />
    <path
      d="M32.8111 61.991H24.504L24.0701 60.5652V43.2072L25.8679 40.6035L24.814 37.3178L26.8598 35.582H31.6952L34.0509 28.8868L36.9026 27.4609H55.9965L58.7242 29.5067L61.0179 35.582H66.1633L67.8991 38.1857L66.6593 40.6035L68.2711 43.2072V61.991H61.0179V54.5519H32.8111V61.991Z"
      fill="white"
    />
    <path
      d="M55.7345 27.4609H36.7646L33.355 30.6226L30.9993 39.4256H62.0578L58.7101 29.4447L55.7345 27.4609Z"
      fill="url(#paint2_angular_5400_144463)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5122 44.8269C23.5122 41.571 26.0998 38.9316 29.2918 38.9316H63.3558C66.5478 38.9316 69.1354 41.571 69.1354 44.8269V54.7312H23.5122V44.8269ZM29.2918 40.0313C26.6952 40.0313 24.5903 42.1783 24.5903 44.8269V53.6315H68.0573V44.8269C68.0573 42.1783 65.9524 40.0313 63.3558 40.0313H29.2918Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0784 44.8272C23.0784 41.3399 25.8522 38.498 29.292 38.498H63.3559C66.7956 38.498 69.5694 41.3399 69.5694 44.8272V55.1655H23.0784V44.8272ZM29.292 39.3659C26.3477 39.3659 23.9463 41.8029 23.9463 44.8272V54.2976H68.7015V44.8272C68.7015 41.8029 66.3001 39.3659 63.3559 39.3659H29.292ZM29.292 40.4656C26.9431 40.4656 25.0243 42.4102 25.0243 44.8272V53.1979H67.6235V44.8272C67.6235 42.4102 65.7047 40.4656 63.3559 40.4656H29.292ZM24.1564 44.8272C24.1564 41.9472 26.4476 39.5977 29.292 39.5977H63.3559C66.2002 39.5977 68.4914 41.9472 68.4914 44.8272V54.0658H24.1564V44.8272Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5903 54.1816V60.8634C24.5903 61.2978 24.9355 61.6499 25.3614 61.6499H31.9121C32.338 61.6499 32.6833 61.2978 32.6833 60.8634V54.1816H33.7613V60.8634C33.7613 61.9051 32.9334 62.7496 31.9121 62.7496H25.3614C24.3401 62.7496 23.5122 61.9051 23.5122 60.8634V54.1816H24.5903Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.0784 53.7461H25.0243V60.8618C25.0243 61.0647 25.1834 61.2144 25.3615 61.2144H31.9123C32.0904 61.2144 32.2494 61.0647 32.2494 60.8618V53.7461H34.1954V60.8618C34.1954 62.135 33.1813 63.1819 31.9123 63.1819H25.3615C24.0925 63.1819 23.0784 62.135 23.0784 60.8618V53.7461ZM23.9463 54.614V60.8618C23.9463 61.672 24.588 62.314 25.3615 62.314H31.9123C32.6858 62.314 33.3275 61.672 33.3275 60.8618V54.614H33.1173V60.8618C33.1173 61.5277 32.5859 62.0823 31.9123 62.0823H25.3615C24.6879 62.0823 24.1564 61.5277 24.1564 60.8618V54.614H23.9463Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.2734 54.1816V60.8634C61.2734 61.2978 61.6186 61.6499 62.0445 61.6499H67.2851C67.711 61.6499 68.0562 61.2978 68.0562 60.8634V54.1816H69.1343V60.8634C69.1343 61.9051 68.3064 62.7496 67.2851 62.7496H62.0445C61.0232 62.7496 60.1953 61.9051 60.1953 60.8634V54.1816H61.2734Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.7615 53.7461H61.7074V60.8618C61.7074 61.0647 61.8665 61.2144 62.0446 61.2144H67.2852C67.4634 61.2144 67.6224 61.0647 67.6224 60.8618V53.7461H69.5683V60.8618C69.5683 62.135 68.5542 63.1819 67.2852 63.1819H62.0446C60.7756 63.1819 59.7615 62.135 59.7615 60.8618V53.7461ZM60.6294 54.614V60.8618C60.6294 61.672 61.2711 62.314 62.0446 62.314H67.2852C68.0588 62.314 68.7004 61.672 68.7004 60.8618V54.614H68.4903V60.8618C68.4903 61.5277 67.9588 62.0823 67.2852 62.0823H62.0446C61.371 62.0823 60.8395 61.5277 60.8395 60.8618V54.614H60.6294Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4514 44.4121C58.9401 44.4121 57.715 45.6617 57.715 47.2032C57.715 48.7447 58.9401 49.9943 60.4514 49.9943C61.9626 49.9943 63.1877 48.7447 63.1877 47.2032C63.1877 45.6617 61.9626 44.4121 60.4514 44.4121ZM56.637 47.2032C56.637 45.0544 58.3447 43.3125 60.4514 43.3125C62.558 43.3125 64.2658 45.0544 64.2658 47.2032C64.2658 49.352 62.558 51.0939 60.4514 51.0939C58.3447 51.0939 56.637 49.352 56.637 47.2032Z"
      fill="#A9A9A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.2031 47.2036C56.2031 44.8233 58.0971 42.8789 60.4515 42.8789C62.8058 42.8789 64.6998 44.8233 64.6998 47.2036C64.6998 49.5838 62.8058 51.5282 60.4515 51.5282C58.0971 51.5282 56.2031 49.5838 56.2031 47.2036ZM60.4515 43.7468C58.5926 43.7468 57.071 45.2863 57.071 47.2036C57.071 49.1208 58.5926 50.6603 60.4515 50.6603C62.3104 50.6603 63.8319 49.1208 63.8319 47.2036C63.8319 45.2863 62.3104 43.7468 60.4515 43.7468ZM60.4515 44.8464C59.188 44.8464 58.1491 45.8936 58.1491 47.2036C58.1491 48.5135 59.188 49.5607 60.4515 49.5607C61.715 49.5607 62.7539 48.5135 62.7539 47.2036C62.7539 45.8936 61.715 44.8464 60.4515 44.8464ZM57.2812 47.2036C57.2812 45.4306 58.6925 43.9785 60.4515 43.9785C62.2104 43.9785 63.6218 45.4306 63.6218 47.2036C63.6218 48.9765 62.2104 50.4286 60.4515 50.4286C58.6925 50.4286 57.2812 48.9765 57.2812 47.2036Z"
      fill="#A9A9A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.2492 44.4121C32.738 44.4121 31.5129 45.6617 31.5129 47.2032C31.5129 48.7447 32.738 49.9943 34.2492 49.9943C35.7605 49.9943 36.9856 48.7447 36.9856 47.2032C36.9856 45.6617 35.7605 44.4121 34.2492 44.4121ZM30.4348 47.2032C30.4348 45.0544 32.1426 43.3125 34.2492 43.3125C36.3559 43.3125 38.0636 45.0544 38.0636 47.2032C38.0636 49.352 36.3559 51.0939 34.2492 51.0939C32.1426 51.0939 30.4348 49.352 30.4348 47.2032Z"
      fill="#A9A9A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.001 47.2036C30.001 44.8233 31.895 42.8789 34.2493 42.8789C36.6037 42.8789 38.4977 44.8233 38.4977 47.2036C38.4977 49.5838 36.6037 51.5282 34.2493 51.5282C31.895 51.5282 30.001 49.5838 30.001 47.2036ZM34.2493 43.7468C32.3904 43.7468 30.8689 45.2863 30.8689 47.2036C30.8689 49.1208 32.3904 50.6603 34.2493 50.6603C36.1082 50.6603 37.6298 49.1208 37.6298 47.2036C37.6298 45.2863 36.1082 43.7468 34.2493 43.7468ZM34.2493 44.8464C32.9858 44.8464 31.9469 45.8936 31.9469 47.2036C31.9469 48.5135 32.9858 49.5607 34.2493 49.5607C35.5128 49.5607 36.5517 48.5135 36.5517 47.2036C36.5517 45.8936 35.5128 44.8464 34.2493 44.8464ZM31.079 47.2036C31.079 45.4306 32.4904 43.9785 34.2493 43.9785C36.0083 43.9785 37.4196 45.4306 37.4196 47.2036C37.4196 48.9765 36.0083 50.4286 34.2493 50.4286C32.4904 50.4286 31.079 48.9765 31.079 47.2036Z"
      fill="#A9A9A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.3614 28.0039C35.9016 28.0039 34.6056 28.9567 34.144 30.3693L31.1094 39.6552C31.0152 39.9432 30.71 40.0989 30.4276 40.0029C30.1451 39.9069 29.9925 39.5955 30.0866 39.3074L33.1213 30.0215C33.7296 28.1599 35.4376 26.9043 37.3614 26.9043H55.2783C57.2021 26.9043 58.91 28.1599 59.5184 30.0215L62.553 39.3074C62.6471 39.5955 62.4945 39.9069 62.2121 40.0029C61.9297 40.0989 61.6244 39.9432 61.5302 39.6552L58.4956 30.3693C58.034 28.9567 56.738 28.0039 55.2783 28.0039H37.3614Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.7088 29.8871C33.374 27.8515 35.2453 26.4707 37.3614 26.4707H55.2783C57.3944 26.4707 59.2657 27.8515 59.9309 29.8871L62.9655 39.173C63.1315 39.6809 62.8652 40.2395 62.3518 40.4141C61.8343 40.59 61.285 40.3018 61.1178 39.7903L58.0832 30.5044C57.6784 29.2659 56.5457 28.4382 55.2783 28.4382H37.3614C36.094 28.4382 34.9613 29.2659 34.5565 30.5044L31.5219 39.7903C31.3548 40.3018 30.8054 40.59 30.2879 40.4141C29.7745 40.2395 29.5082 39.6809 29.6742 39.173L32.7088 29.8871ZM37.3614 27.3386C35.6299 27.3386 34.0853 28.4691 33.5338 30.1567L30.4992 39.4426C30.4769 39.5108 30.5159 39.5749 30.5673 39.5924C30.6147 39.6085 30.6758 39.5854 30.6969 39.5207L33.7315 30.2348C34.25 28.6482 35.7093 27.5703 37.3614 27.5703H55.2783C56.9304 27.5703 58.3897 28.6482 58.9082 30.2348L61.9428 39.5207C61.9639 39.5854 62.025 39.6085 62.0724 39.5924C62.1238 39.5749 62.1628 39.5108 62.1405 39.4426L59.1059 30.1567C58.5544 28.4691 57.0098 27.3386 55.2783 27.3386H37.3614Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M65.1756 36.0215H60.7322V34.9219H65.1756C67.657 34.9219 69.1698 37.706 67.8547 39.8523L67.085 41.1085L66.1708 40.5257L66.9405 39.2695C67.8069 37.8556 66.8103 36.0215 65.1756 36.0215Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.2981 34.4883H65.1754C68.0111 34.4883 69.709 37.6569 68.2245 40.0794L67.224 41.7122L65.5779 40.6628L66.5703 39.0432C67.2675 37.9054 66.4559 36.4558 65.1754 36.4558H60.2981V34.4883ZM61.166 35.3562V35.5879H65.1754C67.1643 35.5879 68.346 37.8065 67.3103 39.4966L66.7634 40.3893L66.9456 40.5055L67.4845 39.626C68.6304 37.7558 67.3027 35.3562 65.1754 35.3562H61.166Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4697 36.0215H31.9131V34.9219H27.4697C24.9883 34.9219 23.4754 37.706 24.7906 39.8523L25.5603 41.1085L26.4745 40.5257L25.7048 39.2695C24.8384 37.8556 25.835 36.0215 27.4697 36.0215Z"
      fill="#6B6B6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.3472 34.4883H27.4698C24.6342 34.4883 22.9363 37.6569 24.4207 40.0794L25.4212 41.7122L27.0674 40.6628L26.0749 39.0432C25.3778 37.9054 26.1893 36.4558 27.4698 36.4558H32.3472V34.4883ZM31.4793 35.3562V35.5879H27.4698C25.4809 35.5879 24.2993 37.8065 25.3349 39.4966L25.8819 40.3893L25.6997 40.5055L25.1607 39.626C24.0148 37.7558 25.3426 35.3562 27.4698 35.3562H31.4793Z"
      fill="#6B6B6B"
    />
    {level ? (
      <g clipPath="url(#clip0_5400_144463)">
        <circle cx="74" cy="74.0002" r="8.88889" fill="#E6E6E6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74 81.7778C78.2955 81.7778 81.7778 78.2955 81.7778 74C81.7778 69.7045 78.2955 66.2222 74 66.2222C69.7045 66.2222 66.2222 69.7045 66.2222 74C66.2222 78.2955 69.7045 81.7778 74 81.7778ZM74 84C79.5228 84 84 79.5228 84 74C84 68.4772 79.5228 64 74 64C68.4772 64 64 68.4772 64 74C64 79.5228 68.4772 84 74 84Z"
          fill="#6B6B6B"
        />
        <text
          x="74"
          y="77.5"
          fill="#6B6B6B"
          textAnchor="middle"
          style={{
            fontFamily: 'inter',
            fontSize: 9,
            fontWeight: 800,
            lineHeight: 16,
          }}>
          {level}
        </text>
      </g>
    ) : (
      ''
    )}
    <defs>
      <radialGradient
        id="paint0_angular_5400_144463"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.0019 44.1379) rotate(-90) scale(65.3406 48.9529)">
        <stop offset="0.33972" stopColor="#A9A9A9" />
        <stop offset="0.773179" stopColor="#E6E6E6" />
        <stop offset="1" stopColor="#E6E6E6" />
      </radialGradient>
      <radialGradient
        id="paint1_angular_5400_144463"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(38.1908 45.9977) rotate(-8.79121) scale(53.9478 54.824)">
        <stop offset="0.33972" stopColor="#2D63F9" />
        <stop offset="0.773179" stopColor="#2EF699" />
        <stop offset="1" stopColor="#2EF699" />
      </radialGradient>
      <radialGradient
        id="paint2_angular_5400_144463"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(46.4975 40.9754) scale(19.7138 18.384)">
        <stop offset="0.221417" stopColor="#A9A9A9" />
        <stop offset="0.280351" stopColor="#A9A9A9" />
        <stop offset="0.645375" stopColor="white" />
        <stop offset="0.854299" stopColor="white" />
      </radialGradient>
      <clipPath id="clip0_5400_144463">
        <rect x="64" y="64" width="20" height="20" rx="9" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default questionsCompletedWorkDisabled;
