import React, { useContext } from 'react';
import { Grid, ListItem, ListItemButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { CartoonSvgAvatar } from '../../common/CartoonSVGAvatar';
import UserImage from '../../common/User/UserImage';
import SVG from '../../../assets/svg';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { Colors } from '../../../theme/shared';
import { ContestRanking } from '../../../types/contest/types';

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 56,
    borderRadius: 10,
  },
  listItemTop3: {
    backgroundColor: theme.palette.primary.dark,
    '& .MuiTypography-body2': {
      color: theme.palette.backgrounds.white,
    },
    '& .MuiTypography-h4': {
      color: Colors.ElectricGreen,
    },
  },
  listItemSticky: {
    backgroundColor: theme.palette.primary.main,
    position: 'sticky',
    top: -8,
    bottom: -8,
    zIndex: 1,
    '& .MuiTypography-root': {
      color: theme.palette.backgrounds.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  name: {
    marginLeft: 8,
  },
}));

type Props = {
  item: ContestRanking;
  onCurrentUserClick?: () => void;
};

const LeaderboardRow: React.FC<Props> = ({ item, onCurrentUserClick }) => {
  const classes = useStyles();
  const { user: currentUser } = useContext(AuthorizationContext);
  const { contestTotal, rank, user } = item;

  const isCurrentUser = user.id === currentUser?.id;

  const getRankIcon = () => {
    switch (rank) {
      case -1:
        return (
          <Typography variant="body2" align="center">
            -
          </Typography>
        );
      case 1:
        return <SVG.ContestGold />;
      case 2:
        return <SVG.ContestSilver />;
      case 3:
        return <SVG.ContestBronze />;
      default:
        return (
          <Typography variant="body2" align="center">
            {rank}
          </Typography>
        );
    }
  };

  const profileIcon = user.avatar ? (
    <CartoonSvgAvatar avatar={user.avatar} size="sm" />
  ) : (
    <UserImage user={user} size="sm" />
  );

  const row = (
    <>
      <Grid container item justifyContent="center" xs={1}>
        {getRankIcon()}
      </Grid>
      <Grid container item xs={10}>
        {profileIcon}
        <Typography className={classes.name} variant="body2">
          {user.name}
        </Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="h4" align="right">
          {contestTotal}
        </Typography>
      </Grid>
    </>
  );

  if (isCurrentUser && onCurrentUserClick) {
    return (
      <ListItemButton
        className={`${classes.listItem} ${classes.listItemSticky}`}
        onClick={onCurrentUserClick}>
        {row}
      </ListItemButton>
    );
  }

  return (
    <ListItem
      className={`${classes.listItem} ${
        rank <= 3 ? classes.listItemTop3 : ''
      }`}>
      {row}
    </ListItem>
  );
};

export default LeaderboardRow;
