/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-body-style */
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { AddPhotoAlternate } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { INSERT_IMAGE_COMMAND } from '../../ImagePlugin';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const ImagePicker = () => {
  const [src, setSrc] = React.useState<string | null>(null);
  const [editor] = useLexicalComposerContext();

  const loadImage = (files: FileList | null) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        setSrc(reader.result);
      }
      return '';
    };
    if (files !== null) {
      reader.readAsDataURL(files[0]);
    }
  };

  useEffect(() => {
    if (src) {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, { src });
    }
  }, [editor, src]);

  return (
    <Button
      color="inherit"
      component="label"
      role={undefined}
      variant="text"
      tabIndex={-1}
      sx={{
        minWidth: 40,
        padding: 1,
        borderRadius: '50%',
        '.MuiButton-startIcon': {
          margin: 0,
        },
        '.MuiButton-startIcon>*:nth-of-type(1)': {
          fontSize: 24,
        },
      }}
      startIcon={<AddPhotoAlternate color="inherit" fontSize="inherit" />}>
      <VisuallyHiddenInput
        onChange={(e) => loadImage(e.target.files)}
        type="file"
        accept="image/png, image/jpeg"
      />
    </Button>
  );
};
