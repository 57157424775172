import { styled, Tab } from '@mui/material';
import { Colors } from '../../../theme/shared';

export default styled(Tab)({
  textTransform: 'none',
  fontSize: 18,
  fontWeight: 600,
  fontFamily: 'Inter',
  color: '#9E9E9E',
  border: 0,
  '&.Mui-selected': {
    color: Colors.CobaltBlue,
    backgroundColor: 'transparent',
  },
  '&.MuiButtonBase-root': {
    paddingBottom: 22,
    whiteSpace: 'nowrap',
    margin: '0 12px',
  },
  '&.MuiButtonBase-root:first-of-type': {
    marginLeft: 0,
  },
  '& .MuiGrid-root': {
    lineHeight: 32,
  },
  '&.MuiTab-root': {
    minWidth: 0,
  },
  '& .MuiTab-wrapper': {
    whiteSpace: 'nowrap',
  },
});
