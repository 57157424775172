import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import QuestionnaireButton from './QuestionnaireButton';
import { QuizType } from '../../communications/types';
import {
  QuestionnaireActionTypes,
  useAnswerQuestion,
  useCleanupQuestionnaire,
  useQuestionnaire,
  useQuestionnaireDispatch,
} from '../../../contexts/QuestionnaireContext';
import { Colors } from '../../../theme/shared';
import {
  ProfileQuestionInputBase,
  QuestionAnswer,
  QuestionOption,
  QuestionTag,
} from '../../../types/question/types';
import { createQuestionAnswerFromQuestionAndEmissionSources } from '../../../utils/questionnaire';

export type OptionInputItemProps = {
  item: QuestionOption;
  internalValue: QuestionAnswer;
  answerHandler: (option: QuestionOption) => void;
} & Pick<ProfileQuestionInputBase, 'forceDisabled'>;

export function OptionInputItem({
  item,
  internalValue,
  answerHandler,
  forceDisabled,
}: OptionInputItemProps) {
  const inputValue = internalValue.unsure
    ? item.unsure
    : internalValue.answerIds?.find((find) => find === item.answerId);

  return (
    <Grid item xs={6}>
      <QuestionnaireButton
        variant={inputValue ? 'contained' : 'outlined'}
        onClick={() => answerHandler(item)}
        disabled={forceDisabled}
        sx={{
          ...(inputValue
            ? {
                // Force enabled look when disabled
                '&.MuiButton-root:disabled': {
                  backgroundColor: Colors.CobaltBlue,
                  color: 'white',
                },
              }
            : {}),
        }}
        fullWidth>
        {item.answerText}
      </QuestionnaireButton>
    </Grid>
  );
}

function OptionInput({
  profileCard,
  inputOnly,
  forceDisabled,
}: ProfileQuestionInputBase): JSX.Element {
  const state = useQuestionnaire();
  const dispatch = useQuestionnaireDispatch();
  const answerQuestion = useAnswerQuestion();
  const cleanupQuestionnaire = useCleanupQuestionnaire();
  const { profileId, question } = profileCard;

  const enableSubmit =
    state.carouselIndex === state.profileCards.length - 1 &&
    state.quiz?.quizType === QuizType.POLL;

  const testingAnswer = state.testingAnswers?.find(
    (x) => x.questionId === question.questionId,
  ) || { questionId: question.questionId };

  const [internalValue, setInternalValue] = useState(
    // If testing, use temporary answers from state
    state.testing
      ? testingAnswer
      : createQuestionAnswerFromQuestionAndEmissionSources(
          state.userEmissionSources,
          profileId,
          question,
        ),
  );

  useEffect(() => {
    // If testing, use temporary answers from state
    setInternalValue(
      state.testing
        ? testingAnswer
        : createQuestionAnswerFromQuestionAndEmissionSources(
            state.userEmissionSources,
            profileId,
            question,
          ),
    );
  }, [profileCard, state.userEmissionSources]);

  const multiSelect = question?.tags?.includes(
    QuestionTag.ModesOfTransportation,
  );

  const answerHandler = async (option: QuestionOption) => {
    const { answerId, profileDocumentId, unsure } = option;

    if (multiSelect) {
      if (answerId) {
        const tempValue: QuestionAnswer = {
          ...internalValue,
        };
        let tempAnswerIds = [...(internalValue.answerIds || [])];

        if (tempAnswerIds.includes(answerId)) {
          // remove the answer
          tempAnswerIds = tempAnswerIds.filter((filter) => filter !== answerId);
        } else {
          // add it
          tempAnswerIds.push(answerId);
        }
        const nextValue: QuestionAnswer = {
          ...tempValue,
          answerIds: tempAnswerIds,
        };
        setInternalValue(nextValue);

        await answerQuestion(
          profileCard,
          nextValue,
          profileDocumentId
            ? {
                answerId,
                profileDocumentId,
              }
            : undefined,
        );
      }
    } else {
      const nextValue: QuestionAnswer = {
        ...internalValue,
        answerIds: answerId ? [answerId] : [],
        unsure,
      };

      setInternalValue(nextValue);

      await answerQuestion(
        profileCard,
        nextValue,
        profileDocumentId && answerId
          ? {
              answerId,
              profileDocumentId,
            }
          : undefined,
      );
    }
  };

  const disabled =
    !internalValue.answerIds ||
    internalValue.answerIds.length === 0 ||
    forceDisabled;

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item container flexDirection="row" spacing={1}>
        {question?.options?.map((item) => (
          <OptionInputItem
            key={item.answerId}
            item={item}
            internalValue={internalValue}
            answerHandler={answerHandler}
            forceDisabled={forceDisabled}
          />
        ))}
        {question?.allowUnsure && (
          <OptionInputItem
            item={{
              answerText: 'Unsure',
              unsure: true,
            }}
            internalValue={internalValue}
            answerHandler={answerHandler}
            forceDisabled={forceDisabled}
          />
        )}
      </Grid>
      {!inputOnly && multiSelect && (
        <Grid item pt={3}>
          <QuestionnaireButton
            variant="contained"
            onClick={() => {
              dispatch({ type: QuestionnaireActionTypes.NEXT });
            }}
            disabled={disabled}>
            Next
          </QuestionnaireButton>
        </Grid>
      )}
      {!inputOnly && enableSubmit && (
        <QuestionnaireButton
          variant="contained"
          onClick={() => cleanupQuestionnaire(state.cleanup)}
          disabled={disabled}
          style={{ marginTop: 8 }}
          fullWidth>
          Submit
        </QuestionnaireButton>
      )}
    </Grid>
  );
}

export default OptionInput;
