import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import { GET_MARKETPLACE_PURCHASEHIST } from '../../../graphql/projects/projects';
import { ProjectPurchaseHistory } from '../../../types/offsetProject/types';
import PurchaseRequestListItem from './PurchaseRequestListItem';
import AppInfo from '../../common/AppInfo';
import NumberUtils from '../../../utils/numberUtils';
import SVG from '../../../assets/svg';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles((theme) => ({
  purchaseRequests: {
    padding: 16,
    paddingTop: 32,
    paddingLeft: 16,
    marginLeft: 16,
  },
  purchaseRequestsBox: {
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 16,
    height: '100%',
  },
  divider: {
    color: '#DADADA',
    margin: '16px 0 24px',
  },
  link: {
    color: theme.palette.buttons.primary.main,
  },
}));

type Props = {
  onClose: () => void;
};

const PurchaseRequestsBox: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const {
    loading,
    error,
    data: { offsetProjects = [] } = {},
  } = useQuery<{ offsetProjects: ProjectPurchaseHistory[] }>(
    GET_MARKETPLACE_PURCHASEHIST(),
    {
      variables: {
        filter: {
          status: ['REQUESTED', 'APPROVED'],
          isDemoProject: ['false'],
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const total = offsetProjects.reduce(
    (prev, curr) => prev + curr.price * curr.quantity,
    0,
  );

  return (
    <Box display="flex" className={classes.purchaseRequests}>
      <Grid container direction="column" spacing={3} wrap="nowrap">
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}>
              <IconButton onClick={onClose}>
                <SVG.Back />
              </IconButton>
              <Typography variant="h3">Credit Requests</Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container alignItems="center">
              <Link
                to="/trader/projects/pending"
                style={{ textDecoration: 'none', marginRight: '12px' }}>
                <Typography className={classes.link} variant="caption">
                  View All Requests
                </Typography>
              </Link>
              <AppInfo id="offsetCreditRequests" />
            </Grid>
          </Grid>
        </Grid>
        <DataStateHandler loading={loading} error={error}>
          <>
            <Grid item container wrap="nowrap" justifyContent="space-evenly">
              <Grid item container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="h2" style={{ color: Colors.CobaltBlue }}>
                    {NumberUtils.format(offsetProjects.length || 0, 'integer')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" style={{ color: '#6B6B6B' }}>
                    REQUESTS
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container direction="column" alignItems="center">
                <Grid item>
                  <Typography variant="h2" style={{ color: Colors.CobaltBlue }}>
                    {NumberUtils.format(total, 'currency')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" style={{ color: '#6B6B6B' }}>
                    TOTAL
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container direction="column">
              <Grid item className={classes.purchaseRequestsBox}>
                <Box
                  flexGrow={1}
                  style={{ overflow: 'auto', paddingRight: 8, height: '100%' }}>
                  {offsetProjects.map((x, i) => (
                    <React.Fragment key={x.id}>
                      <PurchaseRequestListItem purchase={x} />
                      {i < offsetProjects.length - 1 && (
                        <Divider className={classes.divider} />
                      )}
                    </React.Fragment>
                  ))}
                  {!offsetProjects.length && (
                    <Typography variant="h2">
                      No Pending Credit Requests
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        </DataStateHandler>
      </Grid>
    </Box>
  );
};

export default PurchaseRequestsBox;
