import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AppCard from '../common/AppCard/AppCard';

const useStyles = makeStyles((theme) => ({
  value: {
    color: theme.palette.buttons.primary.active,
    textAlign: 'center',
  },
  label: {
    fontSize: 12,
  },
  metric: {
    margin: '5px 8px',
    width: '100%',
  },
}));

type Props = {
  title: string;
  icon: JSX.Element;
  value: string | number;
};

const QuizMetricCard: React.FC<Props> = ({ title, icon, value }) => {
  const classes = useStyles();
  return (
    <AppCard height={124}>
      <Grid container direction="column">
        <Grid item>
          <Grid container justifyContent="space-between">
            <Typography variant="subtitle1" className={classes.label}>
              <Grid container spacing={1} alignItems="center">
                <Grid item>{icon}</Grid>
                <Grid item>{title}</Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item className={classes.metric}>
              <Typography variant="h1" className={classes.value}>
                {value}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default QuizMetricCard;
