import { Box, Grid, List, ListItem, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import trees from '../../../assets/img/trees.png';
import { OffsetContext } from '../../../contexts/OffsetContext';

const useStyles = makeStyles(() => ({
  white: {
    color: '#FFF',
  },
  paperOuter: {
    backgroundImage: `url(${trees})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '100%',
    padding: 16,
  },
  paperInner: {
    backgroundColor: 'transparent',
    height: '100%',
    borderColor: '#BDD9C5',
    padding: 16,
    color: '#FFF',
  },
  total: {
    fontSize: '5em',
    lineHeight: 1,
  },
  label: {
    color: '#BDD9C5',
  },
  container: {
    height: '100%',
  },
  cart: {
    overflow: 'hidden',
    flex: 1,
  },
  cartItems: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    overflow: 'auto',
    height: '100%',
  },
}));

type Item = {
  id: string;
  component: JSX.Element;
};

type Props = {
  items: Item[];
  footer?: JSX.Element;
};

const Cart = ({ items, footer }: Props) => {
  const classes = useStyles();
  const { offsetTotal } = useContext(OffsetContext);

  return (
    <Paper className={classes.paperOuter}>
      <Paper variant="outlined" elevation={0} className={classes.paperInner}>
        <Grid
          container
          direction="column"
          alignItems="stretch"
          spacing={1}
          wrap="nowrap"
          className={classes.container}>
          <Grid item container direction="column" alignItems="center">
            <Grid item>
              <Typography variant="subtitle1" className={classes.white}>
                Offset Total
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={[classes.white, classes.total].join(' ')}>
                {offsetTotal}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h3" className={classes.label}>
                MT C0₂E
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.cart}>
            <Box borderRadius="8px" className={classes.cartItems} py={1}>
              <List disablePadding>
                {items.map((item) => (
                  <ListItem key={item.id} divider>
                    {item.component}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          {footer && <Grid item>{footer}</Grid>}
        </Grid>
      </Paper>
    </Paper>
  );
};

export default Cart;
