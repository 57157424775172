import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { FormControl, Grid, MenuItem, Typography } from '@mui/material';
import { formatTooltip } from '../../common/ChartTooltips/FormattedTooltip';
import { GET_SPEND_BY_CATEGORY } from '../../../graphql/trader/dashboard';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import SelectMenu from '../../common/SelectMenu/SelectMenu';
import AppCard from '../../common/AppCard/AppCard';
import ChartLegend from '../../common/ChartLegend/ChartLegend';
import CategoryDataBarChart from '../../common/CategoryDataBarChart/CategoryDataBarChart';
import { CategoryData } from '../../../types/dashboard/types';
import { CompanyFiscalYearSettings } from '../../../types/company/types';
import { Colors } from '../../../theme/shared';

type Props = {
  fiscalYear?: CompanyFiscalYearSettings;
};

const PurchasesByCategory: React.FC<Props> = ({ fiscalYear }) => {
  const [category, setCategory] = useState('all');
  const [unit, setUnit] = useState<'MT' | 'MWh'>('MT');

  const {
    data: { spendByCategory = [] } = {},
    loading,
    error,
  } = useQuery(GET_SPEND_BY_CATEGORY, {
    variables: {
      dateGrain: 'MONTHLY',
      startDate: fiscalYear?.startDate as string,
      endDate: fiscalYear?.endDate as string,
    },
    fetchPolicy: 'network-only',
  });

  const filteredData = spendByCategory.map((x) =>
    category === 'all' || x.category?.id === category
      ? x
      : {
          ...x,
          mt: 0,
          mwh: 0,
        },
  );

  const categoryData: CategoryData = {
    id: '1',
    label: 'root',
    data: filteredData.reduce(
      (acc, cur) => {
        // make sure 'nocat' exists for every month since it'll define the x axis
        const { date } = cur;
        const nocat = acc.find((x) => x.id === 'nocat');

        if (!nocat) {
          return acc;
        }

        nocat.data = [
          ...nocat.data.filter((x) => x.date !== date),
          {
            date,
            value: 0,
          },
        ];

        if (!cur.category) {
          return acc;
        }

        let cd = acc.find((x) => x.id === cur.category.id);

        if (!cd) {
          cd = {
            ...cur.category,
            data: [],
          };

          acc.push(cd);
        }

        cd.data = [
          ...cd.data,
          {
            date,
            value: unit === 'MT' ? cur.mt || 0 : cur.mwh || 0,
          },
        ];

        return acc;
      },
      [
        {
          id: 'nocat',
          label: 'nocat',
          data: [],
        },
      ] as CategoryData['data'],
    ),
  };

  const categories = uniqBy(
    spendByCategory.filter((x) => !!x.category).map((x) => x.category),
    (x) => x.id,
  );

  const totalSold = filteredData
    .filter((x) =>
      moment.utc(x.date).isSameOrAfter(moment.utc(fiscalYear?.startDate)),
    )
    .reduce((acc, cur) => acc + (unit === 'MT' ? cur.mt : cur.mwh), 0);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <Typography
          variant="h3"
          style={{ marginTop: 'auto', marginBottom: 16 }}>
          Project Purchase History
        </Typography>
        <Grid item>
          <FormControl variant="outlined">
            <SelectMenu
              id="uom"
              value={unit}
              onChange={(e) => setUnit(e.target.value as 'MT' | 'MWh')}>
              <MenuItem key="MT" value="MT">
                VCC
              </MenuItem>
              <MenuItem key="MWh" value="MWh">
                REC
              </MenuItem>
            </SelectMenu>
          </FormControl>
          <FormControl variant="outlined">
            <SelectMenu
              id="demo-simple-select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}>
              <MenuItem key="all" value="all">
                All Categories
              </MenuItem>
              {categories.map((x) => (
                <MenuItem key={x.id} value={x.id}>
                  {x.label}
                </MenuItem>
              ))}
            </SelectMenu>
          </FormControl>
        </Grid>
      </Grid>
      <AppCard
        height="auto"
        headerProps={{ tooltipId: 'offsetProjectPurchaseHistory' }}>
        <DataStateHandler loading={loading} error={error}>
          <>
            <CategoryDataBarChart
              categoryData={categoryData}
              margin={{ top: 10, right: 0, bottom: 50, left: 50 }}
              height={240}
              suffix={` ${unit}`}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              wrap="nowrap">
              <Grid item container style={{ flex: 1 }} alignItems="flex-end">
                <Grid item>
                  <ChartLegend keys={categories} />
                </Grid>
              </Grid>
              <Grid item xs={2} style={{ textAlign: 'right' }}>
                <Typography variant="h1" style={{ color: Colors.CobaltBlue }}>
                  {`${formatTooltip(totalSold, 'integer')} ${unit}`}
                </Typography>
                <Typography variant="h4" style={{ color: '#6B6B6B' }}>
                  Sold in FY {fiscalYear?.fiscalYear}
                </Typography>
              </Grid>
            </Grid>
          </>
        </DataStateHandler>
      </AppCard>
    </>
  );
};

export default PurchasesByCategory;
