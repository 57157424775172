import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { BarDatum, BarItemProps, ResponsiveBar } from '@nivo/bar';

import {
  Grid,
  Typography,
  Box,
  FormControl,
  MenuItem,
  lighten,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AppCard from '../../../common/AppCard/AppCard';
import CollapsibleChartLabel from '../../../common/ChartLabel/CollapsibleChartLabel';
import NumberUtils from '../../../../utils/numberUtils';
import useCurrentCompany from '../../../../hooks/useCurrentCompany';
import DataStateHandler from '../../../common/DataStateHandler/DataStateHandler';
import { EMPLOYEE_COMMUTING } from '../../../../graphql/dashboard/employeeCommuting';
import { dateRangeFromEndDate } from '../../../../utils/dateUtils';
import { DashboardContext } from '../../../../contexts/DashboardContext';
import SelectMenu from '../../../common/SelectMenu/SelectMenu';
import { FULL_Y_BAR_TOOLTIP_LAYERS } from '../../../common/ChartTooltips/FullYBarTooltipLayer';
import TooltipContainer from '../../../common/ChartTooltips/TooltipContainer';
import ChartLabel from '../../../common/ChartLabel/ChartLabel';
import ChartUtils from '../../../../utils/chartUtils';
import AppInfo from '../../../common/AppInfo';

const useStyles = makeStyles(() => ({
  labels: {
    marginTop: 32,
    marginBottom: 32,
  },
}));

type BarProps = BarItemProps<BarDatum>;

const EmployeeCommuting: React.FC = () => {
  const { companyId, includeChildren } = useCurrentCompany();
  const { endDate } = useContext(DashboardContext);

  const [display, setDisplay] = useState<'value' | 'miles'>('value');

  const {
    loading,
    error,
    data: { employeeCommutingStatistics: { data = [] } = {} } = {},
  } = useQuery(EMPLOYEE_COMMUTING, {
    variables: {
      companyId,
      includeChildren,
      ...dateRangeFromEndDate(endDate, 2),
    },
  });

  const chartData = data.map((d) => ({
    id: d.id,
    value: d[display],
    color: d.color,
  }));

  // eslint-disable-next-line react/no-unstable-nested-components
  const BarComponent: React.FC<BarProps> = (props) => {
    const {
      bar: { x = 0, y = 0, width = 0, height = 0, color = '', key = '' } = {},
    } = props;
    const id = key.split('.')[1];
    const parent = data.find((o) => o.id === id);

    if (parent?.children?.length) {
      const children = parent.children.map((c) => {
        const percentOfTotal = c.value / (parent.value || 1);
        return {
          ...c,
          percentOfTotal,
          height: percentOfTotal * height,
        };
      });
      let nextY = y;
      return (
        <>
          {children.map((c, idx) => {
            const currentY = nextY;
            nextY += c.height;
            return (
              <rect
                key={c.id}
                x={x}
                stroke="#FFFFFF"
                strokeWidth={1}
                y={currentY}
                fill={lighten(color, (idx + 1) * 0.2)}
                width={width}
                height={c.height}
              />
            );
          })}
        </>
      );
    }
    return <rect x={x} y={y} fill={color} width={width} height={height} />;
  };

  const classes = useStyles();

  const hasData = chartData.some((d) => d.value > 0);

  const renderTooltipContents = ({
    indexValue,
  }: {
    indexValue: string | number;
  }) => {
    const parent = data.find((d) => d.id === indexValue);
    if (!parent) {
      return <svg />;
    }

    return (
      <TooltipContainer>
        <>
          <ChartLabel
            labelColor="#6B6B6B"
            color={parent.color}
            value={NumberUtils.format(parent[display], 'number')}
            label={parent.label}
          />
          {parent.children?.map((c, idx) => (
            <ChartLabel
              key={c.id}
              labelColor="#6B6B6B"
              color={lighten(parent.color, (idx + 1) * 0.2)}
              label={c.label}
              value={NumberUtils.format(c[display], 'number')}
            />
          ))}
        </>
      </TooltipContainer>
    );
  };

  return (
    <DataStateHandler loading={loading} error={error}>
      <Grid item container spacing={4}>
        <Grid item xs={12}>
          <Box mt={2}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Typography variant="h3" color="textPrimary">
                Member Commuting
              </Typography>
              <FormControl variant="outlined">
                <SelectMenu
                  id="commutingDisplay"
                  value={display}
                  onChange={(e) =>
                    setDisplay(e.target.value as 'value' | 'miles')
                  }>
                  <MenuItem key="value" value="value">
                    Transportation Mode
                  </MenuItem>
                  <MenuItem key="miles" value="miles">
                    Miles Traveled
                  </MenuItem>
                </SelectMenu>
              </FormControl>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <AppCard height="auto">
            <Grid container justifyContent="space-between">
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <AppInfo id="impactMemberCommuting" />
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <div style={{ height: 408 }}>
                  <ResponsiveBar
                    data={chartData}
                    colors={chartData.map((o) => o.color)}
                    colorBy="indexValue"
                    margin={{ top: 45, right: 30, bottom: 55, left: 30 }}
                    padding={0.8}
                    innerPadding={1}
                    groupMode="stacked"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    axisTop={null}
                    axisRight={null}
                    gridYValues={4}
                    axisBottom={{
                      tickSize: 0,
                      tickPadding: 24,
                      tickRotation: 0,
                    }}
                    axisLeft={{
                      tickValues: 4,
                      tickSize: 0,
                      tickPadding: 10,
                      tickRotation: 0,
                      legend: '',
                      legendPosition: 'middle',
                      legendOffset: -20,
                      format: (value) =>
                        Intl.NumberFormat('en-US', {
                          notation: 'compact',
                          maximumFractionDigits: 0,
                          maximumSignificantDigits: 4,
                        }).format(value),
                    }}
                    enableLabel={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    layers={FULL_Y_BAR_TOOLTIP_LAYERS}
                    tooltip={(props) => renderTooltipContents(props)}
                    role="application"
                    ariaLabel="Employee Commuting Chart"
                    barComponent={BarComponent}
                    {...ChartUtils.configureChartMinMaxValues(hasData)}
                  />
                </div>
              </Grid>
              <Grid item xs={4} className={classes.labels}>
                {data.map((d) => (
                  <CollapsibleChartLabel
                    key={d.label}
                    size="medium"
                    label={d.label}
                    value={NumberUtils.format(d[display], 'integer')}
                    color={d.color}
                    childLabels={
                      d.children?.length
                        ? d.children.map((c) => ({
                            label: c.label,
                            value: NumberUtils.format(c[display], 'integer'),
                            color: c.color,
                          }))
                        : undefined
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </AppCard>
        </Grid>
      </Grid>
    </DataStateHandler>
  );
};

export default EmployeeCommuting;
