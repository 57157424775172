import { Grid } from '@mui/material';
import { Field } from 'formik';
import React, { useContext } from 'react';
import { CompanySettingsContext } from '../../contexts/CompanySettingsContext';
import AppTextField from '../common/AppTextField';

const CompanyTypeForm: React.FC = () => {
  const { companyTypes = [] } = useContext(CompanySettingsContext);
  return (
    <Grid item xs={12}>
      <Field
        name="companyTypeId"
        component={AppTextField}
        placeholder=""
        fullWidth
        select
        options={companyTypes.map((ct) => ({ value: ct.id, label: ct.label }))}
      />
    </Grid>
  );
};

export default CompanyTypeForm;
