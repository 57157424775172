import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { ComponentProps, useContext } from 'react';
import { OffsetContext, Step } from '../../../contexts/OffsetContext';
import { GET_ALL_CATEGORIES } from '../../../graphql/projects/projectCategories';
import {
  ProjectPurchaseHistory,
  ProjectPurchaseStatus,
} from '../../../types/offsetProject/types';
import {
  projectCategoryFilterLabel,
  projectCategorySearchFunction,
} from '../../../utils/projectUtils';
import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import CategoryIconSmall from '../../common/Project/CategoryIconSmall';
import AlphaColumnFilter from '../../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../../common/Table/GroupableSelectTable';
import { GroupableTableStructure } from '../../common/Table/types';
import RoundedFlexBox from '../../trader/RoundedFlexBox';
import Cart from '../cart/Cart';
import CartItem from '../cart/CartItem';
import Amount from './Amount';
import AllocationStepButton from '../AllocationStepButton';

const useStyles = makeStyles({
  projectName: {
    width: '35ch',
  },
});

const Assign = () => {
  const classes = useStyles();
  const {
    currentStep,
    projectOffsetAssignments,
    removeProject,
    projectTotal,
    projectPurchases,
  } = useContext(OffsetContext);

  const {
    loading: loadingCategories,
    error: errorCategories,
    data: { projectCategories = [] } = {},
  } = useQuery(GET_ALL_CATEGORIES);

  const tableStructure: GroupableTableStructure<ProjectPurchaseHistory>[] = [
    {
      key: 'main',
      columns: [
        {
          key: 'projectCategoryId',
          display: 'Cat',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Categories',
          searchFunction: projectCategorySearchFunction(projectCategories),
          resolveFilterLabel: projectCategoryFilterLabel(projectCategories),
          render: ({ projectCategoryId }) => (
            <CategoryIconSmall categoryId={projectCategoryId} />
          ),
        },
        {
          key: 'projectTitle',
          display: 'Project Name',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Project Name',
          render: ({ project: { title } }) => (
            <Typography variant="body2" noWrap className={classes.projectName}>
              {title}
            </Typography>
          ),
        },
        {
          key: 'remaining',
          display: 'Remaining',
          searchable: false,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Remaining',
          format: (remaining) =>
            `${(
              remaining as ProjectPurchaseHistory['remaining']
            ).toLocaleString()} MT`,
        },
        {
          key: 'amount',
          display: 'Apply Amount',
          render: (project) => <Amount project={project} />,
        },
      ],
    },
  ];

  const cartItems: ComponentProps<typeof Cart>['items'] =
    projectOffsetAssignments.map((assignment) => ({
      id: assignment.project.id,
      component: (
        <CartItem
          icon={
            <CategoryIconSmall
              categoryId={assignment.project.projectCategoryId}
            />
          }
          title={assignment.project.project.title || ''}
          quantity={assignment.quantity}
          uom={assignment.project.project.uom}
          onDelete={() => removeProject(assignment.project.id)}
        />
      ),
    }));

  const tableDataItems = (projectPurchases || []).map((x) => ({
    ...x,
    projectTitle: x.project.title,
  }));

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      wrap="nowrap"
      style={{
        display: currentStep !== Step.assign ? 'none' : undefined,
        height: '100%',
      }}>
      <Grid item container spacing={2} xs={12}>
        <Grid item xs={7}>
          <DataStateHandler
            loading={!projectPurchases || loadingCategories}
            error={errorCategories}>
            <RoundedFlexBox>
              <GroupableSelectTable<ProjectPurchaseHistory>
                tableData={
                  tableDataItems?.filter(
                    ({ status, remaining }) =>
                      status === ProjectPurchaseStatus.COMPLETE &&
                      remaining > 0,
                  ) || []
                }
                tableStructure={tableStructure}
                queryVariables={{ 'sort-list': projectCategories }}
                hideStickyCol
              />
            </RoundedFlexBox>
          </DataStateHandler>
        </Grid>
        <Grid item xs={5}>
          <Cart
            items={cartItems}
            footer={
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body1" style={{ color: 'white' }}>
                    {projectOffsetAssignments.length} Project
                    {projectOffsetAssignments.length > 1 ? 's' : ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ color: '#BDD9C5' }}>
                    {projectTotal} MT C0₂E
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>
      </Grid>
      <AllocationStepButton />
    </Grid>
  );
};

export default Assign;
