import useRemoveHabitData from './useRemoveHabitData';
import useRemoveLifestyleHabitData from './useRemoveLifestyleHabitData';
import useRemoveProjectedEmissions from './useRemoveProjectedEmissions';
import useRemoveUserEmissionSources from './useRemoveUserEmissionSources';
import useRemoveUserQuestionTreeCategoryStatusDetail from './useRemoveUserQuestionTreeCategoryStatusDetail';
import useRemoveReminders from './useRemoveReminders';

export default function useRemoveQuestionRelatedData(options?: {
  ignoreHabits?: boolean;
}) {
  const { remove: removeHabitData } = useRemoveHabitData();
  const { remove: removeProjectedEmissions } = useRemoveProjectedEmissions();
  const { remove: removeLifestyleHabits } = useRemoveLifestyleHabitData();
  const { remove: removeQuestionTreeCategoryDetail } =
    useRemoveUserQuestionTreeCategoryStatusDetail();
  const { remove: removeEmissionSources } = useRemoveUserEmissionSources();
  const { remove: removeReminders } = useRemoveReminders();

  const remove = async () => {
    await Promise.all([
      !options?.ignoreHabits ? removeHabitData() : null,
      removeProjectedEmissions(),
      !options?.ignoreHabits ? removeLifestyleHabits() : null,
      removeQuestionTreeCategoryDetail(),
      removeEmissionSources(),
      removeReminders(),
    ]);
  };

  return {
    remove,
  };
}
