/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router';
import MemberLayout from '../../../components/layouts/MemberLayout';
import {
  QuestionnaireActionTypes,
  QuestionnaireProvider,
  useQuestionnaire,
} from '../../../contexts/QuestionnaireContext';
import CurrentQuestionnaireCard from '../../../components/common/Questionnaire/CurrentQuestionnaireCard';
import SVG from '../../../assets/svg-v2';
import QuestionnaireButton from '../../../components/common/Questionnaire/QuestionnaireButton';
import { FilterOnboardingQuestionOptions } from '../../../types/question/types';
import OnboardingComplete from '../../../components/common/Questionnaire/OnboardingComplete';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS } from '../../../graphql/questionnaire/questions';
import { GET_WELCOME_VIDEO } from '../../../graphql/communications/memberCommunications';
import DataStateHandler from '../../../components/common/DataStateHandler/DataStateHandler';
import Loading from '../../../components/common/Loading';

const OnboardingState = {
  WELCOME: 1,
  QUESTIONS: 2,
  IMPACT: 3,
};

const MemberOnboardingInner: React.FC = () => {
  const history = useHistory();
  const { user, isOnboarding } = useContext(AuthorizationContext);
  const { internalState } = useQuestionnaire();
  const [showWelcome, setShowWelcome] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const showImpact =
    internalState === QuestionnaireActionTypes.CLEAN_UP_COMPLETE;

  const [updateUserOnboardingQuestionnaireStatus] = useMutation(
    UPDATE_USER_ONBOARDING_QUESTIONNAIRE_STATUS,
  );

  const {
    data: { getWelcomeVideo } = {},
    loading,
    error,
  } = useQuery(GET_WELCOME_VIDEO);

  useEffect(() => {
    if (!isOnboarding && !user?.postalCodeChanged && showWelcome) {
      history.push('/member');
    }
  }, [isOnboarding, user, showWelcome]);

  const onNext = () => {
    updateUserOnboardingQuestionnaireStatus({
      variables: {
        hasConfirmedLocation: true,
      },
    });

    setShowWelcome(false);
  };

  let state;
  if (showWelcome) {
    state = OnboardingState.WELCOME;
  } else if (showImpact) {
    state = OnboardingState.IMPACT;
  } else {
    state = OnboardingState.QUESTIONS;
  }

  return (
    <DataStateHandler loading={loading} error={error}>
      <>
        {state === OnboardingState.WELCOME && (
          <Grid container flexDirection="column" alignItems="center">
            <Grid item>
              <SVG.ZeroMeLogo />
            </Grid>
            <Grid item pt={8}>
              <Typography variant="h1">
                Welcome {user?.hasConfirmedLocation ? 'Back ' : ''}to ZeroMe!
              </Typography>
            </Grid>
            {!user?.postalCodeChanged && (
              <>
                <Grid item pt={3}>
                  <Typography variant="body1">
                    Join your team members in working together to reduce your
                    organization&apos;s carbon footprint.
                  </Typography>
                </Grid>
                {!!getWelcomeVideo?.contentUploadUrl && (
                  <Grid item pt={3}>
                    {!videoLoaded && <Loading />}
                    <video
                      onLoadedData={() => setVideoLoaded(true)}
                      style={!videoLoaded ? { display: 'none' } : undefined}
                      data-testid="member-onboarding-video"
                      controls
                      width="100%">
                      <source src={getWelcomeVideo?.contentUploadUrl} />
                      This video format is not supported by your browswer.
                    </video>
                  </Grid>
                )}
                <Grid item pt={3}>
                  <Typography variant="body1">
                    Did you know the average individual&apos;s carbon footprint
                    in the United States is 18.0 Metric Tons?
                  </Typography>
                </Grid>
                <Grid item pt={3}>
                  <Typography variant="body1">
                    Let&apos;s see how you compare.
                  </Typography>
                </Grid>
              </>
            )}
            {!!user?.postalCodeChanged && (
              <>
                <Grid item pt={3}>
                  <Typography variant="body1">
                    It looks like your postal code has changed.
                  </Typography>
                </Grid>
                <Grid item pt={3}>
                  <Typography variant="body1">
                    Please review your profile responses and make sure your
                    answers are up to date.
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item pt={5}>
              <QuestionnaireButton
                variant="contained"
                color="secondary"
                onClick={onNext}>
                Calculate my carbon footprint
              </QuestionnaireButton>
            </Grid>
          </Grid>
        )}
        {state === OnboardingState.QUESTIONS && <CurrentQuestionnaireCard />}
        {state === OnboardingState.IMPACT && <OnboardingComplete />}
      </>
    </DataStateHandler>
  );
};

const MemberOnboarding: React.FC = () => (
  <MemberLayout>
    <QuestionnaireProvider
      onboarding
      filterOnboardingQuestions={
        FilterOnboardingQuestionOptions.ONBOARDING_ONLY
      }
      cleanup={{ navigationType: 'IMPACT' }}>
      <MemberOnboardingInner />
    </QuestionnaireProvider>
  </MemberLayout>
);

export default MemberOnboarding;
