import React from 'react';
import { Colors } from '../../../theme/shared';

type Props = {
  active?: boolean;
};

const EmailIcon: React.FC<Props> = ({ active }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 0H2C1.15625 0 0.5 0.6875 0.5 1.5V10.5C0.5 11.3438 1.15625 12 2 12H15C15.8125 12 16.5 11.3438 16.5 10.5V1.5C16.5 0.6875 15.8125 0 15 0ZM15 1.5V2.78125C14.2812 3.375 13.1562 4.25 10.7812 6.125C10.25 6.53125 9.21875 7.53125 8.5 7.5C7.75 7.53125 6.71875 6.53125 6.1875 6.125C3.8125 4.25 2.6875 3.375 2 2.78125V1.5H15ZM2 10.5V4.71875C2.6875 5.28125 3.71875 6.09375 5.25 7.3125C5.9375 7.84375 7.15625 9.03125 8.5 9C9.8125 9.03125 11 7.84375 11.7188 7.3125C13.25 6.09375 14.2812 5.28125 15 4.71875V10.5H2Z"
      fill={active ? `${Colors.ElectricGreen}` : '#FFFFFF'}
    />
  </svg>
);

export default EmailIcon;
