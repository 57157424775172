import React from 'react';
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Badge,
  Typography,
  Grid,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextUtils from '../../../utils/textUtils';
import CategoryIconDiamond from './CategoryIconDiamond';
import { Project, ProjectCategory } from '../../../types/project/types';
import ProjectDataPill from './ProjectDataPill';
import ImageUtils from '../../../utils/imageUtils';
import ProjectCardLocation from './ProjectCardLocation';
import useProjectImage from '../../../hooks/useProjectImage';

const CARD_HEIGHT = 260;

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: 593,
    height: CARD_HEIGHT,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.12)',
  },
  cardArea: {
    height: CARD_HEIGHT,
  },
  overlay: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100px',
  },
  sponsor: { height: '100%' },
  logo: { color: '#fff' },
  badgeDanger: {
    backgroundColor: '#FFDDE0', // Pink color
    color: '#DC3545', // Red color
    left: 37,
    padding: '16px',
    margin: 5,
    borderRadius: '0 0 8px 0',
  },
  badgeUnavailable: {
    backgroundColor: '#FFDDE0', // Pink color
    color: '#DC3545', // Red color
    left: 37,
    padding: '16px',
    margin: 5,
    borderRadius: '0 0 8px 0',
  },
  shape: {
    marginTop: -25,
    marginLeft: 11,
    position: 'absolute',
  },
  projectName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: 2,
  },
  unavailable: {
    filter: 'grayscale(1)',
  },
  indicator: {
    marginTop: 8,
    minHeight: 22,
    fontSize: 12,
  },
  price: {
    fontWeight: 'bold',
  },
}));

type Props = {
  category: ProjectCategory;
  newOffer?: boolean;
  unavailable?: boolean;
  project: Project;
};

const ProjectCard: React.FC<Props> = ({
  category,
  newOffer,
  unavailable,
  project,
}) => {
  const classes = useStyles();
  const projectImage = useProjectImage({ project, size: 'medium' });

  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `rgba(${parseInt(result[1], 16)}
      ,${parseInt(result[2], 16)},
      ${parseInt(result[3], 16)},0.70)`
      : hex;
  }

  const overlayColor = () => {
    const rgbValue = hexToRgb(category.color || '');
    if (project.sponsor)
      return typeof category.color === 'string' ? rgbValue : undefined;
    if (unavailable) return 'rgb(255,255,255,.5)';
    return undefined;
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={
          unavailable
            ? `${classes.unavailable} ${classes.cardArea}`
            : classes.cardArea
        }>
        <CardMedia
          component="img"
          height="100px"
          style={ImageUtils.ObjectPositionStyle(project.headerImagePosition)}
          image={projectImage}
        />

        {newOffer || unavailable || project.sponsor ? (
          <div
            className={classes.overlay}
            style={{
              backgroundColor: overlayColor(),
            }}>
            {newOffer ? (
              <Badge
                badgeContent="New&nbsp;Offer!"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{ badge: classes.badgeDanger }}
              />
            ) : null}
            {unavailable ? (
              <Badge
                badgeContent="Unavailable"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                classes={{ badge: classes.badgeUnavailable }}
              />
            ) : null}
            {project.sponsor ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.sponsor}>
                <Grid item>
                  <Typography variant="h3" className={classes.logo}>
                    {project.sponsor}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </div>
        ) : null}
        <div className={classes.shape}>
          <CategoryIconDiamond size="small" categoryId={category.id} />
        </div>
        <Box position="absolute" right="16px" width="100%" mt="-15px" mb="16px">
          <ProjectDataPill
            category={category}
            project={project}
            dataType={
              project.isIndicative ? 'INDICATIVE_OFFER' : 'REMAINING_QUANTITY'
            }
          />
        </Box>
        <CardContent>
          <Grid>
            <Grid item>
              <Typography className={classes.indicator} variant="body2">
                {project.indicator}
              </Typography>
              <Typography
                gutterBottom
                variant="h3"
                className={classes.projectName}
                style={{
                  color: category.color,
                }}>
                {project.title}
              </Typography>
            </Grid>
            <Grid item style={{ minHeight: 44 }}>
              <Typography
                component="p"
                paragraph
                variant="body2"
                color="textSecondary">
                {TextUtils.clampText(project.smallSummary || '', 85)}
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between">
                <ProjectCardLocation project={project} />
                <Grid item>
                  {!project.sellingPrice ? null : (
                    <Typography className={classes.price} variant="body2">
                      {new Intl.NumberFormat('en-us', {
                        maximumFractionDigits: 2,
                        currency: 'USD',
                        style: 'currency',
                      }).format(project.sellingPrice || 0)}{' '}
                      / <small>{project.uom}</small>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

ProjectCard.defaultProps = {
  newOffer: false,
  unavailable: false,
};

export default ProjectCard;
