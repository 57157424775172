import React, { useEffect, useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import {
  ProfileQuestionInputBase,
  ScheduleDateGrain,
} from '../../../types/question/types';
import {
  useAnswerQuestion,
  useQuestionnaire,
} from '../../../contexts/QuestionnaireContext';
import {
  DateArray,
  DateArrayAsPickerOptions,
  createQuestionAnswerFromQuestionAndEmissionSources,
} from '../../../utils/questionnaire';
import QuestionnaireButton from './QuestionnaireButton';
import QuestionnaireSelect from './QuestionnaireSelect';

function ScheduleInput({
  profileCard,
  forceDisabled,
  inputOnly,
}: ProfileQuestionInputBase): JSX.Element {
  const state = useQuestionnaire();
  const answerQuestion = useAnswerQuestion();

  const [internalValue, setInternalValue] = useState(
    createQuestionAnswerFromQuestionAndEmissionSources(
      state.userEmissionSources,
      profileCard.profileId,
      profileCard.question,
    ),
  );

  useEffect(() => {
    setInternalValue(
      createQuestionAnswerFromQuestionAndEmissionSources(
        state.userEmissionSources,
        profileCard.profileId,
        profileCard.question,
      ),
    );
  }, [profileCard, state.userEmissionSources]);

  const onValueChange = (value: string) => {
    if (value === undefined) {
      setInternalValue({ ...internalValue, estimateSchedule: undefined });
    } else {
      // slice from 1 to account for 0 days option
      const array = DateArray.slice(0, parseInt(value, 10));

      setInternalValue({
        ...internalValue,
        estimateSchedule: {
          period: ScheduleDateGrain.DAILY,
          generateOn: array.map((map) => map),
        },
      });
    }
  };

  const onComplete = () => {
    answerQuestion(profileCard, internalValue);
  };

  const inputValue = internalValue.estimateSchedule?.generateOn
    ? internalValue.estimateSchedule.generateOn.length.toString()
    : undefined;

  const disabled =
    inputValue === undefined || inputValue === null || forceDisabled;

  return (
    <Grid container flexDirection="column" alignItems="center">
      <Grid item>
        <QuestionnaireSelect
          placeholder="Select number of days"
          style={{ width: 200 }}
          value={inputValue}
          onChange={(e) => onValueChange(e.target.value as string)}
          disabled={forceDisabled}>
          {DateArrayAsPickerOptions().map((x) => (
            <MenuItem key={x.value} value={x.value}>
              {x.label}
            </MenuItem>
          ))}
        </QuestionnaireSelect>
      </Grid>
      {!inputOnly && (
        <Grid item pt={3}>
          <QuestionnaireButton
            variant="contained"
            onClick={onComplete}
            disabled={disabled}>
            Next
          </QuestionnaireButton>
        </Grid>
      )}
    </Grid>
  );
}

export default ScheduleInput;
