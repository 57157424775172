import React, { useContext, useState } from 'react';
import { range, orderBy } from 'lodash';
import moment from 'moment';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { chartColors } from '../../../theme/theme';
import SelectMenu from '../SelectMenu/SelectMenu';
import SmallPaddedButton from '../Buttons/SmallPaddedButton';
import SVG from '../../../assets/svg';
import FormattedTooltip from '../ChartTooltips/FormattedTooltip';
import Delta from '../Delta';
import AppTextField from '../AppTextField';
import { AuthorizationContext } from '../../../contexts/AuthorizationContext';
import { formatDateStringUtc } from '../../../utils/dateUtils';

const useStyles = makeStyles(() => ({
  lowHigh: {
    color: '#6B6B6B',
    fontSize: 12,
    paddingRight: 8,
  },
  dataRow: {
    padding: 16,
  },
  chartHost: {
    height: 315,
    position: 'relative',
    marginBottom: 16,
  },
}));

const priceSchema = yup.object().shape({
  price: yup.number().required().positive(),
});

enum GraphPeriod {
  Monthly = 'monthly',
  Weekly = 'weekly',
  Daily = 'daily',
}

const ProjectPriceHistory: React.FC = () => {
  const classes = useStyles();
  const { userHasAccess } = useContext(AuthorizationContext);
  const { project, updateProject } = useContext(ProjectContext);
  const [graphPeriod, setGraphPeriod] = useState(GraphPeriod.Monthly);
  const [editing, setEditing] = useState(false);

  const history = project.sellingPriceHistory || [];
  const priceSegmentLengths = [7, 30, 90];

  const priceSegments = priceSegmentLengths.map((segDays) => {
    const segStartMoment = moment().subtract(segDays, 'days');

    const earliestIndexInRange = history.findIndex((x) =>
      moment(x.date).isSameOrAfter(segStartMoment),
    );

    const validPrices = [
      earliestIndexInRange > 0
        ? history[earliestIndexInRange - 1].price
        : project.sellingPrice || 0,
      ...(earliestIndexInRange > -1
        ? history.slice(earliestIndexInRange)
        : []
      ).map((x) => x.price),
    ];

    return {
      days: segDays,
      min: Math.min(...validPrices).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      max: Math.max(...validPrices).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    };
  });

  let change = 'N/A';

  if (history.length > 1) {
    // assume sorted by server
    const diff = history[history.length - 1].price - history[0].price;
    change = new Intl.NumberFormat('en-us', {
      maximumFractionDigits: 2,
      style: 'percent',
    }).format(diff / history[0].price);
  }

  let periods;
  let comparator: moment.unitOfTime.StartOf;
  switch (graphPeriod) {
    case GraphPeriod.Daily:
      periods = range(30).map((x) =>
        moment()
          .subtract(29 - x, 'days')
          .startOf('day')
          .toDate(),
      );
      comparator = 'day';
      break;
    case GraphPeriod.Monthly:
      periods = range(12).map((x) =>
        moment()
          .subtract(11 - x, 'months')
          .startOf('month')
          .toDate(),
      );
      comparator = 'month';
      break;
    default:
      periods = range(12).map((x) =>
        moment()
          .subtract(11 - x, 'weeks')
          .startOf('week')
          .toDate(),
      );
      comparator = 'week';
      break;
  }

  const lineData = [
    {
      id: 'prices',
      data: periods.map((x) => {
        // return price as of this day based
        // on period type
        const found = orderBy(history, 'date')
          .reverse()
          .find((h) => moment(h.date).isSameOrBefore(x, comparator));

        return {
          x: formatDateStringUtc(
            x.toISOString(),
            graphPeriod === GraphPeriod.Monthly ? 'MMM' : 'MMM DD',
          ),
          y: (found?.price || history[0]?.price) ?? 0,
        };
      }),
    },
  ];

  const minPrice = Math.min(...history.map((x) => x.price));
  const minScale = minPrice - minPrice * 0.1;
  const maxPrice = Math.max(...history.map((x) => x.price));
  const maxScale = maxPrice + maxPrice * 0.1;

  const lastMonthTarget = moment().subtract(1, 'month');
  const lastMonthValue = history.reduce<
    | {
        date: string;
        price: number;
        daysFromAMonthAgo: number;
      }
    | undefined
  >((acc, cur) => {
    const daysFromAMonthAgo = Math.abs(
      moment(cur.date).diff(lastMonthTarget, 'days'),
    );

    // Must find a value within 10 days of a month ago to calculate delta
    if (
      !acc ||
      (daysFromAMonthAgo < 10 && daysFromAMonthAgo < acc.daysFromAMonthAgo)
    ) {
      return {
        ...cur,
        daysFromAMonthAgo,
      };
    }

    return acc;
  }, undefined);

  const delta =
    lastMonthValue &&
    project.sellingPrice &&
    (project.sellingPrice - lastMonthValue.price) / lastMonthValue.price;

  return (
    <Grid item container spacing={2} direction="row">
      <Grid item xs={7}>
        <Grid item container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="h3" style={{ marginTop: 12 }}>
              Marketplace Price
            </Typography>
          </Grid>
          <Grid item>
            <FormControl variant="outlined">
              <SelectMenu
                id="category-engagement"
                value={graphPeriod}
                onChange={(e) => setGraphPeriod(e.target.value as GraphPeriod)}>
                <MenuItem value={GraphPeriod.Monthly}>Monthly</MenuItem>
                <MenuItem value={GraphPeriod.Weekly}>Weekly</MenuItem>
                <MenuItem value={GraphPeriod.Daily}>Daily</MenuItem>
              </SelectMenu>
            </FormControl>
          </Grid>
        </Grid>
        <Paper style={{ padding: 24 }}>
          <Grid container direction="row" justifyContent="space-between">
            <Formik
              initialValues={{
                price: project.sellingPrice?.toFixed(2),
              }}
              validationSchema={priceSchema}
              onSubmit={(values) => {
                if (values.price !== project.sellingPrice) {
                  updateProject(
                    {
                      ...project,
                      sellingPrice: parseFloat((values.price || '').toString()),
                    },
                    true,
                  );
                }
                setEditing(false);
              }}>
              {({ submitForm }) => (
                <>
                  <Grid item container direction="row" xs={7}>
                    {editing && (
                      <Field
                        name="price"
                        component={AppTextField}
                        titleEditor
                        style={{
                          width: '150px',
                          position: 'relative',
                          top: -8,
                        }}
                      />
                    )}
                    {!editing && (
                      <Typography variant="h1">
                        {(project.sellingPrice || 0).toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </Typography>
                    )}
                    <Typography
                      variant="subtitle1"
                      style={{ fontSize: 12, margin: 8, marginTop: 0 }}>
                      USD
                    </Typography>
                    {!!delta && (
                      <div style={{ marginTop: 12 }}>
                        <Delta value={delta} />
                      </div>
                    )}
                  </Grid>
                  <Grid item>
                    {userHasAccess('ZeroMe.Marketplace', 'EDIT') && (
                      <SmallPaddedButton
                        onClick={() => {
                          if (editing) {
                            submitForm();
                          } else {
                            setEditing(true);
                          }
                        }}>
                        <SVG.Edit
                          style={{
                            position: 'relative',
                            top: -2,
                            marginRight: 8,
                          }}
                        />
                        {editing ? 'Save Price' : 'Edit Price'}
                      </SmallPaddedButton>
                    )}
                  </Grid>
                </>
              )}
            </Formik>
          </Grid>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <div className={classes.chartHost}>
            {!history.length && (
              <Typography
                variant="h1"
                style={{ textAlign: 'center', paddingTop: 150 }}>
                No Price History Available.
              </Typography>
            )}
            {!!history.length && (
              <ResponsiveLine
                margin={{ top: 10, right: 60, bottom: 20, left: 20 }}
                animate
                data={lineData}
                enablePoints={false}
                enableGridY={false}
                enableGridX={false}
                colors={[chartColors.darkGreen]}
                yScale={{
                  type: 'linear',
                  ...(lineData.length
                    ? {
                        min: minScale,
                        max: maxScale,
                      }
                    : {
                        min: 0,
                        max: 200,
                      }),
                }}
                axisLeft={null}
                axisRight={{
                  tickPadding: 16,
                  tickSize: 0,
                  tickValues: 6,
                  format: (x) =>
                    x.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                }}
                axisBottom={{
                  tickSize: 0,
                  tickPadding: 8,
                  tickValues:
                    graphPeriod === GraphPeriod.Daily
                      ? lineData[0].data
                          .map((d) => d.x)
                          .filter((v, idx) => idx % 3 === 0)
                      : 12,
                }}
                enableArea
                defs={[
                  linearGradientDef('gradientA', [
                    { offset: 0, color: '#65BE90' },
                    {
                      offset: ((maxPrice - minScale) / maxPrice) * 100,
                      color: 'rgba(10, 55, 81, 0)',
                    },
                  ]),
                ]}
                fill={[{ match: '*', id: 'gradientA' }]}
                crosshairType="x"
                useMesh
                tooltip={(props) => (
                  <FormattedTooltip
                    format="currency"
                    value={props.point.data.y.valueOf() as number}
                    id={props.point.data.x as string}
                  />
                )}
                theme={{
                  crosshair: {
                    line: {
                      stroke: '#f00',
                      strokeDasharray: '0',
                      strokeWidth: 1,
                    },
                  },
                }}
              />
            )}
          </div>
        </Paper>
      </Grid>
      <Grid item xs={5}>
        <Paper style={{ padding: 16 }}>
          {priceSegments.map((ps) => (
            <React.Fragment key={ps.days}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                className={classes.dataRow}>
                <Grid item>
                  <Typography variant="body1" align="center">
                    {ps.days}
                    <br />
                    DAY
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between">
                    <Typography variant="subtitle1" className={classes.lowHigh}>
                      LOW:{' '}
                    </Typography>
                    <Typography variant="body1">{ps.min}</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between">
                    <Typography variant="subtitle1" className={classes.lowHigh}>
                      HIGH:{' '}
                    </Typography>
                    <Typography variant="body1">{ps.max}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
            </React.Fragment>
          ))}
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className={classes.dataRow}>
            <Grid item>
              <Typography variant="body1" align="center">
                % Change
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{change}</Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className={classes.dataRow}>
            <Grid item>
              <Typography variant="body1" align="center">
                Revenue
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {project.totalRevenue?.revenue.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                })}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ProjectPriceHistory;
