import { gql, TypedDocumentNode } from '@apollo/client';
import { EmployeeCommutingResult } from '../../types/dashboard/types';

export const EMPLOYEE_COMMUTING = gql`
  query employeeCommutingStatistics(
    $startDate: String!
    $endDate: String!
    $companyId: String!
    $includeChildren: Boolean
  ) {
    employeeCommutingStatistics(
      startDate: $startDate
      endDate: $endDate
      companyId: $companyId
      includeChildren: $includeChildren
    ) {
      total
      totalMiles
      data {
        id
        label
        value
        miles
        color
        children {
          id
          label
          value
          miles
          color
        }
      }
    }
  }
` as TypedDocumentNode<{
  employeeCommutingStatistics: EmployeeCommutingResult;
}>;

export default {
  EMPLOYEE_COMMUTING,
};
