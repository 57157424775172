import React from 'react';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SVG from '../../../assets/svg';
import { Project } from '../../../types/project/types';
import useCountryName from '../../../hooks/useCountryName';

const useStyles = makeStyles(() => ({
  projectLocation: {
    color: '#9E9E9E',
  },
  locationText: {
    position: 'relative',
    bottom: 4,
  },
}));

type Props = {
  project: Pick<Project, 'country'>;
};

const ProjectCardLocation: React.FC<Props> = ({ project }) => {
  const classes = useStyles();

  return (
    <Grid item>
      <Typography
        component="p"
        display="inline"
        variant="body2"
        className={classes.projectLocation}>
        <SVG.Marketplace.Location />{' '}
        <span className={classes.locationText}>
          {useCountryName(project.country || '')}
        </span>
      </Typography>
    </Grid>
  );
};

export default ProjectCardLocation;
