import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { t } from 'i18next';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { keyBy } from 'lodash';

import DataStateHandler from '../../common/DataStateHandler/DataStateHandler';
import ProgressBar from '../../common/ProgressBar/ProgressBar';
import { GET_MEMBER_QUIZ_METRICS } from '../../../graphql/communications/memberCommunications';
import NumberUtils from '../../../utils/numberUtils';
import { Colors } from '../../../theme/shared';

const useStyles = makeStyles((theme) => ({
  date: {
    textAlign: 'center',
    color: Colors.TertiaryText,
    margin: 16,
  },
  question: {
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: `1px solid ${theme.palette.text.disabled}`,
    '&:last-child': { borderBottom: 'none' },
  },
  answer: {
    marginTop: 8,
  },
}));

type Props = {
  pollId: string;
};

const MemberPollResultsDetail: React.FC<Props> = ({ pollId }) => {
  const classes = useStyles();

  const {
    loading,
    error,
    data: { memberQuiz: poll } = {},
  } = useQuery(GET_MEMBER_QUIZ_METRICS, {
    variables: {
      quizId: pollId,
    },
  });

  const expireDate = moment(poll?.expireDate);
  const isPollClosed = expireDate.isValid() && expireDate.isBefore(moment());

  // Calculate the percentage that each option was chosen
  const pollMetrics = useMemo(
    () =>
      keyBy(
        poll?.metrics?.answers?.map((answer) => ({
          ...answer,
          percentage: answer.totalOccurances / (poll?.metrics?.quizCount ?? 1),
        })),
        'answerId',
      ),
    [poll],
  );

  return (
    <DataStateHandler loading={loading} error={error}>
      {poll ? (
        <Grid container spacing={6} justifyContent="center">
          <Grid container item direction="column">
            <Typography variant="h1" align="center">
              {poll.title}
            </Typography>
            <Typography className={classes.date} variant="subtitle2">
              {`Poll ${isPollClosed ? 'closed' : 'closes'} ${expireDate.format(
                'M/D/YYYY',
              )}`}
            </Typography>
          </Grid>
          <Grid container item direction="column">
            {poll.questions?.map((question) => (
              <Grid item className={classes.question}>
                <Typography variant="h3" align="center">
                  {question.questionText}
                </Typography>
                {question.options?.map((option) => (
                  <Grid item className={classes.answer}>
                    <Typography variant="subtitle2" color={Colors.TertiaryText}>
                      {option.answerText}
                    </Typography>
                    <ProgressBar
                      value={pollMetrics?.[option.answerId]?.percentage ?? 0}
                      maxValue={1}
                      label={`
                        ${NumberUtils.format(
                          pollMetrics?.[option.answerId]?.percentage ?? 0,
                          'wholePercent',
                        )}`}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h3" align="center">
          {t('poll.pollResultsNotFound')}
        </Typography>
      )}
    </DataStateHandler>
  );
};

export default MemberPollResultsDetail;
