import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { formatDateStringUtc } from '../../utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  requestedChip: {
    backgroundColor: '#FFDDE4',
    color: theme.palette.error.dark,
  },
  fulfilledChip: {
    color: '#3D2552',
    backgroundColor: '#E6DFED',
  },
}));

type Props = {
  fulfilledDate?: string;
  textClassname?: string;
};

const UserDataStatusChip: React.FC<Props> = ({
  fulfilledDate,
  textClassname,
}) => {
  const classes = useStyles();

  return (
    <>
      <Chip
        label={fulfilledDate ? 'Completed' : 'Requested'}
        className={
          fulfilledDate ? classes.fulfilledChip : classes.requestedChip
        }
      />
      {fulfilledDate ? (
        <Box ml="8px">
          <Typography variant="caption" className={textClassname}>
            {formatDateStringUtc(fulfilledDate, 'MM/DD/YYYY')}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default UserDataStatusChip;
