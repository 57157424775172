import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  MenuItem,
  Box,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@nivo/core';

import { EmissionsQueryResult } from '../../../../../types/dashboard/types';
import AppInfo from '../../../../common/AppInfo';
import Scope3Chart from './Scope3Chart';
import SelectMenu from '../../../../common/SelectMenu/SelectMenu';
import { Colors } from '../../../../../theme/shared';

const useStyles = makeStyles(() => ({
  section: {
    marginBottom: '24px',
  },
  paperContainer: {
    background: Colors.DarkGrey,
    padding: 16,
  },
  chartLabel: {
    color: '#FFFFFF',
    opacity: 0.5,
    marginBottom: 8,
  },
}));

type Props = {
  emissions: EmissionsQueryResult;
  currentGoal: number;
};

export const EmissionsTheme: Theme = {
  textColor: '#FFFFFF',
  grid: {
    line: {
      stroke: '#D1FFBB',
      strokeWidth: 1,
      strokeOpacity: 0.2,
    },
  },
};

const Scope3Container: React.FC<Props> = ({ emissions, currentGoal }) => {
  const classes = useStyles();
  const [category, setCategory] = useState('all');

  const KeyCategory6 = 'Business Travel';
  const KeyCategory7 = 'Commuting';
  const KeyWorkFromHome = 'Work from Home';

  // All data items
  const allDataItems: { key: string; label: string }[] = [];
  allDataItems.push({
    key: 'category6',
    label: KeyCategory6,
  });
  allDataItems.push({
    key: 'category7',
    label: KeyCategory7,
  });
  allDataItems.push({
    key: 'wfh',
    label: KeyWorkFromHome,
  });

  // Render the items for the dropdown list
  const listMenuItems: JSX.Element[] = [];
  allDataItems.forEach((l) => {
    listMenuItems.push(
      <MenuItem key={l.key} value={l.key}>
        {l.label}
      </MenuItem>,
    );
  });

  const data = emissions.totalFootprint.map((e) => {
    let value = 0;
    let offsetValue = 0;
    switch (category) {
      case 'all':
        value = e.category6 + e.category7 + e.wfh;
        offsetValue =
          e.category6OffsetValue + e.category7OffsetValue + e.wfhOffsetValue;
        break;
      case 'category6':
        value = e.category6;
        offsetValue = e.category6OffsetValue;
        break;
      case 'category7':
        value = e.category7;
        offsetValue = e.category7OffsetValue;
        break;
      case 'wfh':
        value = e.wfh;
        offsetValue = e.wfhOffsetValue;
        break;
      default:
        break;
    }

    return {
      date: e.date,
      value,
      category,
      offsetValue,
    };
  });
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.section}>
        <Box mt={2}>
          <Typography variant="h3" color="textPrimary">
            Scope 3 Emissions - Category 6 & 7
          </Typography>
        </Box>
      </Grid>
      <Paper className={classes.paperContainer}>
        <Grid container justifyContent="space-between">
          <Typography variant="caption" className={classes.chartLabel}>
            MT C0₂E
          </Typography>
          <Grid item>
            <FormControl
              sx={{ position: 'relative', bottom: 4 }}
              variant="outlined">
              <SelectMenu
                id="category"
                value={category}
                inputStyle={{ background: 'white' }}
                onChange={(e) => setCategory(e.target.value)}>
                <MenuItem key="all" value="all">
                  All Categories
                </MenuItem>
                {listMenuItems}
              </SelectMenu>
            </FormControl>
            <AppInfo id="employeescope3emissions" iconColor="white" />
          </Grid>
        </Grid>
        <div>
          <Scope3Chart footprints={data} goal={currentGoal} />
        </div>
      </Paper>
    </>
  );
};

export default Scope3Container;
