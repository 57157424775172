import { Box, Typography, Grid, Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useContext } from 'react';
import { isAfter, format } from 'date-fns';

import AlphaColumnFilter from '../common/Table/AlphaColumnFilter';
import GroupableSelectTable from '../common/Table/GroupableSelectTable';
import RoundedFlexBox from '../trader/RoundedFlexBox';

import { GroupableTableStructure } from '../common/Table/types';
import { EmailCommunicationListItem } from './types';
import { CommunicationsContext } from '../../contexts/CommunicationsContext';
import EmailCommunicationDetails from './forms/EmailCommunicationDetails';
import SmallPaddedButton from '../common/Buttons/SmallPaddedButton';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import NumberUtils from '../../utils/numberUtils';
import PageTitle from '../common/PageTitle';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 16,
  },
  drawer: {
    maxWidth: 680,
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      width: 680,
    },
  },
}));

const dateFormat = 'M/d/yy';

const getTableStructure =
  (): GroupableTableStructure<EmailCommunicationListItem>[] => [
    {
      key: 'main',
      columns: [
        {
          key: 'title',
          display: 'Title',
          searchable: true,
          type: AlphaColumnFilter,
          searchPlaceholder: 'Search Title',
          render: (c) => <Typography variant="body2">{c.title}</Typography>,
        },
        {
          key: 'status',
          display: 'Status',
          type: AlphaColumnFilter,
          render: ({ publishDate }) => {
            if (!publishDate || isAfter(new Date(publishDate), new Date())) {
              return <Typography variant="body2">Draft</Typography>;
            }
            return (
              <Typography variant="body2" style={{ color: '#398360' }}>
                Published
              </Typography>
            );
          },
        },
        {
          key: 'publishDate',
          type: AlphaColumnFilter,
          display: 'Published',
          resolveFilterLabel: (value) =>
            value ? format(new Date(value), dateFormat) : '',
          render: (c) => (
            <Typography variant="body2">
              {c.publishDate ? format(new Date(c.publishDate), dateFormat) : ''}
            </Typography>
          ),
        },
        {
          key: 'openedPercent',
          type: AlphaColumnFilter,
          display: 'Opened',
          resolveFilterLabel: (value) =>
            NumberUtils.format(value as number, 'wholePercent'),
          render: (c) => (
            <Typography variant="body2">
              {NumberUtils.format(c.openedPercent, 'wholePercent')}
            </Typography>
          ),
        },
      ],
    },
  ];

const EmailCommunicationsList: React.FC = () => {
  const classes = useStyles();
  const [emailId, setEmailId] = useState<string | null>(null);
  const { userHasAccess } = useContext(AuthorizationContext);
  const { emails, companyId } = useContext(CommunicationsContext);

  const canEdit = companyId
    ? userHasAccess('Client.Communication.Emails', 'EDIT')
    : userHasAccess('ZeroMe.Communication.Emails', 'EDIT');

  return (
    <>
      <Box px={8} py={4}>
        <Grid container direction="column">
          <Grid item>
            <PageTitle title="Emails">
              {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
              <>
                {canEdit && (
                  <SmallPaddedButton onClick={() => setEmailId('new')}>
                    + Create New
                  </SmallPaddedButton>
                )}
              </>
            </PageTitle>
          </Grid>
          <Grid item className={classes.table}>
            <Grid
              container
              direction="column"
              style={{ height: 'calc(100vh - 110px)' }}>
              <RoundedFlexBox>
                <GroupableSelectTable<EmailCommunicationListItem>
                  tableData={emails.map((i) => {
                    let status: 'Draft' | 'Published' = 'Published';
                    if (
                      !i.publishDate ||
                      isAfter(new Date(i.publishDate), new Date())
                    ) {
                      status = 'Draft';
                    }
                    return {
                      ...i,
                      status,
                    };
                  })}
                  tableStructure={getTableStructure()}
                  hideStickyCol
                  onRowClick={({ id }) => {
                    setEmailId(id);
                  }}
                />
              </RoundedFlexBox>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Drawer open={!!emailId} onClose={() => setEmailId(null)} anchor="right">
        <Box className={classes.drawer}>
          {emailId && (
            <EmailCommunicationDetails
              id={emailId}
              onClose={() => setEmailId(null)}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default EmailCommunicationsList;
