import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useContext } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AuthorizedRoute from '../common/AuthorizedRoute';
import Loading from '../common/Loading';
import { AuthorizationContext } from '../../contexts/AuthorizationContext';
import MemberAboutMe from '../../pages/member/aboutme/MemberAboutMe';
import MyResponses from '../../pages/member/aboutme/MyResponses';
import MemberActivities from '../../pages/member/activities/MemberActivities';
import MemberBadgeDetails from '../../pages/member/badge/MemberBadgeDetails';
import MemberBadges from '../../pages/member/badge/MemberBadges';
import MemberChallenges from '../../pages/member/contest/MemberChallenges';
import MemberLeaderboards from '../../pages/member/contest/MemberLeaderboards';
import MemberLeaderboardDetails from '../../pages/member/contest/MemberLeaderboardDetails';
import MemberDashboard from '../../pages/member/dashboard/MemberDashboard';
import MemberDiscover from '../../pages/member/discover/MemberDiscover';
import MemberDiscoverDetails from '../../pages/member/discover/MemberDiscoverDetails';
import ConfirmHabitsPage from '../../pages/member/myhabits/ConfirmHabits';
import MemberHabits from '../../pages/member/myhabits/MemberHabits';
import MemberHabitDetails from '../../pages/member/myhabits/MemberHabitDetails';
import MemberOnboarding from '../../pages/member/onboarding/MemberOnboarding';
import AnswerQuestions from '../../pages/member/questionnaire/AnswerQuestions';
import MemberQuiz from '../../pages/member/quiz/MemberQuiz';
import MemberPoll from '../../pages/member/poll/MemberPoll';
import MemberPollResultDetails from '../../pages/member/poll/MemberPollResultDetails';
import MemberPollResults from '../../pages/member/poll/MemberPollResults';
import MemberProjects from '../../pages/member/projects/MemberProjects';
import MemberProjectDetails from '../../pages/member/projects/MemberProjectDetails';
import MemberProjectHistory from '../../pages/member/projecthistory/MemberProjectHistory';
import MemberContact from '../../pages/member/settings/MemberContact';
import MemberDevelopment from '../../pages/member/settings/MemberDevelopment';
import MemberFAQs from '../../pages/member/settings/MemberFAQs';
import MemberDataManagement from '../../pages/member/settings/MemberManageData';
import MemberSettings from '../../pages/member/settings/MemberSettings';
import MemberSustainabilityInterests from '../../pages/member/sustainabilityInterests/MemberSustainabilityInterests';

const MemberRouter = () => {
  const { loading, isOnboarding } = useContext(AuthorizationContext);

  if (loading) {
    return <Loading />;
  }

  if (isOnboarding) {
    return (
      <Switch>
        <AuthorizedRoute path="/member/onboarding">
          <MemberOnboarding />
        </AuthorizedRoute>
        <Redirect to="/member/onboarding" />
      </Switch>
    );
  }

  return (
    <Switch>
      <AuthorizedRoute path="/member/dashboard">
        <MemberDashboard />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/onboarding">
        <MemberOnboarding />
      </AuthorizedRoute>
      <AuthorizedRoute
        featureIds={['marketplace']}
        exact
        path="/member/projects">
        <MemberProjects />
      </AuthorizedRoute>
      <AuthorizedRoute featureIds={['marketplace']} path="/member/projects/:id">
        <MemberProjectDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/myhabits">
        <MemberHabits />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/myhabits/confirm">
        <ConfirmHabitsPage />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/myhabits/:id">
        <MemberHabitDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/discover">
        <MemberDiscover />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/discover/:id">
        <MemberDiscoverDetails />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/quiz/:id">
        <MemberQuiz />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/poll/:id">
        <MemberPoll />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/about-me">
        <MemberAboutMe />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/sustainability-interests">
        <MemberSustainabilityInterests />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/challenge">
        <MemberChallenges />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/challenge/:contestId">
        <MemberLeaderboards />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/challenge/detail/:contestId">
        <MemberLeaderboardDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/leaderboard">
        <MemberLeaderboards />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/leaderboard/detail/:contestId/:rollup">
        <MemberLeaderboardDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/badge">
        <MemberBadges />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/badge/detail">
        <MemberBadgeDetails />
      </AuthorizedRoute>
      <AuthorizedRoute exact path="/member/poll-results">
        <MemberPollResults />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/poll-results/:id">
        <MemberPollResultDetails />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/my-project-history">
        <MemberProjectHistory />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/settings">
        <MemberSettings />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/faqs">
        <MemberFAQs />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/contact">
        <MemberContact />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/manage-data">
        <MemberDataManagement />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/development">
        <MemberDevelopment />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/activities">
        <MemberActivities />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/questionnaire/:id/:index?">
        <AnswerQuestions />
      </AuthorizedRoute>
      <AuthorizedRoute path="/member/responses/:id">
        <MyResponses />
      </AuthorizedRoute>
      <Redirect to="/member/dashboard" />
    </Switch>
  );
};

export default MemberRouter;
